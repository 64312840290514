import { useEffect, useState } from 'react';

const useGoogleLocation = () => {
  const [userLocation, setUserlocation] = useState({
    city: '',
    address: '',
    country: '',
  });
  const [latlng, setLatlng] = useState({
    lat: '',
    lng: '',
  });

  const getUserLocation = async (position) => {
    try {
      const latitude = position.lat;
      const longitude = position.lng;

      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_KEY}`
      );
      const data = await response.json();
      const city = data?.results[0]?.address_components?.find((component) =>
        component?.types?.includes('locality')
      )?.long_name;

      const address = data?.results[0]?.address_components?.find((component) =>
        component?.types?.includes('route')
      )?.long_name;

      const country = data?.results[0]?.address_components?.find((component) =>
        component?.types?.includes('country')
      )?.long_name;

      setUserlocation((prev) => {
        return {
          ...prev,
          city,
          address,
          country,
          full_address: `${address ?? ''},${city ?? ''},${country ?? ''}`,
        };
      });
    } catch (error) {}
  };

  useEffect(() => {
    getUserLocation({ lat: latlng.lat, lng: latlng.lng });
  }, [latlng]);

  const setUserLatlng = (lat = '', lng = '') => {
    setLatlng((prev) => {
      return {
        ...prev,
        lat,
        lng,
      };
    });
  };
  return { setUserLatlng, userLocation };
};

export default useGoogleLocation;
