import Popup from 'reactjs-popup';
import styles from './unavailable.module.scss';
import Button from '../../../../custom/btn/Btn';
import CheckBox from '../../../../custom/checkbox/CheckBox';
import { AiOutlineClose } from 'react-icons/ai';
import { useEffect } from 'react';

const UnavailableModal = ({
  isOnPrivateCall,
  open,
  handleClose,
  handleBookPrivateRequest,
  broadcastModelDetail,
  isLoading,
  sendOfflineMessage,
  isModelOnline,
}) => {
  return (
    <Popup open={open} closeOnDocumentClick onClose={handleClose}>
      <main
        className={
          isModelOnline && isOnPrivateCall
            ? styles.joinContainer
            : styles.container
        }
      >
        <h1 className={styles.title}>
          Model is Away{' '}
          <AiOutlineClose className={styles.icon} onClick={handleClose} />
        </h1>

        {isModelOnline && isOnPrivateCall ? (
          <p className={styles.subTitle}>
            <span className={styles.message}>
              <span className={styles.model}>
                {broadcastModelDetail?.firstName ?? ''}
              </span>{' '}
              has not joined the private show
            </span>
          </p>
        ) : (
          <p className={styles.subTitle}>
            <span className={styles.message}>
              <span className={styles.model}>
                {broadcastModelDetail?.firstName ?? ''}
              </span>{' '}
              is currently away but we can notify you when she comes online.
            </span>
            <br /> How will you like to be notified?
          </p>
        )}
        {(!isModelOnline || !isOnPrivateCall) && (
          <>
            <p className={styles.option}>
              Email <CheckBox />{' '}
            </p>
            <p className={styles.option}>
              On-site Notification <CheckBox />{' '}
            </p>
            <p className={styles.option}>
              or book a show for when she’s available
            </p>
          </>
        )}

        <Button
          onClick={
            !isModelOnline
              ? () => sendOfflineMessage()
              : () => handleBookPrivateRequest()
          }
          text={
            isOnPrivateCall && isModelOnline
              ? 'End A Private Show'
              : isLoading
              ? 'Sending.. Chat'
              : 'Book A Private Show'
          }
          className={styles.startPrivateShowButton}
          disabled={isLoading}
        />
      </main>
    </Popup>
  );
};

export default UnavailableModal;
