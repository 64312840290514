import AgoraRTC from 'agora-rtc-sdk-ng';
import AgoraRTM from 'agora-rtm-sdk';

const config = {
	screenSharing: false,
	appID: process.env.REACT_APP_AGORA_APP_ID,
	mode: 'rtc',
	codec: 'vp9',
};

let client = null;
let messageClient = null;
let broadcastChannel = null;

export const enableDualStream = async () => {
	await client.enableDualStream();
};

export const setBroadcastResolution = async config => {
	await client.setLowStreamParameter(config);
};

export const getCameras = async () => {
	return await AgoraRTC.getCameras(true);
};

export const getMicrophones = async () => {
	return await AgoraRTC.getMicrophones(true);
};

export const setChannelConfigs = async (channel, data) => {
	try {
		await messageClient.storage.setChannelMetadata(channel, 'MESSAGE', data, {});
	} catch (error) {}
};

export const getChannelConfigs = async channel => {
	try {
		return await messageClient.storage.getChannelMetadata(channel, 'MESSAGE');
	} catch (error) {}
};

export const getRemoteUsers = async () => {
	try {
		return await client.remoteUsers;
	} catch (error) {}
};

export const createAgoraClient = async (role = 'host', { userId }) => {
	try {
		client = new AgoraRTC.createClient({
			mode: config.mode,
			codec: config.codec,
		});

		messageClient = new AgoraRTM.RTM(config.appID, userId, {
			token: null,
		});
	} catch (error) {}
};

export const joinBroadCastInit = async (broadcastingRoomID, userID, token = null) => {
	try {
		await messageClient.login();
		await client.join(config.appID, broadcastingRoomID, null, userID);

		broadcastChannel = await messageClient.createStreamChannel(broadcastingRoomID);
		await broadcastChannel.join({
			withMetadata: true,
		});

		await messageClient.subscribe(broadcastingRoomID);
	} catch (error) {}
};

export const leaveBroadcast = async () => {
	try {
		await client?.leave();
		await broadcastChannel?.leave();
		await messageClient?.logout();
	} catch (error) {}
};

export const getBroadCastMembers = async channel => {
	return messageClient.presence.getOnlineUsers(channel, 'MESSAGE', {
		includedState: true,
	});
};

export const updateBroadcastMemberDetails = async (data, userId) => {
	try {
		await messageClient.storage.updateUserMetadata(data, {
			userId: userId,
			addTimeStamp: true,
			addUserId: true,
		});
	} catch (error) {}
};

export const broadcastMessage = async (channel, message) => {
	await messageClient.publish(channel, JSON.stringify(message));
};

export const getBroadcastMemberDetails = async userId => {
	try {
		return await messageClient.storage.getUserMetadata({ userId });
	} catch (error) {}
};

export const createLocalTrack = async (
	config = {
		audioConfig: {
			ANS: true,
			AEC: true,
		},
		videoConfig: {
			optimizationMode: 'detail',
			encoderConfig: {
				bitrateMax: 3000,
				bitrateMin: 2000,
				frameRate: 30,
				height: { max: 1280, min: 720 },
				width: { max: 1280, min: 720 },
			},
		},
	}
) => {
	try {
		return await new AgoraRTC.createMicrophoneAndCameraTracks(config.audioConfig, config.videoConfig);
	} catch (error) {}
};

export const subScribeUser = async (user, mediaType) => {
	try {
		await client.subscribe(user, mediaType);
	} catch (error) {}
};

export const preSubScribeUser = async (userId, mediaType) => {
	try {
		return await client.presubscribe(userId, mediaType);
	} catch (error) {}
};

export const publishTrack = async tracks => {
	try {
		await client.publish([tracks[0], tracks[1]]);
	} catch (error) {}
};

export const unPublishTrack = async tracks => {
	await client.unpublish([tracks[0], tracks[1]]);
};

export const registerEvent = async (eventName, handler) => {
	client.on(eventName, handler);
};

export const registerMessagingEvent = async (eventName, handler) => {
	messageClient.addEventListener(eventName, handler);
};

export const setRole = async role => {
	await client.setClientRole(role);
};

export const shareScreen = async localTrack => {
	try {
		const screenTrack = await AgoraRTC.createScreenVideoTrack({}, 'auto');
		await localTrack.replaceTrack(screenTrack.getMediaStreamTrack(), true);
	} catch (error) {}
};

export const micChangeDevice = async localAudioTrack => {
	client.onMicrophoneChanged = async changedDevice => {
		if (changedDevice.state === 'ACTIVE') {
			localAudioTrack.setDevice(changedDevice.device.deviceId);
			// Switch to an existing device when the current device is unplugged.
		} else if (changedDevice.device.label === localAudioTrack.getTrackLabel()) {
			const oldMicrophones = await client.getMicrophones();
			oldMicrophones[0] && localAudioTrack.setDevice(oldMicrophones[0].deviceId);
		}
	};
};

export const camChangeDevice = async localVideoTrack => {
	client.onCameraChanged = async changedDevice => {
		if (changedDevice.state === 'ACTIVE') {
			localVideoTrack.setDevice(changedDevice.device.deviceId);
			// Switch to an existing device when the current device is unplugged.
		} else if (changedDevice.device.label === localVideoTrack.getTrackLabel()) {
			const oldCameras = await client.getCameras();
			oldCameras[0] && localVideoTrack.setDevice(oldCameras[0].deviceId);
		}
	};
};
