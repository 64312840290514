import { useState } from 'react';
import Video from '../../../custom/video/Video';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { ReactComponent as EditIcon } from '../../../images/svg/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../images/svg/delete-icon.svg';
import { ReactComponent as AlbumIcon } from '../../../images/svg/album-icon.svg';
import { BiError } from 'react-icons/bi';

const VideoPreview = ({
  className,
  handleOpenEditVideoModal,
  deletePhoto,
  handleVideoSelection,
  isSelected,
  videoUploadId,
  video,
  ...props
}) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const onLoading = (state) => {
    setIsPlaying(state.loading);
  };

  return (
    <section className={classnames(styles.section, className)}>
      {isPlaying && (
        <div className={styles.errorContainer}>
          <BiError className={styles.icon} />
        </div>
      )}
      <section className={styles.wrapper}>
        <button className={styles.btnEdit} onClick={handleOpenEditVideoModal}>
          <EditIcon className={styles.editIcon} />
          Edit
        </button>
        <section className={styles.checkBoxWrapper}>
          <fieldset className={styles.checkboxFieldset}>
            <input
              type='checkbox'
              className={styles.checkbox}
              onChange={() => handleVideoSelection(videoUploadId)}
              checked={isSelected?.(videoUploadId)}
            />
          </fieldset>
          <DeleteIcon className={styles.deleteIcon} onClick={deletePhoto} />
        </section>

        <section className={styles.nameWrapper}>
          <fieldset>
            <label className={styles.inputNameLabel}>
              <AlbumIcon className={styles.editIcon} />
              <input
                disabled={true}
                type='text'
                className={styles.nameInput}
                value={video?.videoName}
              />
            </label>
          </fieldset>
        </section>
      </section>
      <Video
        videoClass={isPlaying && styles.playing}
        className={classnames(styles.video)}
        {...props}
        onLoading={onLoading}
      />
    </section>
  );
};

export default VideoPreview;
