import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';
import { rootReducers } from './rootReducer';

const persistConfig = {
	key: 'nsa-root',
	storage,
	stateReconciler: hardSet,
};

const persistedReducer = persistReducer(persistConfig, rootReducers);

const store = configureStore({
	reducer: persistedReducer,
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}).concat(),
	devTools: process.env.NODE_ENV !== 'production',
});

export const persistedStore = persistStore(store);
export default store;

// persistedStore.purge();
