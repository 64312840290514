import Popup from 'reactjs-popup';
import { AiOutlineClose } from 'react-icons/ai';
import Input from '../../../custom/input/Input';
import { useEffect, useState } from 'react';
import Button from '../../../custom/btn/Btn';

const ChangeTopicModal = ({
  open,
  handleClose,
  handleSaveRoomAndTopic,
  getStreamingConfig,
}) => {
  const [topic, setTopic] = useState({
    name: 'topic',
    value: '',
  });

  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setTopic((prev) => {
      return {
        ...prev,
        name,
        value,
      };
    });
  };

  const handleGetStreamingConfig = async () => {
    try {
      const config = await getStreamingConfig();
      console.log(config, '>>><>>con');
      const { metadata } = config;
      if (metadata) {
        setTopic((prev) => {
          return {
            ...prev,
            value: metadata?.topic?.value,
          };
        });
      }
    } catch (error) {}
  };

  useEffect(() => {
    handleGetStreamingConfig();
  }, []);

  return (
    <Popup onClose={handleClose} closeOnDocumentClick open={open}>
      <main
        className={
          'relative w-[431px] h-[244px] p-[24px]  bg-gradient-to-r from-[#413450] to-[#563143] m-auto'
        }
      >
        <div
          onClick={handleClose}
          className="cursor-pointer absolute top-[0] right-[0] flex justify-center items-center w-12 h-12  before:content-['']  bg-stone-400 opacity-[20]"
        >
          <AiOutlineClose className='z-[999] relative text-[1.5rem]  color-white' />
        </div>

        <p className="mb-[20px] text-primary-100 text-2xl font-bold font-['Montserrat'] leading-7">
          Room Topic
        </p>
        <label className='w-[100%] block mb-[20px]'>
          <span className="block mb-[10px] opacity-90 text-neutral-300 text-sm font-medium font-['Montserrat'] leading-tight">
            Room Topic
          </span>
          <Input
            defaultValue={topic.value}
            name='topic'
            onChange={handleInputChange}
            className={
              "!w-[100%] border border-primary-100 !bg-transparent text-white text-sm font-medium font-['Montserrat'] leading-tight"
            }
            placeholder={'General Topic'}
          />
        </label>

        <div className='flex justify-end gap-[0.2rem]'>
          <Button
            onClick={handleClose}
            text={'Cancel'}
            className={
              "w-[150px] !border-[0px] flex justify-center items-center !bg-transparent h-12 text-center text-white text-base font-medium font-['Montserrat'] leading-[18.40px]"
            }
          />
          <Button
            className={
              "w-[150px] !border-[0px] flex justify-center items-center bg-primary-400 h-12 text-center text-white text-base font-medium font-['Montserrat'] leading-[18.40px]"
            }
            onClick={() => {
              handleSaveRoomAndTopic(topic);
            }}
            text={'Save'}
          />
        </div>
      </main>
    </Popup>
  );
};

export default ChangeTopicModal;
