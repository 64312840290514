import notification from '../utils/notification';
import errorFormmatter from '../utils/errorFormatter';

const validateInputs = async (validationSchema, input) => {
	try {
		return await validationSchema.validate(input, { strick: true });
	} catch (error) {
		notification({
			title: 'Validation error',
			message: errorFormmatter(error),
			type: 'danger',
			position: 'top-center',
		});
	}
};

export default validateInputs;
