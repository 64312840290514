import styles from './personal-infor.module.scss';
import ModelProfileHeader from '../../custom/modelProfileHeader/ModelProfileHeader';
import FluidContainer from '../../custom/fluidContainer/FluidContainer';
import Button from '../../custom/btn/Btn';
import FileUpload from '../../custom/fileUpload/FileUpload';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Spinner from '../../custom/spinner/Spinner';
import { useSelector } from 'react-redux';
import { getPayeeModelDetails } from '../../request/model';
import notification from '../../utils/notification';
import {
  createPayeeInformation,
  updatePayeeInformation,
} from '../../request/model';
import { createClub, getClub, updateFanClub } from '../../request/model';
import { Link, useRouteMatch } from 'react-router-dom';
import errorFormmatter from '../../utils/errorFormatter';
import Popup from 'reactjs-popup';
import { ReactComponent as QuestionMarkIcon } from '../../svgs/question-mark.svg';
import Input from '../../custom/input/Input';
import Textarea from '../../custom/textarea/Textarea';
import DropDown from '../../custom/DropDown/DropDown';
import DatePicker from '../../custom/datePicker/DatePicker';
import moment from 'moment';

const ActiveArrow = (props) => {
  return (
    <svg
      {...props}
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='25'
      viewBox='0 0 25 29'
      fill='none'
    >
      <path
        d='M24 12.7679C25.3333 13.5378 25.3333 15.4622 24 16.232L3.75 27.9234C2.41666 28.6932 0.749999 27.7309 0.749999 26.1913L0.75 2.80865C0.75 1.26905 2.41667 0.306805 3.75 1.07661L24 12.7679Z'
        fill='#010002'
      />
    </svg>
  );
};

const payeeInformationSchema = Yup.object().shape({
  fullName: Yup.string().trim().required('full name is required'),
  email: Yup.string().trim().required('email is required'),
  zipCode: Yup.string().trim().required('zip code is required'),
  city: Yup.string().trim().required('city is required'),
  taxpayer: Yup.string().trim().required('tax payer is required'),
  currency: Yup.string().trim().required('currency is required'),
  bankName: Yup.string().trim().required('bank name is required'),
  bankAddress: Yup.string().trim().required('bank address is required'),
  bankCity: Yup.string().trim().required('bank city is required'),
  bankState: Yup.string().trim().required('bank state is required'),
  payeeType: Yup.string().trim().required('payee type is required'),
  bankZipCode: Yup.string().trim().required('bank zip code is required'),
  bankAccountNumber: Yup.string()
    .trim()
    .required('bank account number  is required'),
  bankCountry: Yup.string().trim().required('bank country is required'),
  bankSwiftAba: Yup.string().trim().required('bank swift aba is required'),
  additionalInformation: Yup.string()
    .trim()
    .required('additional information required'),
});

const initialValue = {
  fullName: '',
  email: '',
  zipCode: '',
  city: '',
  taxpayer: '',
  currency: '',
  bankName: '',
  bankAddress: '',
  bankCity: '',
  bankState: '',
  payeeType: '',
  bankZipCode: '',
  bankAccountNumber: '',
  bankCountry: '',
  bankSwiftAba: '',
  additionalInformation: '',
};

const activeCurrencyOptions = {
  EURO: 'EURO',
  USD: 'USD',
};

const activeWithdrawerOptions = {
  BANK_TRANSFER: 'BANK_TRANSFER',
  PAYPAL_TRANSFER: 'PAYPAL_TRANSFER',
  CHECK_TRANSFER: 'CHECK_TRANSFER',
  OTHERS: 'OTHERS',
};

const ModelPersonalInformation = () => {
  const activeTab = useRouteMatch();
  const [activeTabWithdrawer, setActiveTabWithdrawer] = useState(
    activeWithdrawerOptions.OTHERS
  );
  const [activeTabCurrency, setActiveTabCurrency] = useState(
    activeCurrencyOptions.USD
  );
  const [payeeInformation, setPayeeInformation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const user = useSelector((state) => state?.user);

  const getPayeeInformation = async () => {
    try {
      const response = await getPayeeModelDetails({ modelId: user.clientId });

      setPayeeInformation(response);
      if (response?.currency === activeCurrencyOptions.EURO) {
        setActiveTabCurrency(activeCurrencyOptions.EURO);
      } else if (response.currency === activeCurrencyOptions.USD) {
        setActiveTabCurrency(activeCurrencyOptions.USD);
      }
    } catch (error) {
      setPayeeInformation(null);
    }
  };

  const onTabChange = (index) => {
    setTab(index);
  };

  const handleUpdate = async (values) => {
    try {
      setLoading(true);

      const formData = new FormData();

      formData.append('fullName', values?.fullName);
      formData.append('email', values?.email);
      formData.append('zipCode', values?.zipCode);
      formData.append('city', values?.city);
      formData.append('taxpayer', values?.taxpayer);
      formData.append('currency', values?.currency);
      formData.append('bankName', values?.bankName);
      formData.append('bankAddress', values?.bankAddress);
      formData.append('bankCity', values?.bankCity);
      formData.append('bankState', values?.bankState);
      formData.append('payeeType', values?.payeeType);
      formData.append('bankZipCode', values?.bankZipCode);
      formData.append('bankAccountNumber', values?.bankAccountNumber);
      formData.append('bankCountry', values?.bankCountry);
      formData.append('bankSwiftAba', values?.bankSwiftAba);
      formData.append('additionalInformation', values?.additionalInformation);
      formData.append('registeredId', user.clientId);

      if (payeeInformation === null) {
        await createPayeeInformation({
          payload: formData,
        });
      } else {
        await updatePayeeInformation({
          payload: formData,
        });
      }
      notification({
        title: 'Payee update',
        message: 'Successfully updated your payee information',
        type: 'success',
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notification({
        title: 'Payee update',
        message: errorFormmatter(error),
        type: 'danger',
      });
    }
  };

  useEffect(() => {
    getPayeeInformation();
  }, []);

  useEffect(() => {
    getPayeeInformation();
  }, [loading]);

  return (
    <FluidContainer>
      <ModelProfileHeader />

      <h1 className="text-primary-100 mb-[59px] text-4xl font-semibold font-['Montserrat'] ">
        Payee information
      </h1>

      <Formik
        enableReinitialize={true}
        validationSchema={payeeInformationSchema}
        initialValues={
          payeeInformation !== null
            ? {
                fullName: payeeInformation?.fullName,
                email: payeeInformation?.email,
                zipCode: payeeInformation?.zipCode,
                city: payeeInformation?.city,
                taxpayer: payeeInformation?.taxpayer,
                currency: payeeInformation?.currency,
                bankName: payeeInformation?.bankName,
                bankAddress: payeeInformation?.bankAddress,
                bankCity: payeeInformation?.bankCity,
                bankState: payeeInformation?.bankState,
                payeeType: payeeInformation?.payeeType,
                bankZipCode: payeeInformation?.bankZipCode,
                bankAccountNumber: payeeInformation?.bankAccountNumber,
                bankCountry: payeeInformation?.bankCountry,
                bankSwiftAba: payeeInformation?.bankSwiftAba,
                additionalInformation: payeeInformation?.additionalInformation,
              }
            : initialValue
        }
        onSubmit={handleUpdate}
      >
        {(props) => {
          return (
            <>
              <section className='flex gap-[31px] w-[100%] justify-between mb-[64px]'>
                <section className='w-[300px]'>
                  <h1 className='w-[100%] flex h-[max-content] items-center gap-[0.4rem] mb-[12px]'>
                    <span className="block w-[max-content] text-zinc-100 text-2xl font-semibold font-['Montserrat'] ">
                      Payee information
                    </span>{' '}
                    <span className='block grow  h-[1.5px] bg-gradient-to-r from-[#413450] to-[#563143]'></span>
                  </h1>
                  <h2 className="w-[300px] text-zinc-400 text-sm font-medium font-['Montserrat'] leading-tight">
                    Completed information will be displayed in the profile and
                    see for users
                  </h2>
                </section>

                <section className='grow'>
                  <div className='flex gap-[30px] mb-[24px]'>
                    <div className='!w-[100%]'>
                      <Input
                        defaultValue={payeeInformation?.fullName}
                        onChange={props.handleChange}
                        name='fullName'
                        placeholder={'Full Name'}
                        className='!w-[100%]'
                      />
                      {props.touched && props.errors?.fullName && (
                        <span className={styles.error}>
                          * {props.errors?.fullName}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className='flex gap-[30px] mb-[24px]'>
                    <div>
                      <Input
                        defaultValue={payeeInformation?.city}
                        onChange={props.handleChange}
                        name='city'
                        placeholder={'City'}
                        className='w-[285px]'
                      />
                      {props.touched && props.errors?.city && (
                        <span className={styles.error}>
                          * {props.errors?.city}
                        </span>
                      )}
                    </div>
                    <div>
                      <Input
                        defaultValue={payeeInformation?.zipCode}
                        onChange={props.handleChange}
                        name='zipCode'
                        placeholder={'Zip code'}
                        className='w-[285px]'
                      />
                      {props.touched && props.errors?.zipCode && (
                        <span className={styles.error}>
                          * {props.errors?.zipCode}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className='flex gap-[30px] mb-[24px]'>
                    <div className='!w-[100%]'>
                      <Input
                        defaultValue={payeeInformation?.taxpayer}
                        onChange={props.handleChange}
                        name='taxpayer'
                        placeholder={'Taxpayer ID/SSN'}
                        className='!w-[100%]'
                      />
                      {props.touched && props.errors?.taxpayer && (
                        <span className={styles.error}>
                          * {props.errors?.taxpayer}
                        </span>
                      )}
                    </div>
                  </div>
                </section>

                <section className='w-[300px]'>
                  <nav className={styles.navigationContainer}>
                    <Link
                      to='/registration-information'
                      onClick={() => onTabChange(0)}
                    >
                      <li
                        className={classNames(
                          styles.listItem,
                          activeTab.path === '/registration-information' &&
                            styles.active
                        )}
                      >
                        {activeTab.path === '/registration-information' && (
                          <ActiveArrow className={styles.navigationArrow} />
                        )}
                        Registration information
                      </li>
                    </Link>

                    <Link
                      to='/personal-information'
                      onClick={() => onTabChange(1)}
                    >
                      <li
                        className={classNames(
                          styles.listItem,
                          activeTab.path === '/personal-information' &&
                            styles.active
                        )}
                      >
                        {activeTab.path === '/personal-information' && (
                          <ActiveArrow className={styles.navigationArrow} />
                        )}
                        Personal info
                      </li>
                    </Link>
                    <Link
                      to='/payee-information'
                      onClick={() => onTabChange(2)}
                    >
                      <li
                        className={classNames(
                          styles.listItem,
                          activeTab.path === '/payee-information' &&
                            styles.active
                        )}
                      >
                        {activeTab.path === '/payee-information' && (
                          <ActiveArrow className={styles.navigationArrow} />
                        )}
                        Payee information
                      </li>
                    </Link>
                  </nav>
                </section>
              </section>

              <section className='flex gap-[31px] w-[100%] justify-between mb-[64px]'>
                <section className='w-[300px]'></section>
                <section className='grow'>
                  <h1 className="text-white text-[17px] mb-[22px] font-semibold font-['Montserrat']  tracking-tight">
                    Withdraw Currency:
                  </h1>
                  <div>
                    <div className='flex w-[100%]'>
                      <Button
                        onClick={() => {
                          setActiveTabCurrency(activeCurrencyOptions.EURO);
                          props.setFieldValue(
                            'currency',
                            activeCurrencyOptions.EURO
                          );
                        }}
                        text={'EUR Euro'}
                        className={`${
                          activeTabCurrency === activeCurrencyOptions.EURO
                            ? 'bg-gradient-to-r from-[#413450] to-[#563143]'
                            : 'bg-primary-200'
                        } w-[50%]  cursor-pointer h-12 flex justify-center items-center !border-none text-center text-white text-[17px] font-semibold font-['Montserrat'] leading-[17px] tracking-tight`}
                      />
                      <Button
                        onClick={() => {
                          setActiveTabCurrency(activeCurrencyOptions.USD);
                          props.setFieldValue(
                            'currency',
                            activeCurrencyOptions.USD
                          );
                        }}
                        text={'USD United States Dollars'}
                        className={`!border-none w-[50%] ${
                          activeTabCurrency === activeCurrencyOptions.USD
                            ? 'bg-gradient-to-r from-[#413450] to-[#563143]'
                            : 'bg-primary-200'
                        }   rounded-[0rem] cursor-pointer flex justify-center items-center h-12 text-center text-white text-[17px] font-semibold font-['Montserrat']  tracking-tight`}
                      />
                    </div>
                    {props.touched && props.errors?.currency && (
                      <span className={styles.error}>
                        * {props.errors?.currency}
                      </span>
                    )}
                  </div>
                </section>
                <section className='w-[300px]'></section>
              </section>

              <section className='flex gap-[31px] w-[100%] justify-between mb-[64px]'>
                <section className='w-[300px]'></section>
                <section className='grow '>
                  <h1 className="text-white text-[17px] mb-[22px] font-semibold font-['Montserrat']  tracking-tight">
                    Withdraw type:
                  </h1>
                  <div>
                    <div className='flex w-[100%] justify-between bg-primary-200'>
                      <Button
                        onClick={() => {
                          setActiveTabWithdrawer(
                            activeWithdrawerOptions.BANK_TRANSFER
                          );
                          props.setFieldValue(
                            'payeeType',
                            activeWithdrawerOptions.BANK_TRANSFER
                          );
                        }}
                        text={'Bank transfer'}
                        className={`w-[50%] ${
                          activeTabWithdrawer ===
                          activeWithdrawerOptions.BANK_TRANSFER
                            ? 'bg-gradient-to-r from-[#413450] to-[#563143]'
                            : 'bg-primary-200'
                        }  cursor-pointer h-12 flex justify-center items-center !border-none text-center text-white text-[17px] font-semibold font-['Montserrat'] leading-[17px] tracking-tight`}
                      />
                      <Button
                        onClick={() => {
                          setActiveTabWithdrawer(
                            activeWithdrawerOptions.PAYPAL_TRANSFER
                          );
                          props.setFieldValue(
                            'payeeType',
                            activeWithdrawerOptions.PAYPAL_TRANSFER
                          );
                        }}
                        text={'PayPal transfer'}
                        className={`w-[50%] ${
                          activeTabWithdrawer ===
                          activeWithdrawerOptions.PAYPAL_TRANSFER
                            ? 'bg-gradient-to-r from-[#413450] to-[#563143]'
                            : 'bg-primary-200'
                        }  cursor-pointer h-12 flex justify-center items-center !border-none text-center text-white text-[17px] font-semibold font-['Montserrat'] leading-[17px] tracking-tight`}
                      />
                      <Button
                        onClick={() => {
                          setActiveTabWithdrawer(
                            activeWithdrawerOptions.CHECK_TRANSFER
                          );
                          props.setFieldValue(
                            'payeeType',
                            activeWithdrawerOptions.CHECK_TRANSFER
                          );
                        }}
                        text={'Check transfer'}
                        className={`w-[50%] ${
                          activeTabWithdrawer ===
                          activeWithdrawerOptions.CHECK_TRANSFER
                            ? 'bg-gradient-to-r from-[#413450] to-[#563143]'
                            : 'bg-primary-200'
                        }  cursor-pointer h-12 flex justify-center items-center !border-none text-center text-white text-[17px] font-semibold font-['Montserrat'] leading-[17px] tracking-tight`}
                      />
                      <Button
                        onClick={() =>
                          setActiveTabWithdrawer(activeWithdrawerOptions.OTHERS)
                        }
                        text={'Other'}
                        className={`w-[50%] ${
                          activeTabWithdrawer === activeWithdrawerOptions.OTHERS
                            ? 'bg-gradient-to-r from-[#413450] to-[#563143]'
                            : 'bg-primary-200'
                        }  cursor-pointer h-12 flex justify-center items-center !border-none text-center text-white text-[17px] font-semibold font-['Montserrat'] leading-[17px] tracking-tight`}
                      />
                    </div>
                    {props.touched && props.errors?.payeeType && (
                      <span className={styles.error}>
                        * {props.errors?.payeeType}
                      </span>
                    )}
                  </div>
                </section>
                <section className='w-[300px]'></section>
              </section>

              <section className='flex gap-[31px] w-[100%] justify-between mb-[64px]'>
                <section className='w-[300px]'>
                  {activeTabWithdrawer ===
                    activeWithdrawerOptions.PAYPAL_TRANSFER && (
                    <>
                      <h1 className='w-[100%] flex h-[max-content] items-center gap-[0.4rem] mb-[12px]'>
                        <span className="block w-[max-content] text-zinc-100 text-2xl font-semibold font-['Montserrat'] ">
                          PayPal information
                        </span>{' '}
                        <span className='block grow  h-[1.5px] bg-gradient-to-r from-[#413450] to-[#563143]'></span>
                      </h1>
                      <h2 className="w-[300px] text-zinc-400 text-sm font-medium font-['Montserrat'] leading-tight">
                        PayPal Account
                      </h2>
                    </>
                  )}

                  {activeTabWithdrawer === activeWithdrawerOptions.OTHERS && (
                    <h1 className='w-[100%] flex h-[max-content] items-center gap-[0.4rem] mb-[12px]'>
                      <span className="block w-[max-content] text-zinc-100 text-2xl font-semibold font-['Montserrat'] ">
                        Additionalpayment info:
                      </span>{' '}
                      <span className='block grow  h-[1.5px] bg-gradient-to-r from-[#413450] to-[#563143]'></span>
                    </h1>
                  )}

                  {activeTabWithdrawer ===
                    activeWithdrawerOptions.BANK_TRANSFER && (
                    <>
                      <h1 className='w-[100%] flex h-[max-content] items-center gap-[0.4rem] mb-[12px]'>
                        <span className="block w-[max-content] text-zinc-100 text-2xl font-semibold font-['Montserrat'] ">
                          Bank information
                        </span>{' '}
                        <span className='block grow  h-[1.5px] bg-gradient-to-r from-[#413450] to-[#563143]'></span>
                      </h1>
                      <h2 className="w-[300px] text-zinc-400 text-sm font-medium font-['Montserrat'] leading-tight">
                        Completed information will be displayed in the profile
                        and see for users
                      </h2>
                    </>
                  )}

                  {activeTabWithdrawer ===
                    activeWithdrawerOptions.CHECK_TRANSFER && (
                    <h1 className='w-[100%] flex h-[max-content] items-center gap-[0.4rem] mb-[12px]'>
                      <span className="block w-[max-content] text-zinc-100 text-2xl font-semibold font-['Montserrat'] ">
                        Check information
                      </span>{' '}
                      <span className='block grow  h-[1.5px] bg-gradient-to-r from-[#413450] to-[#563143]'></span>
                    </h1>
                  )}
                </section>
                <section className='grow'>
                  {activeTabWithdrawer ===
                    activeWithdrawerOptions.PAYPAL_TRANSFER && (
                    <div className='!w-[100%]'>
                      <Input
                        defaultValue={props.values?.email}
                        onChange={props.handleChange}
                        name='email'
                        placeholder={'rodion+model@modelnet.club'}
                        className='!w-[100%]'
                      />
                      {props.touched && props.errors?.email && (
                        <span className={styles.error}>
                          * {props.errors?.email}
                        </span>
                      )}
                    </div>
                  )}

                  {activeTabWithdrawer ===
                    activeWithdrawerOptions.CHECK_TRANSFER && (
                    <div>
                      <Input
                        defaultValue={props.values?.email}
                        onChange={props.handleChange}
                        name='email'
                        placeholder={'Check payable to'}
                        className='!w-[100%]'
                      />
                      {props.touched && props.errors?.email && (
                        <span className={styles.error}>
                          * {props.errors?.email}
                        </span>
                      )}
                    </div>
                  )}

                  {activeTabWithdrawer === activeWithdrawerOptions.OTHERS && (
                    <div>
                      <Textarea
                        defaultValue={props.values?.additionalInformation}
                        onChange={props.handleChange}
                        name='additionalInformation'
                        placeholder={'Enter your payment info here'}
                      />
                      {props.touched && props.errors?.additionalInformation && (
                        <span className={styles.error}>
                          * {props.errors?.additionalInformation}
                        </span>
                      )}
                    </div>
                  )}

                  {activeTabWithdrawer ===
                    activeWithdrawerOptions.BANK_TRANSFER && (
                    <>
                      <div className='flex gap-[30px] mb-[24px]'>
                        <div className='block w-[285px]'>
                          <Input
                            className='!w-[100%]'
                            defaultValue={props.values?.bankName}
                            onChange={props.handleChange}
                            name='bankName'
                            placeholder={'Bank Name'}
                          />
                          {props.touched && props.errors?.bankName && (
                            <span className={styles.error}>
                              * {props.errors?.bankName}
                            </span>
                          )}
                        </div>
                        <div className='block w-[285px]'>
                          <Input
                            className='!w-[100%]'
                            defaultValue={props.values?.bankAddress}
                            onChange={props.handleChange}
                            name='bankAddress'
                            placeholder={'Bank Address'}
                          />
                          {props.touched && props.errors?.bankAddress && (
                            <span className={styles.error}>
                              * {props.errors?.bankAddress}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className='flex gap-[30px] mb-[24px]'>
                        <div className='block w-[285px]'>
                          <Input
                            className='!w-[100%]'
                            placeholder={'Bank City'}
                            defaultValue={props.values?.bankCity}
                            onChange={props.handleChange}
                            name='bankCity'
                          />
                          {props.touched && props.errors?.bankCity && (
                            <span className={styles.error}>
                              * {props.errors?.bankCity}
                            </span>
                          )}
                        </div>
                        <div className='block w-[285px]'>
                          <Input
                            className='!w-[100%]'
                            placeholder={'Bank State'}
                            defaultValue={props.values?.bankState}
                            onChange={props.handleChange}
                            name='bankState'
                          />
                          {props.touched && props.errors?.bankState && (
                            <span className={styles.error}>
                              * {props.errors?.bankState}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className='flex gap-[30px] mb-[24px]'>
                        <div className='block w-[285px]'>
                          <Input
                            className='!w-[100%]'
                            placeholder={'Bank ZIP'}
                            defaultValue={props.values?.bankZipCode}
                            onChange={props.handleChange}
                            name='bankZipCode'
                          />
                          {props.touched && props.errors?.bankZipCode && (
                            <span className={styles.error}>
                              * {props.errors?.bankZipCode}
                            </span>
                          )}
                        </div>
                        <div className='block w-[285px]'>
                          <Input
                            className='!w-[100%]'
                            placeholder={'Bank Country'}
                            defaultValue={props.values?.bankCountry}
                            onChange={props.handleChange}
                            name='bankCountry'
                          />
                          {props.touched && props.errors?.bankCountry && (
                            <span className={styles.error}>
                              * {props.errors?.bankCountry}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className='flex gap-[30px] mb-[24px]'>
                        <div className='block w-[285px]'>
                          <Input
                            className='!w-[100%]'
                            placeholder={'Bank account number'}
                            defaultValue={props.values?.bankAccountNumber}
                            onChange={props.handleChange}
                            name='bankAccountNumber'
                          />
                          {props.touched && props.errors?.bankAccountNumber && (
                            <span className={styles.error}>
                              * {props.errors?.bankAccountNumber}
                            </span>
                          )}
                        </div>
                        <div className='block w-[285px]'>
                          <Input
                            className='!w-[100%]'
                            placeholder={'Bank SWIFT-BIC/ABA'}
                            defaultValue={props.values?.bankSwiftAba}
                            onChange={props.handleChange}
                            name='bankSwiftAba'
                          />
                          {props.touched && props.errors?.bankSwiftAba && (
                            <span className={styles.error}>
                              * {props.errors?.bankSwiftAba}
                            </span>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </section>
                <section className='w-[300px]'></section>
              </section>

              <section className='flex gap-[31px] w-[100%] justify-between mb-[115px]'>
                <section className='w-[300px]'></section>
                <section className='grow'>
                  <Button
                    icon={loading && Spinner}
                    type='submit'
                    onClick={props.handleSubmit}
                    text={payeeInformation === null ? 'Create' : 'Update'}
                    className={classNames(
                      "w-[190px] flex justify-center items-center h-12 text-center border-[0rem] bg-primary-400 text-white text-base font-medium font-['Montserrat']"
                    )}
                  />
                </section>

                <section className='w-[300px]'></section>
              </section>
            </>
          );
        }}
      </Formik>
    </FluidContainer>
  );
};

export default ModelPersonalInformation;
