import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import styles from './tab.module.scss';

const Tab = ({
  containerClass,
  items,
  defaultKeyIndex,
  position,
  panelClass,
  tabClass,
  onIndexChange,
}) => {
  const [active, setActive] = useState(
    defaultKeyIndex ? items[defaultKeyIndex] : items[0]
  );

  const getPosition = () => {
    if (position === 'center') {
      return 'center';
    }

    if (position === 'left') {
      return 'flex-start';
    }
  };

  const panelStyles = {
    justifyContent: getPosition(),
  };

  const handleClick = (item, index) => {
    setActive(item);

    if (typeof onIndexChange !== 'function') return;
    onIndexChange(index);
  };

  useEffect(() => {
    setActive(items[defaultKeyIndex]);
  }, [defaultKeyIndex]);

  return (
    <section className={containerClass}>
      <div
        className={classNames(styles.tabPanel, panelClass)}
        style={panelStyles}
      >
        {items?.map((item, index) => (
          <span
            className={classNames(
              styles.tab,
              tabClass,
              active.key === item.key && styles.active
            )}
            key={item.key}
            onClick={() => handleClick(item, index)}
          >
            {typeof item.key === 'function' ? item?.key() : item?.key}
          </span>
        ))}
      </div>

      <div>
        {typeof active.children === 'function' ? (
          <>{active.children()}</>
        ) : (
          <>{active.children}</>
        )}
      </div>
    </section>
  );
};

export default Tab;
