import axios from 'axios';
const nodeServerBaseUrl = process.env.REACT_APP_NODE_URL;

/**member profile */
export const getMemberProfile = async modelId => {
	return (await axios.get(`MemberProfile/${modelId}/GetMemberByMemberId`))?.data?.data;
};

export const getMemberBrowerHistory = async (modelId, pageNumber) => {
	return (await axios.get(`MemberProfile/${modelId}/MemberBrowserHistory?PageNumber=${pageNumber}`))?.data;
};

export const getMemberFavoriteModel = async (modelId, pageNumber) => {
	return (await axios.get(`MemberProfile/${modelId}/FavoriteMember?PageNumber=${pageNumber}`))?.data;
};

export const createPrivatChat = async data => {
	return axios.post(`${nodeServerBaseUrl}/api/v1/chat`, data);
};


export const deleteMemberAccount = async (memberid) => {
  return axios.delete(`MemberProfile/${memberid}/DeleteMemberByMemberId`);
};

/**premium models */
export const getPremiumModels = async () => {
  return (await axios.get(`/MemberProfile/GetAllModelOnPremiumService`))?.data;
};
