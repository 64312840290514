import jwtDecode from 'jwt-decode';

export const decodeJwt = token => {
	let decoded;
	if (typeof token !== 'string' || !token) {
		return false;
	} else {
		decoded = jwtDecode(token);
	}
	return decoded;
};

export default decodeJwt;
