import FormSubscribe from '../../components/subscribe/Subscribe';
import subscribeImage from '../../.././images/trashimg/subscribe.jpg';

const Subscribe = () => {
	return (
		<section className='subscribe-row subscribe_newsletter'>
			<div className='container'>
				<div className='subscribe'>
					<div className='subscribe-title'>
						<strong>Subscribe</strong>
						to our newsletter
					</div>
					<FormSubscribe />
				</div>
				<div className='subscribe--advertising'>
					<img src={subscribeImage} alt='subscribe' />
				</div>
			</div>
		</section>
	);
};

export default Subscribe;
