import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { useMemo, useCallback } from 'react';
import events from './events';
import styles from './eventCalender.module.scss';
import { MdOutlineAccessTime } from 'react-icons/md';
import classNames from 'classnames';

const localizer = momentLocalizer(moment);

const EventCalender = ({ toolBar }) => {
  const dayPropGetter = useCallback(
    (date) => ({
      style: {
        backgroundColor: '#B87693',
        color: 'white',
        minHeight: 'max-content',
        overflow: 'scroll',
      },
    }),
    []
  );

  const { components, defaultDate, views } = useMemo(
    () => ({
      components: {
        timeGutterHeader: () => {
          return <span className={styles.weekTitle}>Weeks</span>;
        },
        event: (props) => {
          return (
            <div>
              <span className={styles.eventTitle}>{props.title}</span>
              <div className={styles.eventTimeContainer}>
                <MdOutlineAccessTime />
                <span className={styles.eventTime}>
                  {`${moment(props.event.start).format('LT')}`} -{' '}
                  {`${moment(props.event.end).format('LT')}`}
                </span>
              </div>
            </div>
          );
        },
        toolbar: toolBar,
        eventWrapper: (props) => {
          return <div>{props.children}</div>;
        },

        week: {
          header: (props) => {
            return (
              <span
                className={classNames(
                  styles.headerLabel,
                  moment()
                    .format('ddd')
                    .includes(moment(props.date).format('ddd')) && styles.active
                )}
              >
                {props.label}
              </span>
            );
          },
        },
      },
      defaultDate: new Date(2015, 3, 13),
    }),
    []
  );

  return (
    <div className={styles.main}>
      <Calendar
        eventPropGetter={dayPropGetter}
        defaultDate={defaultDate}
        localizer={localizer}
        startAccessor='start'
        endAccessor='end'
        defaultView='week'
        events={events}
        components={components}
        views={views}
        selectable
      />
    </div>
  );
};

export default EventCalender;
