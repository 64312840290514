import styles from './styles.module.scss';
import Button from '../../custom/btn/Btn';
import { ReactComponent as DeleteIcon } from '../../images/svg/delete.svg';
import classNames from 'classnames';
import ModelEditPopup from './modelEditPopup/ModelEditPopup';
import Spinner from '../../custom/spinner/Spinner';
import { useState, useEffect, useCallback } from 'react';
import errorFormmatter from '../../utils/errorFormatter';
import notification from '../../utils/notification';
import { useSelector } from 'react-redux';
// import PhotoPreview from '../PhotoPreview/ProfilePreview';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FileUpload from '../../custom/fileUpload/FileUpload';
import {
  getAlbum,
  getAlbumPhoto,
  updateAlbum,
  removeFromPhotoSets,
} from '../../request/model';
import FluidContainer from '../../custom/fluidContainer/FluidContainer';
import ModelProfileHeader from '../../custom/modelProfileHeader/ModelProfileHeader';
import { ReactComponent as CreateAlbumIcon } from '../../images/svg/albumIcon.svg';
import SelectAll from '../../custom/selectAll/SelectAll';
import HeaderToolTip from '../../custom/headerToolTip/HeaderToolTip';
import CreateAlbum from './createAlbum/CreateAlbum';
import PhotoPreview from './PhotoPreview/ProfilePreview';

const CreateAlbumSchema = Yup.object().shape({
  title: Yup.string().trim().required('title is required'),
  description: Yup.string().trim().required('description is required'),
  albumCover: Yup.string().required('album cover is required'),
});

const ModelAlbum = ({ isSelectedAll, photoSetOptions, newPhotoSet }) => {
  const [openNewAlbum, setOpenNewAlbum] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openEditPhoto, setOpenEditPhoto] = useState(false);
  const [photoToEdit, setPhotoToEdit] = useState({});
  const [selectedPhotoSetIds, setSelectedPhotoSetIds] = useState([]);
  const [currentEditAlbum, setCurrentEditAlbum] = useState({});
  const [permission, setPermission] = useState({
    isHidden: currentEditAlbum?.isHidden ?? false,
    isOnDemand: currentEditAlbum?.isOnDemand ?? false,
    isFreeForAll: currentEditAlbum?.isFreeForAll ?? false,
    isFreeForFanClub: currentEditAlbum?.isFreeForFanClub ?? false,
  });

  const [albums, setAlbum] = useState([]);
  const clientId = useSelector((state) => state?.user?.clientId);

  const handlePermission = (name) => {
    setPermission({
      ...permission,
      [name]: !permission[name],
    });
  };

  const handleClose = () => {
    // setIsOpenUploadPhotoModal(false);
    // setIsOpenEditModelPhoto(false);
    // setPhotoToEdit({});
  };

  const getAlbums = async () => {
    try {
      const response = await getAlbum({ modelId: clientId });
      setAlbum(response.data);
    } catch (error) {
      setAlbum([]);
    }
  };

  const handlePhotoSetSelection = (photoId) => {
    const id = selectedPhotoSetIds.find((id) => id === photoId);
    if (id) {
      const filteredPhotoIds = selectedPhotoSetIds.filter(
        (id) => id !== photoId
      );
      setSelectedPhotoSetIds(filteredPhotoIds);
    } else {
      setSelectedPhotoSetIds((prev) => [...prev, photoId]);
    }
  };

  const handleIsSelected = (photoId) => {
    const id = selectedPhotoSetIds.find((id) => id === photoId);
    return id ? true : false;
  };

  useEffect(() => {
    getAlbums();
  }, []);

  useEffect(() => {
    getAlbums();
  }, [loading]);

  useEffect(() => {
    getAlbums();
  }, [openNewAlbum]);

  useEffect(() => {
    setPermission({
      ...permission,
      isHidden: currentEditAlbum?.isHidden ?? false,
      isOnDemand: currentEditAlbum?.isOnDemand ?? false,
      isFreeForAll: currentEditAlbum?.isFreeForAll ?? false,
      isFreeForFanClub: currentEditAlbum?.isFreeForFanClub ?? false,
    });
  }, [currentEditAlbum]);

  useEffect(() => {
    if (!albums) return;
    if (isSelectedAll) {
      const selectedIds = albums?.map((photoSet) => photoSet?.photoSetId);
      setSelectedPhotoSetIds(selectedIds);
    } else {
      setSelectedPhotoSetIds([]);
    }
  }, [isSelectedAll]);

  return (
    <FluidContainer>
      <ModelProfileHeader className={styles.subHeader} />
      <HeaderToolTip
        data-total={albums.length}
        text={'Albums'}
        classNames={styles.headerToottip}
      />
      <section className={styles.navContainer}>
        <SelectAll
          labelText='Select All'
          // checked={isSelectedAll}
          // onChange={handleSelectAll}
        />

        <nav className={styles.nav}>
          {/* {loading && <Spinner />}
          {tab === 0 && (
            <DropDown
              onChange={handleSelectChange}
              placeholder='select state'
              options={fanClubPhotoSets.map((photoSet) => {
                return {
                  label:
                    photoSet?.photoSetName?.length > 14
                      ? `${photoSet?.photoSetName.slice(0, 14)}...`
                      : photoSet?.photoSetName,
                  value: photoSet,
                };
              })}
              className={styles.selectContainer}
              dropElement={({ props, state }) => (
                <div className={styles.dropDownRender}>
                  {selectedPhotoSet !== null
                    ? selectedPhotoSet?.photoSetName?.length > 14
                      ? `${selectedPhotoSet?.photoSetName.slice(0, 14)}...`
                      : selectedPhotoSet?.photoSetName
                    : 'Select'}
                  <DropIcon />
                </div>
              )}
            />
          )} */}
          <Button
            onClick={() => setOpenNewAlbum(true)}
            className={styles.createPhotoSetButton}
            text='Create Photo Sets'
            icon={CreateAlbumIcon}
          />
        </nav>
      </section>
      <div>
        {albums.length > 0 ? (
          albums.map((album, index) => {
            return (
              <EditAlbum
                albumOptions={albums}
                key={index}
                handleIsSelected={handleIsSelected}
                handlePhotoSetSelection={handlePhotoSetSelection}
                currentEditAlbum={currentEditAlbum}
                handleSetCurrentEditAlbum={setCurrentEditAlbum}
                handlePermission={handlePermission}
                permission={permission}
                handleClose={handleClose}
                album={{ ...album }}
                handleSetLoading={setLoading}
                loading={loading}
                photoToEdit={photoToEdit}
                openEditPhoto={openEditPhoto}
                setPhotoToEdit={setPhotoToEdit}
                setOpenEditPhoto={setOpenEditPhoto}
              />
            );
          })
        ) : (
          <div className={styles.emptyFanClubPhotoSetPhotos}>
            {' '}
            No photo set available
          </div>
        )}
      </div>
      <CreateAlbum
        open={openNewAlbum}
        handleClose={() => setOpenNewAlbum(false)}
      />
    </FluidContainer>
  );
};

const EditAlbum = ({
  handleClose,
  handlePermission,
  album,
  permission,
  handleSetCurrentEditAlbum,
  currentEditAlbum,
  handleSetLoading,
  handlePhotoSetSelection,
  handleIsSelected,
  setPhotoToEdit,
  setOpenEditPhoto,
  openEditPhoto,
  albumOptions,
  photoToEdit,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [albumPhotos, setAlbumPhotos] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const clientId = useSelector((state) => state?.user?.clientId);

  const getAlbumPhotos = async (albumId) => {
    try {
      const response = await getAlbumPhoto({
        modelId: clientId,

        albumId: albumId,
      });

      setAlbumPhotos(response?.data[0]?.photosInAlbum ?? []);
    } catch (error) {
      setAlbumPhotos([]);
    }
  };

  const handleUpdateAlbum = async (values) => {
    try {
      const payload = new FormData();

      payload.append('registeredId', clientId);
      payload.append('albumName', values.title);
      payload.append('albumId', album?.albumId);
      payload.append('isHidden', permission.isHidden);
      payload.append('IsOnDemand', permission.isOnDemand);
      payload.append('IsFreeForAll', permission.isFreeForAll);
      payload.append('IsFreeForFanClub', permission.isFreeForFanClub);
      payload.append('albumDescription', values.description);
      payload.append('addAlbumCoverImage', values.albumCover);
      setIsLoading(true);
      handleSetLoading(true);
      const response = await updateAlbum({ payload });
      setIsLoading(false);
      handleSetLoading(false);
      handleClose();
      notification({
        message: response?.message ?? 'Album saved successfully',
      });
    } catch (error) {
      notification({
        message: errorFormmatter(error) ?? 'Album failed to save',
        type: 'danger',
      });
      setIsLoading(false);
      handleSetLoading(false);
    }
  };

  const deleteAlbumPhoto = async (id) => {
    try {
      setIsLoading(true);
      const response = await removeFromPhotoSets({
        modelId: clientId,
        photoId: id,
      });
      setIsLoading(false);
      notification({
        message:
          response?.message ?? 'Fan club photo set photo deleted successfully',
      });
    } catch (error) {
      notification({
        message:
          errorFormmatter(error) ?? 'Fan club photo set photo failed to delete',
        type: 'danger',
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAlbumPhotos(album?.albumId);
  }, []);

  useEffect(() => {
    getAlbumPhotos(album?.albumId);
  }, [openEditPhoto, isLoading]);

  return (
    <>
      <section className={styles.beachSessionContainer}>
        <div className={styles.beachSessionDetailContainer}>
          <img src={album?.albumCoverURL} />

          <div>
            <h1 className={styles.beachTitle}>{album?.albumName}</h1>
            <ul className={styles.beachDetails}>
              <li>{albumPhotos?.length ?? 0} photos</li>
            </ul>
          </div>
        </div>
        <div className={styles.beachSessionActionContainer}>
          {/* <input
            type='checkbox'
            className={styles.checkbox}
            onChange={() => handlePhotoSetSelection(photoSet?.photoSetId)}
            checked={handleIsSelected?.(photoSet?.photoSetId)}
          /> */}
          <Button
            text='Edit photo set'
            icon={DeleteIcon}
            onClick={() => {
              handleSetCurrentEditAlbum(album);
              setOpenEditModal(!openEditModal);
            }}
          />
        </div>
      </section>
      <>
        {openEditModal && (
          <section className={styles.nyEditWrapperContainer}>
            <Formik
              enableReinitialize={true}
              validationSchema={CreateAlbumSchema}
              initialValues={{
                isHidden: currentEditAlbum?.isHidden,
                title: currentEditAlbum?.albumName,
                description: currentEditAlbum?.albumDescription,
                albumCover: currentEditAlbum?.albumCoverURL,
              }}
              onSubmit={handleUpdateAlbum}
            >
              {(props) => {
                return (
                  <section className={styles.nyContainer}>
                    <FileUpload
                      handleFileChange={(file) => {
                        props.setFieldValue('albumCover', file);
                      }}
                      renderElement={
                        <div className={styles.albumCoverImageContainer}>
                          {typeof props.values.albumCover === 'object' ? (
                            <img
                              src={URL.createObjectURL(props.values.albumCover)}
                            />
                          ) : (
                            <img src={props.values.albumCover} />
                          )}
                          {props.touched && props.errors?.albumCover && (
                            <span className={styles.error}>
                              * {props.errors?.albumCover}
                            </span>
                          )}
                        </div>
                      }
                    />

                    <div className={styles.nyDetailWrapper}>
                      <section className={styles.nyTopContainer}>
                        <h1 className={styles.nyTitle}>{props.values.title}</h1>
                        <div className={styles.actionButtonContainer}>
                          {isLoading && <Spinner />}
                          <Button
                            text='Cancel'
                            className={styles.cancelButton}
                            onClick={() => setOpenEditModal(!openEditModal)}
                          />
                          <Button
                            text='Save'
                            className={styles.saveButton}
                            onClick={props.handleSubmit}
                          />
                        </div>
                      </section>

                      <div className={styles.nyActionContainer}>
                        <label className={styles.nyLabel}>
                          <span> Permissions</span>
                          <ul className={styles.nyPermissions}>
                            <li
                              onClick={() => handlePermission('isFreeForAll')}
                              className={classNames(
                                styles.nyPermission,
                                permission.isFreeForAll && styles.nyActive
                              )}
                            >
                              Free for All
                            </li>
                            <li
                              onClick={() =>
                                handlePermission('isFreeForFanClub')
                              }
                              className={classNames(
                                styles.nyPermission,
                                permission.isFreeForFanClub && styles.nyActive
                              )}
                            >
                              Free for Fan Club
                            </li>
                            <li
                              onClick={() => handlePermission('isOnDemand')}
                              className={classNames(
                                styles.nyPermission,
                                permission.isOnDemand && styles.nyActive
                              )}
                            >
                              On Demand
                            </li>
                            <li
                              onClick={() => handlePermission('isHidden')}
                              className={classNames(
                                styles.nyPermission,
                                permission.isHidden && styles.nyActive
                              )}
                            >
                              Hidden
                            </li>
                          </ul>
                          {props.touched && props.errors?.isHidden && (
                            <span className={styles.error}>
                              * {props.errors?.isHidden}
                            </span>
                          )}
                        </label>
                      </div>

                      <label className={styles.nyLabel}>
                        <span>Title</span>
                        <input
                          defaultValue={currentEditAlbum?.albumName}
                          className={styles.input}
                          placeholder='Title'
                          name='title'
                          onChange={props.handleChange}
                        />
                        {props.touched && props.errors?.title && (
                          <span className={styles.error}>
                            * {props.errors?.title}
                          </span>
                        )}
                      </label>

                      <label className={styles.nyLabel}>
                        <span>Description</span>
                        <textarea
                          defaultValue={currentEditAlbum?.albumDescription}
                          name='description'
                          onChange={props.handleChange}
                          className={styles.textarea}
                          placeholder='Album description'
                        ></textarea>
                        {props.touched && props.errors?.description && (
                          <span className={styles.error}>
                            * {props.errors?.description}
                          </span>
                        )}
                      </label>
                    </div>
                  </section>
                );
              }}
            </Formik>
            <section className={styles.nySetupContainer}>
              {albumPhotos.length > 0 ? (
                <section className={styles.photoContainer}>
                  {albumPhotos?.map((photo) => (
                    <PhotoPreview
                      key={photo?.imageURL}
                      src={photo?.imageURL}
                      photoId={photo?.photoId}
                      photo={photo}
                      deletePhoto={() => deleteAlbumPhoto(photo?.photoId)}
                      handleOpenEditPhotoModal={() => {
                        setPhotoToEdit(photo);
                        setOpenEditPhoto(true);
                      }}
                      handlePhotoSelection={handlePhotoSetSelection}
                      isSelected={handleIsSelected}
                    />
                  ))}
                </section>
              ) : (
                <div className={styles.emptyFanClubPhotoSetPhotos}>
                  No available photos
                </div>
              )}
            </section>

            <ModelEditPopup
              albumOptions={albumOptions}
              open={openEditPhoto}
              onClose={() => setOpenEditPhoto(false)}
              photo={{ ...photoToEdit, albumId: album.albumId }}
              key={photoToEdit?.imageURL}
            />
          </section>
        )}
      </>
    </>
  );
};

export default ModelAlbum;
