import { useState } from 'react';
import FluidContainer from '../../../custom/fluidContainer/FluidContainer';
import ModelProfileHeader from '../../../custom/modelProfileHeader/ModelProfileHeader';
import Tab from '../../../custom/Tabs/Tabs';
import VideoSetting from './VideoSetting';
import SoundSetting from './SoundSetting';
import GeneralSetting from './GeneralSetting';
import TipContainer from './Tip';

const BroadcastSetting = () => {
  const [currentTab, setCurrentTab] = useState(0);

  return (
    <FluidContainer>
      <ModelProfileHeader />
      <main>
        <h1 className="text-primary-100 text-4xl font-semibold font-['Montserrat'] mb-[88px]">
          Broadcast Settings
        </h1>

        <Tab
          panelClass={'mb-[40px]'}
          containerClass={'mb-[110px]'}
          tabClass={
            "text-primary-200 text-white text-sm font-semibold font-['Montserrat'] uppercase "
          }
          position='left'
          onIndexChange={(tab) => {
            setCurrentTab(tab);
          }}
          defaultKeyIndex={currentTab}
          items={[
            {
              key: 'General Settings',
              children: <GeneralSetting />,
            },
            {
              key: 'video settings',
              children: <VideoSetting />,
            },
            {
              key: 'Sound settings',
              children: <SoundSetting />,
            },

            {
              key: 'Tip menu settings',
              children: <TipContainer />,
            },
          ]}
        />
      </main>
    </FluidContainer>
  );
};



export default BroadcastSetting;
