import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import notification from '../../utils/notification';

export const getClubProfileStatus = createAsyncThunk('clubProfileStatus', async modelId => {
	try {
		const { data } = await axios.get(`/ClubProfile/${modelId}/GetClubVerificationStatus`);
		return data?.data;
	} catch (error) {
		const errorMessage = error?.response?.data?.message ?? error?.message;
		throw new Error(errorMessage);
	}
});

const clubSlice = createSlice({
	name: 'club',
	initialState: {
		isPhotoUploaded: false,
		isVerified: false,
		isProfileCompleted: false,
		loading: false,
		error: null,
	},

	extraReducers: builder => {
		builder.addCase(getClubProfileStatus.fulfilled, (state, action) => {
			const { isCompletedProfile, isVerified, hasUploadVerify } = action?.payload;
			state.isPhotoUploaded = hasUploadVerify;
			state.isVerified = isVerified;
			state.isProfileCompleted = isCompletedProfile;
			state.loading = false;
		});

		builder.addCase(getClubProfileStatus.pending, state => {
			state.loading = true;
		});

		builder.addCase(getClubProfileStatus.rejected, (state, action) => {
			const errorMessage = action?.error?.message;
			state.loading = false;
			state.error = errorMessage;

			notification({
				title: 'Club Profile Status Error',
				message: errorMessage,
				type: 'danger',
			});
		});
	},
});

export default clubSlice.reducer;
