import React, { Component } from 'react';
import Cookies from 'universal-cookie';

class Over extends Component {
    constructor(props) {
        super(props);
        this.state = {isToggleOn: true};
        this.handleClick = this.handleClick.bind(this);

        this.state = {class: "off", label: "Нажми"};
    }
    handleClick() {
        const cookies = new Cookies();
        cookies.set('age', 'over18', { path: '/' });
        let className = (this.state.class==="off")?"on":"off";
        this.setState({class: className});
        
        document.querySelector('.age-check-logo').classList.add('show');
        let slides = document.querySelectorAll('#slides .slide');
        let currentSlide = 0;
        setInterval(nextSlide,25);
        
        function nextSlide() {
            slides[currentSlide].className = 'slide';
            currentSlide = (currentSlide+1)%slides.length;
            slides[currentSlide].className = 'slide showing';
        }
        setTimeout("window.location.reload(false)", 1500);
    }
    render() {
        return (
            <div className="age-check-checkblock-wrapper">
                <div onClick={this.handleClick} className="age-check-checkblock-Over18"></div>
                <div onClick={this.handleClick} className="age-check-checkblock-overBtn">I`m over</div>
            </div>
        );
    }
}
function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([$?*|{}\\])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}
let name = 'age';
let Over18 = getCookie(name);
export const OVER_18 = Over18;
export default Over;
