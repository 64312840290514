import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getPhoto } from '../../../../request/model';
import { useLocation } from 'react-router-dom';
import useGridDisplay from '../../../../hooks/useGridDisplay';
import { useState, useEffect, useRef } from 'react';

const Photos = () => {
  const params = useLocation();
  const [photos, setPhotos] = useState([]);
  const { renderGallery } = useGridDisplay();
  const firstGalleryRef = useRef(null);
  const mainRef = useRef(null);
  const urlSearchParams = new URLSearchParams(params.search);
  const modelId = urlSearchParams.get('model');

  const getPhotos = async () => {
    try {
      const response = await getPhoto({ modelId: modelId });

      setPhotos(response?.data);
    } catch (error) {}
  };

  useEffect(() => {
    getPhotos();
  }, []);

  useEffect(() => {
    getPhotos();
  }, [modelId]);

  console.log(firstGalleryRef);

  return photos.length > 0 ? (
    <div ref={mainRef} className='model-photos'>
      {renderGallery({
        createCustomLayout: customLayout(
          photos?.slice(0, 3)?.map((item) => {
            return {
              ...item,
              src: item?.imageURL,
            };
          }),
          mainRef.current,
          layoutOne,
          'first-row-model-photos'
        ),
      })}

      {renderGallery({
        createCustomLayout: customLayout(
          photos?.slice(3, 5)?.map((item) => {
            return {
              ...item,
              src: item?.imageURL,
            };
          }),
          mainRef.current,
          layoutTwo,
          'second-row-model-photos'
        ),
      })}

      {renderGallery({
        createCustomLayout: customLayout(
          photos?.slice(4, photos.length)?.map((item) => {
            return {
              ...item,
              src: item?.imageURL,
            };
          }),
          mainRef.current,
          layoutThree,
          'third-row-model-photos'
        ),
      })}

      {/* {renderGallery({
        createCustomLayout: customLayout(
          photo?.slice(0, 3)?.map((item) => {
            return {
              ...item,
              src: item?.imageURL,
            };
          }),
          mainRef.current,
          layoutFourth,
          'fourth-row-model-photos'
        ),
      })} */}
    </div>
  ) : null;
};

export default Photos;

// const layoutFourth = (image = []) => {
//   return `

//         <div class='model-photos-column model-photos-column1'>
//           <img

//              src=${image[0]?.imageURL}
//             alt=''
//           />
//           <img

//              src=${image[0]?.imageURL}
//             alt=''
//           />
//         </div>
//         <div class='model-photos-column model-photos-column1'>
//           <img

//              src=${image[0]?.imageURL}
//             alt=''
//           />
//           <img

//              src=${image[0]?.imageURL}
//             alt=''
//           />
//         </div>
//         <div class='model-photos-column model-photos-column1'>
//           <img

//             src=${image[0]?.imageURL}
//             alt=''
//           />
//           <img

//             src=${image[0]?.imageURL}
//             alt=''
//           />
//         </div>

//      `;
// };

const layoutThree = (image = []) => {
  return `
        
    ${
      image[0] || image[1]
        ? `
        ${
          image[0]
            ? `
        <div class='model-photos-column model-photos-column1'>
          <div class='model-photos-content1'>
            Do you think you like a challenge? Game on my room!
          </div>
        
            <img
               src=${image[0]?.imageURL}
              alt=''
            />
          
        </div>
        `
            : ''
        }

       
        
        <div class='model-photos-column model-photos-column1'>
          <div class='model-photos-content2'>
            <div class='model-photos-content2--title'>
              MEET ME
              <br />
              IN MY FANCLUB
            </div>
            <div class='model-photos-content2-count'>
              <div class='model-photos-content2-count--number'>+124</div>
              <div class='model-photos-content2-count--text'>
                <span>PREMIUM</span>
                PHOTOS AND VIDEOS
              </div>
            </div>
            <ul>
              <li>— Exclusive attention in chat</li>
              <li>— Exclusive emoticons on chat</li>
              <li>— Extra Awards points</li>
              <li>— Private messages for half price</li>
            </ul>
            <div class='model-photos-content2--btn btn btn__red'>
              Be me Fan
            </div>
          </div>
        </div>
         ${
           image[1]
             ? `<div class='model-photos-column model-photos-column1'>
          <img
             src=${image[1]?.imageURL}
            alt=''
          />
        </div>`
             : ''
         }
         `
        : ''
    }
       
      
     `;
};

const layoutTwo = (image = []) => {
  return `
        ${
          image[0] || image[1] || image[2]
            ? `
            ${
              image[0]
                ? `<div class='model-photos-column1'>
          
            
            <img
             src=${image[0]?.imageURL}
              alt=''
            />
          
        </div>`
                : ''
            }
            
${
  image[1]
    ? `<div class='model-photos-column1'>
          <img
             src=${image[1]?.imageURL}
            alt=''
          />
        </div>`
    : ''
}

        ${
          image[2]
            ? `  <div class='model-photos-column1'>
          
           
            <img
               src=${image[2]?.imageURL}
              alt=''
            />
         
        </div>`
            : ''
        }
        
       
     `
            : ''
        } `;
};

const layoutOne = (image = []) => {
  return `
  
    ${
      image[0]
        ? ` <div class='model-photos-column model-photos-column2'>
          <img
            src=${image[0]?.imageURL}
            alt=''
          />
        </div>`
        : ''
    }
   
        ${
          image[1] || image[2]
            ? ` <div class='model-photos-column model-photos-column1'>
         ${
           image[1]
             ? `
            
           
            <img
             src=${image[1]?.imageURL}
              alt=''
            />
          `
             : ''
         }

         ${
           image[2]
             ? ` <img
            src=${image[2]?.imageURL}
            alt=''
          />`
             : ''
         }
         
        </div>`
            : ''
        }`;
};

const customLayout = (image = [], ref, layoutOne, id = '') => {
  const isRowExist = document.querySelector(`#${id}`);

  if (isRowExist === null && ref && layoutOne) {
    const item = document.createElement('section');

    const ItemClass = 'model-photos-row';
    item.className = `${ItemClass}`;
    item.style = `width:100%`;
    item.id = `${id}`;

    item.insertAdjacentHTML('afterbegin', layoutOne(image));

    ref.appendChild(item);
  }
};
