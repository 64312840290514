import React from 'react';
import styles from './styles.module.scss';
import classnames from 'classnames';

const Photo = ({ src, className, alt, ...props }) => {
	return (
		<picture
			className={classnames(styles.picture, className)}
			style={{
				background: `url(${src}) rgba(0, 0, 0, 0.5)`,
				backgroundSize: '100% 100%',
				backgroundRepeat: 'no-repeat',
				aspectRatio: '16/ 9',
				backgroundOrigin: 'center',
			}}
			{...props}
		>
			<img src={src} alt={alt} className={styles.img} loading='lazy' />
		</picture>
	);
};

export default Photo;
