import styles from './event.module.scss';
import { CiClock2 } from 'react-icons/ci';
import { PiDotsThreeOutlineVertical } from 'react-icons/pi';
import { useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Spinner from '../../../custom/spinner/Spinner';

const Event = ({ data, handleEditMode, handleDeleteEvent, loading, id }) => {
  const [openOptions, setOpenOptions] = useState(false);
  const [eventId, setEventId] = useState('');
  const handleHover = () => {
    setOpenOptions(true);
  };

  const handleLeave = () => {
    setOpenOptions(false);
  };

  return (
    <main className={styles.container}>
      <span className={styles.dotContainer}>
        {loading && eventId === id && (
          <Spinner background={false} isTip={false} />
        )}
        <PiDotsThreeOutlineVertical
          onMouseEnter={handleHover}
          onClick={handleHover}
          className={styles.editIcon}
        />
      </span>
      <h1 className={styles.eventTime}>
        <CiClock2 className={styles.icon} /> {data?.startTime} - {data?.endTime}
      </h1>
      <h2 className={styles.eventTitle}>{data?.title}</h2>
      <p className={styles.eventDescription}>{data?.description}</p>
      {openOptions && (
        <nav onMouseLeave={handleLeave} className={styles.actionContainer}>
          <li
            className={styles.action}
            onClick={() => {
              handleEditMode();
              setOpenOptions(false);
            }}
          >
            Edit Event
          </li>
          <li
            className={styles.action}
            onClick={() => {
              handleDeleteEvent();
              setEventId(data.id);
            }}
          >
            Remove Event
          </li>
        </nav>
      )}
    </main>
  );
};

export default Event;
