import { useEffect, useReducer, useState } from 'react';
import Popup from 'reactjs-popup';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { ReactComponent as CloseButton } from '../../../images/svg/Close Btn.svg';
import Button from '../../../custom/btn/Btn';
import Photo from '../../../custom/photo/Photo';
import { ReactComponent as DeleteIcon } from '../../../images/svg/delete.svg';
import { ReactComponent as AlbumIcon } from '../../../images/svg/album-icon.svg';
import { ReactComponent as EditIcon } from '../../../images/svg/edit-icon.svg';
import { updateFanClubShow } from '../../../request/model';
import Spinner from '../../../custom/spinner/Spinner';
import notification from '../../../utils/notification';
import errorFormmatter from '../../../utils/errorFormatter';
import { useSelector } from 'react-redux';

const ModelEditPopup = ({ onClose, open, show, deleteShow }) => {
  const { isHidden, showName, description, modelShowId } = show;

  const user = useSelector((state) => state?.user);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    showName: showName,
    description: description ?? '',
  });

  const [permission, setPermission] = useState({
    isHidden: isHidden,
  });

  const handleChange = (e) => {
    const { value, name } = e.target;

    setData({
      ...data,
      [name]: value,
    });
  };

  const deleteFanClubShow = async (id) => {
    try {
      setLoading(true);
      const response = await deleteShow(id);
      setLoading(false);
      notification({
        message: response?.message ?? 'Fan club show deleted successfully',
      });
    } catch (error) {
      notification({
        message: errorFormmatter(error) ?? 'Fan club show failed to delete',
        type: 'danger',
      });
      setLoading(false);
    }
  };

  const editPhoto = async (type, value = '') => {
    try {
      const payload = new FormData();

      if (type === 'showName') {
        payload.append('showName', data.showName);
      }

      if (type === 'description') {
        payload.append('description', data.description);
      }

      if (type === 'isHidden') {
        payload.append('isHidden', value);
      }

      payload.append('registeredId', user?.clientId);
      payload.append('modelShowId', modelShowId);

      setLoading(true);
      const response = await updateFanClubShow({ payload });
      setLoading(false);
      notification({
        message: response?.message ?? 'Fan club show saved successfully',
      });
    } catch (error) {
      notification({
        message: errorFormmatter(error) ?? 'Fan club show failed to save',
        type: 'danger',
      });
      setLoading(false);
    }
  };

  const handlePermission = (name) => {
    let value = permission[name] === false ? true : false;

    setPermission({
      ...permission,
      [name]: value,
    });

    editPhoto(name, value);
  };

  return (
    <Popup
      onClose={onClose}
      open={open}
      lockScroll={false}
      overlayStyle={{ background: 'rgba(68, 39, 52, 0.89)' }}
    >
      <CloseButton className={styles.closeBtn} onClick={onClose} />
      <section className={styles.imageContainer}>
        <div className={styles.topActionContainer}>
          {loading && <Spinner />}
        </div>
        <Photo
          className={styles.photo}
          src={show?.showCoverURL}
          photoName={show?.showName}
        />

        <nav className={styles.nav}>
          <section className={styles.nameWrapper}>
            <label className={styles.inputNameLabel}>
              <AlbumIcon className={styles.editIcon} />
              <input
                type='text'
                name='showName'
                defaultValue={showName}
                onBlur={() => editPhoto('showName')}
                onChange={handleChange}
                className={styles.nameInput}
              />
              <EditIcon className={styles.editIcon} />
            </label>
          </section>

          <section className={styles.permissionBtn}>
            <Button
              onClick={() => handlePermission('isHidden')}
              text='Hidden'
              className={classnames(
                styles.btn,
                permission.isHidden && styles.active
              )}
            />
            <Button
              text={'Delete'}
              icon={DeleteIcon}
              className={classnames(styles.deleteBtn)}
              onClick={() => deleteFanClubShow(show?.modelSowId)}
            />
          </section>
        </nav>
        <fieldset className={styles.fieldset}>
          <label className={styles.label}>
            <span className={styles.description}>Description</span>
          </label>
          <textarea
            name='description'
            className={styles.textarea}
            onChange={handleChange}
            defaultValue={description}
            onBlur={() => editPhoto('description')}
          ></textarea>
        </fieldset>
      </section>
    </Popup>
  );
};

export default ModelEditPopup;
