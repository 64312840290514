import React from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClientProvider, QueryClient } from 'react-query';
import App from './App';
import 'swiper/css';
import store, { persistedStore } from './store/store';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import '@radix-ui/themes/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'antd/dist/antd.less';
import 'react-notifications-component/dist/theme.css';

import './css/App.scss';

//  axios request interceptors
axios.interceptors.request.use(
	function(config) {
		config.baseURL = process.env.REACT_APP_BASE_URL;
		if (typeof config?.headers?.Authorization === 'undefined') {
			const token = store.getState()?.user?.token;

			config.headers = {
				Authorization: `Bearer ${token}`,
				Accept: 'application/json',
				'X-XSS-Protection': '1; mode=block',
				'Content-Type': 'application/json',
				'Cross-Origin-Embedder-Policy': 'require-corp',
				'X-Frame-Options': 'SAMEORIGIN',
				'X-Content-Type-Options': 'nosniff',
				'Strict-Transport-Security': 'max-age=31536000',
				'Feature-Policy':
					"accelerometer 'none'; gyroscope 'none'; magnetometer 'none'; microphone 'none'; usb 'none'",
				'Cache-Control': 'no-cache',
				'Cross-Origin-Opener-Policy': 'same-origin',
				Pragma: 'no-cache',
			};
		}

		return config;
	},

	function(error) {
		return Promise.reject(error);
	}
);

// Axios Response Interceptor
axios.interceptors.response.use(null, function(error) {
	if (updateOnlineStatus() === 'offline') {
		error = {
			message: 'You are currently offline. Kindly turn on your network or try again',
		};
		return Promise.reject(error);
	}

	if (error?.response?.status === 401) {
		try {
			localStorage.removeItem('token');
		} catch (error) {
			console.error(error);
		}
		window.location.replace('/');
	}

	return Promise.reject(error);
});

function updateOnlineStatus() {
	return navigator.onLine ? 'online' : 'offline';
}

window.addEventListener('offline', updateOnlineStatus);
window.addEventListener('online', updateOnlineStatus);

// Create a client
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			retry: 0,
		},
		mutations: {
			retry: 0,
		},
	},
});

const root = createRoot(document.getElementById('root'));
root.render(
	<Router>
		<Switch>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistedStore}>
					<QueryClientProvider client={queryClient}>
						{/* <Theme> */}
						<App />
						{/* </Theme> */}
					</QueryClientProvider>
				</PersistGate>
			</Provider>
		</Switch>
	</Router>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
