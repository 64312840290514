import { useReducer } from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from 'react-query';
import * as Yup from 'yup';
import styles from './contactUs.module.scss';
import axios from 'axios';
import notification from '../../utils/notification';
import errorFormmatter from '../../utils/errorFormatter';
import validationSchema from '../../utils/validationSchema';

const schema = Yup.object().shape({
	email: Yup.string()
		.email()
		.required('Email is required'),
	name: Yup.string().required('Name is required'),
	subject: Yup.string().required('Subject is required'),
	yourQuestion: Yup.string().required('Your question is required'),
});

const ContactUs = () => {
	const [reportDetails, setResportDetails] = useReducer((prevState, state) => ({ ...prevState, ...state }), {
		name: '',
		email: '',
		subject: '',
		yourQuestion: '',
	});

	const submitContact = payload => axios.post('/SetUp/ContactOrReportProblem', payload);

	const contactMutation = useMutation('conctact', submitContact, { retry: 0 });

	const handleSubmit = async e => {
		e.preventDefault();

		try {
			const successfulValidation = await validationSchema(schema, reportDetails);

			if (!successfulValidation) return;

			await contactMutation.mutateAsync(reportDetails, {
				onSuccess: () => {
					// reset the form
					setResportDetails({
						name: '',
						email: '',
						subject: '',
						yourQuestion: '',
					});

					notification({
						title: 'Contact Submission Successful',
						type: 'success',
						message: 'You have successfully submitted your enquiries. Our team will be in contact',
					});
				},
			});
		} catch (error) {
			notification({
				title: 'Contact Submission Error',
				type: 'danger',
				message: errorFormmatter(error),
			});
		}
	};

	const handleChange = e => {
		const { name, value } = e.target;
		setResportDetails({ [name]: value });
	};

	return (
		<main className={styles.main}>
			<h1 className={styles.heading}>Contact us and Content Removal Policy</h1>

			<section className={styles.container}>
				<article>
					<p className={styles.description}>
						You can also email our customer support team by using the form below. If you have trouble with this
						form they can also be reached at{' '}
						<Link to='mailto:support@nsa1.com' className={styles.link}>
							support@nsa1.com
						</Link>
						. <br /> Or you can fill the form and we will contact to you
					</p>

					<h4 className={styles.subheading}>Billing Issues:</h4>

					<p className={styles.description}>
						If you'd like to cancel your subscription and not to be billed again (if No subscriptions, delete this
						when adding to the site), or for any billing questions including refunds on a case-by-case basis,
						please contact our billing partner, CCBill via 1.888.596.9279, or email at{' '}
						<Link to='mailto:consumersupport@ccbill.com' className={styles.link}>
							consumersupport@ccbill.com
						</Link>
					</p>

					<h4 className={styles.subheading}>Complaints:</h4>

					<p className={styles.description}>
						For all complaints, please contact us through{' '}
						<Link to='mailto:support@nsa1.com' className={styles.link}>
							support@nsa1.com
						</Link>
						. Complaints will be reviewed and resolved within seven (7) business days. That resolution will the be
						communicated to the Complainant. Appeals or requests for any decision made should be presented, again
						to
						<Link to='mailto:support@nsa1.com' className={styles.link}>
							support@nsa1.com
						</Link>
					</p>

					<h4 className={styles.subheading}>Appeals:</h4>

					<p className={styles.description}>
						If you have been depicted in any content and would like to appeal removal of such content, please
						notify us by email
						<Link to='mailto:support@nsa1.com' className={styles.link}>
							support@nsa1.com
						</Link>
						. If there should be disagreement regarding an appeal, we will allow the disagreement to be resolved
						by a neutral party.
					</p>
				</article>

				<p className={styles.contact}>
					For immediate assistance you can call 24 hours a day, 7 days a week <br /> <br />
					<span className={styles.phoneNumber}>+1 111 222-3456</span>
				</p>

				<form className={styles.form} onSubmit={handleSubmit}>
					<div className={styles.formControl}>
						<label className={styles.label}>Name</label>
						<input
							type='text'
							name='name'
							placeholder='Your name'
							className={styles.input}
							onChange={handleChange}
							value={reportDetails?.name}
						/>
					</div>

					<div className={styles.formControl}>
						<label className={styles.label}>E-mail</label>
						<input
							type='email'
							name='email'
							placeholder='Your e-mail'
							className={styles.input}
							onChange={handleChange}
							value={reportDetails?.email}
						/>
					</div>

					<div className={styles.formControl}>
						<label className={styles.label}>Subject</label>
						<select
							className={styles.select}
							onChange={handleChange}
							name='subject'
							value={reportDetails?.subject}
						>
							<option>Payment Issue</option>
							<option>Technical Issue</option>
							<option>Privacy and Security Concern</option>
							<option>User Experience Issue</option>
							<option>Other</option>
						</select>
					</div>

					<div className={styles.formControl}>
						<label className={styles.label}>Your Question</label>
						<div className={styles.textareaWrapper}>
							<textarea
								placeholder='Enter your question'
								className={styles.textarea}
								onChange={handleChange}
								name='yourQuestion'
								value={reportDetails?.yourQuestion}
							></textarea>
							<button className={styles.btn} disabled={contactMutation.isLoading}>
								{contactMutation.isLoading ? 'Sending...' : 'Send'}
							</button>
						</div>
					</div>
				</form>
			</section>
		</main>
	);
};

export default ContactUs;
