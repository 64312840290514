import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import styles from './table.module.scss';
import Spinner from '../../../custom/spinner/Spinner';
import { deleteBlockCriteria } from '../../../request/model';
import notification from '../../../utils/notification';
import errorFormmatter from '../../../utils/errorFormatter';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useSelector } from 'react-redux';

const preProcessTableData = data => {
	return data.map(item => {
		return {
			originalDes: item?.criteriaDescription,
			id: item?.id,
			description: `${
				item?.criteriaDescription.length > 20
					? `${item?.criteriaDescription.slice(0, 20)}...`
					: item?.criteriaDescription
			}`,
			country: item?.country,
			state: item?.state,
			city: `${item?.city.length > 15 ? `${item?.city.slice(0, 15)}...` : item?.city}`,
			zipcode: item?.zipcode,
		};
	});
};

const column = [
	{
		id: 'id',
		header: 'ID',
		accessorKey: 'id',
	},

	{
		id: 'description',
		header: 'Criteria Description',
		accessorKey: 'description',
	},

	{
		id: 'country',
		header: 'Country',
		accessorKey: 'country',
	},
	{
		id: 'state',
		header: 'State',
		accessorKey: 'state',
	},

	{
		id: 'city',
		header: 'City',
		accessorKey: 'city',
	},
	{
		id: 'zipcode',
		header: 'ZipCode',
		accessorKey: 'zipcode',
	},
];

const GridTable = ({ tableData, handleCurrentBlockCriteria, handleDeleteBlockCriteria, deleteLoading }) => {
	const [openOptionId, setOpenOptionId] = useState('');
	const columns = [
		...column,
		{
			id: 'option',
			cell: ({ row }) => {
				const [openOption, setOpenOption] = useState(false);

				const handleHover = () => {
					setOpenOption(true);
				};

				const handleLeave = () => {
					setOpenOption(false);
				};

				return (
					<div className={styles.options} onMouseLeave={handleLeave}>
						<span
							onMouseEnter={() => {
								handleHover();
								setOpenOptionId(row.index);
							}}
							onClick={() => {
								handleHover();
								setOpenOptionId(row.index);
							}}
						>
							Option
							{deleteLoading && row.index === openOptionId && <Spinner background={false} isTip={false} />}{' '}
						</span>
						{openOption && (
							<nav className={styles.optionContainer}>
								<li className={styles.option} onClick={() => handleCurrentBlockCriteria(row.original)}>
									Edit Criteria
								</li>
								<l1
									onClick={() => {
										handleDeleteBlockCriteria(row.original.id);
									}}
									className={styles.option}
								>
									Delete Criteria
								</l1>
							</nav>
						)}
					</div>
				);
			},
		},
	];
	const [data, setData] = React.useState(() => []);

	useEffect(() => {
		setData(() => preProcessTableData(tableData));
	}, [tableData]);

	const table = useReactTable({
		data,
		columns,
		getCoreRowModel: getCoreRowModel(),
	});

	return (
		<div className='p-2'>
			<table>
				<thead>
					{table.getHeaderGroups().map(headerGroup => (
						<tr key={headerGroup.id} className={styles.tableHeader}>
							{headerGroup.headers.map(header => (
								<th key={header.id} className={styles.tableHeaderCell}>
									{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody>
					{table.getRowModel().rows.length === 0 && <span className={styles.noData}>No Block Criteria Available</span>}
					{table.getRowModel().rows.map(row => (
						<tr key={row.id} className={styles.tableRow}>
							{row.getVisibleCells().map(cell => (
								<td key={cell.id} className={styles.tableCell}>
									{flexRender(cell.column.columnDef.cell, cell.getContext())}
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default GridTable;
