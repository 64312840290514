import { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import styles from './uploadpopup.module.scss';
import classname from 'classnames';
import Spinner from '../spinner/Spinner';
import { ReactComponent as PhotoIcon } from '../../svgs/photo-icon.svg';

const UploadPopup = ({
  open,
  disabled = false,
  handleClose,
  onFileChange,
  upload,
  accept = 'image/*',
  title = 'photos',
  acceptableFile = 'JPG, GIF or PNG less than 10 MB',
}) => {
  const [file, setFile] = useState('');
  const [image, setImage] = useState('');
  const [video, setVideo] = useState('');

  const handleChange = (e) => {
    const file = e?.target?.files[0];
    if (!file) return;

    if (onFileChange) {
      onFileChange(file);
    }

    setFile(file);
    hangleImagePreview(file);
  };

  const hangleImagePreview = (file) => {
    const imgUrl = URL.createObjectURL(file);
    if (accept === 'image/*') {
      setImage(imgUrl);
    }

    if (accept === 'video/*') {
      setVideo(imgUrl);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();

    setFile(e.dataTransfer.files[0]);

    // const imageRegex = /image(?=\/jpeg|\/png|\/jpg)/;
    const dropedImage = e.dataTransfer.files[0];
    // const image = dropedImage.type.match(imageRegex);

    if (dropedImage) {
      hangleImagePreview(dropedImage);
    }

    return;
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const closeModal = () => {
    setFile(null);
    setImage(null);
    handleClose(false);
  };

  useEffect(() => {
    setFile(null);
    setImage(null);
    setVideo(null);
  }, []);

  return (
    <Popup
      open={open}
      lockScroll
      className='photoLoad-popup'
      onClose={closeModal}
    >
      <span className='login-popup--close' onClick={() => handleClose(false)}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='18'
          height='18'
          viewBox='0 0 18 18'
          fill='none'
        >
          <path
            d='M17.7071 1.70711C18.0976 1.31658 18.0976 0.683417 17.7071 0.292893C17.3166 -0.0976311 16.6834 -0.0976311 16.2929 0.292893L17.7071 1.70711ZM0.292893 16.2929C-0.0976311 16.6834 -0.0976311 17.3166 0.292893 17.7071C0.683417 18.0976 1.31658 18.0976 1.70711 17.7071L0.292893 16.2929ZM16.2929 17.7071C16.6834 18.0976 17.3166 18.0976 17.7071 17.7071C18.0976 17.3166 18.0976 16.6834 17.7071 16.2929L16.2929 17.7071ZM1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L1.70711 0.292893ZM16.2929 0.292893L0.292893 16.2929L1.70711 17.7071L17.7071 1.70711L16.2929 0.292893ZM17.7071 16.2929L1.70711 0.292893L0.292893 1.70711L16.2929 17.7071L17.7071 16.2929Z'
            fill='white'
          />
        </svg>
      </span>

      <div
        className={`photoLoad-popup--wrapper ${styles.wrapper}`}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <div className={`photoLoad-popup--left ${styles.leftContainer}`}>
          <div>
            <p className='photoLoad-popup--title'>Upload new files</p>
            {(image || video) && file ? null : (
              <div className={styles.informToUpload}>
                You have not uploaded <br />
                any {title} yet
              </div>
            )}
            <div className='photoLoad-popup--img'></div>

            {(image || video) && file && (
              <div id='output' className={styles.output}>
                {accept === 'image/*' ? (
                  <img
                    src={image}
                    alt='image preview'
                    className={styles.imagePreview}
                  />
                ) : (
                  <>
                    <video controls src={video} />
                  </>
                )}
              </div>
            )}
          </div>

          <div
            className={`photoLoad-popup--btnWrapper ${styles.actionButtonContainer}`}
          >
            <label
              className={classname(
                'btn btn__red photoLoad-popup--btn',
                styles.uploadButton
              )}
            >
              <input
                type='file'
                name='myFile'
                onChange={handleChange}
                id='myFile'
                accept={accept}
                className={styles.fileUpload}
              />
              Upload from computer
            </label>
            <button
              onClick={() => {
                if (upload && disabled === false) {
                  upload();
                }
              }}
              className={classname(
                'btn btn__red send-js photoLoad-popup--btn',
                styles.confirmButton,
                !file || disabled === true
                  ? 'photoLoad-popup--btn__disabled'
                  : ''
              )}
              disabled={disabled}
            >
              Confirm upload
              {disabled === true && (
                <Spinner background={false} isTip={false} />
              )}
            </button>
          </div>
        </div>
        <div className='photoLoad-popup--right'>
          <label className='dropzone'>
            <PhotoIcon />
            <h1 className={styles.title}>Drag {title} here</h1>
            <h5 className={styles.subTitle}>{acceptableFile}</h5>
            <input
              name='myFile'
              type='file'
              onChange={handleChange}
              id='myFile'
              accept={accept}
            />
          </label>
        </div>
      </div>
    </Popup>
  );
};

export default UploadPopup;
