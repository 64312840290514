import styles from './personal-infor.module.scss';
import ModelProfileHeader from '../../custom/modelProfileHeader/ModelProfileHeader';
import FluidContainer from '../../custom/fluidContainer/FluidContainer';
import Button from '../../custom/btn/Btn';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { updateModelDetails } from '../../request/model';
import { getCountries } from '../../request/request';
import Spinner from '../../custom/spinner/Spinner';
import { useSelector } from 'react-redux';
import notification from '../../utils/notification';
import { Link, useRouteMatch } from 'react-router-dom';
import errorFormmatter from '../../utils/errorFormatter';
import { getModelDetails } from '../../request/model';
import Input from '../../custom/input/Input';
import Textarea from '../../custom/textarea/Textarea';
import DropDown from '../../custom/DropDown/DropDown';
import DatePicker from '../../custom/datePicker/DatePicker';
import moment from 'moment';
import categories from './category.json';

const ActiveArrow = (props) => {
  return (
    <svg
      {...props}
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='25'
      viewBox='0 0 25 29'
      fill='none'
    >
      <path
        d='M24 12.7679C25.3333 13.5378 25.3333 15.4622 24 16.232L3.75 27.9234C2.41666 28.6932 0.749999 27.7309 0.749999 26.1913L0.75 2.80865C0.75 1.26905 2.41667 0.306805 3.75 1.07661L24 12.7679Z'
        fill='#010002'
      />
    </svg>
  );
};

const categoriesOptions = categories.map((category) => {
  return {
    label: category,
    value: category,
  };
});

const buildOptions = [
  {
    label: 'Slim',
    value: 'Slim',
  },
  {
    label: 'SlimThick',
    value: 'SlimThick',
  },
  {
    label: 'Thick',
    value: 'Thick',
  },
];

const hairOptions = [
  {
    label: 'Blonde',
    value: 'Blonde',
  },
  {
    label: 'Brunette',
    value: 'Brunette',
  },
  {
    label: 'Red',
    value: 'Red',
  },
  {
    label: 'Black',
    value: 'Black',
  },
];

const breastOptions = [
  {
    label: 'Small',
    value: 'Small',
  },
  {
    label: 'Large',
    value: 'Large',
  },
  {
    label: 'Medium',
    value: 'Medium',
  },
  {
    label: 'Extra large',
    value: 'Extra large',
  },
];
const eyeOptions = [
  {
    label: 'Blue',
    value: 'Blue',
  },
  {
    label: 'Brown',
    value: 'Brown',
  },
  {
    label: 'Amber',
    value: 'Amber',
  },
  {
    label: 'Gray',
    value: 'Gray',
  },
  {
    label: 'Green',
    value: 'Green',
  },
  {
    label: 'Hazel',
    value: 'Hazel',
  },

  {
    label: 'Red',
    value: 'Red',
  },
];
const heightOptions = [
  {
    label: 'Tall',
    value: 'Tall',
  },
  {
    label: 'Short',
    value: 'Short',
  },
  {
    label: 'Average',
    value: 'Average',
  },
];

const genderOptions = [
  {
    label: 'Male',
    value: 'Male',
  },
  {
    label: 'Female',
    value: 'Female',
  },
  {
    label: 'Undisclosed',
    value: 'Undisclosed',
  },
  {
    label: 'TransGender',
    value: 'TransGender',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

const ethnicityOptions = [
  {
    label: 'Black',
    value: 'Black',
  },
  {
    label: 'White',
    value: 'White',
  },
  {
    label: 'Asia',
    value: 'Asia',
  },
  {
    label: 'Indian',
    value: 'Indian',
  },
  {
    label: 'Latino',
    value: 'Latino',
  },
  {
    label: 'Arab',
    value: 'Arab',
  },
  {
    label: 'Native America',
    value: 'Native America',
  },
  {
    label: 'Italian',
    value: 'Italian',
  },
];
const userInformationSchema = Yup.object().shape({
  firstName: Yup.string().trim().required('first name is required'),
  lastName: Yup.string().trim().required('last name is required'),
  country: Yup.string().trim().required('country is required'),
  cityOfResident: Yup.string().trim().required('city is required'),
  nickName: Yup.string().trim().required('nick name is required'),
  gender: Yup.string().trim().required('gender is required'),
  age: Yup.string().trim().required('age is required'),
  height: Yup.string().trim().required('height is required'),
  weight: Yup.string().trim().required('weight is required'),
  hairColor: Yup.string().trim().required('hair color is required'),
  dateOfBirth: Yup.string().trim().required('date of birth is required'),
  eyeColor: Yup.string().trim().required('eye color is required'),
  ethinicity: Yup.string().trim().required('ethnicity  is required'),
  breastSize: Yup.string().trim().required('breast size is required'),
  pubicHair: Yup.string().trim().required('public hair is required'),
  language: Yup.string().trim().required('language is required'),
  sexualPreference: Yup.string()
    .trim()
    .required('sexual preference is required'),
  bodyChanges: Yup.string().trim().required('body changes is required'),
  toy: Yup.string().trim().required('toy is required'),
  aboutMe: Yup.string().trim().required('about me is required'),
  myCompetence: Yup.string().trim().required('my competence is required'),
  whatAttractMe: Yup.string().trim().required('what attract me  is required'),
  nationality: Yup.string().trim().required('nationality  is required'),
  build: Yup.string().trim().required('build  is required'),
  category: Yup.string().trim().required('category  is required'),
});

const initialValue = {
  category: '',
  build: '',
  nationality: '',
  firstName: '',
  lastName: '',
  country: '',
  cityOfResident: '',
  nickName: '',
  gender: '',
  age: '',
  height: '',
  weight: '',
  hairColor: '',
  dateOfBirth: '',
  eyeColor: '',
  ethinicity: '',
  breastSize: '',
  pubicHair: '',
  language: '',
  sexualPreference: '',
  bodyChanges: '',
  toy: '',
  aboutMe: '',
  myCompetence: '',
  whatAttractMe: '',
};

const ModelPayeeInformation = () => {
  const activeTab = useRouteMatch();
  const [countryOptions, setCountryOption] = useState([]);
  const [userInformation, setUserInformation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const user = useSelector((state) => state?.user);

  const getInitialData = async () => {
    try {
      const response = await getCountries();

      const res = response.map((country) => {
        return {
          label: country.countryName,
          value: country.countryId,
        };
      });
      setCountryOption(res);
    } catch (error) {
      setCountryOption([]);
    }
  };

  const getUserInformation = async () => {
    try {
      const response = await getModelDetails({ modelId: user.clientId });
      setUserInformation(response);
    } catch (error) {
      setUserInformation(null);
    }
  };

  const onTabChange = (index) => {
    setTab(index);
  };

  const handleUpdate = async (values) => {
    try {
      setLoading(true);
      
      const formData = new FormData();
      formData.append('FirstName', values?.firstName);
      formData.append('LastName', values?.lastName);
      formData.append('Build', values?.build);
      formData.append('Nationality', values?.nationality);
      formData.append('CountryId', parseInt(values?.country));
      formData.append('Category', values?.category);
      formData.append('CityOfResident', values?.cityOfResident);
      formData.append('NickName', values?.nickName);
      formData.append('Gender', values?.gender);
      formData.append('Height', values?.height);
      formData.append('Weight', values?.weight);
      formData.append('HairColor', values?.hairColor);
      formData.append('DateOfBirth', values?.dateOfBirth); //1993-10-16T00:00:00 ${values?.dateOfBirth}
      formData.append('EyeColor', values?.eyeColor);
      formData.append('Ethinicity', values?.ethinicity);
      formData.append('BreastSize', values?.breastSize);
      formData.append('PubicHair', values?.pubicHair);
      formData.append('Language', values?.language);
      formData.append('SexualPreference', values?.sexualPreference);
      formData.append('BodyChanges', values?.bodyChanges);
      formData.append('Toy', values?.toy);
      formData.append('AboutMe', values?.aboutMe);
      formData.append('MyCompetence', values?.myCompetence);
      formData.append('WhatAttractMe', values?.whatAttractMe);
     
      formData.append('RegisterId', user.clientId);
      
      await updateModelDetails({
        payload: formData,
        modelId: user.clientId,
      });
      notification({
        title: 'Profile update',
        message: 'Successfully updated your profile',
        type: 'success',
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notification({
        title: 'Profile update',
        message: errorFormmatter(error),
        type: 'danger',
      });
    }
  };

  useEffect(() => {
    getInitialData();
    getUserInformation();
  }, []);

  useEffect(() => {
    getUserInformation();
  }, [loading]);

  return (
    <FluidContainer>
      <ModelProfileHeader />

      <h1 className="text-primary-100 mb-[59px] text-4xl font-semibold font-['Montserrat'] ">
        Personal Information
      </h1>

      <Formik
        enableReinitialize={true}
        validationSchema={userInformationSchema}
        initialValues={
          userInformation !== null
            ? {
                firstName: userInformation?.firstName,
                lastName: userInformation?.lastName,
                country: userInformation?.country?.countryId,
                cityOfResident: userInformation?.cityOfResident,
                nickName: userInformation?.nickName,
                gender: userInformation?.gender,
                age: userInformation?.age,
                height: userInformation?.height,
                weight: userInformation?.weight,
                hairColor: userInformation?.hairColor,
                dateOfBirth: userInformation?.dateOfBirth,
                eyeColor: userInformation?.eyeColor,
                ethinicity: userInformation?.ethinicity,
                breastSize: userInformation?.breastSize,
                pubicHair: userInformation?.pubicHair,
                language: userInformation?.language,
                sexualPreference: userInformation?.sexualPreference,
                bodyChanges: userInformation?.bodyChanges,
                toy: userInformation?.toy,
                aboutMe: userInformation?.myCompetence,
                myCompetence: userInformation?.myCompetence,
                whatAttractMe: userInformation?.whatAttractMe,
                category: userInformation?.category,
                build: userInformation?.build,
                nationality: userInformation?.nationality,
              }
            : initialValue
        }
        onSubmit={handleUpdate}
      >
        {(props) => {
          return (
            <>
              <section className='flex gap-[31px] w-[100%] justify-between mb-[64px]'>
                <section className='w-[300px]'>
                  <h1 className='w-[300px] flex h-[max-content] items-center gap-[0.4rem]'>
                    <span className="block w-[max-content] text-zinc-100 text-2xl font-semibold font-['Montserrat'] ">
                      Main information
                    </span>{' '}
                    <span className='block grow  h-[1.5px] bg-gradient-to-r from-[#413450] to-[#563143]'></span>
                  </h1>
                </section>

                <section className='grow '>
                  <div className='flex gap-[30px] mb-[24px]'>
                    <div className='w-[285px]'>
                      <Input
                        placeholder={'First name'}
                        className='w-[285px]'
                        defaultValue={userInformation?.firstName}
                        onChange={props.handleChange}
                        name='firstName'
                      />
                      {props.touched && props.errors?.firstName && (
                        <span className={styles.error}>
                          * {props.errors?.firstName}
                        </span>
                      )}
                    </div>
                    <div className='w-[285px]'>
                      <Input
                        defaultValue={userInformation?.lastName}
                        onChange={props.handleChange}
                        name='lastName'
                        placeholder={'Last name'}
                        className='w-[285px]'
                      />
                      {props.touched && props.errors?.lastName && (
                        <span className={styles.error}>
                          * {props.errors?.lastName}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className='flex gap-[30px] mb-[24px]'>
                    <div className='!w-[100%]'>
                      <DropDown
                        onChange={(data) => {
                          props.setFieldValue('ethinicity', data.value);
                        }}
                        defaultValue={[
                          {
                            label: userInformation?.ethinicity,
                            value: userInformation?.ethinicity,
                          },
                        ]}
                        enableHandle={true}
                        options={ethnicityOptions}
                        selectClass={
                          'bg-gradient-to-r from-[#413450] to-[#563143]'
                        }
                        className={classNames(
                          styles.selectContainer,
                          '!w-[100%]'
                        )}
                        dropElement={({ props, state, methods }) => {
                          return (
                            <div className={classNames('px-[1rem] ')}>
                              {state.values.length === 0 ? (
                                props.defaultValue.length > 0 ? (
                                  <span className='text-white'>
                                    {props.defaultValue[0]?.value}
                                  </span>
                                ) : (
                                  <span className='text-primary-300'>
                                    Ethnicity
                                  </span>
                                )
                              ) : (
                                <span className='text-white'>
                                  {state.values[0]?.value}
                                </span>
                              )}
                            </div>
                          );
                        }}
                      />
                      {props.touched && props.errors?.ethinicity && (
                        <span className={styles.error}>
                          * {props.errors?.ethinicity}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className='flex gap-[30px] mb-[24px]'>
                    <div>
                      <DropDown
                        onChange={(data) => {
                          console.log(data, '>>.');
                          props.setFieldValue('country', data.value);
                        }}
                        defaultValue={[
                          {
                            label: userInformation?.country?.countryName,
                            value: userInformation?.country?.countryId,
                          },
                        ]}
                        enableHandle={true}
                        options={countryOptions}
                        className={classNames(
                          styles.selectContainer,
                          '!w-[285px]'
                        )}
                        dropElement={({ props, state, methods }) => {
                          return (
                            <div className={classNames('px-[1rem] ')}>
                              {state.values.length === 0 ? (
                                props.defaultValue.length > 0 ? (
                                  <span className='text-white'>
                                    {props.defaultValue[0]?.label}
                                  </span>
                                ) : (
                                  <span className='text-primary-300'>
                                    Country
                                  </span>
                                )
                              ) : (
                                <span className='text-white'>
                                  {state.values[0]?.label}
                                </span>
                              )}
                            </div>
                          );
                        }}
                      />
                      {props.touched && props.errors?.country && (
                        <span className={styles.error}>
                          * {props.errors?.country}
                        </span>
                      )}
                    </div>
                    <div>
                      <Input
                        defaultValue={userInformation?.cityOfResident}
                        onChange={props.handleChange}
                        name='cityOfResident'
                        placeholder={'  City of residence'}
                        className='w-[285px]'
                      />
                      {props.touched && props.errors?.cityOfResident && (
                        <span className={styles.error}>
                          * {props.errors?.cityOfResident}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className='flex gap-[30px] mb-[24px]'>
                    <Input
                      defaultValue={userInformation?.nationality}
                      onChange={props.handleChange}
                      name='nationality'
                      placeholder={'Nationality'}
                      className='!w-[100%]'
                    />
                    {props.touched && props.errors?.nationality && (
                      <span className={styles.error}>
                        * {props.errors?.nationality}
                      </span>
                    )}
                  </div>
                </section>
                <section className='w-[300px]'>
                  <nav className={styles.navigationContainer}>
                    <Link
                      to='/registration-information'
                      onClick={() => onTabChange(0)}
                    >
                      <li
                        className={classNames(
                          styles.listItem,
                          activeTab.path === '/registration-information' &&
                            styles.active,
                          'whitespace-nowrap'
                        )}
                      >
                        {activeTab.path === '/registration-information' && (
                          <ActiveArrow className={styles.navigationArrow} />
                        )}
                        Registration information
                      </li>
                    </Link>

                    <Link
                      to='/personal-information'
                      onClick={() => onTabChange(1)}
                    >
                      <li
                        className={classNames(
                          styles.listItem,
                          activeTab.path === '/personal-information' &&
                            styles.active
                        )}
                      >
                        {activeTab.path === '/personal-information' && (
                          <ActiveArrow className={styles.navigationArrow} />
                        )}
                        Personal info
                      </li>
                    </Link>
                    <Link
                      to='/payee-information'
                      onClick={() => onTabChange(2)}
                    >
                      <li
                        className={classNames(
                          styles.listItem,
                          activeTab.path === '/payee-information' &&
                            styles.active
                        )}
                      >
                        {activeTab.path === '/payee-information' && (
                          <ActiveArrow className={styles.navigationArrow} />
                        )}
                        Payee information
                      </li>
                    </Link>
                  </nav>
                </section>
              </section>

              <section className='flex gap-[31px] w-[100%] justify-between mb-[64px]'>
                <section className='w-[300px]'>
                  <h1 className='w-[100%] flex h-[max-content] items-center gap-[0.4rem] mb-[12px]'>
                    <span className="block w-[max-content] text-zinc-100 text-2xl font-semibold font-['Montserrat'] ">
                      Personal data
                    </span>{' '}
                    <span className='block grow  h-[1.5px] bg-gradient-to-r from-[#413450] to-[#563143]'></span>
                  </h1>
                  <h2 className="w-[300px] text-zinc-400 text-sm font-medium font-['Montserrat'] leading-tight">
                    Completed information will be displayed in the profile and
                    see for users
                  </h2>
                </section>
                <section className='grow'>
                  <div className='flex gap-[30px] mb-[24px]'>
                    <div className='w-[285px]'>
                      <Input
                        defaultValue={userInformation?.nickName}
                        onChange={props.handleChange}
                        name='nickName'
                        placeholder={'Nickname'}
                        className='w-[285px]'
                      />
                      {props.touched && props.errors?.nickName && (
                        <span className={styles.error}>
                          * {props.errors?.nickName}
                        </span>
                      )}
                    </div>

                    <div>
                      <DropDown
                        onChange={(data) => {
                          props.setFieldValue('gender', data.value);
                        }}
                        defaultValue={[
                          {
                            label: userInformation?.gender,
                            value: userInformation?.gender,
                          },
                        ]}
                        enableHandle={true}
                        options={genderOptions}
                        className={classNames(
                          styles.selectContainer,
                          'w-[285px]'
                        )}
                        dropElement={({ props, state, methods }) => {
                          return (
                            <div className={classNames('px-[1rem] ')}>
                              {state.values.length === 0 ? (
                                props.defaultValue.length > 0 ? (
                                  <span className='text-white'>
                                    {props.defaultValue[0]?.value}
                                  </span>
                                ) : (
                                  <span className='text-primary-300'>
                                    Gender
                                  </span>
                                )
                              ) : (
                                <span className='text-white'>
                                  {state.values[0]?.value}
                                </span>
                              )}
                            </div>
                          );
                        }}
                      />
                      {props.touched && props.errors?.gender && (
                        <span className={styles.error}>
                          * {props.errors?.gender}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className='flex gap-[30px] mb-[24px]'>
                    <div className='w-[285px]'>
                      <DropDown
                        onChange={(data) => {
                          props.setFieldValue('height', data.value);
                        }}
                        defaultValue={[
                          {
                            label: userInformation?.height,
                            value: userInformation?.height,
                          },
                        ]}
                        enableHandle={true}
                        options={heightOptions}
                        className={classNames(
                          styles.selectContainer,
                          'w-[285px]'
                        )}
                        dropElement={({ props, state, methods }) => {
                          return (
                            <div className={classNames('px-[1rem] ')}>
                              {state.values.length === 0 ? (
                                props.defaultValue.length > 0 ? (
                                  <span className='text-white'>
                                    {props.defaultValue[0]?.value}
                                  </span>
                                ) : (
                                  <span className='text-primary-300'>
                                    Height
                                  </span>
                                )
                              ) : (
                                <span className='text-white'>
                                  {state.values[0]?.value}
                                </span>
                              )}
                            </div>
                          );
                        }}
                      />
                      {props.touched && props.errors?.height && (
                        <span className={styles.error}>
                          * {props.errors?.height}
                        </span>
                      )}
                    </div>
                    <div className='w-[285px]'>
                      <Input
                        defaultValue={userInformation?.weight}
                        onChange={props.handleChange}
                        name='weight'
                        placeholder={'Weight'}
                        className='w-[285px]'
                      />
                      {props.touched && props.errors?.weight && (
                        <span className={styles.error}>
                          * {props.errors?.weight}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className='flex gap-[30px] mb-[24px]'>
                    <div>
                      <DropDown
                        onChange={(data) => {
                          props.setFieldValue('build', data.value);
                        }}
                        defaultValue={[
                          {
                            label: userInformation?.build,
                            value: userInformation?.build,
                          },
                        ]}
                        enableHandle={true}
                        options={buildOptions}
                        className={classNames(
                          styles.selectContainer,
                          'w-[285px]'
                        )}
                        dropElement={({ props, state, methods }) => {
                          return (
                            <div className={classNames('px-[1rem] ')}>
                              {state.values.length === 0 ? (
                                props.defaultValue.length > 0 ? (
                                  <span className='text-white'>
                                    {props.defaultValue[0]?.value}
                                  </span>
                                ) : (
                                  <span className='text-primary-300'>
                                    Build
                                  </span>
                                )
                              ) : (
                                <span className='text-white'>
                                  {state.values[0]?.value}
                                </span>
                              )}
                            </div>
                          );
                        }}
                      />
                      {props.touched && props.errors?.build && (
                        <span className={styles.error}>
                          * {props.errors?.build}
                        </span>
                      )}
                    </div>
                    <div className='w-[285px]'>
                      <DropDown
                        onChange={(data) => {
                          props.setFieldValue('category', data.value);
                        }}
                        defaultValue={[
                          {
                            label: userInformation?.category,
                            value: userInformation?.category,
                          },
                        ]}
                        enableHandle={true}
                        options={categoriesOptions}
                        className={classNames(
                          styles.selectContainer,
                          'w-[285px]'
                        )}
                        dropElement={({ props, state, methods }) => {
                          return (
                            <div className={classNames('px-[1rem] ')}>
                              {state.values.length === 0 ? (
                                props.defaultValue.length > 0 ? (
                                  <span className='text-white'>
                                    {props.defaultValue[0]?.value}
                                  </span>
                                ) : (
                                  <span className='text-primary-300'>
                                    Category
                                  </span>
                                )
                              ) : (
                                <span className='text-white'>
                                  {state.values[0]?.value}
                                </span>
                              )}
                            </div>
                          );
                        }}
                      />
                      {props.touched && props.errors?.category && (
                        <span className={styles.error}>
                          * {props.errors?.category}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className='flex gap-[30px] mb-[24px]'>
                    <div>
                      <DropDown
                        onChange={(data) => {
                          props.setFieldValue('eyeColor', data.value);
                        }}
                        defaultValue={[
                          {
                            label: userInformation?.eyeColor,
                            value: userInformation?.eyeColor,
                          },
                        ]}
                        enableHandle={true}
                        options={eyeOptions}
                        className={classNames(
                          styles.selectContainer,
                          'w-[285px]'
                        )}
                        dropElement={({ props, state, methods }) => {
                          return (
                            <div className={classNames('px-[1rem] ')}>
                              {state.values.length === 0 ? (
                                props.defaultValue.length > 0 ? (
                                  <span className='text-white'>
                                    {props.defaultValue[0]?.value}
                                  </span>
                                ) : (
                                  <span className='text-primary-300'>
                                    Eye color
                                  </span>
                                )
                              ) : (
                                <span className='text-white'>
                                  {state.values[0]?.value}
                                </span>
                              )}
                            </div>
                          );
                        }}
                      />
                      {props.touched && props.errors?.eyeColor && (
                        <span className={styles.error}>
                          * {props.errors?.eyeColor}
                        </span>
                      )}
                    </div>
                    <div className='w-[285px]'>
                      <Input
                        defaultValue={userInformation?.bodyChanges}
                        onChange={props.handleChange}
                        name='bodyChanges'
                        placeholder={'Body change'}
                        className='w-[285px]'
                      />
                      {props.touched && props.errors?.bodyChanges && (
                        <span className={styles.error}>
                          * {props.errors?.bodyChanges}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className='flex gap-[30px] mb-[24px]'>
                    <div>
                      <DropDown
                        onChange={(data) => {
                          props.setFieldValue('breastSize', data.value);
                        }}
                        defaultValue={[
                          {
                            label: userInformation?.breastSize,
                            value: userInformation?.breastSize,
                          },
                        ]}
                        enableHandle={true}
                        options={breastOptions}
                        className={classNames(
                          styles.selectContainer,
                          'w-[285px]'
                        )}
                        dropElement={({ props, state, methods }) => {
                          return (
                            <div className={classNames('px-[1rem] ')}>
                              {state.values.length === 0 ? (
                                props.defaultValue.length > 0 ? (
                                  <span className='text-white'>
                                    {props.defaultValue[0]?.value}
                                  </span>
                                ) : (
                                  <span className='text-primary-300'>
                                    Breast size
                                  </span>
                                )
                              ) : (
                                <span className='text-white'>
                                  {state.values[0]?.value}
                                </span>
                              )}
                            </div>
                          );
                        }}
                      />
                      {props.touched && props.errors?.breastSize && (
                        <span className={styles.error}>
                          * {props.errors?.breastSize}
                        </span>
                      )}
                    </div>
                    <div>
                      <DropDown
                        onChange={(data) => {
                          props.setFieldValue('hairColor', data.value);
                        }}
                        defaultValue={[
                          {
                            label: userInformation?.hairColor,
                            value: userInformation?.hairColor,
                          },
                        ]}
                        enableHandle={true}
                        options={hairOptions}
                        className={classNames(
                          styles.selectContainer,
                          'w-[285px]'
                        )}
                        dropElement={({ props, state, methods }) => {
                          return (
                            <div className={classNames('px-[1rem] ')}>
                              {state.values.length === 0 ? (
                                props.defaultValue.length > 0 ? (
                                  <span className='text-white'>
                                    {props.defaultValue[0]?.value}
                                  </span>
                                ) : (
                                  <span className='text-primary-300'>
                                    Hair color
                                  </span>
                                )
                              ) : (
                                <span className='text-white'>
                                  {state.values[0]?.value}
                                </span>
                              )}
                            </div>
                          );
                        }}
                      />
                      {props.touched && props.errors?.hairColor && (
                        <span className={styles.error}>
                          * {props.errors?.hairColor}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className='flex gap-[30px] mb-[24px]'>
                    <div className='w-[285px]'>
                      <Input
                        defaultValue={userInformation?.language}
                        onChange={props.handleChange}
                        name='language'
                        placeholder={'Language'}
                        className='w-[285px]'
                      />
                      {props.touched && props.errors?.language && (
                        <span className={styles.error}>
                          * {props.errors?.language}
                        </span>
                      )}
                    </div>
                    <div className='w-[285px]'>
                      <Input
                        defaultValue={userInformation?.pubicHair}
                        onChange={props.handleChange}
                        name='pubicHair'
                        placeholder={'Public hair'}
                        className='w-[285px]'
                      />
                      {props.touched && props.errors?.pubicHair && (
                        <span className={styles.error}>
                          * {props.errors?.pubicHair}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className='flex gap-[30px] mb-[24px]'>
                    <div className='w-[285px]'>
                      <Input
                        defaultValue={userInformation?.sexualPreference}
                        onChange={props.handleChange}
                        name='sexualPreference'
                        placeholder={'Sexual preference'}
                        className='w-[285px]'
                      />
                      {props.touched && props.errors?.sexualPreference && (
                        <span className={styles.error}>
                          * {props.errors?.sexualPreference}
                        </span>
                      )}
                    </div>
                    <div className='w-[285px]'>
                      <Input
                        defaultValue={userInformation?.toy}
                        onChange={props.handleChange}
                        name='toy'
                        placeholder={'Toys'}
                        className='w-[285px]'
                      />
                      {props.touched && props.errors?.toy && (
                        <span className={styles.error}>
                          * {props.errors?.toy}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className='flex gap-[30px] mb-[24px]'>
                    <div>
                      <DatePicker
                        onChange={(date) => {
                          let formatDate = moment(date);

                          props.setFieldValue('dateOfBirth', formatDate);
                        }}
                        // openToDate={userInformation?.dateOfBirth ? moment(
                        //   userInformation?.dateOfBirth
                        // ) : new Date()}
                        className={
                          'w-[285px] !h-12 [&>.react-datepicker-wrapper]:w-[100%]  [&>.react-datepicker-wrapper]:!h-12'
                        }
                        renderElement={({ value: date, onClick }) => {
                          return (
                            <div
                              onClick={onClick}
                              className={
                                'flex bg-primary-200 justify-between items-center px-[1rem] !h-12 w-[100%]'
                              }
                            >
                              <span className="block grow text-white text-lg font-[1rem] font-['Montserrat']  placeholder:text-primary-300 ">
                                {date}
                              </span>
                              <span className="w-[max-content] block text-primary-100 cursor-pointer text-sm font-[1rem] font-['Montserrat'] ">
                                change request
                              </span>
                            </div>
                          );
                        }}
                      />
                      {props.touched && props.errors?.dateOfBirth && (
                        <span className={styles.error}>
                          * {props.errors?.dateOfBirth}
                        </span>
                      )}
                    </div>
                  </div>
                </section>
                <section className='w-[300px]'></section>
              </section>

              <section className='flex gap-[31px] w-[100%] justify-between'>
                <section className='w-[300px]'>
                  <h1 className='w-[100%] flex h-[max-content] items-center gap-[0.4rem] mb-[12px]'>
                    <span className="block w-[max-content] text-zinc-100 text-2xl font-semibold font-['Montserrat'] ">
                      About my show
                    </span>{' '}
                    <span className='block grow  h-[1.5px] bg-gradient-to-r from-[#413450] to-[#563143]'></span>
                  </h1>
                  <h2 className=" text-zinc-400 text-sm font-medium font-['Montserrat'] leading-tight">
                    No more than 1000 characters
                  </h2>
                </section>
                <section className='grow '>
                  <div className=' mb-[24px]'>
                    <Textarea
                      className=''
                      placeholder='About your show'
                      defaultValue={userInformation?.aboutMe}
                      onChange={props.handleChange}
                      name='aboutMe'
                    />
                    {props.touched && props.errors?.aboutMe && (
                      <span className={styles.error}>
                        * {props.errors?.aboutMe}
                      </span>
                    )}
                  </div>
                </section>
                <section className='w-[300px]'></section>
              </section>

              <section className='flex gap-[31px] w-[100%] justify-between'>
                <section className='w-[300px]'>
                  <h1 className='w-[100%] flex h-[max-content] items-center gap-[0.4rem] mb-[12px]'>
                    <span className="block w-[max-content] text-zinc-100 text-2xl font-semibold font-['Montserrat'] ">
                      My expertise
                    </span>{' '}
                    <span className='block grow  h-[1.5px] bg-gradient-to-r from-[#413450] to-[#563143]'></span>
                  </h1>
                  <h2 className=" text-zinc-400 text-sm font-medium font-['Montserrat'] leading-tight">
                    No more than 1000 characters
                  </h2>
                </section>
                <section className='grow'>
                  <div className=' mb-[24px] w-[100%]'>
                    <Textarea
                      defaultValue={userInformation?.myCompetence}
                      onChange={props.handleChange}
                      name='myCompetence'
                      className='w-[100%]'
                      placeholder='Let`s talk about your competence'
                    />
                    {props.touched && props.errors?.myCompetence && (
                      <span className={styles.error}>
                        * {props.errors?.myCompetence}
                      </span>
                    )}
                  </div>
                </section>
                <section className='w-[300px]'></section>
              </section>

              <section className='flex gap-[31px] w-[100%] justify-between'>
                <section className='w-[300px]'>
                  <h1 className='w-[100%]  flex h-[max-content] items-center gap-[0.4rem] mb-[12px]'>
                    <span className="block w-[max-content] text-zinc-100 text-2xl font-semibold font-['Montserrat'] ">
                      What turns me on
                    </span>{' '}
                    <span className='block grow  h-[1.5px] bg-gradient-to-r from-[#413450] to-[#563143]'></span>
                  </h1>
                  <h2 className=" text-zinc-400 text-sm font-medium font-['Montserrat'] leading-tight">
                    No more than 1000 characters
                  </h2>
                </section>
                <section className='grow'>
                  <div className=' mb-[24px] w-[100%]'>
                    <Textarea
                      defaultValue={userInformation?.whatAttractMe}
                      onChange={props.handleChange}
                      name='whatAttractMe'
                      className='w-[100%]'
                      placeholder='What attracts you'
                    />
                    {props.touched && props.errors?.whatAttractMe && (
                      <span className={styles.error}>
                        * {props.errors?.whatAttractMe}
                      </span>
                    )}
                  </div>
                </section>
                <section className='w-[300px]'></section>
              </section>

              <section className='flex gap-[31px] w-[100%] justify-between mb-[115px]'>
                <section className='w-[300px]'></section>
                <section className='grow'>
                  <Button
                    icon={loading && Spinner}
                    type='submit'
                    onClick={props.handleSubmit}
                    text={'Save'}
                    className={classNames(
                      "w-[190px] flex justify-center items-center h-12 text-center border-[0rem] bg-primary-400 text-white text-base font-medium font-['Montserrat']"
                    )}
                  />
                </section>

                <section className='w-[300px]'></section>
              </section>
            </>
          );
        }}
      </Formik>
    </FluidContainer>
  );
};

export default ModelPayeeInformation;
