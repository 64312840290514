import EmojiPicker from 'emoji-picker-react';
import styles from './emoji.module.scss';

function Emoji({ onSelectedEmoji }) {
  return (
    <div className={styles.container}>
      <EmojiPicker
        onEmojiClick={onSelectedEmoji}
        theme='dark'
        previewConfig={{
          showPreview: false,
        }}
      />
    </div>
  );
}

export default Emoji;
