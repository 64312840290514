import Popup from 'reactjs-popup';
import styles from './participant.module.scss';
import { AiOutlineClose } from 'react-icons/ai';
import { HiDotsVertical } from 'react-icons/hi';
import { useEffect, useState } from 'react';
import notification from '../../../utils/notification';

const ShareBroadcastLinkModal = ({ open, handleClose, broadCastLink }) => {
  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  const handleCopyClick = (copyText) => {
    try {
      copyTextToClipboard(copyText);
      notification({
        title: 'Broadcasting',
        message: 'Link copied successfully',
      });
    } catch (error) {
      notification({
        title: 'Broadcasting',
        type: 'danger',
        message: 'Link failed to copy',
      });
    }
  };

  return (
    <Popup open={open} closeOnDocumentClick onClose={handleClose}>
      <div className='p-[24px] relative max-w-[431px] mx-auto h-[max-content] bg-gradient-to-r from-[#413450] to-[#563143]'>
        <h1 className="mb-[20px] h-[58px] text-primary-100 text-2xl font-bold font-['Montserrat'] ">
          Share Broadcast
        </h1>
        <h2 className="mb-[12px] opacity-90 text-neutral-300 text-sm font-medium font-['Montserrat'] leading-tight">
          Broadcast Link
        </h2>

        <div
          onClick={handleClose}
          className="cursor-pointer absolute top-[0] right-[0] flex justify-center items-center w-12 h-12  before:content-['']  bg-stone-400 opacity-[20]"
        >
          <AiOutlineClose className='z-[999] relative text-[1.5rem]  color-white' />
        </div>

        <button className='mb-[20px] px-[1rem] py-[0.8rem] flex gap-[0.5rem] justify-between items-center w-[100%] h-[46px] rounded-sm  border border-primary-100 border-[1px] '>
          <span className="block !text-primary-100 h-5 left-[16px] top-[13px] text-neutral-300 text-sm font-medium font-['Montserrat'] leading-tight">
            {broadCastLink?.length < 35
              ? broadCastLink
              : `${broadCastLink?.slice(0, 35)}...`}
          </span>
          <input
            className='hidden '
            type='text'
            value={broadCastLink}
            readOnly
          />
          <button
            onClick={() => handleCopyClick(broadCastLink)}
            className='w-[72px] flex gap-[0.2rem] justify-center items-centers  h-7 px-3 py-2 bg-primary-100 rounded justify-center items-center gap-1 inline-flex'
          >
            <span className="text-white text-xs font-semibold font-['Montserrat'] leading-[13.80px]">
              Copy
            </span>
            <div className='w-3 h-3 relative origin-top-left -rotate-180' />
          </button>
        </button>

        {/* <h1 className="mb-[8px] opacity-90 text-neutral-300 text-sm font-medium font-['Montserrat'] leading-tight">
          Share link Via
        </h1> */}

        {/* <div className='gap-[20px] flex-wrap  flex w-[100%]'>
          <div className='w-20 h-[100px]  flex items-center justify-center flex-col'>
            <div className='mb-[0.5rem] w-16 h-16 pl-3 pr-[12.20px] py-3 bg-green-500 rounded justify-center items-center inline-flex'>
              <img
                className='w-[39.80px] h-10 '
                src='https://via.placeholder.com/40x40'
              />
            </div>
            <span className="w-20 text-center text-white text-sm font-normal font-['Montserrat'] leading-none">
              Whatsapp
            </span>
          </div>

          <div className='w-20 h-[100px]  flex items-center justify-center flex-col'>
            <div className='mb-[0.5rem] w-16 h-16 pl-3 pr-[12.20px] py-3 bg-green-500 rounded justify-center items-center inline-flex'>
              <img
                className='w-[39.80px] h-10 '
                src='https://via.placeholder.com/40x40'
              />
            </div>
            <span className="w-20 text-center text-white text-sm font-normal font-['Montserrat'] leading-none">
              Whatsapp
            </span>
          </div>
          <div className='w-20 h-[100px]  flex items-center justify-center flex-col'>
            <div className='mb-[0.5rem] w-16 h-16 pl-3 pr-[12.20px] py-3 bg-green-500 rounded justify-center items-center inline-flex'>
              <img
                className='w-[39.80px] h-10 '
                src='https://via.placeholder.com/40x40'
              />
            </div>
            <span className="w-20 text-center text-white text-sm font-normal font-['Montserrat'] leading-none">
              Whatsapp
            </span>
          </div>
          <div className='w-20 h-[100px]  flex items-center justify-center flex-col'>
            <div className='mb-[0.5rem] w-16 h-16 pl-3 pr-[12.20px] py-3 bg-green-500 rounded justify-center items-center inline-flex'>
              <img
                className='w-[39.80px] h-10 '
                src='https://via.placeholder.com/40x40'
              />
            </div>
            <span className="w-20 text-center text-white text-sm font-normal font-['Montserrat'] leading-none">
              Whatsapp
            </span>
          </div>
          <div className='w-20 h-[100px]  flex items-center justify-center flex-col'>
            <div className='mb-[0.5rem] w-16 h-16 pl-3 pr-[12.20px] py-3 bg-green-500 rounded justify-center items-center inline-flex'>
              <img
                className='w-[39.80px] h-10 '
                src='https://via.placeholder.com/40x40'
              />
            </div>
            <span className="w-20 text-center text-white text-sm font-normal font-['Montserrat'] leading-none">
              Whatsapp
            </span>
          </div>
        </div> */}
      </div>
    </Popup>
  );
};

export default ShareBroadcastLinkModal;
