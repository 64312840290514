import React, { Component } from 'react';
import {Link} from "react-router-dom";
import axios from 'axios';

class Confirm extends Component {
    componentDidMount() {
        let getUrlParameter = function getUrlParameter(sParam) {
            let sPageURL = window.location.search.substring(1);
            let sURLVariables = sPageURL.split('&amp;');
            let sParameterName;
            let i;
        
            for (i = 0; i < sURLVariables.length; i++) {
                sParameterName = sURLVariables[i].split('=');
        
                if (sParameterName[0] === sParam) {
                    return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
                }
            }
        };
        let getEmail = getUrlParameter('email');
        let getToken = getUrlParameter('token');
        axios({
            method: 'post',
            url: `${axios.defaults.baseURL}/api/users/confirm-email`,
            data: {
                email: getEmail,
                emailConfirmationToken: getToken
            }
        }).then(function (response) {
            
        });
    }
    render() {
        return (
            <main className="thanks-page">
                <div className="container">
                    <div className="reg-model-thanks">
                        <div className="reg-model-thanks--title">
                            Thank you <br/>
                            for the subscribe!
                        </div>
                        <div className="reg-model-thanks--subtitle">
                            We will notify you when our service will be available <br/>
                            for work and entertainment!
                        </div>
                        <Link to="/" className="reg-model-thanks--link btn btn__red">Return to main page</Link>
                    </div>
                </div>
            </main>
        );
    }
}
export default Confirm;