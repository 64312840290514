import { config, externalTip } from '../data';
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import { Bar } from 'react-chartjs-2';

Chart.register(CategoryScale);

const BarChart = ({ name, total }) => {
  return (
    <Bar
      id='bar-chart'
      className='!w-[100%] !h-[300px]'
      type='line'
      options={{
        animations: {
          tension: {
            duration: 1000,
            easing: 'linear',
            from: 1,
            to: 0,
            loop: true,
          },
        },

        layout: {
          padding: 0,
          autoPadding: true,
        },
        interaction: {
          //   intersect: false,
        },

        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
            external: externalTip({ posts: 50, impression: 90 }),
          },
        },
        // scales: {
        //   x: {
        //     display: false,
        //   },
        //   y: {
        //     display: false,
        //   },
        // },
      }}
      data={{
        labels: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 19, 20, 21, 22,
        ],

        datasets: [
          {
            data: [
              0, 20, 20, 60, 60, 120, 150, 180, 120, 125, 105, 110, 170, 110,
              170, 150, 170, 110, 130, 110, 180,
            ],

            fill: false,
            borderColor: '#B87693',
            backgroundColor: '#B87693',
            borderWidth: 3,
          },
          {
            data: [
              0, 20, 20, 60, 60, 120, 150, 180, 120, 125, 105, 110, 170, 110,
              170, 150, 170, 110, 130, 110, 180,
            ],

            fill: false,
            borderColor: '#333',
            backgroundColor: '#333',
            borderWidth: 3,
          },
        ],
      }}
    />
  );
};

export default BarChart;
