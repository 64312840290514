import React, { Component } from 'react';
import {Link} from "react-router-dom";
import Img404 from './404img.png';

class NoMatch extends Component {
    render() {
        return (
            <main className="page404">
                <div className="container">
                    <img className = "page404--img" src={Img404} alt=""/>
                    <div className="page404--title">I have a feeling like you got lost... </div>
                    <Link className="page404--btn btn btn__red" to="/">
                        Go back home
                    </Link>
                </div>
            </main>
        )
    }
}
export default NoMatch;