import FluidContainer from '../../../../custom/fluidContainer/FluidContainer';
import { useState, useEffect } from 'react';
import {
  getClubDetails,
  followClub,
  getMemberFollowing,
  getFollower,
} from '../../../../request/club';
import { IoLocationOutline } from 'react-icons/io5';
import { CiGlobe } from 'react-icons/ci';
import moment from 'moment';
import { useLocation, useParams } from 'react-router-dom';
import { IoCallOutline } from 'react-icons/io5';
import { IoTimeOutline } from 'react-icons/io5';
import GalleryView from './GalleryView';
import Feeds from './Feeds';
import { useSelector } from 'react-redux';
import errorFormmatter from '../../../../utils/errorFormatter';
import notification from '../../../../utils/notification';
import { RiUserFollowLine } from 'react-icons/ri';
import Spinner from '../../../../custom/spinner/Spinner';
import { followerCalculation } from '../../../../utils/followerCalculation';

const ClubDetail = () => {
  const params = useLocation();
  const clubId = params?.search?.split('clubId=')[1] ?? '';
  const user = useSelector((state) => state?.user);
  const [clubDetails, setClubDetails] = useState({});
  const [clubPhotos, setClubPhotos] = useState([]);
  const [currentOpenDayTime, setCurrentOpenDayTime] = useState({});
  const [expandGallery, setExpandGallery] = useState(false);
  const [expandFeed, setExpandFeed] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalFellowers, setTotalFellowers] = useState(0);

  const handleGetMemberFollowing = async () => {
    try {
      const response = await getMemberFollowing({
        clubId,
        memberId: user.clientId,
      });
      setIsFollowing(response?.status);
    } catch (error) {}
  };

  const handleCalculateFollowers = (total) => {
    const value = followerCalculation(total);
    setTotalFellowers(value);
  };

  const handleGetFollower = async () => {
    try {
      const response = await getFollower({
        clubId,
      });
      handleCalculateFollowers(response?.totalFollowers);
    } catch (error) {}
  };

  const handleFollowClub = async () => {
    try {
      setLoading(true);
      const payload = new FormData();
      setLoading(true);

      const status = !isFollowing;
      payload.append('clubId', clubId);
      payload.append('memberId', user.clientId);
      payload.append('status', status);

      await followClub({ payload });
      setIsFollowing(status);
      notification({
        title: 'Follow club',
        message: status
          ? 'Successfully followed the club'
          : 'Successfully unfollowed the club',
        type: 'success',
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notification({
        title: 'Follow club',
        message: errorFormmatter(error),
        type: 'danger',
      });
    }
  };

  const handleGetClubDetails = async () => {
    try {
      const response = await getClubDetails({
        clubId,
      });
      handleSetCurrentDayOpenTime(response?.timeClubAvailables ?? []);
      setClubPhotos(response?.clubPhotos ?? []);
      setClubDetails(response);
    } catch (error) {
      setClubDetails({});
    }
  };

  const handleSetCurrentDayOpenTime = (availabilities = []) => {
    try {
      const day = moment().format('dddd');

      const _currentOpenDayTime = availabilities.find((availability) => {
        return availability?.dayName.toLowerCase() === day.toLocaleLowerCase();
      });

      setCurrentOpenDayTime(_currentOpenDayTime);
    } catch (error) {}
  };

  useEffect(() => {
    handleGetClubDetails();
    handleGetMemberFollowing();
    handleGetFollower();
  }, []);

  useEffect(() => {
    handleGetFollower();
    handleGetMemberFollowing();
  }, [loading]);

  return (
    <main>
      <img
        src={clubDetails?.coverURL}
        className='max-w-[100%] object-cover w-[100%] h-[350px]'
      />
      <FluidContainer className='relative'>
        <div className='z-[100] left-[12px] flex items-center justify-center w-[120px] h-[120px] bg-black rounded-full top-[-120px] absolute'>
          <img
            className='w-[80px] rounded-full  h-[80px] '
            src={clubDetails?.clubImageURL}
          />
        </div>

        <section className='mt-[80px] mb-[23px]'>
          <h1 className="mb-[20px]  text-white text-[40px] font-semibold font-['Montserrat'] leading-[46px]">
            {clubDetails?.clubName}
          </h1>

          <p className="mb-[23px] text-neutral-300 text-[20px] font-normal font-['Montserrat'] leading-[25px]">
            {clubDetails?.description}
          </p>
        </section>

        <section className='mb-[26px] max-w-[max-content] w-[100%]  justify-start items-center gap-10 flex'>
          <div className='justify-start items-center gap-3 flex'>
            <IoLocationOutline className='text-white' />
            <span className="block text-neutral-300 text-[1rem] font-medium font-['Montserrat'] leading-[18.40px]">
              {clubDetails?.address}
            </span>
          </div>
          <div className='justify-start items-center gap-3 flex'>
            <CiGlobe className='text-white' />
            <span className=" block text-primary-100 text-[1rem] font-medium font-['Montserrat'] leading-[18.40px]">
              {clubDetails?.site}
            </span>
          </div>
          <div className='justify-start items-center gap-3 flex'>
            <IoCallOutline className='text-white' />
            <span className="block text-neutral-300 text-[1rem] font-medium font-['Montserrat'] leading-[18.40px]">
              {clubDetails?.homePhone}
            </span>
          </div>

          <div
            onClick={handleFollowClub}
            className={`w-[150px] cursor-pointer justify-center items-center gap-1 flex bg-red-700 py-[10px] px-[20px] rounded-[5px]`}
          >
            <RiUserFollowLine className='text-white' />
            <span className="block text-neutral-300 text-[1rem] font-medium font-['Montserrat'] leading-[18.40px]">
              {isFollowing ? 'unfollow' : 'follow'}
            </span>

            {loading && <Spinner width='15px' height='15px' />}
          </div>
        </section>

        <section className='mb-[57px]  w-[100%]  justify-start items-center gap-3 flex'>
          <div className='justify-start items-center gap-3 flex'>
            <IoTimeOutline className='text-white' />
            {clubDetails?.clubStatus ? (
              <>
                <span className="block text-green-600 text-[1rem] font-semibold font-['Montserrat'] leading-[18.40px]">
                  Open Now
                </span>
                <span className="block text-neutral-300 text-[1rem] font-medium font-['Montserrat'] leading-[18.40px]">
                  {currentOpenDayTime?.startTime} —{' '}
                  {currentOpenDayTime?.endTime}
                </span>
              </>
            ) : (
              <>
                <span className="block text-red-500 text-[1rem] font-semibold font-['Montserrat'] leading-[18.40px]">
                  Closed
                </span>
              </>
            )}
          </div>
          <div className='justify-start items-center gap-2 flex'>
            <span className=" block text-primary-100 text-[1rem] font-medium font-['Montserrat'] leading-[18.40px]">
              Followers
            </span>
            {totalFellowers ?? 0}
          </div>
        </section>

        {!expandFeed && (
          <section className=' mb-[41px] flex w-[100%] justify-between'>
            <span className="block text-white text-[24px] font-semibold font-['Montserrat'] leading-[30px]">
              Gallery
            </span>
            <span
              onClick={() => setExpandGallery(!expandGallery)}
              className="block cursor-pointer text-right text-violet-500  text-[20px] font-semibold font-['Montserrat'] leading-[25px]"
            >
              View Full Gallery
            </span>
          </section>
        )}

        {!expandFeed && <GalleryView clubPhotos={clubPhotos} />}

        {!expandGallery && (
          <section className=' mb-[41px] flex w-[100%] justify-between'>
            <span className="block text-white text-2xl font-semibold font-['Montserrat'] leading-[30px]">
              Club Scene
            </span>
            <span
              onClick={() => setExpandFeed(!expandFeed)}
              className="block cursor-pointer text-right text-violet-500 text-xl font-semibold font-['Montserrat'] leading-[25px]"
            >
              View Full Feed
            </span>
          </section>
        )}

        {!expandGallery && <Feeds />}
      </FluidContainer>
    </main>
  );
};

export default ClubDetail;
