import React, { Component } from 'react';
import {Route, Switch, NavLink} from "react-router-dom";
import About from "./about/About";
import Photos from "./photos/Photos";
import Comments from "./comments/Comments";
import Fanclub from "./fanclub/Fanclub";
import { useLocation } from 'react-router-dom';

const Content = ({ modelInfo }) => {
  const params = useLocation();

  const urlSearchParams = new URLSearchParams(params.search);

  const modelFirstName = urlSearchParams.get('firstName');
  const modelLastName = urlSearchParams.get('lastName');
  const modelId = urlSearchParams.get('model');

  const addActive = () => {
    document.querySelector('.detail-menu ul li a').classList.add('active-link');
  };
  const removeActive = () => {
    document
      .querySelector('.detail-menu ul li a')
      .classList.remove('active-link');
  };

  return (
    <section className='detail-content-section'>
      <div className='detail-menu'>
        <ul>
          <li className='detail-menu--chat'>
            <a href='/'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='44'
                height='38'
                viewBox='0 0 44 38'
                fill='none'
              >
                <path
                  d='M15.7007 11.3591C16.29 5.55423 21.9759 1 28.9032 1C36.2225 1 42.156 6.08434 42.156 12.3562C42.156 14.5805 41.4097 16.6555 40.1199 18.4073C39.7722 18.8796 39.4072 19.3632 39.3342 19.9434C39.1362 21.5173 39.8769 23.7361 40.5829 25.0783C41.4445 26.7166 37.2643 24.7805 35.4791 23.3818C34.9994 23.0059 34.2413 22.7673 33.661 22.9586C32.1843 23.4455 30.5801 23.7124 28.9032 23.7124H27.8713M15.7007 11.3591C15.3613 11.3368 15.0183 11.3254 14.6723 11.3254C7.35293 11.3254 1.41943 16.4098 1.41943 22.6816C1.41943 24.906 2.16574 26.9809 3.4555 28.7327C3.80324 29.205 4.16826 29.6887 4.24124 30.2689C4.4392 31.8427 3.69851 34.0615 2.99258 35.4037C2.13091 37.042 6.31117 35.1059 8.0963 33.7072C8.57602 33.3314 9.33419 33.0927 9.91444 33.284C11.3912 33.7709 12.9954 34.0378 14.6723 34.0378C21.5861 34.0378 27.2634 29.5012 27.8713 23.7124M15.7007 11.3591C22.5394 11.8089 27.9251 16.7063 27.9251 22.6816C27.9251 23.0291 27.9069 23.3729 27.8713 23.7124'
                  stroke='#B0B0B0'
                  strokeWidth='2'
                  strokeLinejoin='round'
                />
                <path
                  d='M12.1827 19.5449C11.6304 19.5449 11.1827 19.9926 11.1827 20.5449C11.1827 21.0972 11.6304 21.5449 12.1827 21.5449V19.5449ZM21.0166 21.5449C21.5689 21.5449 22.0166 21.0972 22.0166 20.5449C22.0166 19.9926 21.5689 19.5449 21.0166 19.5449V21.5449ZM8.1875 24.4023C7.63522 24.4023 7.1875 24.85 7.1875 25.4023C7.1875 25.9546 7.63522 26.4023 8.1875 26.4023V24.4023ZM21.0166 26.4023C21.5689 26.4023 22.0166 25.9546 22.0166 25.4023C22.0166 24.85 21.5689 24.4023 21.0166 24.4023V26.4023ZM12.1827 21.5449H21.0166V19.5449H12.1827V21.5449ZM8.1875 26.4023H21.0166V24.4023H8.1875V26.4023Z'
                  fill='#B0B0B0'
                />
                <path
                  d='M9.2041 20.5453C9.2041 21.086 8.7606 21.5319 8.2041 21.5319C7.6476 21.5319 7.2041 21.086 7.2041 20.5453C7.2041 20.0045 7.6476 19.5586 8.2041 19.5586C8.7606 19.5586 9.2041 20.0045 9.2041 20.5453Z'
                  stroke='#B0B0B0'
                />
                <path
                  d='M26.8192 9.09961C26.2669 9.09961 25.8192 9.54732 25.8192 10.0996C25.8192 10.6519 26.2669 11.0996 26.8192 11.0996V9.09961ZM35.6531 11.0996C36.2054 11.0996 36.6531 10.6519 36.6531 10.0996C36.6531 9.54732 36.2054 9.09961 35.6531 9.09961V11.0996ZM25.3672 13.957C24.8149 13.957 24.3672 14.4047 24.3672 14.957C24.3672 15.5093 24.8149 15.957 25.3672 15.957V13.957ZM35.6531 15.957C36.2054 15.957 36.6531 15.5093 36.6531 14.957C36.6531 14.4047 36.2054 13.957 35.6531 13.957V15.957ZM26.8192 11.0996H35.6531V9.09961H26.8192V11.0996ZM25.3672 15.957H35.6531V13.957H25.3672V15.957Z'
                  fill='#B0B0B0'
                />
                <path
                  d='M23.8389 10.1C23.8389 10.6407 23.3954 11.0866 22.8389 11.0866C22.2824 11.0866 21.8389 10.6407 21.8389 10.1C21.8389 9.55923 22.2824 9.11328 22.8389 9.11328C23.3954 9.11328 23.8389 9.55923 23.8389 10.1Z'
                  stroke='#B0B0B0'
                />
              </svg>
            </a>
          </li>
          <li className='detail-menu--about'>
            <NavLink
              onClick={addActive}
              activeClassName='active-link'
              to={`/detail/about?model=${modelId}&lastName=${modelInfo?.lastName}&firstName=${modelInfo?.firstName}`}
            >
              About Me
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='35'
                height='35'
                viewBox='0 0 35 35'
                fill='none'
              >
                <path
                  d='M17.2778 11.6843C16.7213 11.6843 16.2778 11.2383 16.2778 10.6976C16.2778 10.1569 16.7213 9.71094 17.2778 9.71094C17.8343 9.71094 18.2778 10.1569 18.2778 10.6976C18.2778 11.2383 17.8343 11.6843 17.2778 11.6843Z'
                  stroke='#B0B0B0'
                />
                <path
                  d='M18.2778 15.1738C18.2778 14.6215 17.8301 14.1738 17.2778 14.1738C16.7255 14.1738 16.2778 14.6215 16.2778 15.1738L18.2778 15.1738ZM16.2778 23.9293C16.2778 24.4816 16.7255 24.9293 17.2778 24.9293C17.8301 24.9293 18.2778 24.4816 18.2778 23.9293L16.2778 23.9293ZM16.2778 15.1738L16.2778 23.9293L18.2778 23.9293L18.2778 15.1738L16.2778 15.1738Z'
                  fill='#B0B0B0'
                />
                <path
                  d='M33.5557 17.1244C33.5557 26.0212 26.2763 33.2487 17.2778 33.2487C8.2794 33.2487 1 26.0212 1 17.1244C1 8.22752 8.2794 1 17.2778 1C26.2763 1 33.5557 8.22752 33.5557 17.1244Z'
                  stroke='#B0B0B0'
                  stroke-width='2'
                />
              </svg>
            </NavLink>
          </li>
          <li className='detail-menu--photos'>
            <NavLink
              onClick={removeActive}
              activeClassName='active-link'
              to={`/detail/photos?model=${modelId}&lastName=${modelInfo?.lastName}&firstName=${modelInfo?.firstName}`}
            >
              My Photos
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='43'
                height='33'
                viewBox='0 0 43 33'
                fill='none'
              >
                <mask id='path-1-inside-1' fill='white'>
                  <rect
                    x='0.713379'
                    y='0.0898438'
                    width='42'
                    height='32.7069'
                    rx='1'
                  />
                </mask>
                <rect
                  x='0.713379'
                  y='0.0898438'
                  width='42'
                  height='32.7069'
                  rx='1'
                  stroke='#B0B0B0'
                  strokeWidth='4'
                  mask='url(#path-1-inside-1)'
                />
                <path
                  d='M6.73096 26.2928L16.9204 17.3722C17.4977 16.8668 18.3716 16.8918 18.9184 17.4294L25.1737 23.5789M36.7522 19.168L30.3297 13.3232C29.7401 12.7867 28.8249 12.8196 28.2761 13.397L21.8128 20.2749'
                  stroke='#B0B0B0'
                  strokeWidth='2'
                  strokeLinecap='round'
                />
                <path
                  d='M14.981 10.5089C14.981 12.5516 13.3083 14.2189 11.231 14.2189C9.15357 14.2189 7.48096 12.5516 7.48096 10.5089C7.48096 8.46616 9.15357 6.79883 11.231 6.79883C13.3083 6.79883 14.981 8.46616 14.981 10.5089Z'
                  stroke='#B0B0B0'
                  strokeWidth='1.5'
                />
              </svg>
            </NavLink>
          </li>
          <li className='detail-menu--comments'>
            <NavLink
              onClick={removeActive}
              activeClassName='active-link'
              to={`/detail/comments?model=${modelId}&lastName=${modelInfo?.lastName}&firstName=${modelInfo?.firstName}`}
            >
              Member Comments
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='43'
                height='36'
                viewBox='0 0 43 36'
                fill='none'
              >
                <path
                  d='M7.25293 7.93945C6.70064 7.93945 6.25293 8.38717 6.25293 8.93945C6.25293 9.49174 6.70064 9.93945 7.25293 9.93945V7.93945ZM26.687 9.93945C27.2393 9.93945 27.687 9.49174 27.687 8.93945C27.687 8.38717 27.2393 7.93945 26.687 7.93945V9.93945ZM7.25293 9.93945H26.687V7.93945H7.25293V9.93945Z'
                  fill='#B0B0B0'
                />
                <path
                  d='M7.25293 14C6.70064 14 6.25293 14.4477 6.25293 15C6.25293 15.5523 6.70064 16 7.25293 16V14ZM14.7616 16C15.3139 16 15.7616 15.5523 15.7616 15C15.7616 14.4477 15.3139 14 14.7616 14V16ZM7.25293 16H14.7616V14H7.25293V16Z'
                  fill='#B0B0B0'
                />
                <path
                  d='M33.2446 10.468C33.2446 11.0203 33.6923 11.468 34.2446 11.468C34.7969 11.468 35.2446 11.0203 35.2446 10.468H33.2446ZM4.21826 23.8529H5.21826C5.21826 23.3006 4.77055 22.8529 4.21826 22.8529V23.8529ZM4.21826 31.0462H3.21826C3.21826 31.4302 3.4382 31.7804 3.78418 31.9471C4.13016 32.1138 4.54105 32.0676 4.84141 31.8283L4.21826 31.0462ZM13.2465 23.8529V22.8529C13.0201 22.8529 12.8004 22.9297 12.6233 23.0708L13.2465 23.8529ZM14.7152 24.8529C15.2675 24.8529 15.7152 24.4052 15.7152 23.8529C15.7152 23.3006 15.2675 22.8529 14.7152 22.8529V24.8529ZM1.99463 2.55273H33.4946V0.552734H1.99463V2.55273ZM2.24463 23.1096V2.29607H0.244629V23.1096H2.24463ZM33.2446 2.29607V10.468H35.2446V2.29607H33.2446ZM4.21826 22.8529H1.99463V24.8529H4.21826V22.8529ZM0.244629 23.1096C0.244629 24.0808 1.03656 24.8529 1.99463 24.8529V22.8529C2.12427 22.8529 2.24463 22.9594 2.24463 23.1096H0.244629ZM33.4946 2.55273C33.365 2.55273 33.2446 2.44621 33.2446 2.29607H35.2446C35.2446 1.32486 34.4527 0.552734 33.4946 0.552734V2.55273ZM1.99463 0.552734C1.03656 0.552734 0.244629 1.32486 0.244629 2.29607H2.24463C2.24463 2.44622 2.12427 2.55273 1.99463 2.55273V0.552734ZM5.21826 31.0462V23.8529H3.21826V31.0462H5.21826ZM4.84141 31.8283L13.8696 24.635L12.6233 23.0708L3.59511 30.2641L4.84141 31.8283ZM13.2465 24.8529H14.7152V22.8529H13.2465V24.8529Z'
                  fill='#B0B0B0'
                />
                <path
                  d='M39.2015 29.7712H38.2015C38.2015 29.2189 38.6492 28.7712 39.2015 28.7712V29.7712ZM39.2015 34.5624H40.2015C40.2015 34.9464 39.9816 35.2965 39.6356 35.4632C39.2896 35.6299 38.8787 35.5838 38.5784 35.3445L39.2015 34.5624ZM33.1882 29.7712V28.7712C33.4146 28.7712 33.6343 28.848 33.8114 28.9891L33.1882 29.7712ZM19.2022 29.7712V30.7712C18.6499 30.7712 18.2022 30.3235 18.2022 29.7712H19.2022ZM40.6826 15.918H19.7018V13.918H40.6826V15.918ZM40.1821 29.2761V15.4131H42.1821V29.2761H40.1821ZM20.2022 15.4131V20.8561H18.2022V15.4131H20.2022ZM39.2015 28.7712H40.6826V30.7712H39.2015V28.7712ZM42.1821 29.2761C42.1821 30.1102 41.5023 30.7712 40.6826 30.7712V28.7712C40.4146 28.7712 40.1821 28.9889 40.1821 29.2761H42.1821ZM19.7018 15.918C19.9697 15.918 20.2022 15.7003 20.2022 15.4131H18.2022C18.2022 14.579 18.882 13.918 19.7018 13.918V15.918ZM40.6826 13.918C41.5023 13.918 42.1821 14.579 42.1821 15.4131H40.1821C40.1821 15.7003 40.4146 15.918 40.6826 15.918V13.918ZM38.2015 34.5624V29.7712H40.2015V34.5624H38.2015ZM38.5784 35.3445L32.5651 30.5533L33.8114 28.9891L39.8247 33.7803L38.5784 35.3445ZM33.1882 30.7712H32.21V28.7712H33.1882V30.7712ZM20.2022 20.8561V29.7712H18.2022V20.8561H20.2022ZM19.2022 28.7712H32.21V30.7712H19.2022V28.7712Z'
                  fill='#B0B0B0'
                />
                <path
                  d='M26.2275 22.3519C26.2275 22.8926 25.784 23.3386 25.2275 23.3386C24.671 23.3386 24.2275 22.8926 24.2275 22.3519C24.2275 21.8112 24.671 21.3652 25.2275 21.3652C25.784 21.3652 26.2275 21.8112 26.2275 22.3519Z'
                  fill='#B87693'
                  stroke='#B0B0B0'
                />
                <path
                  d='M30.7236 22.3519C30.7236 22.8926 30.2801 23.3386 29.7236 23.3386C29.1671 23.3386 28.7236 22.8926 28.7236 22.3519C28.7236 21.8112 29.1671 21.3652 29.7236 21.3652C30.2801 21.3652 30.7236 21.8112 30.7236 22.3519Z'
                  fill='#B87693'
                  stroke='#B0B0B0'
                />
                <path
                  d='M35.2188 22.3519C35.2188 22.8926 34.7752 23.3386 34.2188 23.3386C33.6623 23.3386 33.2188 22.8926 33.2188 22.3519C33.2188 21.8112 33.6623 21.3652 34.2188 21.3652C34.7752 21.3652 35.2188 21.8112 35.2188 22.3519Z'
                  fill='#B87693'
                  stroke='#B0B0B0'
                />
              </svg>
            </NavLink>
          </li>
          <li className='detail-menu--comments'>
            <NavLink
              onClick={removeActive}
              activeClassName='active-link'
              to={`/detail/fanclub?model=${modelId}&lastName=${modelInfo?.lastName}&firstName=${modelInfo?.firstName}`}
            >
              My Fan Club
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='43'
                height='36'
                viewBox='0 0 43 36'
                fill='none'
              >
                <path
                  d='M7.25293 7.93945C6.70064 7.93945 6.25293 8.38717 6.25293 8.93945C6.25293 9.49174 6.70064 9.93945 7.25293 9.93945V7.93945ZM26.687 9.93945C27.2393 9.93945 27.687 9.49174 27.687 8.93945C27.687 8.38717 27.2393 7.93945 26.687 7.93945V9.93945ZM7.25293 9.93945H26.687V7.93945H7.25293V9.93945Z'
                  fill='#B0B0B0'
                />
                <path
                  d='M7.25293 14C6.70064 14 6.25293 14.4477 6.25293 15C6.25293 15.5523 6.70064 16 7.25293 16V14ZM14.7616 16C15.3139 16 15.7616 15.5523 15.7616 15C15.7616 14.4477 15.3139 14 14.7616 14V16ZM7.25293 16H14.7616V14H7.25293V16Z'
                  fill='#B0B0B0'
                />
                <path
                  d='M33.2446 10.468C33.2446 11.0203 33.6923 11.468 34.2446 11.468C34.7969 11.468 35.2446 11.0203 35.2446 10.468H33.2446ZM4.21826 23.8529H5.21826C5.21826 23.3006 4.77055 22.8529 4.21826 22.8529V23.8529ZM4.21826 31.0462H3.21826C3.21826 31.4302 3.4382 31.7804 3.78418 31.9471C4.13016 32.1138 4.54105 32.0676 4.84141 31.8283L4.21826 31.0462ZM13.2465 23.8529V22.8529C13.0201 22.8529 12.8004 22.9297 12.6233 23.0708L13.2465 23.8529ZM14.7152 24.8529C15.2675 24.8529 15.7152 24.4052 15.7152 23.8529C15.7152 23.3006 15.2675 22.8529 14.7152 22.8529V24.8529ZM1.99463 2.55273H33.4946V0.552734H1.99463V2.55273ZM2.24463 23.1096V2.29607H0.244629V23.1096H2.24463ZM33.2446 2.29607V10.468H35.2446V2.29607H33.2446ZM4.21826 22.8529H1.99463V24.8529H4.21826V22.8529ZM0.244629 23.1096C0.244629 24.0808 1.03656 24.8529 1.99463 24.8529V22.8529C2.12427 22.8529 2.24463 22.9594 2.24463 23.1096H0.244629ZM33.4946 2.55273C33.365 2.55273 33.2446 2.44621 33.2446 2.29607H35.2446C35.2446 1.32486 34.4527 0.552734 33.4946 0.552734V2.55273ZM1.99463 0.552734C1.03656 0.552734 0.244629 1.32486 0.244629 2.29607H2.24463C2.24463 2.44622 2.12427 2.55273 1.99463 2.55273V0.552734ZM5.21826 31.0462V23.8529H3.21826V31.0462H5.21826ZM4.84141 31.8283L13.8696 24.635L12.6233 23.0708L3.59511 30.2641L4.84141 31.8283ZM13.2465 24.8529H14.7152V22.8529H13.2465V24.8529Z'
                  fill='#B0B0B0'
                />
                <path
                  d='M39.2015 29.7712H38.2015C38.2015 29.2189 38.6492 28.7712 39.2015 28.7712V29.7712ZM39.2015 34.5624H40.2015C40.2015 34.9464 39.9816 35.2965 39.6356 35.4632C39.2896 35.6299 38.8787 35.5838 38.5784 35.3445L39.2015 34.5624ZM33.1882 29.7712V28.7712C33.4146 28.7712 33.6343 28.848 33.8114 28.9891L33.1882 29.7712ZM19.2022 29.7712V30.7712C18.6499 30.7712 18.2022 30.3235 18.2022 29.7712H19.2022ZM40.6826 15.918H19.7018V13.918H40.6826V15.918ZM40.1821 29.2761V15.4131H42.1821V29.2761H40.1821ZM20.2022 15.4131V20.8561H18.2022V15.4131H20.2022ZM39.2015 28.7712H40.6826V30.7712H39.2015V28.7712ZM42.1821 29.2761C42.1821 30.1102 41.5023 30.7712 40.6826 30.7712V28.7712C40.4146 28.7712 40.1821 28.9889 40.1821 29.2761H42.1821ZM19.7018 15.918C19.9697 15.918 20.2022 15.7003 20.2022 15.4131H18.2022C18.2022 14.579 18.882 13.918 19.7018 13.918V15.918ZM40.6826 13.918C41.5023 13.918 42.1821 14.579 42.1821 15.4131H40.1821C40.1821 15.7003 40.4146 15.918 40.6826 15.918V13.918ZM38.2015 34.5624V29.7712H40.2015V34.5624H38.2015ZM38.5784 35.3445L32.5651 30.5533L33.8114 28.9891L39.8247 33.7803L38.5784 35.3445ZM33.1882 30.7712H32.21V28.7712H33.1882V30.7712ZM20.2022 20.8561V29.7712H18.2022V20.8561H20.2022ZM19.2022 28.7712H32.21V30.7712H19.2022V28.7712Z'
                  fill='#B0B0B0'
                />
                <path
                  d='M26.2275 22.3519C26.2275 22.8926 25.784 23.3386 25.2275 23.3386C24.671 23.3386 24.2275 22.8926 24.2275 22.3519C24.2275 21.8112 24.671 21.3652 25.2275 21.3652C25.784 21.3652 26.2275 21.8112 26.2275 22.3519Z'
                  fill='#B87693'
                  stroke='#B0B0B0'
                />
                <path
                  d='M30.7236 22.3519C30.7236 22.8926 30.2801 23.3386 29.7236 23.3386C29.1671 23.3386 28.7236 22.8926 28.7236 22.3519C28.7236 21.8112 29.1671 21.3652 29.7236 21.3652C30.2801 21.3652 30.7236 21.8112 30.7236 22.3519Z'
                  fill='#B87693'
                  stroke='#B0B0B0'
                />
                <path
                  d='M35.2188 22.3519C35.2188 22.8926 34.7752 23.3386 34.2188 23.3386C33.6623 23.3386 33.2188 22.8926 33.2188 22.3519C33.2188 21.8112 33.6623 21.3652 34.2188 21.3652C34.7752 21.3652 35.2188 21.8112 35.2188 22.3519Z'
                  fill='#B87693'
                  stroke='#B0B0B0'
                />
              </svg>
            </NavLink>
          </li>
        </ul>
      </div>
      <div className='detail-content'>
        {params.pathname === '/detail/about' && <About modelInfo={modelInfo} />}
        {params.pathname === '/detail/photos' && (
          <Photos modelInfo={modelInfo} />
        )}
        {params.pathname === '/detail/comments' && (
          <Comments modelInfo={modelInfo} />
        )}
        {params.pathname === '/detail/fanclub' && (
          <Fanclub modelInfo={modelInfo} />
        )}

        <div className='detail-content-advertising'>
          <img src={require('../../../images/trashimg/image2.6.jpg')} alt='' />
        </div>
      </div>
    </section>
  );
};

export default Content;