import { useEffect, useState } from 'react';
import Similar from '../components/similar/Similar.js';
import Slider from 'react-slick';
import ModelCard from '../components/card/ModelCard.js';
import Spinner from '../../custom/spinner/Spinner.js';
import { useQuery } from 'react-query';
import { getModels } from '../../request/request.js';
import Pagination from '../../custom/pagination/Pagination.js';

const TopModels = () => {
	const [pageNumber, setPageNumber] = useState(1);
	const [topModels, setTopModels] = useState([]);

	useEffect(() => {
		let countdown = document.getElementById('countdown');

		let d = [1, 2, 3, 4, 5, 6, 7];
		let today = new Date();
		let end = new Date(today.getFullYear(), today.getMonth(), today.getDate() + d[today.getDay()], 23, 59, 59);
		let _second = 1000;
		let _minute = _second * 60;
		let _hour = _minute * 60;
		let _day = _hour * 24;

		function showRemaining() {
			let now = new Date();
			let distance = end - now;
			if (distance < 0) {
				return;
			}
			let days = Math.floor(distance / _day);
			let hours = Math.floor((distance % _day) / _hour);
			if (hours < 10) hours = '0' + hours;
			let minutes = Math.floor((distance % _hour) / _minute);
			if (minutes < 10) minutes = '0' + minutes;
			let seconds = Math.floor((distance % _minute) / _second);
			if (seconds < 10) seconds = '0' + seconds;
			if (countdown) {
				countdown.innerHTML = days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's';
			}
		}
		setInterval(showRemaining, 1000);
	}, []);

	const { data, isErrror, error, isFetching } = useQuery(
		['top-moddels', pageNumber],
		() => getModels({ pageNumber, filterBy: 'top-models' }),
		{
			onSuccess: response => {
				setTopModels(response?.models);
			},
		}
	);

	const pageSize = data?.pageSize ?? 0;
	const pageCount = data?.pageCount ?? 0;

	const errorMessage = error?.response?.data?.message ?? error?.message;

	// const settings = {
	// 	className: 'center',
	// 	centerMode: true,
	// 	centerPadding: '0',
	// 	infinite: true,
	// 	dots: false,
	// 	nav: true,
	// 	slidesToShow: 3,
	// 	nextArrow: <SampleNextArrow />,
	// 	prevArrow: <SamplePrevArrow />,
	// };

	const handlePagination = pagenumber => {
		setPageNumber(pagenumber);
	};

	if (isFetching) return <Spinner width={50} height={50} />;
	if (isErrror) return <p className='error'>{errorMessage}</p>;

	return (
		<main>
			{/* <section className='top-models'>
				<div className='container'>
					<div className='top-models-top'>
						<div className='page-title'>Top models</div>
						<div className='top-models--time'>
							<strong>New top rated model challenge</strong>
							<span id='countdown'></span>
						</div>
					</div>
				</div>
				<Slider {...settings} className='top-models-slider'>
					<div className='top-models-item'>
						<div className='top-models-item-content'>
							<div className='top-models-item--status'>Free chat</div>
							<div className='top-models-item--name'>Megan Live</div>
							<div className='top-models-item--points'>47,376 points</div>
							<div className='top-models-item--link'>
								<svg xmlns='http://www.w3.org/2000/svg' width='31' height='31' viewBox='0 0 31 31' fill='none'>
									<circle opacity='0.6' cx='15.5' cy='15.5' r='15' stroke='white' />
									<path d='M24 15.5L11.25 22.8612L11.25 8.13878L24 15.5Z' fill='white' />
								</svg>
								Go to the broadcast
							</div>
							<div className='top-models-item--vote btn btn__red'>Vote for me</div>
						</div>
						<div className='top-models-item--image'>
							<img src={topModels[0].profilePicUrl} alt='' />
						</div>
						<div className='top-models-item--top'>1</div>
					</div>
					<div className='top-models-item'>
						<div className='top-models-item-content'>
							<div className='top-models-item--status'>Online</div>
							<div className='top-models-item--name'>Megan Live</div>
							<div className='top-models-item--points'>47,376 points</div>
							<div className='top-models-item--link'>
								<svg xmlns='http://www.w3.org/2000/svg' width='31' height='31' viewBox='0 0 31 31' fill='none'>
									<circle opacity='0.6' cx='15.5' cy='15.5' r='15' stroke='white' />
									<path d='M24 15.5L11.25 22.8612L11.25 8.13878L24 15.5Z' fill='white' />
								</svg>
								Go to the broadcast
							</div>
							<div className='top-models-item--vote btn btn__red'>Vote for me</div>
						</div>
						<div className='top-models-item--image'>
							<img src={require('../.././images/trashimg/dfgdfgdf.png')} alt='' />
						</div>
						<div className='top-models-item--top'>2</div>
					</div>
					<div className='top-models-item'>
						<div className='top-models-item-content'>
							<div className='top-models-item--status'>Online</div>
							<div className='top-models-item--name'>Megan Live</div>
							<div className='top-models-item--points'>47,376 points</div>
							<div className='top-models-item--link'>
								<svg xmlns='http://www.w3.org/2000/svg' width='31' height='31' viewBox='0 0 31 31' fill='none'>
									<circle opacity='0.6' cx='15.5' cy='15.5' r='15' stroke='white' />
									<path d='M24 15.5L11.25 22.8612L11.25 8.13878L24 15.5Z' fill='white' />
								</svg>
								Go to the broadcast
							</div>
							<div className='top-models-item--vote btn btn__red'>Vote for me</div>
						</div>
						<div className='top-models-item--image'>
							<img src={require('../.././images/trashimg/dfgdfgdf.png')} alt='' />
						</div>
						<div className='top-models-item--top'>3</div>
					</div>
					<div className='top-models-item'>
						<div className='top-models-item-content'>
							<div className='top-models-item--status'>Online</div>
							<div className='top-models-item--name'>Megan Live</div>
							<div className='top-models-item--points'>47,376 points</div>
							<div className='top-models-item--link'>
								<svg xmlns='http://www.w3.org/2000/svg' width='31' height='31' viewBox='0 0 31 31' fill='none'>
									<circle opacity='0.6' cx='15.5' cy='15.5' r='15' stroke='white' />
									<path d='M24 15.5L11.25 22.8612L11.25 8.13878L24 15.5Z' fill='white' />
								</svg>
								Go to the broadcast
							</div>
							<div className='top-models-item--vote btn btn__red'>Vote for me</div>
						</div>
						<div className='top-models-item--image'>
							<img src={require('../.././images/trashimg/dfgdfgdf.png')} alt='' />
						</div>
						<div className='top-models-item--top'>4</div>
					</div>
					<div className='top-models-item'>
						<div className='top-models-item-content'>
							<div className='top-models-item--status'>Online</div>
							<div className='top-models-item--name'>Megan Live</div>
							<div className='top-models-item--points'>47,376 points</div>
							<div className='top-models-item--link'>
								<svg xmlns='http://www.w3.org/2000/svg' width='31' height='31' viewBox='0 0 31 31' fill='none'>
									<circle opacity='0.6' cx='15.5' cy='15.5' r='15' stroke='white' />
									<path d='M24 15.5L11.25 22.8612L11.25 8.13878L24 15.5Z' fill='white' />
								</svg>
								Go to the broadcast
							</div>
							<div className='top-models-item--vote btn btn__red'>Vote for me</div>
						</div>
						<div className='top-models-item--image'>
							<img src={require('../.././images/trashimg/dfgdfgdf.png')} alt='' />
						</div>
						<div className='top-models-item--top'>5</div>
					</div>
				</Slider>
			</section> */}
			<section className='top-models-section top-models'>
				<div className='container'>
					<div className='top-model-cards'>
						{topModels?.length > 0 ? (
							topModels?.map(model => (
								<ModelCard
									model={model}
									key={model?.registerId}
									modelType={'Top Model'}
									updateModel={setTopModels}
								/>
							))
						) : (
							<p className='no-available-data'>no top model</p>
						)}
					</div>
					<Pagination pageCount={pageCount} pageSize={pageSize} onChange={handlePagination} />
				</div>
			</section>
			<section className='main-reklama'>
				<div className='container'>
					<img src={require('../.././images/trashimg/mainbanner.jpg')} alt='' />
				</div>
			</section>
			{/* <section className='container'>
				<Similar />
			</section> */}
		</main>
	);
};

export default TopModels;

function SampleNextArrow(props) {
	const { className, onClick } = props;
	return (
		<div className={className} onClick={onClick}>
			<svg xmlns='http://www.w3.org/2000/svg' width='17' height='29' viewBox='0 0 17 29' fill='none'>
				<path
					d='M1.28985 1.27626C0.90338 1.64839 0.903381 2.25311 1.28985 2.62524L13.6329 14.487L1.28986 26.3719C0.903382 26.7441 0.903382 27.3488 1.28986 27.7209C1.67633 28.093 2.30435 28.093 2.69082 27.7209L15.7101 15.1847C15.9034 14.9986 16 14.766 16 14.5102C16 14.2776 15.9034 14.0218 15.7101 13.8357L2.69082 1.29952C2.30435 0.904127 1.67633 0.904125 1.28985 1.27626Z'
					fill='white'
					stroke='white'
					strokeWidth='0.4'
				/>
			</svg>
		</div>
	);
}

function SamplePrevArrow(props) {
	const { className, onClick } = props;
	return (
		<div className={className} onClick={onClick}>
			<svg xmlns='http://www.w3.org/2000/svg' width='17' height='29' viewBox='0 0 17 29' fill='none'>
				<path
					d='M15.7101 1.27626C16.0966 1.64839 16.0966 2.25311 15.7101 2.62524L3.36715 14.487L15.7101 26.3719C16.0966 26.7441 16.0966 27.3488 15.7101 27.7209C15.3237 28.093 14.6957 28.093 14.3092 27.7209L1.28985 15.1847C1.09662 14.9986 1 14.766 1 14.5102C1 14.2776 1.09662 14.0218 1.28985 13.8357L14.3092 1.29952C14.6957 0.904127 15.3237 0.904125 15.7101 1.27626Z'
					fill='white'
					stroke='white'
					strokeWidth='0.4'
				/>
			</svg>
		</div>
	);
}
