import React, { Component, useState } from 'react';
import Popup from 'reactjs-popup';
import Spinner from '../../custom/spinner/Spinner';

const LkEditDelAcc = ({
  deleteAccount,
  open,
  closeModal,
  openModal,
  deleting,
}) => {
  return (
    <div className='reg-data--delAcc'>
      <span onClick={openModal}>Delete account</span>
      <Popup open={open} closeOnDocumentClick lockScroll onClose={closeModal}>
        <div className='editInfo-popup editInfo-popup-password editInfo-popup-delAcc'>
          <span className='login-popup--close' onClick={closeModal}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='18'
              height='18'
              viewBox='0 0 18 18'
              fill='none'
            >
              <path
                d='M17.7071 1.70711C18.0976 1.31658 18.0976 0.683417 17.7071 0.292893C17.3166 -0.0976311 16.6834 -0.0976311 16.2929 0.292893L17.7071 1.70711ZM0.292893 16.2929C-0.0976311 16.6834 -0.0976311 17.3166 0.292893 17.7071C0.683417 18.0976 1.31658 18.0976 1.70711 17.7071L0.292893 16.2929ZM16.2929 17.7071C16.6834 18.0976 17.3166 18.0976 17.7071 17.7071C18.0976 17.3166 18.0976 16.6834 17.7071 16.2929L16.2929 17.7071ZM1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L1.70711 0.292893ZM16.2929 0.292893L0.292893 16.2929L1.70711 17.7071L17.7071 1.70711L16.2929 0.292893ZM17.7071 16.2929L1.70711 0.292893L0.292893 1.70711L16.2929 17.7071L17.7071 16.2929Z'
                fill='white'
              />
            </svg>
          </span>
          <div className='editInfo-popup--content'>
            <div className='editInfo-popup--title'>Delete account</div>
            <form className='editInfo-popup--form'>
              <h1 className='text-center mb-[1rem]'>
                Your account will deleted from the system
              </h1>
              <button
                type='button'
                onClick={deleteAccount}
                className='btn btn__red editInfo-popup--btn !flex items-center justify-center !gap-1'
              >
                {deleting && <Spinner />}
                Delete account
              </button>
            </form>
          </div>
        </div>
      </Popup>
    </div>
  );
};
export default LkEditDelAcc;
