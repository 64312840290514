import logo from './footer-logo.svg';
import { Link } from 'react-router-dom';
import { WINDOW_WIDTH, MOBILE } from '../../const/Const';
import FormSubscribe from '../components/subscribe/Subscribe';
import { useSelector } from 'react-redux';

const Footer = () => {
	const token = useSelector(state => state?.user?.token);

	if (WINDOW_WIDTH <= MOBILE) {
		return (
			<footer className='footer-mobile'>
				<div className='container'>
					<div className='footer-mobile--title1'>INFORMATION</div>
					<div className='footer-mobile--title2'>Subscribe to our newsletter</div>
					<FormSubscribe />
					<div className='footer-mobile--title3'>Follow us now!</div>
					{/* <ul className='footer-subscribe--links'>
						<li>
							<Link
								to='https://www.facebook.com/nationalstripclubassociation'
								target='_blank'
								rel='noopener noreferrer'
							>
								<svg width='14' height='31' viewBox='0 0 14 31' fill='none' xmlns='http://www.w3.org/2000/svg'>
									<path
										d='M0.447013 16.5106H3.485V30.5C3.485 30.7762 3.68509 31 3.93202 31H9.08304C9.32997 31 9.53005 30.7762 9.53005 30.5V16.5765H13.0225C13.2496 16.5765 13.4406 16.3859 13.4665 16.1336L13.997 10.9834C14.0115 10.8417 13.9714 10.6998 13.8866 10.5935C13.8018 10.4871 13.6804 10.4262 13.553 10.4262H9.53023V7.1978C9.53023 6.2246 9.9987 5.7311 10.9228 5.7311C11.0545 5.7311 13.553 5.7311 13.553 5.7311C13.7999 5.7311 14 5.5072 14 5.2311V0.5037C14 0.2275 13.7999 0.0037 13.553 0.0037H9.92816C9.90259 0.0023 9.84582 0 9.76214 0C9.13319 0 6.94703 0.1381 5.22013 1.9151C3.30673 3.8843 3.57271 6.2421 3.63627 6.6509V10.4261H0.447013C0.200083 10.4261 0 10.6499 0 10.9261V16.0105C0 16.2867 0.200083 16.5106 0.447013 16.5106Z'
										fill='#DADADA'
									/>
								</svg>
							</Link>
						</li>
						<li>
							<Link to='https://twitter.com/@NSA_alpha' target='_blank' rel='noopener noreferrer'>
								<svg width='28' height='24' viewBox='0 0 28 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
									<path
										d='M27.3654 2.76977C26.9255 2.97402 26.4733 3.1464 26.0112 3.28635C26.5583 2.63861 26.9754 1.87646 27.23 1.04243C27.2871 0.855488 27.2279 0.650859 27.0812 0.528593C26.9346 0.406232 26.7309 0.391575 26.5695 0.491714C25.5881 1.10106 24.5294 1.53897 23.4192 1.79504C22.301 0.651049 20.7821 0 19.2119 0C15.8976 0 13.2011 2.82291 13.2011 6.29271C13.2011 6.56599 13.2176 6.83776 13.2503 7.10574C9.13747 6.72769 5.31386 4.61133 2.68819 1.2394C2.59462 1.11922 2.45046 1.05444 2.30288 1.06683C2.1552 1.07893 2.02252 1.16612 1.94791 1.30011C1.41537 2.25678 1.13383 3.35094 1.13383 4.4642C1.13383 5.98047 1.65093 7.41912 2.56436 8.54325C2.28662 8.44254 2.0171 8.31668 1.75986 8.16718C1.62176 8.08671 1.45321 8.08794 1.31601 8.1703C1.17873 8.25266 1.09265 8.40415 1.08904 8.56973C1.0884 8.59762 1.0884 8.62552 1.0884 8.65379C1.0884 10.9171 2.25193 12.9548 4.03083 14.0654C3.87801 14.0494 3.72527 14.0262 3.57353 13.9959C3.41709 13.9646 3.25623 14.022 3.15073 14.1469C3.04505 14.2717 3.0101 14.4457 3.05878 14.6046C3.71723 16.7568 5.41249 18.3398 7.4619 18.8224C5.76213 19.937 3.8183 20.5208 1.77774 20.5208C1.35196 20.5208 0.923745 20.4946 0.50465 20.4427C0.296457 20.4168 0.0973866 20.5455 0.0264836 20.7528C-0.0444194 20.9602 0.0307287 21.1914 0.207851 21.3102C2.82927 23.0699 5.8604 24 8.97336 24C15.0931 24 18.9214 20.9788 21.0552 18.4443C23.7161 15.284 25.2422 11.1009 25.2422 6.96778C25.2422 6.79511 25.2397 6.62074 25.2346 6.44694C26.2844 5.61887 27.1883 4.61672 27.9238 3.46488C28.0355 3.28995 28.0234 3.05875 27.894 2.89771C27.7647 2.73658 27.5493 2.68448 27.3654 2.76977Z'
										fill='#DADADA'
									/>
								</svg>
							</Link>
						</li>
						<li>
							<Link to='https://www.google.com/' target='_blank' rel='noopener noreferrer'>
								<svg width='27' height='27' viewBox='0 0 27 27' fill='none' xmlns='http://www.w3.org/2000/svg'>
									<path
										d='M19.6913 0H7.30864C3.27859 0 0 3.27859 0 7.30864V19.6915C0 23.7214 3.27859 26.9999 7.30864 26.9999H19.6915C23.7214 26.9999 26.9999 23.7214 26.9999 19.6915V7.30864C26.9999 3.27859 23.7214 0 19.6913 0V0ZM13.5 20.8826C9.42914 20.8826 6.11738 17.5708 6.11738 13.5C6.11738 9.42914 9.42914 6.11738 13.5 6.11738C17.5708 6.11738 20.8826 9.42914 20.8826 13.5C20.8826 17.5708 17.5708 20.8826 13.5 20.8826ZM21.0591 7.85844C19.8561 7.85844 18.8776 6.87997 18.8776 5.67697C18.8776 4.47397 19.8561 3.49529 21.0591 3.49529C22.2621 3.49529 23.2408 4.47397 23.2408 5.67697C23.2408 6.87997 22.2621 7.85844 21.0591 7.85844Z'
										fill='#DADADA'
									/>
									<path
										d='M13.499 7.7002C10.3011 7.7002 7.69922 10.3019 7.69922 13.4999C7.69922 16.6978 10.3011 19.2997 13.499 19.2997C16.697 19.2997 19.2987 16.6978 19.2987 13.4999C19.2987 10.3019 16.697 7.7002 13.499 7.7002Z'
										fill='#DADADA'
									/>
									<path
										d='M21.0596 5.07812C20.7296 5.07812 20.4609 5.34674 20.4609 5.67674C20.4609 6.00674 20.7296 6.27536 21.0596 6.27536C21.3898 6.27536 21.6584 6.00695 21.6584 5.67674C21.6584 5.34653 21.3898 5.07812 21.0596 5.07812Z'
										fill='#DADADA'
									/>
								</svg>
							</Link>
						</li>
						<li>
							<Link to='https://www.google.com/' target='_blank' rel='noopener noreferrer'>
								<svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
									<path
										d='M25.9729 24.6062C27.1129 24.4274 29.4717 24.0149 29.9229 22.9762C30.1504 22.4412 29.8254 22.0062 29.4104 21.9399V21.9387C25.4004 21.2787 23.5929 17.1712 23.5192 16.9962C22.4079 15.0037 25.4242 15.3387 26.6104 14.1237C27.7679 12.9324 25.9117 11.6424 24.7504 12.1899C24.2742 12.4137 23.5604 12.6737 23.0854 12.4562C23.1979 10.5699 23.5092 7.4424 22.7454 5.7349C20.1767 -0.0188468 12.6017 -0.226346 9.08169 3.11365C6.30794 5.74365 6.64794 8.4474 6.90794 12.4562C6.04544 12.8324 5.19294 12.0849 4.57169 12.0849C3.91919 12.0849 3.16044 12.5087 3.03794 13.1474C2.88294 13.9862 3.79419 14.5262 4.58294 14.8374C5.92669 15.3649 7.20419 15.5212 6.49544 16.9612C6.48919 16.9724 6.48419 16.9837 6.48044 16.9949C5.47669 19.2874 3.28544 21.4912 0.587938 21.9362C0.152938 22.0074 -0.144562 22.4662 0.0729381 22.9724V22.9749C0.519188 24.0149 2.90044 24.4312 4.02419 24.6062C4.37169 25.2562 4.10544 26.5399 5.18169 26.5399C5.60044 26.5399 6.41044 26.2662 7.44419 26.2662C8.95044 26.2662 9.46419 26.5949 10.6717 27.4474C11.9117 28.3237 13.2742 29.1799 14.9679 29.0562C16.7317 29.1662 18.0417 28.3549 19.3267 27.4499L19.3279 27.4474C20.5804 26.5662 21.4754 25.9149 24.1279 26.4362C26.0904 26.8162 25.5179 25.4874 25.9729 24.6062Z'
										fill='#DADADA'
									/>
								</svg>
							</Link>
						</li>
					</ul> */}
					<div className='footer-mobile--copyright'>
						© NationalStripClubAssociation.com. 2012–{new Date().getFullYear()}
					</div>
				</div>
			</footer>
		);
	} else {
		return (
			<footer className='footer'>
				<div className='container'>
					<div className='footer-info'>
						<Link to='/'>
							<img src={logo} alt='' />
						</Link>
						<Link
							to='/Adult model release 2257 compliant form.pdf'
							download
							className='footer-info--text'
							target='_blank'
						>
							<p>
								All models are over the age of 18 <br /> and compliant with Title 19 U.S.C. 2257
							</p>
						</Link>
						<div className='footer-info--copyright'>
							© National Strip Club Association Inc, Columbia, South Carolina, United States
						</div>
					</div>

					<div className='footer-menu'>
						<div className='footer-menu--title'>Work With Us</div>
						<div className='footer-menu--submenu'>{!token && <>{/* <MemberAuth />
									<MemberAuth /> */}</>}</div>
					</div>
					<div className='footer-menu'>
						<div className='footer-menu--title'>information</div>
						<ul>
							<li>
								<Link to='/contact-us'>Contact us and Content Removal Policy</Link>
							</li>
							<li>
								<Link to='/terms-and-conditions'>Teams & Conditions</Link>
							</li>
							<li>
								<Link to='/privacy-policy'>Privacy Policy</Link>
							</li>
						</ul>
					</div>
					<div className='footer-subscribe'>
						<div className='footer-subscribe--title'>Subscribe to our newsletter</div>
						<FormSubscribe />
						<div className='footer-subscribe--folow'>Follow us now!</div>
						{/* <ul className='footer-subscribe--links'>
							<li>
								<Link
									to='https://www.facebook.com/nationalstripclubassociation'
									target='_blank'
									rel='noopener noreferrer'
								>
									<svg
										width='14'
										height='31'
										viewBox='0 0 14 31'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M0.447013 16.5106H3.485V30.5C3.485 30.7762 3.68509 31 3.93202 31H9.08304C9.32997 31 9.53005 30.7762 9.53005 30.5V16.5765H13.0225C13.2496 16.5765 13.4406 16.3859 13.4665 16.1336L13.997 10.9834C14.0115 10.8417 13.9714 10.6998 13.8866 10.5935C13.8018 10.4871 13.6804 10.4262 13.553 10.4262H9.53023V7.1978C9.53023 6.2246 9.9987 5.7311 10.9228 5.7311C11.0545 5.7311 13.553 5.7311 13.553 5.7311C13.7999 5.7311 14 5.5072 14 5.2311V0.5037C14 0.2275 13.7999 0.0037 13.553 0.0037H9.92816C9.90259 0.0023 9.84582 0 9.76214 0C9.13319 0 6.94703 0.1381 5.22013 1.9151C3.30673 3.8843 3.57271 6.2421 3.63627 6.6509V10.4261H0.447013C0.200083 10.4261 0 10.6499 0 10.9261V16.0105C0 16.2867 0.200083 16.5106 0.447013 16.5106Z'
											fill='#DADADA'
										/>
									</svg>
								</Link>
							</li>
							<li>
								<Link to='https://twitter.com/@NSA_alpha' target='_blank' rel='noopener noreferrer'>
									<svg
										width='28'
										height='24'
										viewBox='0 0 28 24'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M27.3654 2.76977C26.9255 2.97402 26.4733 3.1464 26.0112 3.28635C26.5583 2.63861 26.9754 1.87646 27.23 1.04243C27.2871 0.855488 27.2279 0.650859 27.0812 0.528593C26.9346 0.406232 26.7309 0.391575 26.5695 0.491714C25.5881 1.10106 24.5294 1.53897 23.4192 1.79504C22.301 0.651049 20.7821 0 19.2119 0C15.8976 0 13.2011 2.82291 13.2011 6.29271C13.2011 6.56599 13.2176 6.83776 13.2503 7.10574C9.13747 6.72769 5.31386 4.61133 2.68819 1.2394C2.59462 1.11922 2.45046 1.05444 2.30288 1.06683C2.1552 1.07893 2.02252 1.16612 1.94791 1.30011C1.41537 2.25678 1.13383 3.35094 1.13383 4.4642C1.13383 5.98047 1.65093 7.41912 2.56436 8.54325C2.28662 8.44254 2.0171 8.31668 1.75986 8.16718C1.62176 8.08671 1.45321 8.08794 1.31601 8.1703C1.17873 8.25266 1.09265 8.40415 1.08904 8.56973C1.0884 8.59762 1.0884 8.62552 1.0884 8.65379C1.0884 10.9171 2.25193 12.9548 4.03083 14.0654C3.87801 14.0494 3.72527 14.0262 3.57353 13.9959C3.41709 13.9646 3.25623 14.022 3.15073 14.1469C3.04505 14.2717 3.0101 14.4457 3.05878 14.6046C3.71723 16.7568 5.41249 18.3398 7.4619 18.8224C5.76213 19.937 3.8183 20.5208 1.77774 20.5208C1.35196 20.5208 0.923745 20.4946 0.50465 20.4427C0.296457 20.4168 0.0973866 20.5455 0.0264836 20.7528C-0.0444194 20.9602 0.0307287 21.1914 0.207851 21.3102C2.82927 23.0699 5.8604 24 8.97336 24C15.0931 24 18.9214 20.9788 21.0552 18.4443C23.7161 15.284 25.2422 11.1009 25.2422 6.96778C25.2422 6.79511 25.2397 6.62074 25.2346 6.44694C26.2844 5.61887 27.1883 4.61672 27.9238 3.46488C28.0355 3.28995 28.0234 3.05875 27.894 2.89771C27.7647 2.73658 27.5493 2.68448 27.3654 2.76977Z'
											fill='#DADADA'
										/>
									</svg>
								</Link>
							</li>
							<li>
								<Link
									to='https://www.instagram.com/nationalstripclub/'
									target='_blank'
									rel='noopener noreferrer'
								>
									<svg
										width='27'
										height='27'
										viewBox='0 0 27 27'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M19.6913 0H7.30864C3.27859 0 0 3.27859 0 7.30864V19.6915C0 23.7214 3.27859 26.9999 7.30864 26.9999H19.6915C23.7214 26.9999 26.9999 23.7214 26.9999 19.6915V7.30864C26.9999 3.27859 23.7214 0 19.6913 0V0ZM13.5 20.8826C9.42914 20.8826 6.11738 17.5708 6.11738 13.5C6.11738 9.42914 9.42914 6.11738 13.5 6.11738C17.5708 6.11738 20.8826 9.42914 20.8826 13.5C20.8826 17.5708 17.5708 20.8826 13.5 20.8826ZM21.0591 7.85844C19.8561 7.85844 18.8776 6.87997 18.8776 5.67697C18.8776 4.47397 19.8561 3.49529 21.0591 3.49529C22.2621 3.49529 23.2408 4.47397 23.2408 5.67697C23.2408 6.87997 22.2621 7.85844 21.0591 7.85844Z'
											fill='#DADADA'
										/>
										<path
											d='M13.499 7.7002C10.3011 7.7002 7.69922 10.3019 7.69922 13.4999C7.69922 16.6978 10.3011 19.2997 13.499 19.2997C16.697 19.2997 19.2987 16.6978 19.2987 13.4999C19.2987 10.3019 16.697 7.7002 13.499 7.7002Z'
											fill='#DADADA'
										/>
										<path
											d='M21.0596 5.07812C20.7296 5.07812 20.4609 5.34674 20.4609 5.67674C20.4609 6.00674 20.7296 6.27536 21.0596 6.27536C21.3898 6.27536 21.6584 6.00695 21.6584 5.67674C21.6584 5.34653 21.3898 5.07812 21.0596 5.07812Z'
											fill='#DADADA'
										/>
									</svg>
								</Link>
							</li>
							<li>
								<Link to='https://www.snapchat.com/add/nsa_alpha' target='_blank' rel='noopener noreferrer'>
									<svg
										width='30'
										height='30'
										viewBox='0 0 30 30'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M25.9729 24.6062C27.1129 24.4274 29.4717 24.0149 29.9229 22.9762C30.1504 22.4412 29.8254 22.0062 29.4104 21.9399V21.9387C25.4004 21.2787 23.5929 17.1712 23.5192 16.9962C22.4079 15.0037 25.4242 15.3387 26.6104 14.1237C27.7679 12.9324 25.9117 11.6424 24.7504 12.1899C24.2742 12.4137 23.5604 12.6737 23.0854 12.4562C23.1979 10.5699 23.5092 7.4424 22.7454 5.7349C20.1767 -0.0188468 12.6017 -0.226346 9.08169 3.11365C6.30794 5.74365 6.64794 8.4474 6.90794 12.4562C6.04544 12.8324 5.19294 12.0849 4.57169 12.0849C3.91919 12.0849 3.16044 12.5087 3.03794 13.1474C2.88294 13.9862 3.79419 14.5262 4.58294 14.8374C5.92669 15.3649 7.20419 15.5212 6.49544 16.9612C6.48919 16.9724 6.48419 16.9837 6.48044 16.9949C5.47669 19.2874 3.28544 21.4912 0.587938 21.9362C0.152938 22.0074 -0.144562 22.4662 0.0729381 22.9724V22.9749C0.519188 24.0149 2.90044 24.4312 4.02419 24.6062C4.37169 25.2562 4.10544 26.5399 5.18169 26.5399C5.60044 26.5399 6.41044 26.2662 7.44419 26.2662C8.95044 26.2662 9.46419 26.5949 10.6717 27.4474C11.9117 28.3237 13.2742 29.1799 14.9679 29.0562C16.7317 29.1662 18.0417 28.3549 19.3267 27.4499L19.3279 27.4474C20.5804 26.5662 21.4754 25.9149 24.1279 26.4362C26.0904 26.8162 25.5179 25.4874 25.9729 24.6062Z'
											fill='#DADADA'
										/>
									</svg>
								</Link>
							</li>
						</ul> */}
					</div>
				</div>
			</footer>
		);
	}
};
export default Footer;
