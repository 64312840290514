import classNames from 'classnames';
import React, { ReactElement, useEffect } from 'react';
import Select from 'react-dropdown-select';
import styles from './dropDown.module.scss';
import { IoIosArrowDown } from 'react-icons/io';

const DropDown = (props) => {
  const {
    dropElement: DropElement,
    className,
    onChange,
    options = [],
    placeholder,
    customItemRender,
    selectClass,
    dropDownClass,
    defaultValue = [],
    enableHandle = false,
  } = props;
  const handleOnChange = (data) => {
    if (onChange) {
      onChange(data[0]);
    }
  };

  const itemRenderer = ({ item, itemIndex, props, state, methods }) => (
    <div key={itemIndex} onClick={() => methods.addItem(item)}>
      <span
        className={classNames(
          styles.dropDownItem,
          'hover:bg-[#936FE0] text-white'
        )}
      >
        {item[props.labelField]}
      </span>
    </div>
  );

  const dropdownHandleRenderer = ({ state }) => {
    return (
      <span className={styles.dropIconContainer}>
        {' '}
        <IoIosArrowDown
          className={classNames(
            styles.dropIcon,
            state.dropdown && styles.rotateIcon
          )}
        />
      </span>
    );
  };

  const DropDownRender = ({ state, props, methods }) => {
    const regexp = new RegExp(state.search, 'i');
    const { itemRenderer: ItemRenderer } = props;

    const injectClass = () => {
      const dropDown = document.querySelector(
        '.react-dropdown-select-dropdown'
      );
      if (dropDown) {
        dropDown.classList.remove(dropDownClass);
        dropDown.classList.add(dropDownClass);
        // dropDown.classList.add('w-[100%]');
      }
    };

    useEffect(() => {
      if (dropDownClass) {
        injectClass();
      }
    }, [state.dropdown]);

    return (
      <>
        {props.options
          .filter((item, index) =>
            regexp.test(item[props.searchBy] || item.label)
          )
          .map((option, index) => (
            <ItemRenderer
              state={state}
              props={props}
              methods={methods}
              itemIndex={index}
              item={option}
            />
          ))}
      </>
    );
  };

  return (
    <div className={classNames(className, styles.container)}>
      <Select
        placeholder={placeholder}
        defaultValue={defaultValue.length > 0 ? defaultValue : [options[0]]}
        onChange={handleOnChange}
        itemRenderer={customItemRender ? customItemRender : itemRenderer}
        // autoFocus={false}
        dropdownHandleRenderer={dropdownHandleRenderer}
        dropdownHandle={enableHandle}
        dropdownRenderer={DropDownRender}
        options={options}
        contentRenderer={DropElement}
        className={classNames(
          styles.select,
          selectClass,
          ' h-12 rounded-[0rem]'
        )}
      />
    </div>
  );
};

export default DropDown;
