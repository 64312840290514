import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Spinner from '../../custom/spinner/Spinner';
import { useQuery } from 'react-query';
import { getModelsByCategory } from '../../request/request';
import Similar from '.././components/similar/Similar.js';
import ModelCard from '../components/card/ModelCard';
import Pagination from '../../custom/pagination/Pagination';

const CategoryDetail = () => {
	const [pageNumber, setPageNumber] = useState(1);
	const category = useParams()?.category;

	const [models, setModels] = useState([]);

	const { data, isErrror, error, isFetching } = useQuery(
		['category-models', pageNumber, category],
		() => getModelsByCategory({ pageNumber, category }),
		{
			onSuccess: response => {
				setModels(response?.models);
			},
		}
	);

	const pageSize = data?.pageSize ?? 0;
	const pageCount = data?.pageCount ?? 0;

	const errorMessage = error?.response?.data?.message ?? error?.message;

	const handlePagination = pagenumber => {
		setPageNumber(pagenumber);
	};

	if (isFetching) return <Spinner width={50} height={50} />;
	if (isErrror) return <p className='error'>{errorMessage}</p>;

	return (
    <main>
      <section className='new-models'>
        <div className='container'>
          <div className='new-models-top'>
            <div className='page-title'>Category {category}</div>
          </div>
        </div>
      </section>
      <section>
        <div className='container'>
          <div className='new-models-cards'>
            {Array.isArray(models) && models.length > 0 ? (
              models?.map((model, index) => (
                <ModelCard
                  model={model}
                  key={index}
                  updateModel={setModels}
                  modelType={' '}
                />
              ))
            ) : (
              <p className='no-available-data'>
                no {category?.toLowerCase()} models available
              </p>
            )}
          </div>
          <Pagination
            pageCount={pageCount}
            pageSize={pageSize}
            onChange={handlePagination}
          />
        </div>
      </section>
      {/* <section className='container'>
				<Similar />
			</section> */}
    </main>
  );
};

export default CategoryDetail;
