import React, { Component } from 'react';
// import {Link} from "react-router-dom";

class Payment extends Component {
    componentDidMount () {
        document.querySelectorAll('.payment-js').forEach(function(element) {
            element.onclick = function() {
                document.querySelector('.payment').classList.add('payment__refil');
                document.querySelector('.reg-model-steps .reg-model--step').classList.remove('reg-model--step__active');
                document.querySelector('.reg-model-steps .reg-model--step:nth-child(2)').classList.add('reg-model--step__active');
            };
        }); 
    }
    render() {
        return (
            <main>
                <section className="payment">
                    <div className="container">
                        <div className="reg-model-steps">
                            <div className="reg-model--step reg-model--step__active">Package selection</div>
                            <div className="reg-model--step">Payment system</div>
                        </div>
                        <div className="page-title payment-block">
                            Choose the package that is right for you
                        </div>
                        <div className="page-title refill-blocks">
                            Payment method
                            <span>Please select one of the following payment methods:</span>
                        </div>
                        <div className="payment-wrapper payment-block">
                            <div className="payment-item">
                                <div className="payment-item-top">
                                    <div className="payment-item--title">
                                        Startup
                                        <span>Package</span>
                                    </div>
                                    <div className="payment-item--bonus">
                                        Bonus
                                        <span>+4.99 credits</span>
                                    </div>
                                </div>
                                <div className="payment-item-middle">
                                    <div className="payment-item--price">
                                        29.99
                                    </div>
                                    <div className="payment-item--price__text">
                                        NSA <br/>
                                        Credits
                                    </div>
                                </div>
                                <div className="payment-item-bottom">
                                    <div className="payment-item--ps">
                                        The amount to be paid no bonuses
                                        <span>25.00</span>
                                    </div>
                                    <div className="btn btn__purple payment-js">
                                        Buy Credits
                                    </div>
                                </div>
                            </div>
                            <div className="payment-item">
                                <div className="payment-item-top">
                                    <div className="payment-item--title">
                                        Medium
                                        <span>Package</span>
                                    </div>
                                    <div className="payment-item--bonus">
                                        Bonus
                                        <span>+9.99 credits</span>
                                    </div>
                                </div>
                                <div className="payment-item-middle">
                                    <div className="payment-item--price">
                                        49.99
                                    </div>
                                    <div className="payment-item--price__text">
                                        NSA <br/>
                                        Credits
                                    </div>
                                </div>
                                <div className="payment-item-bottom">
                                    <div className="payment-item--ps">
                                        The amount to be paid no bonuses
                                        <span>40.00</span>
                                    </div>
                                    <div className="btn btn__purple payment-js">
                                        Buy Credits
                                    </div>
                                </div>
                            </div>
                            <div className="payment-item">
                                <div className="payment-item-top">
                                    <div className="payment-item--title">
                                        Pro
                                        <span>Package</span>
                                    </div>
                                    <div className="payment-item--bonus">
                                        Bonus
                                        <span>+14.99 credits</span>
                                    </div>
                                </div>
                                <div className="payment-item-middle">
                                    <div className="payment-item--price">
                                        79.99
                                    </div>
                                    <div className="payment-item--price__text">
                                        NSA <br/>
                                        Credits
                                    </div>
                                </div>
                                <div className="payment-item-bottom">
                                    <div className="payment-item--ps">
                                        The amount to be paid no bonuses
                                        <span>65.00</span>
                                    </div>
                                    <div className="btn btn__purple payment-js">
                                        Buy Credits
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="refill refill-blocks">
                            <div className="refill-wrapper">
                                <div className="refill-item">
                                    <div className="refill-item--info">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                            <path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0ZM16.1775 11.3549C17.38 11.3549 18.3549 10.38 18.3549 9.17746C18.3549 7.97488 17.38 7 16.1775 7C14.9749 7 14 7.97488 14 9.17746C14 10.38 14.9749 11.3549 16.1775 11.3549ZM14.6758 13.1816C14.3996 13.1816 14.1758 13.4055 14.1758 13.6816V23.6816C14.1758 23.9578 14.3996 24.1816 14.6758 24.1816H17.6758C17.9519 24.1816 18.1758 23.9578 18.1758 23.6816V13.6816C18.1758 13.4055 17.9519 13.1816 17.6758 13.1816H14.6758Z" fill="#B0B0B0"/>
                                        </svg>
                                        <div className="refill-info">
                                            Please pay with your credit /
                                            debit card, this is the fastest 
                                            and most convenient payment 
                                            method. We confirm the security 
                                            of your payment.
                                        </div>
                                    </div>
                                    <div className="refill-item--img">
                                        <img className="" src={require('../.././images/refill/card.svg')} alt="user avatar"/>
                                    </div>
                                    <div className="refill-item--title">
                                        Credit / debit card
                                    </div>
                                </div>
                                <div className="refill-item">
                                    <div className="refill-item--info">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                            <path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0ZM16.1775 11.3549C17.38 11.3549 18.3549 10.38 18.3549 9.17746C18.3549 7.97488 17.38 7 16.1775 7C14.9749 7 14 7.97488 14 9.17746C14 10.38 14.9749 11.3549 16.1775 11.3549ZM14.6758 13.1816C14.3996 13.1816 14.1758 13.4055 14.1758 13.6816V23.6816C14.1758 23.9578 14.3996 24.1816 14.6758 24.1816H17.6758C17.9519 24.1816 18.1758 23.9578 18.1758 23.6816V13.6816C18.1758 13.4055 17.9519 13.1816 17.6758 13.1816H14.6758Z" fill="#B0B0B0"/>
                                        </svg>
                                        <div className="refill-info">
                                            Please pay with your credit /
                                            debit card, this is the fastest 
                                            and most convenient payment 
                                            method. We confirm the security 
                                            of your payment.
                                        </div>
                                    </div>
                                    <div className="refill-item--img">
                                        <img className="btc" src={require('../.././images/refill/btc.svg')} alt="user avatar"/>
                                    </div>
                                    <div className="refill-item--title">
                                        Bitcoin / Litecoin
                                    </div>
                                </div>
                                <div className="refill-item">
                                    <div className="refill-item--info">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                            <path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0ZM16.1775 11.3549C17.38 11.3549 18.3549 10.38 18.3549 9.17746C18.3549 7.97488 17.38 7 16.1775 7C14.9749 7 14 7.97488 14 9.17746C14 10.38 14.9749 11.3549 16.1775 11.3549ZM14.6758 13.1816C14.3996 13.1816 14.1758 13.4055 14.1758 13.6816V23.6816C14.1758 23.9578 14.3996 24.1816 14.6758 24.1816H17.6758C17.9519 24.1816 18.1758 23.9578 18.1758 23.6816V13.6816C18.1758 13.4055 17.9519 13.1816 17.6758 13.1816H14.6758Z" fill="#B0B0B0"/>
                                        </svg>
                                        <div className="refill-info">
                                            Please pay with your credit /
                                            debit card, this is the fastest 
                                            and most convenient payment 
                                            method. We confirm the security 
                                            of your payment.
                                        </div>
                                    </div>
                                    <div className="refill-item--img">
                                        <img  className="wm" src={require('../.././images/refill/webmoney.svg')} alt="user avatar"/>
                                    </div>
                                    <div className="refill-item--title">
                                        WebMoney
                                    </div>
                                </div>
                                <div className="refill-item">
                                    <div className="refill-item--info">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                            <path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0ZM16.1775 11.3549C17.38 11.3549 18.3549 10.38 18.3549 9.17746C18.3549 7.97488 17.38 7 16.1775 7C14.9749 7 14 7.97488 14 9.17746C14 10.38 14.9749 11.3549 16.1775 11.3549ZM14.6758 13.1816C14.3996 13.1816 14.1758 13.4055 14.1758 13.6816V23.6816C14.1758 23.9578 14.3996 24.1816 14.6758 24.1816H17.6758C17.9519 24.1816 18.1758 23.9578 18.1758 23.6816V13.6816C18.1758 13.4055 17.9519 13.1816 17.6758 13.1816H14.6758Z" fill="#B0B0B0"/>
                                        </svg>
                                        <div className="refill-info">
                                            Please pay with your credit /
                                            debit card, this is the fastest 
                                            and most convenient payment 
                                            method. We confirm the security 
                                            of your payment.
                                        </div>
                                    </div>
                                    <div className="refill-item--img">
                                        <img src={require('../.././images/refill/pp.svg')} alt="user avatar"/>
                                    </div>
                                    <div className="refill-item--title">
                                        PayPal
                                    </div>
                                </div>
                                <div className="refill-item">
                                    <div className="refill-item--info">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                            <path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0ZM16.1775 11.3549C17.38 11.3549 18.3549 10.38 18.3549 9.17746C18.3549 7.97488 17.38 7 16.1775 7C14.9749 7 14 7.97488 14 9.17746C14 10.38 14.9749 11.3549 16.1775 11.3549ZM14.6758 13.1816C14.3996 13.1816 14.1758 13.4055 14.1758 13.6816V23.6816C14.1758 23.9578 14.3996 24.1816 14.6758 24.1816H17.6758C17.9519 24.1816 18.1758 23.9578 18.1758 23.6816V13.6816C18.1758 13.4055 17.9519 13.1816 17.6758 13.1816H14.6758Z" fill="#B0B0B0"/>
                                        </svg>
                                        <div className="refill-info">
                                            Please pay with your credit /
                                            debit card, this is the fastest 
                                            and most convenient payment 
                                            method. We confirm the security 
                                            of your payment.
                                        </div>
                                    </div>
                                    <div className="refill-item--img">
                                        <img className="paysafe" src={require('../.././images/refill/paysafe.svg')} alt="user avatar"/>
                                    </div>
                                    <div className="refill-item--title">
                                        Paysefecard
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}

export default Payment;