import { NavLink, Link } from 'react-router-dom';
import categories from '../../../category.json';

const Category = () => {
	return (
		<li className='header-menu-item header-menu__dropdown'>
			<NavLink className='header-menu--link' activeClassName='header-menu--link__active' to='/category'>
				Categories
			</NavLink>
			<div className='header-dropdown-container'>
				<div className='header-dropdown-menu'>
					<ul>
						{categories?.map(category => (
							<li>
								<Link to={`/category/${category}`}>
									<div className='header-dropdown-menu--title'>{category}</div>
								</Link>
							</li>
						))}

						<li className='header-dropdown-menu--more'>
							<Link to='/category'>
								{/* <div className='header-dropdown-menu--img'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='18'
										height='18'
										viewBox='0 0 18 18'
										fill='none'
									>
										<path
											fillRule='evenodd'
											clipRule='evenodd'
											d='M9 0C8.44771 0 8 0.447716 8 1V8L1 8C0.447715 8 0 8.44771 0 9C0 9.55228 0.447716 10 1 10H8V17C8 17.5523 8.44771 18 9 18C9.55229 18 10 17.5523 10 17V10H17C17.5523 10 18 9.55229 18 9C18 8.44772 17.5523 8 17 8L10 8V1C10 0.447715 9.55229 0 9 0Z'
											fill='#B87693'
										/>
									</svg>
								</div> */}
								<div className='header-dropdown-menu--title'>See All</div>
							</Link>
						</li>
					</ul>
				</div>
				{/* <div className="header-dropdown-advertising">
                        <div className="header-club-info">
                            <img src={require('../../../images/trashimg/headerclub.png')}  alt=""/>
                            <div className="header-club-info--title">The Cheetah</div>
                            <div className="header-club-info--title2">Site</div>
                            <div className="header-club-info--description">thecheetah.com</div>
                            <div className="header-club-info--title2">Phone</div>
                            <div className="header-club-info--description">+1 404-892-3037</div>
                            <div className="header-club-info--title2">Open today</div>
                            <div className="header-club-info--description">12:00 Noon — 3:00 am</div>
                            <Link to="/">Learn More</Link>
                        </div>
                        <div className="header-club-auth">
                            <div className="header-club-auth--title">Two girls</div>
                            <img className="header-club-auth--img" src={require('../../../images/trashimg/qwertyuio.png')}  alt=""/>
                            <Link to="/" className="header-club-auth--link btn btn__red">Registration now</Link>
                        </div>
                    </div> */}
			</div>
		</li>
	);
};

export default Category;
