const SideAnalysis = () => {
  return (
    <>
      <section className='flex justify-between items-start w-[100%] mb-[20px]'>
        <div>
          <h1 className="text-white text-base font-medium font-['Montserrat'] leading-[18.40px]">
            Impressions
          </h1>
          <div className='w-[139.99px] h-[34px] justify-start items-center gap-3 inline-flex'>
            <span className="text-white text-2xl font-semibold font-['Montserrat'] leading-[33.60px]">
              76k
            </span>
            <div className='flex-col justify-center items-start gap-5 inline-flex'>
              <div className='justify-end items-center gap-1 inline-flex'>
                {/* <div className='w-5 h-5 justify-center items-center flex'>
                    <div className='w-5 h-5 relative flex-col justify-start items-start flex' />
                  </div> */}
                <a
                  href='/clubs-analysis'
                  className="text-lime-300 text-sm font-semibold font-['Montserrat'] leading-relaxed tracking-wide"
                >
                  +13.47%
                </a>
              </div>
            </div>
          </div>
        </div>
        <h1 className="text-white text-sm font-normal font-['Montserrat'] leading-none">
          July
        </h1>
      </section>
      <section className='mb-[20px]'>
        <h1 className="text-white text-base font-medium font-['Montserrat'] leading-[18.40px]">
          Profile Visits
        </h1>
        <div className='w-[139.99px] h-[34px] justify-start items-center gap-3 inline-flex'>
          <span className="text-white text-2xl font-semibold font-['Montserrat'] leading-[33.60px]">
            256
          </span>
          <div className='flex-col justify-center items-start gap-5 inline-flex'>
            <div className='justify-end items-center gap-1 inline-flex'>
              {/* <div className='w-5 h-5 justify-center items-center flex'>
                    <div className='w-5 h-5 relative flex-col justify-start items-start flex' />
                  </div> */}
              <a
                href='/clubs-analysis'
                className="text-lime-300 text-sm font-semibold font-['Montserrat'] leading-relaxed tracking-wide"
              >
                +13.47%
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className='mb-[20px]'>
        <h1 className="text-white text-base font-medium font-['Montserrat'] leading-[18.40px]">
          Followers
        </h1>
        <div className='w-[139.99px] h-[34px] justify-start items-center gap-3 inline-flex'>
          <span className="text-white text-2xl font-semibold font-['Montserrat'] leading-[33.60px]">
            256
          </span>
          <div className='flex-col justify-center items-start gap-5 inline-flex'>
            <div className='justify-end items-center gap-1 inline-flex'>
              {/* <div className='w-5 h-5 justify-center items-center flex'>
                    <div className='w-5 h-5 relative flex-col justify-start items-start flex' />
                  </div> */}
              <a
                href='/clubs-analysis'
                className="text-lime-300 text-sm font-semibold font-['Montserrat'] leading-relaxed tracking-wide"
              >
                +13.47%
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className='w-[125px] h-4 opacity-80 justify-start items-center gap-1 inline-flex'>
        <a className="text-white text-sm font-medium font-['Montserrat'] underline leading-none">
          More Analytics
        </a>
        {/* <div className='w-4 h-4 relative' /> */}
      </section>
    </>
  );
};

export default SideAnalysis;
