import Popup from 'reactjs-popup';
import styles from './privateRequestModal.module.scss';
import Button from '../../../../custom/btn/Btn';

const PrivateRequestModal = ({
  open,
  handleClose,
  handleEnablePrivateRequest,
  senderUser,
  openPrivateRequestConfirmation,
  startPrivateCall,
}) => {
  return (
    <Popup open={open} closeOnDocumentClick onClose={handleClose}>
      <ConfirmationPopUp
        startPrivateCall={startPrivateCall}
        openPrivateRequestConfirmation={openPrivateRequestConfirmation}
        handleClose={handleClose}
        senderUser={senderUser}
        handleEnablePrivateRequest={handleEnablePrivateRequest}
      />
    </Popup>
  );
};

const ConfirmationPopUp = ({
  senderUser,
  openPrivateRequestConfirmation,
  handleClose,
  startPrivateCall,
}) => {
  return (
    <main className={styles.declineContainer}>
      <h1 className={styles.declineTitle}>
        {openPrivateRequestConfirmation
          ? 'Confirm Private Call '
          : 'Declined Private Call '}
      </h1>
      <p className={styles.declineSubTitle}>
        {openPrivateRequestConfirmation ? (
          <>
            Are you ready to start private broadcast with{' '}
            <span>{senderUser}</span>
          </>
        ) : (
          <>
            <span>{senderUser}</span> declined your private request
          </>
        )}
      </p>

      {openPrivateRequestConfirmation ? (
        <Button
          onClick={() => {
            startPrivateCall();
            handleClose();
          }}
          text='Join private broadcast'
          className={styles.declineConfirmPrivateShowButton}
        />
      ) : (
        <Button
          onClick={handleClose}
          text='Close modal'
          className={styles.declineStartPrivateShowButton}
        />
      )}
    </main>
  );
};

export default PrivateRequestModal;
