import { combineReducers } from '@reduxjs/toolkit';
import modelReducer from './slices/model';
import userReducer from './slices/user';
import loginModalReducer from './slices/loginModal';
import broadcastSlice from './slices/broadcast';
import clubReducer from './slices/club';

export const rootReducers = combineReducers({
	model: modelReducer,
	user: userReducer,
	club: clubReducer,
	loginModal: loginModalReducer,
	liveBroadcast: broadcastSlice,
});
