import React, { Component } from 'react';

const About = ({ modelInfo }) => {
  return (
    <div className='model-characteristics'>
      <div className='model-characteristics-appearance'>
        <div className='model-characteristics--title'>Appearance</div>
        <ul className='model-characteristics-appearance-list'>
          <li className='model-characteristics-appearance-list--item'>
            <b>Height:</b>
            <span>{modelInfo?.height}</span>
          </li>
          <li className='model-characteristics-appearance-list--item'>
            <b>Weight:</b>
            <span>{modelInfo?.weight}</span>
          </li>
          <li className='model-characteristics-appearance-list--item'>
            <b>Hair color:</b>
            <span>{modelInfo?.hairColor}</span>
          </li>
          <li className='model-characteristics-appearance-list--item'>
            <b>Gender:</b>
            <span>{modelInfo?.gender}</span>
          </li>
          <li className='model-characteristics-appearance-list--item'>
            <b>Eye color:</b>
            <span>{modelInfo?.eyeColor}</span>
          </li>
          <li className='model-characteristics-appearance-list--item'>
            <b>Build:</b>
            <span>{modelInfo?.build}</span>
          </li>
          <li className='model-characteristics-appearance-list--item'>
            <b>Ethinicity:</b>
            <span>{modelInfo?.ethinicity}</span>
          </li>

          <li className='model-characteristics-appearance-list--item'>
            <b>Pubic Hair:</b>
            <span>{modelInfo?.pubicHair}</span>
          </li>

          <li className='model-characteristics-appearance-list--item'>
            <b>Body Change:</b>
            <span>{modelInfo?.bodyChanges}</span>
          </li>
          <li className='model-characteristics-appearance-list--item'>
            <b>Category:</b>
            <span>{modelInfo?.category}</span>
          </li>
        </ul>
      </div>
      <div className='model-characteristics-about'>
        <div className='model-characteristics-about-item'>
          <div className='model-characteristics--title'>About My Show</div>
          <div className='model-characteristics-about-item--description'>
            {modelInfo?.aboutMe}
          </div>
        </div>
        <div className='model-characteristics-about-item'>
          <div className='model-characteristics--title'>What Turns Me On</div>
          <div className='model-characteristics-about-item--description'>
            {modelInfo?.whatAttractMe}
          </div>
        </div>
        <div className='model-characteristics-about-item'>
          <div className='model-characteristics--title'>My Expertise</div>
          <div className='model-characteristics-about-item--description'>
            {modelInfo?.myCompetence}
          </div>
        </div>
        <div className='model-characteristics-about-item'>
          <div className='model-characteristics--title'>Languages Spoken</div>
          <div className='model-characteristics-about-item--description'>
            {modelInfo?.language}
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;