import React, { Component, useEffect, useState } from 'react';
import Spinner from '../../../../custom/spinner/Spinner';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { addModelComment } from '../../../../request/model';
import { Popover } from '@radix-ui/themes';
import { useLocation } from 'react-router-dom';
import { getPublicComment } from '../../../../request/model';
import { useSelector } from 'react-redux';
import moment from 'moment';
import errorFormmatter from '../../../../utils/errorFormatter';
import notification from '../../../../utils/notification';
import { postCommentLike, getCommentLike } from '../../../../request/model';
import { AiOutlineLike } from 'react-icons/ai';

const CommentSchema = Yup.object().shape({
  message: Yup.string()
    .trim()
    .required('message is required'),
});

const initialValue = {
  message: '',
};

const AllComments = () => {
  const user = useSelector((state) => state?.user);
  const params = useLocation();
  const [loading, setLoading] = useState(false);
  const urlSearchParams = new URLSearchParams(params.search);
  const modelId = urlSearchParams.get('model');
  const [comments, setComments] = useState([{}]);

  const handleGetModelPublicComment = async () => {
    try {
      const response = await getPublicComment({
        modelId,
      });

      setComments(response?.data ?? []);
    } catch (error) {}
  };

  const handleAddComment = async (values) => {
    try {
      const payload = new FormData();
      setLoading(true);
      payload.append('parentId', 0);
      payload.append('modelID', modelId);
      payload.append('commentText', values.message);
      payload.append('commenterName', user.fullName);
      payload.append('memberRegId', user.clientId);

      await addModelComment({ payload });
      notification({
        title: 'Comment',
        message: 'Successfully sent a comment',
        type: 'success',
      });
      setLoading(false);
    } catch (error) {
      notification({
        title: 'Comment',
        message: errorFormmatter(error),
        type: 'danger',
      });
      setLoading(false);
    }
  };

  const handleAddReply = async (replyContent, parentId = null) => {
    try {
      const payload = new FormData();
      setLoading(true);
      console.log(parentId);
      payload.append('parentId', parentId);
      payload.append('modelID', modelId);
      payload.append('commentText', replyContent);
      payload.append('commenterName', user.fullName);
      payload.append('memberRegId', user.clientId);

      await addModelComment({ payload });
      notification({
        title: 'Comment',
        message: 'Successfully sent a comment',
        type: 'success',
      });
      setLoading(false);
    } catch (error) {
      notification({
        title: 'Comment',
        message: errorFormmatter(error),
        type: 'danger',
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetModelPublicComment();
  }, []);

  useEffect(() => {
    handleGetModelPublicComment();
  }, [loading]);

  return user.clientId ? (
    <div className='model-comments'>
      <div className='model-comments-notAuthorized'>
        <span className='model-comments-notAuthorized--link'>Share your</span>{' '}
        comment here{' '}
      </div>
      <div className='!mt-[0rem] !mb-[1.2rem] !py-[27px] model-comments-wrapper  overflow-y-auto max-h-[500px] no-scrollbar'>
        {comments?.map((comment, index) => {
          return (
            <Comment
              modelId={modelId}
              userId={user.clientId}
              key={comment?.commentId}
              loading={loading}
              canReply={true}
              comment={comment}
              onAddReply={handleAddReply}
            />
          );
        })}
      </div>
      <section className='flex-1'>
        <Formik
          enableReinitialize={true}
          validationSchema={CommentSchema}
          initialValues={initialValue}
          onSubmit={handleAddComment}
        >
          {(props) => {
            return (
              <>
                <div className='w-[100%]  mb-[0.6rem]'>
                  <textarea
                    name='message'
                    onChange={props.handleChange}
                    className="w-[100%] self-stretch text-[#d3d3d3] text-base font-normal font-['Montserrat'] leading-[18.40px] grow shrink basis-0 pl-3 pt-3 pb-20 hover:border-primary-100 !outline-none !appearance-none !bg-[#000] rounded-lg border border-primary-100 text-[#d3d3d3] placeholder:text-base placeholder:font-normal placeholder:font-['Montserrat'] "
                    placeholder='Add your comment..'
                  />
                  {props.touched && props.errors?.message && (
                    <span
                      className={
                        "text-[#b87693] font-['Montserrat'] text-[12px] font-normal font-[500] "
                      }
                    >
                      * {props.errors?.message}
                    </span>
                  )}
                </div>
                <div className='!flex !flex-col items-end'>
                  <button
                    onClick={props.handleSubmit}
                    className="w-[80px] gap-[0.3rem] cursor-pointer h-[30px] bg-red-700 rounded-sm  flex items-center justify-center shadow text-center text-white text-base font-medium font-['Montserrat'] leading-[18.40px]"
                  >
                    {loading && <Spinner />}
                    Send
                  </button>
                </div>
              </>
            );
          }}
        </Formik>
      </section>
    </div>
  ) : null;
};

const Comment = ({
  modelId,
  userId,
  loading,
  comment,
  onAddReply,
  canReply = true,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLike, setIsLike] = useState({
    clubLike: 0,
    changeColor: false,
  });
  const [showReplies, setShowReplies] = useState(false);

  const handleGetLike = async () => {
    try {
      const response = await getCommentLike({
        memberId: userId,
        modelId,
        commentId: comment?.commentId,
      });

      setIsLike(response);
    } catch (error) {}
  };

  const handleLike = async () => {
    try {
      setIsLoading(true);
      const response = await postCommentLike({
        memberId: userId,
        modelId,
        commentId: comment?.commentId,
        userLike: !isLike.changeColor,
      });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetLike();
  }, []);

  useEffect(() => {
    handleGetLike();
  }, [isLoading]);

  return (
    <div className='model-comments-item'>
      <div className='model-comments-item--img !p-[0rem]'>
        <img
          src={comment?.profileAvatar}
          alt='image'
          className='w-[79px] h-[79px] rounded-[50%] '
        />
      </div>
      <div className='model-comments-item-description'>
        <div className='model-comments-item--name'>
          {comment?.commenterName}
        </div>
        <div className='model-comments-item--time'>
          {moment(comment?.commentDate).toNow()}
        </div>
        <p className='model-comments-item--text'>{comment?.commentText}</p>

        <div className='model-comments-item--assessment'>
          <div className='model-comments-item--like flex items-center '>
            <AiOutlineLike
              onClick={handleLike}
              className={`mr-[0.5rem] text-[1rem] ${
                isLike.changeColor ? '!text-red-500' : '!text-gray-600'
              }`}
            />
            <span>{isLike.clubLike}</span>
          </div>

          {canReply && (
            <PopOver
              key={comment?.commentId}
              loading={loading}
              parentId={comment?.commentId}
              handleAddReply={onAddReply}
              renderElement={
                <span className='!ml-[0rem] model-comments-item--reply cursor-pointer'>
                  Reply
                </span>
              }
            />
          )}
          {comment?.replies?.length > 0 && (
            <span
              onClick={() => setShowReplies(!showReplies)}
              className='model-comments-item--reply cursor-pointer'
            >
              {`Reply(${comment?.replies?.length})`}
            </span>
          )}
        </div>
        {showReplies && comment?.replies?.length > 0 && (
          <div className='pl-10 mt-2'>
            {comment?.replies?.map((reply, index) => (
              <Comment
                canReply={false}
                key={comment?.commentId}
                comment={reply}
                onAddReply={onAddReply}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const PopOver = ({ loading, renderElement, parentId, handleAddReply }) => {
  const handleSubmit = (values) => {
    handleAddReply(values.message, parentId);
  };
  return (
    <Popover.Root maxHeight='30'>
      <Popover.Trigger>
        {typeof renderElement === 'function' ? renderElement() : renderElement}
      </Popover.Trigger>
      <Popover.Content
        minHeight='80px'
        width='360px'
        className='bg-gradient-to-r from-[#413450] to-[#563143]  !flex !flex-col'
      >
        <Formik
          enableReinitialize={true}
          validationSchema={CommentSchema}
          initialValues={initialValue}
          onSubmit={handleSubmit}
        >
          {(props) => {
            return (
              <>
                <h1 className="mb-[1rem] text-primary-100 text-[1rem] font-semibold font-['Montserrat'] leading-[30px]">
                  Reply
                </h1>
                <div className='w-[100%] mb-[1rem]'>
                  <textarea
                    name='message'
                    onChange={props.handleChange}
                    className="w-[100%] self-stretch text-[#d3d3d3] text-base font-normal font-['Montserrat'] leading-[18.40px] grow shrink basis-0 pl-3 pt-3 pb-20 hover:border-primary-100 !outline-none !appearance-none [background:none!important] rounded-[5px] border border-[#afafaf] text-[#d3d3d3] placeholder:text-base placeholder:font-normal placeholder:font-['Montserrat'] "
                    placeholder='Add your comment..'
                  />
                  {props.touched && props.errors?.message && (
                    <span
                      className={
                        "text-[#b87693] block font-['Montserrat'] text-[12px] font-normal font-[500] "
                      }
                    >
                      * {props.errors?.message}
                    </span>
                  )}
                </div>
                <div className='!flex !flex-col items-end'>
                  <button
                    onClick={props.handleSubmit}
                    className="w-[80px] gap-[0.3rem] cursor-pointer h-[30px] bg-red-700 rounded-sm  flex items-center justify-center shadow text-center text-white text-base font-medium font-['Montserrat'] leading-[18.40px]"
                  >
                    {loading && <Spinner />}
                    Send
                  </button>
                </div>
              </>
            );
          }}
        </Formik>
      </Popover.Content>
    </Popover.Root>
  );
};

export default AllComments;
