import { bindActionCreators } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { updateUser, logOut, updateUerStatus } from '../store/slices/user';
import { openModal, closeModal } from '../store/slices/loginModal';
import { getModelProfileStatus, getModelOnlineStatus, updateModelOnlineStatus } from '../store/slices/model';
import { getClubProfileStatus } from '../store/slices/club';

export const useActionCreator = () => {
	const dispatch = useDispatch();

	return bindActionCreators(
		{
			updateUerStatus,
			updateUser,
			openModal,
			closeModal,
			getModelProfileStatus,
			getClubProfileStatus,
			logOut,
			getModelOnlineStatus,
			updateModelOnlineStatus,
		},
		dispatch
	);
};
