import styles from './appointment.module.scss';
import ModelProfileHeader from '../../custom/modelProfileHeader/ModelProfileHeader';
import FluidContainer from '../../custom/fluidContainer/FluidContainer';
import CreateAppointment from './createAppointment/createAppointment';
import DropDown from '../../custom/DropDown/DropDown';
import { RiArrowDropDownLine } from 'react-icons/ri';
import AppointmentRequest from './appointmentRequest/appointmentRequest';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { useSelector } from 'react-redux';
import 'moment-timezone';
import {
  addAppointment,
  getAppointments,
  deleteAppointment,
  updateAppointment,
} from '../../request/model';
import notification from '../../utils/notification';
import errorFormmatter from '../../utils/errorFormatter';
import { filterAppointments } from './filter';

import AddAvailability from './addAvailability/AddAvailability';

const timeZones = moment.tz.names().map((data) => {
  return {
    label: data,
    value: moment.tz(data).format('z'),
  };
});

const DAYS = {
  SUNDAY: 'Sunday',
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
};

const ModelAppointments = () => {
  const [timezone, setTimeZone] = useState(moment.tz.guess());
  const [tab, setTab] = useState(0);
  const [currentAppointmentId, setCurrentAppointmentId] = useState('');
  const [mode, setMode] = useState(false);
  const [dayIndex, setDayIndex] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [currentAppointment, setCurrentAppointment] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [openTimePicker, setOpenTimePicker] = useState(false);
  const user = useSelector((state) => state?.user);
  const [allAvailability, setAllAvailability] = useState([]);
  const [availability, setAvailability] = useState({
    sunday: [],
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
  });

  const handleOpenTimePicker = () => {
    setOpenTimePicker(true);
  };

  const handleCloseTimePicker = () => {
    setOpenTimePicker(false);
  };

  const addAvailability = async (values) => {
    try {
      let d = moment(values.date).format().toString();

      setIsLoading(true);
      const payload = new FormData();
      payload.append('startTime', values.from);
      payload.append('endTime', values.to);
      payload.append('appointmentDate', `${d}`);
      payload.append('registeredId', `${user?.clientId}`);

      const response = await addAppointment({ payload });
      handleCloseTimePicker();
      setIsLoading(false);
      notification({ message: response?.message ?? '' });
    } catch (error) {
      setIsLoading(false);
      notification({
        message: errorFormmatter(error) ?? 'Failed to delete availability',
        type: 'danger',
      });
    }
  };

  const deleteAvailability = async (id) => {
    try {
      setCurrentAppointmentId(id);
      const payload = {
        user_id: user?.clientId,
        id: id,
      };
      setIsDeleting(true);
      const response = await deleteAppointment(payload);
      setIsDeleting(false);
      notification({ message: response?.message ?? '' });
    } catch (error) {
      setIsDeleting(false);
      notification({
        message: errorFormmatter(error) ?? 'Failed to delete availability',
        type: 'danger',
      });
    }
  };

  const update = async (values) => {
    try {
      const payload = {
        startTime: values.from,
        endTime: values.to,
        appointmentDate: `${values.date}`,
        registeredId: `${user?.clientId}`,
        id: currentAppointment.id,
      };

      setIsLoading(true);

      const response = await updateAppointment({ payload });
      handleCloseTimePicker();
      setIsLoading(false);
      notification({ message: response?.message ?? '' });
    } catch (error) {
      setIsLoading(false);
      notification({
        message: errorFormmatter(error) ?? 'Failed to delete availability',
        type: 'danger',
      });
    }
  };

  const updateAvailability = (data) => {
    setCurrentAppointment(data);
  };

  const getAllAppointments = async () => {
    try {
      setIsLoading(true);
      const response = await getAppointments({ user_id: user?.clientId });
      setAllAvailability(response.data);
      setIsLoading(false);
    } catch (error) {
      setAllAvailability([]);
      setIsLoading(false);
    }
  };

  const handleTimeZoneChange = (zone) => {
    setTimeZone(zone?.label);
    moment.tz.setDefault(zone?.label);
  };

  useEffect(() => {
    getAllAppointments();
  }, []);

  useEffect(() => {
    if (openTimePicker === false) {
      getAllAppointments();
    }
  }, [openTimePicker]);

  useEffect(() => {
    getAllAppointments();
  }, [isDeleting]);

  useEffect(() => {
    setAvailability(() => ({
      ...availability,
      sunday: filterAppointments(allAvailability, 'Sunday'),
      monday: filterAppointments(allAvailability, 'Monday'),
      tuesday: filterAppointments(allAvailability, 'Tuesday'),
      wednesday: filterAppointments([...allAvailability], 'Wednesday'),
      thursday: filterAppointments(allAvailability, 'Thursday'),
      friday: filterAppointments(allAvailability, 'Friday'),
      saturday: filterAppointments(allAvailability, 'Saturday'),
    }));
  }, [allAvailability]);

  return (
    <FluidContainer>
      <ModelProfileHeader />
      <main className={styles.container}>
        <h1 className={styles.title}>Appointments</h1>

        <h1 className={styles.subTitle}>
          Set up your work hours so users can know your availability for a
          private show. You can also view private show requests from here.
        </h1>
        <DropDown
          onChange={(data) => {
            handleTimeZoneChange(data);
          }}
          options={timeZones}
          className={styles.selectContainer}
          dropElement={({ props, state }) => (
            <section className={styles.timeZoneContainer}>
              Your Time Zone:{' '}
              <span>
                {moment.tz(timezone).format('Z')}{' '}
                {moment.tz(timezone).format('z')}
                <RiArrowDropDownLine className={styles.icon} />
              </span>
            </section>
          )}
        />

        <section className={styles.eventWrapperContainer}>
          <section className={styles.appointmentHoursHeader}>
            <h1
              className={classNames(
                styles.appointmentTitle,
                tab === 0 && styles.activeTab
              )}
              onClick={() => setTab(0)}
            >
              Work Hours
            </h1>
            {/* <h1
                    className={classNames(
                      styles.appointmentHours,
                      tab === 1 && styles.activeTab
                    )}
                    onClick={() => setTab(1)}
                  >
                    Private Show Requests
                  </h1> */}
          </section>
          <section>
            {tab === 0 && (
              <>
                <CreateAppointment
                  currentAppointmentId={currentAppointmentId}
                  isLoading={isDeleting}
                  deleteAvailability={(id) => {
                    deleteAvailability(id);
                  }}
                  day={DAYS.SUNDAY}
                  availability={availability.sunday}
                  openTimePicker={openTimePicker}
                  handleCloseTimePicker={() => {
                    handleCloseTimePicker();
                    setMode(false);
                  }}
                  handleOpenTimePicker={() => {
                    handleOpenTimePicker();
                    setDayIndex(0);
                  }}
                  updateAvailability={(data) => {
                    updateAvailability(data);
                    setDayIndex(0);
                    setMode(true);
                    handleOpenTimePicker();
                  }}
                />
                <CreateAppointment
                  currentAppointmentId={currentAppointmentId}
                  isLoading={isDeleting}
                  deleteAvailability={(id) => {
                    deleteAvailability(id);
                  }}
                  day={DAYS.MONDAY}
                  availability={availability.monday}
                  openTimePicker={openTimePicker}
                  handleCloseTimePicker={() => {
                    handleCloseTimePicker();
                    setMode(false);
                  }}
                  handleOpenTimePicker={() => {
                    handleOpenTimePicker();
                    setDayIndex(1);
                  }}
                  updateAvailability={(data) => {
                    updateAvailability(data);
                    setDayIndex(1);
                    setMode(true);
                    handleOpenTimePicker();
                  }}
                />
                <CreateAppointment
                  currentAppointmentId={currentAppointmentId}
                  isLoading={isDeleting}
                  deleteAvailability={(id) => {
                    deleteAvailability(id);
                  }}
                  day={DAYS.TUESDAY}
                  openTimePicker={openTimePicker}
                  handleCloseTimePicker={() => {
                    handleCloseTimePicker();
                    setMode(false);
                  }}
                  handleOpenTimePicker={() => {
                    handleOpenTimePicker();
                    setDayIndex(2);
                  }}
                  updateAvailability={(data) => {
                    updateAvailability(data);
                    setDayIndex(2);
                    setMode(true);
                    handleOpenTimePicker();
                  }}
                  availability={availability.tuesday}
                />
                <CreateAppointment
                  currentAppointmentId={currentAppointmentId}
                  isLoading={isDeleting}
                  deleteAvailability={(id) => {
                    deleteAvailability(id);
                  }}
                  day={DAYS.WEDNESDAY}
                  availability={availability.wednesday}
                  openTimePicker={openTimePicker}
                  handleCloseTimePicker={() => {
                    handleCloseTimePicker();
                    setMode(false);
                  }}
                  handleOpenTimePicker={() => {
                    handleOpenTimePicker();
                    setDayIndex(3);
                  }}
                  updateAvailability={(data) => {
                    updateAvailability(data);
                    setDayIndex(3);
                    setMode(true);
                    handleOpenTimePicker();
                  }}
                />
                <CreateAppointment
                  currentAppointmentId={currentAppointmentId}
                  isLoading={isDeleting}
                  deleteAvailability={(id) => {
                    deleteAvailability(id);
                  }}
                  day={DAYS.THURSDAY}
                  availability={availability.thursday}
                  openTimePicker={openTimePicker}
                  handleCloseTimePicker={() => {
                    handleCloseTimePicker();
                    setMode(false);
                  }}
                  handleOpenTimePicker={() => {
                    handleOpenTimePicker();
                    setDayIndex(4);
                  }}
                  updateAvailability={(data) => {
                    updateAvailability(data);
                    setDayIndex(4);
                    setMode(true);
                    handleOpenTimePicker();
                  }}
                />
                <CreateAppointment
                  currentAppointmentId={currentAppointmentId}
                  isLoading={isDeleting}
                  deleteAvailability={(id) => {
                    deleteAvailability(id);
                  }}
                  day={DAYS.FRIDAY}
                  availability={availability.friday}
                  openTimePicker={openTimePicker}
                  handleCloseTimePicker={() => {
                    handleCloseTimePicker();
                    setMode(false);
                  }}
                  handleOpenTimePicker={() => {
                    handleOpenTimePicker();
                    setDayIndex(5);
                  }}
                  updateAvailability={(data) => {
                    updateAvailability(data);
                    setDayIndex(5);
                    setMode(true);
                    handleOpenTimePicker();
                  }}
                />
                <CreateAppointment
                  currentAppointmentId={currentAppointmentId}
                  isLoading={isDeleting}
                  deleteAvailability={(id) => {
                    deleteAvailability(id);
                  }}
                  day={DAYS.SATURDAY}
                  availability={availability.saturday}
                  openTimePicker={openTimePicker}
                  handleCloseTimePicker={() => {
                    handleCloseTimePicker();
                    setMode(false);
                  }}
                  handleOpenTimePicker={() => {
                    handleOpenTimePicker();
                    setDayIndex(6);
                  }}
                  updateAvailability={(data) => {
                    setDayIndex(6);
                    updateAvailability(data);
                    setMode(true);
                    handleOpenTimePicker();
                  }}
                />
              </>
            )}
            {tab === 1 && (
              <>
                <AppointmentRequest />

                <AppointmentRequest />
              </>
            )}
          </section>
        </section>
      </main>
      <AddAvailability
        update={update}
        currentAppointment={currentAppointment}
        mode={mode}
        dayIndex={dayIndex}
        isLoading={isLoading}
        addAvailability={addAvailability}
        open={openTimePicker}
        handleCloseTimePicker={() => {
          handleCloseTimePicker();
          setMode(false);
        }}
      />
    </FluidContainer>
  );
}

export default ModelAppointments;
