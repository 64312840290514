import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getPremiumClubs } from '../../../request/club';
import { useLocation } from 'react-router-dom';

const Sexclub = () => {
                        const history = useLocation();
                        const [premiumClubs, setPremiumClubs] = useState([]);

                        const handleGetPremiumClubs = async () => {
                          try {
                            const response = await getPremiumClubs();
                            setPremiumClubs(response?.models ?? []);
                          } catch (error) {}
                        };

                        const handleClick = (id) => {
                          history.push(`clubs-detail?clubId=${id}`);
                        };

                        useEffect(() => {
                          handleGetPremiumClubs();
                        }, []);

                        const settings = {
                          autoplay: true,
                          autoplaySpeed: 2000,
                          className: 'center',
                          centerMode: true,
                          infinite: true,
                          dots: true,
                          nav: false,
                          centerPadding: '30px',
                          //   slidesToShow: 3,
                          slidesToScroll: 1,
                          cssEase: 'linear',
                          slidesToShow: premiumClubs.length >= 3 ? 3 : 1,
                          speed: 500,
                          responsive: [
                            {
                              breakpoint: 768,
                              settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                centerMode: false,
                                centerPadding: '0',
                              },
                            },
                          ],
                        };

                        return (
                          <section className='sex-club'>
                            <div className='container'>
                              <div className='page-title'>
                                <div className='page-title_d'>
                                  Gentlemen Clubs
                                </div>
                                <div className='page-title_m'>Clubs</div>
                              </div>
                              <div className='sex-club--more'>
                                <Link to='/clubs'>All Clubs</Link>
                              </div>
                            </div>
                            <Slider {...settings}>
                              {premiumClubs.map((club, index) => {
                                return (
                                  <Link
                                    to={`/clubs-detail?clubId=${club?.registerId}`}
                                    key={index}
                                    className='slick-slide-item relative z-[9999]'
                                  >
                                    <img src={club?.clubImageURL} alt='' />
                                    <div className='slick-slide-item--title'>
                                      {club?.clubName}
                                    </div>
                                  </Link>
                                );
                              })}
                              {/* <div className='slick-slide-item'>
                                <img
                                  src={require('../../.././images/trashimg/sexclub.jpg')}
                                  alt=''
                                />
                                <div className='slick-slide-item--title'>
                                  The Cheetah
                                </div>
                              </div> */}
                            </Slider>
                          </section>
                        );
                      };

export default Sexclub;
