import { useState, useEffect, useRef } from 'react';
import { getClubPhotos } from '../../../../request/club';
import useGridDisplay from '../../../../hooks/useGridDisplay';
import ViewAllGalleryPhotos from './viewAllGalleryPhotos';
import Spinner from '../../../../custom/spinner/Spinner';
import EmptyView from '../../EmptyView';

const clubId = 'b002d30c-ef07-4462-8489-2c9d4568da3a';

const GalleryView = ({ clubPhotos = [] }) => {
  const [visibleItems, setVisibleItems] = useState([]);
  const [previewItems, setPreviewItems] = useState([]);
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPagination, setCurrentPagination] = useState({
    startPage: 0,
    endPage: currentPage * itemsPerPage,
  });
  const [openViewAllFile, setOpenViewAllFile] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isLoadMore, setIsLoadMore] = useState(false);

  const handleAddNextLoad = async () => {
    try {
      setLoading(true);
      const res = await getClubPhotos({
        clubId,
        page: currentPagination.startPage,
        offset: currentPagination.endPage,
      });

      if (visibleItems.length < res?.totalCount) {
        if (res?.clubphotos.length > 0) {
          setVisibleItems((prev) => {
            return [...prev, ...res?.clubphotos];
          });
          setPreviewItems((prev) => {
            return [...prev, ...res?.clubphotos];
          });
          setIsLoadMore(true);
          const nextPage = currentPage + 1;
          setCurrentPage((prevPage) => nextPage);
          setCurrentPagination(() => {
            return {
              startPage: nextPage,
              endPage: itemsPerPage,
            };
          });
        }
      }

      if (res?.totalCount - visibleItems.length === 0) {
        setIsLoadMore(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleLoadMore = async () => {
    await handleAddNextLoad();
  };

  useEffect(() => {
    handleAddNextLoad();
  }, []);

  const handlePreviewClickImage = (image) => {
    const index = previewItems.findIndex(
      (obj) => obj['photoId'] === image?.photoId
    );
    if (index !== -1) {
      const [data] = previewItems.splice(index, 1);
      previewItems.unshift(data);
    }

    setOpenViewAllFile(true);
  };

  return (
    <>
      <ViewAllGalleryPhotos
        files={previewItems}
        open={openViewAllFile}
        handleClose={() => setOpenViewAllFile(false)}
      />
      {visibleItems.length > 0 ? (
        <section className='mb-[40px]'>
          <View data={visibleItems} openAllView={handlePreviewClickImage} />
        </section>
      ) : (
        <EmptyView text={'No club photos'} />
      )}

      {loading ? (
        <Spinner />
      ) : (
        isLoadMore && (
          <section className='flex items-center justify-center w-[100%] mb-[40px]'>
            <button
              onClick={handleLoadMore}
              className=" appearance-none w-[max-content] !border-none  text-violet-500 text-xl font-medium font-['Montserrat'] leading-[25px]"
            >
              Load More Photos
            </button>
          </section>
        )
      )}
    </>
  );
};

const View = ({ data = [], openAllView }) => {
  const [displayData, setDisplayData] = useState([]);
  const galleryRef = useRef(null);
  const { renderGallery } = useGridDisplay();

  useEffect(() => {
    setDisplayData(data);
  }, [data]);

  const customView = ({ image, width }) => {
    const { src } = image;

    const item = document.createElement('section');

    const galleryItemClass = ' mb-2 !h-[422px]';
    item.className = `${galleryItemClass}  cursor-pointer`;
    item.style = `width:${width}%`;
    item.insertAdjacentHTML(
      'afterbegin',
      `
        <img src=${src} class='w-[100%] !h-[100%]'/>
       `
    );
    item.addEventListener('click', () => {
      if (openAllView) {
        openAllView(image);
      }
    });
    return item;
  };

  return (
    <section ref={galleryRef} className=' gallery'>
      {renderGallery({
        images: displayData.map((item) => {
          return {
            ...item,
            src: item?.imageURL,
          };
        }),
        containerRef: galleryRef.current,
        createGalleryItem: customView,
        openAllView: openAllView,
        rowClass: 'gap-2',
      })}
    </section>
  );
};

export default GalleryView;
