import React from 'react';
import 'rj-calendar/assets/index.css';
import Calendar from 'rj-calendar';
import DatePicker from 'rj-calendar/lib/Picker';

const format = 'YYYY-MM-DD';

const Date = ({ handleDateChange, date }) => {

	return (
		<DatePicker
			onChange={e => handleDateChange(e.format(format))}
			getCalendarContainer={Date.getCalendarContainer}
			calendar={<Calendar />}
		>
			{({ value }) => {
				return (
					<input
						autoComplete='off'
						name='date'
						className='required date'
						type='text'
						placeholder={date}
						readOnly
						value={(value && value.format(format)) || ''}
					/>
				);
			}}
		</DatePicker>
	);
};
export default Date;
