import { forwardRef } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';

const CheckBox = forwardRef(({ className, labelText, ...props }, ref) => {
	return (
		<fieldset className={classNames(styles.fieldset, className)}>
			<input type='checkbox' className={classNames(styles.input)} {...props} ref={ref} />
			<label className={styles.label}>{labelText}</label>
		</fieldset>
	);
});

export default CheckBox;
