let width = window.innerWidth;
export const WINDOW_WIDTH = width;
export const MOBILE = '768';

export const APP_LOAD = 'APP_LOAD';
export const REDIRECT = 'REDIRECT';
export const ARTICLE_SUBMITTED = 'ARTICLE_SUBMITTED';
export const SETTINGS_SAVED = 'SETTINGS_SAVED';
export const DELETE_ARTICLE = 'DELETE_ARTICLE';
export const SETTINGS_PAGE_UNLOADED = 'SETTINGS_PAGE_UNLOADED';
export const HOME_PAGE_LOADED = 'HOME_PAGE_LOADED';
export const HOME_PAGE_UNLOADED = 'HOME_PAGE_UNLOADED';
export const ARTICLE_PAGE_LOADED = 'ARTICLE_PAGE_LOADED';
export const ARTICLE_PAGE_UNLOADED = 'ARTICLE_PAGE_UNLOADED';
export const ADD_COMMENT = 'ADD_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const ARTICLE_FAVORITED = 'ARTICLE_FAVORITED';
export const ARTICLE_UNFAVORITED = 'ARTICLE_UNFAVORITED';
export const SET_PAGE = 'SET_PAGE';
export const APPLY_TAG_FILTER = 'APPLY_TAG_FILTER';
export const CHANGE_TAB = 'CHANGE_TAB';
export const PROFILE_PAGE_LOADED = 'PROFILE_PAGE_LOADED';
export const PROFILE_PAGE_UNLOADED = 'PROFILE_PAGE_UNLOADED';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const LOGIN_PAGE_UNLOADED = 'LOGIN_PAGE_UNLOADED';
export const REGISTER_PAGE_UNLOADED = 'REGISTER_PAGE_UNLOADED';
export const ASYNC_START = 'ASYNC_START';
export const ASYNC_END = 'ASYNC_END';
export const EDITOR_PAGE_LOADED = 'EDITOR_PAGE_LOADED';
export const EDITOR_PAGE_UNLOADED = 'EDITOR_PAGE_UNLOADED';
export const ADD_TAG = 'ADD_TAG';
export const REMOVE_TAG = 'REMOVE_TAG';
export const UPDATE_FIELD_AUTH = 'UPDATE_FIELD_AUTH';
export const UPDATE_FIELD_EDITOR = 'UPDATE_FIELD_EDITOR';
export const FOLLOW_USER = 'FOLLOW_USER';
export const UNFOLLOW_USER = 'UNFOLLOW_USER';
export const PROFILE_FAVORITES_PAGE_UNLOADED =
  'PROFILE_FAVORITES_PAGE_UNLOADED';
export const PROFILE_FAVORITES_PAGE_LOADED = 'PROFILE_FAVORITES_PAGE_LOADED';
export const MEMBER = 'member';
export const CLUB = 'club';
export const MODEL = 'model';
