import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import FluidContainer from '../../custom/fluidContainer/FluidContainer';
import styles from './styles.module.scss';
import Button from '../../custom/btn/Btn';
import ModelProfileHeader from '../../custom/modelProfileHeader/ModelProfileHeader';
import { withRole } from '../../hoc/withRole';
import SelectAll from '../../custom/selectAll/SelectAll';
import AddButton from '../../custom/addButton/AddButton';
import { ReactComponent as DeleteIcon } from '../../images/svg/delete.svg';
import VideoPreview from './VideoPreview/VideoPreview';
import UploadPopup from '../../custom/uploadPopup/UploadPopup';
import notification from '../../utils/notification';
import errorFormmatter from '../../utils/errorFormatter';
import {
  getFanClubVideos,
  uploadFanClubVideo,
  deleteFanClubVideo,
} from '../../request/model';
import ModelEditPopup from './modelEditPopup/ModelEditPopup';
import Spinner from '../../custom/spinner/Spinner';

const ModelFanClubVideo = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const clientId = useSelector((state) => state?.user?.clientId);
  const [isOpenUploadPhotoModal, setIsOpenUploadPhotoModal] = useState(false);
  const [selectedVideoIds, setSelectedVideoIds] = useState([]);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [fanClubVideos, setFanClubVideos] = useState([]);
  const [openEditVideo, setOpenEditVideo] = useState(false);
  const [videoToEdit, setVideoToEdit] = useState([]);

  const handleOpenEditVideo = (video) => {
    setVideoToEdit(video);
    setOpenEditVideo(true);
  };

  const handleClose = () => {
    setIsOpenUploadPhotoModal(false);
  };

  const handleOpenUploadModal = () => {
    setIsOpenUploadPhotoModal(true);
    setLoading(false);
  };

  const handleFileChange = (file) => {
    setFile(file);
  };

  const getFanClubVideo = async () => {
    try {
      const response = await getFanClubVideos({ modelId: clientId });
      setFanClubVideos(response.data);
    } catch (error) {
      setFanClubVideos([]);
    }
  };

  const handleUploadFanClubVideo = async () => {
    try {
      const payload = new FormData();

      payload.append('registeredId', clientId);
      payload.append('uploadVideo', file);
      setLoading(true);
      const response = await uploadFanClubVideo({ payload });
      setLoading(false);
      notification({
        message: response?.message ?? 'Fan club video saved successfully',
      });
    } catch (error) {
      notification({
        message: errorFormmatter(error) ?? 'Fan club video failed to save',
        type: 'danger',
      });
      setLoading(false);
    }
  };

  const handleVideoSelection = (videoId) => {
    const id = selectedVideoIds.find((id) => id === videoId);
    if (id) {
      const filteredPhotoIds = selectedVideoIds.filter((id) => id !== videoId);
      setSelectedVideoIds(filteredPhotoIds);
    } else {
      setSelectedVideoIds((prev) => [...prev, videoId]);
    }
  };

  const handleSelectAll = (e) => {
    const { checked } = e.target;
    if (!fanClubVideos) return;

    if (checked) {
      const selectedIds = fanClubVideos?.map((video) => video?.videoUploadId);
      setSelectedVideoIds(selectedIds);
    } else {
      setSelectedVideoIds([]);
    }

    setIsSelectedAll(checked);
  };

  const deleteVideo = async (id) => {
    try {
      setLoading(true);
      const response = await deleteFanClubVideo({
        modelId: clientId,
        videoId: id,
      });
      setLoading(false);
      notification({
        message: response?.message ?? 'Fan club video deleted successfully',
      });
    } catch (error) {
      notification({
        message: errorFormmatter(error) ?? 'Fan club video failed to delete',
        type: 'danger',
      });
      setLoading(false);
    }
  };

  const handleIsSelected = (photoId) => {
    const id = selectedVideoIds.find((id) => id === photoId);
    return id ? true : false;
  };

  useEffect(() => {
    getFanClubVideo();
  }, []);

  useEffect(() => {
    getFanClubVideo();
  }, [loading]);

  useEffect(() => {
    getFanClubVideo();
  }, [openEditVideo]);

  return (
    <FluidContainer>
      <ModelProfileHeader className={styles.topNav} />
      <div className={styles.header}>
        <h1 className={styles.subTitle}>FAN CLUB</h1>
        <h1 className={styles.title}>
          Videos{' '}
          <span className={styles.tip}>{fanClubVideos?.length ?? 0}</span>
        </h1>
      </div>
      <section className={styles.navContainer}>
        <SelectAll
          labelText='Select All Videos'
          checked={isSelectedAll}
          onChange={handleSelectAll}
        />
        <nav className={styles.nav}>
          {loading && <Spinner />}
          <Button
            icon={DeleteIcon}
            className={styles.deleteButton}
            text='Delete Video'
            onClick={() => {
              selectedVideoIds.map((id) => deleteVideo(id));
            }}
          />
        </nav>
      </section>
      <section className={styles.photoContainer}>
        <AddButton text='Add Fan Club Videos' onClick={handleOpenUploadModal} />
        {fanClubVideos.map((video, index) => {
          return (
            <div key={index}>
              <VideoPreview
                deletePhoto={() => deleteVideo(video?.videoUploadId)}
                src={video?.videoUrl}
                video={video}
                key={video?.videoUrl}
                videoUploadId={video?.videoUploadId}
                photoName={video?.videoName}
                handleOpenEditVideoModal={() => handleOpenEditVideo(video)}
                handleVideoSelection={handleVideoSelection}
                isSelected={handleIsSelected}
              />
              <ModelEditPopup
                key={video?.videoUploadId}
                open={openEditVideo}
                onClose={() => setOpenEditVideo(false)}
                video={videoToEdit}
              />
            </div>
          );
        })}
      </section>

      <UploadPopup
        disabled={loading}
        upload={handleUploadFanClubVideo}
        onFileChange={handleFileChange}
        title='videos'
        open={isOpenUploadPhotoModal}
        handleClose={handleClose}
        accept='video/*'
        acceptableFile='MP3, MP4 less than 10 MB'
      />
    </FluidContainer>
  );
};

export default withRole(ModelFanClubVideo);
