const Textarea = ({ placeholder, defaultValue, ...props }) => {
  return (
    <textarea
      {...props}
      defaultValue={defaultValue}
      className="w-[100%] h-[140px] hover:border hover:border-primary-100 hover:border-[1px] text-white placeholder:text-primary-300 bg-primary-200 text-lg font-[1rem] font-['Montserrat'] p-[1rem] "
      placeholder={placeholder}
    />
  );
};

export default Textarea;
