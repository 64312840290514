import { useEffect, useRef, useState } from 'react';
import Popup from 'reactjs-popup';
import Spinner from '../../../../custom/spinner/Spinner';
import { postClubFeed } from '../../../../request/club';
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useSelector } from 'react-redux';
import errorFormmatter from '../../../../utils/errorFormatter';
import notification from '../../../../utils/notification';

const clubid = 'b002d30c-ef07-4462-8489-2c9d4568da3a';

const PostFeedPopUp = ({ open, handleClose, files = [] }) => {
  const swiperRef = useRef();
  const [initialFiles, setInitialFiles] = useState(files);
  const [activeIndex, setActiveIndex] = useState(0);
  const [feedData, setFeedData] = useState({});
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state?.user);

  const getCurrentSlide = () => {
    try {
      const filtered = swiperRef.current?.slides.find((slider) => {
        const id = slider?.id.split(':::')[1] ?? '';
        return id === `${activeIndex}`;
      });

      if (filtered) {
        const postFeedData = initialFiles.find((file) => {
          const id = filtered?.id.split(':::')[0] ?? '';
          return file?.id === id;
        });
        setFeedData(postFeedData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFeedData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleDeleteFile = () => {
    const id = swiperRef.current?.activeIndex ?? activeIndex;
    const active_filtered = swiperRef.current?.slides.find((slider) => {
      const _id = slider?.id.split(':::')[1] ?? '';
      return _id === `${id}`;
    });

    if (active_filtered) {
      const filtered = swiperRef.current?.slides.filter((slider) => {
        const slider_id = slider?.id.split(':::')[1] ?? '';
        return slider_id !== id;
      });

      const filteredPostFile = initialFiles.filter((file) => {
        const slider_id = active_filtered?.id.split(':::')[0] ?? '';
        return file?.id !== slider_id;
      });

      setInitialFiles(filteredPostFile);
      swiperRef.current['slides'] = filtered ?? [];

      if (swiperRef.current['slides'].length === 1) {
        handleClose();
        setInitialFiles([]);
        swiperRef.current['slides'] = [];
      }
    }
  };

  const handlePostFeed = async () => {
    try {
      const payload = new FormData();
      setLoading(true);
      payload.append('addImagePost', feedData?.file);
      payload.append('name', 'Ada');
      payload.append('title', feedData?.title);
      payload.append('clubId', clubid);
      payload.append('description', feedData?.description);
      const response = await postClubFeed({ payload });
      setLoading(false);
      notification({
        title: 'Club feed',
        message: response?.message,
        type: 'success',
      });
    } catch (error) {
      setLoading(false);
      notification({
        title: 'Club feed',
        message: errorFormmatter(error),
        type: 'danger',
      });
    }
  };

  const handleGetSlidPostData = () => {
    const _filtered = swiperRef.current?.slides.find((slider) => {
      const id = slider?.id.split(':::')[1] ?? '';
      return id === `${swiperRef.current?.activeIndex}`;
    });

    if (_filtered) {
      const postFeedData = initialFiles.find((file) => {
        const id = _filtered?.id.split(':::')[0] ?? '';
        return file?.id === id;
      });
      setFeedData(postFeedData);
    }
  };

  useEffect(() => {
    getCurrentSlide();
  }, [activeIndex]);

  useEffect(() => {
    handleGetSlidPostData();
  }, [initialFiles]);

  useEffect(() => {
    getCurrentSlide();
  }, []);

  useEffect(() => {
    setInitialFiles(files);
  }, [files]);

  return (
    <Popup
      open={open}
      lockScroll
      overlayStyle={{
        background: 'rgba(68, 39, 52, 0.89)',
      }}
      contentStyle={{}}
      onClose={handleClose}
      closeOnDocumentClick
    >
      <span
        className='login-popup--close !top-0 !right-0 !fixed'
        onClick={() => handleClose(false)}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='18'
          height='18'
          viewBox='0 0 18 18'
          fill='none'
        >
          <path
            d='M17.7071 1.70711C18.0976 1.31658 18.0976 0.683417 17.7071 0.292893C17.3166 -0.0976311 16.6834 -0.0976311 16.2929 0.292893L17.7071 1.70711ZM0.292893 16.2929C-0.0976311 16.6834 -0.0976311 17.3166 0.292893 17.7071C0.683417 18.0976 1.31658 18.0976 1.70711 17.7071L0.292893 16.2929ZM16.2929 17.7071C16.6834 18.0976 17.3166 18.0976 17.7071 17.7071C18.0976 17.3166 18.0976 16.6834 17.7071 16.2929L16.2929 17.7071ZM1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L1.70711 0.292893ZM16.2929 0.292893L0.292893 16.2929L1.70711 17.7071L17.7071 1.70711L16.2929 0.292893ZM17.7071 16.2929L1.70711 0.292893L0.292893 1.70711L16.2929 17.7071L17.7071 16.2929Z'
            fill='white'
          />
        </svg>
      </span>

      <main className='max-w-[885px] relative '>
        <MdOutlineKeyboardArrowLeft
          onClick={() => {
            if (activeIndex !== 0) {
              swiperRef.current.slidePrev();
              setActiveIndex(swiperRef.current?.activeIndex);
            }
          }}
          className={`absolute text-[3rem] top-[40%] justify-between left-[-60px] ${
            activeIndex === 0
              ? 'opacity-[0.4] text-white'
              : 'text-primary-100 opacity-[1] cursor-pointer'
          }`}
        />
        <MdOutlineKeyboardArrowRight
          onClick={() => {
            if (activeIndex !== swiperRef.current?.slides?.length - 1) {
              swiperRef.current.slideNext();
              setActiveIndex(swiperRef.current?.activeIndex);
            }
          }}
          className={`absolute top-[40%] text-[3rem] justify-between right-[-60px] ${
            swiperRef.current?.activeIndex ===
            swiperRef.current?.slides?.length - 1
              ? ' opacity-[0.4] text-white'
              : 'text-primary-100 opacity-[1] cursor-pointer'
          }`}
        />

        <Swiper
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          spaceBetween={0}
          slidesPerView={1}
        >
          {initialFiles.map((file, id) => {
            return (
              <SwiperSlide id={`${file?.id}:::${id}`} key={id}>
                <FeedPostImages file={file} />
              </SwiperSlide>
            );
          })}
        </Swiper>

        <section className='flex w-[100%]  max-w-[885px] justify-between items-center'>
          <div className='justify-start items-center gap-1 flex'>
            <img className='w-3 h-3' src='https://via.placeholder.com/12x12' />
            <input
              onChange={handleInputChange}
              name='title'
              defaultValue={feedData?.title}
              placeholder='Halloween 2021 Fans'
              className="[background:none] appearance-none text-neutral-300 text-sm font-medium font-['Montserrat'] leading-tight"
            />
          </div>
          <button
            onClick={() => {
              handleDeleteFile(activeIndex);
            }}
            className="w-[95px] gap-[0.5rem] flex items-center justify-center h-9 rounded-sm border-[1px] border-primary-100 justify-start items-start inline-flex opacity-90 text-neutral-300 text-sm font-medium font-['Montserrat'] leading-tight"
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='10'
              height='10'
              viewBox='0 0 18 18'
              fill='none'
            >
              <path
                d='M17.7071 1.70711C18.0976 1.31658 18.0976 0.683417 17.7071 0.292893C17.3166 -0.0976311 16.6834 -0.0976311 16.2929 0.292893L17.7071 1.70711ZM0.292893 16.2929C-0.0976311 16.6834 -0.0976311 17.3166 0.292893 17.7071C0.683417 18.0976 1.31658 18.0976 1.70711 17.7071L0.292893 16.2929ZM16.2929 17.7071C16.6834 18.0976 17.3166 18.0976 17.7071 17.7071C18.0976 17.3166 18.0976 16.6834 17.7071 16.2929L16.2929 17.7071ZM1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L1.70711 0.292893ZM16.2929 0.292893L0.292893 16.2929L1.70711 17.7071L17.7071 1.70711L16.2929 0.292893ZM17.7071 16.2929L1.70711 0.292893L0.292893 1.70711L16.2929 17.7071L17.7071 16.2929Z'
                fill='white'
              />
            </svg>
            Delete
          </button>
        </section>
        <h1 className="mb-[8px] text-primary-100 text-sm font-medium font-['Montserrat'] leading-tight">
          Post Caption
        </h1>
        <section className='flex items-end  justify-between  '>
          <textarea
            onChange={handleInputChange}
            name='description'
            defaultValue={feedData?.description}
            placeholder='It`s my first travel to beach. And I prepared for you something special'
            className="placeholder:text-neutral-300 placeholder:text-sm placeholder:font-medium placeholder:font-['Montserrat'] outline-0  hover:outline-0 focus:outline-0 focus-within:outline-0  p-[1rem] [background:none] appearance-none w-[100%] max-w-[885px]  h-[106px] rounded-sm border-[1px] border-primary-100"
          />
        </section>
        <div className='flex items-center gap-[0.5rem] absolute bottom-[8px] right-[-350px]'>
          <button
            onClick={handleClose}
            className="w-[100px] h-9 cursor-pointer  rounded-sm shadow text-center text-white text-base font-medium font-['Montserrat'] leading-[18.40px]"
          >
            Cancel
          </button>
          <button
            onClick={handlePostFeed}
            className="w-[152px] gap-[0.5rem] cursor-pointer h-9 bg-red-700 rounded-sm  flex items-center justify-center shadow text-center text-white text-base font-medium font-['Montserrat'] leading-[18.40px]"
          >
            {loading && <Spinner />}
            Post to Feed
          </button>
        </div>
      </main>
    </Popup>
  );
};

const FeedPostImages = ({ file }) => {
  return (
    <>
      <img
        className='w-[100%] h-[588px] max-w-[885px] mb-[33px] '
        src={file?.previewUrl}
      />
    </>
  );
};

export default PostFeedPopUp;
