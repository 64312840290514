import { useEffect, useState } from 'react';
import moment from 'moment';
import errorFormmatter from '../../../../utils/errorFormatter';
import notification from '../../../../utils/notification';
import { ReactComponent as PencilEditIcon } from '../../../../svgs/pencilEdit.svg';
import AvailabilityPop from './AvailabilityPop';
import {
  getClubAvailabilities,
  saveClubAvailability,
} from '../../../../request/club';

const clubId = 'b002d30c-ef07-4462-8489-2c9d4568da3a';

const ClubAvailability = ({}) => {
  const [loading, setLoading] = useState(false);
  const [openEditAvailability, setOpenEditAvailability] = useState(false);
  const [availabilities, setAvailabilities] = useState([]);

  const handleGetClubAvailabilities = async () => {
    try {
      const res =
        (await getClubAvailabilities({
          clubId,
        })) ?? [];

      res?.sort((a, b) => {
        if (a?.dayName && b?.dayName) {
          const timeA = moment();
          const timeB = moment();
          timeA.day(a?.dayName.toLowerCase());
          timeB.day(b?.dayName.toLowerCase());
          return timeA.diff(timeB, 'day') > 0;
        }
      });

      setAvailabilities([...res]);
    } catch (error) {
      setAvailabilities([]);
    }
  };

  const handleUpdateAvailability = async (data) => {
    try {
      setLoading(true);
      const { day, from, to, isOpen } = data;

      const payload = new FormData();

      payload.append('available', isOpen);
      payload.append('startTime', from);
      payload.append('endTime', to);
      payload.append('clubId', clubId);
      payload.append('dayName', day);
      await saveClubAvailability({
        payload,
      });
      notification({
        title: 'Club Availability',
        message: 'Successfully updated club availability',
        type: 'success',
      });
      setLoading(false);
    } catch (error) {
      notification({
        title: 'Club Availability',
        message: errorFormmatter(error),
        type: 'danger',
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetClubAvailabilities();
  }, []);

  useEffect(() => {
    handleGetClubAvailabilities();
  }, [loading]);

  return (
    <>
      <h1 className="mb-[24px] text-white text-2xl font-bold font-['Montserrat'] leading-7">
        Club open
        <br />
        hours
      </h1>
      <div
        onClick={() => setOpenEditAvailability(true)}
        className='w-[26px] h-[26px] bg-white top-[5%] right-[7%] absolute flex items-center justify-center cursor-pointer'
      >
        <PencilEditIcon />
      </div>
      <section>
        {availabilities.length > 0 ? (
          availabilities.map((availability, index) => {
            if (availability?.dayName) {
              return (
                <Day
                  key={index}
                  dayName={availability?.dayName}
                  available={availability?.available}
                  startTime={availability?.startTime}
                  endTime={availability?.endTime}
                />
              );
            }
          })
        ) : (
          <>
            <Day dayName={'Monday'} />
            <Day dayName={'Tuesday'} />
            <Day dayName={'Wednesday'} />
            <Day dayName={'Thursday'} />
            <Day dayName={'Friday'} />
            <Day dayName={'Saturday'} />
            <Day dayName={'Sunday'} />
          </>
        )}
      </section>
      <AvailabilityPop
        handleUpdateAvailability={handleUpdateAvailability}
        open={openEditAvailability}
        availabilities={availabilities}
        handleClose={() => setOpenEditAvailability(false)}
      />
    </>
  );
};

const Day = ({ dayName, available = false, startTime = '', endTime = '' }) => {
  return (
    <div className='w-[175px] h-[43px] mb-[14px]'>
      <p className="mb-[5px] opacity-70 text-neutral-300 text-sm font-medium font-['Montserrat'] leading-tight">
        {dayName}
      </p>
      {available ? (
        <p className=" text-white text-[17px] font-semibold font-['Montserrat'] leading-tight">
          {startTime} — {endTime}
        </p>
      ) : (
        <p className=" text-white text-[17px] font-semibold font-['Montserrat'] leading-tight">
          Not open
        </p>
      )}
    </div>
  );
};

export default ClubAvailability;
