import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MODEL } from '../const/Const';

export const withRole = Component => {
	const WithRole = props => {
		const { isPhotoUploaded, isProfileCompleted, isVerified } = useSelector(state => state?.model);
		const { userType } = useSelector(state => state?.user);

		const history = useHistory();

		if (userType?.toLowerCase() === MODEL) {
			redirectModel();
		}

		function redirectModel() {
			if (!isPhotoUploaded) return history.push('/model-upload-picture');
			if (!isVerified) return history.push('/unverified-model');
			if (!isProfileCompleted) return history.push('/registration-model-info');
		}

		return <Component {...props} />;
	};

	return WithRole;
};
