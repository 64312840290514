import { useState } from 'react';
import { useMutation } from 'react-query';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Popup from 'reactjs-popup';
import styles from './productUpload.module.scss';
import classname from 'classnames';
import notification from '../../utils/notification';
import errorFormmatter from '../../utils/errorFormatter';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

const ProductUploadPopup = ({ open, handleClose, handleConfirmProduct }) => {
  const modelId = useSelector((state) => state?.user?.clientId);
  const [file, setFile] = useState('');
  const [image, setImage] = useState('');
  const history = useHistory();

  const verifyModel = (data) =>
    axios.post('/ModelsProfile/AddVerificationModel', data);
  const verifyImageMutation = useMutation('verifyModel', verifyModel, {
    retry: 0,
  });

  const handleVerifyModel = async () => {
    try {
      // notify the user to upload image for verification
      if (!file) throw new Error('Please upload an image for verification');

      const formData = new FormData();
      formData.append('FileUpload', file);
      formData.append('ModelId', modelId);

      await verifyImageMutation.mutateAsync(formData, {
        onSuccess: (data) => {
          notification({
            title: 'Model Verification',
            message: data?.data?.message,
            type: 'success',
          });

          setFile(null);
          setImage('');
          const successfullyVerified = 'Verification successfully'.toLowerCase();
          //redirect to the user to the profile
          if (data?.data?.message?.toLowerCase() === successfullyVerified)
            history.push('/model-profile');
        },
      });
    } catch (e) {
      notification({
        title: 'Registration Error',
        message: errorFormmatter(e),
        type: 'danger',
      });
    }
  };

  const handleChange = (e) => {
    const file = e?.target?.files[0];
    if (!file) return;
    setFile(file);
    hangleImagePreview(file);
  };

  const hangleImagePreview = (file) => {
    const imgUrl = URL.createObjectURL(file);
    setImage(imgUrl);
  };

  const handleDrop = (e) => {
    e.preventDefault();

    setFile(e.dataTransfer.files[0]);

    // const imageRegex = /image(?=\/jpeg|\/png|\/jpg)/;
    const dropedImage = e.dataTransfer.files[0];
    // const image = dropedImage.type.match(imageRegex);

    if (dropedImage) {
      hangleImagePreview(dropedImage);
    }

    return;
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const closeModal = () => {
    setFile(null);
    setImage(null);
    handleClose(false);
  };

  return (
    <Popup
      open={open}
      lockScroll
      className='photoLoad-popup'
      onClose={closeModal}
    >
      <span className='login-popup--close' onClick={() => handleClose(false)}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='18'
          height='18'
          viewBox='0 0 18 18'
          fill='none'
        >
          <path
            d='M17.7071 1.70711C18.0976 1.31658 18.0976 0.683417 17.7071 0.292893C17.3166 -0.0976311 16.6834 -0.0976311 16.2929 0.292893L17.7071 1.70711ZM0.292893 16.2929C-0.0976311 16.6834 -0.0976311 17.3166 0.292893 17.7071C0.683417 18.0976 1.31658 18.0976 1.70711 17.7071L0.292893 16.2929ZM16.2929 17.7071C16.6834 18.0976 17.3166 18.0976 17.7071 17.7071C18.0976 17.3166 18.0976 16.6834 17.7071 16.2929L16.2929 17.7071ZM1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L1.70711 0.292893ZM16.2929 0.292893L0.292893 16.2929L1.70711 17.7071L17.7071 1.70711L16.2929 0.292893ZM17.7071 16.2929L1.70711 0.292893L0.292893 1.70711L16.2929 17.7071L17.7071 16.2929Z'
            fill='white'
          />
        </svg>
      </span>

      <div
        className={classNames(styles.wrapper, 'photoLoad-popup--wrapper')}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <div className='photoLoad-popup--left'>
          <p className='photoLoad-popup--title'>Upload new Product</p>
          {!file && (
            <div className={styles.informToUpload}>
              You have not uploaded <br />
              any product yet
            </div>
          )}
          <div className='photoLoad-popup--img'></div>
          <div id='output' className={styles.output}>
            {/* {image && (
              <img
                src={image}
                alt='image preview'
                className={styles.imagePreview}
              />
            )} */}
          </div>
          <div className='photoLoad-popup--btnWrapper'>
            <label
              className={classname(
                'btn btn__red photoLoad-popup--btn',
                styles.label
              )}
            >
              <input
                type='file'
                name='myFile'
                onChange={handleChange}
                id='myFile'
                className={styles.fileUpload}
              />
              Upload from computer
            </label>
            <button
              className={classname(
                'btn btn__red send-js photoLoad-popup--btn'
                // !file || verifyImageMutation.isLoading
                //   ? 'photoLoad-popup--btn__disabled'
                //   : ''
              )}
              onClick={handleConfirmProduct}
              // disabled={verifyImageMutation.isLoading}
            >
              {verifyImageMutation.isLoading
                ? 'Confirming upload...'
                : 'Confirm upload'}
            </button>
          </div>
        </div>
        <div className={styles.leftContainer}>
          <label className={styles.dropzone}>
            <input
              name='myFile'
              type='file'
              onChange={handleChange}
              id='myFile'
              //   accept='image/*'
            />

            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='60'
              height='68'
              viewBox='0 0 60 68'
              fill='none'
            >
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M0 53.9974L2.33128e-06 13.9974C2.33128e-06 6.6336 5.96954 0.664062 13.3333 0.664063L46.6667 0.664064C54.0305 0.664064 60 6.6336 60 13.9974V35.1412C60 36.1671 59.8818 37.1808 59.6529 38.1641H46.6667C37.9222 38.1641 30.8333 45.2529 30.8333 53.9974V66.9836C29.85 67.2125 28.8364 67.3307 27.8105 67.3307H13.3333C5.96954 67.3307 -3.21882e-07 61.3612 0 53.9974ZM35.8333 64.647C36.3268 64.2752 36.7966 63.8675 37.2386 63.4255L56.0948 44.5693C56.5367 44.1274 56.9444 43.6576 57.3162 43.1641H46.6667C40.6836 43.1641 35.8333 48.0143 35.8333 53.9974L35.8333 64.647Z'
                fill='#B87693'
              />
            </svg>
            <h5 className={styles.actionTitle}>Drag file here</h5>
            <h5 className={styles.actionSubTitle}>
              .zip, .doc, .rar, .txt and other file types less than 1000 MB
              (only 1 upload at a time)
            </h5>
          </label>
        </div>
      </div>
    </Popup>
  );
};

export default ProductUploadPopup;
