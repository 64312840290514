import React from 'react';
import './App.css';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import moment from 'moment';

function App({ hanleDateChange, value }) {
	const format = 'YYYY-MM-DD';

	const maxDate = moment()
		.subtract(18, 'years')
		.toDate();

	return (
		<div>
			<DatePickerComponent
				placeholder={value ? value : 'Date of birth'}
				format='yyyy-MM-dd'
				max={maxDate}
				change={
					e => hanleDateChange(moment(e?.value).format(format)) // start="Year" // Uncomment below properties to show month picker. Note that, range restiction (min and max properties) should be removed for this case.
				}
			/>
		</div>
	);
}

export default App;
