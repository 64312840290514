import CustomModal from '../../../custom/Modal/Modal';
import styles from '../styles.module.scss';
import Button from '../../../custom/btn/Btn';
import classNames from 'classnames';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FileUpload from '../../../custom/fileUpload/FileUpload';
import { useState } from 'react';
import notification from '../../../utils/notification';
import errorFormmatter from '../../../utils/errorFormatter';
import { createAlbum } from '../../../request/model';
import { useSelector } from 'react-redux';
import Spinner from '../../../custom/spinner/Spinner';

const CreateAlbumSchema = Yup.object().shape({
  title: Yup.string().trim().required('title is required'),
  description: Yup.string().trim().required('description is required'),
  isHidden: Yup.bool().required('permission is required'),
  albumCover: Yup.string().required('album cover is required'),
});

const initialValue = {
  title: 'Untitled Album',
  description: '',
  isHidden: false,
  albumCover: '',
};

const CreateAlbum = ({ open, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const [permission, setPermission] = useState({
    isHidden: false,
    IsOnDemand: false,
    IsFreeForAll: false,
    IsFreeForFanClub: false,
  });

  const user = useSelector((state) => state?.user);

  const handleCreateAlbum = async (values) => {
    try {
      const payload = new FormData();

      payload.append('registeredId', user?.clientId);
      payload.append('albumName', values.title);
      payload.append('isHidden', permission.isHidden);
      payload.append('IsOnDemand', permission.IsOnDemand);
      payload.append('IsFreeForAll', permission.IsFreeForAll);
      payload.append('IsFreeForFanClub', permission.IsFreeForFanClub);
      payload.append('albumDescription', values.description);
      payload.append('addAlbumCoverImage', values.albumCover);
      setLoading(true);
      const response = await createAlbum({ payload });
      setLoading(false);
      handleClose();
      notification({
        message: response?.message ?? 'Album added successfully',
      });
    } catch (error) {
      notification({
        message: errorFormmatter(error) ?? 'Album failed to create',
        type: 'danger',
      });
      setLoading(false);
    }
  };

  const handlePermission = (name) => {
    setPermission({
      ...permission,
      [name]: !permission[name],
    });
  };

  return (
    <CustomModal open={open}>
      <div className={styles.createPhotoSetPopupContainer}>
        <section className={styles.nyWrapperContainer}>
          <Formik
            enableReinitialize={true}
            validationSchema={CreateAlbumSchema}
            initialValues={initialValue}
            onSubmit={handleCreateAlbum}
          >
            {(props) => {
              return (
                <section className={styles.nyContainer}>
                  <FileUpload
                    handleFileChange={(file) => {
                      props.setFieldValue('albumCover', file);
                    }}
                    renderElement={
                      <div className={styles.albumCoverImageContainer}>
                        {typeof props.values.albumCover === 'object' ? (
                          <img
                            src={URL.createObjectURL(props.values.albumCover)}
                          />
                        ) : (
                          <div className={styles.createAlbumPreview}>
                            Album Image
                          </div>
                        )}
                        {props.touched && props.errors?.albumCover && (
                          <span className={styles.error}>
                            * {props.errors?.albumCover}
                          </span>
                        )}
                      </div>
                    }
                  />

                  <div className={styles.nyDetailWrapper}>
                    <section className={styles.nyTopContainer}>
                      <h1 className={styles.nyTitle}>{props.values.title}</h1>
                      <div className={styles.actionButtonContainer}>
                        {loading && <Spinner />}
                        <Button
                          text='Cancel'
                          className={styles.cancelButton}
                          onClick={handleClose}
                        />
                        <Button
                          text='Create'
                          className={styles.saveButton}
                          onClick={props.handleSubmit}
                        />
                      </div>
                    </section>

                    <div className={styles.nyActionContainer}>
                      <label className={styles.nyLabel}>
                        <span> Permissions</span>
                        <ul className={styles.nyPermissions}>
                          <li
                            onClick={() => handlePermission('IsFreeForAll')}
                            className={classNames(
                              styles.nyPermission,
                              permission.IsFreeForAll && styles.nyActive
                            )}
                          >
                            Free for All
                          </li>
                          <li
                            onClick={() => handlePermission('IsFreeForFanClub')}
                            className={classNames(
                              styles.nyPermission,
                              permission.IsFreeForFanClub && styles.nyActive
                            )}
                          >
                            Free for Fan Club
                          </li>
                          <li
                            onClick={() => handlePermission('IsOnDemand')}
                            className={classNames(
                              styles.nyPermission,
                              permission.IsOnDemand && styles.nyActive
                            )}
                          >
                            On Demand
                          </li>
                          <li
                            onClick={() => handlePermission('isHidden')}
                            className={classNames(
                              styles.nyPermission,
                              permission.isHidden && styles.nyActive
                            )}
                          >
                            Hidden
                          </li>
                        </ul>
                        {props.touched && props.errors?.isHidden && (
                          <span className={styles.error}>
                            * {props.errors?.isHidden}
                          </span>
                        )}
                      </label>
                    </div>
                    <label className={styles.nyLabel}>
                      <span>Title</span>
                      <input
                        className={styles.input}
                        placeholder='Title'
                        name='title'
                        onChange={props.handleChange}
                        defaultValue={props.values.title}
                      />
                      {props.touched && props.errors?.title && (
                        <span className={styles.error}>
                          * {props.errors?.title}
                        </span>
                      )}
                    </label>

                    <label className={styles.nyLabel}>
                      <span>Description</span>
                      <textarea
                        name='description'
                        onChange={props.handleChange}
                        className={styles.textarea}
                        placeholder='Album description'
                      ></textarea>
                      {props.touched && props.errors?.description && (
                        <span className={styles.error}>
                          * {props.errors?.description}
                        </span>
                      )}
                    </label>
                  </div>
                </section>
              );
            }}
          </Formik>
        </section>
      </div>
    </CustomModal>
  );
};

export default CreateAlbum;
