import CustomModal from '../../../custom/Modal/Modal';
import styles from '../styles.module.scss';
import Button from '../../../custom/btn/Btn';
import classNames from 'classnames';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FileUpload from '../../../custom/fileUpload/FileUpload';
import { useState } from 'react';
import notification from '../../../utils/notification';
import errorFormmatter from '../../../utils/errorFormatter';
import { createFanClubPhotoSet } from '../../../request/model';
import { useSelector } from 'react-redux';
import Spinner from '../../../custom/spinner/Spinner';

const CreatePhotoSetSchema = Yup.object().shape({
  title: Yup.string().trim().required('title is required'),
  description: Yup.string().trim().required('description is required'),
  isHidden: Yup.bool().required('permission is required'),
  photoSetImage: Yup.string().required('photo set image is required'),
});

const initialValue = {
  title: '',
  description: '',
  isHidden: false,
  photoSetImage: '',
};

const CreatePhotoSetPopup = ({ open, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const [permission, setPermission] = useState({
    isHidden: false,
  });

  const user = useSelector((state) => state?.user);

  const handleCreatePhotoSet = async (values) => {
    try {
      const payload = new FormData();

      payload.append('registeredId', user?.clientId);
      payload.append('photoSetName', values.title);
      payload.append('isHidden', permission.isHidden);
      payload.append('photoSetDescription', values.description);
      payload.append('addPhotoSetCoverImage', values.photoSetImage);
      setLoading(true);
      const response = await createFanClubPhotoSet({ payload });
      setLoading(false);
      handleClose();
      notification({
        message: response?.message ?? 'Fan club photo added successfully',
      });
    } catch (error) {
      notification({
        message: errorFormmatter(error) ?? 'Fan club photo failed to add',
        type: 'danger',
      });
      setLoading(false);
    }
  };

  const handlePermission = () => {
    setPermission({
      ...permission,
      isHidden: !permission.isHidden,
    });
  };

  return (
    <CustomModal open={open}>
      <div className={styles.createPhotoSetPopupContainer}>
        <section className={styles.nyWrapperContainer}>
          <Formik
            enableReinitialize={true}
            validationSchema={CreatePhotoSetSchema}
            initialValues={initialValue}
            onSubmit={handleCreatePhotoSet}
          >
            {(props) => {
              return (
                <section className={styles.nyContainer}>
                  <FileUpload
                    handleFileChange={(file) => {
                      props.setFieldValue('photoSetImage', file);
                    }}
                    renderElement={
                      <div className={styles.photoSetCoverImageContainer}>
                        {typeof props.values.photoSetImage === 'object' ? (
                          <img
                            src={URL.createObjectURL(
                              props.values.photoSetImage
                            )}
                          />
                        ) : (
                          <div className={styles.createPhotoSetPreview}>
                            Cover Image
                          </div>
                        )}
                        {props.touched && props.errors?.photoSetImage && (
                          <span className={styles.error}>
                            * {props.errors?.photoSetImage}
                          </span>
                        )}
                      </div>
                    }
                  />

                  <div className={styles.nyDetailWrapper}>
                    <section className={styles.nyTopContainer}>
                      <h1 className={styles.nyTitle}>{props.values.title}</h1>
                      <div className={styles.actionButtonContainer}>
                        {loading && <Spinner />}
                        <Button
                          text='Cancel'
                          className={styles.cancelButton}
                          onClick={handleClose}
                        />
                        <Button
                          text='Create'
                          className={styles.saveButton}
                          onClick={props.handleSubmit}
                        />
                      </div>
                    </section>

                    <div className={styles.nyActionContainer}>
                      <label className={styles.nyLabel}>
                        <span> Permissions</span>
                        <ul className={styles.nyPermissions}>
                          <li
                            onClick={handlePermission}
                            className={classNames(
                              styles.nyPermission,
                              permission.isHidden && styles.nyActive
                            )}
                          >
                            Hidden
                          </li>
                        </ul>
                        {props.touched && props.errors?.isHidden && (
                          <span className={styles.error}>
                            * {props.errors?.isHidden}
                          </span>
                        )}
                      </label>
                    </div>
                    <label className={styles.nyLabel}>
                      <span>Title</span>
                      <input
                        className={styles.input}
                        placeholder='Title'
                        name='title'
                        onChange={props.handleChange}
                      />
                      {props.touched && props.errors?.title && (
                        <span className={styles.error}>
                          * {props.errors?.title}
                        </span>
                      )}
                    </label>

                    <label className={styles.nyLabel}>
                      <span>Description</span>
                      <textarea
                        name='description'
                        onChange={props.handleChange}
                        className={styles.textarea}
                        placeholder='It`s my first travel to beach. And I prepared for you something special'
                      ></textarea>
                      {props.touched && props.errors?.description && (
                        <span className={styles.error}>
                          * {props.errors?.description}
                        </span>
                      )}
                    </label>
                  </div>
                </section>
              );
            }}
          </Formik>
        </section>
      </div>
    </CustomModal>
  );
};

export default CreatePhotoSetPopup;
