import clubUrl from '../../../images/club.png';
import clubUrl1 from '../../../images/club-1.png';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { getPremiumModels } from '../../../request/member';
import Slider from 'react-slick';
import Button from '../../../custom/btn/Btn';
import modelUrl from '../../../images/trashimg/034.png';
import React from 'react';
import { useRef, useState, useEffect } from 'react';
import useGridDisplay from '../../../hooks/useGridDisplay';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

const ClubDisplayView = ({ clubs, activeTab }) => {
  const [displayData, setDisplayData] = useState([]);
  const firstGalleryRef = useRef(null);
  const secondGalleryRef = useRef(null);
  const { renderGallery } = useGridDisplay();
  const history = useHistory();
  const [premiumModels, setPremiumModels] = useState([]);

  const handleGetPremiumModels = async () => {
    try {
      const response = await getPremiumModels();

      setPremiumModels(response?.models ?? []);
    } catch (error) {}
  };

  const customView = ({ image, width }) => {
    const { src } = image;
    const item = document.createElement('section');

    const galleryItemClass = '!h-auto mb-[64px] cursor-pointer';
    item.className = `${galleryItemClass}`;
    item.style = `width:${width}%`;

    item.insertAdjacentHTML(
      'afterbegin',
      `
      <img src=${image?.coverURL} class='w-[100%] !h-[422px] mb-[24px]'/>
        <div class=' flex-col justify-start items-start gap-3 flex'>
          <div class='self-stretch justify-between items-baseline inline-flex'>
            <span class="block text-white text-2xl font-bold font-['Montserrat'] leading-7">
             ${image?.clubName}
            </span>
           
          </div>
          <div class='self-stretch justify-between items-baseline inline-flex'>
            <span class="block text-zinc-400 text-base font-medium font-['Montserrat'] leading-[18.40px]">
           ${image?.address}
          </span>
          </div>
         
        </div>
      `
    );

    item.addEventListener('click', () => {
      history.push(`clubs-detail?clubId=${image?.registerId}`);
    });
    return item;
  };

  useEffect(() => {
    handleGetPremiumModels();
  }, []);

  useEffect(() => {
    setDisplayData(() => {
      return [...clubs];
    });
  }, [clubs]);
  return (
    <main>
      <section ref={firstGalleryRef}>
        {renderGallery({
          images: displayData?.slice(0, 6)?.map((item) => {
            return {
              ...item,
              src: item?.coverURL,
            };
          }),
          containerRef: firstGalleryRef.current,
          createGalleryItem: customView,
        })}
      </section>
      {activeTab === 'featured' && (
        <Swiper
          loop={true}
          modules={[Autoplay]}
          autoplay={{
            delay: 5000,
          }}
          spaceBetween={0}
          slidesPerView={1}
        >
          {premiumModels.map((model, index) => {
            return (
              <SwiperSlide key={index}>
                <section
                  key={index}
                  className='!inline-flex !w-[100%] gap-[31px] mb-[64px]'
                >
                  <div className=' w-[70%] h-[480px] relative bg-[#4E3E63]'>
                    <img
                      className='w-[100%] h-[480px] top-0 bottom-0 absolute'
                      src={model?.profilePicUrl}
                    />

                    <Link
                      to={`/detail/about?model=${model?.registerId}&lastName=${model?.lastName}&firstName=${model?.firstName}`}
                      className="border-0 flex items-center justify-center  w-[164px] h-12 left-[64px] top-[365px] absolute  bg-red-700 rounded-sm shadow text-center text-white text-base font-medium font-['Montserrat'] hover:text-white"
                    >
                      Take a look
                    </Link>
                    <h2 className="w-[565px] h-[65px] left-[60px] top-[266px] absolute opacity-95 text-red-700 text-[30px] font-bold font-['Montserrat']">
                      {model?.firstName} {model?.lastName}
                    </h2>
                    <h1 className="left-[63px] top-[218px] absolute text-red-700 text-[35px] font-medium font-['Montserrat'] leading-7">
                      Premium models
                    </h1>
                  </div>
                  <div className='w-[30%] h-[480px]'>
                    <img className='w-[410px] h-[480px]' src={clubUrl1} />
                  </div>
                </section>
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
      <section ref={secondGalleryRef}>
        {renderGallery({
          images: displayData?.slice(6, displayData.length)?.map((item) => {
            return {
              ...item,
              src: item?.coverURL,
            };
          }),
          containerRef: secondGalleryRef.current,
          createGalleryItem: customView,
        })}
      </section>
    </main>
  );
};

export default ClubDisplayView;
