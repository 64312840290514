import { TipTapEditor } from './tip';
import ToolBar from './ToolBar/ToolBar';
import { useEditor } from '@tiptap/react';
import { getExtensions } from './extensions/starter-kit';
import { useEffect, useState } from 'react';

const placeholder = `
<p style="text-align: justify"> Its my first travel to beach. And I prepared for you something special</p>
`;

const RichTextEditor = ({
  children,
  toolBarChildrenContent,
  onChange,
  content,
}) => {
  const [mode, setMode] = useState('main-editor');
  const editor = useEditor({
    extensions: [...getExtensions()],
    content: placeholder,
    autofocus: false,
    editorProps: {
      attributes: {
        class: mode,
        spellCheck: 'false',
        suppressContentEditableWarning: 'true',
      },
    },
    onUpdate: ({ editor }) => {
      if (onChange) {
        onChange(editor.getText());
      }
    },
  });

  useEffect(() => {
    if (content) {
      editor?.commands?.setContent(content);
    }
  }, [editor, content]);

  return (
    <>
      <TipTapEditor editor={editor}>{children}</TipTapEditor>
      <ToolBar editor={editor}>
        {typeof toolBarChildrenContent === 'function'
          ? toolBarChildrenContent()
          : toolBarChildrenContent}
      </ToolBar>
    </>
  );
};

export default RichTextEditor;
