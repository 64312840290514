import Photo from '../../../custom/photo/Photo';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { ReactComponent as EditIcon } from '../../../images/svg/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../images/svg/delete-icon.svg';
import { ReactComponent as AlbumIcon } from '../../../images/svg/album-icon.svg';

const ShowPreview = ({
  className,
  handleOpenEditShowModal,
  deleteShow,
  handleShowSelection,
  show,
  showId,
  isSelected,
  ...props
}) => {
  return (
    <section className={classnames(styles.section, className)}>
      <section className={styles.wrapper}>
        <button className={styles.btnEdit} onClick={handleOpenEditShowModal}>
          <EditIcon className={styles.editIcon} />
          Edit
        </button>
        <section className={styles.checkBoxWrapper}>
          <fieldset className={styles.checkboxFieldset}>
            <input
              type='checkbox'
              className={styles.checkbox}
              onChange={() => handleShowSelection(showId)}
              checked={isSelected?.(showId)}
            />
          </fieldset>
          <DeleteIcon className={styles.deleteIcon} onClick={deleteShow} />
        </section>

        <section className={styles.nameWrapper}>
          <fieldset>
            <label className={styles.inputNameLabel}>
              <AlbumIcon className={styles.editIcon} />
              <input
                type='text'
                className={styles.nameInput}
                value={show?.showName}
              />
            </label>
          </fieldset>
        </section>
      </section>
      <Photo className={styles.photo} {...props} photo={show} />
    </section>
  );
};

export default ShowPreview;
