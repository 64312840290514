import styles from './addAvailability.module.scss';
import Popup from 'reactjs-popup';
import DateTimePicker from '../../../custom/datePicker/DatePicker';
import Button from '../../../custom/btn/Btn';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import moment from 'moment';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Spinner from '../../../custom/spinner/Spinner';
import { useEffect, useState } from 'react';

const renderDayContent = (day, date) => {
  return <span className={styles.day}>{day}</span>;
};

const AppointmentSchema = Yup.object().shape({
  from: Yup.string().required('start time is required'),
  to: Yup.string().required('end time is required'),
  date: Yup.string().required('date is required'),
});

const initialValue = {
  from: '',
  to: '',
  date: '',
};

const AddAvailability = ({
  open = false,
  handleCloseTimePicker,
  addAvailability,
  mode,
  isLoading,
  dayIndex,
  currentAppointment,
  update,
}) => {
  const [time, setTime] = useState({
    to: mode ? currentAppointment?.endTime : '',
    from: mode ? currentAppointment?.startTime : '',
  });

  useEffect(() => {
    setTime(() => {
      return {
        to: mode ? currentAppointment?.endTime : '',
        from: mode ? currentAppointment?.startTime : '',
      };
    });
  }, [currentAppointment]);

  return (
    <Popup open={open}>
      <main className={styles.container}>
        {isLoading && <Spinner background={false} isTip={false} />}
        <Formik
          enableReinitialize={true}
          validationSchema={AppointmentSchema}
          initialValues={
            mode
              ? {
                  to: currentAppointment?.endTime,
                  from: currentAppointment?.startTime,
                  date: currentAppointment?.appointmentDate,
                }
              : initialValue
          }
          onSubmit={mode ? update : addAvailability}
        >
          {(props) => {
            return (
              <>
                <div className={styles.appointmentDetailContainer}>
                  <>
                    <div className={styles.appointmentStartEndContainer}>
                      <DateTimePicker
                        onChange={(t) => {
                          setTime(() => {
                            return {
                              ...time,
                              from: moment(t).format('h:mm: a'),
                            };
                          });
                          props.setFieldValue(
                            'from',
                            moment(t).format('h:mm: a')
                          );
                        }}
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat='h:mm aa'
                        renderElement={({ onClick, value }) => {
                          return (
                            <>
                              <label className={styles.label} onClick={onClick}>
                                <span className={styles.title}>From </span>
                                <div className={styles.appointmentDetail}>
                                  {mode ? time.from : value}
                                </div>
                              </label>
                              {props.touched && props.errors?.from && (
                                <span className={styles.error}>
                                  * {props.errors?.from}
                                </span>
                              )}
                            </>
                          );
                        }}
                      />
                      <DateTimePicker
                        onChange={(t) => {
                          setTime(() => {
                            return {
                              ...time,
                              to: moment(t).format('h:mm: a'),
                            };
                          });
                          props.setFieldValue(
                            'to',
                            moment(t).format('h:mm: a')
                          );
                        }}
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat='h:mm aa'
                        renderElement={({ onClick, value }) => {
                          return (
                            <>
                              <label className={styles.label} onClick={onClick}>
                                To
                                <div className={styles.appointmentDetail}>
                                  {mode ? time.to : value}
                                </div>
                              </label>
                              {props.touched && props.errors?.to && (
                                <span className={styles.error}>
                                  * {props.errors?.to}
                                </span>
                              )}
                            </>
                          );
                        }}
                      />
                    </div>
                    <div>
                      <DateTimePicker
                        onChange={(date) => {
                                              let formatDate = moment(date);
                                              // formatDate.day(dayIndex);

                                              props.setFieldValue(
                                                'date',
                                                formatDate.format()
                                              );
                                            }}
                        renderDayContents={renderDayContent}
                        inline
                        openToDate={
                          !mode
                            ? new Date()
                            : new Date(currentAppointment?.appointmentDate)
                        }
                        className={'eventDate'}
                        renderCustomHeader={({
                          date,
                          decreaseMonth,
                          increaseMonth,
                          prevMonthButtonDisabled,
                          nextMonthButtonDisabled,
                        }) => (
                          <div className={styles.dateTimeHeader}>
                            <span className={styles.dateTimeIconContainer}>
                              <FaChevronLeft
                                className={styles.dateIcon}
                                onClick={decreaseMonth}
                                disabled={prevMonthButtonDisabled}
                              />
                            </span>

                            <span className={styles.dateTimeMonthContainer}>
                              {moment(date).format('MMMM')}
                            </span>
                            <span className={styles.dateTimeIconContainer}>
                              <FaChevronRight
                                className={styles.dateIcon}
                                onClick={increaseMonth}
                                disabled={nextMonthButtonDisabled}
                              />
                            </span>
                          </div>
                        )}
                      />
                      {props.touched && props.errors?.date && (
                        <span className={styles.error}>
                          * {props.errors?.date}
                        </span>
                      )}
                    </div>
                  </>
                </div>
                <div className={styles.actionButtonContainer}>
                  <Button
                    text='Cancel'
                    className={styles.cancelButton}
                    onClick={handleCloseTimePicker}
                  />
                  <Button
                    type='submit'
                    text={mode === false ? 'Save' : 'update'}
                    className={styles.saveButton}
                    onClick={props.handleSubmit}
                  />
                </div>
              </>
            );
          }}
        </Formik>
      </main>
    </Popup>
  );
};

export default AddAvailability;
