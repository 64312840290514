import Modal from 'react-modal';
import styles from './modal.module.scss';

const CustomModal = ({ open = false, onClose, content: Component, children }) => {
	return (
		<Modal
			ariaHideApp={false}
			overlayClassName={styles.overlay}
			className={styles.content}
			isOpen={open}
			onRequestClose={onClose}
			portalClassName={styles.portalClassName}
		>
			<div className={styles.container}>
				{Component && <Component />}
				{children && children}
			</div>
		</Modal>
	);
};

export default CustomModal;
