import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';

class RegistrationModel extends Component {
	componentDidMount() {
		document.querySelector('.go-step2').onclick = function() {
			document.querySelector('.reg-model').classList.add('step2');
			document.querySelector('.reg-model .reg-model--step').classList.remove('reg-model--step__active');
			document.querySelector('.reg-model .reg-model--step:nth-child(2)').classList.add('reg-model--step__active');
		};
		document.querySelectorAll('.model-signin').forEach(function(element) {
			element.onclick = function() {
				document.querySelector('.singin-footer').click();
			};
		});
	}
	constructor(props) {
		super(props);
		this.state = { open: false };
		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}
	changeInput(event) {
		let file = event.target.files;
		let f = file[0];
		let reader = new FileReader();
		reader.onload = (function(theFile) {
			return function(e) {
				let fileSize = theFile.size / 1000000;
				if (
					theFile.name.indexOf('.png') !== -1 ||
					theFile.name.indexOf('.jpg') !== -1 ||
					theFile.name.indexOf('.gif') !== -1
				) {
					if (fileSize <= 10) {
						document.querySelector('.photoLoad-popup--img').innerHTML = [
							'<img class="thumb" title="',
							escape(theFile.name),
							'" src="',
							e.target.result,
							'" /><span>',
							escape(theFile.name),
							'</span>',
						].join('');
						document.querySelector('.send-js').classList.remove('photoLoad-popup--btn__disabled');
					}
				}
			};
		})(f);
		reader.readAsDataURL(f);
		document.querySelector('.send-js').onclick = function() {
			document.querySelector('.reg-model').classList.add('step3');
			document.querySelector('.reg-model .reg-model--step:nth-child(2)').classList.remove('reg-model--step__active');
			document.querySelector('.reg-model .reg-model--step:nth-child(3)').classList.add('reg-model--step__active');
		};
	}
	openModal() {
		this.setState({ open: true });
	}
	closeModal() {
		this.setState({ open: false });
	}
	render() {
		return (
			<main className='container'>
				<form className='reg-model'>
					<div className='reg-model-steps'>
						<div className='reg-model--step reg-model--step__active'>Contact Info</div>
						<div className='reg-model--step'>Photo upload</div>
						<div className='reg-model--step'>Validation</div>
					</div>
					<div className='reg-model-step1'>
						<div className='reg-model-step1--left'>
							<div className='reg-model-step1--title'>model registration</div>
							<div className='reg-model-step1--subtitle'>Sign Up now!</div>
							<div className='reg-model-step1-text'>
								<div className='reg-model-step1-text--rotate'>Requirements</div>
								<div>
									<p>
										You have a computer, webcam <br />
										and a good internet connection
									</p>
									<p>
										You are a girl, a guy, shemale <br />
										or a couple, and you are <br />
										at least 18 years old ...
									</p>
									<p>Registration is absolutely free!</p>
								</div>
							</div>
							<div className='reg-model-step1--links'>
								or
								<div className='btn btn__purple model-signin'>Sign In</div>
								if you have an account
							</div>
							<img className='reg-model-step1--img' src={require('../../images/reg-model-women.png')} alt='' />
						</div>
						<div className='reg-model-step1--right'>
							<div className='registration-form--title'>Start here</div>
							<div className='input-wrapper__double'>
								<div>
									<div className='reg-model-step1--form--title'>First name</div>
									<div className='input-wrapper'>
										<input name='name' className='required' type='text' placeholder='Enter your first name' />
									</div>
								</div>
								<div>
									<div className='reg-model-step1--form--title'>Last name</div>
									<div className='input-wrapper'>
										<input name='name' className='required' type='text' placeholder='Enter your last name' />
									</div>
								</div>
							</div>
							<div className='reg-model-step1--form--title'>Email address</div>
							<div className='input-wrapper'>
								<input name='email' className='required' type='text' placeholder='Email address' />
							</div>
							<div className='input-wrapper__double'>
								<div>
									<div className='reg-model-step1--form--title'>Password</div>
									<div className='input-wrapper'>
										<input
											name='password'
											className='required password'
											type='password'
											placeholder='Password'
										/>
									</div>
								</div>
								<div>
									<div className='reg-model-step1--form--title'>Date of birth</div>
									<div className='input-wrapper'>
										<input name='password' className='required date' type='text' placeholder='XX.XX.XXXX' />
									</div>
								</div>
							</div>
							<div className='reg-model-step1--bottom'>
								<div className='btn btn__red go-step2'>Sign up now!</div>
								<div className='politics'>
									By signing up, you agree <br />
									to our <Link to=''>Terms and Conditions</Link>
								</div>
							</div>
						</div>
					</div>
					<div className='reg-model-step2'>
						<div className='reg-model-step2--title'>Validation by photo and IDs</div>
						<div className='reg-model-step2--subtitle'>
							Take a photo with a passport or other document confirming your age
						</div>
						<div className='reg-model-step2--wrapper'>
							<div className='reg-model-step2--left'>
								<div className='reg-model-step2--rotate'>Sample photo</div>
								<div className='reg-model-step2-photoinfo'>
									<p>Supported File Types</p>
									<strong>jpg, gif, png</strong>
									<p>Maximum file size</p>
									<strong>10 Mb</strong>
								</div>
								<div className='reg-model-step2-btn btn btn__red' onClick={this.openModal}>
									Upload a photo
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='23'
										height='18'
										viewBox='0 0 23 18'
										fill='none'
									>
										<path
											d='M11.5 6.75C9.59445 6.75 8.05 8.26155 8.05 10.125C8.05 11.9891 9.59445 13.5 11.5 13.5C13.4051 13.5 14.95 11.9891 14.95 10.125C14.95 8.26155 13.4051 6.75 11.5 6.75ZM20.7 3.375H17.94C17.5605 3.375 17.1516 3.087 17.0317 2.73465L16.3183 0.640125C16.198 0.288 15.7895 0 15.41 0H7.59C7.2105 0 6.80156 0.288 6.68173 0.64035L5.96827 2.73487C5.84798 3.087 5.4395 3.375 5.06 3.375H2.3C1.035 3.375 0 4.3875 0 5.625V15.75C0 16.9875 1.035 18 2.3 18H20.7C21.965 18 23 16.9875 23 15.75V5.625C23 4.3875 21.965 3.375 20.7 3.375ZM11.5 15.75C8.32439 15.75 5.75 13.2316 5.75 10.125C5.75 7.01865 8.32439 4.5 11.5 4.5C14.6751 4.5 17.25 7.01865 17.25 10.125C17.25 13.2316 14.6751 15.75 11.5 15.75ZM19.895 7.19842C19.4506 7.19842 19.09 6.84608 19.09 6.41092C19.09 5.97622 19.4506 5.62343 19.895 5.62343C20.3398 5.62343 20.7 5.97622 20.7 6.41092C20.7 6.84608 20.3396 7.19842 19.895 7.19842Z'
											fill='#EFEFEF'
										/>
									</svg>
								</div>
								<Popup
									open={this.state.open}
									closeOnDocumentClick
									lockScroll
									className='photoLoad-popup'
									onClose={this.closeModal}
								>
									<span className='login-popup--close' onClick={this.closeModal}>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='18'
											height='18'
											viewBox='0 0 18 18'
											fill='none'
										>
											<path
												d='M17.7071 1.70711C18.0976 1.31658 18.0976 0.683417 17.7071 0.292893C17.3166 -0.0976311 16.6834 -0.0976311 16.2929 0.292893L17.7071 1.70711ZM0.292893 16.2929C-0.0976311 16.6834 -0.0976311 17.3166 0.292893 17.7071C0.683417 18.0976 1.31658 18.0976 1.70711 17.7071L0.292893 16.2929ZM16.2929 17.7071C16.6834 18.0976 17.3166 18.0976 17.7071 17.7071C18.0976 17.3166 18.0976 16.6834 17.7071 16.2929L16.2929 17.7071ZM1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L1.70711 0.292893ZM16.2929 0.292893L0.292893 16.2929L1.70711 17.7071L17.7071 1.70711L16.2929 0.292893ZM17.7071 16.2929L1.70711 0.292893L0.292893 1.70711L16.2929 17.7071L17.7071 16.2929Z'
												fill='white'
											/>
										</svg>
									</span>
									<div className='photoLoad-popup--wrapper'>
										<div className='photoLoad-popup--left'>
											<div className='photoLoad-popup--title'>
												You have not uploaded <br />
												any photos yet
											</div>
											<div className='photoLoad-popup--img'></div>
											<div id='output'></div>
											<div className='photoLoad-popup--btnWrapper'>
												<label className='btn btn__red photoLoad-popup--btn' htmlFor='myFile'>
													Upload from computer
												</label>
												<div
													onClick={this.closeModal}
													className='btn btn__red send-js photoLoad-popup--btn photoLoad-popup--btn__disabled'
												>
													Confirm upload
												</div>
											</div>
										</div>
										<div className='photoLoad-popup--right'>
											<label className='dropzone'>
												<input name='myFile' type='file' onChange={this.changeInput} id='myFile' />
											</label>
										</div>
									</div>
								</Popup>
							</div>
							<div className='reg-model-step2--right'>
								<div className='reg-model-step2--right--title'>Pass verification</div>
								<ul>
									<li>
										<strong>What is verification?</strong>
										<p>
											Pass the NSA verification to show that you <br />
											are a real person who has reached the age of majority <br />
											to become a web model. Verification also gives access <br />
											to downloading videos and going on the air.
										</p>
									</li>
									<li>
										<strong>What is needed to verify the identity?</strong>
										<p>
											You only need an NSA account, a snapshot of yourself <br />
											with an identity document. All verification images will <br />
											not be made publicly available and are available only <br />
											to NSA employees.
										</p>
									</li>
									<li>
										<strong>What is a verification image?</strong>
										<p>
											Your high resolution photo on which you hold an identity <br />
											document. The photo should show most of your face.
										</p>
									</li>
									<li>
										<strong>What if verification is rejected?</strong>
										<p>
											If you do not pass the test, you will receive a message <br />
											explaining the reasons why the procedure failed. After <br />
											that you can upload a new photo and verify it again.
										</p>
									</li>
								</ul>
							</div>
						</div>
						<div className='photo-example photo-example__true'>
							<div className='photo-example--title'>Photos passed verification</div>
							<div className='photo-example--wrapper'>
								<div className='photo-example--item'>
									<img src={require('../../images/trashimg/true.png')} alt='' />
								</div>
								<div className='photo-example--item'>
									<img src={require('../../images/trashimg/true.png')} alt='' />
								</div>
								<div className='photo-example--item'>
									<img src={require('../../images/trashimg/true.png')} alt='' />
								</div>
								<div className='photo-example--item'>
									<img src={require('../../images/trashimg/true.png')} alt='' />
								</div>
							</div>
						</div>
						<div className='photo-example photo-example__error'>
							<div className='photo-example--title'>Photos failed verification</div>
							<div className='photo-example--wrapper'>
								<div className='photo-example--item'>
									<img src={require('../../images/trashimg/true.png')} alt='' />
								</div>
								<div className='photo-example--item'>
									<img src={require('../../images/trashimg/true.png')} alt='' />
								</div>
								<div className='photo-example--item'>
									<img src={require('../../images/trashimg/true.png')} alt='' />
								</div>
								<div className='photo-example--item'>
									<img src={require('../../images/trashimg/true.png')} alt='' />
								</div>
							</div>
						</div>
					</div>

					<div className='reg-model-step3'>
						<div className='reg-model-thanks'>
							<div className='reg-model-thanks--title'>
								Thank you <br />
								for the application!
							</div>
							<div className='reg-model-thanks--subtitle'>
								Processing the application will take 3-5 days
								<br />
								and you will be able to proceed with the registration,
								<br />
								we will send you the result of validation by mail.
							</div>
							<Link to='/' className='reg-model-thanks--link btn btn__red'>
								Return to main page
							</Link>
						</div>
						<div className='reg-model-thanks processed'>
							<div className='reg-model-thanks--title'>
								Application <br />
								is being processed
							</div>
							<div className='reg-model-thanks--subtitle'>
								At the moment, the application is under processing. <br />
								After verification of the application by our manager, <br />
								you will be able to proceed with registration, <br />
								we will send the result of validation to your email
							</div>
							<Link to='/' className='reg-model-thanks--link btn btn__red'>
								Return to main page
							</Link>
						</div>
					</div>
				</form>
			</main>
		);
	}
}
export default RegistrationModel;
