export const boldText = (editor) => {
 editor.chain().focus().toggleBold().run();
 return {
  isDisabled: !editor.can().chain().focus().toggleBold().run(),
  activeClass: editor.isActive('bold') ? 'is-active' : '',
 };
};

export const italicText = (editor) => {
 editor.chain().focus().toggleItalic().run();
 return {
  isDisabled: !editor.can().chain().focus().toggleItalic().run(),
  activeClass: editor.isActive('italic') ? 'is-active' : '',
 };
};

export const underlineText = (editor) => {
 editor.chain().focus().toggleUnderline().run();
 return {
  isDisabled: !editor.can().chain().focus().toggleUnderline().run(),
  activeClass: editor.isActive('underline') ? 'is-active' : '',
 };
};

export const textHeading = (editor, level) => {
 editor.chain().focus().toggleHeading({ level }).run();
 return {
  activeClass: editor.isActive('heading', { level }) ? 'is-active' : '',
 };
};

export const textAlign = (editor, align) => {
 editor.chain().focus().setTextAlign(align).run();
 return {
  activeClass: editor.isActive({ textAlign: align }) ? 'is-active' : '',
 };
};

export const sinkList = (editor) => {
 editor.chain().focus().sinkListItem('listItem').run();
};

export const bulletList = (editor) => {
 editor.chain().focus().toggleBulletList().run();
};

export const orderedBulletList = (editor) => {
 editor.chain().focus().toggleOrderedList().run();
};

export const clearFormats = (editor) => {
 editor.chain().focus().clearNodes().unsetAllMarks().run();
};

export const uploadImage = (editor, src, alt, title) => {
 editor.commands.setImage({
  src: src ?? '',
  alt: alt ?? '',
  title: title ?? '',
 });
};

export const History = function (editor) {
 this.history = [];
 this.editor = editor;
};

History.prototype.push = (command) => {
 //  if (this.history.includes(command)) {
 //   for (let i = 0; i < this.history.length; i++) {
 //    if (this.history[i] === command) {
 //     this.history.splice(i, 1);
 //    }
 //   }
 //  } else {
 //   this.history.push(command);
 //  }
 //  alert('pushed');
 console.log(history);
};

History.prototype.clearCommands = () => {
 this.history.forEach((command, index) => {
  this.editor.chain().focus()[this.history[index]]().run();
 });
};
