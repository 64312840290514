import { useEffect, useState } from 'react';
import CheckBox from '../../../custom/checkbox/CheckBox';
import DropDown from '../../../custom/DropDown/DropDown';
import classNames from 'classnames';
import {
  updateStreamingBroadcastConfig,
  getStreamingConfig,
} from '../../../request/model';
import Input from '../../../custom/input/Input';
import { IoIosRemoveCircleOutline } from 'react-icons/io';
import Button from '../../../custom/btn/Btn';
import notification from '../../../utils/notification';
import { useSelector } from 'react-redux';
import {
  createStreamingTip,
  getStreamingTips,
  updateStreamingTip,
  deleteStreamingTip,
} from '../../../request/model';
import errorFormmatter from '../../../utils/errorFormatter';

const TipContainer = () => {
  const [tips, setTips] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [tipSettings, setTipSettings] = useState({});
  const user = useSelector((state) => state?.user);

  const handleGetTipSetting = async () => {
    try {
      const response = await getStreamingConfig({ modelId: user.clientId });

      setTipSettings((prev) => {
        return {
          ...prev,
          ...response,
        };
      });
    } catch (error) {}
  };

  const onChange = async (event) => {
    try {
      const name = event.target.name;
      const value = event.target.checked;
      setTipSettings((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });

      await updateStreamingVolumeSetting(name, value);
    } catch (error) {}
  };

  const handleSendTipTimeChange = (event) => {
    setTipSettings((prev) => {
      return {
        ...prev,
        sendByMinutes: event?.value,
      };
    });
    updateStreamingVolumeSetting('sendByMinutes', event?.value);
  };

  const handleDisplayChange = (event) => {
    setTipSettings((prev) => {
      return {
        ...prev,
        display: event?.value,
      };
    });
    updateStreamingVolumeSetting('display', event?.value);
  };

  const updateStreamingVolumeSetting = async (name, value) => {
    try {
      setLoading(true);
      const payload = new FormData();

      payload.append('tipVolume', tipSettings.tipVolume);
      payload.append('exitVolume', tipSettings.exitVolume);
      payload.append('entryVolume', tipSettings.entryVolume);
      payload.append('allowPrivateChat', tipSettings.allowPrivateChat);
      payload.append('allowGeneralChat', tipSettings.allowGeneralChat);
      payload.append('allowMessage', tipSettings.allowMessage);
      payload.append('allowGuest', tipSettings.allowGuest);
      payload.append('allowMicOff', tipSettings.allowMicOff);
      payload.append('allowCamOn', tipSettings.allowCamOn);
      payload.append('allowTips', tipSettings.allowTips);
      payload.append('registerId', user?.clientId);

      payload.append(
        'sortByPrice',
        name === 'sortByPrice' ? value : tipSettings?.sortByPrice
      );

      payload.append(
        'display',
        name === 'display' ? value : tipSettings?.display
      );
      payload.append(
        'sendByMinutes',
        name === 'sendByMinutes'
          ? parseInt(value)
          : parseInt(tipSettings?.sendByMinutes)
      );

      await updateStreamingBroadcastConfig({
        payload,
      });

      setLoading(false);
      notification({
        title: 'Tip settings',
        message: `Successfully updated tip setting`,
        type: 'success',
      });
    } catch (error) {
      setLoading(false);
      notification({
        title: 'Tip settings',
        message: `Tip setting failed to update`,
        type: 'danger',
      });
    }
  };

  const getAllStreamingTips = async () => {
    try {
      const response = await getStreamingTips(user.clientId);
      setTips(response ?? []);
    } catch (error) {
      setTips([]);
    }
  };

  const addNewTip = async () => {
    try {
      const id = Math.floor(Math.random() * 50) + 1;
      setIsSaving(true);
      const payload = new FormData();
      payload.append('registeredId', user.clientId);
      payload.append('credit', 0);
      payload.append('description', `new tip ${id}`);
      await createStreamingTip(payload);
      notification({
        title: 'Tip settings',
        message: `Successfully added a new tip`,
        type: 'success',
      });
      setIsSaving(false);
    } catch (error) {
      setIsSaving(false);
      notification({
        title: 'Tip settings',
        message: errorFormmatter(error),
        type: 'danger',
      });
    }
  };

  const updateTip = async (id, updatePayload) => {
    try {
      setIsSaving(true);
      const payload = new FormData();
      payload.append('registeredId', user.clientId);
      payload.append('credit', updatePayload?.credit);
      payload.append('description', updatePayload?.description);
      payload.append('id', updatePayload?.id);
      await updateStreamingTip(payload);

      setIsSaving(false);
    } catch (error) {
      setIsSaving(false);
    }
  };

  const removeTip = async (id) => {
    try {
      setIsSaving(true);
      await deleteStreamingTip(user.clientId, id);
      notification({
        title: 'Tip settings',
        message: `Successfully deleted a tip`,
        type: 'success',
      });
      setIsSaving(false);
    } catch (error) {
      notification({
        title: 'Tip settings',
        message: errorFormmatter(error),
        type: 'danger',
      });
    }
  };

  useEffect(() => {
    getAllStreamingTips();
    handleGetTipSetting();
  }, []);

  useEffect(() => {
    getAllStreamingTips();
  }, [isSaving]);

  useEffect(() => {
    handleGetTipSetting();
  }, [loading]);

  return (
    <section key={4} className='flex'>
      <section className='w-[60%]'>
        <section className='mb-[32] '>
          {tips.map((data, index) => {
            return (
              <Tip
                key={data.id}
                tip={data}
                updateGoal={updateTip}
                removeGoal={removeTip}
              />
            );
          })}
        </section>
        <Button
          onClick={addNewTip}
          className={
            "!bg-primary-100 !text-white flex items-center justify-center text-center text-stone-700 text-base font-medium font-['Montserrat']"
          }
          text={'Add New Tip '}
        />
      </section>
      <section className='border-l-[1px] border-primary-100 h-[400px] w-[40%] pl-[50px]'>
        <div className='flex w-[100%] gap-[1rem]  mb-[20px] items-center'>
          <span className="block w-[max-content] opacity-90 text-neutral-300 text-sm font-medium font-['Montserrat'] leading-tight">
            Sort by Price
          </span>
          <CheckBox
            onChange={onChange}
            checked={tipSettings?.sortByPrice}
            name='sortByPrice'
            className={'w-[38px]  bg-primary-500'}
            checkedColor={'#775E96'}
          />
        </div>

        <div className='flex w-[100%] gap-[12px] justify-between  mb-[20px] items-center'>
          <span className=" opacity-90 text-neutral-300 text-sm font-medium font-['Montserrat'] ">
            Periodically send tip menu to chat every
          </span>
          <DropDown
            onChange={handleSendTipTimeChange}
            defaultValue={[
              {
                label: tipSettings?.sendByMinutes,
                value: tipSettings?.sendByMinutes,
              },
            ]}
            enableHandle={true}
            options={[
              {
                label: 5,
                value: 5,
              },
              {
                label: 10,
                value: 10,
              },
              {
                label: 15,
                value: 15,
              },
            ]}
            className='w-[84px] h-[46px] '
            selectClass={
              'w-[84px] h-[46px] bg-black  !border-[1px] !border-primary-100  active:!border-[1px] active:!border-primary-100  hover:!border-[1px] hover:!border-primary-100'
            }
            dropElement={({ props, state, methods }) => {
              return (
                <div className={classNames('px-[0.5rem] ')}>
                  {state.values.length === 0 ? (
                    props.defaultValue.length > 0 ? (
                      <span className='text-white'>
                        {props.defaultValue[0]?.label}
                      </span>
                    ) : (
                      <span className='text-primary-300'>10</span>
                    )
                  ) : (
                    <span className='text-white'>{state.values[0]?.label}</span>
                  )}
                </div>
              );
            }}
          />
          <span className="block opacity-90 text-neutral-300 text-sm font-medium font-['Montserrat'] leading-tight">
            minutes
          </span>
        </div>

        <div className='flex w-[100%] gap-[12px]   mb-[20px] items-center'>
          <span className=" opacity-90 text-neutral-300 text-sm font-medium font-['Montserrat'] ">
            Tip menu display type
          </span>
          <DropDown
            onChange={handleDisplayChange}
            defaultValue={[
              {
                label: tipSettings?.display,
                value: tipSettings?.display,
              },
            ]}
            enableHandle={true}
            options={[
              // {
              //   label: 'List',
              //   value: 'List',
              // },
              {
                label: 'Grid',
                value: 'Grid',
              },
            ]}
            className='w-[84px] h-[46px] '
            selectClass={
              'w-[84px] h-[46px] bg-black  !border-[1px] !border-primary-100  active:!border-[1px] active:!border-primary-100  hover:!border-[1px] hover:!border-primary-100'
            }
            dropElement={({ props, state, methods }) => {
              return (
                <div className={classNames('px-[0.5rem] ')}>
                  {state.values.length === 0 ? (
                    props.defaultValue.length > 0 ? (
                      <span className='text-white'>
                        {props.defaultValue[0]?.label}
                      </span>
                    ) : (
                      <span className='text-primary-300'>
                        5 minutes (default)
                      </span>
                    )
                  ) : (
                    <span className='text-white'>{state.values[0]?.label}</span>
                  )}
                </div>
              );
            }}
          />
        </div>
      </section>
    </section>
  );
};

export default TipContainer;

const Tip = ({ tip, updateGoal, removeGoal }) => {
  const [data, setData] = useState(tip);

  const onInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setData(() => {
      return {
        ...data,
        [name]: value,
      };
    });

    // const payload = {
    //   ...data,
    //   [name]: value,
    // };
  };

  const onBlur = () => {
    updateGoal(tip?.id, data);
  };
  return (
    <section className='w-[100%] mb-[1rem]'>
      <div className='flex gap-[0.5rem] items-center  w-[100%]'>
        <div className=''>
          <h1 className="mb-[8px] opacity-90 text-neutral-300 text-sm font-medium font-['Montserrat'] leading-tight">
            Tip Description
          </h1>
          <Input
            onBlur={onBlur}
            name='description'
            onChange={onInputChange}
            defaultValue={tip?.description}
            className={
              "text-neutral-300 text-sm font-medium font-['Montserrat']  w-[280px] bg-black h-[46px] hover:border-[1px] hover:border-primary-100"
            }
            placeholder={'new tip'}
          />
        </div>

        <div className=''>
          <h1 className="mb-[8px] opacity-90 text-neutral-300 text-sm font-medium font-['Montserrat'] leading-tight">
            Tip Description
          </h1>
          <div className='flex gap-[10px]  items-center'>
            <Input
              onBlur={onBlur}
              name='credit'
              onChange={onInputChange}
              defaultValue={tip?.credit}
              type={'number'}
              className={
                "text-neutral-300 text-sm font-medium font-['Montserrat']  w-[280px] bg-black h-[46px] hover:border-[1px] hover:border-primary-100"
              }
              placeholder={'credit'}
            />
            <IoIosRemoveCircleOutline
              className='w-6 h-6  font-[1.6rem] text-[#b87693] cursor-pointer'
              onClick={() => removeGoal(tip?.id)}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
