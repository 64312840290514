import Popup from 'reactjs-popup';
import styles from './send-tip.module.scss';
import Button from '../../../../custom/btn/Btn';
import { AiOutlineClose } from 'react-icons/ai';
import { useState } from 'react';
import notification from '../../../../utils/notification';

const SendTipModal = ({
  handleSendTip,
  isOnPrivateCall,
  open,
  handleClose,
}) => {
  const [tip, setTip] = useState({
    tip: 'show ass',
    message: '',
    amount: 0,
  });

  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setTip((prev) => {
      return {
        ...prev,
        tip: 'show ass',
        [name]: value,
      };
    });
  };

  const sendTipToModel = () => {
    if (parseInt(tip.amount) > 0) {
      handleSendTip(tip);
      handleClose();
    } else {
      notification({
        title: 'Broadcasting',
        type: 'warning',
        message: 'The tip amount must be greater 0 credit',
      });
    }
  };

  return (
    <Popup open={open}>
      <main
        className={isOnPrivateCall ? styles.joinContainer : styles.container}
      >
        <h1 className={styles.title}>
          Send a tip
          <AiOutlineClose className={styles.icon} onClick={handleClose} />
        </h1>
        <p className={styles.subTitle}>
          Current Balance : <span className={styles.credits}>356 credits</span>
        </p>

        <div className={styles.inputContainer}>
          <label className={styles.label}>Enter tip amount</label>
          <input
            onChange={handleInputChange}
            name='amount'
            type='number'
            className={styles.input}
            placeholder='enter credit amount'
          />
        </div>

        <div className={styles.inputContainer}>
          <label className={styles.label}>Include optional message</label>
          <textarea
            className={styles.textarea}
            placeholder='enter message'
            onChange={handleInputChange}
            name='message'
          />
        </div>

        <Button
          onClick={sendTipToModel}
          text={'Send Tip'}
          className={styles.sendTipButton}
        />
      </main>
    </Popup>
  );
};

export default SendTipModal;
