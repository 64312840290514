import classNames from 'classnames';
import {
  IoIosAddCircleOutline,
  IoIosRemoveCircleOutline,
} from 'react-icons/io';
import styles from './createAppointment.module.scss';
import AddAvailability from '../addAvailability/AddAvailability';
import { useState } from 'react';
import Spinner from '../../../custom/spinner/Spinner';

const CreateAppointment = ({
  day,
  availability = [],
  handleOpenTimePicker,
  updateAvailability,
  deleteAvailability,
  isLoading,
  currentAppointmentId,
}) => {
  return (
    <main className={styles.container}>
      <h1 className={styles.title}>{day}</h1>
      <section>
        {availability.length === 0 ? (
          <span className={styles.noAvailability}>No work hours</span>
        ) : (
          <>
            {availability.map((data) => {
              return (
                <section className={styles.timeWrapper}>
                  <div className={styles.timeInnerWrapper}>
                    <TimeViewer
                      time={data.startTime}
                      active={true}
                      onClick={() => updateAvailability(data)}
                    />
                    <span className={styles.separator}></span>
                    <TimeViewer
                      time={data.endTime}
                      active={true}
                      onClick={() => updateAvailability(data)}
                    />
                  </div>
                  <span
                    className={styles.removeAvailabilityButton}
                    onClick={() => deleteAvailability(data.id)}
                  >
                    {isLoading && currentAppointmentId === data.id ? (
                      <Spinner background={false} isTip={false} />
                    ) : (
                      <IoIosRemoveCircleOutline className={styles.icon} />
                    )}
                    Remove
                  </span>
                </section>
              );
            })}
          </>
        )}
        <span
          className={styles.addAvailabilityButton}
          onClick={handleOpenTimePicker}
        >
          <IoIosAddCircleOutline className={styles.icon} /> Add Availability
        </span>
      </section>
    </main>
  );
};

const TimeViewer = ({ active, onClick, time }) => {
  return (
    <div className={styles.timeContainer} onClick={onClick}>
      <span className={styles.time}>{time.split(' ')[0]}</span>
      <div className={styles.timeTickerContainer}>
        <span
          className={classNames(
            styles.timeTicker,
            time.split(' ')[1]?.toLowerCase() === 'pm' &&
              styles.activeTimeTicker
          )}
        >
          PM
        </span>
        <span
          className={classNames(
            styles.timeTicker,
            time.split(' ')[1]?.toLowerCase() === 'am' &&
              styles.activeTimeTicker
          )}
        >
          AM
        </span>
      </div>
    </div>
  );
};

export default CreateAppointment;
