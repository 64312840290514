import classNames from 'classnames';
import ReactPaginate from 'react-paginate';
import styles from './pagination.module.scss';

function Pagination({ paginationItemClass, className, onChange, pageCount, pageSize }) {
	const handlePageChange = event => {
		if (onChange && typeof onChange === 'function') {
			// increment the page number as it is zeror indexed
			onChange(event.selected + 1);
		}
	};

	return (
		<>
			<ReactPaginate
				className={classNames(styles.pagination, className)}
				pageLinkClassName={classNames(paginationItemClass, styles.paginationItem)}
				activeLinkClassName={styles.activePage}
				nextClassName={styles.nextIcon}
				previousClassName={styles.previousIcon}
				nextLabel='>'
				onPageChange={handlePageChange}
				pageRangeDisplayed={pageSize}
				pageCount={pageCount}
				previousLabel={null}
				renderOnZeroPageCount={null}
			/>
		</>
	);
}

export default Pagination;
