import Popup from 'reactjs-popup';
import styles from './goal-modal.module.scss';
import Button from '../../../custom/btn/Btn';
import { useEffect, useState } from 'react';
import { IoIosRemoveCircleOutline } from 'react-icons/io';
import { v4 as uuidv4 } from 'uuid';
import { IoMdAdd } from 'react-icons/io';
import notification from '../../../utils/notification';
import { createBroadcastGoal } from '../../../request/model';
import { useSelector } from 'react-redux';
import {
  getStreamingGoals,
  createStreamingGoal,
  updateStreamingGoal,
  deleteStreamingGoal,
} from '../../../request/model';
import errorFormmatter from '../../../utils/errorFormatter';
import classNames from 'classnames';

const GoalModal = ({ open, handleClose, isOnline }) => {
  const user = useSelector((state) => state?.user);
  const [goals, setGoals] = useState([]);
  const [newGoals, setNewGoal] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  const addNewGoal = () => {
    if (goals.length < 5) {
      const id = uuidv4();
      const payload = {
        description: 'New goal',
        amount: 0,
        id,
      };
      setGoals((prev) => {
        return [...prev, payload];
      });
      setNewGoal((prev) => {
        return [...prev, payload];
      });
    } else {
      notification({
        title: 'Goal settings',
        message: `The maximal goal per streaming is 1 - 5`,
        type: 'warning',
      });
    }
  };

  const updateGoal = (id, updatePayload) => {
    setGoals((prev) => {
      const filterGoal = prev.map((goal) => {
        if (goal.id === id) {
          return {
            ...updatePayload,
          };
        }

        if (goal.goalSettingId === id) {
          try {
            const payload = new FormData();
            payload.append('goalSettingId', updatePayload?.goalSettingId);
            payload.append('amount', updatePayload?.amount);
            payload.append('description', updatePayload?.description);
            payload.append('registeredId', user.clientId);

            setIsSaving(true);
            updateStreamingGoal(payload)
              .catch(() => {
                setIsSaving(false);
              })
              .then(() => {
                setIsSaving(false);
              });

            return {
              ...updatePayload,
            };
          } catch (error) {
            notification({
              title: 'Goal settings',
              message: `The goal ${updatePayload?.description} failed to update`,
              type: 'danger',
            });
          }
        }

        return goal;
      });

      return [...filterGoal];
    });

    setNewGoal((prev) => {
      const filterGoal = prev.map((goal) => {
        if (goal.id === id) {
          return {
            ...updatePayload,
          };
        } else {
          return goal;
        }
      });

      return [...filterGoal];
    });
  };

  const removeGoal = async (id) => {
    const _filteredData = goals.filter((goal) => {
      return goal.id ? goal.id !== id : goal?.goalSettingId !== id;
    });

    const deleteData = goals.filter((goal) => {
      return goal.id ? goal.id === id : goal?.goalSettingId === id;
    });

    if (deleteData.length > 0) {
      const data = deleteData[0];
      try {
        if (data.goalSettingId) {
          setIsSaving(true);
          await deleteStreamingGoal(user.clientId, id);
          notification({
            title: 'Goal settings',
            message: `Successfully deleted ${data?.description} goal`,
            type: 'success',
          });
          setIsSaving(false);
        }

        setGoals((prev) => {
          return [..._filteredData];
        });
      } catch (error) {
        setIsSaving(false);
        notification({
          title: 'Goal settings',
          message: `The ${data?.description} failed to delete`,
          type: 'danger',
        });
      }
    }
  };

  const autoCreateOneGoal = async () => {
    try {
      if (goals.length > 0) {
        const payload = new FormData();
        payload.append('amount', 0);
        payload.append('description', 'Twerk');
        payload.append('registeredId', user.clientId);

        await createBroadcastGoal({
          payload,
        });
      }
    } catch (error) {}
  };

  const getAllStreamingGoals = async () => {
    try {
      const response = await getStreamingGoals(user.clientId);
      setGoals((prev) => {
        return [...response];
      });
    } catch (error) {
      setGoals((prev) => {
        return [...prev];
      });
    }
  };

  const saveGoals = async () => {
    let data = null;

    try {
      if (newGoals.length > 0) {
        for (let i = 0; i < newGoals.length; i++) {
          setIsSaving(true);
          data = newGoals[i];
          const payload = new FormData();
          payload.append('registeredId', user.clientId);
          payload.append('amount', data?.amount);
          payload.append('description', data?.description);
          payload.append('setGoal', 'progress');
          await createStreamingGoal(payload);

          setNewGoal(() => {
            const filteredData = newGoals.filter((data) => {
              return data.id !== data.id;
            });
            return [...filteredData];
          });
          notification({
            title: 'Goal settings',
            message: `Successfully added a new gaol`,
            type: 'success',
          });
          setIsSaving(false);
        }
      }
    } catch (error) {
      setIsSaving(false);
      notification({
        title: 'Goal settings',
        message: errorFormmatter(error),
        type: 'danger',
      });
    }
  };

  useEffect(() => {
    // autoCreateOneGoal();
    getAllStreamingGoals();
  }, []);

  useEffect(() => {
    getAllStreamingGoals();
  }, [isSaving, isOnline]);

  return (
    <Popup open={open} closeOnDocumentClick onClose={handleClose}>
      <main className={styles.container}>
        <h1 className={styles.title}>Goal Settings</h1>
        <h2 className={styles.subTitle}>
          You can add more goals by using the “Add a Goal” button below. To
          remove a goal simply erase the default content and leave it blank.
        </h2>
        <section
          className={classNames(styles.goalWrapperContainer, 'w-[100%]')}
        >
          {goals?.map((goal, index) => {
            return (
              <Goal
                key={goal.id ?? goal.goalSettingId ?? index}
                goal={goal}
                updateGoal={updateGoal}
                removeGoal={removeGoal}
              />
            );
          })}
        </section>
        <button onClick={addNewGoal} className={styles.addButton}>
          <IoMdAdd />
          Add a Goal
        </button>
        <div className={styles.actionButtonContainer}>
          <Button
            text={'Cancel'}
            className={styles.cancelButton}
            onClick={handleClose}
          />
          <Button
            onClick={saveGoals}
            text={'Save'}
            className={styles.saveButton}
          />
        </div>
      </main>
    </Popup>
  );
};

const Goal = ({ goal, updateGoal, removeGoal }) => {
  const [data, setData] = useState(goal);

  const onInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setData(() => {
      return {
        ...data,
        [name]: value,
      };
    });

    // const payload = {
    //   ...data,
    //   [name]: value,
    // };
  };

  const onBlur = () => {
    updateGoal(data?.goalSettingId ?? data?.id, data);
  };

  return (
    <section className='w-[100%] mb-[24px]'>
      <div className='flex gap-[0.5rem] items-center  w-[100%]'>
        <div className=' w-[50%]'>
          <h1 className="mb-[8px] opacity-90 text-neutral-300 text-sm font-medium font-['Montserrat'] leading-tight">
            Goal Description
          </h1>
          <input
            onBlur={onBlur}
            name='description'
            onChange={onInputChange}
            className={styles.goalInput}
            defaultValue={data?.description}
            type='text'
          />
        </div>

        <div className=' w-[50%]'>
          <h1 className="mb-[8px] opacity-90 text-neutral-300 text-sm font-medium font-['Montserrat'] leading-tight">
            Goal Credits
          </h1>
          <div className='flex gap-[10px]  items-center'>
            <input
              onBlur={onBlur}
              name='amount'
              onChange={onInputChange}
              className={styles.goalInput}
              defaultValue={data?.amount}
              type='number'
            />
            <IoIosRemoveCircleOutline
              onClick={async () =>
                await removeGoal(data?.goalSettingId ?? data?.id)
              }
              className={styles.removeIcon}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default GoalModal;
