import styles from './fanClub.module.scss';
import ModelProfileHeader from '../../custom/modelProfileHeader/ModelProfileHeader';
import FluidContainer from '../../custom/fluidContainer/FluidContainer';
import Btn from '../../custom/btn/Btn';
import FileUpload from '../../custom/fileUpload/FileUpload';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Spinner from '../../custom/spinner/Spinner';
import { useSelector } from 'react-redux';
import notification from '../../utils/notification';
import { createClub, getClub, updateFanClub } from '../../request/model';
import { Link, useRouteMatch } from 'react-router-dom';
import errorFormmatter from '../../utils/errorFormatter';

const ActiveArrow = (props) => {
  return (
    <svg
      {...props}
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='25'
      viewBox='0 0 25 29'
      fill='none'
    >
      <path
        d='M24 12.7679C25.3333 13.5378 25.3333 15.4622 24 16.232L3.75 27.9234C2.41666 28.6932 0.749999 27.7309 0.749999 26.1913L0.75 2.80865C0.75 1.26905 2.41667 0.306805 3.75 1.07661L24 12.7679Z'
        fill='#010002'
      />
    </svg>
  );
};

const ClubSchema = Yup.object().shape({
  title: Yup.string().trim().required('title is required'),
  description: Yup.string().trim().required('description is required'),
  price: Yup.number().required('price is required'),
  clubImage: Yup.string().required('club image is required'),
});

const initialValue = {
  title: '',
  description: '',
  price: '',
  clubImage: '',
};

const FanClub = () => {
  const activeTab = useRouteMatch();
  const [userFanClub, setUserFanClub] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);

  const user = useSelector((state) => state?.user);

  const onTabChange = (index) => {
    setTab(index);
  };

  const handleCreateClub = async (values) => {
    try {
      const payload = new FormData();
      payload.append('fanClubTitle', values.title);
      payload.append('details', values?.description);
      payload.append('headerImage', values?.clubImage);
      payload.append('registeredId', user?.clientId);
      payload.append('price', values?.price);
      setLoading(true);
      const response = await createClub({ payload });
      notification({ message: response?.message ?? '' });
      setLoading(false);
    } catch (error) {
      notification({
        message: errorFormmatter(error) ?? 'Fan club failed to create',
        type: 'danger',
      });
      setLoading(false);
    }
  };

  const getUserFanClub = async () => {
    try {
      const response = await getClub({ modelId: user?.clientId });

      setUserFanClub(response.data[0] ?? null);
    } catch (error) {}
  };

  const handleUpdateClub = async (values) => {
    try {
      const payload = new FormData();
      payload.append('fanClubTitle', values.title);
      payload.append('details', values?.description);
      payload.append('headerImage', values?.clubImage);
      payload.append('registeredId', user?.clientId);
      payload.append('price', values?.price);
      payload.append('id', userFanClub?.id);
      setLoading(true);
      const response = await updateFanClub({ payload });
      notification({
        message: response?.message ?? 'Fan club failed to update',
      });
      setLoading(false);
    } catch (error) {
      notification({
        message: errorFormmatter(error) ?? 'Fan club failed to update',
        type: 'danger',
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserFanClub();
  }, []);

  useEffect(() => {
    getUserFanClub();
  }, [loading]);

  return (
    <FluidContainer>
      <ModelProfileHeader />
      <main className={styles.container}>
        <section>
          <h1 className={styles.title}>Fanclub Setup</h1>
          <p className={styles.subTitle}>
            <span>
              A fan club is a personalized webpage that features photo and video
              content uploaded by a model that a user can subscribe to. There
              are five sections to each fan club: Photo Sets, Videos, Shows,
              Diaries and Store. Not all sections are mandatory. Models can
              update it as they’d like and price it how they see fit.
            </span>
            <br />
            <span>
              You can setup your fan club from here and activate it by setting a
              price. Then you can upload content to the sections of the fan
              club.
            </span>
            <br />
            <span>
              You can deactivate your fan club by selecting the fan club
              disabled option in the fan club price box below. If you turn it
              off while there are active fan club subscriiptions, be prepared to
              accept refunds.
            </span>
          </p>
        </section>

        <section className={styles.fanSetupContainer}>
          <Formik
            enableReinitialize={true}
            validationSchema={ClubSchema}
            initialValues={
              userFanClub !== null
                ? {
                    title: userFanClub?.fanClubTitle,
                    description: userFanClub?.details,
                    price: userFanClub?.price,
                    clubImage: userFanClub?.headerImageUrl,
                  }
                : initialValue
            }
            onSubmit={
              userFanClub === null ? handleCreateClub : handleUpdateClub
            }
          >
            {(props) => {
              return (
                <>
                  <section>
                    <h1 className={styles.setupTitle}>Fanclub Setup</h1>
                    <h2 className={styles.setupSubTitle}>
                      Fill in the information to setup your fan club
                    </h2>
                  </section>
                  <section>
                    <form>
                      <div className={styles.flexContainer}>
                        <div className={styles.flexInnerContainer}>
                          <input
                            defaultValue={userFanClub?.fanClubTitle}
                            onChange={props.handleChange}
                            name='title'
                            type={'text'}
                            className={styles.input}
                            placeholder='Fan Club  Title'
                          />
                          {props.touched && props.errors?.title && (
                            <span className={styles.error}>
                              * {props.errors?.title}
                            </span>
                          )}
                        </div>

                        <div className={styles.flexInnerContainer}>
                          <input
                            defaultValue={userFanClub?.price}
                            onChange={props.handleChange}
                            name='price'
                            type={'number'}
                            className={styles.input}
                            placeholder='$0'
                          />
                          {props.touched && props.errors?.price && (
                            <span className={styles.error}>
                              * {props.errors?.price}
                            </span>
                          )}
                        </div>
                      </div>
                      <textarea
                        defaultValue={userFanClub?.details}
                        onChange={props.handleChange}
                        name='description'
                        rows='10'
                        className={styles.textArea}
                        placeholder='Tell users why they should sign up for your fanclub. Sell yourself'
                      />
                      {props.touched && props.errors?.description && (
                        <span className={styles.error}>
                          * {props.errors?.description}
                        </span>
                      )}
                    </form>
                  </section>
                  <nav className={styles.navigationContainer}>
                    <li
                      className={classNames(
                        styles.listItem,
                        activeTab.path === '/fan-club' && styles.active
                      )}
                      onClick={() => onTabChange(0)}
                    >
                      {tab === 0 && (
                        <ActiveArrow className={styles.navigationArrow} />
                      )}
                      Fanclub Setup
                    </li>
                    <Link to='/fan-club-photo-sets'>
                      <li
                        className={classNames(
                          styles.listItem,
                          activeTab.path === '/fan-club-photo-sets' &&
                            styles.active
                        )}
                        onClick={() => onTabChange(1)}
                      >
                        {tab === 1 && (
                          <ActiveArrow className={styles.navigationArrow} />
                        )}
                        Photo Sets
                      </li>
                    </Link>
                    <Link to='/fan-club-videos'>
                      <li
                        className={classNames(
                          styles.listItem,
                          tab === 2 && styles.active
                        )}
                        onClick={() => onTabChange(2)}
                      >
                        {tab === 2 && (
                          <ActiveArrow className={styles.navigationArrow} />
                        )}
                        Videos
                      </li>
                    </Link>

                    <Link to='/fan-club-shows'>
                      <li
                        className={classNames(
                          styles.listItem,
                          tab === 3 && styles.active
                        )}
                        onClick={() => onTabChange(3)}
                      >
                        {tab === 3 && (
                          <ActiveArrow className={styles.navigationArrow} />
                        )}
                        Shows
                      </li>
                    </Link>
                    <Link to='/fan-club-dairies'>
                      <li
                        onClick={() => onTabChange(4)}
                        className={classNames(
                          styles.listItem,
                          tab === 4 && styles.active
                        )}
                      >
                        {tab === 4 && (
                          <ActiveArrow className={styles.navigationArrow} />
                        )}
                        Diaries
                      </li>
                    </Link>
                    {/* <Link to='/fan-club-store'>
                      {' '}
                      <li
                        onClick={() => onTabChange(5)}
                        className={classNames(
                          styles.listItem,
                          activeTab.path === '/fan-club-store' && styles.active
                        )}
                      >
                        {tab === 5 && (
                          <ActiveArrow className={styles.navigationArrow} />
                        )}
                        Store
                      </li>
                    </Link> */}
                  </nav>
                  <div>
                    <h1 className={styles.imageSetupTitle}>Header Image</h1>
                    <h2 className={styles.imageSetupSubTitle}>
                      Set a background header image for your fan club
                    </h2>
                  </div>
                  <div>
                    {props.touched && props.errors?.clubImage && (
                      <span className={styles.error}>
                        * {props.errors?.clubImage}
                      </span>
                    )}
                    <div className={styles.imageUploadContainer}>
                      <FileUpload
                        handleFileChange={(file) => {
                          props.setFieldValue('clubImage', file);
                        }}
                        renderElement={
                          <span className={styles.addPhotoButton}>
                            Add Photo
                          </span>
                        }
                      />

                      <>
                        {(userFanClub != null || props.values.clubImage) && (
                          <>
                            {props.values.clubImage === '' ? (
                              <>
                                {userFanClub?.headerImageUrl && (
                                  <img
                                    className={styles.previewImage}
                                    src={`${userFanClub?.headerImageUrl}`}
                                  />
                                )}
                              </>
                            ) : (
                              <>
                                {props.values.clubImage && (
                                  <img
                                    className={styles.previewImage}
                                    src={
                                      typeof props.values.clubImage === 'object'
                                        ? URL.createObjectURL(
                                            props.values.clubImage
                                          )
                                        : props.values.clubImage
                                    }
                                  />
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    </div>

                    <div className={styles.buttonActionContainer}>
                      <Btn
                        text={
                          userFanClub === null ? 'Create Fan Club' : 'Update'
                        }
                        className={styles.saveSetupButton}
                        type='submit'
                        onClick={props.handleSubmit}
                      />
                      {loading && <Spinner background={false} isTip={false} />}
                    </div>
                  </div>
                </>
              );
            }}
          </Formik>
        </section>
      </main>
    </FluidContainer>
  );
};

export default FanClub;
