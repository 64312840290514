import CheckBox from '../../../custom/checkbox/CheckBox';
import DropDown from '../../../custom/DropDown/DropDown';
import notification from '../../../utils/notification';
import classNames from 'classnames';
import Input from '../../../custom/input/Input';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getStreamingConfig,
  updateStreamingBroadcastSetting,
  updateStreamingBroadcastConfig,
} from '../../../request/model';

const GeneralSetting = () => {
  const [fontSizes, setFontSizes] = useState([
    {
      label: 'Small',
      fontSize: 'sm',
    },
    {
      label: 'Normal',
      fontSize: 'md',
    },
    {
      label: 'Large',
      fontSize: 'lg',
    },
    {
      label: 'Very Large',
      fontSize: 'xlg',
    },
  ]);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state?.user);
  const [generalSetting, setGeneralSetting] = useState({
    tipReceivedSound: false,
    messageReceivedSound: false,
    userEnteredSound: false,
    textSizeData: '',
    guestTimeKickOut: '5',
    timeLimit: false,
    allowShowRecording: false,
    tipVolume: 0,
    exitVolume: 0,
    entryVolume: 0,
    allowPrivateChat: true,
    allowGeneralChat: true,
    allowMessage: true,
    allowGuest: true,
    allowMicOff: true,
    allowCamOn: true,
    allowTips: true,
  });
  const [selectedFontSize, setSelectedFontSize] = useState(
    generalSetting?.textSizeData
  );
  const [defaultValue, setDefaultValue] = useState('');

  const handleGetSetting = async () => {
    try {
      const response = await getStreamingConfig({ modelId: user.clientId });

      setGeneralSetting((prev) => {
        return {
          ...prev,
          ...response,
          tipReceivedSound: response?.tipReceivedSound,
          messageReceivedSound: response?.messageReceivedSound,
          userEnteredSound: response?.userEnteredSound,
          textSizeData: response?.textSize,
          guestTimeKickOut: response?.guestTimeKickOut,
          allowShowRecording: response?.allowShowRecording,
          timeLimit: response?.timeLimit,
          tipVolume: response?.tipVolume,
          exitVolume: response?.exitVolume,
          entryVolume: response?.entryVolume,
        };
      });
    } catch (error) {}
  };

  const updateStreamingVolumeSetting = async (name, value) => {
    try {
      setLoading(true);
      const payload = new FormData();

      payload.append('tipVolume', generalSetting.tipVolume);
      payload.append('exitVolume', generalSetting.tipVolume);
      payload.append('entryVolume', generalSetting.tipVolume);

      payload.append(
        'allowPrivateChat',
        name === 'allowPrivateChat' ? value : generalSetting?.allowPrivateChat
      );
      payload.append(
        'allowGeneralChat',
        name === 'allowGeneralChat' ? value : generalSetting?.allowGeneralChat
      );

      payload.append(
        'allowMessage',
        name === 'allowMessage' ? value : generalSetting?.allowMessage
      );
      payload.append(
        'allowGuest',
        name === 'allowGuest' ? value : generalSetting?.allowGuest
      );
      payload.append(
        'allowTips',
        name === 'allowTips' ? value : generalSetting?.allowTips
      );
      payload.append('allowMicOff', generalSetting?.allowMicOff);
      payload.append('allowCamOn', generalSetting?.allowCamOn);
      payload.append('registerId', user?.clientId);

      await updateStreamingBroadcastConfig({
        payload,
      });

      setLoading(false);
      notification({
        title: 'General settings',
        message: `Successfully updated general setting`,
        type: 'success',
      });
    } catch (error) {
      setLoading(false);
      notification({
        title: 'General settings',
        message: `General setting failed to update`,
        type: 'danger',
      });
    }
  };

  const onStreamChange = async (event) => {
    try {
      const name = event.target.name;
      const value = event.target.checked;
      console.log(event.target.checked);

      setGeneralSetting((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });

      await updateStreamingVolumeSetting(name, value);
    } catch (error) {}
  };

  const updateSoundSetting = async (name, value) => {
    try {
      setLoading(true);
      const payload = new FormData();

      payload.append('tipReceivedSound', generalSetting?.tipReceivedSound);
      payload.append(
        'messageReceivedSound',
        generalSetting?.messageReceivedSound
      );
      payload.append('userEnteredSound', generalSetting?.userEnteredSound);
      payload.append(
        'textSizeData',
        name === 'textSizeData' ? value : generalSetting?.textSizeData
      );
      payload.append(
        'guestTimeKickOut',
        name === 'guestTimeKickOut' ? value : generalSetting?.guestTimeKickOut
      );
      payload.append('allowShowRecording', generalSetting?.allowShowRecording);
      payload.append(
        'timeLimit',
        name === 'timeLimit' ? value : generalSetting?.timeLimit
      );
      payload.append('registerId', user?.clientId);

      await updateStreamingBroadcastSetting({
        payload,
      });

      setLoading(false);
      notification({
        title: 'General settings',
        message: `Successfully updated general setting`,
        type: 'success',
      });
    } catch (error) {
      setLoading(false);
      notification({
        title: 'General settings',
        message: `General setting general to update`,
        type: 'danger',
      });
    }
  };

  const onBroadcastChange = async (event) => {
    try {
      const name = event.target.name;
      const value = event.target.checked;

      setGeneralSetting((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });

      await updateSoundSetting(name, value);
    } catch (error) {}
  };

  const handleFontSizeChange = (event) => {
    setSelectedFontSize(() => {
      return {
        ...event.value,
      };
    });

    updateSoundSetting('textSizeData', event.value?.fontSize);
  };

  const handleKickOutChange = (event) => {
    setGeneralSetting((prev) => {
      return {
        ...prev,
        guestTimeKickOut: event?.value,
      };
    });
    updateSoundSetting('guestTimeKickOut', event?.value);
  };

  useEffect(() => {
    handleGetSetting();
  }, []);

  useEffect(() => {
    handleGetSetting();
  }, [loading]);

  const getDefaultValue = () => {
    const defaultFilter = fontSizes.find((font) => {
      return font.fontSize === generalSetting.textSizeData;
    });
    setSelectedFontSize(defaultFilter);
  };

  useEffect(() => {
    getDefaultValue();
  }, [generalSetting.textSizeData]);

  return (
    <section key={0} className=' max-w-[440px]'>
      <div className='flex w-[100%] justify-between mb-[10px] items-center'>
        <span className="block w-[max-content] opacity-90 text-neutral-300 text-sm font-medium font-['Montserrat'] leading-tight">
          Time limit for general show preview
        </span>
        <CheckBox
          onChange={onBroadcastChange}
          name={'timeLimit'}
          checked={generalSetting?.timeLimit}
          className={'w-[38px]  bg-primary-500'}
          checkedColor={'#775E96'}
        />
      </div>
      <DropDown
        onChange={handleKickOutChange}
        enableHandle={true}
        defaultValue={[
          {
            label: generalSetting?.guestTimeKickOut,
            value: generalSetting?.guestTimeKickOut,
          },
        ]}
        options={[
          {
            label: '5',
            value: '5',
          },
          {
            label: '10',
            value: '10',
          },
          {
            label: '15',
            value: '15',
          },
        ]}
        className='w-[440px] h-12 mb-[40px]'
        selectClass={'w-[100%] h-[46px]'}
        dropElement={({ props, state, methods }) => {
          return (
            <div className={classNames('px-[1rem] ')}>
              {state.values.length === 0 ? (
                props.defaultValue.length > 0 ? (
                  <span className='text-white'>
                    {props.defaultValue[0]?.label}
                  </span>
                ) : (
                  <span className='text-primary-300'>5 minutes (default)</span>
                )
              ) : (
                <span className='text-white'>{state.values[0]?.label}</span>
              )}
            </div>
          );
        }}
      />

      <h1 className="mb-[8px] opacity-90 text-neutral-300 text-sm font-medium font-['Montserrat'] leading-tight">
        Chat font size
      </h1>
      <DropDown
        onChange={handleFontSizeChange}
        enableHandle={true}
        defaultValue={[defaultValue]}
        options={fontSizes.map((fontSize) => {
          return {
            label: fontSize.label,
            value: fontSize,
          };
        })}
        className='w-[440px] h-12 mb-[20px]'
        selectClass={'w-[100%] h-[46px]'}
        dropElement={({ props, state, methods }) => {
          return (
            <div className={classNames('px-[1rem] ')}>
              {selectedFontSize?.label}
            </div>
          );
        }}
      />

      <div className='flex w-[100%] justify-between mb-[20px] items-center'>
        <span className="block w-[max-content] opacity-90 text-neutral-300 text-sm font-medium font-['Montserrat'] leading-tight">
          Allow show recording
        </span>
        <CheckBox
          // onChange={onStreamChange}
          // name={'allowPrivateChat'}
          // checked={generalSetting?.allowPrivateChat}
          className={'w-[38px]  bg-primary-500'}
          checkedColor={'#775E96'}
        />
      </div>

      <div className='flex w-[100%] justify-between mb-[20px] items-center'>
        <span className="block w-[max-content] opacity-90 text-neutral-300 text-sm font-medium font-['Montserrat'] leading-tight">
          Allow private chat
        </span>
        <CheckBox
          onChange={onStreamChange}
          name={'allowPrivateChat'}
          checked={generalSetting?.allowPrivateChat}
          className={'w-[38px]  bg-primary-500'}
          checkedColor={'#775E96'}
        />
      </div>

      <div className='flex w-[100%] justify-between mb-[20px] items-center'>
        <span className="block w-[max-content] opacity-90 text-neutral-300 text-sm font-medium font-['Montserrat'] leading-tight">
          Allow general chat
        </span>
        <CheckBox
          onChange={onStreamChange}
          name={'allowGeneralChat'}
          checked={generalSetting?.allowGeneralChat}
          className={'w-[38px]  bg-primary-500'}
          checkedColor={'#775E96'}
        />
      </div>

      <div className='flex w-[100%] justify-between mb-[20px] items-center'>
        <span className="block w-[max-content] opacity-90 text-neutral-300 text-sm font-medium font-['Montserrat'] leading-tight">
          Allow message
        </span>
        <CheckBox
          onChange={onStreamChange}
          name={'allowMessage'}
          checked={generalSetting?.allowMessage}
          className={'w-[38px]  bg-primary-500'}
          checkedColor={'#775E96'}
        />
      </div>

      <div className='flex w-[100%] justify-between mb-[20px] items-center'>
        <span className="block w-[max-content] opacity-90 text-neutral-300 text-sm font-medium font-['Montserrat'] leading-tight">
          Allow guest
        </span>
        <CheckBox
          onChange={onStreamChange}
          name={'allowGuest'}
          checked={generalSetting?.allowGuest}
          className={'w-[38px]  bg-primary-500'}
          checkedColor={'#775E96'}
        />
      </div>

      <div className='flex w-[100%] justify-between mb-[40px] items-center'>
        <span className="block w-[max-content] opacity-90 text-neutral-300 text-sm font-medium font-['Montserrat'] leading-tight">
          Allow tips
        </span>
        <CheckBox
          onChange={onStreamChange}
          name={'allowTips'}
          checked={generalSetting?.allowTips}
          className={'w-[38px]  bg-primary-500'}
          checkedColor={'#775E96'}
        />
      </div>
    </section>
  );
};

export default GeneralSetting;
