import { createSlice } from '@reduxjs/toolkit';

const loginModalSlice = createSlice({
	name: 'loginModal',
	initialState: {
		isModalOpen: false,
	},
	reducers: {
		openModal: state => {
			state.isModalOpen = true;
		},

		closeModal: state => {
			state.isModalOpen = false;
		},
	},
});

export const { openModal, closeModal } = loginModalSlice.actions;

export default loginModalSlice.reducer;
