import axios from 'axios';
/**general club */

export const getClubDetails = async ({ clubId }) => {
  return (await axios.get(`ClubProfile/${clubId}GetClubDetailByClubId`))?.data
    ?.data;
};

export const getClubPhotos = async ({ clubId, page, offset }) => {
  return (
    await axios.get(
      `ClubProfile/${clubId}GetClubPhotos?PageNumber=${page}&PageSize=${offset}`
    )
  )?.data;
};

export const getClubFeeds = async ({ clubId }) => {
  return (await axios.get(`ClubProfile/${clubId}GetClubFullFeed`))?.data;
};

export const getClubFeed = async ({ clubId, postId }) => {
  return (await axios.get(`ClubProfile/${postId}/${clubId}SingleClubFeed`))
    ?.data?.data;
};

export const postClubFeed = async ({ payload }) => {
  return (await axios.post(`ClubProfile/PostToClubGFullFeed`, payload))?.data
    ?.data;
};

export const postClubPhotos = async ({ payload }) => {
  return (await axios.post(`ClubProfile/AddClubPhotos`, payload))?.data?.data;
};

export const saveClubAvailability = async ({ payload }) => {
  return (
    await axios.post(`ClubProfile/AddOrEditClubAvailabilityTimes`, payload)
  )?.data?.data;
};

export const updateClubDetails = async ({ payload, clubId }) => {
  return (await axios.put(`ClubProfile/${clubId}/UpdateClubDetails`, payload))
    ?.data?.data;
};

export const updateClubLogo = async ({ payload }) => {
  return (await axios.put(`ClubProfile/EditClubLogo`, payload))?.data?.data;
};

export const updateClubCover = async ({ payload }) => {
  return (await axios.put(`ClubProfile/EditClubCoverImages`, payload))?.data
    ?.data;
};

export const getClubAvailabilities = async ({ clubId }) => {
  return (await axios.get(`ClubProfile/${clubId}GetClubAvailabilityTimes`))
    ?.data?.data;
};

export const deleteClubPhoto = async ({ clubId, photoId }) => {
  return (
    await axios.delete(
      `ClubProfile/${clubId}/DeleteClubPhotoByClub?PhotoId=${photoId}`
    )
  )?.data?.data;
};

export const getClubByType = async ({ clubType }) => {
  return (await axios.get(`ClubProfile/${clubType}/GetClubType`))?.data;
};

export const searchClub = async ({ searchText, page, offset }) => {
  return (
    await axios.get(
      `ClubProfile/${searchText}GetClubSearchText?PageNumber=${page}&PageSize=${offset}`
    )
  )?.data;
};

export const addComment = async ({ payload }) => {
  return (await axios.post(`ClubProfile/AddComments`, payload))?.data?.data;
};

export const likeAndGetPostLike = async ({
  payload,
  memberId,
  clubId,
  postId,
  userLike,
}) => {
  return (
    await axios.get(
      `LikeEndPoint/${clubId}/${memberId}/${userLike}/${postId}/ClubLikes`,
      payload
    )
  )?.data;
};

export const followClub = async ({ payload }) => {
  return (await axios.post(`ClubProfile/FollowClub`, payload))?.data?.data;
};

export const getMemberFollowing = async ({ memberId, clubId }) => {
  return (
    await axios.get(`ClubProfile/${clubId}/${memberId}GetMemberClubFollower`)
  )?.data?.data;
};

export const getFollower = async ({ clubId }) => {
  return (await axios.get(`ClubProfile/${clubId}GetClubFollower`))?.data?.data;
};


export const getClubLikes = async ({ memberId, postId, clubId }) => {
  return (
    await axios.get(`ClubProfile/${clubId}/${memberId}/${postId}/GetClubLikes`)
  )?.data?.data;
};


/**premium clubs */
export const getPremiumClubs = async () => {
  return (await axios.get(`/ClubProfile/GetAllClubOnPremiumService`))?.data;
};




