import { useEffect } from 'react';
import Scrollbar from 'react-scrollbars-custom';
import { Link } from 'react-router-dom';
import features from '../../../features.json';
import categories from '../../../category.json';

const CategoriesMenu = ({ feature, selectFeature, selectCategory, applyCategoryFilter }) => {
	useEffect(() => {
		document.querySelector('.categories-menu--more__js').onclick = function() {
			let hiddenMenu = document.querySelector('.categories-menu--detail');
			hiddenMenu.classList.toggle('show');
			this.classList.toggle('show');
		};
	}, []);

	return (
		<div className='categories-menu'>
			<div className='categories-menu--title'>Features</div>
			<ul className='categories-menu--mode'>
				{features?.map(({ name, value }) => (
					<li key={value} className={feature === value && 'categories-menu--active'}>
						<Link to='/' onClick={() => selectFeature(value)}>
							{name}
						</Link>
					</li>
				))}
			</ul>
			<div className='categories-menu--more'>
				<div className='categories-menu--more-link categories-menu--more__js'>
					Categories <span>{categories?.length}</span>
				</div>
			</div>
			<form className='categories-menu--detail'>
				<Scrollbar style={{ height: 430 }}>
					<ul>
						{categories?.map(feature => (
							<li key={feature}>
								<label className='check-all__js'>
									<input type='checkbox' onChange={() => selectCategory(feature)} />
									<span></span>
									<b>{feature}</b>
								</label>
							</li>
						))}
					</ul>
				</Scrollbar>
				<button className='btn btn__red' onClick={applyCategoryFilter}>
					Apply
				</button>
			</form>
		</div>
	);
};

export default CategoriesMenu;
