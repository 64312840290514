import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import FluidContainer from '../../custom/fluidContainer/FluidContainer';
import styles from './styles.module.scss';
import ModelProfileHeader from '../../custom/modelProfileHeader/ModelProfileHeader';
import { withRole } from '../../hoc/withRole';
import SelectAll from '../../custom/selectAll/SelectAll';
import Button from '../../custom/btn/Btn';
import { ReactComponent as CreateAlbumIcon } from '../../images/svg/albumIcon.svg';
import { ReactComponent as PermissionIcon } from '../../images/svg/permission.svg';
import Spinner from '../../custom/spinner/Spinner';
import { ReactComponent as DeleteIcon } from '../../images/svg/delete.svg';
import { getDiaries, deleteDiary } from '../../request/model';
import notification from '../../utils/notification';
import errorFormmatter from '../../utils/errorFormatter';
import CreateDiaryPopup from './createDiaryPopup/CreateDiaryPopup';
import Dairy from './dairy/Dairy';

const ModelFanClubDairy = () => {
  const clientId = useSelector((state) => state?.user?.clientId);
  const [diaries, setDiaries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDiaryIds, setSelectedDiaryIds] = useState([]);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [openModal, setModal] = useState(false);

  const handleOpenModal = () => {
    setModal(true);
  };

  const handleSelectAll = (e) => {
    const { checked } = e.target;
    if (!diaries) return;

    if (checked) {
      const selectedIds = diaries?.map((diary) => diary?.diaryId);
      setSelectedDiaryIds(selectedIds);
    } else {
      setSelectedDiaryIds([]);
    }
    setIsSelectedAll(checked);
  };

  const handleDiarySelection = (diaryId) => {
    const id = selectedDiaryIds.find((id) => id === diaryId);
    if (id) {
      const filteredPhotoIds = selectedDiaryIds.filter((id) => id !== diaryId);
      setSelectedDiaryIds(filteredPhotoIds);
    } else {
      setSelectedDiaryIds((prev) => [...prev, diaryId]);
    }
  };

  const handleIsSelected = (diaryId) => {
    const id = selectedDiaryIds.find((id) => id === diaryId);
    return id ? true : false;
  };

  const handleDeleteDiary = async (id) => {
    try {
      setIsLoading(true);

      const response = await deleteDiary({ modelId: clientId, diaryId: id });
      setIsLoading(false);

      notification({
        message: response?.message ?? 'Fan club dairy deleted successfully',
      });
    } catch (error) {
      notification({
        message: errorFormmatter(error) ?? 'Fan club dairy failed to delete',
        type: 'danger',
      });
      setIsLoading(false);
    }
  };

  const getModelDiaries = async () => {
    try {
      const response = await getDiaries({ modelId: clientId });
      setDiaries(response.data);
    } catch (error) {
      setDiaries([]);
    }
  };

  useEffect(() => {
    getModelDiaries();
  }, []);

  useEffect(() => {
    getModelDiaries();
  }, [openModal, isLoading]);

  return (
    <FluidContainer>
      <ModelProfileHeader className={styles.subHeader} />
      <div className={styles.header}>
        <h1 className={styles.subTitle}>FAN CLUB</h1>
        <h1 className={styles.title}>
          Diaries <span className={styles.tip}>{diaries?.length}</span>
        </h1>
      </div>
      <section className={styles.navContainer}>
        <SelectAll
          labelText='Select All Diary Entries'
          checked={isSelectedAll}
          onChange={handleSelectAll}
        />

        <nav className={styles.nav}>
          {isLoading && <Spinner />}
          <Button
            text='Create Diary Entry'
            icon={CreateAlbumIcon}
            onClick={() => setModal(true)}
          />
          {/* <Button text='Permissions' icon={PermissionIcon} /> */}
          <Button
            text='Delete'
            icon={DeleteIcon}
            onClick={() => {
              selectedDiaryIds.map((diaryId) => {
                handleDeleteDiary(diaryId);
              });
            }}
          />
        </nav>
      </section>
      <section className={styles.photoSetContainer}>
        {diaries.length > 0 ? (
          diaries.map((data, index) => {
            return (
              <Dairy
                handleDiarySelection={handleDiarySelection}
                handleIsSelected={handleIsSelected}
                handleDeleteDiary={handleDeleteDiary}
                data={data}
                key={index}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
              />
            );
          })
        ) : (
          <div className={styles.emptyModelDiaries}>No available dairies</div>
        )}
      </section>

      <CreateDiaryPopup
        open={openModal}
        handleClose={() => setModal(false)}
        handleOpenUploadModal={handleOpenModal}
      />
    </FluidContainer>
  );
};

export default withRole(ModelFanClubDairy);
