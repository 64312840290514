import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import FluidContainer from '../../custom/fluidContainer/FluidContainer';
import styles from './styles.module.scss';
import Button from '../../custom/btn/Btn';
import ModelProfileHeader from '../../custom/modelProfileHeader/ModelProfileHeader';
import { withRole } from '../../hoc/withRole';
import SelectAll from '../../custom/selectAll/SelectAll';
import AddButton from '../../custom/addButton/AddButton';
import { ReactComponent as DeleteIcon } from '../../images/svg/delete.svg';
import ShowPreview from './ShowPreview/ShowPreview';
import UploadPopup from '../../custom/uploadPopup/UploadPopup';
import notification from '../../utils/notification';
import errorFormmatter from '../../utils/errorFormatter';
import {
  getFanClubShows,
  uploadFanClubShow,
  deleteFanClubShow,
} from '../../request/model';
import ModelEditPopup from './modelEditPopup/ModelEditPopup';
import Spinner from '../../custom/spinner/Spinner';

const ModelFanClubShow = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const clientId = useSelector((state) => state?.user?.clientId);
  const [isOpenUploadPhotoModal, setIsOpenUploadPhotoModal] = useState(false);
  const [selectedShowIds, setSelectedShowIds] = useState([]);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [fanClubShows, setFanClubShows] = useState([]);
  const [openEditShow, setOpenEditShow] = useState(false);
  const [showToEdit, setShowToEdit] = useState([]);

  const handleOpenEditShow = (show) => {
    setShowToEdit(show);
    setOpenEditShow(true);
  };

  const handleClose = () => {
    setIsOpenUploadPhotoModal(false);
  };

  const handleOpenUploadModal = () => {
    setIsOpenUploadPhotoModal(true);
    setLoading(false);
  };

  const handleFileChange = (file) => {
    setFile(file);
  };

  const getFanClubShow = async () => {
    try {
      const response = await getFanClubShows({ modelId: clientId });

      setFanClubShows(response.data);
    } catch (error) {
      setFanClubShows([]);
    }
  };

  const handleUploadFanClubShow = async () => {
    try {
      const payload = new FormData();

      payload.append('registeredId', clientId);
      payload.append('AddCoverImage', file);
      setLoading(true);
      const response = await uploadFanClubShow({ payload });
      setLoading(false);
      notification({
        message: response?.message ?? 'Fan club show saved successfully',
      });
    } catch (error) {
      notification({
        message: errorFormmatter(error) ?? 'Fan club show failed to save',
        type: 'danger',
      });
      setLoading(false);
    }
  };

  const handleShowSelection = (showId) => {
    const id = selectedShowIds.find((id) => id === showId);
    if (id) {
      const filteredShowIds = selectedShowIds.filter((id) => id !== showId);
      setSelectedShowIds(filteredShowIds);
    } else {
      setSelectedShowIds((prev) => [...prev, showId]);
    }
  };

  const handleSelectAll = (e) => {
    const { checked } = e.target;
    if (!fanClubShows) return;

    if (checked) {
      const selectedIds = fanClubShows?.map((show) => show?.modelShowId);
      setSelectedShowIds(selectedIds);
    } else {
      setSelectedShowIds([]);
    }

    setIsSelectedAll(checked);
  };

  const deleteShow = async (id) => {
    try {
      setLoading(true);
      const response = await deleteFanClubShow({
        modelId: clientId,
        showId: id,
      });
      setLoading(false);
      notification({
        message: response?.message ?? 'Fan club show deleted successfully',
      });
    } catch (error) {
      notification({
        message: errorFormmatter(error) ?? 'Fan club show failed to delete',
        type: 'danger',
      });
      setLoading(false);
    }
  };

  const handleIsSelected = (showId) => {
    const id = selectedShowIds.find((id) => id === showId);
    return id ? true : false;
  };

  useEffect(() => {
    getFanClubShow();
  }, []);

  useEffect(() => {
    getFanClubShow();
  }, [loading]);

  useEffect(() => {
    getFanClubShow();
  }, [openEditShow]);

  return (
    <FluidContainer>
      <ModelProfileHeader className={styles.topNav} />
      <div className={styles.header}>
        <h1 className={styles.subTitle}>FAN CLUB</h1>
        <h1 className={styles.title}>
          Shows <span className={styles.tip}>{fanClubShows?.length ?? 0}</span>
        </h1>
      </div>
      <section className={styles.navContainer}>
        <SelectAll
          labelText='Select All Shows'
          checked={isSelectedAll}
          onChange={handleSelectAll}
        />
        <nav className={styles.nav}>
          {loading && <Spinner />}
          <Button
            icon={DeleteIcon}
            className={styles.deleteButton}
            text='Delete Show'
            onClick={() => {
              selectedShowIds.map((id) => deleteShow(id));
            }}
          />
        </nav>
      </section>
      <section className={styles.photoContainer}>
        <AddButton text='Add Fan Club Shows' onClick={handleOpenUploadModal} />
        {fanClubShows.map((show, index) => {
          return (
            <div key={index}>
              <ShowPreview
                src={show?.showCoverURL}
                showId={show?.modelShowId}
                show={show}
                deleteShow={() => deleteShow(show?.modelShowId)}
                handleOpenEditShowModal={() => handleOpenEditShow(show)}
                handleShowSelection={handleShowSelection}
                isSelected={handleIsSelected}
              />
              <ModelEditPopup
                deleteShow={(id) => deleteShow(id)}
                key={show?.modelShowId}
                open={openEditShow}
                onClose={() => setOpenEditShow(false)}
                show={showToEdit}
              />
            </div>
          );
        })}
      </section>

      <UploadPopup
        disabled={loading}
        upload={handleUploadFanClubShow}
        onFileChange={handleFileChange}
        title='show cover'
        open={isOpenUploadPhotoModal}
        handleClose={handleClose}
        accept='image/*'
        acceptableFile='JPG, PNG,JPEG less than 10 MB'
      />
    </FluidContainer>
  );
};

export default withRole(ModelFanClubShow);
