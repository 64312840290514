import { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import classname from 'classnames';
import SelectAll from '../../../../custom/selectAll/SelectAll';
import Spinner from '../../../../custom/spinner/Spinner';
import { ReactComponent as PhotoIcon } from '../../../../svgs/photo-icon.svg';
import { MdOutlineModeEdit } from 'react-icons/md';
import { FaTimes } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';

const GalleryUploadPop = ({
  open,
  disabled = false,
  handleClose,
  loading,
  handleConfirmGalleryPhotos,
  acceptableFile = 'JPG, GIF or PNG less than 10 MB',
}) => {
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [files, setFiles] = useState([]);

  const handleChange = (e) => {
    try {
      const file = e?.target?.files[0];
      if (!file) return;
      const imgUrl = URL.createObjectURL(file);
      const fileData = {
        file,
        id: uuidv4(),
        fileName: file?.name ?? '',
        title: '#title',
        description: 'new feed image',
        previewUrl: imgUrl,
        isSelected: false,
      };

      setFiles((prev) => [...prev, fileData]);
    } catch (error) {}
  };

  const handleDeleteFeedImage = (id) => {
    const filteredData = files.filter((file) => {
      return file?.id !== id;
    });

    setFiles((prev) => {
      return filteredData;
    });
  };

  const handleInputChange = (event, id) => {
    const value = event.target.value;
    const name = event.target.name;

    setFiles((prev) => {
      return prev.map((file) => {
        if (file?.id === id) {
          return { ...file, [name]: value };
        } else {
          return file;
        }
      });
    });
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (!file) return;
    const imgUrl = URL.createObjectURL(file);
    const fileData = {
      file,
      id: uuidv4(),
      fileName: file?.name ?? '',
      title: '#title',
      description: 'new feed image',
      previewUrl: imgUrl,
      isSelected: false,
    };
    setFiles((prev) => [...prev, fileData]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const closeModal = () => {
    setFiles([]);
    handleClose(false);
  };

  const handleSelectAll = (e) => {
    const checked = e.target.checked;

    setIsSelectAll(checked);

    const newPrev = files.map((data) => {
      return {
        ...data,
        isSelected: checked,
      };
    });

    setFiles(newPrev);
  };

  const handleSelectOne = (id, checked) => {
    setIsSelectAll(false);
    const filteredData = files.map((file) => {
      if (file?.id === id) {
        return {
          ...file,
          isSelected: checked,
        };
      } else {
        return file;
      }
    });
    setFiles(filteredData);
  };

  useEffect(() => {
    setFiles([]);
  }, []);

  return (
    <Popup
      open={open}
      lockScroll
      onClose={closeModal}
      closeOnDocumentClick
      contentStyle={{
        width: '100%!important',
      }}
    >
      <main
        className={`flex px-[48px] py-[36px] z-[999]   !flex-col bg-gradient-to-r from-[#413450] to-[#563143]  h-[551px] !min-w-[1290px] relative `}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <span className='login-popup--close' onClick={() => handleClose(false)}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='18'
            height='18'
            viewBox='0 0 18 18'
            fill='none'
          >
            <path
              d='M17.7071 1.70711C18.0976 1.31658 18.0976 0.683417 17.7071 0.292893C17.3166 -0.0976311 16.6834 -0.0976311 16.2929 0.292893L17.7071 1.70711ZM0.292893 16.2929C-0.0976311 16.6834 -0.0976311 17.3166 0.292893 17.7071C0.683417 18.0976 1.31658 18.0976 1.70711 17.7071L0.292893 16.2929ZM16.2929 17.7071C16.6834 18.0976 17.3166 18.0976 17.7071 17.7071C18.0976 17.3166 18.0976 16.6834 17.7071 16.2929L16.2929 17.7071ZM1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L1.70711 0.292893ZM16.2929 0.292893L0.292893 16.2929L1.70711 17.7071L17.7071 1.70711L16.2929 0.292893ZM17.7071 16.2929L1.70711 0.292893L0.292893 1.70711L16.2929 17.7071L17.7071 16.2929Z'
              fill='white'
            />
          </svg>
        </span>
        <section className='border-b-[1px] border-primary-100 pb-[10px] '>
          {files.length > 0 ? (
            <h1 className="mb-[24px] text-primary-100 text-2xl font-medium font-['Montserrat'] leading-7">
              Upload new files
            </h1>
          ) : (
            <h1 className="mb-[24px] text-primary-100 text-2xl font-medium font-['Montserrat'] leading-7">
              You have not uploaded any photos yet
            </h1>
          )}

          <div className='w-[162px] h-9 flex items-center gap-[0.5rem]'>
            <SelectAll
              labelText='Select All Photo'
              checked={isSelectAll}
              onChange={handleSelectAll}
            />
          </div>
        </section>
        <section className='flex gap-[1rem] '>
          <section className={` !w-[40%] !flex !flex-col !justify-between `}>
            <section className='h-[250px] scrollbar overflow-y-scroll pr-[2rem] mb-[1rem]  '>
              {files?.map((file, index) => {
                return (
                  <UploadedFeed
                    handleDeleteFeedImage={handleDeleteFeedImage}
                    handleInputChange={handleInputChange}
                    data={file}
                    key={index}
                    handleSelectOne={handleSelectOne}
                  />
                );
              })}
            </section>
            <div className={` !w-[100%]  mb-[2rem]`}>
              <label
                className={classname(
                  'btn btn__red photoLoad-popup--btn',
                  '!w-[100%]'
                )}
              >
                <input
                  type='file'
                  name='myFile'
                  onChange={handleChange}
                  id='myFile'
                  accept={'image/*'}
                  className={'h-[0px] w-[0px] scale-0'}
                />
                Upload from computer
              </label>
              <button
                onClick={() => {
                  if (
                    handleConfirmGalleryPhotos &&
                    disabled === false &&
                    files.length !== 0
                  ) {
                    handleConfirmGalleryPhotos(files);
                  }
                }}
                className={classname(
                  'btn btn__red send-js photoLoad-popup--btn',
                  '!w-[100%]',
                  files.length === 0 || disabled === true
                    ? 'photoLoad-popup--btn__disabled'
                    : ''
                )}
                disabled={disabled}
              >
                Confirm upload
                {loading && <Spinner />}
              </button>
            </div>
          </section>

          <section className=' !w-[55%] h-[400px]   py-[21px]'>
            <div className='!w-[100%] relative  h-[100%]    bg-white/opacity-10 rounded-[5px] flex flex-col items-center justify-center border-[2px] border-stone-300 block border-dashed '>
              <PhotoIcon className='mb-[13px]' />

              <h1 className="text-center text-neutral-300 text-4xl font-semibold font-['Montserrat'] leading-[50.40px]">
                Drag Media here
              </h1>

              <h5 className="text-center text-primary-100 text-base font-semibold font-['Montserrat'] leading-[18.40px]">
                {acceptableFile}
              </h5>
              <input
                title=' '
                name='myFile'
                type='file'
                onChange={handleChange}
                id='myFile'
                accept={'image/*'}
                className='absolute top-0 bottom-0 left-0 right-0 [background:none] opacity-0 appearance-none outline-none '
              />
            </div>
          </section>
        </section>
      </main>
    </Popup>
  );
};

const UploadedFeed = ({
  data,
  handleSelectOne,
  handleInputChange,
  handleDeleteFeedImage,
}) => {
  return (
    <div className='w-[100%] mb-[1rem] h-24 flex items-center justify-between '>
      <div className='flex items-center gap-[1rem]'>
        <div className='flex items-center gap-[1rem]'>
          <input
            onChange={(e) => handleSelectOne(data?.id, e.target.checked)}
            checked={data?.isSelected}
            type='checkbox'
            className='w-9 h-9  rounded-sm border-2 border-primary-100  before:content-["\2713"] before:text-[#d4d4d4] before:text-[1.6rem] before:opacity-[0] cursor-pointer checked:before:opacity-[1] appearance-none flex items-center justify-center'
          />
          <img className='w-[88px] h-[58.04px]' src={data?.previewUrl} />
        </div>
        <div className='w-[100px] h-[19px] justify-center items-center gap-1 inline-flex'>
          <input
            onChange={(event) => {
              handleInputChange(event, data?.id);
            }}
            name='fileName'
            defaultValue={data?.fileName}
            className="[background:none!important] focus:[background:none] focus:outline-none  focus-within:[background:none] appearance-none text-primary-100 w-[100%] text-base font-medium font-['Montserrat'] leading-[18.40px]"
          />
          <MdOutlineModeEdit className='text-white text-[1rem] cursor-pointer' />
        </div>
      </div>

      <button
        onClick={() => handleDeleteFeedImage(data?.id)}
        className='w-[95px] cursor-pointer h-9 rounded-sm border border-primary-100 justify-center gap-[0.5rem] items-center inline-flex'
      >
        <FaTimes className='text-neutral-300  text-[1rem] opacity-90 ' />
        <span className="pr-3 py-2 justify-start items-start flex opacity-90 text-neutral-300 text-sm font-medium font-['Montserrat'] leading-tight">
          Delete
        </span>
      </button>
    </div>
  );
};

export default GalleryUploadPop;
