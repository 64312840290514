import { useState } from 'react';
import ModelCard from '../components/card/ModelCard';
import Spinner from '../../custom/spinner/Spinner';
import { useQuery } from 'react-query';
import { getModelsByCategory } from '../../request/request';
import Pagination from '../../custom/pagination/Pagination';

const Category = () => {
	const [pageNumber, setPageNumber] = useState(1);
	const [models, setModels] = useState([]);

	const { data, isErrror, error, isFetching } = useQuery(
		['category-models', pageNumber],
		() => getModelsByCategory({ pageNumber, category: 'All' }),
		{
			onSuccess: response => {
				setModels(response?.models);
			},
		}
	);

	const pageSize = data?.pageSize ?? 0;
	const pageCount = data?.pageCount ?? 0;

	const errorMessage = error?.response?.data?.message ?? error?.message;

	const handlePagination = pagenumber => {
		setPageNumber(pagenumber);
	};

	if (isFetching) return <Spinner width={50} height={50} />;
	if (isErrror) return <p className='error'>{errorMessage}</p>;

	return (
    <main className='container categories-page'>
      <section>
        <div className='page-title'>
          Categories <span>{models?.length}</span>
        </div>
        <div className='container'>
          <div className='new-models-cards'>
            {Array.isArray(models) && models.length > 0 ? (
              models?.map((model, index) => (
                <ModelCard
                  model={model}
                  key={index}
                  updateModel={setModels}
                  modelType={' '}
                />
              ))
            ) : (
              <p>no model available</p>
            )}
          </div>
        </div>

        <Pagination
          pageCount={pageCount}
          pageSize={pageSize}
          onChange={handlePagination}
        />
      </section>
      <section className='categories-page--advertising'>
        <div className='container'>
          <img src={require('../../images/trashimg/mainbanner.jpg')} alt='' />
        </div>
      </section>
      {/* <Similar /> */}
    </main>
  );
};

export default Category;
