export const errorFormmatter = error => {
	return error?.response?.data?.message
		? error?.response?.data?.message
		: error?.response?.data
		? formatError(error)
		: error?.message;
};

function formatError(error) {
	return typeof error?.response?.data === 'string'
		? error?.response?.data
		: Object.values(error?.response?.data)
				.flat()
				.join(', ');
}
export default errorFormmatter;
