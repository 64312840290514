import { useState } from 'react';
import { ReactComponent as Camera } from '../../images/svg/camera.svg';
import styles from './styles.module.scss';
import UploadPopUp from './uploadPopup/UploadPopup';

const ModelUploadPicture = () => {
	const [open, setOpen] = useState(false);

	return (
		<main className={styles.main}>
			<h2 className={styles.heading}>Validation by ID</h2>
			<p className={styles.subHeading}>
				Please upload Articles of Incorporation to validate you are a registered club.
			</p>

			<div className={styles.container}>
				<section className={styles.uploadImageWrapper}>
					<button className={styles.uploadBtn} onClick={() => setOpen(true)}>
						<span>Upload a photo</span> |
						<Camera />
					</button>
				</section>
				<section>
					<h3 className={styles.passVerificationHeading}>Pass verification</h3>
					<h4 className={styles.passVerificationSubHeading}>What is verification?</h4>
					<p className={styles.passVerificationPara}>
						We hope this message finds you well. As part of our club verification process, we kindly request that
						you provide us with the Articles of Incorporation for your club. We understand that this may require
						some effort, but we assure you that it is a crucial step in ensuring the legitimacy and credibility of
						your club.
					</p>

					<h4 className={styles.passVerificationSubHeading}>Why do I need to submit the AOI?</h4>
					<p className={styles.passVerificationPara}>
						By submitting the Articles of Incorporation, you are demonstrating that your club is a legally
						recognized entity. It provides concrete evidence that your club has gone through the proper
						registration process, ensuring compliance with applicable laws and regulations.
					</p>

					<h4 className={styles.passVerificationSubHeading}>What if verification is rejected ?</h4>
					<p className={styles.passVerificationPara}>
						If you do not qualify, you will receive a message explaining the reasons why the procedure failed.
						After that you can upload a new photo and verify it again.
					</p>
				</section>
			</div>
			<UploadPopUp open={open} handleClose={setOpen} />
		</main>
	);
};

export default ModelUploadPicture;
