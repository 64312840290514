import { useEffect, useState } from 'react';
import moment from 'moment';
import {
  getClubFeeds,
  likeAndGetPostLike,
  getClubLikes,
} from '../../../../request/club';
import { SlLike } from 'react-icons/sl';
import { FaRegCommentAlt } from 'react-icons/fa';
import EmptyView from '../../EmptyView';
import { PiDotsThreeOutlineLight } from 'react-icons/pi';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

// const clubId = 'b002d30c-ef07-4462-8489-2c9d4568da3a';

const Feeds = () => {
  const [clubFeeds, setClubFeeds] = useState([]);
  const [loading, setLoading] = useState(false);
  const params = useLocation();
  const _clubId = params?.search?.split('clubId=')[1] ?? '';

  const handleGetFeeds = async () => {
    try {
      const response = await getClubFeeds({ clubId: _clubId });

      setClubFeeds(response?.clubFullFeed);
    } catch (error) {
      setClubFeeds([]);
    }
  };

  useEffect(() => {
    handleGetFeeds();
  }, []);

  useEffect(() => {
    handleGetFeeds();
  }, [loading]);

  return (
    <main>
      {clubFeeds?.length > 0 ? (
        clubFeeds?.map((clubFeed, index) => {
          return (
            <Feed
              loading={loading}
              setLoading={setLoading}
              clubFeed={clubFeed}
              key={index}
            />
          );
        })
      ) : (
        <EmptyView text={'No club feeds'} />
      )}
    </main>
  );
};

export default Feeds;

const Feed = ({ setLoading, loading, clubFeed }) => {
  const route = useHistory();
  const [likes, setLikes] = useState(0);
  const [isLike, setIsLike] = useState(false);
  const params = useLocation();
  const _clubId = params?.search?.split('clubId=')[1] ?? '';
  const user = useSelector((state) => state?.user);

  const handleViewFeed = () => {
    route.push(`/club/post/comment?postId=${clubFeed?.clubPostId}`);
  };

  const handleLikeAndGetPostLike = async () => {
    try {
      const response = await getClubLikes({
        postId: clubFeed?.clubPostId,
        clubId: _clubId,
        memberId: user?.clientId,
      });

      setLikes(response?.likeCount ?? 0);
      setIsLike(response?.changeColor);
    } catch (error) {
      setLikes(0);
      //setIsLike(clubFeed?.changeColor);
    }
  };

  const handleLike = async () => {
    try {
      const like = !clubFeed?.changeColor;

      setLoading(true);
      const response = await likeAndGetPostLike({
        clubId: _clubId,
        memberId: user?.clientId,
        postId: clubFeed?.clubPostId,
        userLike: like,
      });

      setLikes(response?.clubLike ?? 0);
      setIsLike(response?.changeColor);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setLikes(0);
      //setIsLike(clubFeed?.changeColor);
    }
  };

  useEffect(() => {
    handleLikeAndGetPostLike();
    setLikes(clubFeed?.likeCount);
  }, []);

  return (
    <section className='mb-[64px] cursor-pointer  w-[100%] h-[760px] justify-start items-start gap-5 inline-flex'>
      <section className='justify-start items-start gap-5 flex'>
        <img className='w-16 h-16 rounded-full' src={clubFeed?.profileImage} />
      </section>
      <section className='grow shrink basis-0 flex-col justify-start items-start gap-6 inline-flex'>
        <section className='self-stretch  flex-col justify-start items-start gap-4 flex'>
          <section className='self-stretch  flex-col justify-start items-start gap-2 flex'>
            <div className='self-stretch h-[67px] flex-col justify-start items-start gap-4 flex'>
              <div className='self-stretch pr-4 justify-between items-center inline-flex'>
                <div className='justify-start items-baseline gap-2.5 flex'>
                  <span className=" block text-white text-2xl font-bold font-['Montserrat'] leading-7">
                    {clubFeed?.name}
                  </span>
                  <span className="block text-zinc-400 text-xl font-normal font-['Montserrat'] leading-[23px]">
                    {moment(clubFeed?.postDate).fromNow()}
                  </span>
                </div>
                <PiDotsThreeOutlineLight className='text-white' />
              </div>
              <p className="text-white text-xl font-bold font-['Montserrat'] leading-[23px]">
                {clubFeed?.title}
              </p>
            </div>
            <p className="text-white text-lg font-normal font-['Montserrat'] leading-tight">
              {clubFeed?.description}
            </p>
          </section>
          <section
            onClick={handleViewFeed}
            className='w-[100%] h-[600px] rounded-xl flex-col justify-start items-start gap-2.5 flex'
          >
            <img className='h-[100%] w-[100%]' src={clubFeed?.imagePostURL} />
          </section>
        </section>
        <section className='justify-start items-center gap-[1rem] inline-flex'>
          <div className='justify-start items-center gap-3 flex'>
            <SlLike
              onClick={() => handleLike()}
              className={`cursor-pointer ${
                isLike ? 'text-red-500' : 'text-white'
              }`}
            />
            <span className="block text-neutral-300 text-lg font-normal font-['Montserrat'] leading-tight">
              {likes}
            </span>
          </div>
          <div className='justify-start items-center gap-3 flex'>
            <FaRegCommentAlt className='text-white' onClick={handleViewFeed} />
            <span className="block text-neutral-300 text-lg font-normal font-['Montserrat'] leading-tight">
              {clubFeed?.comments?.length ?? 0}
            </span>
          </div>
        </section>
      </section>
    </section>
  );
};
