export const gridLayouts = {
  LAYOUT_GRID_ONE: 'layout-1',
  LAYOUT_GRID_TWO: 'layout-2',
  LAYOUT_GRID_THREE: 'layout-3',
};

const useGridDisplay = () => {
  function createGalleryItems(
    image,
    width,
    createGalleryItem,
    openAllView,

    ...rest
  ) {
    if (createGalleryItem) {
      return createGalleryItem({
        ...rest,
        image,
        width,
      });
    } else {
      return null;
    }
  }

  let lastLayout = gridLayouts.LAYOUT_GRID_TWO;
  let alternateWidths = true;

  let currentCombinationIndex = 0;

  function renderGallery({
    images = [],
    containerRef = null,
    createGalleryItem = null,
    openAllView = null,
    rowClass = '',
    widthCombinations = [
      [60, 40],
      [40, 60],
      [70, 30],
      [30, 70],
      [80, 20],
      [20, 80],
    ],
    createCustomLayout = null,
  }) {
    if (createCustomLayout) {
      createCustomLayout();
    } else {
      if (containerRef) {
        containerRef.innerHTML = '';

        let imageIndex = 0;

        const toggleLayout = () => {
          if (lastLayout === gridLayouts.LAYOUT_GRID_ONE) {
            lastLayout = gridLayouts.LAYOUT_GRID_TWO;
          } else {
            lastLayout = gridLayouts.LAYOUT_GRID_ONE;
          }
        };

        while (imageIndex < images.length) {
                                             const row = document.createElement(
                                               'div'
                                             );

                                             if (
                                               lastLayout ===
                                               gridLayouts.LAYOUT_GRID_ONE
                                             ) {
                                               // Layout: Two images side by side with alternating widths
                                               // !h-[422px]
                                               row.className = `flex gap-2 ${rowClass} `;
                                               const [
                                                 width1,
                                                 width2,
                                               ] = widthCombinations[
                                                 currentCombinationIndex
                                               ];
                                               const item_one = createGalleryItems(
                                                 images[imageIndex],
                                                 width1,
                                                 createGalleryItem,
                                                 openAllView
                                               );
                                               if (item_one) {
                                                 row.appendChild(item_one);
                                               }

                                               imageIndex++;
                                               if (imageIndex < images.length) {
                                                 const item_two = createGalleryItems(
                                                   images[imageIndex],
                                                   width2,
                                                   createGalleryItem,
                                                   openAllView
                                                 );
                                                 if (item_two) {
                                                   row.appendChild(item_two);
                                                 }

                                                 imageIndex++;
                                               }
                                               currentCombinationIndex =
                                                 (currentCombinationIndex + 1) %
                                                 widthCombinations.length; // Cycle to the next width combination
                                               toggleLayout();
                                             } else if (
                                               lastLayout ===
                                               gridLayouts.LAYOUT_GRID_TWO
                                             ) {
                                               // Layout: One full-width image
                                               row.className = `flex gap-2 ${rowClass} `;
                                               const item_three = createGalleryItems(
                                                 images[imageIndex],
                                                 100,
                                                 createGalleryItem,
                                                 openAllView
                                               );
                                               if (item_three) {
                                                 row.appendChild(item_three);
                                               }
                                               imageIndex++;
                                               toggleLayout();
                                             }

                                             containerRef.appendChild(row);
                                           }
      }
    }
  }

  return {
    renderGallery,
  };
};

export default useGridDisplay;

// function renderGallery(openAllView) {
//   if (containerRef) {
//     containerRef.innerHTML = '';

//     let imageIndex = 0;

//     const toggleLayout = () => {
//       if (lastLayout === gridLayouts.LAYOUT_GRID_ONE) {
//         lastLayout = gridLayouts.LAYOUT_GRID_TWO;
//       } else {
//         lastLayout = gridLayouts.LAYOUT_GRID_ONE;
//       }
//     };

//     while (imageIndex < images.length) {
//       const row = document.createElement('div');

//       if (lastLayout === gridLayouts.LAYOUT_GRID_ONE) {
//         // Layout: Two images side by side with alternating widths
//         row.className = 'flex gap-2 mb-4 h-[422px]';
//         if (alternateWidths) {
//           row.appendChild(
//             createGalleryItem(images[imageIndex], 60, openAllView)
//           );
//           imageIndex++;
//           if (imageIndex < images.length) {
//             row.appendChild(
//               createGalleryItem(images[imageIndex], 40, openAllView)
//             );
//             imageIndex++;
//           }
//         } else {
//           row.appendChild(
//             createGalleryItem(images[imageIndex], 40, openAllView)
//           );
//           imageIndex++;
//           if (imageIndex < images.length) {
//             row.appendChild(
//               createGalleryItem(images[imageIndex], 60, openAllView)
//             );
//             imageIndex++;
//           }
//         }
//         alternateWidths = !alternateWidths; // Toggle the widths
//         toggleLayout();
//       } else if (lastLayout === gridLayouts.LAYOUT_GRID_TWO) {
//         // Layout: One full-width image
//         row.className = 'flex gap-2 mb-4 h-[422px]';
//         row.appendChild(
//           createGalleryItem(images[imageIndex], 100, openAllView)
//         );
//         imageIndex++;
//         toggleLayout();
//       }

//       containerRef.appendChild(row);
//     }
//   }
// }
