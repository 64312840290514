import { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import classnames from 'classnames';
import styles from './styles.module.scss';
import Video from '../../../custom/video/Video';
import { ReactComponent as CloseButton } from '../../../images/svg/Close Btn.svg';
import Button from '../../../custom/btn/Btn';
import { ReactComponent as DeleteIcon } from '../../../images/svg/delete.svg';
import { ReactComponent as AlbumIcon } from '../../../images/svg/album-icon.svg';
import { ReactComponent as EditIcon } from '../../../images/svg/edit-icon.svg';
import { updateFanClubVideo } from '../../../request/model';
import Spinner from '../../../custom/spinner/Spinner';
import notification from '../../../utils/notification';
import errorFormmatter from '../../../utils/errorFormatter';
import { useSelector } from 'react-redux';
import { BiError } from 'react-icons/bi';

const ModelEditPopup = ({ onClose, open, video }) => {
  const { videoUrl, videoUploadId, isHidden, description, videoName } = video;

  const user = useSelector((state) => state?.user);

  const [loading, setLoading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const [data, setData] = useState({
    videoName: videoName,
    description: description,
  });

  const onLoading = (state) => {
    setIsPlaying(state.loading);
  };

  const [permission, setPermission] = useState({
    isHidden: false,
    isDeleted: video?.isDeleted,
  });

  const handleChange = (e) => {
    const { value, name } = e.target;

    setData({
      ...data,
      [name]: value ?? data[name],
    });
  };

  const editVideo = async (type, value = '') => {
    try {
      const payload = new FormData();

      if (type === 'videoName') {
        payload.append('videoName', data.videoName);
      }

      if (type === 'description') {
        payload.append('description', data.description ?? value);
      }

      if (type === 'isHidden') {
        payload.append('isHidden', value);
      }

      if (type === 'isDeleted') {
        payload.append('isDeleted', value);
      }

      payload.append('registeredId', user?.clientId);
      payload.append('videoUploadId', videoUploadId);

      setLoading(true);
      const response = await updateFanClubVideo({ payload });
      setLoading(false);
      notification({
        message: response?.message ?? 'Fan club photo added successfully',
      });
    } catch (error) {
      notification({
        message: errorFormmatter(error) ?? 'Fan club photo failed to add',
        type: 'danger',
      });
      setLoading(false);
    }
  };

  const handlePermission = (name) => {
    let value = permission[name] === false ? true : false;

    setPermission({
      ...permission,
      [name]: value,
    });

    if (name === 'isDeleted') {
      editVideo('isDeleted', true);
    } else {
      editVideo(name, value);
    }
  };

  useEffect(() => {
    setPermission(() => ({
      ...permission,
      isHidden: video?.isHidden,
    }));
  }, [isHidden]);

  return (
    <Popup
      onClose={onClose}
      open={open}
      lockScroll={false}
      overlayStyle={{ background: 'rgba(68, 39, 52, 0.89)' }}
    >
      <CloseButton className={styles.closeBtn} onClick={onClose} />
      <section className={styles.imageContainer}>
        <div className={styles.topActionContainer}>
          {loading && <Spinner />}
          {/* 
          <Button
            text='Add to photo set'
            icon={CreateAlbumIcon}
            className={styles.addAlbum}
            onClick={() => addToPhotoSet(photoId)}
          /> */}
        </div>
        <div className={styles.videoContainer}>
          {isPlaying && (
            <div className={styles.errorContainer}>
              <BiError className={styles.icon} />
            </div>
          )}
          <Video
            className={styles.video}
            src={videoUrl}
            videoClass={styles.videoClass}
            onLoading={onLoading}
          />
        </div>

        <nav className={styles.nav}>
          <section className={styles.nameWrapper}>
            <label className={styles.inputNameLabel}>
              <AlbumIcon className={styles.editIcon} />
              <input
                type='text'
                name='videoName'
                defaultValue={videoName}
                onBlur={() => editVideo('videoName')}
                onChange={handleChange}
                className={styles.nameInput}
              />
              <EditIcon className={styles.editIcon} />
            </label>
          </section>

          <section className={styles.permissionBtn}>
            <Button
              onClick={() => handlePermission('isHidden')}
              text='Hidden'
              className={classnames(
                styles.btn,
                permission.isHidden === true && styles.active
              )}
            />
            <Button
              text={'Delete'}
              icon={DeleteIcon}
              className={classnames(styles.deleteBtn)}
              onClick={() => handlePermission('isDeleted')}
            />
          </section>
        </nav>
        <fieldset className={styles.fieldset}>
          <label className={styles.label}>
            <span className={styles.description}>Description</span>
          </label>
          <textarea
            name='description'
            className={styles.textarea}
            onChange={handleChange}
            defaultValue={video.description ?? ''}
            onBlur={() => editVideo('description', description)}
          ></textarea>
        </fieldset>
      </section>
    </Popup>
  );
};

export default ModelEditPopup;
