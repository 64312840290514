import styles from './modelStats.module.scss';
import ModelProfileHeader from '../../custom/modelProfileHeader/ModelProfileHeader';
import FluidContainer from '../../custom/fluidContainer/FluidContainer';
import DatePicker from '../../custom/datePicker/DatePicker';
import Button from '../../custom/btn/Btn';
import Pagination from '../../custom/pagination/Pagination';
import GridTable from './table/Table';
import { ReactComponent as DropIcon } from '../../svgs/drop-icon.svg';
import Tab from '../../custom/Tabs/Tabs';
import DropDown from '../../custom/DropDown/DropDown';
import { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { getModelStats } from '../../request/model';
import Spinner from '../../custom/spinner/Spinner';
import { format } from 'currency-formatter';

import moment from 'moment';
const months = [
	{
		label: 1,
		value: 'January',
	},
	{
		label: 2,
		value: 'February',
	},
	{
		label: 3,
		value: 'March',
	},
	{
		label: 4,
		value: 'April',
	},
	{
		label: 5,
		value: 'May',
	},
	{
		label: 6,
		value: 'June',
	},
	{
		label: 7,
		value: 'July',
	},
	{
		label: 8,
		value: 'August',
	},
	{
		label: 8,
		value: 'August',
	},
	{
		label: 9,
		value: 'September',
	},
	{
		label: 10,
		value: 'October',
	},
	{
		label: 11,
		value: 'November',
	},
	{
		label: 12,
		value: 'December',
	},
];

const tableHeader = [
	{
		text: 'Date',
	},
	{
		text: 'Earned',
		subText: 'credits',
	},
	{
		text: 'Paid',
		subText: 'credits',
	},
	{
		text: 'Visits',
	},
	{
		text: 'Uniq',
	},
	{
		text: 'Online',
	},
	{
		text: 'Avail',
	},
	{
		text: 'Free',
	},
	{
		text: 'Group',
	},
	{
		text: 'Private',
	},
	{
		text: 'Voyeur',
	},
];
const currentDate = moment();
const today = moment().format('YYYY-MM-DD');
const thisMonth = moment().format('MMMM');
const thisYear = moment().format('YYYY');
const lastMonth = moment()
	.subtract(1, 'months')
	.format('MMMM');

const ModelStatistics = () => {
	const clientId = useSelector(state => state?.user?.clientId);
	const [tabIndex, setTabIndex] = useState(0);
	// const [selectedMonth, setSelectedMonth] = useState(thisMonth);
	const [dynamicDate, setDynamicDate] = useState('');
	const [startDate, setStartDate] = useState(today);
	const [endDate, setEndDate] = useState(today);
	const [dynamicStartDate, setDynamicStartDate] = useState('');
	const [dynamicEndDate, setDynamicEndDate] = useState('');

	const params = `${startDate}${endDate}`;

	const { data, error, isFetching, isError } = useQuery(
		['model-stats', params, tabIndex],
		() => getModelStats({ modelId: clientId, startDate, endDate, pageNumber: 1 }),
		{
			refetchOnWindowFocus: false,
			retry: 0,
		}
	);

	const errorMessage = error?.response?.data?.message ?? error?.message;
	const quickStarts = data && Array.isArray(data) ? data : [];

	let transformedQuickStarts = quickStarts?.map(quickStart => {
		return [
			{ text: moment(new Date(quickStart?.date)).format('YYYY-MM-DD') },
			{ text: format(quickStart?.earnedCredit, { code: 'USD' }) },
			{ text: format(quickStart?.paidCredit, { code: 'USD' }) },
			{ text: quickStart?.visit },
			{ text: quickStart?.uniq },
			{ text: quickStart?.online },
			{ text: quickStart?.available },
			{ text: quickStart?.freeBie },
			{ text: quickStart?.Group },
			{ text: quickStart?.private },
			{ text: quickStart?.voyeuer },
		];
	});

	console.log(data, 'this is data');

	const handleDropDownChange = data => {
		setSelectedMonth(data?.value);
	};

	const setDate = keyIndex => {
		let startDate, endDate;

		if (keyIndex === 0) {
			startDate = moment().format('YYYY-MM-DD');
			endDate = moment().format('YYYY-MM-DD');
		}

		if (keyIndex === 1) {
			startDate = currentDate
				.clone()
				.startOf('month')
				.format('YYYY-MM-DD');

			endDate = currentDate
				.clone()
				.endOf('month')
				.format('YYYY-MM-DD');
		}

		if (keyIndex === 2) {
			startDate = currentDate
				.clone()
				.subtract(1, 'months')
				.startOf('month')
				.format('YYYY-MM-DD');

			endDate = currentDate
				.clone()
				.subtract(1, 'months')
				.endOf('month')
				.format('YYYY-MM-DD');
		}

		if (keyIndex === 3) {
			startDate = currentDate
				.clone()
				.startOf('year')
				.format('YYYY-MM-DD');

			endDate = currentDate
				.clone()
				.endOf('year')
				.format('YYYY-MM-DD');
		}

		if (keyIndex === 4) {
			startDate = moment(new Date('1909-08-13')).format('YYYY-MM-DD');
			endDate = moment().format('YYYY-MM-DD');
		}

		setStartDate(startDate);
		setEndDate(endDate);
		setTabIndex(keyIndex);
	};

	const handleFind = () => {
		setStartDate(dynamicStartDate);
		setEndDate(dynamicEndDate);
	};

	return (
		<FluidContainer>
			<ModelProfileHeader />
			<section>
				<h1 className={styles.header}>
					<span className={styles.subTitle}>Billing</span>
					<span className={styles.title}>Quick Stats</span>
				</h1>
				<div className={styles.subHeader}>
					<DatePicker
						onChange={date => setDynamicStartDate(moment(date).format('YYYY-MM-DD'))}
						renderElement={({ value, onClick }) => (
							<div className={styles.datePickerContainer} onClick={onClick}>
								<div className={styles.datePickerLeftContainer}>
									<span>{value}</span>
								</div>
								<div className={styles.datePickerRightContainer}>
									<span>Date from</span>
									<DropIcon />
								</div>
							</div>
						)}
					/>
					<DatePicker
						onChange={date => setDynamicEndDate(moment(date).format('YYYY-MM-DD'))}
						renderElement={({ value, onClick }) => (
							<div className={styles.datePickerContainer} onClick={onClick}>
								<div className={styles.datePickerLeftContainer}>
									<span>{value}</span>
								</div>
								<div className={styles.datePickerRightContainer}>
									<span>Date to</span>
									<DropIcon />
								</div>
							</div>
						)}
					/>
					{/* <DropDown
						onChange={handleDropDownChange}
						options={months}
						className={styles.selectContainer}
						dropElement={({ props, state }) => (
							<div className={styles.dropDownRender}>
								<div className={styles.datePickerLeftContainer}>
									<span className={styles.selectedDropDownItem}>{selectedMonth ? selectedMonth : 'Monthly'}</span>
								</div>
								<div className={styles.datePickerRightContainer}>
									<span>Period</span>
									<DropIcon />
								</div>
							</div>
						)}
					/> */}
					{/* <DatePicker
            renderElement={({ value, onClick }) => (
              <div className={styles.datePickerContainer} onClick={onClick}>
                <div className={styles.datePickerLeftContainer}>
                  <span>{value}</span>
                </div>
                <div className={styles.datePickerRightContainer}>
                  <span>Monthly</span>
                  <DropIcon />
                </div>
              </div>
            )}
          /> */}
					<Button text='Find' className={styles.findButton} onClick={handleFind} />
				</div>

				{isFetching ? (
					<Spinner />
				) : isError ? (
					<p className='error'>{errorMessage}</p>
				) : (
					<Fragment>
						<Tab
							panelClass={styles.tabContainer}
							containerClass={styles.navContainer}
							tabClass={styles.navList}
							position='left'
							onIndexChange={setDate}
							defaultKeyIndex={tabIndex}
							items={[
								{
									key: 'Today',
									children: (
										<div className={styles.table}>
											<GridTable tableHeader={tableHeader} data={transformedQuickStarts} />
										</div>
									),
								},
								{
									key: `This month — ${thisMonth}`,
									children: (
										<div className={styles.table}>
											<GridTable tableHeader={tableHeader} data={transformedQuickStarts} />
										</div>
									),
								},
								{
									key: <span className={styles.midList}>Last month — {lastMonth}</span>,
									children: (
										<div className={styles.table}>
											<GridTable tableHeader={tableHeader} data={transformedQuickStarts} />
										</div>
									),
								},
								{
									key: `This year — ${thisYear}`,
									children: (
										<div className={styles.table}>
											<GridTable tableHeader={tableHeader} data={transformedQuickStarts} />
										</div>
									),
								},
								{
									key: 'All time',
									children: (
										<div className={styles.table}>
											<GridTable tableHeader={tableHeader} data={transformedQuickStarts} />
										</div>
									),
								},
							]}
						/>
						{/* <Pagination className={styles.paginationContainer} data={quickStarts} /> */}
					</Fragment>
				)}
			</section>
		</FluidContainer>
	);
};

export default ModelStatistics;
