import React, { Component } from 'react';
import { useLocation, Link } from 'react-router-dom';

const Info = ({ modelInfo }) => {
  const params = useLocation();
  const urlSearchParams = new URLSearchParams(params.search);
  const modelId = urlSearchParams.get('model');

  return (
    <section className='model-info'>
      <div className='model-info-description'>
        <div className='model-info-description--name'>
          {modelInfo?.firstName ?? ''} {modelInfo?.lastName ?? ''}
        </div>
        <div className='model-info-description--rate'>
          <div className='model-info-description--rate-count'>
            <img src={require('../../.././images/trashimg/Stars.png')} alt='' />
          </div>
          <div className='model-info-description--rate-text'>
            {modelInfo?.rating} Rates
          </div>
        </div>
        <div className='model-info-description-bottom'>
          <div className='model-info-description--age'>
            <span className='!text-[18px]'>{modelInfo?.age}</span>
            Age
          </div>
          <div className='model-info-description--breast'>
            <span className='!text-[18px]'>{modelInfo?.breastSize}</span>
            Breast size
          </div>
          <div className='model-info-description--orientation'>
            <span className='!text-[18px]'>{modelInfo?.sexualPreference}</span>
            Sexual preference
          </div>
        </div>
      </div>

      <div className='model-info-img !w-[422px] !h-[269px]'>
        <img
          className='!w-[422px] !h-[269px]'
          src={modelInfo?.profilePicUrl}
          alt=''
        />
      </div>
      <div className='model-info-premium'>
        <div className='model-info-premium--title'>MEET ME IN MY FANCLUB</div>
        <div className='model-info-premium--info'>
          +124
          <span>
            <b>PREMIUM</b>
            PHOTOS AND VIDEOS
          </span>
        </div>
        <ul>
          <li>— Exclusive attention in chat</li>
          <li>— Exclusive emoticons on chat</li>
          <li>— Exclusive Awards points</li>
          <li>— Private messages for half price</li>
        </ul>
        <Link
          to={`/detail/fanclub?model=${modelId}&lastName=${modelInfo?.lastName}&firstName=${modelInfo?.firstName}`}
          className='model-info-premium--btn btn btn__red'
        >
          Be me Fan
        </Link>
      </div>
    </section>
  );
};

export default Info;