import { Link } from 'react-router-dom';
import ModelCard from '../components/card/ModelCard';
import Spinner from '../../custom/spinner/Spinner';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { getMemberFavoriteModel } from '../../request/member';
import { useState } from 'react';
import Pagination from '../../custom/pagination/Pagination';

const LkFavourite = () => {
	const clientId = useSelector(state => state?.user?.clientId);
	const [pageNumber, setPageNumber] = useState(1);

	const { data, isErrror, error, isLoading } = useQuery(
		['member-favorite-model', pageNumber],
		() => getMemberFavoriteModel(clientId, pageNumber),
		{
			enabled: !!clientId,
		}
	);

	const pageSize = data?.pageSize ?? 0;
	const pageCount = data?.pageCount ?? 0;

	const errorMessage = error?.response?.data?.message ?? error?.message;

	if (isLoading) return <Spinner />;
	if (isErrror) return <p className='error'>{errorMessage}</p>;

	const handlePagination = pagenumber => {
		setPageNumber(pagenumber);
	};

	console.log(data, 'history');

	return (
		<main className='favouritePage'>
			<section className='favourite'>
				<div className='container'>
					<div className='reg-data--subtitle'>
						<ul className='breadcrumb'>
							<li>
								<Link to='/lk'>
									<span>Personal account</span>
								</Link>
							</li>
							<li>
								<span>Favorite models</span>
							</li>
						</ul>
					</div>
					<div className='favourite-top'>
						<div className='page-title'>Favorite models</div>
						<div className='favourite-online'>
							<label className='check-all__js'>
								<input type='checkbox' />
								<span></span>
								<b>Show online</b>
							</label>
						</div>
					</div>
					<div className='new-models-cards'>
						{data && data?.models?.map(model => <ModelCard model={model} key={model?.registerId} />)}
					</div>
					<Pagination pageCount={pageCount} pageSize={pageSize} onChange={handlePagination} />
				</div>
			</section>
			<section className='main-reklama'>
				<div className='container'>
					<img src={require('../.././images/trashimg/mainbanner.jpg')} alt='' />
				</div>
			</section>
		</main>
	);
};

export default LkFavourite;
