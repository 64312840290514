import { useState } from 'react';
import { ReactComponent as Camera } from '../../images/svg/camera.svg';
import styles from './modelUploadPicture.module.scss';
import UploadPopUp from './uploadPopup/UploadPopup';

const ModelUploadPicture = () => {
	const [open, setOpen] = useState(false);

	return (
		<main className={styles.main}>
			<h2 className={styles.heading}>Validation by photo and IDs</h2>
			<p className={styles.subHeading}>Take a photo with a passport or other document confirming your age</p>

			<div className={styles.container}>
				<section className={styles.uploadImageWrapper}>
					<button className={styles.uploadBtn} onClick={() => setOpen(true)}>
						<span>Upload a photo</span> |
						<Camera />
					</button>
				</section>
				<section>
					<h3 className={styles.passVerificationHeading}>Pass verification</h3>
					<h4 className={styles.passVerificationSubHeading}>What is verification?</h4>
					<p className={styles.passVerificationPara}>
						Pass the NSA verification to show that you ? are a real person who has reached the age of majority?to
						become a web model. Verification also gives access?to downloading videos and going on the air.
					</p>

					<h4 className={styles.passVerificationSubHeading}>What is needed to verify the identity?</h4>
					<p className={styles.passVerificationPara}>
						You only need an NSA account, a snapshot of yourself with an identity document. All verification
						images will not be made publicly available and are available only?to NSA employees.
					</p>

					<h4 className={styles.passVerificationSubHeading}>What is a verification image ?</h4>
					<p className={styles.passVerificationPara}>
						Your high resolution photo on which you hold an identity document. The photo should show most of your
						face.
					</p>

					<h4 className={styles.passVerificationSubHeading}>What if verification is rejected ?</h4>
					<p className={styles.passVerificationPara}>
						If you do not pass the test, you will receive a message explaining the reasons why the procedure
						failed. After that you can upload a new photo and verify it again.
					</p>
				</section>
			</div>
			<UploadPopUp open={open} handleClose={setOpen} />
		</main>
	);
};

export default ModelUploadPicture;
