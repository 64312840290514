import styles from '../styles.module.scss';
import Button from '../../../custom/btn/Btn';
import { ReactComponent as DeleteIcon } from '../../../images/svg/delete.svg';
import classNames from 'classnames';
import ModelEditPopup from '../modelEditPopup/ModelEditPopup';
import Spinner from '../../../custom/spinner/Spinner';
import { useState, useEffect, useCallback } from 'react';
import errorFormmatter from '../../../utils/errorFormatter';
import notification from '../../../utils/notification';
import { useSelector } from 'react-redux';
import PhotoPreview from '../PhotoPreview/ProfilePreview';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FileUpload from '../../../custom/fileUpload/FileUpload';
import {
  getFanClubPhotoSet,
  getFanClubPhotoSetPhotos,
  updateFanClubPhotoSet,
  removeFromPhotoSets,
} from '../../../request/model';

const CreatePhotoSetSchema = Yup.object().shape({
  title: Yup.string().trim().required('title is required'),
  description: Yup.string().trim().required('description is required'),
  isHidden: Yup.bool().required('permission is required'),
  photoSetImage: Yup.string().required('photo set image is required'),
});

const PhotoSet = ({ isSelectedAll, photoSetOptions, newPhotoSet }) => {
  const [loading, setLoading] = useState(false);
  const [openEditPhoto, setOpenEditPhoto] = useState(false);
  const [photoToEdit, setPhotoToEdit] = useState({});
  const [selectedPhotoSetIds, setSelectedPhotoSetIds] = useState([]);
  const [currentEditPhotoSet, setCurrentEditPhotoSet] = useState({});
  const [permission, setPermission] = useState({
    isHidden: currentEditPhotoSet?.isHidden ?? false,
  });

  const [fanClubPhotoSets, setFanClubPhotoSets] = useState([]);
  const clientId = useSelector((state) => state?.user?.clientId);

  const handlePermission = () => {
    setPermission({
      ...permission,
      isHidden: !permission.isHidden,
    });
  };

  const handleClose = () => {
    // setIsOpenUploadPhotoModal(false);
    // setIsOpenEditModelPhoto(false);
    // setPhotoToEdit({});
  };

  const getFanClubPhotoSets = async () => {
    try {
      const response = await getFanClubPhotoSet({ modelId: clientId });
      setFanClubPhotoSets(response.data);
    } catch (error) {
      setFanClubPhotoSets([]);
    }
  };

  const handlePhotoSetSelection = (photoId) => {
    const id = selectedPhotoSetIds.find((id) => id === photoId);
    if (id) {
      const filteredPhotoIds = selectedPhotoSetIds.filter(
        (id) => id !== photoId
      );
      setSelectedPhotoSetIds(filteredPhotoIds);
    } else {
      setSelectedPhotoSetIds((prev) => [...prev, photoId]);
    }
  };

  const handleIsSelected = (photoId) => {
    const id = selectedPhotoSetIds.find((id) => id === photoId);
    return id ? true : false;
  };

  useEffect(() => {
    getFanClubPhotoSets();
  }, []);

  useEffect(() => {
    getFanClubPhotoSets();
  }, [loading]);

  useEffect(() => {
    getFanClubPhotoSets();
  }, [newPhotoSet]);

  useEffect(() => {
    setPermission({
      isHidden: currentEditPhotoSet?.isHidden ?? false,
    });
  }, [currentEditPhotoSet]);

  useEffect(() => {
    if (!fanClubPhotoSets) return;
    if (isSelectedAll) {
      const selectedIds = fanClubPhotoSets?.map(
        (photoSet) => photoSet?.photoSetId
      );
      setSelectedPhotoSetIds(selectedIds);
    } else {
      setSelectedPhotoSetIds([]);
    }
  }, [isSelectedAll]);

  return (
    <div>
      {fanClubPhotoSets.length > 0 ? (
        fanClubPhotoSets.map((photoSet, index) => {
          return (
            <>
              <EditPhotoSet
                photoSetOptions={photoSetOptions}
                key={index}
                handleIsSelected={handleIsSelected}
                handlePhotoSetSelection={handlePhotoSetSelection}
                currentEditPhotoSet={currentEditPhotoSet}
                handleSetCurrentEditPhotoSet={setCurrentEditPhotoSet}
                handlePermission={handlePermission}
                permission={permission}
                handleClose={handleClose}
                photoSet={photoSet}
                handleSetLoading={setLoading}
                loading={loading}
                photoToEdit={photoToEdit}
                openEditPhoto={openEditPhoto}
                setPhotoToEdit={setPhotoToEdit}
                setOpenEditPhoto={setOpenEditPhoto}
              />
            </>
          );
        })
      ) : (
        <div className={styles.emptyFanClubPhotoSetPhotos}>
          {' '}
          No photo set available
        </div>
      )}
    </div>
  );
};

const EditPhotoSet = ({
  handleClose,
  handlePermission,
  photoSet,
  permission,
  handleSetCurrentEditPhotoSet,
  currentEditPhotoSet,
  handleSetLoading,
  loading,
  handlePhotoSetSelection,
  handleIsSelected,
  setPhotoToEdit,
  setOpenEditPhoto,
  openEditPhoto,
  photoToEdit,
  photoSetOptions,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [fanClubPhotoSetPhotos, setFanClubPhotoSetPhotos] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const clientId = useSelector((state) => state?.user?.clientId);

  const getFanClubPhotoSetPhoto = useCallback(
    async (photoSetId) => {
      try {
        const response = await getFanClubPhotoSetPhotos({
          modelId: clientId,
          photoSetId,
        });
        setFanClubPhotoSetPhotos(response?.data[0]?.photosInPhotoSet ?? []);
      } catch (error) {
        setFanClubPhotoSetPhotos([]);
      }
    },
    [isLoading]
  );

  const handleUpdatePhotoSet = async (values) => {
    try {
      const payload = new FormData();

      payload.append('registeredId', clientId);
      payload.append('photoSetName', values.title);
      payload.append('isHidden', permission.isHidden);
      payload.append('photoSetDescription', values.description);
      payload.append('addPhotoSetCoverImage', values.photoSetImage);
      payload.append('photoSetId', photoSet?.photoSetId);
      setIsLoading(true);
      handleSetLoading(true);
      const response = await updateFanClubPhotoSet({ payload });
      setIsLoading(false);
      handleSetLoading(false);
      handleClose();
      notification({
        message: response?.message ?? 'Fan club photo set saved successfully',
      });
    } catch (error) {
      notification({
        message: errorFormmatter(error) ?? 'Fan club photo set  failed to save',
        type: 'danger',
      });
      setIsLoading(false);
      handleSetLoading(false);
    }
  };

  const deletePhotoSetPhoto = async (id) => {
    try {
      setIsLoading(true);
      const response = await removeFromPhotoSets({
        modelId: clientId,
        photoId: id,
      });
      setIsLoading(false);
      notification({
        message:
          response?.message ?? 'Fan club photo set photo deleted successfully',
      });
    } catch (error) {
      notification({
        message:
          errorFormmatter(error) ?? 'Fan club photo set photo failed to delete',
        type: 'danger',
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getFanClubPhotoSetPhoto(photoSet?.photoSetId);
  }, []);

  useEffect(() => {
    getFanClubPhotoSetPhoto(photoSet?.photoSetId);
  }, [openEditPhoto, isLoading]);

  return (
    <>
      <section className={styles.beachSessionContainer}>
        <div className={styles.beachSessionDetailContainer}>
          <img src={photoSet?.photoSetCoverURL} />

          <div>
            <h1 className={styles.beachTitle}>{photoSet?.photoSetName}</h1>
            <ul className={styles.beachDetails}>
              <li>{fanClubPhotoSetPhotos?.length ?? 0} photos</li>
            </ul>
          </div>
        </div>
        <div className={styles.beachSessionActionContainer}>
          {/* <input
            type='checkbox'
            className={styles.checkbox}
            onChange={() => handlePhotoSetSelection(photoSet?.photoSetId)}
            checked={handleIsSelected?.(photoSet?.photoSetId)}
          /> */}
          <Button
            text='Edit photo set'
            icon={DeleteIcon}
            onClick={() => {
              handleSetCurrentEditPhotoSet(photoSet);
              setOpenEditModal(!openEditModal);
            }}
          />
        </div>
      </section>
      <>
        {openEditModal && (
          <section className={styles.nyEditWrapperContainer}>
            <Formik
              enableReinitialize={true}
              validationSchema={CreatePhotoSetSchema}
              initialValues={{
                isHidden: currentEditPhotoSet?.isHidden,
                title: currentEditPhotoSet?.photoSetName,
                description: currentEditPhotoSet?.photoSetDescription,
                photoSetImage: currentEditPhotoSet?.photoSetCoverURL,
              }}
              onSubmit={handleUpdatePhotoSet}
            >
              {(props) => {
                return (
                  <section className={styles.nyContainer}>
                    <FileUpload
                      handleFileChange={(file) => {
                        props.setFieldValue('photoSetImage', file);
                      }}
                      renderElement={
                        <div className={styles.photoSetCoverImageContainer}>
                          {typeof props.values.photoSetImage === 'object' ? (
                            <img
                              src={URL.createObjectURL(
                                props.values.photoSetImage
                              )}
                            />
                          ) : (
                            <img src={props.values.photoSetImage} />
                          )}
                          {props.touched && props.errors?.photoSetImage && (
                            <span className={styles.error}>
                              * {props.errors?.photoSetImage}
                            </span>
                          )}
                        </div>
                      }
                    />

                    <div className={styles.nyDetailWrapper}>
                      <section className={styles.nyTopContainer}>
                        <h1 className={styles.nyTitle}>{props.values.title}</h1>
                        <div className={styles.actionButtonContainer}>
                          {isLoading && <Spinner />}
                          <Button
                            text='Cancel'
                            className={styles.cancelButton}
                            onClick={() => setOpenEditModal(!openEditModal)}
                          />
                          <Button
                            text='Save'
                            className={styles.saveButton}
                            onClick={props.handleSubmit}
                          />
                        </div>
                      </section>

                      <div className={styles.nyActionContainer}>
                        <label className={styles.nyLabel}>
                          <span> Permissions</span>
                          <ul className={styles.nyPermissions}>
                            <li
                              onClick={handlePermission}
                              className={classNames(
                                styles.nyPermission,
                                permission.isHidden && styles.nyActive
                              )}
                            >
                              Hidden
                            </li>
                          </ul>
                          {props.touched && props.errors?.isHidden && (
                            <span className={styles.error}>
                              * {props.errors?.isHidden}
                            </span>
                          )}
                        </label>
                      </div>
                      <label className={styles.nyLabel}>
                        <span>Title</span>
                        <input
                          defaultValue={currentEditPhotoSet?.photoSetName}
                          className={styles.input}
                          placeholder='Title'
                          name='title'
                          onChange={props.handleChange}
                        />
                        {props.touched && props.errors?.title && (
                          <span className={styles.error}>
                            * {props.errors?.title}
                          </span>
                        )}
                      </label>

                      <label className={styles.nyLabel}>
                        <span>Description</span>
                        <textarea
                          defaultValue={
                            currentEditPhotoSet?.photoSetDescription
                          }
                          name='description'
                          onChange={props.handleChange}
                          className={styles.textarea}
                          placeholder='It`s my first travel to beach. And I prepared for you something special'
                        ></textarea>
                        {props.touched && props.errors?.description && (
                          <span className={styles.error}>
                            * {props.errors?.description}
                          </span>
                        )}
                      </label>
                    </div>
                  </section>
                );
              }}
            </Formik>
            <section className={styles.nySetupContainer}>
              {fanClubPhotoSetPhotos.length > 0 ? (
                <section className={styles.photoContainer}>
                  {fanClubPhotoSetPhotos?.map((photo) => (
                    <PhotoPreview
                      key={photo?.imageURL}
                      src={photo?.imageURL}
                      photoId={photo?.photoId}
                      photo={photo}
                      deletePhoto={() => deletePhotoSetPhoto(photo?.photoId)}
                      handleOpenEditPhotoModal={() => {
                        setPhotoToEdit(photo);
                        setOpenEditPhoto(true);
                      }}
                      handlePhotoSelection={handlePhotoSetSelection}
                      isSelected={handleIsSelected}
                    />
                  ))}
                </section>
              ) : (
                <div className={styles.emptyFanClubPhotoSetPhotos}>
                  No available photos
                </div>
              )}
            </section>
          </section>
        )}
      </>
      <ModelEditPopup
        photoSetOptions={photoSetOptions}
        open={openEditPhoto}
        onClose={() => setOpenEditPhoto(false)}
        photo={photoToEdit}
        key={photoSet?.photoSetId}
      />
    </>
  );
};

export default PhotoSet;
