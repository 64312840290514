import Text from '@tiptap/extension-text';
import Paragraph from '@tiptap/extension-paragraph';
import Document from '@tiptap/extension-document';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Underline from '@tiptap/extension-underline';
import Heading from '@tiptap/extension-heading';
import ListItem from '@tiptap/extension-list-item';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import Image from '@tiptap/extension-image';
import TextAlign from '@tiptap/extension-text-align';
import CharacterCount from '@tiptap/extension-character-count';
import TextStyle from '@tiptap/extension-text-style';

export const getExtensions = () => {
  return [
    Document,
    Paragraph,
    Text,
    TextStyle,
    Underline,
    Bold.configure({
      HTMLAttributes: {
        class: 'tiptap_bold',
      },
    }),
    Italic.configure({
      HTMLAttributes: {
        class: 'tiptap_italize',
      },
    }),
    Heading.configure({
      levels: [1, 2, 3],
    }),
    TextAlign.configure({
      types: ['heading', 'paragraph'],
    }),
    BulletList.configure({
      keepMarks: true,
    }),
    OrderedList.configure({
      keepMarks: true,
    }),
    ListItem,
    CharacterCount,
    Image.configure({
      allowBase64: true,
      inline: true,
    }),
  ];
};
