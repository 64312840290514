import styles from './index.module.scss';
import classNames from 'classnames';
import { useMemo } from 'react';

const Switch = ({
  containerClass,
  onChange,
  checkedColor = '',
  sliderClass,
  ...props
}) => {
  console.log(checkedColor);

  const sliderBgClass = `peer-checked:!bg-[${checkedColor}]`;
  return (
    <>
      <label className={styles.switch}>
        <input
          type='checkbox'
          onChange={onChange}
          {...props}
          className='peer'
        />
        <span
          className={classNames(
            styles.slider,
            styles.round,
            containerClass,
            sliderBgClass
          )}
        ></span>
      </label>
    </>
  );
};

export default Switch;
