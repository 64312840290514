import styles from '../live-streaming.module.scss';
import { useState } from 'react';
import Participants from '../participants/Participants';
import { HiDotsVertical } from 'react-icons/hi';

const BroadcastEdit = ({
  className,
  item,
  itemIndex,
  props,
  methods,
  handleOpenParticipants,
  openParticipants,
  handleCloseParticipants,
  participants,
  handleOpenChangeTopic,
  handleOpenChangeRoom,
  startPrivateCallFromParticipantModal,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <div key={itemIndex} className={styles.dropDown}>
      <span className={styles.dropDownItem}>
        {item[props.labelField]}{' '}
        <span className={styles.groupIcon}>
          <HiDotsVertical
            onClick={() => {
              handleOpen();
            }}
          />
        </span>
        {open && (
          <ul className={styles.dropDownOptionContainer}>
            <li
              className={styles.dropDownOption}
              onClick={() => {
                handleOpenParticipants();
                setOpen(!open);
              }}
            >
              List of participants
            </li>
            <li
              className={styles.dropDownOption}
              onClick={() => {
                handleOpenChangeRoom();
                setOpen(!open);
              }}
            >
              Rename room
            </li>
            <li
              className={styles.dropDownOption}
              onClick={() => {
                handleOpenChangeTopic();
                setOpen(!open);
              }}
            >
              Rename topic
            </li>
          </ul>
        )}
      </span>
      <Participants
        startPrivateCallFromParticipantModal={
          startPrivateCallFromParticipantModal
        }
        participants={participants}
        open={openParticipants}
        handleCloseParticipants={handleCloseParticipants}
      />
    </div>
  );
};

export default BroadcastEdit;
