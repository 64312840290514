import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Scrollbar from 'react-scrollbars-custom';
import { nanoid } from 'nanoid';
import Upload from 'rc-upload';
import { deleteMemberAccount } from '../../request/member.js';
import { useSelector } from 'react-redux';
import LkEditMail from '../popup/LkEditMail';
import LkEditPassword from '../popup/LkEditPassword';
import LkEditDelAcc from '../popup/LkEditDelAcc';
import notification from '../../utils/notification';
import errorFormmatter from '../../utils/errorFormatter';
import App from './App.tsx';
import { useLocation } from 'react-router-dom';
import { useActionCreator } from '../../hooks/useActionCreators.js';

const LkEdit = () => {
  const { logOut } = useActionCreator();
  const [deleting, setDeleting] = useState(false);
  const [open, setOpenModal] = useState(false);
  const [countries, setCountries] = useState([]);
  const [gender, setGender] = useState('');
  const [countryId, setCountryId] = useState(0);
  const [country, setCountry] = useState('');
  const [date, setDate] = useState('');
  const [fullName, setFullName] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [file, setFile] = useState('');
  const [filename, setFilename] = useState(() =>
    file ? 'profile uploaded' : null
  );
  const [loading, setLoading] = useState(false);
  const [memberProfileLoading, setMemberProfileLoading] = useState(false);
  const clientId = useSelector((state) => state?.user?.clientId);

  const deleteAccount = async () => {
    try {
      setDeleting(true);
      const response = await deleteMemberAccount(clientId);

      notification({
        title: 'Profile Edit',
        message: response?.data?.message,
        type: 'success',
      });
      setDeleting(false);
      logOut();
    } catch (error) {
      setDeleting(false);
      notification({
        title: 'Profile Edit',
        message: errorFormmatter(error),
        type: 'danger',
      });
    }
  };

  const getCountries = async () => {
    try {
      const { data } = await axios.get('/SetUp/GetAllCountries');
      setCountries(data?.models);
    } catch (error) {
      notification({
        title: 'Data Fetching Error',
        message: errorFormmatter(error),
        type: 'danger',
      });
    }
  };

  useEffect(() => {
    getCountries();
  }, []);

  const getMemberProfile = async () => {
    setMemberProfileLoading(true);

    try {
      const {
        data: {
          data: {
            country,
            fullName,
            gender,
            dateOfBirth,
            displayName,
            imageBase64,
          },
        },
      } = await axios.get(`/MemberProfile/${clientId}/GetMemberByMemberId`);

      setCountry(country?.countryName);
      setCountryId(country?.countryId);
      setGender(gender);
      setDate(() => dateOfBirth?.slice(0, 10));
      setFullName(fullName?.toUpperCase());
      setDisplayName(displayName?.toUpperCase());
      setFilename(() => (imageBase64 ? 'profile uploaded' : null));
      setFile(imageBase64);
    } catch (error) {
      return false;
    } finally {
      setMemberProfileLoading(false);
    }
  };

  useEffect(() => {
    getMemberProfile();
  }, []);

  const addActiveClassToDropdowns = (e) => {
    e.currentTarget.classList.toggle('reg-data-select__active');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const formData = new FormData();

    formData.append('CountryId', countryId);
    formData.append('Gender', gender);
    formData.append('DateOfBirth', date);
    formData.append('FullName', fullName);
    formData.append('DisplayName', displayName);
    formData.append('RegisterId', clientId);

    // check if a user has changed profile pics
    if (typeof file !== 'string') {
      formData.append('FileUpload', file);
    }

    try {
      const { data } = await axios.post(
        '/MemberProfile/RegisterMember',
        formData
      );
      const {
        data: {
          country,
          fullName,
          gender,
          dateOfBirth,
          displayName,
          imageBase64,
        },
      } = data;

      setCountry(country?.countryName);
      setCountryId(country?.countryId);
      setGender(gender);
      setDate(() => dateOfBirth?.slice(0, 10));
      setFullName(fullName?.toUpperCase());
      setDisplayName(displayName?.toUpperCase());
      setFilename(() => (imageBase64 ? 'profile uploaded' : null));
      setFile(imageBase64);

      notification({
        title: 'Profile Edit Success',
        message: data?.message,
        type: 'success',
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notification({
        title: 'Profile Edit Error',
        message: errorFormmatter(error),
        type: 'danger',
      });
    }
  };

  const handleSetCountry = ({ countryId, countryName }) => {
    setCountryId(countryId);
    setCountry(countryName);
  };

  // map options for list of coutries dropdown
  const countryOptions =
    countries?.length > 0 &&
    countries?.map((country) => (
      <div
        className='reg-data-select-list--item'
        onClick={() => handleSetCountry(country)}
        key={nanoid()}
        style={{
          display: 'flex',
          paddingInline: '1rem',
          gap: '.1rem',
          alignItems: 'center',
        }}
      >
        <img
          src={country?.countryFlagUrl}
          alt='country flag'
          style={{
            width: '25px',
            height: '25px',
            aspectRatio: '1/1',
            objectFit: 'contain',
          }}
        />
        {country?.countryName}
      </div>
    ));

  // handle profile picture upload
  const onStart = (file) => {
    const filename = file.name?.slice(0, 10)?.padEnd(13, '...');
    setFile(file);
    setFilename(filename);
  };

  return (
    <main>
      {memberProfileLoading ? (
        <section
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '40vh',
          }}
        >
          loading ...
        </section>
      ) : (
        <section className='reg-data reg-data__member editProfile'>
          {/* add class sentEmail if success edit */}
          {/* add class successfully sent email */}
          <div className='container'>
            <div className='reg-data--subtitle'>
              <ul className='breadcrumb'>
                <li>
                  <Link to='/lk'>
                    <span>Personal account</span>
                  </Link>
                </li>
                <li>
                  <span>Edit profile</span>
                </li>
              </ul>
            </div>
            <div className='page-titleWrapper'>
              <div className='page-title'>Edit profile</div>
              <LkEditDelAcc
                deleting={deleting}
                deleteAccount={deleteAccount}
                closeModal={() => setOpenModal(false)}
                openModal={() => setOpenModal(true)}
                open={open}
              />
            </div>
            <div className='reg-data-wrapper'>
              <div className='reg-data-wrapper--left'>
                <form onSubmit={handleSubmit}>
                  <div className='reg-data-row'>
                    <div className='reg-data-left'>
                      <div className='reg-data-row--title'>
                        <span>Account settings</span>
                      </div>
                      <div className='reg-data-row--subtitle'>
                        Information for the site administration it <br />
                        will not be shown to site visitors
                      </div>
                    </div>
                    <div className='reg-data-right'>
                      <div className='reg-data-input'>
                        <input
                          type='text'
                          name='FullName'
                          onChange={(e) => setFullName(e.target.value)}
                          value={fullName}
                        />
                        <span className='reg-data-input--placeholder'>
                          Full Name
                        </span>
                      </div>
                      <div className='reg-data-input'>
                        <input
                          type='text'
                          name='DisplayName'
                          onChange={(e) => setDisplayName(e.target.value)}
                          value={displayName}
                        />
                        <span className='reg-data-input--placeholder'>
                          Display Name
                        </span>
                      </div>
                      <LkEditMail />
                      <div className='reg-data-input'>
                        <Upload onStart={onStart} accept='image/*'>
                          <input
                            placeholder={filename}
                            type='text'
                            name='upload profile pics'
                            onChange={(e) => setDisplayName(e.target.value)}
                            value={filename}
                            style={{ appearance: 'none' }}
                            disabled
                            readOnly='readOnly'
                          />
                          <span className='reg-data-input--placeholder'>
                            {file || filename
                              ? 'File uploaded'
                              : 'Upload profile pics'}
                          </span>
                        </Upload>
                      </div>{' '}
                      <LkEditPassword clientid={clientId} />
                    </div>
                  </div>
                  {/* <div className='reg-data-row editProfile-sent'>
									<div className='editProfile-sentEmail'>
										An email has been sent to the specified email address. Follow the link in the email to confirm the
										changes
									</div>
								</div> */}
                  <div className='reg-data-row'>
                    <div className='reg-data-left'>
                      <div className='reg-data-row--title'>
                        <span>Personal data</span>
                      </div>
                      <div className='reg-data-row--subtitle'>
                        Completed information will be displayed <br />
                        in the profile and see for users
                      </div>
                    </div>
                    <div className='reg-data-right'>
                      <div className='reg-data-input'>
                        <div
                          className='reg-data-select'
                          onClick={addActiveClassToDropdowns}
                        >
                          <input
                            placeholder='Gender'
                            type='text'
                            readOnly='readOnly'
                            value={gender}
                          />
                          <div className='reg-data-select-list'>
                            <Scrollbar style={{ height: 144 }}>
                              <div
                                className='reg-data-select-list--item'
                                onClick={(e) => setGender(e.target.textContent)}
                              >
                                Male
                              </div>
                              <div
                                className='reg-data-select-list--item'
                                onClick={(e) => setGender(e.target.textContent)}
                              >
                                Female
                              </div>
                              <div
                                className='reg-data-select-list--item'
                                onClick={(e) => setGender(e.target.textContent)}
                              >
                                TransGender
                              </div>
                              <div
                                className='reg-data-select-list--item'
                                onClick={(e) => setGender(e.target.textContent)}
                              >
                                indefinite
                              </div>
                            </Scrollbar>
                          </div>
                        </div>
                      </div>
                      <div className='reg-data-input'>
                        <div
                          className='reg-data-select'
                          onClick={addActiveClassToDropdowns}
                        >
                          <input
                            placeholder='Country'
                            type='text'
                            readOnly='readOnly'
                            value={country}
                          />
                          <div className='reg-data-select-list'>
                            <Scrollbar style={{ height: 384 }}>
                              {countryOptions}
                            </Scrollbar>
                          </div>
                        </div>
                      </div>
                      <div className='reg-data-input reg-data-input-picker'>
                        <React.StrictMode>
                          <App hanleDateChange={setDate} value={date} />
                        </React.StrictMode>
                      </div>
                    </div>
                  </div>
                  <button
                    className='btn btn__red'
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    {loading ? 'Saving...' : 'Save'}
                  </button>
                </form>
              </div>
              <div className='warningInfo'>
                <div className='warningInfo-text' />
              </div>
              <div className='reg-data-wrapper--right '>
                <div className='reg-data-vip--title'>Account status</div>
                <div className='reg-data-vip--status'>Free account</div>
                <div className='reg-data-vip-buy !h-[max-content]'>
                  <div className='reg-data-vip-buy--inner'>
                    <div className='reg-data-vip-buy--title'>
                      Premium account
                    </div>
                    <div className='reg-data-vip-buy--subtitle'>
                      Monthly subscribtion
                    </div>
                    <div className='reg-data-vip-buy--price'>
                      <strong>+5.99</strong>
                      <span>
                        and enhance <br />
                        your experience
                      </span>
                    </div>
                    {/* <div className='btn btn__purple'>Activate subscription</div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </main>
  );
};;
// reportWebVitals();

export default LkEdit;

/* <<<<<<< HEAD
import React, { Component} from 'react';
import {Link} from "react-router-dom";
import Scrollbar from 'react-scrollbars-custom';
import DateOfBirth from "../components/date/DateOfBirth";

import LkEditMail from '../popup/LkEditMail';
import LkEditPassword from '../popup/LkEditPassword';
import LkEditDelAcc from '../popup/LkEditDelAcc';

// import './index.css';
import App from './datePicker.js';
//import reportWebVitals from 'reportWebVitals.tsx';

class LkEdit extends Component {
    constructor(props) {
        super(props)
        this.state = {  open: false }
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }
    openModal (){
        this.setState({ open: true })
    }
    closeModal () {
        this.setState({ open: false })
    }
    componentDidMount () {
        // Кастомный селект
        document.querySelectorAll('.reg-data-select').forEach(function(element) {
            element.onclick = function() {
                this.classList.toggle('reg-data-select__active');
            };
        });
        // Выбор в кастомном селекте
        document.querySelectorAll('.reg-data-select-list--item').forEach(function(element) {
            element.onclick = function() {
                let textContent = this.textContent;
                this.closest('.reg-data-select').querySelector('input').value = textContent;
            };
        });
    }
    render() {
        return (
            <main>
                <section className="reg-data reg-data__member editProfile">
                {/* add class sentEmail if success edit */ //}
//{/* add class successfully sent email */}
/*<div className="container">
								<div className="reg-data--subtitle">
										<ul className="breadcrumb">
												<li>
														<Link to="/lk"><span>Personal account</span></Link>
												</li>
												<li>
														<span>Edit profile</span>
												</li>
										</ul>
								</div>
								<div className="page-titleWrapper">
										<div className="page-title">
												Edit profile
										</div>
										<LkEditDelAcc/>
								</div>
								<div className="reg-data-wrapper">
										<div className="reg-data-wrapper--left">
												<form>
														<div className="reg-data-row">
																<div className="reg-data-left">
																		<div className="reg-data-row--title">
																				<span>Account settings</span>
																		</div>
																		<div className="reg-data-row--subtitle">
																				Information for the site administration it <br/>
																				will not be shown to site visitors
																		</div>
																</div>
																<div className="reg-data-right">
																		<div className="reg-data-input">
																				<input placeholder="" type="text" name="username" defaultValue="Alloress"/>
																				<span className="reg-data-input--placeholder">Username</span>
																		</div>
																		<div className="reg-data-input">
																				<input placeholder="" type="text" name="name" defaultValue="Alloress"/>
																				<span className="reg-data-input--placeholder">Display Name</span>
																		</div>
																		<LkEditMail/>
																		<LkEditPassword/>
																</div>
														</div>
														<div className="reg-data-row editProfile-sent">
																<div className="editProfile-sentEmail">
																		An email has been sent to the specified email address. Follow the link in the email to confirm the changes
																</div>
														</div>
														<div className="reg-data-row">
																<div  className="reg-data-left">
																		<div className="reg-data-row--title">
																				<span>Personal data</span>
																		</div>
																		<div className="reg-data-row--subtitle">
																				Completed information will be displayed <br/>
																				in the profile and see for users
																		</div>
																</div>
																<div className="reg-data-right">
																		<div className="reg-data-input">
																				<div className="reg-data-select">
																						<input placeholder="Gender" type="text" readOnly="readOnly"/>
																						<div className="reg-data-select-list">
																								<Scrollbar style={{ height: 144 }}>
																										<div className="reg-data-select-list--item">
																												Male
																										</div>
																										<div className="reg-data-select-list--item">
																												Female
																										</div>
																										<div className="reg-data-select-list--item">
																												indefinite
																										</div>
																								</Scrollbar>
																						</div>
																				</div>
																		</div>
																		<div className="reg-data-input">
																				<div className="reg-data-select">
																						<input placeholder="Country" type="text" readOnly="readOnly"/>
																						<div className="reg-data-select-list">
																								<Scrollbar style={{ height: 384 }}>
																										<div className="reg-data-select-list--item">
																												A
																										</div>
																										<div className="reg-data-select-list--item">
																												B
																										</div>
																										<div className="reg-data-select-list--item">
																												C
																										</div>
																										<div className="reg-data-select-list--item">
																												D
																										</div>
																										<div className="reg-data-select-list--item">
																												E
																										</div>
																										<div className="reg-data-select-list--item">
																												A
																										</div>
																										<div className="reg-data-select-list--item">
																												B
																										</div>
																										<div className="reg-data-select-list--item">
																												C
																										</div>
																										<div className="reg-data-select-list--item">
																												D
																										</div>
																										<div className="reg-data-select-list--item">
																												E
																										</div>
																								</Scrollbar>
																						</div>
																				</div>
																		</div>
																		<div className="reg-data-input reg-data-input-picker">
																				<React.StrictMode>
																						<App />
																				</React.StrictMode>
																		</div>
																</div>
														</div>
														<button className="btn btn__red">
																Save
														</button>
												</form>
										</div>
										<div className="warningInfo">
												<div className="warningInfo-text">

												</div>
										</div>
										<div className="reg-data-wrapper--right">
												<div className="reg-data-vip--title">
														Account status
												</div>
												<div className="reg-data-vip--status">
														Free account
												</div>
												<div className="reg-data-vip-buy">
														<div className="reg-data-vip-buy--inner">
																<div className="reg-data-vip-buy--title">Premium account</div>
																<div className="reg-data-vip-buy--subtitle">Monthly subscribtion</div>
																<div className="reg-data-vip-buy--price">
																		<strong>+5.99</strong>
																		<span>
																				and enhance <br/>
																				your experience
																		</span>
																</div>
																<div className="btn btn__purple">Activate subscription</div>
														</div>
												</div>
										</div>
								</div>
						</div>
				</section>
		</main>
)
}
//}
// reportWebVitals();

//export default LkEdit;
//======= 
>>>>>>> 59d03e77e78a8be035b39ba65f4989f1c2ae5150*/
