import styles from './styles.module.scss';
import classnames from 'classnames';

const Btn = ({ className, icon: Icon, text, iconClass, onClick, disabled = false, ...props }) => {
	return (
		<button
			className={classnames(styles.btn, className)}
			onClick={() => {
				if (!disabled && typeof onClick === 'function') {
					onClick();
				}
			}}
			{...props}
		>
			{Icon && <Icon className={classnames(styles.icon, iconClass)} />}
			{typeof text === 'function' ? text() : text}
		</button>
	);
};

export default Btn;
