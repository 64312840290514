import styles from './payout.module.scss';
import ModelProfileHeader from '../../custom/modelProfileHeader/ModelProfileHeader';
import FluidContainer from '../../custom/fluidContainer/FluidContainer';
import DatePicker from '../../custom/datePicker/DatePicker';
import Button from '../../custom/btn/Btn';
import Pagination from '../../custom/pagination/Pagination';
import GridTable from './table/Table';
import { ReactComponent as DropIcon } from '../../svgs/drop-icon.svg';
import Tab from '../../custom/Tabs/Tabs';
import AnalyzeCard from './AnalyzeCard/AnalyzeCard';
import DropDown from '../../custom/DropDown/DropDown';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { ReactComponent as PayoutICon } from '../../svgs/payout-icon.svg';
import { ReactComponent as PaidICon } from '../../svgs/paid-icon.svg';
import { ReactComponent as RequestICon } from '../../svgs/request-icon.svg';
import { ReactComponent as RequestPaymentICon } from '../../svgs/request-payment-icon.svg';
import RequestPayment from './RequestPayment/RequestPayment';
import { getModelPayouts } from '../../request/model';
import { useSelector } from 'react-redux';
import Spinner from '../../custom/spinner/Spinner';
import moment from 'moment';
import { format } from 'currency-formatter';

const tableHeader = [
	{
		text: 'Date',
	},
	{
		text: 'Earned',
		subText: 'credits',
	},

	{
		text: 'Comment',
	},
];

const currentDate = moment();
const today = moment().format('YYYY-MM-DD');
const thisMonth = moment().format('MMMM');
const thisYear = moment().format('YYYY');
const lastMonth = moment()
	.subtract(1, 'months')
	.format('MMMM');

const Payout = () => {
	const clientId = useSelector(state => state?.user?.clientId);
	const [tabIndex, setTabIndex] = useState(0);
	const [openRequestPayment, setOpenRequestPayment] = useState(false);
	const [startDate, setStartDate] = useState(today);
	const [endDate, setEndDate] = useState(today);
	const [dynamicDate, setDynamicDate] = useState('');

	const params = `${startDate}${endDate}`;

	const { data, error, isFetching } = useQuery(
		['model-payouts', params, tabIndex],
		() => getModelPayouts({ modelId: clientId, startDate, endDate, pageNumber: 1 }),
		{
			refetchOnWindowFocus: false,
			retry: 0,
		}
	);

	const openForPayout = data?.requestPayoutDashBoard?.openForPayout;
	const requestForPayout = data?.requestPayoutDashBoard?.requestForPayout;
	const totalPayout = data?.requestPayoutDashBoard?.totalPayout;

	let payoutHistoryList =
		(data?.data &&
			Array.isArray(data?.data) &&
			data?.data?.map(payoutHistory => {
				return [
					{ text: moment(new Date(payoutHistory?.todayTransaction)).format('YYYY/MM/DD') },
					{ text: format(payoutHistory?.amount, { code: 'USD' }) },
					{ text: payoutHistory?.description },
				];
			})) ||
		[];

	const errorMessage = error?.response?.data?.message ?? error?.message;

	const handleOpenRequestPayment = data => {
		setOpenRequestPayment(true);
	};

	const handleCloseRequestPayment = data => {
		setOpenRequestPayment(false);
	};

	const setDate = keyIndex => {
		let startDate, endDate;

		if (keyIndex === 0) {
			startDate = moment().format('YYYY-MM-DD');
			endDate = moment().format('YYYY-MM-DD');
		}

		if (keyIndex === 1) {
			startDate = currentDate
				.clone()
				.startOf('month')
				.format('YYYY-MM-DD');

			endDate = currentDate
				.clone()
				.endOf('month')
				.format('YYYY-MM-DD');
		}

		if (keyIndex === 2) {
			startDate = currentDate
				.clone()
				.subtract(1, 'months')
				.startOf('month')
				.format('YYYY-MM-DD');

			endDate = currentDate
				.clone()
				.subtract(1, 'months')
				.endOf('month')
				.format('YYYY-MM-DD');
		}

		if (keyIndex === 3) {
			startDate = currentDate
				.clone()
				.startOf('year')
				.format('YYYY-MM-DD');

			endDate = currentDate
				.clone()
				.endOf('year')
				.format('YYYY-MM-DD');
		}

		if (keyIndex === 4) {
			startDate = moment(new Date('1909-08-13')).format('YYYY-MM-DD');
			endDate = moment().format('YYYY-MM-DD');
		}

		setStartDate(startDate);
		setEndDate(endDate);
		setTabIndex(keyIndex);
	};

	const dateChange = date => {
		setDynamicDate(moment(date).format('YYYY-MM-DD'));
	};

	const handleFind = () => {
		setStartDate(dynamicDate);
		setEndDate(dynamicDate);
	};

	return (
		<FluidContainer>
			<ModelProfileHeader />

			<RequestPayment
				openPayment={openRequestPayment}
				handleOpenRequestPayment={handleOpenRequestPayment}
				handleCloseRequestPayment={handleCloseRequestPayment}
			/>

			<section className={styles.container}>
				<div className={styles.headerContainer}>
					<h1 className={styles.header}>
						<span className={styles.subTitle}>Billing</span>
						<span className={styles.title}>Payout</span>
					</h1>
					<Button
						onClick={handleOpenRequestPayment}
						iconClass={styles.iconClass}
						text='Request Payout'
						icon={RequestPaymentICon}
						className={styles.requestButton}
					/>
				</div>

				<section className={styles.analyzeContainer}>
					<AnalyzeCard type={'Open for payout'} text={format(openForPayout, { code: 'USD' })} icon={<PayoutICon />} />
					<AnalyzeCard type={'Total paid'} text={format(totalPayout, { code: 'USD' })} icon={<PaidICon />} />
					<AnalyzeCard
						type={'Requested for payout'}
						text={format(requestForPayout, { code: 'USD' })}
						icon={<RequestICon />}
					/>
				</section>

				<h2 className={styles.historyTitle}>History</h2>

				<section className={styles.subHeader}>
					<DatePicker
						onChange={dateChange}
						renderElement={({ value, onClick }) => (
							<section className={styles.datePickerContainer} onClick={onClick}>
								<div className={styles.datePickerLeftContainer}>
									<span>{value}</span>
								</div>
								<div className={styles.datePickerRightContainer}>
									<span>Date from</span>
									<DropIcon />
								</div>
							</section>
						)}
					/>

					<Button text='Find' className={styles.findButton} onClick={handleFind} />
				</section>
				{isFetching ? (
					<Spinner />
				) : errorMessage ? (
					<p className='error'>{errorMessage}</p>
				) : (
					<Tab
						panelClass={styles.tabContainer}
						containerClass={styles.navContainer}
						tabClass={styles.navList}
						position='left'
						defaultKeyIndex={tabIndex}
						onIndexChange={setDate}
						items={[
							{
								keyIndex: 0,
								key: 'Today',
								children: (
									<div className={styles.table}>
										<GridTable tableHeader={tableHeader} data={payoutHistoryList} />
									</div>
								),
							},
							{
								keyIndex: 1,
								key: `This month — ${thisMonth}`,
								children: (
									<div className={styles.table}>
										<GridTable tableHeader={tableHeader} data={payoutHistoryList} />
									</div>
								),
							},
							{
								keyIndex: 2,
								key: <span className={styles.midList}>Last month — {lastMonth}</span>,
								children: (
									<div className={styles.table}>
										<GridTable tableHeader={tableHeader} data={payoutHistoryList} />
									</div>
								),
							},
							{
								keyIndex: 3,
								key: `This year — ${thisYear}`,
								children: (
									<div className={styles.table}>
										<GridTable tableHeader={tableHeader} data={payoutHistoryList} />
									</div>
								),
							},
							{
								keyIndex: 4,
								key: 'All time',
								children: (
									<div className={styles.table}>
										<GridTable tableHeader={tableHeader} data={payoutHistoryList} />
									</div>
								),
							},
						]}
					/>
				)}
			</section>
		</FluidContainer>
	);
};

export default Payout;
