import styles from './analyzeCard.module.scss';

const AnalyzeCard = ({ type = '', text = '' }) => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{type}</h1>
      <p className={styles.text}>{text}</p>
    </div>
  );
};

export default AnalyzeCard;
