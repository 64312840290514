import React, { Component } from 'react';

class Privacy extends Component {
	componentDidMount() {
		document.querySelector('body').classList.remove('main-page');

		document.querySelectorAll('.info-page--paragraph').forEach(function(element) {
			element.onclick = function() {
				this.classList.toggle('show');
			};
		});

		//sticky block on scroll
		let a = document.querySelector('.info-page-menu'),
			b = null,
			P = 0;
		window.addEventListener('scroll', Ascroll, false);
		document.body.addEventListener('scroll', Ascroll, false);
		function Ascroll() {
			if (b == null) {
				let Sa = getComputedStyle(a, ''),
					s = '';
				for (let i = 0; i < Sa.length; i++) {
					if (
						Sa[i].indexOf('overflow') === 0 ||
						Sa[i].indexOf('padding') === 0 ||
						Sa[i].indexOf('border') === 0 ||
						Sa[i].indexOf('outline') === 0 ||
						Sa[i].indexOf('box-shadow') === 0 ||
						Sa[i].indexOf('background') === 0
					) {
						s += Sa[i] + ': ' + Sa.getPropertyValue(Sa[i]) + '; ';
					}
				}
				b = document.createElement('div');
				b.style.cssText = s + ' box-sizing: border-box; width: ' + a.offsetWidth + 'px;';
				a.insertBefore(b, a.firstChild);
				let l = a.childNodes.length;
				for (let j = 1; j < l; j++) {
					b.appendChild(a.childNodes[1]);
				}
				a.style.height = b.getBoundingClientRect().height + 'px';
				a.style.padding = '0';
				a.style.border = '0';
			}
			let Ra = a.getBoundingClientRect(),
				R = Math.round(
					Ra.top +
						b.getBoundingClientRect().height -
						document.querySelector('footer.footer').getBoundingClientRect().top +
						0
				);
			if (Ra.top - P <= 0) {
				if (Ra.top - P <= R) {
					b.className = 'stop';
					b.style.top = -R + 'px';
				} else {
					b.className = 'sticky';
					b.style.top = P + 'px';
				}
			} else {
				b.className = '';
				b.style.top = '';
			}
			window.addEventListener(
				'resize',
				function() {
					a.children[0].style.width = getComputedStyle(a, '').width;
				},
				false
			);
		}
		//Anchor Links
		let linkNav = document.querySelectorAll('.info-page-menu ul li a'),
			V = 0.2;
		for (let i = 0; i < linkNav.length; i++) {
			linkNav[i].addEventListener(
				'click',
				function(e) {
					let w = window.pageYOffset,
						hash = this.href.replace(/[^#]*(.*)/, '$1');
					let t = document.querySelector(hash).getBoundingClientRect().top,
						start = null;
					requestAnimationFrame(step);
					function step(time) {
						if (start === null) start = time;
						let progress = time - start,
							r = t < 0 ? Math.max(w - progress / V, w + t) : Math.min(w + progress / V, w + t);
						window.scrollTo(0, r);
						if (r !== w + t) {
							requestAnimationFrame(step);
						} else {
							window.location.hash = hash; // URL с хэшем
						}
					}
				},
				false
			);
		}
	}
	render() {
		return (
			<main className='container privacy-main'>
				<section className='info-page'>
					<div className='info-page--left'>
						<div className='info-page--text'>
							<div className='info-page--title'>Privacy policy</div>
							<div className='info-page--modifed'>Last modified: may 21st, 2018</div>
							<div id='article1' className='info-page--paragraph'>
								<strong>Article 1: Definitions</strong>
								<p>Member/Customer/Subscriber/User – The visitor who signs up to the services of the site.</p>
								<p>
									Adult Service Provider (ASP)/ Performer – An adult entity older than age 18 or 21 in some
									region, who provides shows, chats or interacts with visitors of the site
								</p>
								<p>
									Credit Balance – At NSA1.com, Members have the opportunity of obtaining Credits to pay for
									premium services, by purchasing packages. Payments are securely processed by our payment
									processors, and the purchased Credits consequently appear on the Member’s account as a
									balance.
								</p>
								<p>
									Free Chat – An uninhibited option to interact with Adult Service Providers in a form of
									writing on-screen text messages.
								</p>
								<p>
									Private Chat – In Private Chat, Members are permitted to write, talk (voice communication) or
									video chat with ASPs face-to-face, in exclusion from the general users.
								</p>
							</div>
							<div id='article2' className='info-page--paragraph'>
								<strong>Article 2: NSA1.com Services</strong>
								<p>
									NSA1.com provides an online adult interactive website (including nude and non-nude erotic and
									sexual content). Therefore, its access by minors is prohibited. The access to NSA1.com is not
									recommended to persons who might be sensitive to adult entertainment content, any eventual
									access of NSA1.com by such persons will be their sole responsibility.
								</p>
								<p>
									The adult entertainment provided on the website is rendered by female and male individuals
									over the age of 18 spread worldwide, who are commonly designated Adult Service Providers
									(ASP).
								</p>
								<p>
									The Adult Service Providers (ASP) chat and carry out live webcam shows, in front of their
									camera, for Users all over the world, who have selected them according to live streams,
									images, offline (teaser) and paying videos.
								</p>
								<p>NSA1.com offers ASPs in the following categories:</p>
								<ul>
									<li>
										Girl: One female model online from who you can request a nude, erotic show during Private
										according to her Willingness setting.
									</li>
									<li>
										Lesbians: Two female models online from who you can request a nude, erotic show during
										Private according to their Willingness setting.
									</li>
									<li>
										Mature female: One, at least a 30-year-old model online from who you can request a nude,
										erotic show during Private according to her Willingness setting.
									</li>
									<li>
										Couple: Couples or groups of 3-4 models online from who you can request a nude, erotic
										show during Private according to their Willingness setting.
									</li>
									<li>
										Fetish: One female model online, spiced up with fetish accessories and extreme
										undertakings from who you can request a nude, erotic show during Private according to her
										Willingness setting.
									</li>
									<li>
										Boy: One male model online from who you can request a nude, erotic show during Private
										according to his Willingness setting.
									</li>
									<li>
										Gays: One or more male models online from who you can request a nude, erotic show during
										Private according to his Willingness setting.
									</li>
									<li>
										Transsexual: One or more transsexual models online from whom you can request a nude,
										erotic show during Private according to their Willingness setting.
									</li>
									<li>
										Hot Flirt: No nudity is allowed in the Hot Flirt category; the models must be fully
										dressed at all times.
									</li>
									<li>
										Non-nude: Models of this category are not supposed to provide or imply any sexually
										explicit content, be it written, image or a live camera feed. They chat within the subject
										specified by their subcategory.
									</li>
									<li>
										Free Chat: As a general rule, models are not allowed to provide nudity or any sexually
										explicit content in Free chat area.
									</li>
								</ul>
								<p>
									NSA1.com uses the two most popular industry-standard technologies on its pages for the sake
									of perfectly customized content and to grant the greatest comfort available. At NSA1.com the
									User is a King!
								</p>
								<p>
									NSA1.com is divided into specific areas, which provide free and payable Adult Entertainment
									services. NSA1.com is constantly adding new services in order to accommodate its users&#39;
									requirements.
								</p>
							</div>
							<div id='article3' className='info-page--paragraph'>
								<strong>Article 3: NSA1.com Representations</strong>
								<p>
									Prior to selecting the services intended, it is important to consult the content, price list,
									and means of payment (credit and debit cards, online checks, etc.) established for such
									services for your country. This information is available on the website.{' '}
								</p>
								<p>
									The publication of different content on the website does not mean that NSA1.com supports the
									author or takes the responsibility for such content.
								</p>
								<p>
									NSA1.com makes, to the extent permitted by law, no warranties or representations as to the
									information, services or products provided through or in connection with the service. Users'
									use of the service is at their own risk
								</p>
								<p>
									NSA1.com seeks, at all times, to provide each User with the best content and services
									available. However, it cannot give warranty of merchantability, fitness for any purpose, or
									non-results of the use of the content in terms of their correctness, accuracy, timeliness,
									and reliability or otherwise.
								</p>
								<p>
									NSA1.com nor any party involved in creating, producing, or delivering the service or content
									is liable for any direct, incidental, consequential, indirect or punitive damages arising
									from the access to, use of, or interpretation of, the services, products or information
									provided by or through NSA1.com, without prejudice to the terms established below in the
									present Agreement.
								</p>
								<p>NSA1.com cannot verify the age or identity of NSA1.com Users.</p>
							</div>
							<div id='article4' className='info-page--paragraph'>
								<strong>Article 4: NSA1.com - Policies</strong>
								<p>
									NSA1.com has no intention to support immoral interests; therefore strict rules have been set
									forth.
								</p>
								<p>
									As a Platinum Sponsor Member of the Association of Sites Advocating Child Protection (ASACP)
									and member of the Restricted to Adults Association (RTA), as well as of the Internet Content
									Rating Association (ICRA), NSA1.com is committed to the protection of underage individuals.
								</p>
								<p>
									NSA1.com's services are only available for persons over the age of 18 (21 in some regions).
									Therefore, no sexually explicit material is within the easy reach of minors.
								</p>
								<p>
									Persons under the age of 18 (21 in some regions) are also not allowed to be Adult Service
									Providers (ASP). Consequently, all Adult Service Providers (ASP) were 18 years of age or
									older (21 in some regions) during the time of photography, as per proof of age held by the
									custodian of records. Under no circumstances does NSA1.com present Adult Service Providers
									(ASP) as children.
								</p>
								<p>All Adult Service Providers (ASP) must fulfill three major requirements:</p>
								<ul>
									<li>
										The signing of an agreement declaring, namely, the genuineness of personal data and age
										provided;
									</li>
									<li>Providing of a scanned copy of his/her photo ID;</li>
									<li>
										Providing a photo taken of the Adult Service Provider (ASP), holding his/her photo ID next
										to his/her face.
									</li>
								</ul>
								<p>
									The accounts of the Adult Service Providers (ASP) are immediately and permanently suspended
									if they violate NSA1.com's policies.
								</p>
								<p>
									At NSA1.com there is a zero-tolerance policy related to child pornography (written or
									visual). In case of the slightest suspicion, the account in question is immediately and
									permanently closed and the appropriate authorities may be contacted.
								</p>
								<p>
									NSA1.com reserves the right to apply immediate and permanent suspension in case a screen name
									is offensive, refers to minors or upon the slightest suspicion of forgery.
								</p>
							</div>
							<div id='article5' className='info-page--paragraph'>
								<strong>Article 5: User Representations</strong>
								<p>
									{' '}
									User understands that in order to subscribe at NSA1.com, it is mandatory to be at least 18
									years old or 21 in some regions, in compliance with the local regulations applicable to the
									User.
								</p>
								<p>
									User expressly declares that, on the present date, I have reached the necessary age to view
									adult content in accordance to my local regulations.
								</p>
								<p>User is fully aware that NSA1.com transmits Adult entertainment content to its Users.</p>
								<p>
									User understands that the present agreement shall be effective as from the date when the User
									registers on NSA1.com and accepts this agreement and shall remain in force until duly
									terminated by either party in full compliance with the terms and conditions expressly set
									forth in this Agreement.
								</p>
								<p>
									User currently resides in the country in which the User truthfully and accurately indicated
									in the form provided to register into NSA1.com and undertake the duty to immediately inform,
									within 24 hours, of any change of residence by sending an email to help@dditservices.com.
								</p>
								<p>
									User acknowledges that the above-referred duty shall only be considered to be duly
									accomplished until NSA1.com issues the confirmation that the above-mentioned changes have
									been well received.
								</p>
								<p>
									The User understands that User is fully and exclusively liable for any false declarations
									regarding residency and for not informing NSA1.com of its change in compliance with the
									above-mentioned terms.
								</p>
								<p>
									The User also certifies that the User is aware of criminal liability and, therefore, will
									act, under all circumstances, in accordance with my local community standard of morality and
									applicable laws.
								</p>
								<p>
									User believes that, as an adult, the User has the inalienable right to read/view any content
									the User deems advisable.
								</p>
								<p>
									User believes that reading and/or viewing content on the website does not in any way infringe
									the norms or law of the User’s neighborhood, village, city, county, state or country.{' '}
								</p>
								<p>
									User is fully aware that all contents and materials provided on NSA1.com, included but not
									limited to live camera streams, images, graphics, applications and texts are duly protected
									by the applicable legislation, being all rights reserved. Therefore they shall be for the
									User’s own exclusive personal use.
								</p>
								<p>
									User is fully aware of and agrees that NSA1.com can grant full cooperation to legal
									authorities investigating fraudulent transactions and other matters falling under legal
									jurisdiction as well as responds to subpoenas and court orders.
								</p>
								<p>
									I acknowledge that NSA1.com and its affiliates cannot be held responsible for any legal
									ramifications that may arise as a result of fraudulent entry into, or use of, this website
									and/or material contained herein.
								</p>
								<p>
									I also acknowledge that NSA1.com or any of its related, affiliated companies cannot be held
									responsible for any damages or losses originating from any contact with Adult Service
									Providers (ASP).
								</p>
								<p>
									I understand and accept that under no circumstances shall NSA1.com or any of its related,
									affiliated companies be liable for any direct, indirect, incidental, special, consequential
									or punitive damages that result from any false disclosures that may arise, violation of the
									rights of Users, prostitution, pedophilia, child pornography, illegal abuse, exploitation or
									traffic of women and/or children.
								</p>
								<p>
									The user recognizes and hereby authorizes a team of NSA1.com to review any content for
									violation of its policies. Any forbidden content will be immediately removed by NSA1.com.
									Notwithstanding, the User is aware that it is not a duty to proceed with such revision and
									said entity will not be considered responsible, either jointly or severally, in case of my
									unlawful conduct.
								</p>
								<p>
									User agrees that intellectual property rights (including but not limited to the right to use,
									adapt, translate, etc.) to any content (eg. chat messages, etc.) created, published or
									otherwise made public by me on NSA1.com will belong to NSA1.com.
								</p>
								<p>
									The user expressly authorizes NSA1.com to monitor, record and log all my online activities on
									the website (including chat, video, email).
								</p>
								<p>
									User acknowledges and agrees that any material recorded or any original work made under this
									Agreement and/or when using NSA1.com services (and all rights therein, including, without
									limitation, copyright) belong to and shall be the sole and exclusive property of NSA1.com.
								</p>
								<p>
									User hereby expressly assigns, and transfers, without further compensation, to NSA1.com the
									results, content, and proceeds of my appearance(s) (including all such appearances made to
									date) videos, audio, chat, dialog, texts, acts, and instructional videos and advice, all of
									which are part of services provided - including all author rights to the above mentioned
									materials, renewals and extensions of such rights worldwide and throughout the whole validity
									period of such rights.
								</p>
								<p>
									User understands that NSA1.com shall be deemed the authors thereof for all purposes and owner
									of all rights, title, and interest, of every kind and character for the period of the
									validity of such rights, including any extensions and renewals, in all jurisdictions.
								</p>
								<p>
									User understands that NSA1.com may use and reuse, publish, distribute, edit, excerpt, exhibit
									and otherwise exploit my name (real or fictional), likeness, persona, performance, voice,
									pictures, chat, video, audio, biological information and identification, and statements, for
									any and all uses, in whole or in part, in any and all media and manners now known or learned,
									for use throughout the universe, without limitation, including in connection with the
									advertising, exploitation and publicizing.
								</p>
								<p>
									User hereby expressly waives any rights and declare to withdraw any claim that any use by
									NSA1.com violates any of my rights, including but not limited to moral rights, privacy
									rights, rights to publicity, proprietary or other rights, and/or rights to credit for the
									material or ideas set for therein.
								</p>
								<p>
									The user understands that NSA1.com may edit my appearance as they see fit (and I waive any
									and all moral rights that I may have), and I understand that they have no obligation to use
									my appearance(s).
								</p>
								<p>
									User understands that Still pictures may be made from video or my appearance(s) by any means,
									and I grant to NSA1.com, its successors, licensees, and assignees the right to use said
									photographs, without further payment to me in printed publications, digitally on the internet
									or via CD, or any other media, without restrictions.
								</p>
								<p>
									User also grants to NSA1.com, its successors, licensees and assignees the right to use any
									photos taken by me (via webcam or by other means) and sent for publication on the site,
									without further payment to me in printed publications, digitally on the internet or via CD,
									or any other media, without restrictions.
								</p>
								<p>
									The user hereby expressly waives any further financial compensation for any of the rights
									assigned, transferred or granted to NSA1.com under this agreement.
								</p>
								<p>
									The user declares and acknowledges that I am not acting on behalf of a legal person but as an
									individual consumer and in no event the purchase of services under this Agreement could be
									considered as being part of my professional activity.
								</p>
								<p>
									User understands that in case of User’s unlawful conduct or breach of the present Agreement,
									NSA1.com may terminate, without notice, the User's account and/or anything associated with
									it. NSA1.com shall not be held responsible for any possible loss as a result of such
									termination nor for any credit compensation or refund.
								</p>
								<p>
									User understands that by registering on NSA1.com and by accepting this Agreement, Users agree
									to indemnify, pay the costs of defense and hold harmless NSA1.com, its officers, directors,
									affiliates, attorneys, shareholders, managers, members, agents and employees from any and all
									claims, losses, liabilities or expenses (including reasonable attorneys' fees) brought by
									third parties arising out of or related to their conduct, statements or actions, as well as
									breach of any term, condition or promise contained herein and unlawful conduct in the
									framework of this agreement.
								</p>
							</div>
							<div id='article6' className='info-page--paragraph'>
								<strong>Article 6: NSA1.com - Fees, Credit Compensation, Refund, and Unlawful Conduct</strong>
								<p>
									The Users may purchase credit packages to spend in the available areas of Adult
									Entertainment; therefore the selection of services must be preceded of the consultation by
									the User of the correspondent content, price list, and means of payment made available on the
									website.
								</p>
								<p>
									Text chat with the dressed Adult Service Providers (ASP) is free, but if a user wants to
									access the full feature service (including undressed Adult Service Providers – ASP and
									private show) he/she must click on “A Private Show with this Performer” button and from that
									point on stays in the video chat room with the Adult Service Provider (ASP) entering into a
									private mode where the purchased credits are used on a per-second basis.
								</p>
								<p>
									Users may use the option Quickbuy during private shows with Adult Service Providers (ASP).
									The use of this feature will allow Users’ to, immediately and manually, top-up their credit
									balance with the chosen package during the show.
								</p>
								<p>
									Users may also use the option Auto Quickbuy to enjoy private shows uninterruptedly since it
									allows the system to automatically top-up Users’ credit balance with the selected package
									whenever credits reach an end.{' '}
								</p>
								<p>
									Users are notified about the referred auto-purchase each time it occurs and will have the
									option to stop the next charge by clicking on the “X button” on the notifying message.{' '}
								</p>
								<p>
									The use of any of the referred options will charge the default payment method set by the
									Users.
								</p>
								<p>
									Billing of a given account (the actual price payable for credit packages) depends on the
									physical location where the account has been created. Please take into consideration that
									NSA1.com reserves the right to round prices and therefore, shall be entitled to apply to
									round.
								</p>

								<p>For banking expenses information, each User should consult directly his/her bank.</p>
								<p>NSA1.com will not take responsibility for any difficulty occurring outside NSA1.com.</p>
								<p>
									Any complaint must be placed, within 24 hours counted from the occurrence, at NSA1.com -
									Customer Service. Given the complexity and particularities of the procedure to determine the
									righteousness of the complaints placed after the referred 24 hour period, these shall only be
									acknowledged in cases of force majeure.
								</p>
								<p>
									NSA1.com - Customer Service team may whenever deemed necessary, carefully investigate reports
									and other data at its disposal, resorting to any existing means for such effect.
								</p>
								<p>
									NSA1.com will only compensate/refund credits in the amount proportionally determined in
									accordance to the price paid and problem effectively reported when any credit is lost due to
									dysfunction of NSA1.com or an Adult Service Provider (ASP) in the “Nude Only” part of the
									site totally and explicitly refuses to provide erotic content. In case some erotic content is
									provided, NSA1.com will assess the situation and decide whether the claim of the customer is
									grounded and any compensation/refund of credits is due. Performers of the Hot Flirt category
									are not allowed to provide any sexually explicit content or expose sexually provoking body
									parts even during a Private show. They must at all times wear at least underwear according to
									their category rules.
								</p>
								<p>
									Due to the payment system applied by Duodecad ITS and third-party payment processors, only
									the same amount can be refunded which was originally paid for the purchase (i.e. no partial
									refund of credit packages bought is possible). For this purpose whenever only part of the
									credits is used, refund would as the case may only be possible by restoring the credits on
									your account. No payment refund will be possible. Refunds, when possible according to the
									above, will be issued using the same payment method the purchase was made with. The
									corresponding amount of credits will be deducted from the NSA1 account's balance. Certain
									payment methods do not allow the refund because of technical reasons.
								</p>
								<p>
									Amounts spent on related services, such as TopMembership or FanClub fee, Surprise or
									SneakPeek feature, are not subject to refund/compensation.
								</p>
								<p>
									Notwithstanding the above, NSA1.com will not be liable for any defamatory, offensive or
									illegal conduct of any User, or for any failure of performance, error, omission,
									interruption, deletion, defect, delay in operation or transmission, communications line
									failure, theft or destruction or unauthorized access to, alteration of or use of records,
									whether under contract or tort theory, or under any other cause of action, for any amount
									over and above the amount paid by the User to NSA1.com.
								</p>
								<p>
									Under no circumstances, including, but not limited to, negligence, shall NSA1.com or any of
									its related, affiliated companies be liable for any direct, indirect, incidental, special,
									consequential or punitive damages that result from the use of, or the inability to use the
									service, without prejudice of the established in the present clause.
								</p>
								<p>
									Cases of dishonest behavior related to online transactions are handled with high priority
									keeping, however, in mind the User’s privacy. Such cases may be outsourced to companies
									specialized in this field.
								</p>
								<p>
									The User accepts that in case of any fraudulent transaction NSA1.com has a right to use all
									available information at its disposal during any kind of legal procedure. Including, without
									the claim of completeness, browser history, IP and email addresses and any other traceable
									activity. During such legal procedures, NSA1.com might involve other, professional
									investigator parties and share certain information in order to comply with law requirements,
									vindicate its rights and represent the best interests of its Customers. The User will stay
									liable for investigation or collection costs related to any fraudulent transaction.
								</p>
								<p>
									NSA1.com grants full cooperation to legal authorities investigating fraudulent transactions
									and other matters falling under legal jurisdiction and responds to subpoenas and court
									orders.
								</p>
								<p>
									Please take into consideration that NSA1.com reserves the right to round payments to be paid
									as a compensation/refund, and therefore, NSA1.com shall be entitled to apply to round.
								</p>
								<p>
									When new persons register on NSA1.com, they may purchase packages with bonus credits. This
									option is only available for new purchases of first-time users; a new account registration
									alone is not considered to be a new user. Members can regularly get packages with bonus
									credits. Members can also check the number of required purchases to receive their bonus
									packages. Bonus credit packages are not available with CCBill and phone purchases. Bonus
									credits are part of the full credit package, and therefore refunds are NOT possible after
									spending them on our website.
								</p>
								<p>
									New Users may validate their credit card with a minor validation transaction for which they
									are entitled to 10.99 promotional credits. The amount for the validation transaction will be
									refunded immediately to the credit card user. The aforementioned promotional credits are not
									available for those users who already have a membership on NSA1.com, though their validation
									transaction will be refunded without exceptions.
								</p>
								<p>
									All currency and transaction-related costs are included in the displayed price of credit
									packages. NSA1.com reserves the right to modify the prices incurred from the fluctuations of
									currency exchange rates.
								</p>
							</div>
							<div id='article7' className='info-page--paragraph'>
								<strong>Article 7: NSA1.com - User Duties</strong>
								<p>
									I agree not to copy, reproduce, republish, download, transmit and/or make available to the
									public any of the content/materials of NSA1.com, without the prior written consent of
									NSA1.com.
								</p>
								<p>
									I am fully liable for any false disclosures and responsible for any legal ramifications that
									may arise from viewing, reading, or downloading of material and images contained within this
									website.
								</p>
								<p>
									I will never expose minors to the content of the website and will take on full precautions to
									avoid any type of exhibition or access of the minors to the website, namely, for example, by
									not including the website in their list of favorite sites to visit. I will be solely
									responsible in case any minor would access NSA1.com through my account or by using my credit
									card details.
								</p>
								<p>I assume full responsibility to maintain the security of my account and password.</p>
								<p>
									I will not give out personal information, provide or support escort services and/or
									prostitution.
								</p>
								<p>
									I will not arrange personal appointments with any Adult Service Provider (ASP), since it is
									prohibited, and will not exchange my telephone number with an Adult Service Provider/User or
									in any other way try to have any physical contact with an Adult Service Provider (ASP)/User.
									In any event, I acknowledge that NSA1.com would not be liable should by breaching these Terms
									& Conditions I would try to have physical contact with an Adult Services Provider/User.
								</p>
								<p>
									I will not use obscene words, threaten or quarrel with, or violate the rights of visitors,
									Adult Service Providers (ASP), NSA1.com support persons or management of the website, since
									it is prohibited.
								</p>
								<p>
									Text content sent or forwarded and the chosen user name will not be offensive, will not
									suggest pedophilia, adolescence, bestiality or zoophilia, or refer to elimination or
									consumption of any bodily waste.
								</p>
								<p>
									I will not use remarks and user names that are unacceptable by the standards of good taste,
									suggesting a violation of the law or deceiving others.
								</p>
								<p>
									I will inform, immediately, NSA1.com of any unlawful conduct of the Adult Service Providers
									(ASP), as well as of any unlawful use of trademarks, brands and/or registered music.
								</p>
							</div>
							<div id='article8' className='info-page--paragraph'>
								<strong>Article 8: NSA1.com - Subscription Cancellation</strong>
								<p>Users have the option to unsubscribe at any time from NSA1.com services. </p>
								<p>
									The subscription cancellation can be accomplished by visiting NSA1.com 24/7 Customer Service
									Center or by sending an email to help@NSA1.com.
								</p>
								<p>
									Once the account is canceled, your User status will change to 'Canceled' and all related
									details will be archived.
								</p>
								<p>
									NSA1.com reserves a right to suspend or cancel any subscription in case of breach of any term
									of this Agreement or any unlawful conduct of the User in the framework of this Agreement and
									to contact any relevant authorities, including but not limited to initiating any criminal
									complaint and contacting state prosecutor.
								</p>
							</div>
							<div id='article9' className='info-page--paragraph'>
								<strong>Article 9: NSA1.com - Personal Data and Privacy Policy</strong>
								<p>
									I hereby authorize NSA1.com to process technical data related to my visit to the NSA1.com
									website and my personal data, including sensitive data (eg. relating to my sexual life),
									provided by me or collected during the registration process in accordance with the present
									Privacy Policy, during the period of my subscription with NSA1.com and after cancellation of
									my subscription during the period necessary for NSA1.com to comply with legal requirements.
								</p>
								<p>
									I have not and will not provide any false information and/or documents to NSA1.com.
									Therefore, I recognize that NSA1.com has the right to, immediately and unilaterally,
									terminate the present agreement upon the slightest suspicion of forgery.
								</p>
								<p>
									I allow all my data to be inspected by NSA1.com, randomly, resorting to any existing means
									for such effect. Notwithstanding, I am aware that it is not their duty to proceed with such
									inspection and said entities will not be considered responsible, either jointly or severally,
									in case of my unlawful conduct.
								</p>
								<p>
									I agree and authorize NSA1.com, LiveNSA1.com to obtain and store information automatically
									from my computer used to visit NSA1.com, LiveNSA1.com website (with use of cookies and
									similar technologies). NSA1.com, LiveNSA1.com may track the User’s visit to the website by
									giving a choice to accept Cookies when entering. Cookies help to collect anonymous data by
									tracking user trends and patterns.
								</p>
								<p>
									I agree and allow NSA1.com to collect, process and communicate to its processors, including
									the processors situated in countries not ensuring an adequate level of protection according
									to European Commission, the following types of data/information related to User:
								</p>
								<p>
									information that the Users voluntarily provide to and/or authorize to view, such as names,
									email address, address, date of birth and other miscellaneous account information submitted
									through NSA1 Submission Forms.
								</p>
								<p>
									the number of visits and areas of NSA1.com pages visited by the Users. Software and hardware
									attributes might get logged too, along with any other data that can be gained from the
									general Internet environment, such as browser type, IP address, etc.
								</p>
								<p>
									private communications, such as telephone conversations, chat logs, faxes, and letters to
									NSA1 Staff along with e-mail messages to Adult Service Providers (ASP) or to our Staff.
									NSA1.com also keeps Adult Service Providers (ASP) chat client logs for a limited period of
									time.
								</p>
								<p>
									NSA1.com will use the data collected from the Users for mainly general purposes, such as
									improving services, contacting the Users, customizing the website content and for promotional
									marketing purposes, to the extent allowed by law.
								</p>
								<p>
									By accepting this Agreement, the User also agrees that NSA1.com may also research behavior
									patterns and trends to improve the Users’ user experience.
								</p>
								<p>
									NSA1.com takes serious security measures to grant maximum protection to information against
									unauthorized access, modification, disclosure or deletion of data. The Users’ details are
									always protected by a highly sophisticated security system.
								</p>
								<p>NSA1.com guards the Users’ information on electronic and physical levels.</p>
								<p>
									Besides using its own security software and mechanisms, NSA1.com also incorporates the most
									advanced security technologies available in order to ensure maximum safety of its Users.
								</p>
								<p>
									NSA1.com’s system meets the security standards of PCI DSS, a standard set by Visa/MasterCard
									laying down stringent requirements.
								</p>
								<p>
									NSA1.com employees' access to any personal information of NSA1.com Users is extremely limited
									and they are bound by confidentiality obligations. Therefore, they might be subject to
									disciplinary measures, including the termination of their contracts and in serious cases even
									criminal prosecution should they fail to meet these strict obligations.
								</p>
								<p>
									NSA1.com does not rent, sell, trade, share or otherwise transfer information to outside
									parties except the communication to NSA1.com group companies and services providers in order
									to ensure the good functioning of NSA1.com services.
								</p>
								<p>
									NSA1.com does not allow its performers to record or capture the members’ personal camera
									feeds during private shows. Such action is prohibited on the website.
								</p>
								<p>
									The Users may obtain a copy of any personal information that NSA1.com process and ask for
									rectification of any incorrect personal data, upon written request to the contacts provided
									in the present Agreement and indication of the address to which the information must be sent.
									If the User would like to oppose the processing of personal data by NSA1.com, he/she is
									entitled to cancel his/her subscription on NSA1.com at any time as described above.
								</p>
								<p>
									NSA1.com, upon reception of the above-mentioned request, will communicate the personal data
									concerning the User to the email address provided during the registration process on
									NSA1.com. NSA1.com shall be held harmless in case the right, foreseen in the previous clause,
									is exercised and the personal data is lost or subject to unauthorized access.
								</p>
								<p>
									In case of any queries, the User may use the contacts foreseen in clause 12 of the present
									Agreement.
								</p>
							</div>
							<div id='article10' className='info-page--paragraph'>
								<strong>Article 10: NSA1.com - Functionality and Security</strong>
								<p>
									NSA1.com is a Flash-technology based website that also uses the so-called “Shared-Object”
									technology in order to improve user experience.
								</p>
								<p>
									NSA1.com suggests for the Users to enable “Cookies” in their browsers to ensure full
									functionality.
								</p>
								<p>
									The NSA1.com Support Team monitors all camera feeds available on the website 24 hours a day,
									7 days a week.
								</p>
								<p>Due to its precise, unique design, the website has never had any serious security breach.</p>
								<p>NSA1.com is a Scam-Free Zone.</p>
							</div>
							<div id='article11' className='info-page--paragraph'>
								<strong>Article 11: NSA1.com - Spam</strong>
								<p>
									NSA1.com does not send spam messages and will not tolerate spam, distancing itself from any
									actions related to spamming.
								</p>
								<p>The following is considered to constitute a spam:</p>
								<p>
									Manipulating messages, such as email headers, sent to or through NSA1.com computer systems in
									a way that may deceit Users of NSA1.com.
								</p>
								<p>
									Relaying email from a third party's mail servers without the permission of that third party.
								</p>
								<p>
									Sending, relaying or causing to be sent false, deceptive information or that is otherwise
									against the business interest of NSA1.com.
								</p>
								<p>
									Using or causing to be used NSA1.com computer systems to facilitate the transmission of
									unsolicited or unauthorized material. This includes any promotional materials, URLs or any
									other form of unauthorized solicitation that you may upload, post, email, transmit, or
									otherwise make available.
								</p>
								<p>
									Uploading, posting, emailing, or transmitting the same message, URL, or post multiple times
								</p>
								<p>
									Disrupting the normal flow of dialogue by posting messages in quick succession, multiple
									times, using capital letters only or otherwise acting in a manner that negatively affects
									other users' ability to engage in real-time exchanges.
								</p>
								<p>
									Spamming through the NSA1.com system or disturbing its Users is a violation of Terms and
									Conditions of the site.
								</p>
								<p>
									NSA1.com does everything in its power to protect its Users from deleterious effects of
									spamming.
								</p>
								<p>
									The use of all legal proceedings is considered in case of spamming inflicting a loss on
									NSA1.com.
								</p>
								<p>
									Notwithstanding the above, NSA1.com when authorized by the Users, within the terms of the
									present agreement, may send promotional e-mails about its own and about its partner-websites
									and every e-mail will contain the option to unsubscribe from the mailing list.
								</p>
								<p>
									Registered Users may occasionally receive newsletters in relation to NSA1.com. The messages
									sent to Users are transactional or relationship messages. Subscribing to and unsubscribing
									from these newsletters takes a single click. Any promotional messages will be only sent if
									the User has expressed its consent for receiving such promotional marketing services.
								</p>
								<p>
									In case Users would like to report spam, it is recommended the use of “Message to Support
									function” in “My Account” or the sending of an email to help@NSA1.com. NSA1.com Support Team
									investigates all reports as soon as possible.
								</p>
							</div>
							<div id='article12' className='info-page--paragraph'>
								<strong>Article 12: NSA1.com - Contactss</strong>
								<p>Customer Service Department can be contacted from:</p>
								<p>Online Support Chat;</p>
								<p>“Message to Support” function of the Users’ accounts;</p>
								<p>Sending of an email to help@NSA1.com.</p>
								<p>Sending of a letter to: NSA1.COM, Columbia, SC </p>
							</div>
							<div id='article13' className='info-page--paragraph'>
								<strong>Article 13: General Considerations</strong>
								<p>
									This Agreement sets forth the full and complete understanding between Users and NSA1.com with
									respect to its subject matter and supersedes all prior understanding or agreements, whether
									written or verbal.
								</p>
								<p>
									Unless contrary to law or otherwise stated, each provision of this Agreement shall survive
									termination.
								</p>
								<p>
									If any portion of this Agreement is deemed unenforceable by a court of competent
									jurisdiction, it shall not affect the enforceability of the other portions of this Agreement.
								</p>
								<p>
									The prevailing party in any suit to enforce the terms hereof shall be entitled to recover
									his/her/its reasonable attorneys' fees.
								</p>
								<p>
									If NSA1.com changes the terms of this Agreement, NSA1.com will post an updated set of terms
									and conditions on the website and/or will post a change notice and/or send Users an email
									notice of the changes in advance of implementing the changes.
								</p>
								<p>
									If any modification is unacceptable to you, you shall cease using the NSA1.com website and
									its services and be able to terminate your subscription (if any). If you do not cease using
									the NSA1.com website and its services, you will be conclusively deemed to have accepted the
									change.
								</p>
								<p>
									The English version shall prevail of all legal statements, statutory declarations made by
									NSA1.com, including the present Agreement. NSA1.com does not accept any kind of legal claims
									or other complaints about the misunderstandings as a result of any mistranslation.
								</p>
								<p>
									This Agreement and the relations arising out from it between NSA1.com and the Users will be
									governed by the law of the United States of America.
								</p>
								<p>
									Any disputes arising between NSA1.com and the Users will be settled amicably and only when
									this solution is not efficient, the competent jurisdiction for the disputes arising from this
									Agreement will be the courts of the United States of America.
								</p>
							</div>
						</div>
					</div>
					<div className='info-page--right'>
						<div className='info-page-menu'>
							<ul>
								<li>
									<a href='#article1'>Definitions</a>
								</li>
								<li>
									<a href='#article2'>NSA1.com Services</a>
								</li>
								<li>
									<a href='#article3'>NSA1.com Representations</a>
								</li>
								<li>
									<a href='#article4'>NSA1.com - Policies</a>
								</li>
								<li>
									<a href='#article5'>User Representations</a>
								</li>
								<li>
									<a href='#article6'>NSA1.com - Fees, Credit Compensation, Refund, and Unlawful Conduct</a>
								</li>
								<li>
									<a href='#article7'>NSA1.com - User Duties</a>
								</li>
								<li>
									<a href='#article8'>NSA1.com - Subscription Cancellation</a>
								</li>
								<li>
									<a href='#article9'>NSA1.com - Personal Data and Privacy Policy</a>
								</li>
								<li>
									<a href='#article10'>NSA1.com - Functionality and Security</a>
								</li>
								<li>
									<a href='#article11'>NSA1.com - Spam</a>
								</li>
								<li>
									<a href='#article12'>NSA1.com - Contacts</a>
								</li>
								<li>
									<a href='#article13'>General Considerations</a>
								</li>
							</ul>
						</div>
					</div>
				</section>
			</main>
		);
	}
}

export default Privacy;
