import React, { Component, useEffect, useState } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import flags from '../../../images/flags/canada.jpg';
import { getPremiumModels } from '../../../request/member';

const Similar = () => {
  const [premiumModels, setPremiumModels] = useState([]);

  const settings = {
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: 'linear',
    dots: false,
    infinite: true,
    vertical: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          swipeToSlide: false,
          dots: false,
        },
      },
    ],
  };

  const handleGetPremiumModels = async () => {
    try {
      const response = await getPremiumModels();

      setPremiumModels(response?.models ?? []);
    } catch (error) {}
  };

  useEffect(() => {
    handleGetPremiumModels();
  }, []);

  return (
    <section className='models-slider model-slider-similar'>
      {premiumModels.length > 0 && (
        <div className='page-title'>Other girls wants your attention</div>
      )}
      <Slider {...settings}>
        {premiumModels?.map((model, index) => {
          return (
            <div key={index} className='models-big-item models-big-item__free '>
              <Link
                to={`/detail/about?model=${model?.registerId}&lastName=${model?.lastName}&firstName=${model?.firstName}`}
                className='models-big-item--img !h-[200px] !w-[420px]'
              >
                <img
                  className='!h-[200px] !w-[420px] !object-fill'
                  src={model?.profilePicUrl}
                  alt=''
                />
                <div className='models-big-item--status'>
                  <span className='models-big-item--status__quantity'>HD</span>
                </div>
              </Link>
              <div className='models-big-item-bottom'>
                <div className='models-big-item--name'>
                  {model?.firstName} {model?.lastName}
                </div>
                {/* <div className='models-big-item--like'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='20'
                    height='19'
                    viewBox='0 0 20 19'
                    fill='none'
                  >
                    <path
                      d='M19.4101 5.62556C19.1312 2.55516 16.9582 0.327516 14.2387 0.327516C12.4269 0.327516 10.768 1.30259 9.83457 2.86536C8.90958 1.28238 7.31869 0.327148 5.53595 0.327148C2.81684 0.327148 0.643456 2.55479 0.364893 5.62519C0.342843 5.76081 0.252439 6.47456 0.527327 7.63855C0.92349 9.31746 1.83856 10.8446 3.17294 12.0538L9.83016 18.0957L16.6017 12.0541C17.9361 10.8446 18.8511 9.31783 19.2473 7.63855C19.5222 6.47493 19.4318 5.76118 19.4101 5.62556ZM18.5318 7.47022C18.1702 9.00358 17.3319 10.4006 16.11 11.5076L9.83457 17.107L3.66686 11.5091C2.44273 10.3999 1.60483 9.00322 1.24285 7.46985C0.982657 6.36835 1.0896 5.74611 1.08997 5.74206L1.09548 5.70494C1.33435 3.01494 3.20161 1.06222 5.53595 1.06222C7.25842 1.06222 8.77471 2.12073 9.49427 3.82426L9.83274 4.62659L10.1712 3.82426C10.8794 2.14682 12.4758 1.06259 14.239 1.06259C16.573 1.06259 18.4406 3.01531 18.6843 5.74023C18.685 5.74611 18.792 6.36871 18.5318 7.47022Z'
                      fill='white'
                      stroke='white'
                      strokeWidth='0.4'
                    />
                  </svg>
                  16 600
                </div> */}
              </div>
              {/* <div className='models-big-item-bottom2'>
                <div className='models-big-item-category'>Free chat</div>
                <div className='models-big-item--flag'>
                  <img src={flags} alt='' />
                </div>
              </div> */}
            </div>
          );
        })}
      </Slider>
    </section>
  );
};

export default Similar;
