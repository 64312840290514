import React, { Component } from 'react';
import Scrollbar from 'react-scrollbars-custom';
import Over from './Over';
import Notover from "./Notover";

class Agecheck extends Component {
    render() {
        return (
            <div className="age-check">
                <div className="container">
                    <div className="age-check-animation-wrapper">
                        <div className="age-check-animation"></div>
                    </div>
                    <div className="age-check-checkblock">
                        <Notover/>
                        <Over/>
                    </div>
                    <div className="age-check-logo" id="slides">
                        <img src={require('../../images/logo-anim/0_0001.png')} alt="" className="slide showing"/>
                        <img src={require('../../images/logo-anim/0_0002.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0003.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0004.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0005.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0006.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0007.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0008.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0009.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0010.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0011.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0012.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0013.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0014.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0015.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0016.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0017.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0018.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0019.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0020.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0021.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0022.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0023.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0024.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0025.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0026.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0027.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0028.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0029.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0030.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0031.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0032.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0033.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0034.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0035.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0036.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0037.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0038.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0039.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0040.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0041.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0042.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0043.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0044.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0045.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0046.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0047.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0048.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0049.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0050.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0051.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0052.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0053.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0054.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0055.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0056.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0057.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0058.png')} alt="" className="slide"/>
                        <img src={require('../../images/logo-anim/0_0060.png')} alt="" className="slide"/>
                    </div>
                    <div className="age-check-text">
                        <div className="age-check-text-wrapper age-check-text-wrapper__pink">
                            <div className="age-check-text--title">
                                WARNING — SEXUALLY EXPLICIT CONTENT
                            </div>
                            <div className="age-check-text--text">
                                This site contains sexually explicit materials, adult-oriented novelties and products intended for individuals 18 years of age or older with the legal right to access such materials and products as determined by the local and national laws of the region in which you reside. If you are not yet 18, if adult material offends you, or if you are accessing this site from any country or locale where adult material is prohibited by law or may violate your community standards, DO NOT CONTINUE!
                            </div>
                        </div>
                        <div className="age-check-text-wrapper age-check-text-wrapper__violet">
                            <div className="age-check-text--title">
                                By continuing, I certify that<br />
                                I agree to the terms<br />
                                and conditions below
                            </div>
                            <div className="age-check-text--text">
                                <Scrollbar style={ {width: '100%', height: '100%', minHeight: 500} } >
                                    This Agreement is effective immediately between you (“User”) and National Strip Club of Association (“NSA1.com”). WHEREAS, NSA1.com is an online adult interactive web site that offers the exchange of erotic and sexual content intended for those over 18 years of age, 21 years of age in some jurisdictions; and WHEREAS, NSA1.com is operated by National Strip Club Association of Columbia, SC; and WHEREAS, User seeks to utilize NSA1.com for any and all services that are offered by NSA1.com; and WHEREAS, User conveys to NSA1.com that they are of the appropriate age and mental capacity to enter NSA1.com; and WHEREAS, User represents to NSA1.com that they are not in violation of any local criminal ordinance or statute of their accessing location by entering NSA1.com; NOW, THEREFORE, in consideration of the mutual covenants contained herein, User and NSA1.com hereby consent, warrant and agree as follows: Article 1: Definitions 1.1 Member/Customer/Subscriber/User – The visitor who signs up to the services of the site. 1.2 Adult Service Provider (ASP)/ Performer – An adult entity older than age 18 or 21 in some region, who provides shows, chats or interacts with visitors of the site.
                                    This Agreement is effective immediately between you (“User”) and National Strip Club of Association (“NSA1.com”). WHEREAS, NSA1.com is an online adult interactive web site that offers the exchange of erotic and sexual content intended for those over 18 years of age, 21 years of age in some jurisdictions; and WHEREAS, NSA1.com is operated by National Strip Club Association of Columbia, SC; and WHEREAS, User seeks to utilize NSA1.com for any and all services that are offered by NSA1.com; and WHEREAS, User conveys to NSA1.com that they are of the appropriate age and mental capacity to enter NSA1.com; and WHEREAS, User represents to NSA1.com that they are not in violation of any local criminal ordinance or statute of their accessing location by entering NSA1.com; NOW, THEREFORE, in consideration of the mutual covenants contained herein, User and NSA1.com hereby consent, warrant and agree as follows: Article 1: Definitions 1.1 Member/Customer/Subscriber/User – The visitor who signs up to the services of the site. 1.2 Adult Service Provider (ASP)/ Performer – An adult entity older than age 18 or 21 in some region, who provides shows, chats or interacts with visitors of the site.
                                    This Agreement is effective immediately between you (“User”) and National Strip Club of Association (“NSA1.com”). WHEREAS, NSA1.com is an online adult interactive web site that offers the exchange of erotic and sexual content intended for those over 18 years of age, 21 years of age in some jurisdictions; and WHEREAS, NSA1.com is operated by National Strip Club Association of Columbia, SC; and WHEREAS, User seeks to utilize NSA1.com for any and all services that are offered by NSA1.com; and WHEREAS, User conveys to NSA1.com that they are of the appropriate age and mental capacity to enter NSA1.com; and WHEREAS, User represents to NSA1.com that they are not in violation of any local criminal ordinance or statute of their accessing location by entering NSA1.com; NOW, THEREFORE, in consideration of the mutual covenants contained herein, User and NSA1.com hereby consent, warrant and agree as follows: Article 1: Definitions 1.1 Member/Customer/Subscriber/User – The visitor who signs up to the services of the site. 1.2 Adult Service Provider (ASP)/ Performer – An adult entity older than age 18 or 21 in some region, who provides shows, chats or interacts with visitors of the site.
                                </Scrollbar>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Agecheck;
