import { Fragment, useEffect, useMemo, useState } from 'react';
import styles from './modelprice.module.scss';
import ModelProfileHeader from '../../custom/modelProfileHeader/ModelProfileHeader';
import FluidContainer from '../../custom/fluidContainer/FluidContainer';
import Button from '../../custom/btn/Btn';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import Spinner from '../../custom/spinner/Spinner';
import {
	getModelPrices,
	updateModelPrivateChatPrice,
	updateModelPrivateCallAndBroadcastPrices,
} from '../../request/model';
import { useSelector } from 'react-redux';
import notification from '../../utils/notification';

const ModelPrice = () => {
	const clientId = useSelector(state => state?.user?.clientId);
	const queryClient = useQueryClient();

	const { isLoading, isError, error, data } = useQuery({
		queryFn: () => getModelPrices(clientId),
		queryKey: ['model-prices'],
		refetchOnWindowFocus: false,
		retry: 0,
	});

	const modelPriceError = error?.response?.data?.message ?? error?.message;

	const [broadcastPrice, setBroadcastPrice] = useState(data?.broadcast);
	const [privateCallPrice, setPrivateCallPrice] = useState(data?.privateCall);
	const [privateChatPrice, setPrivateChatPrice] = useState(data?.privateChat);

	const memoizedModelPrices = useMemo(() => data, [data]);

	useEffect(() => {
		setBroadcastPrice(data?.broadcast);
		setPrivateCallPrice(data?.privateCall);
		setPrivateChatPrice(data?.privateChat);
	}, [memoizedModelPrices]);

	const updatePriveChatMutation = useMutation(
		['model-update-private-chat-price'],
		payload => updateModelPrivateChatPrice(payload),
		{
			retry: 0,
		}
	);

	const updatePriveCallAndBroadcastMutation = useMutation(
		['model-update-private-call-and-broacast-price'],
		payload => updateModelPrivateCallAndBroadcastPrices(payload),
		{
			retry: 0,
		}
	);

	const handleUpdateModelPrivateChatPrice = () => {
		const formData = new FormData();
		formData.append('PrivateChat', privateChatPrice);
		formData.append('ModelId', clientId);

		updatePriveChatMutation.mutate(formData, {
			onSuccess: () => {
				queryClient.invalidateQueries(['model-prices']);
				notification({
					title: 'Update model Price',
					message: 'model price update successful',
					type: 'success',
				});
			},
			onError: error => {
				notification({
					title: 'Update model Price',
					message: error?.response?.data?.message ?? error?.message,
					type: 'danger',
				});
			},
		});
	};

	const handleUpdateModelPrivateCallAndBroadcastPrice = () => {
		const formData = new FormData();
		formData.append('Broadcast', broadcastPrice);
		formData.append('PrivateCall', privateCallPrice);
		formData.append('ModelId', clientId);

		updatePriveCallAndBroadcastMutation.mutate(formData, {
			onSuccess: () => {
				queryClient.invalidateQueries(['model-prices']);
				notification({
					title: 'Update model Price',
					message: 'model price update successful',
					type: 'success',
				});
			},
			onError: error => {
				notification({
					title: 'Update model Price',
					message: error?.response?.data?.message ?? error?.message,
					type: 'danger',
				});
			},
		});
	};

	return (
		<FluidContainer>
			<ModelProfileHeader />
			<section>
				<h1 className={styles.header}>
					<span className={styles.subTitle}>Billing</span>
					<span className={styles.title}>Prices</span>
				</h1>

				{isLoading ? (
					<Spinner />
				) : isError ? (
					<p className='error'>{modelPriceError}</p>
				) : (
					<Fragment>
						<section className={styles.flexContainer}>
							<div className={styles.leftCol}>
								<h1 className={styles.tariffTitle}>
									Tariff <Line />
								</h1>
								<p className={styles.tariffContent}>
									Here you can determine the price (credits per minute) which your customers have to pay
								</p>
							</div>
							<div className={styles.rightCol}>
								<div className={styles.inputContainer}>
									<div className={styles.input}>
										<input
											placeholder='10.00'
											type={'number'}
											value={broadcastPrice}
											onChange={e => setBroadcastPrice(e.target.value)}
										/>
										<span>Gold Show</span>
									</div>
									<div className={styles.input}>
										<input
											placeholder='10.00'
											type={'number'}
											value={privateCallPrice}
											onChange={e => setPrivateCallPrice(e.target.value)}
										/>
										<span>Private Call</span>
									</div>
								</div>
								<Button
									className={styles.updateButton}
									text={updatePriveCallAndBroadcastMutation?.isLoading ? 'Updating...' : 'Update'}
									onClick={handleUpdateModelPrivateCallAndBroadcastPrice}
									disabled={updatePriveCallAndBroadcastMutation.isLoading}
								/>
							</div>
						</section>
						<section className={styles.flexContainer}>
							<div className={styles.leftCol}>
								<h1 className={styles.tariffTitle}>
									Requests <Line />
								</h1>
								<p className={styles.tariffContent}>Here you can determine the price customers pay for requests</p>
							</div>
							<div className={styles.rightCol}>
								<div className={styles.inputContainer}>
									<div className={styles.input}>
										<input
											placeholder='10.00'
											type={'number'}
											value={privateChatPrice}
											onChange={e => setPrivateChatPrice(e.target.value)}
										/>
										<span>Private Chat</span>
									</div>
								</div>

								<Button
									text={updatePriveChatMutation?.isLoading ? 'Updating...' : 'Update'}
									className={styles.updateButton}
									onClick={handleUpdateModelPrivateChatPrice}
									disabled={updatePriveChatMutation.isLoading}
								/>
							</div>
						</section>
					</Fragment>
				)}
			</section>
		</FluidContainer>
	);
};

const Line = () => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='216' height='2' viewBox='0 0 216 2' fill='none'>
			<path d='M0 1L216 1.00004' stroke='url(#paint0_linear_5334_161119)' stroke-width='2' />
			<defs>
				<linearGradient
					id='paint0_linear_5334_161119'
					x1='-1.33272e-06'
					y1='1.00001'
					x2='215.204'
					y2='-12.0867'
					gradientUnits='userSpaceOnUse'
				>
					<stop stop-color='#413450' />
					<stop offset='0.750097' stop-color='#563143' />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default ModelPrice;
