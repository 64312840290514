import styles from '../styles.module.scss';
import { updateDiary, deleteDiary } from '../../../request/model';
import Button from '../../../custom/btn/Btn';
import { ReactComponent as DeleteIcon } from '../../../images/svg/delete.svg';
import classNames from 'classnames';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Switch from '../../../custom/switch/Switch';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FileUpload from '../../../custom/fileUpload/FileUpload';
import Spinner from '../../../custom/spinner/Spinner';
import RichTextEditor from '../../../custom/Tiptap/RichTextEditor';
import errorFormmatter from '../../../utils/errorFormatter';
import notification from '../../../utils/notification';

const DiarySchema = Yup.object().shape({
  // title: Yup.string().trim().required('title is required'),
  description: Yup.string().trim().required('description is required'),
  isHidden: Yup.bool().required('permission is required'),
  coverImage: Yup.string().required('cover image is required'),
  diaryText: Yup.string().required('diary content  is required'),
  isCommentAllowed: Yup.bool().required('comment permission is required'),
});

const Dairy = ({
  data,
  isLoading,
  setIsLoading,
  handleDiarySelection,
  handleIsSelected,
}) => {
  console.log(data);
  const clientId = useSelector((state) => state?.user?.clientId);

  const [permission, setPermission] = useState({
    isHidden: false,
    isCommentAllowed: false,
    isFreeForFanClub: false,
  });

  const handleSetPermission = (name) => {
    setPermission((prev) => ({
      ...prev,
      [name]: !permission[name],
    }));
  };

  const [openToEdit, setOpenToEdit] = useState(false);

  const handleCommentAllowed = (event) => {
    const name = event.target.name;
    const value = event.target.checked;

    setPermission((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUpdateDiary = async (values) => {
    try {
      setIsLoading(true);
      const payload = new FormData();
      payload.append('registeredId', clientId);
      payload.append('diaryId', data?.diaryId);
      payload.append('isFreeForFanClub', permission.isFreeForFanClub);
      // payload.append('title', values.title);
      payload.append('isHidden', permission.isHidden);
      payload.append('description', values.description);
      payload.append('diaryText', values.diaryText);
      payload.append('isAllowComments', permission.isCommentAllowed);
      payload.append('addCoverImage', values.coverImage);

      const response = await updateDiary({ payload });
      setIsLoading(false);

      notification({
        message: response?.message ?? 'Fan club dairy created successfully',
      });
    } catch (error) {
      notification({
        message: errorFormmatter(error) ?? 'Fan club dairy failed to create',
        type: 'danger',
      });
      setIsLoading(false);
    }
  };

  const handleDeleteDiary = async (id) => {
    try {
      setIsLoading(true);

      const response = await deleteDiary({ modelId: clientId, diaryId: id });
      setIsLoading(false);
      setOpenToEdit(false);
      notification({
        message: response?.message ?? 'Fan club dairy deleted successfully',
      });
    } catch (error) {
      notification({
        message: errorFormmatter(error) ?? 'Fan club dairy failed to delete',
        type: 'danger',
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setPermission(() => ({
      isCommentAllowed: data?.isAllowComments,
      isHidden: data?.isHidden,
      isFreeForFanClub: data?.isFreeForFanClub,
    }));
  }, [data]);

  return (
    <>
      <section className={styles.beachSessionContainer}>
        <div className={styles.beachSessionDetailContainer}>
          <img src={data?.dairyCoverURL} />

          <div>
            <h1 className={styles.beachTitle}>
              {data?.description?.length > 60
                ? `${data?.description.slice(0, 60)}...`
                : data?.description}
            </h1>
            <ul className={styles.beachDetails}>
              <li className={data?.isFreeForFanClub && styles.allowed}>
                {data?.isFreeForFanClub
                  ? 'Free for Fan Club'
                  : 'Not free for Fan club'}
              </li>
              <span className={styles.bar}></span>
              <li className={data?.isAllowComments && styles.allowed}>
                {data?.isAllowComments
                  ? 'Comment allowed'
                  : 'Comment not allowed'}
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.beachSessionActionContainer}>
          <input
            type='checkbox'
            className={styles.checkbox}
            onChange={() => handleDiarySelection(data?.diaryId)}
            checked={handleIsSelected?.(data?.diaryId)}
          />
          <Button
            text='Edit Diary'
            icon={DeleteIcon}
            onClick={() => {
              setOpenToEdit(!openToEdit);
            }}
          />
        </div>
      </section>
      <section className={styles.nyEditWrapperContainer} data-edit={openToEdit}>
        <Formik
          enableReinitialize={true}
          validationSchema={DiarySchema}
          initialValues={{
            // title: '',
            description: data?.description,
            isHidden: data?.isHidden,
            coverImage: data?.dairyCoverURL,
            diaryText: data?.diaryText,
            isCommentAllowed: data?.isAllowComments,
          }}
          onSubmit={handleUpdateDiary}
        >
          {(props) => {
            return (
              <section className={styles.nyContainer}>
                <section className={styles.coverImageContainer}>
                  {typeof props.values.coverImage === 'object' ? (
                    <img
                      className={styles.nyDairyCover}
                      src={URL.createObjectURL(props.values.coverImage)}
                    />
                  ) : (
                    <img
                      className={styles.nyDairyCover}
                      src={props.values.coverImage}
                    />
                  )}
                  {props.touched && props.errors?.coverImage && (
                    <span className={styles.error}>
                      * {props.errors?.coverImage}
                    </span>
                  )}

                  <div className={styles.optionContainer}>
                    <span className={styles.option}>
                      <span>Allow Comments</span>{' '}
                      <Switch
                        containerClass={styles.switchContainer}
                        checked={permission.isCommentAllowed}
                        name={'isCommentAllowed'}
                        onChange={(event) => {
                          handleCommentAllowed(event);
                        }}
                      />
                    </span>
                  </div>
                </section>
                <section className={styles.nyDetailWrapper}>
                  <div className={styles.actionContainer}>
                    {isLoading && <Spinner />}

                    <Button
                      text='Save'
                      className={styles.createButton}
                      onClick={props.handleSubmit}
                    />
                  </div>

                  <h1 className={styles.nyTitle}>{props.values.title}</h1>

                  <div className={styles.nyActionContainer}>
                    <label className={styles.nyLabel}>
                      <span> Permissions</span>

                      <ul className={styles.nyPermissions}>
                        <li
                          onClick={() =>
                            handleSetPermission('isFreeForFanClub')
                          }
                          className={classNames(
                            styles.nyPermission,
                            permission.isFreeForFanClub && styles.nyActive
                          )}
                        >
                          Free for fan club
                        </li>
                        <li
                          onClick={() => handleSetPermission('isHidden')}
                          className={classNames(
                            styles.nyPermission,
                            permission.isHidden && styles.nyActive
                          )}
                        >
                          Hidden
                        </li>
                      </ul>
                    </label>
                    <section className={styles.nyLabel}>
                      <span> Actions</span>

                      <div className={styles.nyDairyActionContainer}>
                        <FileUpload
                          handleFileChange={(file) => {
                            props.setFieldValue('coverImage', file);
                          }}
                          renderElement={
                            <Button
                              text='Change diary cover'
                              className={styles.nyChangeDiaryCoverButton}
                            />
                          }
                        />
                        <Button
                          onClick={() => handleDeleteDiary(data?.diaryId)}
                          icon={DeleteIcon}
                          text='Delete diary'
                          className={styles.nyChangeDiaryDeleteButton}
                        />
                      </div>
                    </section>
                  </div>
                  {/* <label className={styles.nyLabel}>
                    <span>Title</span>
                    <input
                      className={styles.input}
                      placeholder='Title'
                      name='title'
                      onChange={props.handleChange}
                    />
                    {props.touched && props.errors?.title && (
                      <span className={styles.error}>
                        * {props.errors?.title}
                      </span>
                    )}
                  </label> */}

                  <label className={styles.nyLabel}>
                    <span>Description</span>
                    <textarea
                      defaultValue={data?.description}
                      name='description'
                      onChange={props.handleChange}
                      className={styles.textarea}
                      placeholder='It`s my first travel to beach. And I prepared for you something special'
                    />
                    {props.touched && props.errors?.description && (
                      <span className={styles.error}>
                        * {props.errors?.description}
                      </span>
                    )}
                  </label>

                  <label className={styles.nyLabel}>
                    <span>Diary Text</span>
                    <div className={styles.editorContainer}>
                      <RichTextEditor
                        content={data?.diaryText}
                        onChange={(content) => {
                          props.setFieldValue('diaryText', content);
                        }}
                      />
                    </div>
                    {props.touched && props.errors?.diaryText && (
                      <span className={styles.error}>
                        * {props.errors?.diaryText}
                      </span>
                    )}
                  </label>
                </section>
              </section>
            );
          }}
        </Formik>
      </section>
    </>
  );
};

export default Dairy;
