import React, { Component } from 'react';
import Similar from '.././components/similar/Similar.js';

class NotFound extends Component {
    render() {
        return (
            <main className="container search-page">
                <section className="search-page--header notfound">
                    <h1 className="page-title">Most Relevant Video Results: Medison</h1>
                    <div className="notfound-title2">
                        Только не это! Нет результатов поиска по запросу: <span>Hppy Iuse</span>
                    </div>
                    <div className="notfound-title3">
                        Не сдавайтесь! Проверьте орфографию или попробуйте использовать общие термины
                    </div>
                </section>
                <section className="container">
                    <Similar/>
                </section>
                <section className="search-page-club">
                    <div className="container">
                        <div className="page-title">
                            Most Relevant Club Results: "Madison"
                        </div>
                        <div className="search-page-club--wrapper">
                            <div className="search-page-club-item">
                                <img src={require('../../images/trashimg/search123.png')} alt=""/>
                                <div className="search-page-club-item--title">
                                    The Park Ultra Lounge
                                </div>
                            </div>
                            <div className="search-page-club-item">
                                <img src={require('../../images/trashimg/search321.png')} alt=""/>
                                <div className="search-page-club-item--title">
                                    The Madison
                                </div>
                            </div>
                        </div>
                        <div className="search-page-club--wrapper">
                            <div className="search-page-new">
                                <div className="search-page-new--title">
                                    New models
                                </div>
                                <div className="search-page-new--name">
                                    Kate Hamilton
                                </div>
                                <div className="btn btn__red">
                                    Take a look
                                </div>
                                <img src={require('../../images/trashimg/034.png')} alt=""/>
                            </div>
                            <div className="search-page-club--adversting">
                                <img src={require('../../images/trashimg/image_2.6.png')} alt=""/>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}

export default NotFound;
