import styles from './settingPop.module.scss';
import Popup from 'reactjs-popup';
import { FaVideo } from 'react-icons/fa6';
import { BiSolidVolumeFull } from 'react-icons/bi';
import { ImTextHeight } from 'react-icons/im';
import classNames from 'classnames';
import { SlLogout } from 'react-icons/sl';
import { AiOutlineClose } from 'react-icons/ai';
import { useEffect, useState } from 'react';
import CheckBox from '../../../custom/checkbox/CheckBox';
import DropDown from '../../../custom/DropDown/DropDown';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { getCameras, getMicrophones } from '../../../utils/agoraManager';
import { CgSandClock } from 'react-icons/cg';
import Btn from '../../../custom/btn/Btn';
import notification from '../../../utils/notification';

const SettingPop = ({
  open,
  handleClose,
  config,
  handleSettingOnChange,
  handleChangeDevices,
  isOnline,
  handleResolution,
  applyFontSize,
  enableAudio,
  enableVideo,
}) => {
  const [tab, setTab] = useState('video');

  return (
    <Popup closeOnDocumentClick open={open} onClose={handleClose}>
      <main className={styles.container}>
        <section className={styles.navigationContainer}>
          <span
            onClick={() => {
              setTab('video');
            }}
            className={classNames(
              styles.inActive,
              tab === 'video' && styles.active
            )}
          >
            <span
              className={classNames(
                styles.inActiveInnerContainer,
                tab === 'video' && styles.activeInnerContainer
              )}
            >
              <FaVideo className={styles.icon} />
            </span>
          </span>
          <span
            onClick={() => {
              setTab('audio');
            }}
            className={classNames(
              styles.inActive,
              tab === 'audio' && styles.active
            )}
          >
            <span
              className={classNames(
                styles.inActiveInnerContainer,
                tab === 'audio' && styles.activeInnerContainer
              )}
            >
              <BiSolidVolumeFull className={styles.icon} />
            </span>
          </span>
          <span
            onClick={() => {
              setTab('size');
            }}
            className={classNames(
              styles.inActive,
              tab === 'size' && styles.active
            )}
          >
            <span
              className={classNames(
                styles.inActiveInnerContainer,
                tab === 'size' && styles.activeInnerContainer
              )}
            >
              <ImTextHeight className={styles.icon} />
            </span>
          </span>
          {/* <span
            onClick={() => {
              setTab('time');
            }}
            className={classNames(
              styles.inActive,
              tab === 'time' && styles.active
            )}
          >
            <span
              className={classNames(
                styles.inActiveInnerContainer,
                tab === 'time' && styles.activeInnerContainer
              )}
            >
              <CgSandClock className={styles.icon} />
            </span>
          </span> */}
          <span
            onClick={() => {
              setTab('record');
            }}
            className={classNames(
              styles.inActive,
              tab === 'record' && styles.active
            )}
          >
            {/* <span
              className={classNames(
                styles.inActiveInnerContainer,
                tab === 'record' && styles.activeInnerContainer
              )}
            >
              Rec <span className={styles.recordIcon}></span>
            </span> */}
          </span>
          <span
            onClick={() => {
              setTab('full-screen');
            }}
            className={classNames(
              styles.inActive,
              tab === 'full-screen' && styles.active
            )}
          >
            <span
              className={classNames(
                styles.inActiveInnerContainer,
                tab === 'full-screen' && styles.activeInnerContainer
              )}
            >
              <SlLogout className={styles.icon} />
            </span>
          </span>
        </section>
        <section className={styles.contentContainer}>
          <h1 className={styles.title}>Settings</h1>
          <span className={styles.closeIconContainer} onClick={handleClose}>
            <span className={styles.closeIconInnerContainer}>
              {' '}
              <AiOutlineClose className={styles.closeIcon} />
            </span>
          </span>
          {tab === 'size' && (
            <FontSizeSelection config={config} applyFontSize={applyFontSize} />
          )}
          {tab === 'video' && (
            <VideoSetting
              handleClose={handleClose}
              enableAudio={enableAudio}
              enableVideo={enableVideo}
              isOnline={isOnline}
              handleChangeDevices={handleChangeDevices}
              handleResolution={handleResolution}
            />
          )}
          {tab === 'audio' && (
            <SoundSetting
              config={config}
              handleSettingOnChange={handleSettingOnChange}
            />
          )}

          {/* {tab === 'time' && <TimePreviewSelection />} */}

          {/* {tab === 'record' && (
            <RecordSetting
              config={config}
              handleSettingOnChange={handleSettingOnChange}
            />
          )} */}
          {tab === 'full-screen' && <FullPageSetting />}
        </section>
      </main>
    </Popup>
  );
};

const FullPageSetting = ({}) => {
  return (
    <>
      <h1 className={styles.settingTitle}>
        More settings like Tip menu, and advanced broadcast settings
      </h1>

      <a
        className={styles.fullPageLink}
        href='/broadcast-setting'
        target={'_blank'}
      >
        Go to Full Settings Page{' '}
        <SlLogout className={styles.fullPageLinkICon} />
      </a>
    </>
  );
};

const RecordSetting = ({ handleSettingOnChange, config }) => {
  return (
    <main className={styles.settingActionContainer}>
      <section>
        <h1 className={styles.settingTitle}>Event Records</h1>

        <div className={styles.recordSettingListItem}>
          <div className={styles.leftRecordSettingListItem}>
            <span>Allow show recording</span>
          </div>
          <CheckBox
            checkedColor={'#775E96'}
            className={styles.checkBox}
            onChange={handleSettingOnChange}
            name='allowShowRecording'
            checked={config?.allowShowRecording}
          />
        </div>

        <h5 className={styles.recordCreditTitle}>
          Recording Credits (per minute)
        </h5>
        <input
          type={'number'}
          className={styles.recordCreditInput}
          defaultValue={2}
          // onChange={handleSettingOnChange}
          // name='recordingCredit'
          placeholder='2'
        />
      </section>

      <section className={styles.actionContainer}>
        <Btn className={styles.cancelButton} text='Cancel' />

        <button className={styles.saveButton}>Save</button>
      </section>
    </main>
  );
};

const TimePreviewSelection = ({}) => {
  const [selectedTimes, setSelectedTimes] = useState({
    label: '5 minutes (default)',
    time: '2',
  });
  const [times, seTimes] = useState([
    {
      label: '2 minutes',
      time: '2',
    },
    {
      label: '5 minutes (default)',
      time: '2',
    },
    {
      label: '10 minutes',
      time: '2',
    },
    {
      label: '30 minutes',
      time: '2',
    },
  ]);

  const handleTimeChange = (event) => {
    setSelectedTimes(() => {
      return {
        ...event.value,
      };
    });
  };

  const handleApplyTime = async () => {};

  return (
    <main className={styles.settingActionContainer}>
      <section>
        <h1 className={styles.settingTitle}>
          Time limit for general show preview
        </h1>
        <DropDown
          customItemRender={itemRenderer}
          onChange={handleTimeChange}
          options={times.map((time) => {
            return {
              label: time.label,
              value: time,
            };
          })}
          selectClass={styles.select}
          className={styles.selectContainer}
          dropElement={({ props, state }) => (
            <div className={styles.settingDropDownRender}>
              <span className={styles.selectedText}>
                {' '}
                {selectedTimes?.label}
              </span>

              <RiArrowDropDownLine className={styles.icon} />
            </div>
          )}
        />
      </section>

      <section className={styles.actionContainer}>
        <Btn
          onClick={handleApplyTime}
          className={styles.cancelButton}
          text='Cancel'
        />

        <button onClick={handleApplyTime} className={styles.saveButton}>
          Save
        </button>
      </section>
    </main>
  );
};

const FontSizeSelection = ({ applyFontSize, config }) => {
  const [selectedFontSize, setSelectedFontSize] = useState(config.textSizeData);
   const [defaultValue, setDefaultValue] = useState('');
   const [value, setValue] = useState(defaultValue?.fontSize);
   const [fontSizes, setFontSizes] = useState([
     {
       label: 'Small',
       fontSize: 'sm',
     },
     {
       label: 'Normal',
       fontSize: 'md',
     },
     {
       label: 'Large',
       fontSize: 'lg',
     },
     {
       label: 'Very Large',
       fontSize: 'xlg',
     },
   ]);

   const handleFontSizeChange = (event) => {
     setSelectedFontSize(() => {
       return {
         ...event.value,
       };
     });
     setValue(event.value?.fontSize);
   };

   const handleApplyFontSize = async () => {
     await applyFontSize(value, 'live');
   };

   const getDefaultValue = () => {
     const defaultFilter = fontSizes.find((font) => {
       return font.fontSize === config.textSizeData;
     });
     setSelectedFontSize(defaultFilter);
   };

   useEffect(() => {
     getDefaultValue();
   }, [config.textSizeData]);

   return (
     <main className={styles.settingActionContainer}>
       <section>
         <h1 className={styles.settingTitle}>Chat font size</h1>
         <DropDown
           customItemRender={itemRenderer}
           onChange={handleFontSizeChange}
           defaultValue={[defaultValue]}
           options={fontSizes.map((fontSize) => {
             return {
               label: fontSize.label,
               value: fontSize,
             };
           })}
           selectClass={styles.select}
           className={styles.selectContainer}
           dropElement={({ props, state }) => (
             <div className={styles.settingDropDownRender}>
               <span className={styles.selectedText}>
                 {selectedFontSize?.label}
               </span>

               <RiArrowDropDownLine className={styles.icon} />
             </div>
           )}
         />
       </section>
       <section className={styles.actionContainer}>
         <Btn
           onClick={handleApplyFontSize}
           className={styles.cancelButton}
           text='Cancel'
         />

         <button onClick={handleApplyFontSize} className={styles.saveButton}>
           Save
         </button>
       </section>
     </main>
   );
};

const itemRenderer = ({ item, itemIndex, props, state, methods }) => (
  <div
    className={styles.dropDownItemContainer}
    key={itemIndex}
    onClick={() => methods.addItem(item)}
  >
    <span key={itemIndex} className={styles.dropDownItem}>
      {item[props.labelField]}{' '}
    </span>
  </div>
);

const VideoSetting = ({
  isOnline,
  handleChangeDevices,
  handleResolution,
  enableAudio,
  enableVideo,
  handleClose,
}) => {
  const [userCameras, setUserCameras] = useState([]);
  const [selectedResolution, setSelectedResolution] = useState({
    width_height: '320 x 240',
    bitrate: 200,
    framerate: 15,
  });
  const [resolutions, setResolution] = useState([
    {
      width_height: '320 x 240',
      bitrate: 200,
      framerate: 15,
    },
    {
      width_height: '640 x 360',
      bitrate: 400,
      framerate: 15,
    },
    {
      width_height: '160 x 120',
      bitrate: 65,
      framerate: 15,
    },
    {
      width_height: '320 x 180',
      bitrate: 140,
      framerate: 15,
    },
    {
      width_height: '120 x 120',
      bitrate: 50,
      framerate: 15,
    },
  ]);
  const [userMicrophones, setUserMicrophones] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState({
    camera: {
      label: 'None',
      deviceId: '',
    },
    microphone: {
      label: 'None',
      deviceId: '',
    },
  });

  const handleCameralChange = (event) => {
    setSelectedDevices((prev) => {
      return {
        ...prev,
        camera: event.value,
      };
    });
  };

  const handleMicrophoneChange = (event) => {
    setSelectedDevices((prev) => {
      return {
        ...prev,
        microphone: event.value,
      };
    });
  };

  const getUserCameras = async () => {
    const cameras = await getCameras();
    if (cameras.length > 0) {
      setSelectedDevices((prev) => {
        return {
          ...prev,
          camera: cameras[0],
        };
      });
    }
    setUserCameras(cameras);
  };

  const getUserMicrophones = async () => {
    const microphones = await getMicrophones();
    if (microphones.length > 0) {
      setSelectedDevices((prev) => {
        return {
          ...prev,
          microphone: microphones[0],
        };
      });
    }
    setUserMicrophones(microphones);
  };

  const handleChangeDevice = async () => {
    try {
      await handleChangeDevices({
        microphoneId: selectedDevices?.microphone?.deviceId,
        cameraId: selectedDevices?.camera?.deviceId,
      });
    } catch (error) {}
  };

  const handleResolutionChange = async (value) => {
    await handleResolution(value);
  };

  const handleSelectedResolution = async (event) => {
    const value = event.value;

    setSelectedResolution(() => {
      return {
        ...value,
      };
    });
    await handleResolutionChange(value);
  };

  useEffect(() => {
    if (isOnline) {
      getUserCameras();
      getUserMicrophones();
    }
  }, []);

  useEffect(() => {
    if (isOnline) {
      getUserCameras();
      getUserMicrophones();
    }
  }, [enableAudio, enableVideo]);
  return (
    <main className={styles.settingActionContainer}>
      <section>
        <h1 className={styles.settingTitle}>Event videos</h1>
        <DropDown
          customItemRender={itemRenderer}
          onChange={handleCameralChange}
          options={userCameras.map((camera) => {
            return {
              label:
                camera?.label?.length > 35
                  ? `${camera?.label?.slice(0, 35)}...`
                  : camera?.label,
              value: camera,
            };
          })}
          selectClass={styles.select}
          className={styles.selectContainer}
          dropElement={({ props, state }) => (
            <div className={styles.settingDropDownRender}>
              <span className={styles.selectedText}>
                {' '}
                {selectedDevices?.camera?.label?.length > 11
                  ? `${selectedDevices?.camera?.label?.slice(0, 11)}...`
                  : selectedDevices?.camera?.label}
              </span>

              <div className={styles.type}>
                Cameral <RiArrowDropDownLine className={styles.icon} />
              </div>
            </div>
          )}
        />

        <DropDown
          customItemRender={itemRenderer}
          onChange={handleMicrophoneChange}
          options={userMicrophones.map((mic) => {
            return {
              label:
                mic?.label?.length > 35
                  ? `${mic?.label?.slice(0, 35)}...`
                  : mic?.label,
              value: mic,
            };
          })}
          selectClass={styles.select}
          className={styles.selectContainer}
          dropElement={({ props, state }) => (
            <div className={styles.settingDropDownRender}>
              <span className={styles.selectedText}>
                {selectedDevices?.microphone?.label?.length > 11
                  ? `${selectedDevices?.microphone?.label?.slice(0, 11)}...`
                  : selectedDevices?.microphone?.label}
              </span>

              <div className={styles.type}>
                Microphone <RiArrowDropDownLine className={styles.icon} />
              </div>
            </div>
          )}
        />

        <DropDown
          customItemRender={itemRenderer}
          onChange={handleSelectedResolution}
          options={resolutions.map((resolution) => {
            return {
              label: resolution.width_height,
              value: resolution,
            };
          })}
          selectClass={styles.select}
          className={styles.selectContainer}
          dropElement={({ props, state }) => (
            <div className={styles.settingDropDownRender}>
              <span className={styles.selectedText}>
                {selectedResolution.width_height}
              </span>

              <div className={styles.type}>
                Resolution <RiArrowDropDownLine className={styles.icon} />
              </div>
            </div>
          )}
        />
      </section>
      <section className={styles.actionContainer}>
        <Btn
          onClick={handleClose}
          className={styles.cancelButton}
          text='Cancel'
        />

        <button onClick={handleChangeDevice} className={styles.saveButton}>
          Save
        </button>
      </section>
    </main>
  );
};

const SoundSetting = ({ config, handleSettingOnChange }) => {
  return (
    <main className={styles.settingActionContainer}>
      <section>
        <h1 className={styles.settingTitle}>Event sounds</h1>
        <ul>
          <li className={styles.soundSettingListItem}>
            <div className={styles.leftSoundSettingListItem}>
              <PlayIcon /> <span>User entered</span>
            </div>
            <CheckBox
              onChange={handleSettingOnChange}
              name='userEnteredSound'
              checkedColor={'#775E96'}
              className={styles.checkBox}
              checked={config?.userEnteredSound}
            />
          </li>
          <li className={styles.soundSettingListItem}>
            <div className={styles.leftSoundSettingListItem}>
              <PlayIcon /> <span>Message Received</span>
            </div>
            <CheckBox
              onChange={handleSettingOnChange}
              name='messageReceivedSound'
              checkedColor={'#775E96'}
              className={styles.checkBox}
              checked={config?.messageReceivedSound}
            />
          </li>
          <li className={styles.soundSettingListItem}>
            <div className={styles.leftSoundSettingListItem}>
              <PlayIcon /> <span>Tip received</span>
            </div>
            <CheckBox
              onChange={handleSettingOnChange}
              name='tipReceivedSound'
              checkedColor={'#775E96'}
              className={styles.checkBox}
              checked={config?.tipReceivedSound}
            />
          </li>
        </ul>
      </section>
      <section className={styles.actionContainer}>
        <Btn className={styles.cancelButton} text='Cancel' />

        <button className={styles.saveButton}>Save</button>
      </section>
    </main>
  );
};

const PlayIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <circle opacity='0.15' cx='12' cy='12' r='12' fill='white' />
      <path d='M19 12L8.5 18.0622L8.5 5.93782L19 12Z' fill='#B87693' />
    </svg>
  );
};

export default SettingPop;
