import Popup from 'reactjs-popup';
import styles from './participant.module.scss';
import { AiOutlineClose } from 'react-icons/ai';
import { HiDotsVertical } from 'react-icons/hi';
import { useEffect, useState } from 'react';
import {
  getStreamMemberDisabled,
  getListOfStreamMemberDisabled,
} from '../../../request/model';
import { getMemberProfile } from '../../../request/member';
import notification from '../../../utils/notification';
import errorFormmatter from '../../../utils/errorFormatter';
import { disableMemberChat } from '../../../request/model';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

const Participants = ({
  open,
  handleCloseParticipants,
  participants = [],
  startPrivateCallFromParticipantModal,
}) => {
  const user = useSelector((state) => state?.user);
  const [listOfBannedMember, setListOfBannedMember] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  const getUserDisabledFromChat = async () => {
    try {
      const response = await getListOfStreamMemberDisabled({
        modelId: user.clientId.trim(),
        // memberId: userId,
      });
      const filteredData = response?.filter((data) => {
        return data?.kickOut === true;
      });
      setListOfBannedMember(filteredData);
    } catch (error) {
      setListOfBannedMember([]);
    }
  };

  useEffect(() => {
    getUserDisabledFromChat();
  }, []);

  useEffect(() => {
    getUserDisabledFromChat();
  }, [isSaving]);

  return (
    <Popup onClose={handleCloseParticipants} closeOnDocumentClick open={open}>
      <main className={styles.container}>
        <div className={styles.innerContainer}>
          <div
            onClick={handleCloseParticipants}
            className="cursor-pointer absolute top-[0] right-[0] flex justify-center items-center w-12 h-12  before:content-['']  bg-stone-400 opacity-[20]"
          >
            <AiOutlineClose className='z-[999] relative text-[1.5rem]  color-white' />
          </div>

          <h1 className={styles.title}>List of participants</h1>
          <div className={styles.subTitleContainer}>
            <span>
              Participants :{' '}
              <span className={styles.totalParticipant}>
                {participants.length}
              </span>
            </span>
            <span>
              Total credits : <span className={styles.totalCredits}>0</span>
            </span>
          </div>

          <ul className='mb-[2rem]'>
            {participants.map((participant, index) => {
              return (
                <Member
                  isSaving={isSaving}
                  setIsSaving={setIsSaving}
                  startPrivateCallFromParticipantModal={
                    startPrivateCallFromParticipantModal
                  }
                  key={participant?.id?.value}
                  participant={participant}
                />
              );
            })}
          </ul>

          <div className="text-zinc-400 text-sm  mb-[1.5rem] font-medium font-['Montserrat'] leading-tight">
            Kick out participants
          </div>

          {listOfBannedMember.map((member, index) => {
            return (
              <BannedMember
                isSaving={isSaving}
                setIsSaving={setIsSaving}
                key={member?.memberId}
                participant={member}
              />
            );
          })}
        </div>
      </main>
    </Popup>
  );
};

const BannedMember = ({ participant, isSaving, setIsSaving }) => {
  const [memberProfile, setMemberProfile] = useState({});
  const [member, setMember] = useState({
    kickOut: participant?.kickOut,
    ...participant,
  });

  const [open, setOpen] = useState(false);
  const user = useSelector((state) => state?.user);
  let userId = participant?.memberId;

  const kickOutMember = async () => {
    try {
      setIsSaving(true);
      const payload = new FormData();
      payload.append('kickOut', !member.kickOut);
      payload.append('memberId', userId);
      payload.append('registeredId', user.clientId);
      await disableMemberChat({ payload });
      setOpen(false);
      setIsSaving(false);
    } catch (error) {
      setIsSaving(false);
      notification({
        title: '',
        message: errorFormmatter(error),
        type: 'danger',
      });
    }
  };

  const getProfile = async () => {
    try {
      const response = await getMemberProfile(userId);
      setMemberProfile(response);
    } catch (error) {
      setMemberProfile({});
    }
  };

  useEffect(() => {
    getProfile();
    setMember((prev) => {
      return {
        ...prev,
        ...participant,
      };
    });
  }, [participant]);

  return (
    <li
      className={classNames(
        styles.participant,
        '!flex items-center justify-between'
      )}
    >
      <span
        className={classNames(styles.participantProfileContainer, 'w-[100%]')}
      >
        {' '}
        <img
          alt='pro'
          className={styles.participantProfile}
          src={memberProfile?.profilePicUrl}
        />{' '}
        <span
          className={classNames(
            styles.participantProfileName,
            '!flex justify-between items-center grow'
          )}
        >
          <span>{memberProfile?.fullName}</span>
          {userId === user.clientId && (
            <span className='w-3 h-3 bg-green-500 rounded-full'></span>
          )}
        </span>
      </span>

      {userId !== user.clientId && (
        <HiDotsVertical
          onClick={() => {
            setOpen(!open);
          }}
        />
      )}

      {open && (
        <ul className={classNames(styles.optionContainer, '!z-[9999]')}>
          <li
            className={styles.option}
            onClick={() => {
              kickOutMember();
              setOpen(false);
            }}
          >
            Allowed back
          </li>
        </ul>
      )}
    </li>
  );
};

const Member = ({
  isSaving,
  setIsSaving,
  participant,
  startPrivateCallFromParticipantModal,
}) => {
  const [listOfDisabledMember, setListOfDisabledMember] = useState([]);
  // const [isSaving, setIsSaving] = useState(false);

  const [member, setMember] = useState({
    disableChat: false,
    kickOut: false,
  });
  const [open, setOpen] = useState(false);
  const user = useSelector((state) => state?.user);
  let userId = '';

  if (participant?.id?.value?.includes('host-')) {
    userId = participant?.id?.value?.split('host-')[1]?.trim() ?? '';
  }

  if (participant?.id?.value?.includes('audience-')) {
    userId = participant?.id?.value?.split('audience-')[1] ?? '';
  }

  const handleDisableMemberChat = async () => {
    try {
      setIsSaving(true);
      const payload = new FormData();
      payload.append('disableChat', !member.disableChat);
      payload.append('kickOut', member.kickOut);
      payload.append('memberId', userId);
      payload.append('registeredId', user.clientId);
      await disableMemberChat({ payload });
      setOpen(false);
      setIsSaving(false);
    } catch (error) {
      setIsSaving(false);
      notification({
        title: '',
        message: errorFormmatter(error),
        type: 'danger',
      });
    }
  };

  const getUserDisabledFromChat = async () => {
    try {
      const response = await getListOfStreamMemberDisabled({
        modelId: user.clientId.trim(),
        // memberId: userId,
      });
      setListOfDisabledMember(response);
    } catch (error) {
      setListOfDisabledMember([]);
    }
  };

  const kickOutMember = async () => {
    try {
      setIsSaving(true);
      const payload = new FormData();
      payload.append('disableChat', member.disableChat);
      payload.append('kickOut', !member.kickOut);
      payload.append('memberId', userId);
      payload.append('registeredId', user.clientId);
      await disableMemberChat({ payload });
      setOpen(false);
      setIsSaving(false);
    } catch (error) {
      setIsSaving(false);
      notification({
        title: '',
        message: errorFormmatter(error),
        type: 'danger',
      });
    }
  };

  useEffect(() => {
    getUserDisabledFromChat();
  }, []);

  useEffect(() => {
    getUserDisabledFromChat();
  }, [isSaving]);

  useEffect(() => {
    if (participant?.id?.value?.includes('audience-')) {
      const _userMember = listOfDisabledMember.find((userMember) => {
        return userMember?.memberId === userId;
      });

      if (_userMember) {
        setMember((prev) => {
          return {
            ...prev,
            ..._userMember,
          };
        });
      }
    }
  }, [listOfDisabledMember]);

  return (
    <li
      className={classNames(
        styles.participant,
        '!flex items-center justify-between'
      )}
    >
      <span
        className={classNames(styles.participantProfileContainer, 'w-[100%]')}
      >
        {' '}
        <img
          alt='profile'
          className={styles.participantProfile}
          src={participant?.profileUrl?.value}
        />{' '}
        <span
          className={classNames(
            styles.participantProfileName,
            '!flex justify-between items-center grow'
          )}
        >
          <span>{participant?.fullName?.value}</span>
          {userId === user.clientId && (
            <span className='w-3 h-3 bg-green-500 rounded-full'></span>
          )}
        </span>
      </span>

      {userId !== user.clientId && (
        <HiDotsVertical
          onClick={() => {
            setOpen(!open);
          }}
        />
      )}

      {open && (
        <ul className={classNames(styles.optionContainer, '!z-[9999]')}>
          <li
            className={styles.option}
            onClick={() => {
              handleDisableMemberChat();
              setOpen(false);
            }}
          >
            {member?.disableChat ? 'Enable chat' : 'Disable chat'}
          </li>
          <li
            className={styles.option}
            onClick={() => {
              kickOutMember();
              setOpen(false);
            }}
          >
            {member?.kickOut ? 'Allowed back' : 'Kick Out / Ban '}
          </li>
          <li
            className={styles.option}
            onClick={() => {
              startPrivateCallFromParticipantModal(userId);
              setOpen(false);
            }}
          >
            Start Private Chat
          </li>
        </ul>
      )}
    </li>
  );
};

export default Participants;
