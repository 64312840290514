import React, { Component } from 'react';
import Masonry from "react-masonry-component";
import {Link} from "react-router-dom";
import ModelCard from "../components/card/ModelCard"

class Search extends Component {
    render() {
        return (
            <main className="container search-page">
                <section className="search-page--header">
                    <h1 className="page-title">Most Relevant Video Results: Medison</h1>
                    <div className="main-category-sort">
                        <div className="main-category-sort-wrapper">
                            <div className="main-category-sort-recommended main-category-sort-item">Most relevant</div>
                            <ul className=" main-category-sort-item--menu">
                                <li><Link to="/">Most Popular</Link></li>
                                <li><Link to="/">Newbies</Link></li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="search-page-result">
                    <Masonry className={'search-page-result-wrapper'} >
                        <div className="search-page-result-item">
                            <ModelCard/>
                        </div>
                        <div className="search-page-result-item">
                            <ModelCard/>
                        </div>
                        <div className="search-page-result-item">
                            <ModelCard/>
                        </div>
                        <div className="search-page-result-item">
                            <ModelCard/>
                        </div>
                        <div className="search-page-result-item">
                            <ModelCard/>
                        </div>
                        <div className="search-page-result-item search-page-result-item__advertising">
                            <img src={require('../../images/trashimg/image2.6.jpg')} alt=""/>
                        </div>
                        <div className="search-page-result-item">
                            <ModelCard/>
                        </div>
                        <div className="search-page-result-item">
                            <ModelCard/>
                        </div>
                        <div className="search-page-result-item">
                            <ModelCard/>
                        </div>
                        <div className="search-page-result-item">
                            <ModelCard/>
                        </div>
                        <div className="search-page-result-item">
                            <ModelCard/>
                        </div>
                        <div className="search-page-result-item">
                            <ModelCard/>
                        </div>
                        <div className="search-page-result-item">
                            <ModelCard/>
                        </div>
                        <div className="search-page-result-item">
                            <ModelCard/>
                        </div>
                        <div className="search-page-result-item">
                            <ModelCard/>
                        </div>
                        <div className="search-page-result-item">
                            <ModelCard/>
                        </div>
                        <div className="search-page-result-item">
                            <ModelCard/>
                        </div>
                    </Masonry>
                    <div className="pagination">
                        <ul>
                            <li className="pagination__active">1</li>
                            <li>2</li>
                            <li>3</li>
                            <li>4</li>
                            <li>5</li>
                            <li className="pagination-next">
                                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="16" viewBox="0 0 9 16"
                                     fill="none">
                                    <path
                                        d="M0.69402 0.788759C0.482201 1.00058 0.482201 1.34478 0.69402 1.5566L7.45898 8.30833L0.69402 15.0733C0.482202 15.2851 0.482202 15.6293 0.69402 15.8411C0.905839 16.053 1.25005 16.053 1.46186 15.8411L8.59751 8.70549C8.70342 8.59958 8.75637 8.46719 8.75637 8.32157C8.75637 8.18918 8.70342 8.04355 8.59751 7.93764L1.46186 0.801999C1.25004 0.576941 0.905839 0.576941 0.69402 0.788759Z"
                                        fill="white"></path>
                                </svg>
                            </li>
                        </ul>
                    </div>
                </section>
                <section className="search-page-club">
                    <div className="container">
                        <div className="page-title">
                            Most Relevant Club Results: "Madison"
                        </div>
                        <div className="search-page-club--wrapper">
                            <div className="search-page-club-item">
                                <img src={require('../../images/trashimg/search123.png')} alt=""/>
                                <div className="search-page-club-item--title">
                                    The Park Ultra Lounge
                                </div>
                            </div>
                            <div className="search-page-club-item">
                                <img src={require('../../images/trashimg/search321.png')} alt=""/>
                                <div className="search-page-club-item--title">
                                    The Madison
                                </div>
                            </div>
                        </div>
                        <div className="search-page-club--wrapper">
                            <div className="search-page-new">
                                <div className="search-page-new--title">
                                    New models
                                </div>
                                <div className="search-page-new--name">
                                    Kate Hamilton
                                </div>
                                <div className="btn btn__red">
                                    Take a look
                                </div>
                                <img src={require('../../images/trashimg/034.png')} alt=""/>
                            </div>
                            <div className="search-page-club--adversting">
                                <img src={require('../../images/trashimg/image_2.6.png')} alt=""/>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}

export default Search;
