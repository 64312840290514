import AgoraRTC from 'agora-rtc-sdk-ng';
import AgoraRTM from 'agora-rtm-sdk';
import { v4 as uuidv4 } from 'uuid';
import { getStreamingConfig } from '../request/model';
import axios from 'axios';
import messageSound from '../sounds/message-alert.wav';
import joinedSound from '../sounds/join-alert.mp3';
import tipSound from '../sounds/tips-alert.wav';

let _client = null;
let _messageClient = null;
let broadcastChannel = null;
let id = process.env.REACT_APP_AGORA_APP_ID;

const getToken = async (userId = '', channel, role) => {
  try {
    const response = await axios({
      url: `https://nsachatserver.onrender.com/rtcToken?channelName=${channel}&uid=${userId}&role=${role}`, //
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      },
    });

    const tokens = response.data;

    return tokens ?? { rtc: '', rtm: '' };
  } catch (error) {
    return { rtc: '', rtm: '' };
  }
};

export const useAgoraStreaming = () => {
  const initStreaming = async (userId = '', channel, role = 'audience') => {
    try {
      const { rtc, rtm } = await getToken(userId, channel, role);
      if (rtc !== '' && rtm !== '') {
        _client = new AgoraRTC.createClient({
          mode: 'rtc',
          codec: 'vp9',
        });

        _messageClient = new AgoraRTM.RTM(id, userId, {
          token: rtm,
        });

        await _messageClient.login({ token: rtm });
        await _client.join(rtc, channel, null, 0);
       
        broadcastChannel = _messageClient.createStreamChannel(channel);
        await broadcastChannel.join({
          withMetadata: true,
          token: rtc,
        });
        await _messageClient.subscribe(channel);
        return true;
      }
      return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const createLocalRtcTrack = async (
    config = {
      audioConfig: {
        ANS: true,
        AEC: true,
      },
      videoConfig: {
        optimizationMode: 'detail',
        encoderConfig: {
          bitrateMax: 3000,
          bitrateMin: 2000,
          frameRate: 30,
          height: { max: 1280, min: 720 },
          width: { max: 1280, min: 720 },
        },
      },
    }
  ) => {
    try {
      return await new AgoraRTC.createMicrophoneAndCameraTracks(
        config.audioConfig,
        config.videoConfig
      );
    } catch (error) {
      throw error;
    }
  };

  const publishTrack = async (tracks) => {
    try {
      if (_client) {
        await _client.publish([...tracks]);
      }
    } catch (error) {
      throw error;
    }
  };

  const subScribeUserTrack = async (user, mediaType) => {
    try {
      if (_client) {
        await _client.subscribe(user, mediaType);
      }
    } catch (error) {
      throw error;
    }
  };

  const unsubScribeUserTrack = async (user, mediaType) => {
    try {
      if (_client) {
        await _client.unsubscribe(user, mediaType);
      }
    } catch (error) {
      throw error;
    }
  };

  const unPublishTrack = async (tracks) => {
    try {
      if (_client) {
        await _client.unpublish([tracks[0], tracks[1]]);
      }
    } catch (error) {
      throw error;
    }
  };

  const setRtcResolution = async (config) => {
    try {
      if (_client) {
        await _client.setLowStreamParameter(config);
      }
    } catch (error) {
      throw error;
    }
  };

  const registerRtcEvent = async (eventName, handler) => {
    try {
      if (_client) {
        _client.on(eventName, handler);
      }
    } catch (error) {
      throw error;
    }
  };

  const registerRtmMessagingEvent = async (eventName, handler) => {
    try {
      if (_messageClient) {
        _messageClient.addEventListener(eventName, handler);
      }
    } catch (error) {
      throw error;
    }
  };

  const enableRtcUserTrack = async (user, mediaType) => {
    try {
      const uid = user.uid.toString();

      if (mediaType === 'video') {
        user?.videoTrack?.play(`user-${uid}`);
      }

      if (mediaType === 'audio') {
        user?.audioTrack?.play();
      }
    } catch (error) {
      throw error;
    }
  };

  const handleAddLocalUserToView = async (domRef, userId) => {
    try {
      
      if (document.getElementById(`user-${userId}`)) {
        document.getElementById(`user-${userId}`).remove();
      }

      if (document.getElementById(`user-${userId}`) === null) {
        const player = ` <div
                  class='streamer_player'
                  id=${`user-${userId}`}
                ></div>`;
        domRef?.current?.insertAdjacentHTML('beforeend', player);
      }
    } catch (error) {}
  };

  const handleRemoveLocalUserFromView = async (userId) => {
    try {
      document.getElementById(`user-${userId}`)?.remove();
    } catch (error) {}
  };

  const removeMemberFromMessagingView = async (userId) => {
    try {
      document.querySelector(`#joined_user-container-${userId}`)?.remove();
    } catch (error) {}
  };

  const removeRemoteUserFromView = async (user) => {
    try {
      document.querySelector(`#user-container-${user.uid}`)?.remove();
    } catch (error) {}
  };

  const addAllMembersToMessagingView = async (domRef, members) => {
    try {
      for (let i = 0; i < members.length; i++) {
        const user_id = members[i]?.userId;
        const { metadata } = await getBroadcastMemberDetails(user_id);

        if (
          document.getElementById(`joined_user-container-${user_id}`) ===
            null &&
          metadata.id.value
        ) {
          const player = `<div
                class='joinedBroadcastUserContainer'
                id='joined_user-container-${user_id}'
              >
                <div class='joined_user' id='join_user-${user_id}'>
                  <img src=${metadata?.profileUrl?.value}  alt='profile'/>
                  <span>${metadata?.fullName.value}</span>
                </div>
                <span class='joined'>Joined</span>
              </div>`;

          domRef?.current?.insertAdjacentHTML('afterbegin', player);
        }
      }
    } catch (error) {}
  };

  const updateRtcMemberDetails = async (data, userId) => {
    try {
      if (_messageClient) {
        await _messageClient.storage.setUserMetadata(data, {
          userId: userId,
          addTimeStamp: true,
          addUserId: true,
          majorRevision: -1,
        });
      }
    } catch (error) {
      throw error;
    }
  };

  const getRtcMembers = async (channel) => {
    try {
      if (_messageClient) {
        return _messageClient.presence.getOnlineUsers(channel, 'MESSAGE', {
          includedState: true,
        });
      }
    } catch (error) {
      console.log(error, 'member error');
    }
  };

  const getBroadcastMemberDetails = async (userId) => {
    try {
      if (_messageClient) {
        return await _messageClient.storage.getUserMetadata({ userId });
      }
    } catch (error) {
      throw error;
    }
  };

  const handleBroadcastingChannelMessage = async (
    message,
    messageContentRef
  ) => {
    const parsedMessage = JSON.parse(message);
    if (parsedMessage.type === 'bot') {
      await addBotMessageToView(messageContentRef, parsedMessage);
    } else if (parsedMessage.type === 'tip') {
      await addTipMessageToView(messageContentRef, parsedMessage);
    } else {
      await addMessageToView(messageContentRef, parsedMessage);
    }
  };

  const handleBroadcastingLocalChannelMessage = async (
    messages = [],
    messageContentRef
  ) => {
    for (let i = 0; i < messages.length; i++) {
      const parsedMessage = messages[i];
      if (parsedMessage.type === 'bot') {
        await addBotMessageToView(messageContentRef, parsedMessage);
      } else if (parsedMessage.type === 'tip') {
        await addTipMessageToView(messageContentRef, parsedMessage);
      } else {
        await addMessageToView(messageContentRef, parsedMessage);
      }
    }
  };

  const addTipMessageToView = async (
    messageContentRef,
    message,
    config = null
  ) => {
    try {
      const messageId = `message-${uuidv4()}`;

      const tipMessage = `<div class="bot_chat_MessageContainer" id=${messageId}>
                  <h1 class="tipMessageName ${config?.fontSize}ChatFontSize">${message?.author}</h1>
                  <p class="tipMessageText ${config?.fontSize}ChatFontSize">${message?.text}</p>
                </div>`;

      messageContentRef?.current?.insertAdjacentHTML('beforeend', tipMessage);
      if (message.optionalMessage) {
        const optionMessage = `<div class="bot_chat_MessageContainer" id=${messageId}>
                  <h1 class="tipMessageName ${config?.fontSize}ChatFontSize">Tip message</h1>
                  <p class="tipMessageText ${config?.fontSize}ChatFontSize">${message?.optionalMessage}</p>
                </div>`;
        messageContentRef?.current?.insertAdjacentHTML(
          'beforeend',
          optionMessage
        );
      }

      const lastMessage = document.getElementById(`${messageId}`);
      lastMessage?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    } catch (error) {}
  };

  const addBotMessageToView = async (
    messageContentRef,
    message,
    config = null
  ) => {
    try {
      const messageId = `message-${uuidv4()}`;

      const botMessage = `<div class="bot_chat_MessageContainer" id=${messageId}>
                  <h1 class="botMessageName ${config?.fontSize}ChatFontSize">${message?.author}</h1>
                  <p class="botMessageText ${config?.fontSize}ChatFontSize">${message?.text}</p>
                </div>`;

      messageContentRef?.current?.insertAdjacentHTML('beforeend', botMessage);

      const lastMessage = document.getElementById(`${messageId}`);
      lastMessage?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    } catch (error) {}
  };

  const addMessageToView = async (
    messageContentRef,
    message,
    config = null
  ) => {
    try {
      const messageId = `message-${uuidv4()}`;

      const messageView = `<div class="bot_chat_MessageContainer" id=${messageId}>
                  <h1 class="chatMessageName ${config?.fontSize}ChatFontSize">${message?.author}: </h1>
                  <p class="chatMessageText ${config?.fontSize}ChatFontSize">${message?.text}</p>
                </div>`;

      messageContentRef?.current?.insertAdjacentHTML('beforeend', messageView);
      const lastMessage = document.getElementById(`${messageId}`);

      lastMessage?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    } catch (error) {}
  };

  const broadcastMessage = async (channel, message) => {
    try {
      if (_messageClient) {
        await _messageClient.publish(channel, JSON.stringify(message));
      }
    } catch (error) {
      throw error;
    }
  };

  const sendMessage = async (
    channel,
    message,
    messageContentRef,
    config = null
  ) => {
    try {
      await broadcastMessage(channel, message);
      if (message.type === 'bot') {
        await addBotMessageToView(messageContentRef, message, config);
      } else if (message.type === 'tip') {
        await addTipMessageToView(messageContentRef, message, config);
      } else {
        await addMessageToView(messageContentRef, message, config);
      }
    } catch (error) {}
  };

  const leaveStreamingChannel = async (tracks, userId) => {
    try {
      if (_messageClient && _client) {
        //   await unPublishTrack(tracks);
        await _client?.leave();
        await broadcastChannel?.leave();
        await _messageClient?.logout();
        await handleRemoveLocalUserFromView(userId);
        await removeMemberFromMessagingView(userId);
        tracks[1]?.close();
        tracks[0]?.close();
      }
    } catch (error) {
      console.log(error, 'baba');
    }
  };

  const updateStreamingConfigs = async (channel, data) => {
    try {
      if (_messageClient) {
        await _messageClient.storage.setChannelMetadata(
          channel,
          'MESSAGE',
          data,
          {}
        );
      }
    } catch (error) {}
  };

  const getStreamingConfigs = async (modelId) => {
    try {
      return await getStreamingConfig({ modelId });
    } catch (error) {}
  };

  const getChannelConfigs = async (channel) => {
    try {
      if (_messageClient) {
        return await _messageClient.storage.getChannelMetadata(
          channel,
          'MESSAGE'
        );
      }
    } catch (error) {}
  };

  const handleMemberLeftMessaging = async (channel, memberId) => {
    try {
      const { metadata } = await getBroadcastMemberDetails(memberId);
      const message = {
        type: 'bot',
        text: `${metadata?.fullName?.value} has left the broadcast`,
        author: '🤖 Bot',
      };
      await broadcastMessage(channel, message);
      await removeMemberFromMessagingView(memberId);
    } catch (error) {}
  };

  const handleMemberJoinedMessaging = async (channel, memberId, domRef) => {
    try {
      const data = await getBroadcastMemberDetails(memberId);
      const { metadata } = data;

      if (Object.keys(metadata).length > 0) {
        const message = {
          type: 'bot',
          text: `Welcome to the broadcast ${metadata?.fullName?.value}`,
          author: '🤖 Bot',
        };

        await broadcastMessage(channel, message);
        await addBotMessageToView(domRef, message);
      }
    } catch (error) {}
  };

  const broadcastAlert = async (messageType = '') => {
    try {
      let sound = new Audio();
      sound.pause();
      if (messageType === 'joined') {
        sound = new Audio(joinedSound);
      } else if (messageType === 'message') {
        sound = new Audio(messageSound);
      } else if (messageType === 'tip') {
        sound = new Audio(tipSound);
      }
      sound.play();
    } catch (error) {}
  };

  const applyChatFontSize = async (fontSize) => {
    let botTexts = document.querySelectorAll('.botMessageText');
    const botNames = document.querySelectorAll('.botMessageName');
    const chatTexts = document.querySelectorAll('.chatMessageText');
    const chatNames = document.querySelectorAll('.chatMessageName');

    botTexts?.forEach((botText) => {
      botText.className = '';
      let className = `${fontSize}ChatFontSize`;
      botText.classList.add(`${className}`);
      botText.classList.add(`botMessageText`);
    });

    botNames?.forEach((botName) => {
      botName.className = '';
      let className = `${fontSize}ChatFontSize`;
      botName.classList.add(`${className}`);
      botName.classList.add(`botMessageName`);
    });

    chatTexts?.forEach((chatText) => {
      chatText.className = '';
      let className = `${fontSize}ChatFontSize`;
      chatText.classList.add(`${className}`);
      chatText.classList.add(`chatMessageText`);
    });

    chatNames?.forEach((chatName) => {
      chatName.className = '';
      let className = `${fontSize}ChatFontSize`;
      chatName.classList.add(`${className}`);
      chatName.classList.add(`chatMessageName`);
    });
  };

  const micChangeDevice = async (localAudioTrack) => {
    if (_client) {
      _client.onMicrophoneChanged = async (changedDevice) => {
        if (changedDevice.state === 'ACTIVE') {
          localAudioTrack.setDevice(changedDevice.device.deviceId);
          // Switch to an existing device when the current device is unplugged.
        } else if (
          changedDevice.device.label === localAudioTrack.getTrackLabel()
        ) {
          const oldMicrophones = await _client.getMicrophones();
          oldMicrophones[0] &&
            localAudioTrack.setDevice(oldMicrophones[0].deviceId);
        }
      };
    }
  };

  const camChangeDevice = async (localVideoTrack) => {
    if (_client) {
      _client.onCameraChanged = async (changedDevice) => {
        if (changedDevice.state === 'ACTIVE') {
          localVideoTrack.setDevice(changedDevice.device.deviceId);
          // Switch to an existing device when the current device is unplugged.
        } else if (
          changedDevice.device.label === localVideoTrack.getTrackLabel()
        ) {
          const oldCameras = await _client.getCameras();
          oldCameras[0] && localVideoTrack.setDevice(oldCameras[0].deviceId);
        }
      };
    }
  };

  const enableDualStream = async () => {
    if (_client) {
      await _client.enableDualStream();
    }
  };

  const getRemoteUsers = async () => {
    try {
      if (_client) {
        return await _client.remoteUsers;
      }
    } catch (error) {}
  };

  const preSubScribeUser = async (userId, mediaType) => {
    try {
      if (_client) {
        return await _client.presubscribe(userId, mediaType);
      }
    } catch (error) {}
  };

  const getStreamingId = async () => {
    try {
      if (_client) {
        return _client.uid;
      }
    } catch (error) {}
  };
  return {
    getStreamingId,
    preSubScribeUser,
    getRemoteUsers,
    getChannelConfigs,
    enableDualStream,
    micChangeDevice,
    camChangeDevice,
    removeMemberFromMessagingView,
    applyChatFontSize,
    broadcastAlert,
    handleMemberJoinedMessaging,
    handleMemberLeftMessaging,
    updateStreamingConfigs,
    getStreamingConfigs,
    publishTrack,
    leaveStreamingChannel,
    sendMessage,
    handleBroadcastingChannelMessage,
    getRtcMembers,
    updateRtcMemberDetails,
    removeRemoteUserFromView,
    enableRtcUserTrack,
    initStreaming,
    registerRtcEvent,
    createLocalRtcTrack,
    registerRtmMessagingEvent,
    addAllMembersToMessagingView,
    getBroadcastMemberDetails,
    handleAddLocalUserToView,
    unPublishTrack,
    subScribeUserTrack,
    setRtcResolution,
    handleBroadcastingLocalChannelMessage,
    unsubScribeUserTrack,
  };
};
