import { useSelector } from 'react-redux';
import { addFanClubPhoto, getFanClubPhoto } from '../../../request/model';
import PhotoPreview from '../PhotoPreview/ProfilePreview';
import AddButton from '../../../custom/addButton/AddButton';
import UploadPopup from '../../../custom/uploadPopup/UploadPopup';
import notification from '../../../utils/notification';
import { useEffect, useState } from 'react';
import styles from './photos.module.scss';
import ModelEditPopup from '../modelEditPopup/ModelEditPopup';
import errorFormmatter from '../../../utils/errorFormatter';
import { updateFanClubPhoto } from '../../../request/model';

const Photos = ({
  isSelectedAll,
  photoSetOptions,
  setSelectedPhotoIds,
  selectedPhotoIds,
}) => {
  const [file, setFile] = useState('');
  const [clubPhotos, setClubPhotos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [photoToEdit, setPhotoToEdit] = useState({});

  const [isOpenEditModelPhoto, setIsOpenEditModelPhoto] = useState(false);

  const user = useSelector((state) => state?.user);

  const handleOpenEditPhotoModal = (photo) => {
    setIsOpenEditModelPhoto(true);
    setPhotoToEdit(photo);
  };

  const handlePhotoSelection = (photoId) => {
    const id = selectedPhotoIds.find((id) => id === photoId);
    if (id) {
      const filteredPhotoIds = selectedPhotoIds.filter((id) => id !== photoId);
      setSelectedPhotoIds(filteredPhotoIds);
    } else {
      setSelectedPhotoIds((prev) => [...prev, photoId]);
    }
  };

  const handleIsSelected = (photoId) => {
    const id = selectedPhotoIds.find((id) => id === photoId);
    return id ? true : false;
  };

  const handleFileChange = (file) => {
    setFile(file);
  };

  const deletePhoto = async (id) => {
    try {
      const payload = new FormData();

      payload.append('isDeleted', true);
      payload.append('registeredId', user?.clientId);
      payload.append('photoId', id);

      setLoading(true);
      const response = await updateFanClubPhoto({ payload });
      setLoading(false);
      notification({
        message: response?.message ?? 'Fan club photo deleted successfully',
      });
    } catch (error) {
      notification({
        message: errorFormmatter(error) ?? 'Fan club photo failed to delete',
        type: 'danger',
      });
      setLoading(false);
    }
  };

  const handlePhotoUpload = async () => {
    try {
      const payload = new FormData();

      payload.append('registeredId', user?.clientId);
      payload.append('addImage', file);
      setLoading(true);
      const response = await addFanClubPhoto({ payload });
      setLoading(false);
      notification({
        message: response?.message ?? 'Fan club photo added successfully',
      });
    } catch (error) {
      notification({
        message: errorFormmatter(error) ?? 'Fan club photo failed to add',
        type: 'danger',
      });
      setLoading(false);
    }
  };

  const getFanClubPhotos = async () => {
    try {
      const response = await getFanClubPhoto({ modelId: user.clientId });

      setClubPhotos(response?.data);
    } catch (error) {
      setClubPhotos([]);
    }
  };

  const handleUploadClose = () => {
    setOpenUpload(false);
  };

  const handleUploadOpen = () => {
    setOpenUpload(true);
  };

  useEffect(() => {
    getFanClubPhotos();
  }, []);

  useEffect(() => {
    getFanClubPhotos();
  }, [loading, isOpenEditModelPhoto]);

  useEffect(() => {
    if (!clubPhotos) return;
    if (isSelectedAll) {
      const selectedIds = clubPhotos?.map((photo) => photo?.photoId);
      setSelectedPhotoIds(selectedIds);
    } else {
      setSelectedPhotoIds([]);
    }
  }, [isSelectedAll]);

  return (
    <>
      <main className={styles.photosContainer}>
        <AddButton text='Add Photo' onClick={handleUploadOpen} />
        {clubPhotos?.map((photo) => (
          <PhotoPreview
            key={photo?.imageURL}
            src={photo?.imageURL}
            photoId={photo?.photoId}
            photo={photo}
            deletePhoto={() => deletePhoto(photo?.photoId)}
            handleOpenEditPhotoModal={() => handleOpenEditPhotoModal(photo)}
            handlePhotoSelection={handlePhotoSelection}
            isSelected={handleIsSelected}
          />
        ))}
        <UploadPopup
          disabled={loading}
          upload={handlePhotoUpload}
          onFileChange={handleFileChange}
          open={openUpload}
          handleClose={handleUploadClose}
        />
        <ModelEditPopup
          photoSetOptions={photoSetOptions}
          open={isOpenEditModelPhoto}
          onClose={() => setIsOpenEditModelPhoto(false)}
          photo={photoToEdit}
          key={photoToEdit?.imageURL}
        />
      </main>
    </>
  );
};

export default Photos;
