import { useEffect, useRef, useState } from 'react';
import Popup from 'reactjs-popup';
import Spinner from '../../../../custom/spinner/Spinner';
import { postClubFeed } from '../../../../request/club';
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useSelector } from 'react-redux';
import errorFormmatter from '../../../../utils/errorFormatter';
import notification from '../../../../utils/notification';

const ViewAllGalleryPhotos = ({ open, handleClose, files = [] }) => {
  const swiperRef = useRef();
  const [initialFiles, setInitialFiles] = useState(files);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    setInitialFiles(files);
    // swiper.slideTo;
  }, [files]);

  return (
    <Popup
      open={open}
      lockScroll
      overlayStyle={{
        background: 'rgba(68, 39, 52, 0.89)',
      }}
      contentStyle={{}}
      onClose={handleClose}
      closeOnDocumentClick
    >
      <span
        className='login-popup--close !top-0 !right-0 !fixed'
        onClick={() => handleClose(false)}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='18'
          height='18'
          viewBox='0 0 18 18'
          fill='none'
        >
          <path
            d='M17.7071 1.70711C18.0976 1.31658 18.0976 0.683417 17.7071 0.292893C17.3166 -0.0976311 16.6834 -0.0976311 16.2929 0.292893L17.7071 1.70711ZM0.292893 16.2929C-0.0976311 16.6834 -0.0976311 17.3166 0.292893 17.7071C0.683417 18.0976 1.31658 18.0976 1.70711 17.7071L0.292893 16.2929ZM16.2929 17.7071C16.6834 18.0976 17.3166 18.0976 17.7071 17.7071C18.0976 17.3166 18.0976 16.6834 17.7071 16.2929L16.2929 17.7071ZM1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L1.70711 0.292893ZM16.2929 0.292893L0.292893 16.2929L1.70711 17.7071L17.7071 1.70711L16.2929 0.292893ZM17.7071 16.2929L1.70711 0.292893L0.292893 1.70711L16.2929 17.7071L17.7071 16.2929Z'
            fill='white'
          />
        </svg>
      </span>

      <main className='max-w-[885px] relative '>
        <MdOutlineKeyboardArrowLeft
          onClick={() => {
            if (activeIndex !== 0) {
              swiperRef.current.slidePrev();
              setActiveIndex(swiperRef.current?.activeIndex);
            }
          }}
          className={`absolute text-[3rem] top-[40%] justify-between left-[-60px] ${
            activeIndex === 0
              ? 'opacity-[0.4] text-white'
              : 'text-primary-100 opacity-[1] cursor-pointer'
          }`}
        />
        <MdOutlineKeyboardArrowRight
          onClick={() => {
            if (activeIndex !== swiperRef.current?.slides?.length - 1) {
              swiperRef.current.slideNext();
              setActiveIndex(swiperRef.current?.activeIndex);
            }
          }}
          className={`absolute top-[40%] text-[3rem] justify-between right-[-60px] ${
            swiperRef.current?.activeIndex ===
            swiperRef.current?.slides?.length - 1
              ? ' opacity-[0.4] text-white'
              : 'text-primary-100 opacity-[1] cursor-pointer'
          }`}
        />

        <Swiper
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          spaceBetween={0}
          slidesPerView={1}
        >
          {initialFiles.map((file, id) => {
            return (
              <SwiperSlide id={`${file?.id}:::${id}`} key={id}>
                <FeedPostImages file={file} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </main>
    </Popup>
  );
};

const FeedPostImages = ({ file }) => {
  return (
    <>
      <img
        className='w-[100%] h-[588px] max-w-[885px] mb-[33px] '
        src={file?.imageURL}
      />
    </>
  );
};

export default ViewAllGalleryPhotos;
