import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import * as yup from 'yup';
import notification from '../../utils/notification';
import errorFormmatter from '../../utils/errorFormatter';
import validateInputs from '../../utils/validationSchema';
import axios from 'axios';

const LkEditPassword = () => {
	const [open, setOpen] = useState(false);
	const [oldPassword, setOldPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [loading, setLoading] = useState(false);
	const [clientId] = useState(localStorage.getItem('clientId'));

	const validationSchema = yup.object().shape({
		oldPassword: yup
			.string()
			.required('Old password is required')
			.min(8, 'Old password is less than 8 characters')
			.matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/, 'Old password must be alphanumeric'),
		newPassword: yup
			.string()
			.required('New password is required')
			.min(8, 'New password is less than 8 characters')
			.matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/, 'New password must be alphanumeric'),
		confirmPassword: yup.string().oneOf([yup.ref('newPassword')], 'Confirm password does not match new password'),
	});

	const changePassword = async e => {
		e.preventDefault();

		try {
			const result = await validateInputs(validationSchema, { confirmPassword, newPassword, oldPassword });
			if (!result) return; //return on errors

			setLoading(true);
			const formData = new FormData();

			formData.append('CurrentPassword', oldPassword);
			formData.append('NewPassword', newPassword);
			formData.append('ConfirmPassword', confirmPassword);

			const { data } = await axios.post(`/Authentication/${clientId}/change-password`, formData);

			// reset all password to empty
			setNewPassword('');
			setOldPassword('');
			setConfirmPassword('');

			notification({
				title: 'Password Edit Success',
				message: data.message,
				type: 'success',
				position: 'top-center',
			});
			setLoading(false);
		} catch (error) {
			setLoading(false);
			notification({
				title: 'Password Edit Error',
				message: errorFormmatter(error),
				type: 'danger',
				position: 'top-center',
			});
		}
	};

	const openModal = () => setOpen(true);
	const closeModal = () => setOpen(false);

	return (
		<div className='reg-data-input'>
			<input placeholder='' type='password' name='password' defaultValue='•••••••••••••' />
			<span className='reg-data-input--placeholder'>Password</span>
			<div className='reg-data-input--edit'>
				<span onClick={openModal}>Edit password</span>
			</div>
			<Popup open={open} closeOnDocumentClick lockScroll onClose={closeModal}>
				<div className='editInfo-popup editInfo-popup-password'>
					<span className='login-popup--close' onClick={closeModal}>
						<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'>
							<path
								d='M17.7071 1.70711C18.0976 1.31658 18.0976 0.683417 17.7071 0.292893C17.3166 -0.0976311 16.6834 -0.0976311 16.2929 0.292893L17.7071 1.70711ZM0.292893 16.2929C-0.0976311 16.6834 -0.0976311 17.3166 0.292893 17.7071C0.683417 18.0976 1.31658 18.0976 1.70711 17.7071L0.292893 16.2929ZM16.2929 17.7071C16.6834 18.0976 17.3166 18.0976 17.7071 17.7071C18.0976 17.3166 18.0976 16.6834 17.7071 16.2929L16.2929 17.7071ZM1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L1.70711 0.292893ZM16.2929 0.292893L0.292893 16.2929L1.70711 17.7071L17.7071 1.70711L16.2929 0.292893ZM17.7071 16.2929L1.70711 0.292893L0.292893 1.70711L16.2929 17.7071L17.7071 16.2929Z'
								fill='white'
							/>
						</svg>
					</span>
					<div className='editInfo-popup--content'>
						<div className='editInfo-popup--title'>Сhange the password</div>
						<form className='editInfo-popup--form' onSubmit={changePassword}>
							<div className='reg-data-input'>
								<input type='password' name='CurrentPassword' onChange={e => setOldPassword(e.target.value)} />
								<span className='reg-data-input--placeholder'>Old password</span>
							</div>
							<div className='reg-data-input'>
								<input type='password' name='NewPassword' onChange={e => setNewPassword(e.target.value)} />
								<span className='reg-data-input--placeholder'>New password</span>
							</div>
							<div className='reg-data-input'>
								<input type='password' name='confirm-password' onChange={e => setConfirmPassword(e.target.value)} />
								<span className='reg-data-input--placeholder'>Confirm password</span>
							</div>
							<button className='btn btn__red editInfo-popup--btn' onClick={changePassword} disabled={loading}>
								{loading ? 'Changing Password...' : 'Сhange password'}
							</button>
						</form>
					</div>
				</div>
			</Popup>
		</div>
	);
};
export default LkEditPassword;



/* <<<<<<< HEAD
import React, { Component } from 'react';
import {Link} from "react-router-dom";
import Popup from "reactjs-popup";

class LkEditPassword extends Component {
    constructor(props) {
        super(props)
        this.state = { open: false }
        this.openModal = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
    }
    openModal (){
        this.setState({ open: true })
    }
    closeModal () {
        this.setState({ open: false })
    }
    render() {
        return (
            <div className="reg-data-input">
                <input placeholder="" type="password" name="password" defaultValue="•••••••••••••"/>
                <span className="reg-data-input--placeholder">Password</span>
                <div className="reg-data-input--edit">
                    <span onClick={this.openModal}>Edit password</span>
                </div>
                <Popup open={this.state.open} closeOnDocumentClick lockScroll onClose={this.closeModal}>
                    <div className="editInfo-popup editInfo-popup-password">
                        <span className="login-popup--close" onClick={this.closeModal}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path d="M17.7071 1.70711C18.0976 1.31658 18.0976 0.683417 17.7071 0.292893C17.3166 -0.0976311 16.6834 -0.0976311 16.2929 0.292893L17.7071 1.70711ZM0.292893 16.2929C-0.0976311 16.6834 -0.0976311 17.3166 0.292893 17.7071C0.683417 18.0976 1.31658 18.0976 1.70711 17.7071L0.292893 16.2929ZM16.2929 17.7071C16.6834 18.0976 17.3166 18.0976 17.7071 17.7071C18.0976 17.3166 18.0976 16.6834 17.7071 16.2929L16.2929 17.7071ZM1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L1.70711 0.292893ZM16.2929 0.292893L0.292893 16.2929L1.70711 17.7071L17.7071 1.70711L16.2929 0.292893ZM17.7071 16.2929L1.70711 0.292893L0.292893 1.70711L16.2929 17.7071L17.7071 16.2929Z" fill="white"/>
                            </svg>
                        </span>
                        <div className="editInfo-popup--content">
                            <div className="editInfo-popup--title">Сhange the password</div>
                            <form className="editInfo-popup--form">
                                <div className="reg-data-input">
                                    <input placeholder="" type="password" name="old-password"/>
                                    <span className="reg-data-input--placeholder">Old password</span>
                                </div>
                                <div className="reg-data-input">
                                    <input placeholder="" type="password" name="new-password"/>
                                    <span className="reg-data-input--placeholder">New password</span>
                                </div>
                                <div className="reg-data-input">
                                    <input placeholder="" type="password" name="confirm-password"/>
                                    <span className="reg-data-input--placeholder">Confirm password</span>
                                </div>
                                <button className="btn btn__red editInfo-popup--btn">Сhange password</button>
                            </form>
                        </div>
                    </div>
                </Popup>
            </div>
        );
    }
}
export default LkEditPassword;
=======
>>>>>>> 59d03e77e78a8be035b39ba65f4989f1c2ae5150
 */