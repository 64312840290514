import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as Camera } from '../../images/svg/camera.svg';
import styles from './modelProfile.module.scss';
import { ReactComponent as BroadCastIcon } from '../../images/svg/broadcast.svg';
import { useSelector } from 'react-redux';
import { useActionCreator } from '../../hooks/useActionCreators';
import classNames from 'classnames';

const ModelProfileHeader = ({ className }) => {
	const { isOnline, onlineStatusLoading } = useSelector(
    (state) => state?.model
  );
  const { clientId, fullName, profileUrl } = useSelector(
    (state) => state?.user
  );
  const { updateModelOnlineStatus } = useActionCreator();
  const route = useHistory();

	const handleChangeModelStatus = (e) => {
    const { value } = e.target;
    const formData = new FormData();
    formData.append('Online', value);
    formData.append('ModelId', clientId);
    updateModelOnlineStatus(formData);
  };

	const handleStartBroadCast = () => {
    route.push('/mode-live-streaming');
  };

	return (
    <div className={className}>
      <section className={styles.navSection}>
        <Camera className={styles.cameraIcon} />
        <div className={styles.wrapper}>
          <div className={styles.imageWrapper}>
            <img
              src={profileUrl}
              alt='profile picture'
              className={styles.profile}
              loading='lazy'
            />
          </div>

          <div className={styles.navInfo}>
            <h2 className={styles.name}>{fullName}</h2>
            <select
              className={classNames(
                styles.select,
                !isOnline ? styles.offline : ''
              )}
              onChange={handleChangeModelStatus}
            >
              {onlineStatusLoading ? (
                <option>...</option>
              ) : (
                <>
                  <option
                    className={styles.option}
                    value={true}
                    selected={isOnline}
                  >
                    Online
                  </option>
                  <option
                    value={false}
                    selected={isOnline === false ? true : false}
                  >
                    Offline
                  </option>
                </>
              )}
            </select>
            <button
              className={styles.btnBroadCast}
              onClick={handleStartBroadCast}
            >
              <BroadCastIcon />
              Start broadcast
            </button>
          </div>
        </div>
      </section>
      <nav className={styles.nav}>
        <Link className={styles.active} to='/model-public-profile'>
          Public profile
        </Link>
        <Link className={styles.active} to='/mode-live-streaming'>
          Broadcast
        </Link>
        <Link className={styles.active} to='/lk-message'>
          Messages
        </Link>
        <span className={classNames(styles.active, styles.dropdown)}>
          Photos & video
          <nav className={styles.dropDownContainer}>
            <ul className={styles.dropDownListContainer}>
              <Link className={styles.dropList} to='/model-profile-photo'>
                Photos
              </Link>

              <Link className={styles.dropList} to='/model-album'>
                Albums
              </Link>

              <li className={styles.dropList}>
                {' '}
                <Link className={styles.dropList} to='/model-video'>
                  Videos{' '}
                </Link>{' '}
              </li>
            </ul>
          </nav>
        </span>

        <Link className={styles.active} to='/fan-club'>
          Fan Club
        </Link>
        <span className={classNames(styles.active, styles.dropdown)}>
          Account
          <nav className={styles.dropDownContainer}>
            <ul className={styles.dropDownListContainer}>
              <Link className={styles.dropList} to='/registration-information'>
                Registration Information{' '}
              </Link>
              <Link className={styles.dropList} to='/personal-information'>
                Personal Information
              </Link>
              <Link className={styles.dropList} to='/payee-information'>
                Payee Information
              </Link>

              <Link className={styles.dropList} to='/block-users'>
                Blocked Users{' '}
              </Link>
            </ul>
          </nav>
        </span>

        <span className={classNames(styles.active, styles.dropdown)}>
          Billing
          <nav className={styles.dropDownContainer}>
            <ul className={styles.dropDownListContainer}>
              <Link className={styles.dropList} to='/model-stats'>
                Quick Stats{' '}
              </Link>
              <Link className={styles.dropList} to='/transactions'>
                Transactions
              </Link>
              <Link className={styles.dropList} to='/payouts'>
                Payouts
              </Link>

              <Link className={styles.dropList} to='/model-price'>
                Prices{' '}
              </Link>

              <Link className={styles.dropList} to='/fan-club-event'>
                Shows
              </Link>
            </ul>
          </nav>
        </span>
      </nav>
    </div>
  );
};

export default ModelProfileHeader;
