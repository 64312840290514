import { createSlice } from '@reduxjs/toolkit';

const broadcastSlice = createSlice({
  name: 'broadcast',
  initialState: {
    isOnPrivateCall: false,
    privateLink: '',
    streamingMsgs: {
      private: [],
      general: [],
    },
  },
  reducers: {
    updateIsOnPrivateCall: (state, action) => {
      state.isOnPrivateCall = action.payload.isOnPrivateCall;
    },

    updatePrivateLink: (state, action) => {
      state.privateLink = action.payload.privateLink;
    },

    updateStreamingMsgs: (state, action) => {
      if (action.payload.type === 'private_msg') {
        if (
          state.streamingMsgs.private.length === (action.payload.size ?? 20)
        ) {
          state.streamingMsgs.private.shift();
        }
        state.streamingMsgs.private.push(action.payload.message);
      }

      if (action.payload.type === 'general_msg') {
        if (
          state.streamingMsgs.general.length === (action.payload.size ?? 20)
        ) {
          state.streamingMsgs.general.shift();
        }
        state.streamingMsgs.general.push(action.payload.message);
      }
    },

    resetStreamingMsgs: (state) => {
      state.streamingMsgs.general = [];
      state.streamingMsgs.private = [];
    },
  },
});

export const {
  updateIsOnPrivateCall,
  updateStreamingMsgs,
  resetStreamingMsgs,
  updatePrivateLink,
} = broadcastSlice.actions;

export default broadcastSlice.reducer;
