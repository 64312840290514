import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ component: Component, roles, ...rest }) => {
	const { token, userType } = useSelector(state => state?.user);

	return (
		<Route
			{...rest}
			render={props => {
				if (!token) {
					// redirect unauthorised users to homepage
					return <Redirect to={{ pathname: '/', state: { from: props.location } }} />;
				}

				// check if a user is protected by role
				if (token && roles.indexOf(userType?.toLowerCase()) === -1) {
					return <Redirect to={{ pathname: '/' }} />;
				}

				//authorised
				return <Component {...props} />;
			}}
		/>
	);
};

export default ProtectedRoute;
