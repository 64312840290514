import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';

Chart.register(CategoryScale);

const CHART_COLORS = [
  '#4dc9f6',
  '#f67019',
  '#f53794',
  '#537bc4',
  '#acc236',
  '#166a8f',
  '#00a950',
  '#58595b',
  '#8549ba',
];

const DATA_COUNT = 12;
const labels = [];
for (let i = 0; i < DATA_COUNT; ++i) {
  labels.push(i.toString());
}
const datapoints = [0, 20, 20, 60, 60, 120, NaN, 180, 120, 125, 105, 110, 170];
const data = {
  labels: labels,
  datasets: [
    {
      label: 'Cubic interpolation (monotone)',
      data: datapoints,
      borderColor: 'red',
      fill: false,
      cubicInterpolationMode: 'monotone',
      tension: 0.4,
    },
    // {
    //   label: 'Cubic interpolation',
    //   data: datapoints,
    //   borderColor: 'blue',
    //   fill: false,
    //   tension: 0.4,
    // },
    // {
    //   label: 'Linear interpolation (default)',
    //   data: datapoints,
    //   borderColor: 'green',
    //   fill: false,
    // },
  ],
};

export const config = {
  type: 'line',
  data: data,
  options: {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Chart.js Line Chart - Cubic interpolation mode',
      },
    },
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'Value',
        },
        suggestedMin: -10,
        suggestedMax: 200,
      },
    },
  },
};

export function externalTip(config) {
  return function (context) {
    // Tooltip Element
    let tooltipEl = document.getElementById('chartjs-tooltip');

    // Create element on first render
    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.id = 'chartjs-tooltip';
      tooltipEl.className = '!z-[99999]';
      tooltipEl.innerHTML = `<main class='w-[207px] h-[max-content] px-3 py-2  bg-gradient-to-r from-[#413450] to-[#563143] shadow flex-col justify-start items-start gap-2.5 inline-flex '>
      <h1 class="text-white text-base font-semibold font-['Montserrat']">
        Saturday , July 4, 
      </h1>
      <div class='w-[180px] justify-between items-start inline-flex'>
        <p class="text-white text-sm font-normal font-['Montserrat']">
          Impressions
        </p>
        <p class="text-white text-sm font-normal font-['Montserrat']">
          ${config?.impression}
        </p>
      </div>
      <div class='self-stretch justify-between items-start inline-flex'>
        <p class="text-white text-sm font-normal font-['Montserrat']">
          Posts
        </p>
        <p class="text-white text-sm font-normal font-['Montserrat']"> ${config?.posts}</p>
      </div>
        </main>`;
      document.body.appendChild(tooltipEl);
    }

    // Hide if no tooltip
    const tooltipModel = context.tooltip;
    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    // Set caret Position
    tooltipEl.classList.remove('above', 'below', 'no-transform');
    if (tooltipModel.yAlign) {
      tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
      tooltipEl.classList.add('no-transform');
    }

    function getBody(bodyItem) {
      return bodyItem.lines;
    }

    //   Set Text
    if (tooltipModel.body) {
      const titleLines = tooltipModel.title || [];
      const bodyLines = tooltipModel.body.map(getBody);
      console.log(bodyLines, titleLines);
    }

    const position = context.chart.canvas.getBoundingClientRect();
    //   const bodyFont = Chart.helpers.toFont(tooltipModel.options.bodyFont);

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.left =
      position.left + window.pageXOffset + tooltipModel.caretX + 'px';
    tooltipEl.style.top =
      position.top + window.pageYOffset + tooltipModel.caretY + 'px';
    //   tooltipEl.style.font = bodyFont.string;
    tooltipEl.style.padding =
      tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
    tooltipEl.style.pointerEvents = 'none';
  };
}
