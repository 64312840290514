import CustomModal from '../../../custom/Modal/Modal';
import styles from './request.module.scss';
import { ReactComponent as CloseButton } from '../../../svgs/close-button.svg';
import { ReactComponent as PaymentConfirmationIcon } from '../../../svgs/payment-confirmation-icon.svg';
import Button from '../../../custom/btn/Btn';
import DropDown from '../../../custom/DropDown/DropDown';
import classNames from 'classnames';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getModelPaymentInfo, requestPayout, confirmPayoutOtp } from '../../../request/model';
import Spinner from '../../../custom/spinner/Spinner';
import { useSelector } from 'react-redux';

import { ReactComponent as DropIcon } from '../../../svgs/drop-icon.svg';
import { useState } from 'react';
import { format } from 'currency-formatter';
import notification from '../../../utils/notification';

const RequestPayment = ({ openPayment = false, handleCloseRequestPayment }) => {
	const clientId = useSelector(state => state?.user?.clientId);
	const [steps, setSteps] = useState(1);
	const [amount, setAmount] = useState(0);
	const [description, setDescription] = useState('');

	const { data, isFetching } = useQuery('get-payment-info', () => getModelPaymentInfo(clientId), {
		retry: 0,
	});

	if (isFetching) return <Spinner />;

	return (
		<CustomModal open={openPayment}>
			<main className={styles.container}>
				<span className={styles.buttonContainer}>
					<CloseButton onClick={handleCloseRequestPayment} />
				</span>
				{steps === 1 && <SelectPayment paymentInfo={data} setSteps={setSteps} />}
				{steps === 2 && (
					<GetPaymentDetails
						paymentInfo={data}
						amount={amount}
						description={description}
						handleAmount={e => setAmount(e.target.value)}
						handleDescription={e => setDescription(e.target.value)}
						setSteps={setSteps}
					/>
				)}
				{steps === 3 && (
					<PreviewPaymentDetails
						paymentInfo={data}
						amount={amount}
						description={description}
						setSteps={setSteps}
						clientId={clientId}
					/>
				)}
				{steps === 4 && <ConfirmOtp setSteps={setSteps} clientId={clientId} />}
				{steps === 5 && (
					<ConfirmPayment
						setSteps={setSteps}
						handleCloseRequestPayment={handleCloseRequestPayment}
						setAmount={setAmount}
						setDescription={setDescription}
					/>
				)}
			</main>
		</CustomModal>
	);
};

const ConfirmPayment = ({ setSteps, handleCloseRequestPayment, setAmount, setDescription }) => {
	const queryClient = useQueryClient();

	const handleSteps = () => {
		queryClient.invalidateQueries(['model-payouts']);
		setSteps(1);
		setAmount('');
		setDescription('');
		handleCloseRequestPayment();
	};

	return (
		<main>
			<h1 className={styles.paymentTitle}>Request Payout</h1>
			<section className={styles.content}>
				<span className={styles.paymentConfirmationContainer}>
					<PaymentConfirmationIcon />
				</span>
				<h5 className={styles.confirmationTitle}>Payout Requested</h5>
				<h5 className={styles.confirmationSubTitle}>
					Your payout request will be reviewed and responded to within 48 hours.
				</h5>
			</section>
			<Button text='Continue' className={styles.continueButton} onClick={handleSteps} />
		</main>
	);
};

const ConfirmOtp = ({ clientId, setSteps }) => {
	const [otp, setOtp] = useState('');

	const otpMutaion = useMutation({
		mutationFn: payload => confirmPayoutOtp(payload),
		mutationKey: 'otp-payout',
		retry: 0,
	});

	const handleOtp = () => {
		if (otp === '') {
			notification({
				title: 'otp payout',
				message: 'please enter otp',
				type: 'danger',
			});
			return;
		}

		const formData = new FormData();
		formData.append('otp', otp);
		formData.append('RegisteredId', clientId);

		otpMutaion.mutate(formData, {
			onSuccess: () => {
				setSteps(prev => prev + 1);
			},
			onError: error => {
				notification({
					title: 'otp payout',
					message: error?.response?.data?.message ?? error?.message,
					type: 'danger',
				});
			},
		});
	};

	return (
		<main>
			<h1 className={styles.title}>Request Payout</h1>
			<section className={styles.content}>
				<section>
					<h5 className={styles.confirmationPasswordTitle}>Enter otp sent to you to confirm payout</h5>
					<label className={styles.label}>
						<textarea rows={5} className={styles.passwordInput} onChange={e => setOtp(e.target.value)} value={otp} />
					</label>
				</section>
			</section>
			<Button
				text={otpMutaion?.isLoading ? 'Submitting...' : 'Continue'}
				className={styles.continueButton}
				onClick={handleOtp}
				disabled={otpMutaion?.isLoading}
			/>
			<button className={styles.footerTitle} onClick={() => setSteps(prev => prev - 1)}>
				Back to Payout Preview
			</button>
		</main>
	);
};

const PreviewPaymentDetails = ({ paymentInfo, amount, setSteps, clientId, description }) => {
	const requestPayoutMutaion = useMutation({
		mutationFn: payload => requestPayout(payload),
		mutationKey: 'request-payout',
		retry: 0,
	});

	const isInsufficientBalance = () => {
		return paymentInfo?.availableBalance < parseFloat(amount) + paymentInfo?.transferFees;
	};

	const handleRequestForPayout = () => {
		if (isInsufficientBalance()) {
			notification({
				title: 'request payout',
				message: 'insufficient balance',
				type: 'danger',
			});
			return;
		}

		const formData = new FormData();
		formData.append('Bank', paymentInfo?.bankName);
		formData.append('AccountNuber', paymentInfo?.bankAccountNumber);
		formData.append('AccountName', paymentInfo?.fullName);
		formData.append('Amount', parseFloat(amount));
		formData.append('RegisteredId', clientId);
		formData.append('Description', description);

		requestPayoutMutaion.mutate(formData, {
			onSuccess: () => {
				setSteps(prev => prev + 1);
			},
			onError: error => {
				notification({
					title: 'request payout',
					message: error?.response?.data?.message ?? error?.message,
					type: 'danger',
				});
			},
		});
	};
	return (
		<main>
			<h1 className={styles.title}>Request Payout</h1>
			<section className={styles.content}>
				<h1 className={classNames(styles.grayText, styles.paymentTitle)}>Payout to</h1>

				<section className={styles.paymentDetailContainer}>
					<span className={classNames(styles.grayText, styles.paymentDetails)}>{paymentInfo?.fullName}</span>

					<span className={classNames(styles.grayText, styles.paymentDetails)}>{paymentInfo?.bankName}</span>

					<span className={classNames(styles.grayText, styles.paymentDetails)}>{paymentInfo?.bankAccountNumber}</span>
				</section>

				<section className={styles.balanceContainer}>
					<div className={styles.previewAmountContainer}>
						<span className={classNames(styles.leftCol, styles.grayText)}>Payout Amount</span>
						<span className={styles.grayText}>Fees</span>
					</div>
					<div className={styles.previewAmountContainer}>
						<span className={classNames(styles.leftCol, styles.grayText)}>
							{format(parseFloat(amount), { code: 'USD' })}
						</span>
						<span className={styles.grayText}>{format(paymentInfo?.transferFees, { code: 'USD' })}</span>
					</div>

					<p className={styles.grayText}>
						Remaining Balance:{' '}
						<span className={styles.whiteText}>
							{format(paymentInfo?.availableBalance - (paymentInfo?.transferFees + parseFloat(amount)), {
								code: 'USD',
							})}
						</span>
					</p>
				</section>
			</section>
			<Button
				text={requestPayoutMutaion?.isLoading ? 'Requesting...' : 'Continue'}
				className={styles.continueButton}
				onClick={handleRequestForPayout}
				disabled={requestPayoutMutaion?.isLoading}
			/>
			<button className={styles.footerTitle} onClick={() => setSteps(prev => prev - 1)}>
				Back to Payout Details
			</button>
		</main>
	);
};

const GetPaymentDetails = ({ paymentInfo, handleAmount, handleDescription, amount, description, setSteps }) => {
	const handleNextStep = () => {
		if (validateFormFields()) setSteps(prev => prev + 1);
	};

	const validateFormFields = () => {
		if (typeof parseFloat(amount) !== 'number') {
			notification({ title: 'payout amount', message: 'enter a valid payout amount', type: 'danger' });
			return false;
		}

		if (parseFloat(amount) < 5) {
			notification({ title: 'payout amount', message: 'payout amount should be $5 or greater', type: 'danger' });
			return false;
		}

		if (description === '') {
			notification({ title: 'payout description', message: 'enter payout description', type: 'danger' });
			return false;
		}

		return true;
	};
	return (
		<main>
			<h1 className={styles.title}>Request Payout</h1>
			<section className={styles.content}>
				<h1 className={classNames(styles.grayText, styles.paymentTitle)}>Payout to</h1>

				<section className={styles.paymentDetailContainer}>
					<span className={classNames(styles.grayText, styles.paymentDetails)}>{paymentInfo?.fullName}</span>

					<span className={classNames(styles.grayText, styles.paymentDetails)}>{paymentInfo?.bankName}</span>

					<span className={classNames(styles.grayText, styles.paymentDetails)}>{paymentInfo?.bankAccountNumber}</span>
				</section>

				<section>
					<label className={styles.label}>
						Payout Amount
						<input className={styles.paymentAmountInput} value={amount} onChange={handleAmount} />
						<span className={styles.availableBalance}>
							Available Balance: {format(paymentInfo?.availableBalance, { code: 'USD' })}
						</span>
					</label>

					<label className={styles.label}>
						Description
						<textarea rows={5} className={styles.descriptionInput} value={description} onChange={handleDescription} />
					</label>
				</section>
			</section>
			<Button text='Continue' className={styles.continueButton} onClick={handleNextStep} />
			<button className={styles.footerTitle} onClick={() => setSteps(prev => prev - 1)}>
				Back to Request Payout
			</button>
		</main>
	);
};

const SelectPayment = ({ paymentInfo, setSteps }) => {
	return (
		<main>
			<h1 className={styles.title}>Request Payout</h1>
			<section className={styles.content}>
				<h1 className={classNames(styles.grayText, styles.paymentTitle)}>Payout Method</h1>
				<DropDown
					disabled={true}
					className={styles.selectContainer}
					dropElement={({ props, state }) => (
						<div className={styles.dropDownRender}>
							<div className={styles.datePickerLeftContainer}>
								<span className={styles.selectedDropDownItem}>Bank Transfer</span>
							</div>
							<div className={styles.datePickerRightContainer}>
								<DropIcon />
							</div>
						</div>
					)}
				/>
				{/* <h4 className={classNames(styles.grayText, styles.paymentSetupTitle)}>Setup Payout Methods</h4> */}

				<div className={styles.paymentDetailContainer}>
					<span className={classNames(styles.grayText, styles.paymentDetails)}>Bank Name</span>
					<span className={classNames(styles.whiteText, styles.paymentDetails)}>{paymentInfo?.bankName}</span>
					<span className={classNames(styles.grayText, styles.paymentDetails)}>Bank Account Number</span>
					<span className={classNames(styles.whiteText, styles.paymentDetails)}>{paymentInfo?.bankAccountNumber}</span>
					<span className={classNames(styles.grayText, styles.paymentDetails)}>Account Holder </span>
					<span className={classNames(styles.whiteText, styles.paymentDetails)}>{paymentInfo?.fullName}</span>
				</div>
			</section>
			<Button text='Continue' className={styles.continueButton} onClick={() => setSteps(prev => prev + 1)} />
		</main>
	);
};

export default RequestPayment;
