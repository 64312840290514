import styles from './registration-infor.module.scss';
import ModelProfileHeader from '../../custom/modelProfileHeader/ModelProfileHeader';
import FluidContainer from '../../custom/fluidContainer/FluidContainer';
import Button from '../../custom/btn/Btn';
import FileUpload from '../../custom/fileUpload/FileUpload';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  getModelDetails,
  updateModelDetails,
  changePassword,
} from '../../request/model';
import Spinner from '../../custom/spinner/Spinner';
import { useSelector } from 'react-redux';
import notification from '../../utils/notification';
import { createClub, getClub, updateFanClub } from '../../request/model';
import { Link, useRouteMatch } from 'react-router-dom';
import errorFormmatter from '../../utils/errorFormatter';
import Popup from 'reactjs-popup';
import { ReactComponent as QuestionMarkIcon } from '../../svgs/question-mark.svg';

const ActiveArrow = (props) => {
  return (
    <svg
      {...props}
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='25'
      viewBox='0 0 25 29'
      fill='none'
    >
      <path
        d='M24 12.7679C25.3333 13.5378 25.3333 15.4622 24 16.232L3.75 27.9234C2.41666 28.6932 0.749999 27.7309 0.749999 26.1913L0.75 2.80865C0.75 1.26905 2.41667 0.306805 3.75 1.07661L24 12.7679Z'
        fill='#010002'
      />
    </svg>
  );
};

const userSchema = Yup.object().shape({
  firstName: Yup.string().trim(),
  lastName: Yup.string().trim(),
  password: Yup.string().trim(),
  oldPassword: Yup.string().trim(),
  confirmPassword: Yup.string().trim(),
  email: Yup.string().trim(),
});

const initialValue = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  oldPassword: '',
  confirmPassword: '',
};

const RegistrationInformation = () => {
  const activeTab = useRouteMatch();
  const [userInformation, setUserInformation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const [openModal, setModal] = useState(false);
  const handleOpenModal = () => {
    setModal(true);
  };
  const handleCloseModal = () => {
    setModal(false);
  };
  const user = useSelector((state) => state?.user);

  const getUserInformation = async () => {
    try {
      const response = await getModelDetails({ modelId: user.clientId });
      setUserInformation(response);
    } catch (error) {
      setUserInformation(null);
    }
  };

  const handleUpdate = async (values) => {
    if (values.password && openModal === true) {
      try {
        setLoading(true);
        const formData = new FormData();

        formData.append('currentPassword', values?.oldPassword);
        formData.append('newPassword', values?.password);
        formData.append('confirmPassword', values?.confirmPassword);
        formData.append('registeredId', user.clientId);

        await changePassword({
          payload: formData,
          modelId: user.clientId,
        });

        notification({
          title: 'Password update',
          message: 'Successfully updated your password',
          type: 'success',
        });
        setLoading(false);
        setModal(false);
      } catch (error) {
        setLoading(false);
        setModal(false);
        notification({
          title: 'Password update',
          message: errorFormmatter(error),
          type: 'danger',
        });
      }
    } else {
      try {
        setLoading(true);

        const formData = new FormData();

        formData.append('FirstName', values?.firstName);
        formData.append('LastName', values?.lastName);
        formData.append('Build', userInformation?.build);
        formData.append('Nationality', userInformation?.nationality);
        formData.append('CountryId', parseInt(userInformation?.country.countryId));
        formData.append('Category', userInformation?.category);
        formData.append('CityOfResident', userInformation?.cityOfResident);
        formData.append('NickName', userInformation?.nickName);
        formData.append('Gender', userInformation?.gender);
        formData.append('Height', userInformation?.height);
        formData.append('Weight', userInformation?.weight);
        formData.append('HairColor', userInformation?.hairColor);
        formData.append('DateOfBirth', userInformation?.dateOfBirth); //1993-10-16T00:00:00 ${values?.dateOfBirth}
        formData.append('EyeColor', userInformation?.eyeColor);
        formData.append('Ethinicity', userInformation?.ethinicity);
        formData.append('BreastSize', userInformation?.breastSize);
        formData.append('PubicHair', userInformation?.pubicHair);
        formData.append('Language', userInformation?.language);
        formData.append('SexualPreference', userInformation?.sexualPreference);
        formData.append('BodyChanges', userInformation?.bodyChanges);
        formData.append('Toy', userInformation?.toy);
        formData.append('AboutMe', userInformation?.aboutMe);
        formData.append('MyCompetence', userInformation?.myCompetence);
        formData.append('WhatAttractMe', userInformation?.whatAttractMe);
        formData.append('RegisterId', user.clientId);

        await updateModelDetails({
          payload: formData,
          modelId: user.clientId,
        });
        setLoading(false);
        notification({
          title: 'Profile update',
          message: 'Successfully updated your profile',
          type: 'success',
        });
      } catch (error) {
        setLoading(false);
        notification({
          title: 'Profile update',
          message: errorFormmatter(error),
          type: 'danger',
        });
      }
    }
  };

  const onTabChange = (index) => {
    setTab(index);
  };

  useEffect(() => {
    getUserInformation();
  }, []);

  useEffect(() => {
    getUserInformation();
  }, [loading]);

  return (
    <FluidContainer>
      <ModelProfileHeader />
      <main className={styles.container}>
        <h1 className={styles.title}>Registration information</h1>

        <section className={styles.registrationContainer}>
          <Formik
            enableReinitialize={true}
            validationSchema={userSchema}
            initialValues={
              userInformation !== null
                ? {
                    firstName: userInformation?.firstName,
                    lastName: userInformation?.lastName,
                    email: userInformation?.email,
                    password: '',
                    oldPassword: '',
                    confirmPassword: '',
                  }
                : initialValue
            }
            onSubmit={handleUpdate}
          >
            {(props) => {
              return (
                <>
                  <section>
                    <h1 className={styles.setupTitle}>
                      Registration information
                    </h1>
                    <h2 className={styles.setupSubTitle}>
                      Here you can change your password,your email address and
                      screen name
                    </h2>
                  </section>
                  <section>
                    <form>
                      <div className={styles.flexContainer}>
                        <div className={styles.flexInnerContainer}>
                          <label className={styles.inputContainer}>
                            <input
                              defaultValue={props.values.firstName}
                              onChange={props.handleChange}
                              name='firstName'
                              type={'text'}
                              className={styles.input}
                              placeholder='first name'
                            />
                            <span>First Name</span>
                          </label>
                          {props.touched && props.errors?.firstName && (
                            <span className={styles.error}>
                              * {props.errors?.firstName}
                            </span>
                          )}
                        </div>

                        <div className={styles.flexInnerContainer}>
                          <label className={styles.inputContainer}>
                            <input
                              defaultValue={props.values.lastName}
                              onChange={props.handleChange}
                              name='lastName'
                              type={'text'}
                              className={styles.input}
                              placeholder='last name'
                            />
                            <span>Last Name</span>
                          </label>
                          {props.touched && props.errors?.lastName && (
                            <span className={styles.error}>
                              * {props.errors?.lastName}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className={styles.flexContainer}>
                        <div className={styles.flexInnerContainer}>
                          <label className={styles.inputContainer}>
                            <input
                              defaultValue={props.values.email}
                              name='email'
                              type={'text'}
                              disabled={true}
                              className={styles.input}
                              placeholder='•••••••••••••'
                            />
                            <span>Email</span>
                          </label>
                        </div>

                        <div className={styles.flexInnerContainer}>
                          <label className={styles.inputContainer}>
                            <input
                              defaultValue={props.values.password}
                              name='password'
                              type={'password'}
                              disabled={true}
                              className={styles.input}
                              placeholder='•••••••••••••'
                            />
                            <span>Password</span>
                          </label>

                          <span
                            className={styles.changeAction}
                            onClick={handleOpenModal}
                          >
                            Change password
                          </span>
                        </div>
                      </div>
                    </form>
                    <Button
                      icon={loading && Spinner}
                      type='submit'
                      onClick={props.handleSubmit}
                      className={styles.saveButton}
                      text='Save'
                    />
                  </section>
                  <nav className={styles.navigationContainer}>
                    <Link to='/registration-information'>
                      <li
                        className={classNames(
                          styles.listItem,
                          activeTab.path === '/registration-information' &&
                            styles.active
                        )}
                        onClick={() => onTabChange(0)}
                      >
                        {activeTab.path === '/registration-information' && (
                          <ActiveArrow className={styles.navigationArrow} />
                        )}
                        Registration information
                      </li>
                    </Link>

                    <Link to='/personal-information'>
                      <li
                        className={classNames(
                          styles.listItem,
                          activeTab.path === '/personal-information' &&
                            styles.active
                        )}
                        onClick={() => onTabChange(1)}
                      >
                        {activeTab.path === '/personal-information' && (
                          <ActiveArrow className={styles.navigationArrow} />
                        )}
                        Personal info
                      </li>
                    </Link>
                    <Link to='/payee-information'>
                      <li
                        className={classNames(
                          styles.listItem,
                          tab === 2 && styles.active
                        )}
                        onClick={() => onTabChange(2)}
                      >
                        {activeTab.path === '/payee-information' && (
                          <ActiveArrow className={styles.navigationArrow} />
                        )}
                        Payee information
                      </li>
                    </Link>
                  </nav>
                  <Modal
                    props={props}
                    loading={loading}
                    values={props.values}
                    open={openModal}
                    onClose={handleCloseModal}
                    handleChangePassword={props.handleSubmit}
                    handleChange={props.handleChange}
                  />
                </>
              );
            }}
          </Formik>
        </section>
      </main>
    </FluidContainer>
  );
};

const Modal = ({
  values,
  open,
  onClose,
  handleChangePassword,
  handleChange,
  loading,
  props,
}) => {
  return (
    <Popup
      closeOnDocumentClick
      onClose={onClose}
      open={open}
      lockScroll={false}
    >
      <section className={classNames(styles.popContainer, '!m-auto')}>
        <span className={styles.closeButton} onClick={onClose}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='18'
            height='18'
            viewBox='0 0 18 18'
            fill='none'
          >
            <path
              d='M17.7071 1.70711C18.0976 1.31658 18.0976 0.683417 17.7071 0.292893C17.3166 -0.0976311 16.6834 -0.0976311 16.2929 0.292893L17.7071 1.70711ZM0.292893 16.2929C-0.0976311 16.6834 -0.0976311 17.3166 0.292893 17.7071C0.683417 18.0976 1.31658 18.0976 1.70711 17.7071L0.292893 16.2929ZM16.2929 17.7071C16.6834 18.0976 17.3166 18.0976 17.7071 17.7071C18.0976 17.3166 18.0976 16.6834 17.7071 16.2929L16.2929 17.7071ZM1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L1.70711 0.292893ZM16.2929 0.292893L0.292893 16.2929L1.70711 17.7071L17.7071 1.70711L16.2929 0.292893ZM17.7071 16.2929L1.70711 0.292893L0.292893 1.70711L16.2929 17.7071L17.7071 16.2929Z'
              fill='white'
            />
          </svg>
        </span>
        <section>
          <h1 className={styles.popTitle}>Change email</h1>
          <div className={styles.popInputContainer}>
            <label className={styles.inputContainer}>
              <input
                onChange={handleChange}
                defaultValue={values.oldPassword}
                name={'oldPassword'}
                type={'text'}
                className={styles.input}
                placeholder='•••••••••••••'
              />
              <span>Old password</span>
            </label>
            {props.touched && props.errors?.oldPassword && (
              <span className={styles.error}>
                * {props.errors?.oldPassword}
              </span>
            )}
          </div>
          <div className={styles.popInputContainer}>
            <label className={styles.inputContainer}>
              <input
                onChange={handleChange}
                name='password'
                defaultValue={values.password}
                type={'text'}
                className={styles.input}
                placeholder='•••••••••••••'
              />
              <span>New password</span>
            </label>
            {props.touched && props.errors?.password && (
              <span className={styles.error}>* {props.errors?.password}</span>
            )}
          </div>

          <div className={styles.popInputContainer}>
            <label className={styles.inputContainer}>
              <input
                onChange={handleChange}
                name='confirmPassword'
                defaultValue={values.confirmPassword}
                type={'text'}
                className={styles.input}
                placeholder='•••••••••••••'
              />
              <span>Confirm password</span>
            </label>
            {props.touched && props.errors?.confirmPassword && (
              <span className={styles.error}>
                * {props.errors?.confirmPassword}
              </span>
            )}
          </div>

          <h5 className={styles.message}>
            Confirmation your password before changing
          </h5>
          <Button
            icon={loading && Spinner}
            type='submit'
            onClick={() => {
              if (!values.password || !values.oldPassword) {
                notification({
                  title: 'Password update',
                  message: 'current password and new password is required',
                  type: 'danger',
                });
                return;
              }
              if (values.password !== values.confirmPassword) {
                notification({
                  title: 'Password update',
                  message: 'Confirmation password is not correct',
                  type: 'danger',
                });
                return;
              }
              handleChangePassword();
            }}
            className={styles.changeButton}
            text='Change password'
          />
        </section>
      </section>
    </Popup>
  );
};

export default RegistrationInformation;
