import React from 'react';
import { Link } from 'react-router-dom';
import stles from './unverifiedModel.module.scss';
import Container from '../../custom/fluidContainer/FluidContainer';

const UnverifiedModel = () => {
	return (
		<Container>
			<section className={stles.section}>
				<article>
					<p className='reg-model-thanks--title'>
						Application <br />
						is being processed
					</p>
					<div className='reg-model-thanks--subtitle'>
						At the moment, the application is under processing. <br />
						After verification of the application by our manager, <br />
						you will be able to proceed with registration, <br />
						we will send the result of validation to your email
					</div>
					<Link to='/' className='reg-model-thanks--link btn btn__red'>
						Return to main page
					</Link>
				</article>
			</section>
		</Container>
	);
};

export default UnverifiedModel;
