import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getClub } from '../../../../request/model';
import { getPhoto } from '../../../../request/model';
import { useLocation } from 'react-router-dom';
import useGridDisplay from '../../../../hooks/useGridDisplay';
import { useState, useEffect, useRef } from 'react';

const FanClub = ({ modelInfo }) => {
  const params = useLocation();
  const [photos, setPhotos] = useState([]);
  const { renderGallery } = useGridDisplay();
  const [modelFanClub, setModelFanClub] = useState(null);
  const firstGalleryRef = useRef(null);
  const mainRef = useRef(null);
  const urlSearchParams = new URLSearchParams(params.search);
  const modelId = urlSearchParams.get('model');

  const getPhotos = async () => {
    try {
      const response = await getPhoto({ modelId: modelId });

      setPhotos(response?.data);
    } catch (error) {}
  };

  const getModelFanClub = async () => {
    try {
      const response = await getClub({ modelId: modelId });
      setModelFanClub(response.data[0] ?? null);
    } catch (error) {}
  };

  useEffect(() => {
    getPhotos();
    getModelFanClub();
  }, []);

  useEffect(() => {
    getPhotos();
    getModelFanClub();
  }, [modelId]);

  return modelFanClub ? (
    <div ref={mainRef} className='model-photos'>
      {renderGallery({
        createCustomLayout: customLayout(
          [],
          mainRef.current,
          layoutThree,
          { modelFanClub, modelInfo },
          'first-row-model-photos'
        ),
      })}
    </div>
  ) : null;
};

export default FanClub;

const layoutThree = (image = [], clubInfo) => {
  return `
        
   
        ${
          clubInfo?.modelInfo?.profilePicUrl
            ? `
        <div class='model-photos-column model-photos-column1'>
          <div class='mb-[1rem] model-photos-content1'>
            Do you think you like a challenge? Game on my fan club!
          </div>
        
            <img
            class='!h-[400px] !w-[100%]'
            src=${clubInfo?.modelInfo?.profilePicUrl}
               
              alt=''
            />
          
        </div>
        `
            : ''
        }

       
        
        <div class='model-photos-column model-photos-column1'>
          <div class='model-photos-content2'>
            <div class='model-photos-content2--title'>
             CLUB NAME :
              <br />
             ${clubInfo?.modelFanClub?.fanClubTitle?.toUpperCase()}
            </div>
            <div class='model-photos-content2-count'>
              <div class='model-photos-content2-count--number'>+124</div>
              <div class='model-photos-content2-count--text'>
                <span>PREMIUM</span>
                PHOTOS AND VIDEOS
              </div>
            </div>
            <ul>
              <li>— Exclusive attention in chat</li>
              <li>— Exclusive emoticons on chat</li>
              <li>— Extra Awards points</li>
              <li>— Private messages for half price</li>
            </ul>
            
          </div>
        </div>
         <div class='model-photos-column model-photos-column1'>
          <img
             src=${clubInfo?.modelFanClub?.headerImageUrl}
            alt=''
          />
        </div>
            
         
         
       
       
      
     `;
  //<div class='model-photos-content2--btn btn btn__red'>Be me Fan</div>;
};

const customLayout = (image = [], ref, layoutOne, modelInfo, id = '') => {
  const isRowExist = document.querySelector(`#${id}`);

  if (isRowExist === null && ref && layoutOne) {
    const item = document.createElement('section');

    const ItemClass = 'model-photos-row';
    item.className = `${ItemClass}`;
    item.style = `width:100%`;
    item.id = `${id}`;

    item.insertAdjacentHTML('afterbegin', layoutOne(image, modelInfo));

    ref.appendChild(item);
  }
};
