import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import FluidContainer from '../../custom/fluidContainer/FluidContainer';
import styles from './styles.module.scss';
import ModelProfileHeader from '../../custom/modelProfileHeader/ModelProfileHeader';
import { withRole } from '../../hoc/withRole';
import SelectAll from '../../custom/selectAll/SelectAll';
import Button from '../../custom/btn/Btn';
import { ReactComponent as CreateAlbumIcon } from '../../images/svg/albumIcon.svg';
import { getFanClubPhotoSet } from '../../request/model';
import classNames from 'classnames';
import CreatePhotoSetPopup from './createPhotoSetPopup/CreatePhotoSetPopup';
import PhotoSet from './photoSet/PhotoSet';
import Photos from './photos/Photos';
import Spinner from '../../custom/spinner/Spinner';
import DropDown from '../../custom/DropDown/DropDown';
import errorFormmatter from '../../utils/errorFormatter';
import notification from '../../utils/notification';
import { addToPhotoSets } from '../../request/model';
import { ReactComponent as DropIcon } from '../../svgs/drop-icon.svg';

const ModelFanClubPhotoSets = () => {
  const clientId = useSelector((state) => state?.user?.clientId);
  const [fanClubPhotoSets, setFanClubPhotoSets] = useState([]);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [selectedPhotoSet, setSelectedPhotoSet] = useState(null);
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedPhotoIds, setSelectedPhotoIds] = useState([]);

  const [newPhotoset, setNewPhotoSet] = useState(false);

  const getFanClubPhotoSets = async () => {
    try {
      const response = await getFanClubPhotoSet({ modelId: clientId });
      setFanClubPhotoSets(response.data);
    } catch (error) {
      setFanClubPhotoSets([]);
    }
  };

  const handleSelectAll = (e) => {
    const { checked } = e.target;
    setIsSelectedAll(checked);
  };

  const setCurrentTab = (index) => {
    setTab(index);
  };

  const deletePhoto = async (id) => {
    try {
      const payload = new FormData();

      payload.append('registeredId', user?.clientId);
      payload.append('addImage', file);
      setLoading(true);
      const response = await addFanClubPhoto({ payload });
      setLoading(false);
      notification({
        message: response?.message ?? 'Fan club photo added successfully',
      });
    } catch (error) {
      notification({
        message: errorFormmatter(error) ?? 'Fan club photo failed to add',
        type: 'danger',
      });
      setLoading(false);
    }
  };

  const addToPhotoSet = async (photoId) => {
    try {
      const payload = new FormData();

      if (selectedPhotoSet === null) {
        notification({
          message: 'No photo set selected',
          type: 'danger',
        });
        return;
      }

      payload.append('registeredId', clientId);
      payload.append('photoSetId', selectedPhotoSet.photoSetId);
      payload.append('PhotoId', photoId);

      setLoading(true);
      const response = await addToPhotoSets({ payload });
      setLoading(false);
      notification({
        message: response?.message ?? 'Fan club photo added successfully',
      });
    } catch (error) {
      notification({
        message: errorFormmatter(error) ?? 'Fan club photo failed to add',
        type: 'danger',
      });
      setLoading(false);
    }
  };

  const handleSelectChange = (data) => {
    setSelectedPhotoSet(data.value);
  };

  useEffect(() => {
    getFanClubPhotoSets();
  }, []);

  useEffect(() => {
    if (newPhotoset) {
      getFanClubPhotoSets();
    }
  }, [newPhotoset]);

  return (
    <FluidContainer>
      <ModelProfileHeader className={styles.subHeader} />
      <h1 className={styles.header}>
        <span className={styles.subTitle}>FAN CLUB</span>
        <span className={styles.title}>Photos and Photo Sets</span>
      </h1>
      <section className={styles.navContainer}>
        <SelectAll
          labelText='Select All'
          checked={isSelectedAll}
          onChange={handleSelectAll}
        />
        <nav className={styles.tabContainer}>
          <li
            className={classNames(
              styles.tabLink,
              tab === 0 && styles.activeTab
            )}
            onClick={() => setCurrentTab(0)}
          >
            Photos
          </li>
          <li
            onClick={() => setCurrentTab(1)}
            className={classNames(
              styles.tabLink,
              tab === 1 && styles.activeTab
            )}
          >
            Photo Sets
          </li>
        </nav>
        <nav className={styles.nav}>
          {loading && <Spinner />}
          {tab === 0 && (
            <DropDown
              onChange={handleSelectChange}
              placeholder='select state'
              options={fanClubPhotoSets.map((photoSet) => {
                return {
                  label:
                    photoSet?.photoSetName?.length > 14
                      ? `${photoSet?.photoSetName.slice(0, 14)}...`
                      : photoSet?.photoSetName,
                  value: photoSet,
                };
              })}
              className={styles.selectContainer}
              dropElement={({ props, state }) => (
                <div className={styles.dropDownRender}>
                  {selectedPhotoSet !== null
                    ? selectedPhotoSet?.photoSetName?.length > 14
                      ? `${selectedPhotoSet?.photoSetName.slice(0, 14)}...`
                      : selectedPhotoSet?.photoSetName
                    : 'Select'}
                  <DropIcon />
                </div>
              )}
            />
          )}
          <Button
            className={styles.createPhotoSetButton}
            text={tab === 0 ? 'Add To Photo Sets' : 'Create Photo Sets'}
            icon={CreateAlbumIcon}
            onClick={
              tab === 0
                ? () => {
                    selectedPhotoIds.map((id) => {
                      addToPhotoSet(id);
                    });
                  }
                : () => setNewPhotoSet(true)
            }
          />
        </nav>
      </section>
      <section className={styles.photoSetContainer}>
        {tab === 1 && (
          <PhotoSet
            newPhotoSet={newPhotoset}
            photoSetOptions={fanClubPhotoSets}
            isSelectedAll={isSelectedAll}
            deletePhoto={deletePhoto}
            fanClubPhotoSets={fanClubPhotoSets}
          />
        )}
        {tab === 0 && (
          <Photos
            setSelectedPhotoIds={setSelectedPhotoIds}
            selectedPhotoIds={selectedPhotoIds}
            deletePhot={deletePhoto}
            isSelectedAll={isSelectedAll}
            photoSetOptions={fanClubPhotoSets}
          />
        )}
      </section>

      <CreatePhotoSetPopup
        open={newPhotoset}
        handleClose={() => setNewPhotoSet(false)}
      />
    </FluidContainer>
  );
};

export default withRole(ModelFanClubPhotoSets);
