//ts-check
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { useHistory } from 'react-router-dom';
import { MEMBER, CLUB, MODEL } from '../../const/Const';
import { useActionCreator } from '../../hooks/useActionCreators';
import notification from '../../utils/notification';
import axios from 'axios';
import { unwrapResult } from '@reduxjs/toolkit';
import decodeJwt from '../../utils/decodeJwt';

const MemberLogin = () => {
	const isModalOpen = useSelector(state => state?.loginModal.isModalOpen);
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [keepMeLoggedIn, setKeepMeLoggedIn] = useState(false);
	const {
		updateUser,
		updateUerStatus,
		openModal,
		closeModal,
		getModelProfileStatus,
		getClubProfileStatus,
	} = useActionCreator();
	const history = useHistory();

	const handleKeyup = () => {
		document.querySelectorAll('.authEmail').forEach(function(element) {
			element.onkeyup = function() {
				const r = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
				if (!r.test(element.value)) {
					element.parentNode.classList.remove('true');
					element.parentNode.classList.add('error');
				} else {
					element.parentNode.classList.remove('error');
					element.parentNode.classList.add('true');
				}
			};
		});
	};

	// validate email and password
	const formValidation = () => {
		let error = '';
		let hasError = false;

		if (!email) {
			error = 'Please enter your email';
			hasError = true;
		} else if (!password) {
			error = 'Please enter your password';
			hasError = true;
		} else {
			return { error, hasError };
		}

		return { error, hasError };
	};

	const handleSubmit = async event => {
		event.preventDefault();
		const { error: validationError, hasError } = formValidation();

		// validate form
		if (hasError) {
			notification({
				title: 'Login Error',
				message: validationError,
				type: 'danger',
			});
			return;
		}

		try {
			const formData = new FormData();
			formData.append('Username', email);
			formData.append('Password', password);
			setLoading(true);

			const { data } = await axios.post(`/Authentication/login`, formData);
			const { userType, clientId, token } = data;
			const decodedToken = await decodeJwt(token);

			if (userType?.toLowerCase() === MODEL) {
				const response = await getModelProfileStatus(clientId);
				const { hasUploadVerify, isCompletedProfile, isVerified } = unwrapResult(response);
				updateUser({ ...data, fullName: decodedToken?.FullName });
				redirectModel({ hasUploadVerify, isCompletedProfile, isVerified });
				closeModal();
				return;
			}

			if (userType?.toLowerCase() === CLUB) {
				const response = await getClubProfileStatus(clientId);
				const { hasUploadVerify, isCompletedProfile, isVerified } = unwrapResult(response);
				updateUser({ ...data, fullName: decodedToken?.FullName });
				redirectClub({ hasUploadVerify, isCompletedProfile, isVerified });
				closeModal();
				return;
			}

			updateUser({ ...data, fullName: decodedToken?.FullName });
			redirectMember(userType);
		} catch (error) {
			const errorMessage = error?.response?.data?.message ?? error?.message;
			notification({
				title: 'Registration Error',
				message: errorMessage,
				type: 'danger',
			});
		} finally {
			setLoading(false);
		}
	};

	function redirectModel({ hasUploadVerify, isCompletedProfile, isVerified }) {
		if (!hasUploadVerify) return history.push('/model-upload-picture');
		if (!isVerified) return history.push('/unverified-model');
		if (!isCompletedProfile) return history.push('/registration-model-info');

		updateUerStatus({ isCompletedVerfication: true });
		history.push('/model-profile');
		return;
	}

	function redirectClub({ hasUploadVerify, isCompletedProfile, isVerified }) {
		if (!hasUploadVerify) return history.push('/club-upload-picture');
		if (!isVerified) return history.push('/unverified-club');
		if (!isCompletedProfile) return history.push('/registration-club-info');

		updateUerStatus({ isCompletedVerfication: true });
		history.push('/clubs-detail-info');
		return;
	}

	function redirectMember(userType) {
		const serializedUser = userType?.trim()?.toLowerCase();
		if (serializedUser === MEMBER) {
			updateUerStatus({ isCompletedVerfication: true });
			return history.push('/lk');
		} else {
			return history.push('/');
		}
	}

	const handleRedirection = redirectionLink => {
		closeModal();
		history.push(redirectionLink);
	};

	return (
		<div className='header--auth'>
			<div className='header--auth-logIn' onClick={() => openModal()}>
				Sign In
			</div>
			<Link to='/registration-member' className='header--auth-signUp'>
				Sign Up
			</Link>
			<Popup open={isModalOpen} closeOnDocumentClick lockScroll onClose={closeModal}>
				<div className='login-popup'>
					<span className='login-popup--close' onClick={closeModal}>
						<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'>
							<path
								d='M17.7071 1.70711C18.0976 1.31658 18.0976 0.683417 17.7071 0.292893C17.3166 -0.0976311 16.6834 -0.0976311 16.2929 0.292893L17.7071 1.70711ZM0.292893 16.2929C-0.0976311 16.6834 -0.0976311 17.3166 0.292893 17.7071C0.683417 18.0976 1.31658 18.0976 1.70711 17.7071L0.292893 16.2929ZM16.2929 17.7071C16.6834 18.0976 17.3166 18.0976 17.7071 17.7071C18.0976 17.3166 18.0976 16.6834 17.7071 16.2929L16.2929 17.7071ZM1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L1.70711 0.292893ZM16.2929 0.292893L0.292893 16.2929L1.70711 17.7071L17.7071 1.70711L16.2929 0.292893ZM17.7071 16.2929L1.70711 0.292893L0.292893 1.70711L16.2929 17.7071L17.7071 16.2929Z'
								fill='white'
							/>
						</svg>
					</span>
					<div className='login-popup--left'>
						<div className='login-popup--title'>Member login</div>
						<div className='login-popup--subtitle'>Access your account</div>
						<form onSubmit={handleSubmit}>
							<div className='input-wrapper'>
								<input
									name='email'
									type='email'
									className='authEmail'
									placeholder='Email address'
									onChange={e => setEmail(e.target.value)}
									onKeyUp={handleKeyup}
									value={email}
								/>
							</div>
							{/* <div className='input-wrapper--ps'>We reading you password here</div> */}
							<div className='input-wrapper--ps'>Please enter your email</div>
							<div className='input-wrapper'>
								<input
									name='password'
									type='password'
									className='authPassword'
									placeholder='Password'
									onChange={e => setPassword(e.target.value)}
									value={password}
								/>
							</div>
							{/* <div className='input-wrapper--ps'>We send you password here</div> */}
							<div className='input-wrapper--ps'>Please enter your password</div>
							<div className='checkbox'>
								<label>
									<input
										name='check'
										type='checkbox'
										onChange={e => setKeepMeLoggedIn(e.target.checked)}
										value={keepMeLoggedIn}
									/>
									<span />
									Remember me on this computer
								</label>
								<div className='checkbox--ps'>
									(not recommended on public <br />
									or shared computers)
								</div>
							</div>
							<button onClick={handleSubmit} className='btn btn__red' disabled={loading}>
								{loading ? 'Loggin in...' : 'Log In'}
							</button>
							<div className='form-links'>
								<button onClick={() => handleRedirection('/lost_password')}>
									Forgot Username or Password?
								</button>
								<button onClick={() => handleRedirection('/registration-member')}>Create Free Account</button>
							</div>
						</form>
					</div>
					<div className='login-popup--right' />
				</div>
			</Popup>
		</div>
	);
};
export default MemberLogin;
