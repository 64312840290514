import React from 'react';
import { Link } from 'react-router-dom';
import stles from './styles.module.scss';
import Container from '../../custom/fluidContainer/FluidContainer';

const UnverifiedClub = () => {
	return (
		<Container>
			<section className={stles.section}>
				<article>
					<p className='reg-model-thanks--title'>
						Thank you <br />
						for the application! Application is being processed
					</p>
					<p className='reg-model-thanks--subtitle'>
						An e-mail has been sent to your email, confirming your application. Within 24 hours, another email
						will be sent with a link, which will confirm your membership status and guide your onboarding process.
					</p>
					<Link to='/' className='reg-model-thanks--link btn btn__red'>
						Return to main page
					</Link>
				</article>
			</section>
		</Container>
	);
};

export default UnverifiedClub;
