
import React, { useState, useRef } from 'react';
import axios from 'axios';
import * as yup from 'yup';
import notification from '../../utils/notification';
import errorFormmatter from '../../utils/errorFormatter';
import validateInputs from '../../utils/validationSchema';

const ForgetPassword = () => {
	const [email, setEmail] = useState('');
	const [loading, setLoading] = useState(false);
	const mainSectionRef = useRef(null);
	const resendPasswordSectionRef = useRef(null);

	const validationSchema = yup.object().shape({
		email: yup
			.string()
			.email('email is not valid')
			.required('email is required'),
	});

	const handleSubmit = async event => {
		event.preventDefault();

		try {
			const result = await validateInputs(validationSchema, { email });
			if (!result) return;

			setLoading(true);

			const response = await axios.post('/Authentication/forgot-password', { email });

			notification({
				title: 'Forgot Password Error',
				message: response?.data?.message,
				type: 'success',
				position: 'top-center',
			});

			if (response.status === 204 || response.status === 201 || response.status === 200) {
				document.querySelector('.lost-data_main').classList.add('hidden');
				document.querySelector('.lost-data__resend').classList.add('active');
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
			notification({
				title: 'Forgot Password Error',
				message: errorFormmatter(error),
				type: 'danger',
				position: 'top-center',
			});
		}
	};

	return (
		<main className='container'>
			<section className='lost-data lost-data_main' ref={mainSectionRef}>
				<div className='lost-data--title'>Lost username or password?</div>
				<div className='lost-data--subtitle'>Please enter your email which you first registered with</div>
				<form id='forget-password' onSubmit={handleSubmit}>
					<div className='input-wrapper'>
						<input
							className='forgetEmail'
							id='forgetEmail'
							name='email'
							type='text'
							placeholder='Email address'
							onChange={e => setEmail(e.target.value)}
						/>
					</div>
					<div className='input-wrapper--ps'>We will send you instruction here</div>
					<button onClick={handleSubmit} className='btn btn__red' disabled={loading}>
						{loading ? 'Sending email...' : 'Send me email'}
					</button>
				</form>
				<img className='lost-data--img' src={require('../../images/forget.png')} alt='' />
			</section>

			<section className='lost-data lost-data__resend' ref={resendPasswordSectionRef}>
				<div className='lost-data--title'>We have sent you an email</div>
				<div className='lost-data--subtitle'>
					We have sent you an email with your username <br />
					and a link in order to reset your password
				</div>
				<button className='btn btn__red' onClick={handleSubmit} disabled={loading}>
					{loading ? 'Resending email...' : 'Resend confirmation email'}
				</button>
				<img className='lost-data--img' src={require('../../images/forget.png')} alt='' />
			</section>
		</main>
	);
};
export default ForgetPassword;

