import styles from './transaction.module.scss';
import ModelProfileHeader from '../../custom/modelProfileHeader/ModelProfileHeader';
import FluidContainer from '../../custom/fluidContainer/FluidContainer';
import DatePicker from '../../custom/datePicker/DatePicker';
import Button from '../../custom/btn/Btn';
import Pagination from '../../custom/pagination/Pagination';
import GridTable from './table/Table';
import { ReactComponent as DropIcon } from '../../svgs/drop-icon.svg';
import Tab from '../../custom/Tabs/Tabs';
import AnalyzeCard from './AnalyzeCard/AnalyzeCard';
import DropDown from '../../custom/DropDown/DropDown';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { getModelTransactions } from '../../request/model';
import { useSelector } from 'react-redux';
import Spinner from '../../custom/spinner/Spinner';
import moment from 'moment';
import { format } from 'currency-formatter';

const tableHeader = [
	{
		text: 'ID',
	},

	{
		text: 'Date',
	},
	{
		text: 'Time',
	},
	{
		text: 'Customer',
	},
	{
		text: 'Description',
	},
	{
		text: 'Duration',
	},
	{
		text: 'Amount',
		subText: 'credits',
	},
	{
		text: 'Customer Amount',
		subText: 'credits',
	},
];

const customers = [
	{
		label: 1,
		value: 'Leanne Graham',
	},
	{
		label: 2,
		value: 'Leanne Graham',
	},
];

const today = moment().format('YYYY-MM-DD');
const currentDate = moment();
const thisMonth = moment().format('MMMM');
const thisYear = moment().format('YYYY');
const lastMonth = moment()
	.subtract(1, 'months')
	.format('MMMM');

const Transactions = () => {
	const clientId = useSelector(state => state?.user?.clientId);
	const [tabIndex, setTabIndex] = useState(0);
	const [selectedCustomer, setSelectedCustomer] = useState('');
	const [startDate, setStartDate] = useState(today);
	const [endDate, setEndDate] = useState(today);
	const [dynamicStartDate, setDynamicStartDate] = useState('');
	const [dynamicEndDate, setDynamicEndDate] = useState('');

	const params = `${startDate}${endDate}`;

	const { data, error, isFetching } = useQuery(
		['model-payout-transactions', params, tabIndex],
		() => getModelTransactions({ modelId: clientId, startDate, endDate, pageNumber: 1 }),
		{
			refetchOnWindowFocus: false,
			retry: 0,
		}
	);

	const totalCustomersReversed = data?.modelTransDashBoard?.totalCustomersReversed;
	const totalCustomersSpent = data?.modelTransDashBoard?.totalCustomersSpent;
	const totalModelDeposit = data?.modelTransDashBoard?.totalModelDeposit;
	const totalReversed = data?.modelTransDashBoard?.totalReversed;

	const errorMessage = error?.response?.data?.message ?? error?.message;

	const transactions = data?.data ?? [];

	const handleDropDownChange = data => {
		setSelectedCustomer(data?.value);
	};

	const handleFind = () => {
		setStartDate(dynamicStartDate);
		setEndDate(dynamicEndDate);
	};

	const setDate = keyIndex => {
		let startDate, endDate;

		if (keyIndex === 0) {
			startDate = moment().format('YYYY-MM-DD');
			endDate = moment().format('YYYY-MM-DD');
		}

		if (keyIndex === 1) {
			startDate = currentDate
				.clone()
				.startOf('month')
				.format('YYYY-MM-DD');

			endDate = currentDate
				.clone()
				.endOf('month')
				.format('YYYY-MM-DD');
		}

		if (keyIndex === 2) {
			startDate = currentDate
				.clone()
				.subtract(1, 'months')
				.startOf('month')
				.format('YYYY-MM-DD');

			endDate = currentDate
				.clone()
				.subtract(1, 'months')
				.endOf('month')
				.format('YYYY-MM-DD');
		}

		if (keyIndex === 3) {
			startDate = currentDate
				.clone()
				.startOf('year')
				.format('YYYY-MM-DD');

			endDate = currentDate
				.clone()
				.endOf('year')
				.format('YYYY-MM-DD');
		}

		if (keyIndex === 4) {
			startDate = moment(new Date('1909-08-13')).format('YYYY-MM-DD');
			endDate = moment().format('YYYY-MM-DD');
		}

		setStartDate(startDate);
		setEndDate(endDate);
		setTabIndex(keyIndex);
	};

	return (
		<FluidContainer>
			<ModelProfileHeader />

			<section>
				<h1 className={styles.header}>
					<span className={styles.subTitle}>Billing</span>
					<span className={styles.title}>Transactions</span>
				</h1>
				<h2 className={styles.secondSubTitle}>Here you can see in detail how much money you earned with whom</h2>

				<section className={styles.analyzeContainer}>
					<AnalyzeCard type={'Total deposited'} text={format(totalModelDeposit, { code: 'USD' })} />
					<AnalyzeCard type={'Total customer spent'} text={format(totalCustomersSpent, { code: 'USD' })} />
					<AnalyzeCard type={'Total reversed'} text={format(totalReversed, { code: 'USD' })} />
					<AnalyzeCard type={'Total customers reversed'} text={format(totalCustomersReversed, { code: 'USD' })} />
				</section>

				<section className={styles.subHeader}>
					<DatePicker
						onChange={date => setDynamicStartDate(moment(date).format('YYYY-MM-DD'))}
						renderElement={({ value, onClick }) => (
							<div className={styles.datePickerContainer} onClick={onClick}>
								<div className={styles.datePickerLeftContainer}>
									<span>{value}</span>
								</div>
								<div className={styles.datePickerRightContainer}>
									<span>Date from</span>
									<DropIcon />
								</div>
							</div>
						)}
					/>
					<DatePicker
						onChange={date => setDynamicEndDate(moment(date).format('YYYY-MM-DD'))}
						renderElement={({ value, onClick }) => (
							<div className={styles.datePickerContainer} onClick={onClick}>
								<div className={styles.datePickerLeftContainer}>
									<span>{value}</span>
								</div>
								<div className={styles.datePickerRightContainer}>
									<span>Date to</span>
									<DropIcon />
								</div>
							</div>
						)}
					/>
					{/* <DropDown
						onChange={handleDropDownChange}
						options={customers}
						className={styles.selectContainer}
						dropElement={({ props, state }) => (
							<div className={styles.dropDownRender}>
								<div className={styles.datePickerLeftContainer}>
									<span className={styles.selectedDropDownItem}>{selectedCustomer ? selectedCustomer : 'Pick'}</span>
								</div>
								<div className={styles.datePickerRightContainer}>
									<span>Customers</span>
									<DropIcon />
								</div>
							</div>
						)}
					/> */}
					<Button text='Find' className={styles.findButton} onClick={handleFind} />
				</section>
				{isFetching ? (
					<div style={{ minHeight: '60vh' }}>
						<Spinner />
					</div>
				) : (
					<Tab
						panelClass={styles.tabContainer}
						containerClass={styles.navContainer}
						tabClass={styles.navList}
						position='left'
						onIndexChange={setDate}
						defaultKeyIndex={tabIndex}
						items={[
							{
								key: 'Today',
								children: (
									<div className={styles.table}>
										<GridTable tableHeader={tableHeader} tableData={transactions} />
									</div>
								),
							},
							{
								key: `This month — ${thisMonth}`,
								children: (
									<div className={styles.table}>
										<GridTable tableHeader={tableHeader} tableData={transactions} />
									</div>
								),
							},
							{
								key: <span className={styles.midList}>Last month — {lastMonth}</span>,
								children: (
									<div className={styles.table}>
										<GridTable tableHeader={tableHeader} tableData={transactions} />
									</div>
								),
							},
							{
								key: `This year — ${thisYear}`,
								children: (
									<div className={styles.table}>
										<GridTable tableHeader={tableHeader} tableData={transactions} />
									</div>
								),
							},
							{
								key: 'All time',
								children: (
									<div className={styles.table}>
										<GridTable tableHeader={tableHeader} tableData={transactions} />
									</div>
								),
							},
						]}
					/>
				)}
			</section>
		</FluidContainer>
	);
};

export default Transactions;
