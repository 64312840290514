import { useCallback, useEffect, useRef, useState } from 'react';
import styles from './live-streaming.module.scss';
import ModelProfileHeader from '../../custom/modelProfileHeader/ModelProfileHeader';
import FluidContainer from '../../custom/fluidContainer/FluidContainer';
import Button from '../../custom/btn/Btn';
import { ReactComponent as NoMessageIcon } from '../../svgs/no-message.svg';
import { ReactComponent as NoLogIcon } from '../../svgs/no-log.svg';
import { ReactComponent as MicIcon } from '../../svgs/mic.svg';
import { ReactComponent as CamIcon } from '../../svgs/cam.svg';
import { ReactComponent as SettingIcon } from '../../svgs/settings.svg';
import GoalModal from './goalModal/GoalModal';
import { ReactComponent as VideoOffIcon } from '../../svgs/video-off.svg';
import { ReactComponent as SendIcon } from '../../svgs/send-icon.svg';
import { ReactComponent as EmojiIcon } from '../../svgs/emoji.svg';
import CheckBox from '../../custom/checkbox/CheckBox';
import Spinner from '../../custom/spinner/Spinner';
import notification from '../../utils/notification';
import { RiShareForwardFill } from 'react-icons/ri';
import DropDown from '../../custom/DropDown/DropDown';
import SettingPop from './settingpop/SettingPop';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { EventTypes } from './constant';
import { buildConfig } from './utils';
import ChangeRoomModal from './changeRoomModal/ChangeRoomModal';
import { useDispatch } from 'react-redux';
import Counter from './broadcastCounter/broadcastCounter';
import { RiDeleteBin6Line } from 'react-icons/ri';
import {
  updateStreamingBroadcastConfig,
  updateStreamingBroadcastSetting,
} from '../../request/model';
import ChangeTopicModal from './changeTopicModal/ChangeTopicModal';
import { updateIsOnPrivateCall } from '../../store/slices/broadcast';
import BroadcastEdit from './broadcastEdit/broadcastEdit';
import CurrentGoalShow from './currentGoalShow/CurrentGoalShow';
import { RiFullscreenFill } from 'react-icons/ri';
import Emoji from '../../custom/emojiPicker/EmojiPicker';
import { getBroadcastGoal } from '../../request/model';
import PrivateRequestModal from './PrivateRequestModal/PrivateRequestModal';
import ShareBroadcastLinkModal from './shareBroadcastLinkModal/shareBroadcastLinkModal';
import { updateStreamingGoal, getStreamingGoals } from '../../request/model';
import {
  updateStreamingMsgs,
  resetStreamingMsgs,
  updatePrivateLink,
} from '../../store/slices/broadcast';
import { useAgoraStreaming } from '../../hooks/useAgora';

const ModelLiveStream = () => {
  const [rooms, setRooms] = useState([
    {
      label: 'General chat',
      value: 'General chat',
    },
  ]);
  const [isChangingTopicNameOpen, setIsChangingTopicNameOpen] = useState(false);
  const [isChangingRoomNameOpen, setIsChangingRoomNameOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [goals, setGoals] = useState([]);
  const streamContainerRef = useRef(null);
  const dispatch = useDispatch();
  const liveMessages = useSelector(
    (state) => state.liveBroadcast.streamingMsgs
  );
  const liveBroadcast = useSelector((state) => state.liveBroadcast);
  const broadcasterRef = useRef(null);
  const messageContentRef = useRef(null);
  const user = useSelector((state) => state?.user);
  const [localTracks, setLocalTracks] = useState([]);
  const [remoteUsers, setRemoteUsers] = useState({});
  const [isOnline, setIsOnline] = useState(false);
  const [openSetting, setOpenSetting] = useState(false);
  const [broadCastLink, setBroadCastLink] = useState('');
  const [message, setMessage] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [isLog, setIsLog] = useState(false);
  const [enable, setEnabled] = useState(true);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [enableEmoji, setEnableEmoji] = useState(false);
  const [currentOnlineUsers, setCurrentOnlineUsers] = useState([]);
  const [isUpdatingConfig, setIsUpdatingConfig] = useState(false);
  const [openParticipants, setOpenParticipants] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [openGoalModal, setOpenGoalModal] = useState(false);
  const [privateLink, setPrivateLink] = useState(liveBroadcast?.privateLink);
  const [privateRequestUser, setPrivateRequestUser] = useState('');
  const [openPrivateRequestModal, setOpenPrivateRequestModal] = useState(false);
  // const [isPrivateRequest, setIsPrivateRequest] = useState(false);
  const streamingConfigRef = useRef({
    allowPrivateChat: true,
    allowGeneralChat: true,
    allowMessage: true,
    allowGuest: true,
    allowMicOff: true,
    allowCamOn: true,
    allowTips: true,
    userEnteredSound: false,
    messageReceivedSound: false,
    tipReceivedSound: false,
    textSizeData: 'md',
    guestTimeKickOut: 5,
    allowShowRecording: false,
    timeLimit: false,
  });
  const [streamingConfig, setStreamingConfig] = useState({
    allowPrivateChat: true,
    allowGeneralChat: true,
    allowMessage: true,
    allowGuest: true,
    allowMicOff: true,
    allowCamOn: true,
    allowTips: true,
    userEnteredSound: false,
    messageReceivedSound: false,
    tipReceivedSound: false,
    textSizeData: 'md',
    guestTimeKickOut: 5,
    allowShowRecording: false,
    timeLimit: false,
  });

  const link = `broadcast-${user.clientId}`;
  // const privateLink = 'private-channel';

  const {
    micChangeDevice,
    camChangeDevice,
    leaveStreamingChannel,
    publishTrack,
    getBroadcastMemberDetails,
    sendMessage,
    initStreaming,
    registerRtcEvent,
    createLocalRtcTrack,
    registerRtmMessagingEvent,
    addAllMembersToMessagingView,
    updateRtcMemberDetails,
    enableRtcUserTrack,
    removeRemoteUserFromView,
    getRtcMembers,
    handleAddLocalUserToView,
    handleBroadcastingChannelMessage,
    unPublishTrack,
    updateStreamingConfigs,
    getStreamingConfigs,
    subScribeUserTrack,
    setRtcResolution,
    handleMemberLeftMessaging,
    handleMemberJoinedMessaging,
    broadcastAlert,
    applyChatFontSize,
    removeMemberFromMessagingView,
    enableDualStream,
    getStreamingId,
    getChannelConfigs,
    handleBroadcastingLocalChannelMessage,
  } = useAgoraStreaming();

  const resetModelVideoView = () => {
    try {
      const node = document.getElementById(`user-host-${user.clientId}`);
      if (node) {
        var innerDiv = node.querySelectorAll('div') ?? [];

        innerDiv.forEach(function (inner) {
          if (inner.id.includes('agora-video-player')) {
            inner?.remove();
          }
        });
      }
    } catch (error) {}
  };

  const persistMessage = async (message) => {
    try {
      dispatch(
        updateStreamingMsgs({
          message: { ...message },
          type: 'general_msg',
          size: 50,
        })
      );
    } catch (error) {}
  };

  const handleLiveMessages = async () => {
    try {
      messageContentRef.current.innerHTML = '';
      handleBroadcastingLocalChannelMessage(
        liveMessages.general ?? [],
        messageContentRef
      );
    } catch (error) {}
  };

  const updateStreamingBroadcastConfigs = async (values) => {
    try {
      const payload = new FormData();
      payload.append('allowPrivateChat', values.allowPrivateChat);
      payload.append('allowGeneralChat', values.allowGeneralChat);
      payload.append('allowMessage', values.allowMessage);
      payload.append('allowGuest', values.allowGuest);
      payload.append('allowMicOff', values.allowMicOff);
      payload.append('allowCamOn', values.allowCamOn);
      payload.append('allowTips', values.allowTips);
      payload.append('registerId', values.registerId);
      return await updateStreamingBroadcastConfig({ payload });
    } catch (error) {}
  };

  const updateStreamingBroadcastSettings = async (values) => {
    try {
      const payload = new FormData();
      payload.append('userEnteredSound', values.userEnteredSound);
      payload.append('messageReceivedSound', values.messageReceivedSound);
      payload.append('tipReceivedSound', values.tipReceivedSound);
      payload.append('textSizeData', values.textSizeData);
      payload.append('guestTimeKickOut', values.guestTimeKickOut);
      payload.append('allowShowRecording', values.allowShowRecording);
      payload.append('timeLimit', values.timeLimit);
      payload.append('registerId', values.registerId);
      return await updateStreamingBroadcastSetting({ payload });
    } catch (error) {}
  };

  const handleUserLeft = async (user) => {
    try {
      delete remoteUsers[user.uid];
      setRemoteUsers(() => {
        return { ...remoteUsers };
      });

      await removeRemoteUserFromView(user);
    } catch (error) {}
  };

  const handleUserPublish = async (user, mediaType) => {
    try {
      const uid = user.uid.toString();
      const userType = uid?.split('-')[0];
      await subScribeUserTrack(user, mediaType);
      setRemoteUsers((prev) => {
        return {
          ...prev,
          [uid]: user,
        };
      });

      if (
        mediaType === 'audio' &&
        streamingConfigRef.current.allowMicOff === true
      ) {
        user?.audioTrack?.play();
      }

      if (
        mediaType === 'video' &&
        streamingConfigRef.current.allowCamOn === true
      ) {
        await enableRtcUserTrack(user, mediaType);
      }
    } catch (error) {
      throw error;
    }
  };

  const updateBroadcastSetting = async (payload) => {
    try {
      setIsUpdatingConfig(true);
      await updateStreamingBroadcastSettings(payload);
      setIsUpdatingConfig(false);
    } catch (error) {
      setIsUpdatingConfig(false);
    }
  };

  const updateStreamBroadcastConfig = async (payload) => {
    try {
      setIsUpdatingConfig(true);
      await updateStreamingBroadcastConfigs(payload);
      setIsUpdatingConfig(false);
    } catch (error) {
      setIsUpdatingConfig(false);
    }
  };

  const getTotalSeconds = (hours, minutes, seconds) => {
    return hours * 3600 + minutes * 60 + seconds;
  };

  const handleStart = () => {
    setIsRunning(true);
  };

  const handleOpenSetting = (value) => {
    if (isOnline) {
      setOpenSetting(value);
    } else {
      notification({
        title: 'Broadcasting',
        type: 'warning',
        message: 'You are currently offline, action not allowed',
      });
    }
  };

  const handleOpenEmoji = () => {
    setEnableEmoji(!enableEmoji);
  };

  const handleSaveRoomAndTopic = async (event) => {
    try {
      if (isOnline) {
        const name = event.name;
        const value = event.value;

        const channelConfigs = await buildConfig(streamingConfig);
        const hostId = await getStreamingId();
        await updateStreamingConfigs(
          liveBroadcast?.isOnPrivateCall ? privateLink : link,
          [
            ...channelConfigs,
            {
              key: 'isModelOnline',
              value: `${isOnline}`,
            },
            {
              key: 'hostId',
              value: `${hostId}`,
            },
            {
              key: name,
              value: value,
            },
          ]
        );
        notification({
          title: 'Broadcasting',
          type: 'success',
          message: `Successfully updated the ${
            name === 'topic' ? 'channel topic' : 'channel room name'
          } `,
        });
      } else {
        notification({
          title: 'Broadcasting',
          type: 'warning',
          message: 'You are currently offline, action not allowed',
        });
      }
    } catch (error) {}
  };

  const handleConfigOnChange = async (event) => {
    if (isOnline) {
      const name = event.target.name;
      const value = event.target.checked;
      setStreamingConfig(() => {
        return {
          ...streamingConfig,
          [name]: value,
        };
      });
      const payload = {
        ...streamingConfig,
        [name]: value,
        registerId: user.clientId,
      };
      await updateStreamBroadcastConfig(payload);
    } else {
      notification({
        title: 'Broadcasting',
        type: 'warning',
        message: 'You are currently offline, action not allowed',
      });
    }
  };

  const handleSettingOnChange = async (event) => {
    if (isOnline) {
      const name = event.target.name;
      const value = event.target.checked;
      setStreamingConfig(() => {
        return {
          ...streamingConfig,
          [name]: value,
        };
      });
      streamingConfigRef.current = {
        ...streamingConfig,
        [name]: value,
      };
      const payload = {
        ...streamingConfig,
        [name]: value,
        textSizeData: streamingConfig.textSizeData,
        registerId: user.clientId,
      };
      await updateBroadcastSetting(payload);
    } else {
      notification({
        title: 'Broadcasting',
        type: 'warning',
        message: 'You are currently offline, action not allowed',
      });
    }
  };

  const handleReset = () => {
    setHours(0);
    setMinutes(0);
    setSeconds(0);
    setIsRunning(false);
  };

  const muteVideo = async () => {
    try {
      if (isOnline) {
        setIsUpdatingConfig(true);
        const updateValue = streamingConfig.allowCamOn ? false : true;
        setStreamingConfig(() => {
          return {
            ...streamingConfig,
            allowCamOn: updateValue,
          };
        });
        streamingConfigRef.current = {
          ...streamingConfig,
          allowCamOn: updateValue,
        };
        await updateStreamingBroadcastConfigs({
          ...streamingConfig,
          allowCamOn: updateValue,
          registerId: user.clientId,
        });

        if (updateValue) {
          if (localTracks[1]) {
            localTracks[1]?.play(`user-host-${user.clientId}`);
            localTracks[1]?.setEnabled(true);
          }
        } else {
          if (localTracks[1]) {
            localTracks[1]?.setEnabled(false);
            localTracks[1]?.stop(`user-host-${user.clientId}`);
          }
        }

        setIsUpdatingConfig(false);
      } else {
        setIsUpdatingConfig(false);
        notification({
          title: 'Broadcasting',
          type: 'warning',
          message: 'You are currently offline, action not allowed',
        });
      }
    } catch (error) {
      notification({
        title: 'Broadcasting',
        type: 'danger',
        message: 'Error occurred taking the action',
      });
    }
  };

  const muteAudio = async () => {
    try {
      if (isOnline) {
        setIsUpdatingConfig(true);
        const updateValue = streamingConfig.allowMicOff ? false : true;
        setStreamingConfig(() => {
          return {
            ...streamingConfig,
            allowMicOff: updateValue,
          };
        });
        streamingConfigRef.current = {
          ...streamingConfig,
          allowMicOff: updateValue,
        };
        await updateStreamingBroadcastConfigs({
          ...streamingConfig,
          allowMicOff: updateValue,
          registerId: user.clientId,
        });

        if (updateValue) {
          if (localTracks[0]) {
            localTracks[0]?.setEnabled(updateValue);
            localTracks[0]?.play(`user-host-${user.clientId}`);
          }
        } else {
          if (localTracks[0]) {
            localTracks[0]?.setEnabled(updateValue);
            localTracks[0]?.stop(`user-host-${user.clientId}`);
          }
        }
        setIsUpdatingConfig(false);
      } else {
        setIsUpdatingConfig(false);
        notification({
          title: 'Broadcasting',
          type: 'warning',
          message: 'You are currently offline, action not allowed',
        });
      }
    } catch (error) {
      notification({
        title: 'Broadcasting',
        type: 'danger',
        message: 'Error occurred taking the action',
      });
    }
  };

  const handleMemberJoinedMessagingEvent = async (event) => {
    try {
      const { eventType, publisher } = event;

      const allowEnterSound = streamingConfigRef.current?.userEnteredSound;

      if (eventType === EventTypes.REMOTE_JOIN) {
        await handleMemberJoinedMessaging(
          liveBroadcast?.isOnPrivateCall ? privateLink : link,
          publisher,
          messageContentRef
        );
        if (allowEnterSound) {
          await broadcastAlert('joined');
        }
        await getListOfMembersJoinedAndAddToView();
        await getCurrentOnlineUsers();
        await getParticipants();
      } else if (eventType === EventTypes.REMOTE_LEAVE) {
        await handleMemberLeftMessaging(
          liveBroadcast?.isOnPrivateCall ? privateLink : link,
          publisher
        );
        await getCurrentOnlineUsers();
        await getParticipants();
      }
    } catch (error) {}
  };

  const handleEndPrivateBroadcast = async () => {
    try {
      setBroadCastLink('');
      setPrivateLink('');
      dispatch(
        updatePrivateLink({
          privateLink: '',
        })
      );
      const msgPayload = {
        type: 'privateRequestEnded',
        text: {
          message: `${user?.fullName} has ended the private broadcast`,
          link: '',
          user: user?.fullName,
        },
        author: `${user?.fullName}`,
      };
      await sendMessage(
        liveBroadcast?.isOnPrivateCall ? privateLink : link,
        msgPayload,
        messageContentRef,
        null
      );
    } catch (error) {}
  };
  const handleChannelMessage = async (event) => {
    try {
      const { message } = event;

      const allowMessageSound =
        streamingConfigRef.current?.messageReceivedSound;
      const parsedMessage = JSON.parse(message);

      if (parsedMessage?.type === 'privateRequest') {
        setOpenPrivateRequestModal(true);
        setPrivateLink(parsedMessage?.text?.link);
        // setBroadCastLink(parsedMessage?.text?.link);
        setPrivateRequestUser(parsedMessage?.text?.user);
        dispatch(
          updatePrivateLink({
            privateLink: parsedMessage?.text?.link,
          })
        );
      } else if (parsedMessage?.type === 'privateRequestEnded') {
        await endBroadcast();
        dispatch(
          updateIsOnPrivateCall({
            isOnPrivateCall: false,
          })
        );
        dispatch(
          updatePrivateLink({
            privateLink: '',
          })
        );
        setBroadCastLink('');
        setPrivateLink('');
      }

      if (parsedMessage?.type === 'chat') {
        await persistMessage(parsedMessage);
        await handleBroadcastingChannelMessage(message, messageContentRef);
        if (allowMessageSound === true) {
          await broadcastAlert('message');
        }
      }

      if (parsedMessage?.type === 'bot') {
        await persistMessage(parsedMessage);
        await handleBroadcastingChannelMessage(message, messageContentRef);
        if (streamingConfigRef.current?.userEnteredSound === true) {
          await broadcastAlert('joined');
        }
      }

      if (parsedMessage?.type === 'tip') {
        await persistMessage(parsedMessage);
        await handleBroadcastingChannelMessage(message, messageContentRef);

        if (streamingConfigRef.current?.tipReceivedSound === true) {
          await broadcastAlert('tip');
        }
      }
    } catch (error) {}
  };

  const getListOfMembersJoinedAndAddToView = async () => {
    try {
      const members = await getRtcMembers(
        liveBroadcast?.isOnPrivateCall ? privateLink : link
      );

      await addAllMembersToMessagingView(
        streamContainerRef,
        members?.occupants
      );
    } catch (error) {
      throw error;
    }
  };

  const handleChannelConfig = async () => {
    try {
      const channelConfigs = await buildConfig(streamingConfig);
      const hostId = await getStreamingId();
      updateStreamingConfigs(
        liveBroadcast?.isOnPrivateCall ? privateLink : link,
        [
          ...channelConfigs,
          {
            key: 'isModelOnline',
            value: `${isOnline}`,
          },
          {
            key: 'hostId',
            value: `${hostId}`,
          },
          {
            key: 'topic',
            value: 'Enjoying the feelings',
          },
          {
            key: 'channelName',
            value: `${
              liveBroadcast?.isOnPrivateCall
                ? 'Private channel'
                : 'General channel'
            }`,
          },
        ]
      );
    } catch (error) {}
  };

  const handleChangeDevices = async (config) => {
    try {
      if (localTracks.length > 0) {
        if (
          localTracks[1]?.enabled === false ||
          localTracks[0]?.enabled === false
        ) {
          notification({
            title: 'Settings',
            message: 'No cam or mic is on, enable before changing',
            type: 'warning',
          });
          return;
        }
        //  // change something here >>> use setEncoderConfiguration
        const audioConfigurationChange = {
          microphoneId: config.microphoneId,
          ANS: true,
          AEC: true,
        };
        const videoConfigurationChange = {
          cameraId: config.cameraId,
          optimizationMode: 'detail',
        };
        const updateLocalTracks = await createLocalRtcTrack({
          audioConfig: audioConfigurationChange,
          videoConfig: videoConfigurationChange,
        });

        setLocalTracks([...updateLocalTracks]);
        await unPublishTrack(localTracks);
        await addLocalUserToView(updateLocalTracks);
        // await publishTrack(updateLocalTracks);
        notification({
          title: 'Broadcasting',
          type: 'success',
          message: 'Successfully updated broadcast cam and mic devices',
        });
      }
    } catch (error) {}
  };

  const handleResolution = async (config) => {
    try {
      if (localTracks[1]) {
        const width_height = config.width_height?.split('x');
        const height = parseInt(width_height[0]?.trim());
        const width = parseInt(width_height[1]?.trim());

        const payload = {
          height,
          width: width,
          framerate: config.framerate,
          bitrate: config.bitrate,
        };
        // change something here >>> use setEncoderConfiguration
        await setRtcResolution(payload);
      }
    } catch (error) {}
  };

  const joinBroadCast = async () => {
    try {
      setEnabled(false);
      const isDone = await initStreaming(
        `host-${user.clientId}`,
        liveBroadcast?.isOnPrivateCall ? privateLink : link,
        'host'
      );

      if (isDone) {
        dispatch(resetStreamingMsgs());

        const localTrack = await createLocalRtcTrack();

        await updateRtcMemberDetails(
          [
            {
              key: 'id',
              value: `host-${user.clientId}`,
              revision: -1,
            },
            {
              key: 'email',
              value: user.email,
              revision: -1,
            },
            {
              key: 'profileUrl',
              value: user?.profileUrl,
              revision: -1,
            },
            {
              key: 'fullName',
              value: user?.fullName,
              revision: -1,
            },
          ],
          `host-${user.clientId}`
        );

        await registerRtcEvent('user-published', handleUserPublish);
        await registerRtcEvent('user-left', handleUserLeft);
        await registerRtmMessagingEvent(
          'presence',
          handleMemberJoinedMessagingEvent
        );
        await registerRtmMessagingEvent('message', handleChannelMessage);

        setBroadCastLink(
          `${location.origin}/detail/about?broadcast-id=${
            liveBroadcast?.isOnPrivateCall ? privateLink : link
          }`
        );

        await getListOfMembersJoinedAndAddToView();
        await handleChannelConfig();
        await getParticipants();
        await getCurrentOnlineUsers();
        const msgPayload = {
          type: 'bot',
          text: `Welcome to the broadcast ${user?.fullName}`,
          author: '🤖 Bot',
        };
        await sendMessage(
          liveBroadcast?.isOnPrivateCall ? privateLink : link,
          msgPayload,
          messageContentRef,
          { fontSize: streamingConfig?.textSizeData?.fontSize }
        );
        await persistMessage(msgPayload);
        if (localTrack[1]) {
          await camChangeDevice(localTrack[1]);
        }

        if (localTrack[0]) {
          await micChangeDevice(localTrack[0]);
        }

        await enableDualStream();

        await addLocalUserToView(localTrack);
        setLocalTracks(() => [...localTrack]);

        handleStart();
        setIsOnline(true);
        setIsLog(true);
        setEnabled(true);
        const room = liveBroadcast?.isOnPrivateCall
          ? {
              label: 'Private channel',
              value: 'Private channel',
            }
          : {
              label: 'General channel',
              value: 'General channel',
            };
        setRooms([room]);

        if (streamingConfig.allowCamOn == true && localTrack[1]) {
          localTrack[1]?.setEnabled(true);
          localTrack[1]?.play(`user-host-${user.clientId}`);
        }

        if (streamingConfig.allowCamOn === false && localTrack[1]) {
          localTrack[1]?.setEnabled(false);
          localTrack[1]?.stop(`user-host-${user.clientId}`);
        }

        if (streamingConfig.allowMicOff === true && localTrack[0]) {
          localTrack[0]?.setEnabled(true);
          localTrack[0]?.play();
        }
        if (streamingConfig.allowMicOff === false && localTrack[0]) {
          localTrack[0]?.setEnabled(false);
          localTrack[0]?.stop();
        }
      } else {
        notification({
          title: 'Broadcasting',
          type: 'warning',
          message: 'Error occurred while starting the broadcast',
        });
      }
    } catch (error) {
      setIsRunning(false);
      setIsOnline(false);
      setIsLog(false);
      setEnabled(true);
      notification({
        title: 'Broadcasting',
        type: 'warning',
        message: 'Error occurred while starting the broadcast',
      });
    }
  };

  const addLocalUserToView = async (localTracker) => {
    try {
      await publishTrack(localTracker);
      await handleAddLocalUserToView(broadcasterRef, `host-${user.clientId}`);
      // localTracker[1]?.play(`user-host-${user.clientId}`);
    } catch (error) {
      throw error;
    }
  };

  const sendChat = async (event) => {
    event.preventDefault();
    if (isOnline) {
      if (message && message !== '') {
        setIsSending(true);
        const payload = {
          type: 'chat',
          author: `${user?.fullName}`,
          text: message,
        };
        setIsSending(false);
        await sendMessage(
          liveBroadcast?.isOnPrivateCall ? privateLink : link,
          payload,
          messageContentRef,
          {
            fontSize: streamingConfig?.textSizeData?.fontSize,
          }
        );
        await persistMessage(payload);
      }
    } else {
      notification({
        title: 'Broadcasting',
        type: 'warning',
        message: 'You are currently offline, action not allowed',
      });
    }
    setMessage('');
  };

  const onMessageChange = (event) => {
    const message = event.target.value;
    setMessage(message);
  };

  const handleSelectedEmoji = (values) => {
    setMessage((prev) => `${prev + values.emoji}`);
    setEnableEmoji(!enableEmoji);
  };

  const startBroadCast = async () => {
    try {
      if (!isOnline) {
        await joinBroadCast();

        window.addEventListener('beforeunload', () => {
          try {
            endBroadcast();
          } catch (error) {}
        });
        // .then(async () => {
        //   try {

        //   } catch (error) {
        //     throw error
        //   }
        // })
        // .catch((error) => {
        //   console.log(error);
        //   notification({
        //     title: 'Broadcasting',
        //     type: 'danger',
        //     message: 'Error occurred when starting the broadcast',
        //   });
        // });
      }
    } catch (error) {}
  };

  const endBroadcast = async () => {
    try {
      const msgPayload = {
        type: 'bot',
        text: `${user?.fullName} has left the broadcast`,
        author: '🤖 Bot',
      };
      await sendMessage(
        liveBroadcast?.isOnPrivateCall ? privateLink : link,
        msgPayload,
        messageContentRef,
        { fontSize: streamingConfig?.textSizeData?.fontSize }
      );
      await persistMessage(msgPayload);
      leaveStreamingChannel(localTracks, `host-${user.clientId}`).then(
        async () => {
          setIsOnline(false);
          setIsLog(false);
          setLocalTracks([]);
          setEnabled(true);
          setParticipants([]);
          setCurrentOnlineUsers([]);
          setRooms([]);
          dispatch(resetStreamingMsgs());
          if (liveBroadcast?.isOnPrivateCall) {
            dispatch(
              updateIsOnPrivateCall({
                isOnPrivateCall: false,
              })
            );
          }
          handleReset();
          handleClearMessages();
          if (streamContainerRef.current) {
            streamContainerRef.current.innerHTML = '';
          }
          removeMemberFromMessagingView(user.clientId);

          const goalResponse = await getStreamingGoals(user.clientId);
          goalResponse?.map(async (singleGoal) => {
            const data = new FormData();
            data.append('goalSettingId', singleGoal?.goalSettingId);
            data.append('amount', singleGoal?.amount);
            data.append('description', singleGoal?.description);
            data.append('registeredId', user.clientId);
            data.append('setGoal', 'progress');
            await updateStreamingGoal(data);
          });
        }
      );
    } catch (error) {}
  };

  const getStreamingConfig = async () => {
    try {
      const config = await getStreamingConfigs(user.clientId);
      const parsedFontSize = config.textSize;

      setStreamingConfig((prev) => {
        return {
          ...prev,
          ...config,
          allowPrivateChat: config?.allowPrivateChat,
          allowGeneralChat: config?.allowGeneralChat,
          allowMessage: config?.allowMessage,
          allowGuest: config?.allowGuest,
          allowMicOff: config?.allowMicOff,
          allowCamOn: config?.allowCamOn,
          allowTips: config?.allowTips,
          userEnteredSound: config?.userEnteredSound,
          messageReceivedSound: config?.messageReceivedSound,
          tipReceivedSound: config?.tipReceivedSound,
          guestTimeKickOut: config?.guestTimeKickOut,
          allowShowRecording: config?.allowShowRecording,
          timeLimit: config?.timeLimit,
          textSizeData: parsedFontSize,
        };
      });
      streamingConfigRef.current = {
        ...config,
        allowPrivateChat: config?.allowPrivateChat,
        allowGeneralChat: config?.allowGeneralChat,
        allowMessage: config?.allowMessage,
        allowGuest: config?.allowGuest,
        allowMicOff: config?.allowMicOff,
        allowCamOn: config?.allowCamOn,
        allowTips: config?.allowTips,
        userEnteredSound: config?.userEnteredSound,
        messageReceivedSound: config?.messageReceivedSound,
        tipReceivedSound: config?.tipReceivedSound,
        guestTimeKickOut: config?.guestTimeKickOut,
        allowShowRecording: config?.allowShowRecording,
        timeLimit: config?.timeLimit,
        textSizeData: parsedFontSize,
      };
    } catch (error) {}
  };

  const checkVideoTracker = () => {
    if (streamingConfig.allowCamOn && localTracks.length > 0) {
      localTracks[1]?.setEnabled(true);
    }

    if (streamingConfig.allowCamOn === false && localTracks.length > 0) {
      localTracks[1]?.setEnabled(false);
    }
  };

  const checkAudioTracker = () => {
    if (streamingConfig.allowMicOff && localTracks.length > 0) {
      localTracks[0]?.setEnabled(true);
    }

    if (streamingConfig.allowMicOff === false && localTracks.length > 0) {
      localTracks[0]?.setEnabled(false);
    }
  };

  const getCurrentOnlineUsers = async () => {
    const members = await getRtcMembers(
      liveBroadcast?.isOnPrivateCall ? privateLink : link
    );
    setCurrentOnlineUsers(members?.occupants);
  };

  const getParticipants = async () => {
    const members = await getRtcMembers(
      liveBroadcast?.isOnPrivateCall ? privateLink : link
    );
    const participants = [];

    for (let i = 0; i < members?.occupants.length; i++) {
      const userId = members?.occupants[i]?.userId;
      const { metadata } = await getBroadcastMemberDetails(userId);

      participants.push(metadata);
    }

    setParticipants(participants);
  };

  const handleClearMessages = () => {
    if (messageContentRef.current) {
      messageContentRef.current.innerHTML = '';
    }
  };

  const applyFontSize = async (applySize, updateType = 'local') => {
    try {
      await applyChatFontSize(applySize);

      if (updateType === 'live') {
        setStreamingConfig(() => {
          return {
            ...streamingConfig,
            textSizeData: applySize,
          };
        });
        streamingConfigRef.current = {
          ...streamingConfig,
          textSizeData: applySize,
        };
        setIsUpdatingConfig(true);
        const payload = {
          ...streamingConfig,
          textSizeData: applySize,
          registerId: user.clientId,
        };

        await updateBroadcastSetting(payload);
        setIsUpdatingConfig(false);
      }
    } catch (error) {
      setIsUpdatingConfig(false);
    }
  };

  const handleDeclinePrivateRequest = async () => {
    try {
      const msgPayload = {
        type: 'privateRequestDeclined',
        text: {
          message: `${user?.fullName} has declined the private broadcast`,
          link: '',
          user: user?.fullName,
        },
        author: `${user?.fullName}`,
      };
      setPrivateLink('');
      setBroadCastLink(
        `${location.origin}/detail/about?broadcast-id=${
          liveBroadcast?.isOnPrivateCall ? privateLink : link
        }`
      );
      await sendMessage(
        liveBroadcast?.isOnPrivateCall ? privateLink : link,
        msgPayload,
        null
      );
    } catch (error) {}
  };

  const handleAcceptPrivateRequest = async () => {
    try {
      const msgPayload = {
        type: 'privateRequestAccepted',
        text: {
          message: `Private broadcast accepted`,
          link: '',
          user: user?.fullName,
        },
        author: `${user?.fullName}`,
      };
      setBroadCastLink(
        `${location.origin}/detail/about?broadcast-id=${privateLink}`
      );
      await sendMessage(
        liveBroadcast?.isOnPrivateCall ? privateLink : link,
        msgPayload,
        null
      );
    } catch (error) {}
  };

  const startPrivateCall = async () => {
    endBroadcast().then(() => {
      dispatch(
        updateIsOnPrivateCall({
          isOnPrivateCall: true,
        })
      );
    });
  };

  const startPrivateCallFromParticipantModal = async (participantId) => {
    try {
      const msgPayload = {
        type: 'privateRequestAccepted',
        text: {
          message: `${user?.fullName} wants to start private broadcast with you`,
          link: '',
          user: user?.fullName,
        },
        author: `${user?.fullName}`,
      };

      await sendMessage(
        liveBroadcast?.isOnPrivateCall ? privateLink : link,
        msgPayload,
        null
      );

      await endBroadcast();
      setPrivateLink(`private-channel-${participantId}`);
      dispatch(
        updateIsOnPrivateCall({
          isOnPrivateCall: true,
        })
      );
    } catch (error) {}
  };

  const getBroadcastGoals = async () => {
    try {
      const response = await getBroadcastGoal({ modelId: user.clientId });
      setGoals(response);
    } catch (error) {
      setGoals([]);
    }
  };

  const handleFullScreen = () => {
    const isFullscreen = document.fullscreenElement;

    if (!isFullscreen) {
      broadcasterRef?.current.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  const handleGetConfig = async () => {
    try {
      const config =
        (await getChannelConfigs(
          liveBroadcast?.isOnPrivateCall ? privateLink : link
        )) ?? {};

      const { metadata } = config;
      setRooms(() => {
        return [
          {
            label: metadata?.channelName?.value,
            value: metadata?.channelName?.value,
          },
        ];
      });
    } catch (error) {}
  };

  const ItemRenderWrapper = useCallback(
    ({ ...rest }) => {
      return (
        <BroadcastEdit
          startPrivateCallFromParticipantModal={
            startPrivateCallFromParticipantModal
          }
          {...rest}
          handleOpenChangeTopic={() => {
            setIsChangingTopicNameOpen(true);
          }}
          handleOpenChangeRoom={() => {
            setIsChangingRoomNameOpen(true);
          }}
          handleCloseParticipants={() => {
            setOpenParticipants(false);
          }}
          participants={participants}
          openParticipants={openParticipants}
          handleOpenParticipants={() => {
            setOpenParticipants(true);
          }}
        />
      );
    },
    [openParticipants, participants]
  );

  useEffect(() => {
    if (isOnline) {
      applyChatFontSize(streamingConfig.textSizeData);
    }
  }, [streamingConfig.textSizeData, isOnline]);

  useEffect(() => {
    if (isOnline) {
      if (streamingConfig?.allowCamOn === false && localTracks[1]) {
        localTracks[1]?.setEnabled(false);
        localTracks[1]?.stop(`user-host-${user.clientId}`);
      }

      if (streamingConfig?.allowMicOff === false && localTracks[0]) {
        localTracks[0]?.setEnabled(false);
        localTracks[0]?.stop();
      }
    }
  }, [streamingConfig.allowCamOn, isOnline]);

  useEffect(() => {
    const getStreamingConfigIntervalId = setInterval(() => {
      handleGetConfig();
      getStreamingConfig();
    }, 5000);

    handleLiveMessages();
    getStreamingConfig();
    getBroadcastGoals();
    return () => {
      try {
        endBroadcast();
        clearInterval(getStreamingConfigIntervalId);
      } catch (error) {}
    };
  }, []);

  useEffect(() => {
    getStreamingConfig();
    if (isOnline) {
      handleChannelConfig();
    }
  }, [isUpdatingConfig, isOnline]);

  useEffect(() => {
    if (isOnline) {
      checkVideoTracker();
      checkAudioTracker();
    }
  }, [
    streamingConfig.allowCamOn,
    streamingConfig.allowMicOff,
    isOnline,
    localTracks,
  ]);

  useEffect(() => {
    let intervalId = null;

    if (isRunning === true) {
      intervalId = setInterval(() => {
        const updatedSeconds = seconds + 1;
        const updatedMinutes = minutes + Math.floor(updatedSeconds / 60);
        const updatedHours = hours + Math.floor(updatedMinutes / 60);

        setSeconds(updatedSeconds % 60);
        setMinutes(updatedMinutes % 60);
        setHours(updatedHours);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [hours, minutes, seconds, isRunning]);

  useEffect(() => {
    const totalSeconds = hours * 3600 + minutes * 60 + seconds;

    // Reset the timer after 24 hours
    if (totalSeconds >= 24 * 60 * 60) {
      handleReset();
    }
  }, [hours, minutes, seconds]);

  useEffect(() => {
    handleLiveMessages();
  }, [liveMessages?.general]);

  return (
    <FluidContainer>
      <ChangeRoomModal
        handleSaveRoomAndTopic={handleSaveRoomAndTopic}
        open={isChangingRoomNameOpen}
        handleClose={() => {
          setIsChangingRoomNameOpen(false);
        }}
        getStreamingConfig={async () => {
          try {
            return await getChannelConfigs(
              liveBroadcast?.isOnPrivateCall ? privateLink : link
            );
          } catch (error) {
            throw error;
          }
        }}
      />
      <ChangeTopicModal
        getStreamingConfig={async () => {
          try {
            return await getChannelConfigs(
              liveBroadcast?.isOnPrivateCall ? privateLink : link
            );
          } catch (error) {
            throw error;
          }
        }}
        handleSaveRoomAndTopic={handleSaveRoomAndTopic}
        open={isChangingTopicNameOpen}
        handleClose={() => {
          setIsChangingTopicNameOpen(false);
        }}
      />
      <PrivateRequestModal
        handleDeclinePrivateRequest={handleDeclinePrivateRequest}
        handleAcceptPrivateRequest={handleAcceptPrivateRequest}
        requestUser={privateRequestUser}
        handleEnablePrivateRequest={startPrivateCall}
        open={openPrivateRequestModal}
        handleClose={() => {
          setOpenPrivateRequestModal(false);
        }}
      />

      <ShareBroadcastLinkModal
        broadCastLink={broadCastLink}
        open={isShareModalOpen}
        handleClose={() => {
          setIsShareModalOpen(false);
        }}
      />

      <SettingPop
        enableAudio={streamingConfig.allowMicOff}
        enableVideo={streamingConfig.allowCamOn}
        applyFontSize={applyFontSize}
        handleResolution={handleResolution}
        handleChangeDevices={handleChangeDevices}
        isOnline={isOnline}
        handleSettingOnChange={handleSettingOnChange}
        config={streamingConfig}
        open={openSetting}
        handleClose={() => handleOpenSetting(false)}
      />
      <ModelProfileHeader />
      <main>
        <section className={styles.topNav}>
          <div className={styles.leftTopNav}>
            <h1 className={styles.title}>Broadcast</h1>
            <nav className={styles.nav}>
              <li className={styles.credits}>
                Credits earned : <span>0</span>
              </li>
              <li
                className={styles.shareLink}
                onClick={() => {
                  if (broadCastLink) {
                    setIsShareModalOpen(true);
                  }
                }}
              >
                Share Broadcast Link{' '}
                <RiShareForwardFill className={styles.icon} />
              </li>
            </nav>
          </div>
          <button className={styles.recordButton}>
            {' '}
            <span
              className={classNames(
                isOnline ? styles.onlineStatus : styles.offlineStatus,
                'w-[10px] h-[10px] rounded-full '
              )}
            ></span>
            REC
          </button>
        </section>

        <section className={styles.mainLiveStreamContainer}>
          <section>
            <section className={styles.streamControl}>
              <div
                className={classNames(
                  styles.streamBox,
                  isOnline ? styles.online : styles.offline
                )}
                ref={broadcasterRef}
              >
                {!streamingConfigRef.current.allowCamOn === true &&
                  isOnline === true && (
                    <div className={styles.placeCountDown}>
                      <Counter
                        hours={hours}
                        minutes={minutes}
                        seconds={seconds}
                      />
                    </div>
                  )}

                {isOnline === false && (
                  <div className={styles.offlineCamera}>
                    <div className={styles.offlineCameraContainer}>
                      {!enable ? (
                        <Spinner width='50' height='50' />
                      ) : (
                        <>
                          <VideoOffIcon />
                          Camera is off
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className={styles.streamController}>
                <span className={styles.streamInnerController}>
                  <RiFullscreenFill
                    onClick={handleFullScreen}
                    className={styles.fullScreenIcon}
                  />
                  FullScreen
                </span>
                <span
                  className={styles.streamInnerController}
                  onClick={muteAudio}
                >
                  {!streamingConfigRef.current.allowMicOff && (
                    <span className={styles.muteCrossIcon}></span>
                  )}
                  <MicIcon />
                  Mic OFF
                </span>
                <span
                  className={styles.streamInnerController}
                  onClick={muteVideo}
                >
                  {!streamingConfigRef.current?.allowCamOn && (
                    <span className={styles.muteCrossIcon}></span>
                  )}
                  <CamIcon />
                  Cam OFF
                </span>
                <span
                  className={styles.streamInnerController}
                  onClick={() => {
                    handleOpenSetting(true);
                  }}
                >
                  <SettingIcon />
                  Setting
                </span>
              </div>
              <Button
                className={classNames(
                  styles.broadcastButton,
                  !isOnline ? styles.onlineButton : styles.offlineButton
                )}
                // disabled={enable ? false : true}
                onClick={() => {
                  if (isOnline) {
                    if (liveBroadcast.isOnPrivateCall) {
                      handleEndPrivateBroadcast();
                      endBroadcast();
                    } else {
                      endBroadcast();
                    }
                  } else {
                    startBroadCast();
                  }
                }}
                text={
                  isOnline
                    ? liveBroadcast?.isOnPrivateCall
                      ? 'End private broadcast'
                      : 'End broadcast'
                    : liveBroadcast?.isOnPrivateCall
                    ? 'Start private call'
                    : 'Start broadcast'
                }
              />
            </section>

            <section className={classNames(styles.streams_container)}>
              <h1 className={classNames(styles.logTitle)}>Activity log</h1>
              {!isOnline && (
                <div className='h-[100%] justify-center items-center flex flex-col  '>
                  <NoLogIcon className='mb-[20px]  text-primary-300' />
                  <p className="text-center text-primary-300 text-base font-semibold font-['Montserrat'] ">
                    No activity yet
                  </p>
                </div>
              )}
              <div
                ref={streamContainerRef}
                className={classNames(styles.streams_container_wrapper)}
              ></div>
            </section>
          </section>
          <section className={styles.chatRoomContainer}>
            <section className={styles.chatRoomHeader}>
              <div className={styles.chatGeneralHeader}>
                <h1 className={styles.broadcastStatus}>
                  {isOnline ? 'Live' : 'Offline'}{' '}
                  <span
                    className={
                      isOnline ? styles.onlineStatus : styles.offlineStatus
                    }
                  ></span>
                </h1>
                <div className={styles.chatGeneralStatics}>
                  <span
                    onClick={handleClearMessages}
                    className={styles.clearMessage}
                  >
                    Clear <RiDeleteBin6Line />
                  </span>
                  <div className={styles.chatGeneralStaticsInnerContainer}>
                    {liveBroadcast?.isOnPrivateCall ? (
                      <Button
                        onClick={() => {
                          if (isOnline) {
                            if (liveBroadcast.isOnPrivateCall) {
                              handleEndPrivateBroadcast();
                              endBroadcast();
                            } else {
                              endBroadcast();
                            }
                          } else {
                            startBroadCast();
                          }
                        }}
                        text={
                          liveBroadcast?.isOnPrivateCall
                            ? 'End Private Call'
                            : 'Start Private Call'
                        }
                        className={styles.startPrivateCallButton}
                      />
                    ) : (
                      <h1 className={styles.chatGeneralStaticsList}>
                        Guests: <span>{currentOnlineUsers?.length}</span>
                      </h1>
                    )}
                  </div>
                </div>
              </div>
            </section>
            <section
              className={classNames(
                styles.chatRoomMessageContainer,
                styles.flipScrollBar,
                '!relative'
              )}
            >
              <div
                className={classNames(styles.chatRoomMessageContent)}
                ref={messageContentRef}
              ></div>
              {!isOnline && (
                <div className='h-[100%] left-[0] right-[0] absolute justify-center items-center flex flex-col  '>
                  <NoMessageIcon className='mb-[24px]' />
                  <p className="max-w-[200px] text-center text-primary-100 text-base font-semibold font-['Montserrat'] ">
                    Messages sent during broadcast are displayed here
                  </p>
                </div>
              )}
            </section>

            <section className={styles.chatRoomFooter}>
              <form
                className={styles.chatRoomInputContainer}
                onSubmit={sendChat}
              >
                {enableEmoji && <Emoji onSelectedEmoji={handleSelectedEmoji} />}
                <input
                  value={message ? message : ''}
                  onChange={onMessageChange}
                  type={'text'}
                  className={styles.chatRoomInput}
                  placeholder='Enter a message...'
                />
                <div className={styles.actionIconContainer}>
                  <EmojiIcon
                    onClick={handleOpenEmoji}
                    className={styles.emojiIcon}
                  />
                  {isSending ? (
                    <Spinner />
                  ) : (
                    <SendIcon onClick={sendChat} className={styles.sendIcon} />
                  )}
                </div>
              </form>
            </section>
          </section>
          <section>
            <section className={classNames(styles.broadcastingRoomContainer)}>
              <DropDown
                enableHandle={true}
                dropDownClass={styles.dropDownClass}
                customItemRender={ItemRenderWrapper}
                options={rooms}
                selectClass={classNames(
                  styles.select,
                  isOnline && styles.activeBackgroundOne
                )}
                className={styles.selectContainer}
                dropElement={({ props, state, methods }) => {
                  return (
                    <div className={classNames(styles.roomDownRender)}>
                      {' '}
                      Rooms : {isOnline ? 1 : 0}
                    </div>
                  );
                }}
              />
            </section>
            <Counter
              className={classNames(
                styles.sideBarCountDown,
                isOnline && styles.activeBackgroundOne
              )}
              hours={hours}
              minutes={minutes}
              seconds={seconds}
            />
            <section
              className={classNames(
                styles.broadcastingSettingContainer,
                isOnline && styles.activeBackgroundTwo
              )}
            >
              <div className={styles.broadcastingSettingInnerWrapper}>
                <span className={styles.broadcastingSettingList}>
                  <CheckBox
                    checked={streamingConfig?.allowGeneralChat}
                    onChange={handleConfigOnChange}
                    name='allowGeneralChat'
                    checkedColor={'#775E96'}
                    className={styles.broadcastingSettingCheckBox}
                  />
                  Allow general chat
                </span>
                <span className={styles.broadcastingSettingList}>
                  <CheckBox
                    checked={streamingConfig?.allowPrivateChat}
                    onChange={handleConfigOnChange}
                    name='allowPrivateChat'
                    checkedColor={'#775E96'}
                    className={styles.broadcastingSettingCheckBox}
                  />
                  Allow private request
                </span>
              </div>
            </section>
            <CurrentGoalShow
              isOnline={isOnline}
              setOpenGoalModal={setOpenGoalModal}
            />
            <GoalModal
              isOnline={isOnline}
              broadcastGoals={goals}
              open={openGoalModal}
              handleClose={() => setOpenGoalModal(false)}
            />
          </section>
        </section>
      </main>
    </FluidContainer>
  );
};

export default ModelLiveStream;
