import React, { useState, useEffect } from 'react';
import FluidContainer from '../../../../custom/fluidContainer/FluidContainer';
import { IoIosArrowRoundBack } from 'react-icons/io';
import {
  getClubFeed,
  addComment,
  likeAndGetPostLike,
  getClubLikes,
} from '../../../../request/club';
import { useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import Spinner from '../../../../custom/spinner/Spinner';
import { PiDotsThreeOutlineLight } from 'react-icons/pi';
import { Popover } from '@radix-ui/themes';
import { SlLike } from 'react-icons/sl';
import errorFormmatter from '../../../../utils/errorFormatter';
import notification from '../../../../utils/notification';
import { useSelector } from 'react-redux';
import { FaRegCommentAlt } from 'react-icons/fa';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Avatar } from '@radix-ui/themes';
import { getFirstTwoCharacters } from '../../../../utils/index';

const clubId = 'b002d30c-ef07-4462-8489-2c9d4568da3a';

const CommentSchema = Yup.object().shape({
  message: Yup.string()
    .trim()
    .required('message is required'),
});

const initialValue = {
  message: '',
};

const CommentFeed = () => {
  const params = useLocation();
  const route = useHistory();
  const [loading, setLoading] = useState(false);
  const [clubFeed, setClubFeed] = useState([]);
  const [comments, setComments] = useState([]);
  const [likes, setLikes] = useState(0);
  const [isLike, setIsLike] = useState(false);

  const user = useSelector((state) => state?.user);

  const handleLikeAndGetPostLike = async () => {
    try {
      const postId = params?.search?.split('postId=')[1] ?? '';
      const response = await getClubLikes({
        postId,
        clubId,
        memberId: user?.clientId,
      });

      setLikes(response?.likeCount ?? 0);
      setIsLike(response?.changeColor);
    } catch (error) {
      setLikes(0);
      //setIsLike(clubFeed?.changeColor);
    }
  };

  const handleLike = async () => {
    try {
      const like = !clubFeed?.changeColor;
      const postId = params?.search?.split('postId=')[1] ?? '';
      setLoading(true);
      const response = await likeAndGetPostLike({
        clubId,
        memberId: user?.clientId,
        postId,
        userLike: like,
      });

      // setLikes(response?.clubLike ?? 0);
      // setIsLike(response?.changeColor);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setLikes(0);
      // setIsLike(clubFeed?.changeColor);
    }
  };

  const handleGetFeed = async () => {
    try {
      const postId = params?.search?.split('postId=')[1] ?? '';
      const response = await getClubFeed({
        clubId,
        postId,
      });

      setClubFeed(response);
      setComments(response?.comments ?? []);
      //setLikes(response?.likeCount);
    } catch (error) {
      setClubFeed({});
    }
  };

  const handleAddComment = async (values) => {
    try {
      const postId = params?.search?.split('postId=')[1] ?? '';
      const payload = new FormData();
      setLoading(true);
      payload.append('parentId', 0);
      payload.append('clubID', clubId);
      payload.append('commentText', values.message);
      payload.append('commenterName', user.fullName);
      payload.append('memberRegId', user.clientId);
      payload.append('clubPostId', postId);

      await addComment({ payload });
      notification({
        title: 'Comment',
        message: 'Successfully sent a comment',
        type: 'success',
      });
      setLoading(false);
    } catch (error) {
      notification({
        title: 'Comment',
        message: errorFormmatter(error),
        type: 'danger',
      });
      setLoading(false);
    }
  };

  const handleAddReply = async (replyContent, parentId = null) => {
    try {
      const postId = params?.search?.split('postId=')[1] ?? '';
      const payload = new FormData();
      setLoading(true);
      console.log(parentId);
      payload.append('parentId', parentId);
      payload.append('clubID', clubId);
      payload.append('commentText', replyContent);
      payload.append('commenterName', user.fullName);
      payload.append('memberRegId', user.clientId);
      payload.append('clubPostId', postId);

      await addComment({ payload });
      notification({
        title: 'Comment',
        message: 'Successfully sent a comment',
        type: 'success',
      });
      setLoading(false);
    } catch (error) {
      notification({
        title: 'Comment',
        message: errorFormmatter(error),
        type: 'danger',
      });
      setLoading(false);
    }
  };

  const handleGoBack = () => {
    route.goBack();
  };

  useEffect(() => {
    handleGetFeed();
    handleLikeAndGetPostLike();
  }, [params?.search && params?.search, loading]);

  return (
    <FluidContainer className={''}>
      <section className='w-[100%] mt-[64px] mb-[3rem] flex-col justify-start items-start gap-10 inline-flex'>
        <section className='justify-start items-center gap-2 inline-flex'>
          <IoIosArrowRoundBack
            onClick={handleGoBack}
            className='text-[2.3rem] cursor-pointer'
          />
          <span
            onClick={handleGoBack}
            className="cursor-pointer text-white text-2xl font-semibold font-['Montserrat'] leading-7"
          >
            Back to club scene
          </span>
        </section>
        <section className='self-stretch mb-[3rem] flex-col justify-start items-end gap-10 flex'>
          <section className='self-stretch justify-start items-start gap-5 inline-flex'>
            <section className='justify-start items-start gap-5 flex '>
              <img
                className='w-16 h-16 rounded-full'
                src={clubFeed?.profileImage}
              />
            </section>
            <section className='grow shrink basis-0 flex-col justify-start items-start gap-10 inline-flex'>
              <section className='self-stretch  flex-col justify-start items-start gap-6 flex'>
                <section className='self-stretch  flex-col justify-start items-start gap-4 flex'>
                  <div className='self-stretch  flex-col justify-start items-start gap-2 flex'>
                    <div className='self-stretch pr-4 justify-between items-center inline-flex'>
                      <div className='justify-start items-baseline gap-2.5 flex'>
                        <div className=' justify-center items-center gap-1 flex'>
                          <span className="text-white text-2xl font-bold font-['Montserrat'] leading-7">
                            {clubFeed?.name}
                          </span>
                        </div>
                        <span className="text-[#afafaf] text-xl font-normal font-['Montserrat'] leading-[23px]">
                          {moment(clubFeed?.postDate).fromNow()}
                        </span>
                      </div>
                      <PiDotsThreeOutlineLight className='text-white' />
                    </div>
                    <div>
                      <p className="mb-[8px] text-white text-xl font-bold font-['Montserrat'] leading-[23px]">
                        {clubFeed?.title}
                      </p>
                      <p className="self-stretch text-white text-lg font-normal font-['Montserrat'] leading-tight">
                        {clubFeed?.description}
                      </p>
                    </div>
                  </div>
                  <section className=' w-[100%]'>
                    <img
                      className='rounded-[10px] w-[100%] h-[467px]'
                      src={clubFeed?.imagePostURL}
                    />
                  </section>
                </section>
                <section className='justify-start items-center gap-[1rem] inline-flex'>
                  <div className='justify-start items-center gap-3 flex'>
                    <SlLike
                      onClick={() => handleLike()}
                      className={`cursor-pointer ${
                        isLike ? 'text-red-500' : 'text-white'
                      }`}
                    />
                    <span className="block text-neutral-300 text-lg font-normal font-['Montserrat'] leading-tight">
                      {likes}
                    </span>
                  </div>
                  <div className='justify-start items-center gap-3 flex'>
                    <FaRegCommentAlt className='text-white' />
                    <span className="block text-neutral-300 text-lg font-normal font-['Montserrat'] leading-tight">
                      {clubFeed?.comments?.length ?? 0}
                    </span>
                  </div>
                </section>
              </section>
              <section className='self-stretch flex-col justify-start items-start  flex'>
                {clubFeed?.comments?.length > 0 && (
                  <h1 className=" text-white text-xl font-semibold font-['Montserrat'] leading-[23px]">
                    Comments
                  </h1>
                )}

                <section className='pb-[10px] pt-[40px] self-stretch overflow-y-auto  flex-col justify-start items-start gap-6 flex'>
                  {comments?.map((comment, index) => (
                    <Comment
                      loading={loading}
                      canReply={true}
                      key={index}
                      comment={comment}
                      onAddReply={handleAddReply}
                    />
                  ))}
                </section>
                <section className='self-stretch h-[152px] py-[21px] flex-col justify-start items-start gap-6 flex'>
                  <section className='self-stretch justify-start items-start gap-6 inline-flex'>
                    <section className='max-w-[max-content]'>
                      {/* <img
                        className='w-16 h-16 rounded-full'
                        src={user?.profileUrl}
                      /> */}
                      <Avatar
                        color='blue'
                        src={user?.profileUrl}
                        className=' !w-16 !h-16  !rounded-[50%]  '
                        fallback={
                          <span>{getFirstTwoCharacters(user?.fullName)}</span>
                        }
                      />
                    </section>
                    <section className='flex-1'>
                      <Formik
                        enableReinitialize={true}
                        validationSchema={CommentSchema}
                        initialValues={initialValue}
                        onSubmit={handleAddComment}
                      >
                        {(props) => {
                          return (
                            <>
                              <div className='w-[100%]  mb-[1rem]'>
                                <textarea
                                  name='message'
                                  onChange={props.handleChange}
                                  className="w-[100%] self-stretch text-[#d3d3d3] text-base font-normal font-['Montserrat'] leading-[18.40px] grow shrink basis-0 pl-3 pt-3 pb-20 hover:border-primary-100 !outline-none !appearance-none !bg-[#000] rounded-lg border border-primary-100 text-[#d3d3d3] placeholder:text-base placeholder:font-normal placeholder:font-['Montserrat'] "
                                  placeholder='Add your comment..'
                                />
                                {props.touched && props.errors?.message && (
                                  <span
                                    className={
                                      "text-[#b87693] font-['Montserrat'] text-[12px] font-normal font-[500] "
                                    }
                                  >
                                    * {props.errors?.message}
                                  </span>
                                )}
                              </div>
                              <div className='!flex !flex-col items-end'>
                                <button
                                  onClick={props.handleSubmit}
                                  className="w-[80px] gap-[0.3rem] cursor-pointer h-[30px] bg-red-700 rounded-sm  flex items-center justify-center shadow text-center text-white text-base font-medium font-['Montserrat'] leading-[18.40px]"
                                >
                                  {loading && <Spinner />}
                                  Send
                                </button>
                              </div>
                            </>
                          );
                        }}
                      </Formik>
                    </section>
                  </section>
                </section>
              </section>
            </section>
          </section>
        </section>
      </section>
    </FluidContainer>
  );
};

const Comment = ({ loading, comment, onAddReply, canReply = true }) => {
  const [showReplies, setShowReplies] = useState(false);

  return (
    <div className='self-stretch  flex-col justify-start items-start gap-1 flex'>
      <div className='self-stretch justify-start items-start gap-6 inline-flex'>
        <div className='justify-start items-start gap-5 flex'>
          <img
            className='w-16 h-16 rounded-full'
            src={comment?.profileAvatar}
          />
        </div>
        <div className='grow shrink basis-0 flex-col justify-start items-start gap-4 inline-flex'>
          <div className='self-stretch h-[81px] flex-col justify-start items-start gap-2 flex'>
            <div className='self-stretch pr-4 justify-between items-center inline-flex'>
              <div className='justify-start items-baseline gap-2.5 flex'>
                <span className="text-white text-2xl font-bold font-['Montserrat'] leading-7">
                  {comment?.commenterName}
                </span>
                <span className="text-[#afafaf] text-xl font-normal font-['Montserrat'] leading-[23px]">
                  {moment(comment?.commentDate).fromNow()}
                </span>
              </div>
              <div className='w-6 h-6 relative' />
            </div>
            <p className="self-stretch text-white text-lg font-normal font-['Montserrat'] leading-tight">
              {comment?.commentText}
            </p>
            <div className='self-stretch justify-start items-center gap-2 inline-flex '>
              {canReply && (
                <PopOver
                  key={comment?.commentID}
                  loading={loading}
                  parentId={comment?.commentID}
                  handleAddReply={onAddReply}
                  renderElement={
                    <span className="cursor-pointer text-[#d3d3d3] text-sm font-normal font-['Montserrat'] leading-none">
                      Reply
                    </span>
                  }
                />
              )}
              {comment?.replies?.length > 0 && (
                <button
                  className='text-primary-100 text-sm'
                  onClick={() => setShowReplies(!showReplies)}
                >
                  {`Reply(${comment?.replies?.length})`}
                </button>
              )}
            </div>
          </div>
          {showReplies && comment?.replies?.length > 0 && (
            <div className='pl-10 mt-2'>
              {comment?.replies?.map((reply, index) => (
                <Comment
                  canReply={false}
                  key={index}
                  comment={reply}
                  onAddReply={onAddReply}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const PopOver = ({ loading, renderElement, parentId, handleAddReply }) => {
  const handleSubmit = (values) => {
    console.log(values.message);
    handleAddReply(values.message, parentId);
  };
  return (
    <Popover.Root maxHeight='30'>
      <Popover.Trigger>
        {typeof renderElement === 'function' ? renderElement() : renderElement}
      </Popover.Trigger>
      <Popover.Content
        minHeight='80px'
        width='360px'
        className='bg-gradient-to-r from-[#413450] to-[#563143]  !flex !flex-col'
      >
        <Formik
          enableReinitialize={true}
          validationSchema={CommentSchema}
          initialValues={initialValue}
          onSubmit={handleSubmit}
        >
          {(props) => {
            return (
              <>
                <h1 className="mb-[1rem] text-primary-100 text-[1rem] font-semibold font-['Montserrat'] leading-[30px]">
                  Reply
                </h1>
                <div className='w-[100%] mb-[1rem]'>
                  <textarea
                    name='message'
                    onChange={props.handleChange}
                    className="w-[100%] self-stretch text-[#d3d3d3] text-base font-normal font-['Montserrat'] leading-[18.40px] grow shrink basis-0 pl-3 pt-3 pb-20 hover:border-primary-100 !outline-none !appearance-none [background:none!important] rounded-[5px] border border-[#afafaf] text-[#d3d3d3] placeholder:text-base placeholder:font-normal placeholder:font-['Montserrat'] "
                    placeholder='Add your comment..'
                  />
                  {props.touched && props.errors?.message && (
                    <span
                      className={
                        "text-[#b87693] block font-['Montserrat'] text-[12px] font-normal font-[500] "
                      }
                    >
                      * {props.errors?.message}
                    </span>
                  )}
                </div>
                <div className='!flex !flex-col items-end'>
                  <button
                    onClick={props.handleSubmit}
                    className="w-[80px] gap-[0.3rem] cursor-pointer h-[30px] bg-red-700 rounded-sm  flex items-center justify-center shadow text-center text-white text-base font-medium font-['Montserrat'] leading-[18.40px]"
                  >
                    {loading && <Spinner />}
                    Send
                  </button>
                </div>
              </>
            );
          }}
        </Formik>
      </Popover.Content>
    </Popover.Root>
  );
};

export default CommentFeed;
