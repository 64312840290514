import styles from './analyzeCard.module.scss';

const AnalyzeCard = ({ type = '', text = '', icon }) => {
  return (
    <main className={styles.container}>
      <div className={styles.lefCol}>
        <h1 className={styles.title}>{type}</h1>
        <p className={styles.text}>{text}</p>
      </div>
      <div className={styles.rightCol}>{icon && icon}</div>
    </main>
  );
};

export default AnalyzeCard;
