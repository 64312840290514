import { useReducer, useState } from 'react';
import Popup from 'reactjs-popup';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { ReactComponent as CloseButton } from '../../../images/svg/Close Btn.svg';
import Button from '../../../custom/btn/Btn';
import { ReactComponent as CreateAlbumIcon } from '../../../images/svg/albumIcon.svg';
import Photo from '../../../custom/photo/Photo';
import { ReactComponent as DeleteIcon } from '../../../images/svg/delete.svg';
import { ReactComponent as AlbumIcon } from '../../../images/svg/album-icon.svg';
import { ReactComponent as EditIcon } from '../../../images/svg/edit-icon.svg';
import {
  updateFanClubPhoto,
  removeFromPhotoSets,
  addToPhotoSets,
} from '../../../request/model';
import Spinner from '../../../custom/spinner/Spinner';
import notification from '../../../utils/notification';
import errorFormmatter from '../../../utils/errorFormatter';
import { useSelector } from 'react-redux';
import DropDown from '../../../custom/DropDown/DropDown';
import { ReactComponent as DropIcon } from '../../../svgs/drop-icon.svg';

const ModelEditPopup = ({ onClose, open, photo, photoSetOptions }) => {
  const {
    imageURL,
    photoSetId = null,
    photoId,
    isHidden,
    photoName,
    description,
  } = photo;

  const user = useSelector((state) => state?.user);
  const [selectedPhotoSet, setSelectedPhotoSet] = useState(null);

  const handleSelectChange = (data) => {
    setSelectedPhotoSet(data.value);
  };

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    photoName: photoName,
    description: description,
  });

  const [permission, setPermission] = useState({
    isHidden: isHidden,
    isDeleted: photo?.isDeleted,
  });

  const handleChange = (e) => {
    const { value, name } = e.target;

    setData({
      ...data,
      [name]: value ?? data[name],
    });
  };

  const deletePhotoSetPhoto = async (id) => {
    try {
      setLoading(true);
      const response = await removeFromPhotoSets({
        modelId: user?.clientId,
        photoId: id,
      });
      setLoading(false);
      notification({
        message:
          response?.message ?? 'Fan club photo set photo deleted successfully',
      });
    } catch (error) {
      notification({
        message:
          errorFormmatter(error) ?? 'Fan club photo set photo failed to delete',
        type: 'danger',
      });
      setLoading(false);
    }
  };

  const addToPhotoSet = async (photoId) => {
    try {
      const payload = new FormData();

      if (selectedPhotoSet === null) {
        notification({
          message: 'No photo set selected',
          type: 'danger',
        });
        return;
      }

      payload.append('registeredId', user?.clientId);
      payload.append('photoSetId', selectedPhotoSet.photoSetId);
      payload.append('PhotoId', photoId);

      setLoading(true);
      const response = await addToPhotoSets({ payload });
      setLoading(false);
      notification({
        message: response?.message ?? 'Fan club photo added successfully',
      });
    } catch (error) {
      notification({
        message: errorFormmatter(error) ?? 'Fan club photo failed to add',
        type: 'danger',
      });
      setLoading(false);
    }
  };

  const editPhoto = async (type, value = '') => {
    try {
      const payload = new FormData();

      if (type === 'photoName') {
        payload.append('photoName', data.photoName);
      }

      if (type === 'description') {
        payload.append('description', data.description);
      }

      if (type === 'isHidden') {
        payload.append('isHidden', value);
      }

      if (type === 'isDeleted') {
        payload.append('isDeleted', value);
      }

      payload.append('registeredId', user?.clientId);
      payload.append('photoId', photoId);

      setLoading(true);
      const response = await updateFanClubPhoto({ payload });
      setLoading(false);
      notification({
        message: response?.message ?? 'Fan club photo added successfully',
      });
    } catch (error) {
      notification({
        message: errorFormmatter(error) ?? 'Fan club photo failed to add',
        type: 'danger',
      });
      setLoading(false);
    }
  };

  const handlePermission = (name) => {
    let value = permission[name] === false ? true : false;

    setPermission({
      ...permission,
      [name]: value,
    });

    if (name === 'isDeleted') {
      editPhoto('isDeleted', true);
    } else {
      editPhoto(name, value);
    }
  };

  return (
    <Popup
      onClose={onClose}
      open={open}
      lockScroll={false}
      overlayStyle={{ background: 'rgba(68, 39, 52, 0.89)' }}
    >
      <CloseButton className={styles.closeBtn} onClick={onClose} />
      <section className={styles.imageContainer}>
        <div className={styles.topActionContainer}>
          {loading && <Spinner />}
          <DropDown
            onChange={handleSelectChange}
            placeholder='select state'
            options={photoSetOptions.map((photoSet) => {
              return {
                label:
                  photoSet?.photoSetName?.length > 14
                    ? `${photoSet?.photoSetName.slice(0, 14)}...`
                    : photoSet?.photoSetName,
                value: photoSet,
              };
            })}
            className={styles.selectContainer}
            dropElement={({ props, state }) => (
              <div className={styles.dropDownRender}>
                {selectedPhotoSet !== null
                  ? selectedPhotoSet?.photoSetName?.length > 14
                    ? `${selectedPhotoSet?.photoSetName.slice(0, 14)}...`
                    : selectedPhotoSet?.photoSetName
                  : 'Select'}
                <DropIcon />
              </div>
            )}
          />
          <Button
            text='Add to photo set'
            icon={CreateAlbumIcon}
            className={styles.addAlbum}
            onClick={() => addToPhotoSet(photoId)}
          />
        </div>
        <Photo
          className={styles.photo}
          src={imageURL}
          photoName={photo?.photoName}
        />

        <nav className={styles.nav}>
          <section className={styles.nameWrapper}>
            <label className={styles.inputNameLabel}>
              <AlbumIcon className={styles.editIcon} />
              <input
                type='text'
                name='photoName'
                defaultValue={photoName}
                onBlur={() => editPhoto('photoName')}
                onChange={handleChange}
                className={styles.nameInput}
              />
              <EditIcon className={styles.editIcon} />
            </label>
          </section>

          <section className={styles.permissionBtn}>
            <Button
              onClick={() => handlePermission('isHidden')}
              text='Hidden'
              className={classnames(
                styles.btn,
                permission.isHidden && styles.active
              )}
            />
            <Button
              text={'Delete'}
              icon={DeleteIcon}
              className={classnames(styles.deleteBtn)}
              onClick={
                photoSetId === null
                  ? () => handlePermission('isDeleted')
                  : () => deletePhotoSetPhoto(photoId)
              }
            />
          </section>
        </nav>
        <fieldset className={styles.fieldset}>
          <label className={styles.label}>
            <span className={styles.description}>Description</span>
          </label>
          <textarea
            name='description'
            className={styles.textarea}
            onChange={handleChange}
            defaultValue={description}
            onBlur={() => editPhoto('description')}
          ></textarea>
        </fieldset>
      </section>
    </Popup>
  );
};

export default ModelEditPopup;

