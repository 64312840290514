import styles from './fanclub.module.scss';
import ModelProfileHeader from '../../custom/modelProfileHeader/ModelProfileHeader';
import FluidContainer from '../../custom/fluidContainer/FluidContainer';
import EventCalender from '../../custom/eventCalender/EventCalender';
import DateTimePicker from '../../custom/datePicker/DatePicker';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import moment from 'moment';
import { BiPlus } from 'react-icons/bi';
import Switch from '../../custom/switch/Switch';
import CreateEvent from './createEvent/CreatEvent';
import { useEffect, useState } from 'react';
import DropDown from '../../custom/DropDown/DropDown';
import Event from './event/Event';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { getEvents, deleteEvent } from '../../request/model';
import { useSelector } from 'react-redux';
import notification from '../../utils/notification';
import { ReactComponent as EventIcon } from '../../svgs/event-icon.svg';

const renderDayContent = (day, date) => {
  return <span className={styles.day}>{day}</span>;
};

const options = [
  {
    label: 1,
    value: 'Today',
  },
  {
    label: 2,
    value: 'Weeks',
  },
];

const FanClubEvent = () => {
  const [selectedFilter, setSelectedFilter] = useState('Today');
  const [selectedFilteredEvent, setSelectedFilteredEvent] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const user = useSelector((state) => state?.user);
  const [openModal, setOpenModal] = useState(false);
  const [events, setEvents] = useState([]);
  const [selectDate, setSelectedDate] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentEditingEvent, setCurrentEditingEvent] = useState({});

  const end = moment();
  const start = moment();
  end.day(end.day() + 7);
  start.day(start.day() - 1);

  const handleOpenModal = (status) => {
    setOpenModal(status);
    if (status === false) {
      setEditMode(false);
    }
  };

  const getEventList = async () => {
    try {
      const response = await getEvents({ user_id: user?.clientId });

      const filterEvent = response?.data.filter((data) => {
        return moment(data.date).isSame(moment(), 'days');
      });
      setSelectedFilteredEvent(filterEvent);
      setEvents(response?.data);
    } catch (error) {
      notification({
        message: 'Error occurred when fetching events',
        type: 'danger',
      });
    }
  };
  const handleDeleteEvent = async (id) => {
    try {
      const payload = { id: id, modelId: user?.clientId };
      setLoading(true);
      const response = await deleteEvent({ payload });
      notification({ message: response?.message ?? '' });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notification({
        message: 'Error occurred deleting event',
        type: 'danger',
      });
    }
  };

  const handleSelectedFilter = (selectedFilter) => {
    if (selectedFilter === 'Today') {
      const filterEvent = events.filter((data) => {
        return moment(data.date).isSame(moment(), 'day');
      });
      setSelectedFilteredEvent(filterEvent);
    }

    if (selectedFilter === 'Weeks') {
      const filterEvent = events.filter((data) => {
        return moment(data.date).isBetween(start, end, 'day');
      });

      setSelectedFilteredEvent(filterEvent);
    }
  };

  const handleSingleDateFilter = () => {
    const filterEvent = events.filter((data) => {
      return moment(selectDate).isSame(data.date, 'day');
    });

    setSelectedFilteredEvent(filterEvent);
  };

  useEffect(() => {
    if (!openModal) {
      getEventList();
    }

    if (!editMode) {
      setCurrentEditingEvent({});
    }
  }, [openModal]);

  useEffect(() => {
    getEventList();
  }, []);

  useEffect(() => {
    getEventList();
  }, [loading]);

  useEffect(() => {
    handleSingleDateFilter();
  }, [selectDate]);

  return (
    <FluidContainer>
      <ModelProfileHeader />
      <main className={styles.container}>
        <h1 className={styles.title}>Events</h1>
        <section className={styles.notificationOptionContainer}>
          <span className={styles.notificationOption}>
            Receive Push Notifications for events <Switch />{' '}
          </span>
          <span className={styles.notificationOption}>
            Email Notifications <Switch />
          </span>
          <span className={styles.notificationOption}>
            Get birthday notifications for people you follow <Switch />
          </span>
        </section>
        <section className={styles.eventWrapperContainer}>
          <div className={styles.eventContainer}>
            {/* <EventCalender toolBar={ToolBar} /> */}
            <section className={styles.eventHeader}>
              <section className={styles.eventWrapperIconContainer}>
                <div className={styles.eventIconContainer}>
                  <FaChevronLeft className={styles.eventIcon} />
                  <FaChevronRight className={styles.eventIcon} />
                </div>
                <span className={styles.selectedOptionSearch}>
                  {selectedFilter}
                </span>
              </section>
              <DropDown
                onChange={(data) => {
                  setSelectedFilter(data.value);
                  handleSelectedFilter(data.value);
                }}
                options={options}
                className={styles.selectContainer}
                dropElement={({ props, state }) => {
                  return (
                    <span className={styles.selectOption}>
                      {selectedFilter}{' '}
                      <RiArrowDropDownLine className={styles.dropIcon} />
                    </span>
                  );
                }}
              />
            </section>
            <h1 className={styles.eventTitle}>Upcoming Events</h1>

            {selectedFilteredEvent.length === 0 ? (
              <EmptyState />
            ) : (
              <>
                <h2 className={styles.eventDate}>
                  {selectDate
                    ? moment(selectDate).format('dddd, Do MMMM')
                    : selectedFilter === 'Weeks'
                    ? `${moment(start).format('dddd, Do MMMM')} -
                  ${moment(end).format('dddd, Do MMMM')}`
                    : moment().format('dddd, Do MMMM')}
                </h2>
                <section className={styles.eventsContainer}>
                  <div className={styles.eventInnerContainer}>
                    <div className={styles.eventInnerScrollContainer}>
                      {selectedFilteredEvent?.map((data, index) => {
                        return (
                          <Event
                            id={data?.id}
                            loading={loading}
                            handleDeleteEvent={() => handleDeleteEvent(data.id)}
                            key={index}
                            data={data}
                            handleEditMode={() => {
                              setEditMode(true);
                              handleOpenModal(true);
                              setCurrentEditingEvent(data);
                            }}
                          />
                        );
                      })}
                    </div>
                  </div>
                </section>
              </>
            )}
          </div>

          <div className={styles.datePickerContainer}>
            <button
              className={styles.newEventButton}
              onClick={() => handleOpenModal(true)}
            >
              <BiPlus className={styles.newEventIcon} /> New Event
            </button>
            <DateTimePicker
              onChange={(date) => {
                setSelectedDate(moment(date));
              }}
              renderDayContents={renderDayContent}
              inline
              className={'eventDate'}
              renderCustomHeader={({
                date,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <div className={styles.dateTimeHeader}>
                  <span className={styles.dateTimeIconContainer}>
                    <FaChevronLeft
                      className={styles.dateIcon}
                      onClick={decreaseMonth}
                      disabled={prevMonthButtonDisabled}
                    />
                  </span>

                  <span className={styles.dateTimeMonthContainer}>
                    {moment(date).format('MMMM')}
                  </span>
                  <span className={styles.dateTimeIconContainer}>
                    <FaChevronRight
                      className={styles.dateIcon}
                      onClick={increaseMonth}
                      disabled={nextMonthButtonDisabled}
                    />
                  </span>
                </div>
              )}
            />
          </div>
        </section>
      </main>
      <CreateEvent
        handleDeleteEvent={handleDeleteEvent}
        currentEditingEvent={currentEditingEvent}
        open={openModal}
        action={editMode}
        handleOpenModal={handleOpenModal}
      />
    </FluidContainer>
  );
};

const EmptyState = () => {
  return (
    <div className={styles.nonAvialablecontainer}>
      <h1>You don’t have any events for today</h1>
      <EventIcon className={styles.icon} />
      <p>
        You can use the <span>New Event</span> button to create events and plan
        out your work day.
      </p>
    </div>
  );
};

export default FanClubEvent;
