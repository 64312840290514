import Button from '../../../custom/btn/Btn';
import styles from './appointmentRequest.module.scss';
import { TbMessages } from 'react-icons/tb';
import { IoIosCheckmark } from 'react-icons/io';
import { HiMiniXMark, HiOutlineVideoCamera } from 'react-icons/hi2';
import { LuAlarmClock } from 'react-icons/lu';
import classNames from 'classnames';
import { useState } from 'react';

const AppointmentRequest = () => {
  const [approve, setApprove] = useState(false);

  const handleApproveRequest = () => {
    setApprove(true);
  };

  const handleRejectRequest = () => {
    setApprove(false);
  };
  return (
    <main className={styles.requestCard}>
      <section className={styles.requestHeader}>
        <h1 className={styles.requestDetailContainer}>
          <span className={styles.headerTitle}>Request from</span>
          <span className={styles.from}>Luke12es</span>
        </h1>
        <span className={styles.separator}></span>
        <h1 className={styles.headerTitle}>Thursday, 18 February, 2023</h1>
        <span className={styles.separator}></span>
        <h1 className={styles.requestTimeContainer}>
          <LuAlarmClock className={styles.icon} />
          <span className={styles.headerTitle}>10:00 AM</span>
          <span className={styles.headerTitle}>11 : 00 AM</span>
        </h1>
      </section>
      <section className={styles.actionContainer}>
        <div className={styles.actionButtonContainer}>
          <button
            onClick={handleApproveRequest}
            className={classNames(
              styles.approveButton,
              approve && styles.activeApproval
            )}
          >
            Approve <IoIosCheckmark className={styles.icon} />
          </button>
          <button className={styles.rejectButton} onClick={handleRejectRequest}>
            Reject <HiMiniXMark className={styles.icon} />{' '}
          </button>
        </div>
        <span className={styles.credits}>50 Credits deposited</span>
      </section>
      <div className={styles.actionButtonContainer}>
        <button className={styles.replyButton}>
          Reply in Private Message <TbMessages className={styles.icon} />
        </button>
        {approve && (
          <button className={styles.startShowButton}>
            Start Private Show <HiOutlineVideoCamera className={styles.icon} />
          </button>
        )}
      </div>
    </main>
  );
};

export default AppointmentRequest;
