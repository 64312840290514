import classNames from 'classnames';
import styles from './fluidContainer.module.scss';

const FluidContainer = ({ children, className }) => {
  return (
    <main className={classNames(styles.container, className)}>{children}</main>
  );
};

export default FluidContainer;
