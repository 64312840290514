import { useState } from 'react';
import Mainbanner from './mainbanner/Mainbanner';
import Mainslider from './mainslider/Mainslider';
import Sexclub from './sexclub/Sexclub';
import Categoryitem from './categoryitem/Categoryitem';
import Subscribe from './subscribe/Subscribe';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Spinner from '../../custom/spinner/Spinner';
import { getModels, getNewModels } from '../../request/request';
import { useQueries } from 'react-query';
import mainBanner from '../.././images/trashimg/mainbanner.jpg';

const Main = () => {
	const [models, setModels] = useState([]);
	const [newModels, setNewModels] = useState([]);
	const [numberofOnlineModels, setNumberOfOnlineModels] = useState(0);
	const [pageCount, setPageCount] = useState(0);
	const [pageNumber, setPageNumber] = useState(1);
	const [totalCount, setTotalCount] = useState(0);
	const [pageSize, setPageSize] = useState(20);
	const [recommendation, setRecommendation] = useState('top-models');
	const [feature, setFeatures] = useState('All');
	const [category, setCategory] = useState([]);
	const [categoryFilter, setCategoryFilter] = useState([]);

	const modelQueryParams = `${pageNumber}${recommendation}${feature}${categoryFilter}`;
	const [newModelsResponse, modelsResponse] = useQueries([
		{
			queryKey: ['main-new-models'],
			queryFn: getNewModels,
			onSuccess: data => {
				setNewModels(data?.data?.models);
			},
		},
		{
			queryKey: ['main-model', modelQueryParams],
			queryFn: () => getModels({ feature, pageNumber, category, recommendation, pageSize }),
			onSuccess: data => {
				setModels(data.models ?? []);
				setPageNumber(data?.pageNumber ?? 0);
				setPageSize(data?.pageSize ?? 0);
				setTotalCount(data?.totalCount ?? 0);
				setPageCount(data?.pageCount ?? 0);
				setNumberOfOnlineModels(data?.online ?? 0);
			},
		},
	]);

	const changeRecommendation = recommendation => {
		setRecommendation(recommendation);
	};

	const applyCategoryFilter = e => {
		e.preventDefault();
		setCategoryFilter(category);
	};

	const handleCategoryChange = category => {
		setCategory(prev => {
			// remove cateory if is in the list otherwise add it
			if (prev?.find(prevCategory => prevCategory === category)) {
				return prev?.filter(prevCategory => prevCategory !== category);
			} else {
				return [...prev, category];
			}
		});
	};

	const newModelsErrorMessage = newModelsResponse.error?.response?.data?.message ?? newModelsResponse?.error?.messsage;
	const modelsErrorMessage = modelsResponse?.error?.response?.data?.message ?? modelsResponse?.error?.messsage;

	const handlePagination = pagenumber => {
		setPageNumber(pagenumber);
	};

	console.log(newModels, 'this is the new models');

	return (
		<main className='main'>
			<Helmet>
				<title>NSA1</title>
				<meta name='NSA1' content='NSA1' />
			</Helmet>

			<Mainbanner />
			{newModelsResponse.isFetching ? (
				<Spinner width={50} height={50} />
			) : newModelsResponse?.isError ? (
				<p className='error'>{newModelsErrorMessage}</p>
			) : (
				<Mainslider models={newModels} updateNewModels={setNewModels} />
			)}

			<section className='main-reklama'>
				<div className='container'>
					<img src={mainBanner} alt='' />
				</div>
			</section>

			<section className='main-category'>
				<div className='container'>
					<div className='main-category-filter'>
						<div className='main-category-filter--quantity'>
							{numberofOnlineModels} Models Online
							<div className='main-category-filter--quantity__refresh'>
								<svg
									id='new-quote'
									xmlns='http://www.w3.org/2000/svg'
									width='14'
									height='14'
									viewBox='0 0 14 14'
									fill='none'
								>
									<path
										d='M11.9508 1.72462C11.1645 0.9177 10.2398 0.343502 9.25371 0V2.16975C9.72845 2.41265 10.1785 2.72871 10.5731 3.13522C12.5443 5.16168 12.5443 8.45645 10.5731 10.4819C8.60194 12.5043 5.39744 12.5043 3.42332 10.4819C1.45414 8.45442 1.45414 5.16168 3.42332 3.13522C3.42826 3.12912 3.44409 3.11896 3.45101 3.10981H3.45299L4.62105 4.308L4.61412 0.503058L0.913132 0.497977L2.07624 1.69312C2.06932 1.70532 2.05844 1.71751 2.04954 1.72462C-0.68318 4.53159 -0.68318 9.08553 2.04954 11.8925C4.78622 14.7025 9.21514 14.7025 11.9508 11.8925C14.6816 9.08553 14.6845 4.53159 11.9508 1.72462Z'
										fill='#B87693'
									/>
								</svg>
							</div>
						</div>
						<div className='main-category-sort'>
							<div className='main-category-sort-wrapper'>
								<div className='main-category-sort-recommended main-category-sort-item'>recommended</div>
								<ul className='main-category-sort-item--menu'>
									<li>
										<Link
											to='/'
											onClick={() => changeRecommendation('top-models')}
											className={recommendation === 'top-models' && 'main-category-sort-item--menu__active'}
										>
											Most Popular
										</Link>
									</li>
									<li>
										<Link
											to='/'
											onClick={() => changeRecommendation('new-models')}
											className={recommendation === 'new-models' && 'main-category-sort-item--menu__active'}
										>
											Newbies
										</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className='row'>
						{modelsResponse?.isError ? (
							<p className='error'>{modelsErrorMessage}</p>
						) : (
							<Categoryitem
								isLoading={modelsResponse?.isFetching}
								models={models}
								updateModel={setModels}
								selectFeature={setFeatures}
								selectCategory={handleCategoryChange}
								applyCategoryFilter={applyCategoryFilter}
								feature={feature}
								handlePagination={handlePagination}
								pageCount={pageCount}
								pageSize={pageSize}
							/>
						)}
					</div>
				</div>
			</section>
			<Subscribe />
			<Sexclub />
		</main>
	);
};

export default Main;
