import styles from './index.module.scss';
import { ImFileZip } from 'react-icons/im';
import { PiDotsThreeOutlineVerticalLight } from 'react-icons/pi';
import { useState } from 'react';

const StoreCard = () => {
  const [openOption, setOpenOption] = useState(false);
  return (
    <main className={styles.card}>
      <span className={styles.optionButtonContainer}>
        <PiDotsThreeOutlineVerticalLight
          className={styles.optionButton}
          onClick={() => setOpenOption(!openOption)}
        />
      </span>

      <ImFileZip className={styles.fileIcon} />
      <h1 className={styles.fileName}>galileo.zip</h1>
      <h2 className={styles.fileTitle}>A collection of Old Pictures</h2>
      <div className={styles.filePermissionContainer}>
        <span className={styles.permission}>Showing in Fan Club</span>
        <span className={styles.credit}>2 credits</span>
      </div>
      <div className={styles.viewsContainer}>
        <span className={styles.totalSales}>576 sales</span>
        <span className={styles.totalView}>2321 views</span>
      </div>
      <ul className={styles.fileOptionContainer} data-openOption={openOption}>
        <li className={styles.option}>Rename file</li>
        <li className={styles.option}>Delete</li>
        <li className={styles.option}>Share</li>
        <li className={styles.option}>Edit Product Details</li>
      </ul>
    </main>
  );
};

export default StoreCard;
