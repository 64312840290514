import FluidContainer from '../../../custom/fluidContainer/FluidContainer';
import url from '../../../images/club-hd-img.png';
import notification from '../../../utils/notification';

import { getClubByType, searchClub } from '../../../request/club';
import useGoogleLocation from '../../../hooks/useGoogleLocation';

import { ReactComponent as LocationSearchIcon } from '../../../images/svg/location-search.svg';

import ClubDisplayView from './clubDisplayView';
import { useEffect, useState } from 'react';

const Club = () => {
  const [activeTab, setActiveTab] = useState('featured');
  const [clubs, setClubs] = useState([]);
  const { setUserLatlng, userLocation } = useGoogleLocation();

  const handleGetUserLatLng = (position) => {
    const lat = position?.coords?.latitude;
    const lng = position?.coords?.longitude;
    setUserLatlng(lat, lng);
  };

  const handleEnableLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(handleGetUserLatLng);
    } else {
      notification({
        title: 'Clubs',
        message: 'Geolocation is not supported by this browser.',
        type: 'warning',
      });
    }
  };

  const handleGetClubBySearch = async () => {
    try {
          let search = userLocation?.address ?? ''; //?? '115 Pendleton way Salford, Manchester';
          const res = await searchClub({ searchText: search });
          setClubs(
            res?.clubphotos && res?.clubphotos.length > 0 ? res?.clubphotos : []
          );
        } catch (error) {
      setClubs([]);
    }
  };

  const handleGetClub = async () => {
    try {
          let search = '';
          let response = [];
          if (activeTab === 'featured') {
            search = 'NormalClub';
          } else if (activeTab === 'exclusive') {
            search = 'ExecutiveClub';
          } else if (activeTab === 'lgbtq') {
            search = 'LGBTClub';
          } else {
            search = 'NormalClub';
          }

          const res = await getClubByType({
            clubType: search,
          });

          setClubs(res && res.clubs.length > 0 ? res?.clubs : []);
        } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetClub();
  }, []);

  useEffect(() => {
    if (activeTab === 'nearme') {
      handleEnableLocation();
    } else {
      handleGetClub();
    }
  }, [activeTab]);

  useEffect(() => {
    if (userLocation.address) {
      handleGetClubBySearch();
    }
  }, [userLocation]);

  return (
    <main>
      <img src={url} className='max-w-[100%] object-cover w-[100%] h-[350px]' />
      <FluidContainer>
        <nav className='w-[100%] bg-black justify-center items-center inline-flex mb-[72px] gap-[40px]'>
          <li
            onClick={() => {
              setActiveTab('featured');
            }}
            className={` ${
              activeTab === 'featured' ? 'text-white' : 'text-neutral-700'
            } text-[24px] cursor-pointer font-bold font-['Montserrat']`}
          >
            FEATURED
          </li>
          <li
            onClick={() => {
              setActiveTab('nearme');
            }}
            className={` ${
              activeTab === 'nearme' ? 'text-white' : 'text-neutral-700'
            } text-[24px] cursor-pointer  font-bold font-['Montserrat']`}
          >
            NEAR ME
          </li>
          <li
            onClick={() => {
              setActiveTab('exclusive');
            }}
            className={` ${
              activeTab === 'exclusive' ? 'text-white' : 'text-neutral-700'
            } text-[24px] cursor-pointer  font-bold font-['Montserrat']`}
          >
            EXCLUSIVE VENUES
          </li>
          <li
            onClick={() => {
              setActiveTab('lgbtq');
            }}
            className={` ${
              activeTab === 'lgbtq' ? 'text-white' : 'text-neutral-700'
            } text-[24px] cursor-pointer  font-bold font-['Montserrat']`}
          >
            LGBTQ+ FRIENDLY
          </li>
        </nav>

        {activeTab === 'nearme' && clubs.length === 0 && (
          <section className='flex items-center flex-col mt-[113px]'>
            <LocationSearchIcon className='mb-[40px]' />
            <section className='flex items-center flex-col mb-[200px]'>
              <button
                onClick={handleEnableLocation}
                className="mb-[24px] text-white text-[36px] rounded-[5px] font-bold font-['Montserrat'] bg-primary-100 p-[1rem]"
              >
                Enable Location
              </button>
              <h2 className=" mb-[120px] w-[828px] text-center text-white text-[24px] font-medium font-['Montserrat'] leading-7">
                We need to know your location in order to suggest nearby clubs
                that you would love to party at.
              </h2>

              <h5 className="text-white text-[24px] font-semibold font-['Montserrat']">
                To do this click “Allow” when you see this modal.
              </h5>
            </section>
          </section>
        )}
        {clubs.length > 0 && (
          <ClubDisplayView activeTab={activeTab} clubs={clubs} />
        )}
      </FluidContainer>
    </main>
  );
};

export default Club;
