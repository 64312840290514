import moment from 'moment';

export const filterAppointments = (appointments = [], dayOfWeek) => {
  const data = appointments.filter((appointment, index) => {
    const date = moment(appointment?.appointmentDate);
    const dayDate = moment();
    const week = weekOfTheMonth(date);
    const monthWeek = weekOfTheMonth(dayDate);

    const isDay = dayOfWeek === appointment?.weekday;
    const isMonth = date.format('M') === dayDate.format('M');
    return isDay === true && isMonth === true && week === monthWeek;
  });

  return data;
};

const weekOfTheMonth = (date) => {
  const startDay = date.startOf('week');
  const day = parseInt(startDay.format('D'), 10);

  if (day > 28) {
    return 5;
  }

  if (day > 21 && day <= 28) {
    return 4;
  }

  if (day > 14 && day <= 21) {
    return 3;
  }

  if (day > 7 && day <= 14) {
    return 2;
  }

  return 1;
};
