import { useState } from 'react';
import Similar from '../components/similar/Similar.js';
import ModelCard from '../components/card/ModelCard.js';
import Spinner from '../../custom/spinner/Spinner.js';
import { useQuery } from 'react-query';
import { getModels } from '../../request/request.js';
import Pagination from '../../custom/pagination/Pagination.js';

const NewModels = () => {
	const [pageNumber, setPageNumber] = useState(1);
	const [newModels, setNewModels] = useState([]);

	const { data, isErrror, error, isFetching } = useQuery(
		['top-models', pageNumber],
		() => getModels({ pageNumber, filterBy: 'new-models' }),
		{
			onSuccess: response => {
				setNewModels(response?.models);
			},
		}
	);

	const pageSize = data?.pageSize ?? 0;
	const pageCount = data?.pageCount ?? 0;

	const errorMessage = error?.response?.data?.message ?? error?.message;

	const handlePagination = pagenumber => {
		setPageNumber(pagenumber);
	};

	if (isFetching) return <Spinner width={50} height={50} />;
	if (isErrror) return <p className='error'>{errorMessage}</p>;

	return (
		<main>
			<section className='new-models'>
				<div className='container'>
					<div className='new-models-top'>
						<div className='page-title'>New Models</div>
					</div>
				</div>
			</section>
			<section>
				<div className='container'>
					<div className='new-models-cards'>
						{newModels?.length > 0 ? (
							newModels?.map(model => (
								<ModelCard model={model} key={model?.registerId} updateModel={setNewModels} />
							))
						) : (
							<p className='no-available-data'>no new model</p>
						)}
					</div>
					<Pagination pageCount={pageCount} pageSize={pageSize} onChange={handlePagination} />
				</div>
			</section>
			{/* <section className='container'>
				<Similar />
			</section> */}
		</main>
	);
};

export default NewModels;
