import { FileUploader } from 'react-drag-drop-files';
const fileTypes = ['JPG', 'PNG', 'GIF', 'JPEG'];

const FileUpload = ({ renderElement, handleFileChange }) => {
  return (
    <FileUploader
      name='file'
      types={fileTypes}
      handleChange={(file) => {
        if (handleFileChange) {
          handleFileChange(file);
        }
      }}
    >
      {typeof Component === 'function' ? renderElement() : renderElement}
    </FileUploader>
  );
};

export default FileUpload;
