import React, { Component, useEffect, useState } from 'react';
import Info from './info/Info';
import { getModelDetails } from '../../request/model';
import Content from './content/Content';
import Like from '../components/similar/Similar';
import { useLocation } from 'react-router-dom';
import styles from './detail.module.scss';
import MemberViewLiveStreaming from './MemberViewLiveStreaming/MemberViewLiveStreaming';
import classNames from 'classnames';

const Detail = () => {
  const params = useLocation();
  const [modelInfo, setModelInfo] = useState(null);
  const urlSearchParams = new URLSearchParams(params.search);
  const modelId = urlSearchParams.get('model');

  const handleGetModelDetails = async () => {
    try {
      const response = await getModelDetails({
        modelId: modelId ?? '',
      });
      setModelInfo(response);
    } catch (error) {}
  };

  useEffect(() => {
    handleGetModelDetails();
  }, []);

  useEffect(() => {
    handleGetModelDetails();
  }, [modelId]);

  return (
    <main className={classNames('container', styles.main)}>
      <MemberViewLiveStreaming />
      {modelInfo && modelId && (
        <>
          <Info modelInfo={modelInfo} />
          <Content modelInfo={modelInfo} />
        </>
      )}
      <Like modelInfo={modelInfo} />
    </main>
  );
};

export default Detail;
