import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	clientId: '',
	token: '',
	email: '',
	userType: '',
	profileUrl: '',
	fullName: '',
	isCompletedVerfication: false,
};

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		logOut: state => {
			state.clientId = '';
			state.email = '';
			state.token = '';
			state.userType = '';
			state.fullName = '';
			state.profileUrl = '';
			state.isCompletedVerfication = false;
		},

		updateUser: (state, action) => {
			const { clientId, email, userType, token, profilePicture, fullName } = action?.payload;

			state.clientId = clientId;
			state.email = email;
			state.userType = userType;
			state.token = token;
			state.fullName = fullName;
			state.profileUrl = profilePicture;
		},

		updateUerStatus: (state, action) => {
			state.isCompletedVerfication = action.payload;
		},
	},
});

export const { logOut, updateUser, updateUerStatus } = userSlice.actions;

export default userSlice.reducer;
