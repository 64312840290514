import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PatternFormat } from 'react-number-format';
import Date from '../components/date/Date';
import axios from 'axios';
import styles from './styles.module.scss';
import notification from '../../utils/notification';
import errorFormmatter from '../../utils/errorFormatter';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import validateInputs from '../../utils/validationSchema';

const RegistrationMember = () => {
	const [selectedOption, setSelectedOption] = useState('option1');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [dateOfBirth, setDateOfBirth] = useState('');
	const [userType, setUserType] = useState('member');
	const [accountType, setAccountType] = useState('Basic');
	const [clubName, setClubName] = useState('');
	const [clubAddress, setClubAddress] = useState('');
	const [clubPhoneNumber, setClubPhoneNumber] = useState('');
	const [loading, setLoading] = useState(false);
	const [isAgreeTC, setIsAgreeTC] = useState(false);
	const [isImageAgreement, setIsImageAgreement] = useState(false);
	const history = useHistory();

	const clubValidationSchema = yup.object({
		clubAddress: yup
			.string()
			.max(255, 'club address must be within 255')
			.required('club address is required'),
		clubName: yup
			.string()
			.max(255, 'club name must be within 255')
			.required('club name is required'),
		clubPhoneNumber: yup.string().required('club phone number is required'),
	});

	const handleSubmit = async event => {
		event.preventDefault();
		try {
			if (!isAgreeTC || !isImageAgreement) throw new Error('agree for T&C and image use agreement');

			const formData = new FormData();
			formData.append('Email', email);
			formData.append('Password', password);
			formData.append('UserType', userType);
			formData.append('AccountType', accountType);
			formData.append('DateOfBith', dateOfBirth);

			if (userType === 'club') {
				const clubInfo = { clubPhoneNumber, clubAddress, clubName };
				const validationError = await validateInputs(clubValidationSchema, clubInfo);
				if (!validationError) return;

				formData.append('ClubPhoneNumber', clubPhoneNumber);
				formData.append('ClubAddress', clubAddress);
				formData.append('ClubName', clubName);
			}

			setLoading(true);
			if (document.querySelectorAll('#free-reg .error')?.length === 0) {
				const response = await axios({
					method: 'post',
					url: `/Authentication/registration`,
					data: formData,
				});

				if (response?.status === 200 || response?.status === 201) {
					document.querySelector('.registration-free').classList.add('form-sucess');
					setTimeout(() => {
						history.push('/');
					}, 4000);
				}
			} else {
				notification({
					title: 'Registration Error',
					message: 'Incomplete registration details',
					type: 'danger',
				});
			}
		} catch (error) {
			notification({
				title: 'Registration Error',
				message: errorFormmatter(error),
				type: 'danger',
			});
		} finally {
			setLoading(false);
		}
	};

	const handleOptionChange = changeEvent => setSelectedOption(changeEvent.target.value);

	useEffect(() => {
		document.querySelector('.registration-free--btn').onclick = function() {
			document.querySelector('.registration').classList.add('registration__free');
		};
		document.querySelector('.want-free--btn').onclick = function() {
			document.querySelector('.registration').classList.remove('registration__premium');
			document.querySelector('.registration').classList.add('registration__free');
		};
		document.querySelector('.registration-transition').onclick = function() {
			document.querySelector('.registration').classList.remove('registration__premium');
			document.querySelector('.registration').classList.add('registration__free');
		};
		document.querySelectorAll('.member-singin').forEach(function(element) {
			element.onclick = function() {
				document.querySelector('.header--auth-logIn').click();
			};
		});

		document.querySelector('.registration-premium form .btn').onclick = function() {
			document.querySelector('.registration-premium').classList.add('subscribe');
		};

		//toggle the show icon svg / image
		document.querySelectorAll('.viewPassword').forEach(function(element) {
			element.onclick = function() {
				if (element.classList.toggle('active')) {
					element.parentNode.querySelector('input').setAttribute('type', 'text');
					element.classList.add('active');
				} else {
					element.parentNode.querySelector('input').setAttribute('type', 'password');
					element.classList.remove('active');
				}
			};
		});

		//validate the email field
		document.querySelectorAll('#email-free').forEach(function(element) {
			element.onkeyup = function() {
				const r = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

				if (!r.test(document.querySelector('#email-free').value)) {
					document.querySelector('#email-free').parentNode.classList.remove('true');
					document.querySelector('#email-free').parentNode.classList.add('error');
				} else {
					document.querySelector('#email-free').parentNode.classList.remove('error');
					document.querySelector('#email-free').parentNode.classList.add('true');
				}
			};
		});

		document.querySelectorAll('.passwordJS').forEach(function(element) {
			element.onfocus = function() {
				element.parentNode.querySelector('.password-tooltip').style.display = 'block';
			};
			element.onblur = function() {
				element.parentNode.querySelector('.password-tooltip').style.display = 'none';
				let listErors = element.parentNode.querySelectorAll('.password-tooltip--list li.false');
				if (listErors.length > 0) {
					element.parentNode.classList.add('error');
					element.parentNode.classList.remove('true');
				} else {
					element.parentNode.classList.remove('error');
					element.parentNode.classList.add('true');
				}
			};
		});

		document.querySelectorAll('.passwordJS').forEach(function(element) {
			element.onkeyup = function() {
				let thisVal = element.value;
				let thisLower = thisVal.replace(/[A-Z,0-9]/g, '');
				let thisUpper = thisVal.replace(/[a-z,0-9]/g, '');
				let thisNumber = thisVal.match(/\d{1,}/);
				let lowerError = element.parentNode.querySelector('.passwordJS_lower');
				let numberError = element.parentNode.querySelector('.passwordJS_number');
				let upperError = element.parentNode.querySelector('.passwordJS_upper');
				let minError = element.parentNode.querySelector('.passwordJS_min');

				if (thisVal.length >= 8) {
					minError.classList.remove('false');
					minError.classList.add('true');
				} else {
					minError.classList.remove('true');
					minError.classList.add('false');
				}

				if (thisLower.length >= 1) {
					lowerError.classList.remove('false');
					lowerError.classList.add('true');
				} else {
					lowerError.classList.remove('true');
					lowerError.classList.add('false');
				}

				if (thisUpper.length >= 1) {
					upperError.classList.remove('false');
					upperError.classList.add('true');
				} else {
					upperError.classList.remove('true');
					upperError.classList.add('false');
				}

				if (thisNumber) {
					numberError.classList.remove('false');
					numberError.classList.add('true');
				} else {
					numberError.classList.remove('true');
					numberError.classList.add('false');
				}
			};
		});
	}, []);

	return (
		<main className='container'>
			<section className='registration'>
				<div className='registration-free'>
					<div className='registration-free--text'>
						<div className='registration--title'>
							Join now and <br />
							enjoy for free!
						</div>
						<ul className='registration--list'>
							<li>
								<img src={require('../../images/registration_w.png')} alt='' />
								5,000 models online
							</li>
							<li>
								<img src={require('../../images/registration_free.png')} alt='' />
								Available free <br />
								photos & videos
							</li>
						</ul>
						<div className='registration-free--btn btn btn__purple'>Sign up for free</div>
					</div>
					{/* добавлять к registration__free - thanks */}
					<div className='registration-free-thanks free-thanks'>
						<div className='registration-free-thanks--svg'>
							<svg xmlns='http://www.w3.org/2000/svg' width='94' height='93' viewBox='0 0 94 93' fill='none'>
								<path
									opacity='0.5'
									d='M46.1602 92.3204C20.7112 92.3204 0 71.6092 0 46.1602C0 20.7112 20.7112 0 46.1602 0C54.5358 0 62.8564 2.3964 70.2264 6.91421C72.5363 8.33634 73.2592 11.3613 71.8371 13.6713C70.4149 15.9812 67.39 16.6962 65.0878 15.2898C59.2658 11.7149 52.7208 9.82132 46.1602 9.82132C26.1247 9.82132 9.82132 26.1247 9.82132 46.1602C9.82132 66.1957 26.1247 82.4991 46.1602 82.4991C66.1957 82.4991 82.4991 66.2035 82.4991 46.1602C82.4991 43.5438 82.1376 40.7781 81.3991 37.7139C80.7627 35.0739 82.3891 32.4261 85.0212 31.7896C87.669 31.1454 90.3168 32.7718 90.9454 35.4117C91.8725 39.2538 92.3204 42.7659 92.3204 46.1602C92.3204 71.6092 71.6092 92.3204 46.1602 92.3204ZM47.633 59.4822L91.7596 15.3556C93.6689 13.4385 93.6689 10.3271 91.7596 8.40996C89.8425 6.49284 86.7233 6.49284 84.8061 8.40996L44.1602 49.0638L32.8225 37.7182C30.9053 35.801 27.794 35.801 25.8768 37.7182C23.9597 39.6353 23.9597 42.7467 25.8768 44.6638L40.6952 59.4822C41.6538 60.4408 42.9031 60.92 44.1602 60.92C45.4173 60.92 46.6745 60.4408 47.633 59.4822Z'
									fill='#EC9ABE'
								/>
							</svg>
						</div>

						{/* Success page after signup */}
						<div className='registration-free-thanks--title'>Thank you for registering</div>
						<div className='registration-free-thanks--description'>
							An email with a link was sent to the email <br />
							address you specified, after which you can <br />
							confirm the email address and complete the <br />
							registration process
						</div>
						{/* <div className='btn btn__red registration-free-thanks--link'>Resend confirmation email</div> */}
						<Link to='/' className='registration-free-thanks--home'>
							Return to main page
						</Link>
					</div>

					{/* free registration section */}
					<div className='registration-form'>
						<div className='registration-form--title'>Sign up for free</div>
						<div className='registration-form--subtitle'>and enhance your experience</div>
						<form id='free-reg' name='free-reg' onSubmit={handleSubmit}>
							<div className='input-wrapper--title'>Email address</div>
							<div className='input-wrapper'>
								<input
									autoComplete='new-email'
									name='email'
									type='text'
									id='email-free'
									placeholder='Email address'
									onChange={e => setEmail(e.target.value)}
								/>
							</div>
							<div className='input-wrapper'>
								<label className={styles.selectLabel}>User Type</label>
								<select className={styles.select} onChange={e => setUserType(e.target.value)}>
									<option selected value='member'>
										Member
									</option>
									<option value='model'>Model</option>
									<option value='club'>Club</option>
								</select>
							</div>
							<div className='input-wrapper'>
								<label className={styles.selectLabel}>Account Type</label>
								<select className={styles.select} onChange={e => setAccountType(e.target.value)}>
									<option selected value='Basic'>
										Basic
									</option>
									<option value='Standard'>Standard</option>
									<option value='Premium'>Premium</option>
								</select>
							</div>
							{userType === 'club' && (
								<>
									<div className={styles.controlGroup}>
										<label className={styles.label}>Club Name</label>
										<input
											type='text'
											name='clubName'
											placeholder='Enter your club name'
											value={clubName}
											onChange={e => setClubName(e.target.value)}
											className={styles.input}
										/>
									</div>
									<div className={styles.controlGroup}>
										<label className={styles.label}>Club Address</label>
										<input
											type='text'
											name='clubAddress'
											placeholder='Enter your club’s street address'
											className={styles.input}
											value={clubAddress}
											onChange={e => setClubAddress(e.target.value)}
										/>
									</div>
									<div className={styles.controlGroup}>
										<label className={styles.label}>Club Phone Number</label>
										<PatternFormat
											name='Club Phone Number'
											placeholder='Enter your club’s phone number'
											format='+1 ### #### ###'
											className={styles.input}
											value={clubPhoneNumber}
											onChange={e => setClubPhoneNumber(e.target.value)}
										/>
									</div>
								</>
							)}
							{/* <div className='input-wrapper--ps'>We send you an instruction here</div> */}

							<div className='input-wrapper__double'>
								<div>
									<div className='reg-model-step1--form--title'>Password</div>
									<div className='input-wrapper'>
										<input
											name='password'
											autoComplete='new-password'
											className='required passwordJS'
											type='password'
											placeholder='Password'
											onChange={e => setPassword(e.target.value)}
										/>
										<div className='password-tooltip'>
											<div className='password-tooltip--title'>Must comply with the following rules:</div>
											<ul className='password-tooltip--list'>
												<li className='passwordJS_lower'>
													Аt least one lower <span />
												</li>
												<li className='passwordJS_number'>
													Аt least one number <span />
												</li>
												<li className='passwordJS_upper'>
													Аt least one upper <span />
												</li>
												<li className='passwordJS_min'>
													Оver 8 characters <span />
												</li>
											</ul>
										</div>
										<svg
											className='viewPassword'
											xmlns='http://www.w3.org/2000/svg'
											width='25'
											height='14'
											viewBox='0 0 25 14'
											fill='none'
										>
											<g opacity='0.8'>
												<path
													d='M14.9149 6.73145C14.9149 8.41239 13.5928 9.73145 12.0152 9.73145C10.4375 9.73145 9.11548 8.41239 9.11548 6.73145C9.11548 5.0505 10.4375 3.73145 12.0152 3.73145C13.5928 3.73145 14.9149 5.0505 14.9149 6.73145Z'
													stroke='#B0B0B0'
													strokeWidth='2'
												/>
												<path
													d='M12.0109 12.4629C6.04378 12.4629 2.61486 9.6441 1.11775 6.72709C2.74488 3.61227 6.02678 1 12.0131 1C17.9859 1 21.4187 3.80984 22.9139 6.72537C21.2885 9.8449 18.0039 12.4629 12.0109 12.4629Z'
													stroke='#B0B0B0'
													strokeWidth='2'
												/>
											</g>
										</svg>
									</div>
								</div>
								<div>
									<div className='reg-model-step1--form--title'>Date of birth</div>
									<div className='input-wrapper'>
										<Date handleDateChange={setDateOfBirth} />
									</div>
								</div>
							</div>

							<section className={styles.checkGroup}>
								<input
									type='checkbox'
									className={styles.checkbox}
									value={isAgreeTC}
									onChange={e => setIsAgreeTC(e.target.checked)}
								/>
								<label className={styles.checkboxLabel}>I agree to the T&C</label>
							</section>
							<section className={styles.checkGroup}>
								<input
									type='checkbox'
									className={styles.checkbox}
									onChange={e => setIsImageAgreement(e.target.checked)}
									value={isImageAgreement}
								/>
								<label className={styles.checkboxLabel}>
									I acknowledge and agree that my images will be used and displayed publicly on the website
								</label>
							</section>
							<div className='btn-wrapper'>
								<button onClick={handleSubmit} className='btn btn__red' disabled={loading}>
									{loading ? 'Signing up...' : 'Sign up'}
								</button>
								<span className='or'>or</span>
								<div className='link member-singin'>Login</div>
							</div>
							<div className='politics'>
								By signing up, you agree to our <Link to='/terms-and-conditions'>Terms and Conditions</Link>
							</div>
							<div className='politics'>
								By signing up, you agree to our{' '}
								<Link to='/final model-release.pdf' download target='_blank'>
									Content Agreement
								</Link>
							</div>
						</form>
						<div className='registration-free--btn btn btn__purple'>I want premium account</div>
					</div>
				</div>

				{/* premium registtrations starts here */}
				<div className='registration-premium'>
					<div className='registration-form'>
						<div className='registration-form--title'>
							Sign up for premium <br />
							account{' '}
							<span>
								and enhance your <br />
								experience
							</span>
						</div>

						<form onSubmit={handleSubmit}>
							<div className='registration-form--step1'>
								<div className='input-wrapper--title'>Email address</div>
								<div className='input-wrapper'>
									<input
										autoComplete='new-email'
										name='email'
										type='text'
										id='email-free'
										placeholder='Email address'
										onChange={e => setEmail(e.target.value)}
									/>{' '}
								</div>
								<div className='input-wrapper'>
									<label className={styles.selectLabel}>User Type</label>
									<select className={styles.select} onChange={e => setUserType(e.target.value)}>
										<option selected value='member'>
											Member
										</option>
										<option value='model'>Model</option>
										<option value='club'>Club</option>
									</select>
								</div>
								<div className='input-wrapper__double'>
									<div>
										<div className='reg-model-step1--form--title'>Password</div>
										<div className='input-wrapper'>
											<input
												name='password'
												autoComplete='new-password'
												className='required passwordJS'
												type='password'
												placeholder='Password'
												onChange={e => setPassword(e.target.value)}
											/>
											<div className='password-tooltip'>
												<div className='password-tooltip--title'>Must comply with the following rules:</div>
												<ul className='password-tooltip--list'>
													<li className='passwordJS_lower'>
														Аt least one lower <span />
													</li>
													<li className='passwordJS_number'>
														Аt least one number <span />
													</li>
													<li className='passwordJS_upper'>
														Аt least one upper <span />
													</li>
													<li className='passwordJS_min'>
														Оver 8 characters <span />
													</li>
												</ul>
											</div>
											<svg
												className='viewPassword'
												xmlns='http://www.w3.org/2000/svg'
												width='25'
												height='14'
												viewBox='0 0 25 14'
												fill='none'
											>
												<g opacity='0.8'>
													<path
														d='M14.9149 6.73145C14.9149 8.41239 13.5928 9.73145 12.0152 9.73145C10.4375 9.73145 9.11548 8.41239 9.11548 6.73145C9.11548 5.0505 10.4375 3.73145 12.0152 3.73145C13.5928 3.73145 14.9149 5.0505 14.9149 6.73145Z'
														stroke='#B0B0B0'
														strokeWidth='2'
													/>
													<path
														d='M12.0109 12.4629C6.04378 12.4629 2.61486 9.6441 1.11775 6.72709C2.74488 3.61227 6.02678 1 12.0131 1C17.9859 1 21.4187 3.80984 22.9139 6.72537C21.2885 9.8449 18.0039 12.4629 12.0109 12.4629Z'
														stroke='#B0B0B0'
														strokeWidth='2'
													/>
												</g>
											</svg>
										</div>
									</div>
									<div>
										<div className='reg-model-step1--form--title'>Date of birth</div>
										<div className='input-wrapper'>
											<Date handleDateChange={setDateOfBirth} />
										</div>
									</div>
								</div>
								<div className='btn-wrapper'>
									<div onClick={handleSubmit} className='btn btn__red'>
										Sign up
									</div>
									<span className='or'>or</span>
									<div className='link member-singin' to='/login'>
										Login
									</div>
								</div>
								<div className='politics'>
									By signing up, you agree to our <Link to='/privacy-policy'>Terms and Conditions</Link>
								</div>
							</div>

							<div className='registration-form--step2'>
								<div className='registration-form-subscribe'>
									<label className='registration-form-subscribe-item__outer'>
										<input
											type='radio'
											name='react-tips'
											value='option2'
											checked={selectedOption === 'option2'}
											onChange={handleOptionChange}
											className='form-check-input'
										/>
										<div className='registration-form-subscribe-item'>
											<div className='registration-form-subscribe-item--date'>1 month</div>
											<div className='registration-form-subscribe-item--price'>+5.99</div>
											<div className='registration-form-subscribe-item--text'>
												and enhance <br />
												your experience
											</div>
										</div>
									</label>
									<label className='registration-form-subscribe-item__outer'>
										<input
											type='radio'
											name='react-tips'
											value='option1'
											checked={selectedOption === 'option1'}
											onChange={handleOptionChange}
											className='form-check-input'
										/>
										{/* <input name="budget" type="radio" value="6month" checked={true}/> */}
										<div className='registration-form-subscribe-item'>
											<div className='registration-form-subscribe-item--date'>6 month</div>
											<div className='registration-form-subscribe-item--price'>+41.94</div>
											<div className='registration-form-subscribe-item--text'>
												and enhance <br />
												your experience
											</div>
										</div>
									</label>
								</div>
								<button className='btn btn__red registration-form-subscribe--btn'>Go to payment</button>
								<div className='registration-form-subscribe-transition'>
									<div className='registration-form-subscribe-transition--btn registration-transition'>
										Free 7 day trial
									</div>
								</div>
							</div>
						</form>
						<div className='registration-form--want want-free--btn'>I want free account</div>
					</div>

					<div className='registration-free-thanks premium-thanks'>
						<div className='registration-free-thanks--svg'>
							<svg xmlns='http://www.w3.org/2000/svg' width='94' height='93' viewBox='0 0 94 93' fill='none'>
								<path
									opacity='0.5'
									d='M46.1602 92.3204C20.7112 92.3204 0 71.6092 0 46.1602C0 20.7112 20.7112 0 46.1602 0C54.5358 0 62.8564 2.3964 70.2264 6.91421C72.5363 8.33634 73.2592 11.3613 71.8371 13.6713C70.4149 15.9812 67.39 16.6962 65.0878 15.2898C59.2658 11.7149 52.7208 9.82132 46.1602 9.82132C26.1247 9.82132 9.82132 26.1247 9.82132 46.1602C9.82132 66.1957 26.1247 82.4991 46.1602 82.4991C66.1957 82.4991 82.4991 66.2035 82.4991 46.1602C82.4991 43.5438 82.1376 40.7781 81.3991 37.7139C80.7627 35.0739 82.3891 32.4261 85.0212 31.7896C87.669 31.1454 90.3168 32.7718 90.9454 35.4117C91.8725 39.2538 92.3204 42.7659 92.3204 46.1602C92.3204 71.6092 71.6092 92.3204 46.1602 92.3204ZM47.633 59.4822L91.7596 15.3556C93.6689 13.4385 93.6689 10.3271 91.7596 8.40996C89.8425 6.49284 86.7233 6.49284 84.8061 8.40996L44.1602 49.0638L32.8225 37.7182C30.9053 35.801 27.794 35.801 25.8768 37.7182C23.9597 39.6353 23.9597 42.7467 25.8768 44.6638L40.6952 59.4822C41.6538 60.4408 42.9031 60.92 44.1602 60.92C45.4173 60.92 46.6745 60.4408 47.633 59.4822Z'
									fill='#EC9ABE'
								/>
							</svg>
						</div>

						{/* Success registration page */}
						<div className='registration-free-thanks--title'>Thank you for registering</div>
						<div className='registration-free-thanks--description'>
							An email with a link was sent to the email <br />
							address you specified, after which you can <br />
							confirm the email address and complete the <br />
							registration process
						</div>
						{/* <div className='btn btn__red registration-free-thanks--link'>Resend confirmation email</div> */}
						<Link to='/login' className='registration-free-thanks--home'>
							login
						</Link>
					</div>

					{/* payment page */}
					<div className='registration-free--text'>
						<div className='registration--title'>
							Join now and <br />
							enjoy for free!
						</div>
						<ul className='registration--list'>
							<li>
								<img src={require('../../images/registration_w.png')} alt='' />
								5,000 models online
							</li>
							<li>
								<img src={require('../../images/registration_free.png')} alt='' />
								Available free <br />
								photos & videos
							</li>
						</ul>
					</div>
				</div>
			</section>
		</main>
	);
};
export default RegistrationMember;
