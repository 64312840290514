import { FaAngleRight } from 'react-icons/fa6';
import { FaChevronLeft } from 'react-icons/fa';
import { RiArrowDropUpLine } from 'react-icons/ri';
import DateTimePicker from '../../../custom/datePicker/DatePicker';
import BarChart from './BarChart';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useRef } from 'react';
import { Autoplay } from 'swiper/modules';

const ExpandAnalysis = () => {
  const swiperRef = useRef();
  

  return (
    <main className='mb-[120px]'>
      <section className='flex items-center mb-[20px] w-[100%] justify-between'>
        <div>
          <div className='flex items-center gap-[0.5rem]'>
            <h1 className="text-white text-xl font-bold font-['Montserrat']">
              Impressions
            </h1>
            <div className='flex items-center gap-[0.5rem]'>
              <FaChevronLeft
                className='text-white  cursor-pointer'
                onClick={() => swiperRef.current.slidePrev()}
              />
              <FaAngleRight
                className='text-white  cursor-pointer'
                onClick={() => swiperRef.current.slideNext()}
              />
            </div>
          </div>
          <p>
            <span className="text-stone-300 text-sm font-normal font-['Montserrat']">
              Your posts earned 76
            </span>
            <span className="text-stone-300 text-sm font-semibold font-['Montserrat']">
              k impressions
            </span>
            <span className="text-stone-300 text-sm font-normal font-['Montserrat']">
              {' '}
              so far in{' '}
            </span>
            <span className="text-stone-300 text-sm font-semibold font-['Montserrat']">
              July
            </span>
          </p>
        </div>
        <div className='flex flex-col items-end w-[max-content]'>
          <DateTimePicker
            selected={new Date()}
            renderElement={({ value, onClick }) => (
              <div className='flex items-center gap-[4px]'>
                <span className="text-white text-xl font-medium font-['Montserrat']">
                  {value}
                </span>
                <RiArrowDropUpLine
                  onClick={onClick}
                  className='text-[2rem] rotate-180'
                />
              </div>
            )}
            showMonthYearPicker
            dateFormat='MMM yyyy'
          />
          <p>
            <span className="text-stone-300 text-sm font-normal font-['Montserrat']">
              You earned an average of{' '}
            </span>
            <span className="text-stone-300 text-sm font-semibold font-['Montserrat']">
              2099 / day
            </span>
            <span className="text-stone-300 text-sm font-normal font-['Montserrat']">
              {' '}
            </span>
          </p>
        </div>
      </section>
      <section>
        <Swiper
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          modules={[Autoplay]}
          autoplay
          spaceBetween={0}
          slidesPerView={1}
        >
          <SwiperSlide>
            <BarChart />
          </SwiperSlide>
          <SwiperSlide>
            {' '}
            <BarChart />
          </SwiperSlide>
          <SwiperSlide>
            {' '}
            <BarChart />
          </SwiperSlide>
          <SwiperSlide>
            {' '}
            <BarChart />
          </SwiperSlide>
        </Swiper>
      </section>
    </main>
  );
};

export default ExpandAnalysis;
