import axios from 'axios';

export const getModels = async ({
  feature,
  pageNumber,
  category,
  recommendation,
  pageSize,
}) => {
  return (
    await axios.get(
      `ModelsProfile/GetAllModelWithSearchAndFilter?PageNumber=${pageNumber}&PageSize=${pageSize}&filterBy=${recommendation}&category=${category}&feature=${feature}`
    )
  )?.data;
};

export const getModelsByCategory = async ({ pageNumber, category }) => {
	return (
    await axios.get(
      `ModelsProfile/GetAllModelWithSearchAndFilter?PageNumber=${pageNumber}&PageSize=20&category=${category}`
    )
  )?.data;
};

export const getNewModels = async () => {
  return axios.get('ModelsProfile/GetTopModels');
};

export const getCountries = async () => {
  return (await axios.get(`/SetUp/GetAllCountries`))?.data?.models;
}