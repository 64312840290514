import styles from './styles.module.scss';
import classnames from 'classnames';

const Btn = ({ className, text, ...props }) => {
	return (
		<button className={classnames(styles.btn, className)} {...props}>
			{text}
		</button>
	);
};

export default Btn;
