import styles from './blockusers.module.scss';
import ModelProfileHeader from '../../custom/modelProfileHeader/ModelProfileHeader';
import FluidContainer from '../../custom/fluidContainer/FluidContainer';
import Btn from '../../custom/btn/Btn';
import Spinner from '../../custom/spinner/Spinner';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import DropDown from '../../custom/DropDown/DropDown';
import { ReactComponent as DropIcon } from '../../svgs/drop-icon.svg';
import Table from './table/Table';
import { useSelector } from 'react-redux';
import {
  createBlockCriteria,
  getAllBlockCriteria,
  updateBlockCriteria,
  deleteBlockCriteria,
} from '../../request/model';
import notification from '../../utils/notification';
import errorFormmatter from '../../utils/errorFormatter';

import { Formik } from 'formik';
import * as Yup from 'yup';

const BlockSchema = Yup.object().shape({
  country: Yup.string()
    .trim()
    .required('country is required'),
  description: Yup.string()
    .trim()
    .required('description is required'),
  state: Yup.string().required('state time is required'),
  city: Yup.string().required('city time is required'),
  zipCode: Yup.string().required('zip code is required'),
});

const ActiveArrow = (props) => {
  return (
    <svg
      {...props}
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='25'
      viewBox='0 0 25 29'
      fill='none'
    >
      <path
        d='M24 12.7679C25.3333 13.5378 25.3333 15.4622 24 16.232L3.75 27.9234C2.41666 28.6932 0.749999 27.7309 0.749999 26.1913L0.75 2.80865C0.75 1.26905 2.41667 0.306805 3.75 1.07661L24 12.7679Z'
        fill='#010002'
      />
    </svg>
  );
};

const countries = [
  {
    label: 'UK',
    value: 'UK',
  },
  {
    label: 'Mexico',
    value: 'Mexico',
  },
];

const states = [
  {
    label: 'LMexicali',
    value: 'LMexicali',
  },
  {
    label: 'Baja California',
    value: 'Baja California',
  },
];

const BlockerUser = () => {
  const [currentEditingCriteria, setCurrentEditingCriteria] = useState({});
  const [blockCriteria, setBlockCriteria] = useState([]);
  const [mode, setMode] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [initialValue, setInitialValue] = useState(
    mode
      ? {
          country: currentEditingCriteria?.country,
          description: currentEditingCriteria?.description,
          state: currentEditingCriteria?.state,
          city: currentEditingCriteria?.city,
          zipCode: currentEditingCriteria?.zipCode,
        }
      : {
          country: '',
          description: '',
          state: '',
          city: '',
          zipCode: '',
        }
  );
  const user = useSelector((state) => state?.user);

  const handleStateChange = (data) => {
    setSelectedState(data?.value);
  };

  const handleCountryChange = (data) => {
    setSelectedCountry(data?.value);
  };

  const onModeChange = (mode) => {
    setMode(mode);
  };

  const handleCurrentBlockCriteria = (selectCriteria) => {
    setCurrentEditingCriteria(selectCriteria);
    onModeChange(true);
  };

  const resetCurrentBlockCriteria = () => {
    onModeChange(false);
    setCurrentEditingCriteria({});
  };

  const handleAddBlockCriteria = async (values) => {
    try {
      setLoading(true);
      const payload = new FormData();
      payload.append('criteriaDescription', values.description);
      payload.append('country', values.country);
      payload.append('state', `${values.state}`);
      payload.append('city', values.city);
      payload.append('zipCode', values.zipCode);
      payload.append('registeredId', `${user?.clientId}`);

      const response = await createBlockCriteria({
        payload,
      });
      notification({
        message: response?.message ?? '',
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);

      notification({
        message:
          errorFormmatter(error) ?? 'Request failed to add block criteria',
        type: 'danger',
      });
    }
  };

  const handleUpdateBlockCriteria = async (values) => {
    try {
      setLoading(true);
      const payload = {
        id: currentEditingCriteria?.id,
        criteriaDescription: values.description,
        country: values.country,
        state: `${values.state}`,
        city: values.city,
        zipCode: values.zipCode,
        registeredId: `${user?.clientId}`,
      };

      const response = await updateBlockCriteria({
        payload: JSON.stringify(payload),
      });
      notification({
        message: response?.message ?? '',
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);

      notification({
        message:
          errorFormmatter(error) ?? 'Request failed to add block criteria',
        type: 'danger',
      });
    }
  };

  const handleDeleteBlockCriteria = async (id) => {
    try {
      setDeleteLoading(true);
      const payload = {
        modelId: user?.clientId,
        id,
      };

      const response = await deleteBlockCriteria({
        payload,
      });
      notification({
        message: response?.message ?? '',
      });
      setDeleteLoading(false);
    } catch (error) {
      setDeleteLoading(false);
      notification({
        message:
          errorFormmatter(error) ?? 'Request failed to delete block criteria',
        type: 'danger',
      });
    }
  };

  const getListOfBlockCriteria = async () => {
    try {
      const response = await getAllBlockCriteria({
        modelId: user?.clientId,
      });
      setBlockCriteria(response?.data);
    } catch (error) {
      setBlockCriteria([]);
    }
  };

  useEffect(() => {
    getListOfBlockCriteria();
  }, []);

  useEffect(() => {
    getListOfBlockCriteria();
  }, [loading, deleteLoading]);

  useEffect(() => {
    setSelectedCountry(currentEditingCriteria?.country ?? '');
    setSelectedState(currentEditingCriteria?.state ?? '');
  }, [currentEditingCriteria]);

  useEffect(() => {
    if (mode === true) {
      setInitialValue(currentEditingCriteria);
    } else {
      setInitialValue({
        country: '',
        description: '',
        state: '',
        city: '',
        zipCode: '',
      });
    }
  }, [currentEditingCriteria, mode]);

  return (
    <FluidContainer>
      <ModelProfileHeader />
      <main className={styles.container}>
        <h1 className={styles.title}>Block Visitors</h1>
        <section className={styles.tabContainer}>
          <span
            className={classNames(
              styles.tab,
              mode === true && styles.activeTab
            )}
          >
            Editing Criteria
          </span>{' '}
          -{' '}
          <span
            onClick={() => resetCurrentBlockCriteria()}
            className={classNames(
              styles.tab,
              mode === false && styles.activeTab
            )}
          >
            I Prefer to Block
          </span>
        </section>

        <section className={styles.blockerContainer}>
          <section>
            <h1 className={styles.blockerTitle}>Block Criteria</h1>
            <p className={styles.blockerContent}>
              Fill in the information that will be used to block unwanted
              visitors
            </p>
          </section>
          <section>
            <Formik
              enableReinitialize={true}
              validationSchema={BlockSchema}
              initialValues={
                mode
                  ? {
                      country:
                        currentEditingCriteria?.country ?? initialValue.country,
                      description:
                        currentEditingCriteria?.originalDes ??
                        initialValue.description,
                      state:
                        currentEditingCriteria?.state ?? initialValue.state,
                      city: currentEditingCriteria?.city ?? initialValue.city,
                      zipCode:
                        currentEditingCriteria?.zipcode ??
                        initialValue?.zipCode,
                    }
                  : initialValue
              }
              onSubmit={
                mode ? handleUpdateBlockCriteria : handleAddBlockCriteria
              }
            >
              {(props) => {
                return (
                  <>
                    <div className={styles.inputContainer}>
                      <div>
                        <div className={styles.input}>
                          <textarea
                            key={
                              currentEditingCriteria?.description ??
                              `input-${0}`
                            }
                            defaultValue={currentEditingCriteria?.originalDes}
                            autoComplete='off'
                            placeholder='Description'
                            type={'text'}
                            name='description'
                            onChange={props.handleChange}
                          />
                          <span>Criteria Description</span>
                        </div>
                      </div>
                      {props.touched && props.errors?.description && (
                        <span className={styles.error}>
                          * {props.errors?.description}
                        </span>
                      )}
                    </div>

                    <div className={styles.flexInputContainer}>
                      <div>
                        <DropDown
                          onChange={(value) => {
                            handleCountryChange(value);
                            props.setFieldValue('country', value?.value);
                          }}
                          selectClass={styles.select}
                          options={countries}
                          placeholder='select country'
                          className={styles.selectContainer}
                          dropElement={({ props, state }) => {
                            return (
                              <div className={styles.dropDownRender}>
                                <div className={styles.datePickerLeftContainer}>
                                  <span className={styles.selectedDropDownItem}>
                                    {selectedCountry ? (
                                      selectedCountry
                                    ) : (
                                      <span className={styles.placeholder}>
                                        {props.placeholder}
                                      </span>
                                    )}
                                  </span>
                                </div>
                                {/* <div
                                  className={styles.datePickerRightContainer}
                                >
                                  <DropIcon />
                                </div> */}
                              </div>
                            );
                          }}
                        />
                        {props.touched && props.errors?.country && (
                          <span className={styles.error}>
                            * {props.errors?.country}
                          </span>
                        )}
                      </div>
                      <div>
                        <DropDown
                          onChange={(value) => {
                            handleStateChange(value);
                            props.setFieldValue('state', value?.value);
                          }}
                          placeholder='select state'
                          options={states}
                          selectClass={styles.select}
                          className={styles.selectContainer}
                          dropElement={({ props, state }) => (
                            <div className={styles.dropDownRender}>
                              <div className={styles.datePickerLeftContainer}>
                                <span className={styles.selectedDropDownItem}>
                                  {selectedState ? (
                                    selectedState
                                  ) : (
                                    <span className={styles.placeholder}>
                                      {props.placeholder}
                                    </span>
                                  )}
                                </span>
                              </div>
                              {/* <div className={styles.datePickerRightContainer}>
                                <DropIcon />
                              </div> */}
                            </div>
                          )}
                        />
                        {props.touched && props.errors?.state && (
                          <span className={styles.error}>
                            * {props.errors?.state}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className={styles.flexInputContainer}>
                      <div className={styles.inputContainerWrapper}>
                        <div className={styles.input}>
                          <input
                            key={currentEditingCriteria?.city ?? `input-${1}`}
                            defaultValue={currentEditingCriteria?.city}
                            autoComplete='off'
                            placeholder='City'
                            name='city'
                            onChange={props.handleChange}
                          />
                        </div>
                        {props.touched && props.errors?.city && (
                          <span className={styles.error}>
                            * {props.errors?.city}
                          </span>
                        )}
                      </div>
                      <div className={styles.inputContainerWrapper}>
                        <div className={styles.input}>
                          <input
                            key={
                              currentEditingCriteria?.zipcode ?? `input-${2}`
                            }
                            defaultValue={currentEditingCriteria?.zipcode}
                            autoComplete='off'
                            placeholder='Zip code'
                            type={'text'}
                            name='zipCode'
                            onChange={props.handleChange}
                          />

                          <span>Zip Code</span>
                        </div>
                        {props.touched && props.errors?.zipCode && (
                          <span className={styles.error}>
                            * {props.errors?.zipCode}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className={styles.actionContainer}>
                      <Btn
                        type='submit'
                        onClick={props.handleSubmit}
                        text={
                          mode ? 'Update Block Criteria' : 'Add Block Criteria'
                        }
                        className={styles.updateButton}
                      />
                      {loading && <Spinner background={false} isTip={false} />}
                    </div>
                  </>
                );
              }}
            </Formik>
          </section>
          <section className={styles.navigationContainer}>
            {mode && (
              <>
                <div className={styles.blockerDetailContainer}>
                  <h1 className={styles.blockerDetailTitle}>
                    Blocking Visitors from : {currentEditingCriteria?.country}
                  </h1>

                  <span className={styles.blockerDetail}>
                    State : {currentEditingCriteria.state}
                  </span>
                  <span className={styles.blockerDetail}>
                    City : {currentEditingCriteria?.city}
                  </span>
                  <span className={styles.blockerDetail}>
                    ZipCode : {currentEditingCriteria?.zipcode}
                  </span>
                </div>
                <span className={styles.totalBlocked}>
                  1 visitor(s) blocked
                </span>
              </>
            )}
          </section>
        </section>
        <section>
          <h1 className={styles.blockerContentListTitle}>
            Block Criteria List
          </h1>

          <Table
            deleteLoading={deleteLoading}
            handleDeleteBlockCriteria={handleDeleteBlockCriteria}
            tableData={blockCriteria}
            handleCurrentBlockCriteria={handleCurrentBlockCriteria}
          />
        </section>
      </main>
    </FluidContainer>
  );
};

export default BlockerUser;
