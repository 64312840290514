import DatePicker from 'react-datepicker';
import { useState } from 'react';
import { forwardRef } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';

const DateTimePicker = ({
	renderElement: CustomComponent,
	dayClassName,
	className,
	dateFormat,
	openToDate,
	onChange,
	...rest
}) => {
	const [startDate, setStartDate] = useState(openToDate ? openToDate : new Date());

	let CustomPicker = null;

	if (CustomComponent) {
		CustomPicker = forwardRef((props, ref) => <CustomComponent {...props} ref={ref} />);
	}

	const WEEKDAYS_SHORT = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
	return (
    <div className={classNames(styles.container, className)}>
      <DatePicker
        adjustDateOnChange
        openToDate={openToDate ? openToDate : new Date()}
        popperClassName={classNames(styles.dateTime)}
        dateFormat={dateFormat ? dateFormat : 'd.MM.yyyy'}
        popperModifiers={[]}
        weekdaysShort={WEEKDAYS_SHORT}
        selected={startDate}
        onChange={(date) => {
          if (onChange) {
            onChange(new Date(date));
          }
          setStartDate(new Date(date));
        }}
        customInput={CustomPicker !== null ? <CustomPicker /> : null}
        {...rest}
      />
    </div>
  );
};

export default DateTimePicker;
