import CustomModal from '../../../custom/Modal/Modal';
import styles from '../styles.module.scss';
import { createDiary } from '../../../request/model';
import Button from '../../../custom/btn/Btn';
import classNames from 'classnames';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Switch from '../../../custom/switch/Switch';
import FileUpload from '../../../custom/fileUpload/FileUpload';
import RichTextEditor from '../../../custom/Tiptap/RichTextEditor';
import { useSelector } from 'react-redux';
import notification from '../../../utils/notification';
import errorFormmatter from '../../../utils/errorFormatter';
import { useEffect, useState } from 'react';
import Spinner from '../../../custom/spinner/Spinner';

const DiarySchema = Yup.object().shape({
  // title: Yup.string().trim().required('title is required'),
  description: Yup.string().trim().required('description is required'),
  isHidden: Yup.bool().required('permission is required'),
  coverImage: Yup.string().required('cover image is required'),
  diaryText: Yup.string().required('diary content  is required'),
  isCommentAllowed: Yup.bool().required('comment permission is required'),
});

const initialValue = {
  // title: '',
  description: '',
  isHidden: false,
  coverImage: '',
  diaryText: '',
  isCommentAllowed: false,
};

const CreateDiaryPopup = ({ open, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const [permission, setPermission] = useState({
    isHidden: false,
    isCommentAllowed: false,
  });

  const handleSetPermission = (name) => {
    setPermission((prev) => ({
      ...prev,
      [name]: !permission[name],
    }));
  };

  const handleCommentAllowed = (event) => {
    const name = event.target.name;
    const value = event.target.checked;

    setPermission((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const user = useSelector((state) => state?.user);

  const handleCreateDiary = async (values) => {
    try {
      setLoading(true);
      const payload = new FormData();
      payload.append('registeredId', user?.clientId);
      // payload.append('title', values.title);
      payload.append('isHidden', permission.isHidden);
      payload.append('description', values.description);
      payload.append('diaryText', values.diaryText);
      payload.append('isAllowComments', permission.isCommentAllowed);
      payload.append('addCoverImage', values.coverImage);

      const response = await createDiary({ payload });
      setLoading(false);
      handleClose();
      notification({
        message: response?.message ?? 'Fan club dairy created successfully',
      });
    } catch (error) {
      notification({
        message: errorFormmatter(error) ?? 'Fan club dairy failed to create',
        type: 'danger',
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open === false) {
      setPermission(() => ({
        isCommentAllowed: false,
        isHidden: false,
      }));
    }
  }, [open]);

  return (
    <CustomModal open={open}>
      <Formik
        enableReinitialize={true}
        validationSchema={DiarySchema}
        initialValues={initialValue}
        onSubmit={handleCreateDiary}
      >
        {(props) => {
          return (
            <section className={styles.createDairyContainer}>
              <section className={styles.nyWrapperContainer}>
                <section className={styles.nyContainer}>
                  <section className={styles.coverImageContainer}>
                    <FileUpload
                      handleFileChange={(file) => {
                        props.setFieldValue('coverImage', file);
                      }}
                      renderElement={
                        <>
                          {typeof props.values.coverImage === 'object' ? (
                            <img
                              className={styles.nyDairyCover}
                              src={URL.createObjectURL(props.values.coverImage)}
                            />
                          ) : (
                            <div className={styles.coverImagePreview}>
                              Diary Cover
                            </div>
                          )}
                          {props.touched && props.errors?.coverImage && (
                            <span className={styles.error}>
                              * {props.errors?.coverImage}
                            </span>
                          )}
                        </>
                      }
                    />

                    <div className={styles.optionContainer}>
                      <span className={styles.option}>
                        <span>Allow Comments</span>{' '}
                        <Switch
                          name={'isCommentAllowed'}
                          containerClass={styles.switchContainer}
                          onChange={(event) => {
                            handleCommentAllowed(event);
                          }}
                        />
                      </span>
                    </div>
                  </section>
                  <section className={styles.nyDetailWrapper}>
                    <div className={styles.actionContainer}>
                      {loading && <Spinner />}
                      <Button
                        type='button'
                        text='Cancel'
                        className={styles.cancelButton}
                        onClick={handleClose}
                      />
                      <Button
                        type='button'
                        text='Create'
                        className={styles.createButton}
                        onClick={props.handleSubmit}
                      />
                    </div>
                    <h1 className={styles.nyTitle}>{props.values.title}</h1>

                    <div className={styles.nyActionContainer}>
                      <label className={styles.nyLabel}>
                        <span> Permissions</span>

                        <ul className={styles.nyPermissions}>
                          <li
                            onClick={() => {
                              handleSetPermission('isHidden');
                            }}
                            className={classNames(
                              styles.nyPermission,
                              permission.isHidden && styles.nyActive
                            )}
                          >
                            Hidden
                          </li>
                        </ul>
                      </label>
                      {/* <section className={styles.nyLabel}>
                        <span> Actions</span>

                        <div className={styles.nyDairyActionContainer}>
                          <FileUpload
                            handleFileChange={(file) => {
                              props.setFieldValue('coverImage', file);
                            }}
                            renderElement={
                              <Button
                                text='Change diary cover'
                                className={styles.nyChangeDiaryCoverButton}
                              />
                            }
                          />
                        </div>
                      </section> */}
                    </div>
                    {/* <label className={styles.nyLabel}>
                      <span>Title</span>
                      <input
                        className={styles.input}
                        placeholder='Title'
                        name='title'
                        onChange={props.handleChange}
                      />
                      {props.touched && props.errors?.title && (
                        <span className={styles.error}>
                          * {props.errors?.title}
                        </span>
                      )}
                    </label> */}

                    <label className={styles.nyLabel}>
                      <span>Description</span>
                      <textarea
                        name='description'
                        onChange={props.handleChange}
                        className={styles.textarea}
                        placeholder='It`s my first travel to beach. And I prepared for you something special'
                      />
                      {props.touched && props.errors?.description && (
                        <span className={styles.error}>
                          * {props.errors?.description}
                        </span>
                      )}
                    </label>

                    <label className={styles.nyLabel}>
                      <span>Diary Text</span>
                      <div className={styles.editorContainer}>
                        <RichTextEditor
                          onChange={(content) => {
                            props.setFieldValue('diaryText', content);
                          }}
                        />
                      </div>
                      {props.touched && props.errors?.diaryText && (
                        <span className={styles.error}>
                          * {props.errors?.diaryText}
                        </span>
                      )}
                    </label>
                  </section>
                </section>
              </section>
            </section>
          );
        }}
      </Formik>
    </CustomModal>
  );
};

export default CreateDiaryPopup;
