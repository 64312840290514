import CheckBox from '../../../custom/checkbox/CheckBox';
import notification from '../../../utils/notification';
import {
  getStreamingConfig,
  updateStreamingBroadcastSetting,
  updateStreamingBroadcastConfig,
} from '../../../request/model';
import Input from '../../../custom/input/Input';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const SoundSetting = () => {
  const [loading, setLoading] = useState(false);
  const [soundSetting, setSoundSetting] = useState({
    tipReceivedSound: false,
    messageReceivedSound: false,
    userEnteredSound: false,
    textSizeData: '',
    guestTimeKickOut: '',
    timeLimit: false,
    allowShowRecording: false,
    tipVolume: 0,
    exitVolume: 0,
    entryVolume: 0,
    allowPrivateChat: true,
    allowGeneralChat: true,
    allowMessage: true,
    allowGuest: true,
    allowMicOff: true,
    allowCamOn: true,
    allowTips: true,
  });
  const user = useSelector((state) => state?.user);

  const handleGetSetting = async () => {
    try {
      const response = await getStreamingConfig({ modelId: user.clientId });

      setSoundSetting((prev) => {
        return {
          ...prev,
          ...response,
          tipReceivedSound: response?.tipReceivedSound,
          messageReceivedSound: response?.messageReceivedSound,
          userEnteredSound: response?.userEnteredSound,
          textSizeData: response?.textSize,
          guestTimeKickOut: response?.guestTimeKickOut,
          allowShowRecording: response?.allowShowRecording,
          timeLimit: response?.timeLimit,
          tipVolume: response?.tipVolume,
          exitVolume: response?.exitVolume,
          entryVolume: response?.entryVolume,
        };
      });
    } catch (error) {}
  };

  const updateSoundSetting = async (name, value) => {
    try {
      setLoading(true);
      const payload = new FormData();

      payload.append(
        'tipReceivedSound',
        name === 'tipReceivedSound' ? value : soundSetting.tipReceivedSound
      );
      payload.append(
        'messageReceivedSound',
        name === 'messageReceivedSound'
          ? value
          : soundSetting.messageReceivedSound
      );
      payload.append(
        'userEnteredSound',
        name === 'userEnteredSound' ? value : soundSetting.userEnteredSound
      );
      payload.append('textSizeData', soundSetting.textSizeData);
      payload.append('guestTimeKickOut', soundSetting.guestTimeKickOut);
      payload.append('allowShowRecording', soundSetting.allowShowRecording);
      payload.append('timeLimit', soundSetting.timeLimit);
      payload.append('registerId', user?.clientId);

      await updateStreamingBroadcastSetting({
        payload,
      });

      setLoading(false);
      notification({
        title: 'Sound settings',
        message: `Successfully updated sound setting`,
        type: 'success',
      });
    } catch (error) {
      setLoading(false);
      notification({
        title: 'Sound settings',
        message: `Sounding setting failed to update`,
        type: 'danger',
      });
    }
  };

  const updateStreamingVolumeSetting = async (name, value) => {
    try {
      setLoading(true);
      const payload = new FormData();

      payload.append(
        'tipVolume',
        name === 'tipVolume' ? value : soundSetting.tipVolume
      );
      payload.append(
        'exitVolume',
        name === 'exitVolume' ? value : soundSetting.exitVolume
      );
      payload.append(
        'entryVolume',
        name === 'entryVolume' ? value : soundSetting.entryVolume
      );
      payload.append('allowPrivateChat', soundSetting.allowPrivateChat);
      payload.append('allowGeneralChat', soundSetting.allowGeneralChat);
      payload.append('allowMessage', soundSetting.allowMessage);
      payload.append('allowGuest', soundSetting.allowGuest);
      payload.append('allowMicOff', soundSetting.allowMicOff);
      payload.append('allowCamOn', soundSetting.allowCamOn);
      payload.append('allowTips', soundSetting.allowTips);
      payload.append('registerId', user?.clientId);

      await updateStreamingBroadcastConfig({
        payload,
      });

      setLoading(false);
      //   notification({
      //     title: 'Volume settings',
      //     message: `Successfully updated volume setting`,
      //     type: 'success',
      //   });
    } catch (error) {
      setLoading(false);
      notification({
        title: 'Volume settings',
        message: `Volume setting failed to update`,
        type: 'danger',
      });
    }
  };

  const onChange = async (event) => {
    try {
      if (event.target?.type === 'range') {
        const name = event.target.name;
        const value = parseInt(event.target.value);

        setSoundSetting((prev) => {
          return {
            ...prev,
            [name]: value,
          };
        });

        const id = setTimeout(async () => {
          await updateStreamingVolumeSetting(name, value);
        }, 10000);
        clearTimeout(id);
      } else {
        const name = event.target.name;
        const value = event.target.checked;
        setSoundSetting((prev) => {
          return {
            ...prev,
            [name]: value,
          };
        });

        await updateSoundSetting(name, value);
      }
    } catch (error) {}
  };

  useEffect(() => {
    handleGetSetting();
  }, []);

  useEffect(() => {
    handleGetSetting();
  }, [loading]);

  return (
    <section key={2} className='max-w-[300px]'>
      <section>
        <h1 className="mb-[1rem] text-neutral-300 text-[17px] font-semibold font-['Montserrat'] leading-[17px] tracking-tight">
          User enter sound
        </h1>
        <div className={'flex w-[100%] mb-[24px] justify-between'}>
          <div className={'flex gap-[1rem] '}>
            <PlayIcon /> <span>User entered</span>
          </div>
          <CheckBox
            onChange={onChange}
            checked={soundSetting.userEnteredSound}
            name='userEnteredSound'
            checkedColor={'#775E96'}
            className={'!w-[10px]  bg-primary-500'}
          />
        </div>
        <div className={'flex w-[100%] mb-[24px] justify-between'}>
          <div className={'flex gap-[1rem] '}>
            <PlayIcon /> <span>Message Received</span>
          </div>
          <CheckBox
            onChange={onChange}
            checked={soundSetting.messageReceivedSound}
            name='messageReceivedSound'
            checkedColor={'#775E96'}
            className={'!w-[10px]  bg-primary-500'}
          />
        </div>
        <div className={'flex w-[100%] mb-[40px] justify-between'}>
          <div className={'flex gap-[1rem] '}>
            <PlayIcon /> <span>Tip received</span>
          </div>
          <CheckBox
            onChange={onChange}
            checked={soundSetting.tipReceivedSound}
            name='tipReceivedSound'
            checkedColor={'#775E96'}
            className={'!w-[10px]  bg-primary-500'}
          />
        </div>
      </section>
      <section>
        <h1 className="mb-[1rem] text-neutral-300 text-[17px] font-semibold font-['Montserrat'] leading-[17px] tracking-tight">
          Notification Volume
        </h1>
        <div className={'mb-[24px] '}>
          <h1 className="mb-[8px] text-neutral-300 text-sm font-medium font-['Montserrat']">
            Entry Volume
          </h1>
          <Input
            value={`${soundSetting.entryVolume}`}
            onChange={onChange}
            name='entryVolume'
            type={'range'}
            className='!rounded-[5px] !h-[3px] w-[100%]'
          />
        </div>
        <div className={'mb-[24px] '}>
          <h1 className="mb-[8px] text-neutral-300 text-sm font-medium font-['Montserrat']">
            Exit Volume
          </h1>
          <Input
            value={`${soundSetting.exitVolume}`}
            onChange={onChange}
            name='exitVolume'
            type={'range'}
            className='!rounded-[5px] !h-[3px] w-[100%]'
          />
        </div>
        <div className={'mb-[24px] '}>
          <h1 className="mb-[8px] text-neutral-300 text-sm font-medium font-['Montserrat']">
            Tip Volume
          </h1>
          <Input
            value={`${soundSetting.tipVolume}`}
            onChange={onChange}
            name='tipVolume'
            type={'range'}
            className='!rounded-[5px] !h-[3px] w-[100%]'
          />
        </div>
      </section>
    </section>
  );
};

const PlayIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <circle opacity='0.15' cx='12' cy='12' r='12' fill='white' />
      <path d='M19 12L8.5 18.0622L8.5 5.93782L19 12Z' fill='#B87693' />
    </svg>
  );
};

export default SoundSetting;
