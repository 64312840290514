import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import styles from './input.module.scss';

const Input = ({ placeholder, onChange, className, type, ...rest }) => {
  const inputRef = useRef(null);

  return (
    <input
      {...rest}
      data-track='red'
      onChange={onChange}
      ref={inputRef}
      type={type}
      className={classNames(
        "w-[300px] h-12 hover:border hover:border-primary-100 hover:border-[1px] text-white placeholder:text-primary-300 bg-primary-200 text-lg font-[1rem] font-['Montserrat'] p-[1rem] ",
        className,
        styles.input
      )}
      placeholder={placeholder}
    />
  );
};

export default Input;
