import styles from './index.module.scss';
import { BiImageAdd, BiUnderline } from 'react-icons/bi';
import { BsLink } from 'react-icons/bs';
import {
  AiOutlineOrderedList,
  AiOutlineAlignCenter,
  AiOutlineAlignLeft,
  AiOutlineAlignRight,
  AiOutlineBold,
} from 'react-icons/ai';
import {
  BsTypeH1,
  BsTypeH2,
  BsTypeH3,
  BsJustify,
  BsListUl,
  BsListNested,
} from 'react-icons/bs';
import { CgFormatItalic } from 'react-icons/cg';
import {
  boldText,
  italicText,
  underlineText,
  clearFormats,
  textHeading,
  textAlign,
  sinkList,
  bulletList,
  uploadImage,
  orderedBulletList,
} from '../commands/command';

import { HiOutlinePaperClip } from 'react-icons/hi';
import { useState } from 'react';
import classNames from 'classnames';

const ToolBar = ({ editor, children }) => {
  const [boldClass, setBoldClass] = useState('');
  const [italicClass, setItalicClass] = useState('');
  const [underlineClass, setUnderlineClass] = useState('');
  const [listStatus, setListStatus] = useState(0);

  const handleBoldText = () => {
    const { activeClass } = boldText(editor);
    setBoldClass(activeClass);
  };

  const handleItalicText = () => {
    const { activeClass } = italicText(editor);
    setItalicClass(activeClass);
  };

  const handleUnderlineText = () => {
    const { activeClass } = underlineText(editor);
    setUnderlineClass(activeClass);
  };

  const handleHeading = (level) => {
    textHeading(editor, level);
  };

  const handleTextAlign = (align) => {
    textAlign(editor, align);
  };

  const handleList = () => {
    bulletList(editor);
  };

  const handleOrderedList = () => {
    orderedBulletList(editor);
  };

  const handleSplit = () => {
    sinkList(editor);
  };

  const handleSelectImage = (file) => {
    const imageReader = new FileReader();
    imageReader.onload = (e) => {
      const { result } = e.target;
      if (result) {
        uploadImage(editor, result, '', '');
      }
    };
    imageReader.readAsDataURL(file[0]);
  };
  const handleClear = () => {
    clearFormats(editor);
  };
  return (
    <>
      <main className={styles.toolBarContainer}>
        <div className={styles.toolBarWrapperContainer}>
          <div className={styles.toolBarNavInnerContainer}>
            <section className={styles.toolActionContainer}>
              <div className={styles.groupToolAction}>
                <span
                  className={classNames(styles.toolIconContainer, boldClass)}
                >
                  <AiOutlineBold
                    className={styles.toolIcon}
                    onClick={handleBoldText}
                  />
                </span>
                <span
                  className={classNames(styles.toolIconContainer, italicClass)}
                >
                  <CgFormatItalic
                    className={styles.toolIcon}
                    onClick={handleItalicText}
                  />
                </span>
                <span
                  className={classNames(
                    styles.toolIconContainer,
                    underlineClass
                  )}
                >
                  <BiUnderline
                    className={styles.toolIcon}
                    onClick={handleUnderlineText}
                  />
                </span>
              </div>

              <div className={styles.groupToolAction}>
                <span className={styles.toolIconContainer}>
                  <BsTypeH1
                    className={styles.toolIcon}
                    onClick={() => handleHeading(1)}
                  />
                </span>
                <span className={styles.toolIconContainer}>
                  <BsTypeH2
                    className={styles.toolIcon}
                    onClick={() => handleHeading(2)}
                  />
                </span>
                <span className={styles.toolIconContainer}>
                  <BsTypeH3
                    className={styles.toolIcon}
                    onClick={() => handleHeading(3)}
                  />
                </span>
              </div>

              <div className={styles.groupToolAction}>
                <span className={styles.toolIconContainer}>
                  <AiOutlineAlignLeft
                    onClick={() => handleTextAlign('left')}
                    className={styles.toolIcon}
                  />
                </span>

                <span className={styles.toolIconContainer}>
                  <AiOutlineAlignCenter
                    onClick={() => handleTextAlign('center')}
                    className={styles.toolIcon}
                  />
                </span>
                <span className={styles.toolIconContainer}>
                  <BsJustify
                    onClick={() => handleTextAlign('justify')}
                    className={styles.toolIcon}
                  />
                </span>

                <span className={styles.toolIconContainer}>
                  <AiOutlineAlignRight
                    onClick={() => handleTextAlign('right')}
                    className={styles.toolIcon}
                  />
                </span>
              </div>

              <div className={styles.groupToolAction}>
                <span className={styles.toolIconContainer}>
                  <AiOutlineOrderedList
                    className={styles.toolIcon}
                    onClick={handleOrderedList}
                  />
                </span>

                <span className={styles.toolIconContainer}>
                  <BsListUl className={styles.toolIcon} onClick={handleList} />
                </span>
                <span className={styles.toolIconContainer}>
                  <BsListNested
                    className={styles.toolIcon}
                    onClick={handleSplit}
                  />
                </span>
              </div>
              {/* <div className={styles.groupToolAction}>
                {' '}
                <span className={styles.toolIconContainer}>
                  <BiImageAdd className={styles.toolIcon} />
                </span>
              </div> */}
            </section>
          </div>
        </div>
      </main>
      <div>{children}</div>
    </>
  );
};

export default ToolBar;
