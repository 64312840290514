import { useState, useRef } from 'react';

const ImageOptimization = props => {
	const { src, alt, width, height } = props;
	const [isLoaded, setIsLoaded] = useState(false);
	const imageRef = useRef(null);

	const handleImageLoad = () => {
		setIsLoaded(true);

		// Get the natural width and height of the image
		const naturalWidth = imageRef.current.naturalWidth;
		const naturalHeight = imageRef.current.naturalHeight;

		// Calculate the aspect ratio of the image
		const imageRatio = naturalWidth / naturalHeight;

		// Calculate the aspect ratio of the parent element
		const containerRatio = width / height;

		// Set the width and height styles of the image based on the aspect ratios
		if (imageRatio > containerRatio) {
			// The image is wider than the parent element, so scale it down to fit the width
			imageRef.current.style.width = '100%';
			imageRef.current.style.height = 'auto';
		} else {
			// The image is taller than the parent element, so scale it down to fit the height
			imageRef.current.style.width = 'auto';
			imageRef.current.style.height = '100%';
		}
	};

	return (
		<img
			ref={imageRef}
			src={src}
			alt={alt}
			{...props}
			onLoad={handleImageLoad}
			style={{
				visibility: isLoaded ? 'visible' : 'hidden',
				transition: 'visibility 0.5s linear',
			}}
		/>
	);
};

export default ImageOptimization;
