import { useSelector } from 'react-redux';
import { addPhoto, getPhoto, getAlbum, addToAlbum, deletePhotos } from '../../request/model';
import PhotoPreview from './PhotoPreview/ProfilePreview';
import AddButton from '../../custom/addButton/AddButton';
import UploadPopup from '../../custom/uploadPopup/UploadPopup';
import notification from '../../utils/notification';
import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import ModelEditPopup from './modelEditPopup/ModelEditPopup';
import errorFormmatter from '../../utils/errorFormatter';
import { updateFanClubPhoto } from '../../request/model';
import FluidContainer from '../../custom/fluidContainer/FluidContainer';
import ModelProfileHeader from '../../custom/modelProfileHeader/ModelProfileHeader';
import HeaderToolTip from '../../custom/headerToolTip/HeaderToolTip';
import { ReactComponent as CreateAlbumIcon } from '../../images/svg/albumIcon.svg';
import { ReactComponent as AddAlbumIcon } from '../../images/svg/add-album.svg';
import { ReactComponent as DeleteIcon } from '../../images/svg/delete.svg';
import SelectAll from '../../custom/selectAll/SelectAll';
import Button from '../../custom/btn/Btn';
import { Link } from 'react-router-dom';
import Spinner from '../../custom/spinner/Spinner';
import DropDown from '../../custom/DropDown/DropDown';

const Photos = ({}) => {
	const [file, setFile] = useState('');
	const [photos, setPhotos] = useState([]);
	const [loading, setLoading] = useState(false);
	const [openUpload, setOpenUpload] = useState(false);
	const [photoToEdit, setPhotoToEdit] = useState({});
	const [isSelectedAll, setIsSelectedAll] = useState(false);
	const [selectedPhotoIds, setSelectedPhotoIds] = useState([]);
	const [selectedAlbum, setSelectedAlbum] = useState(null);
	const [albums, setAlbum] = useState([]);

	const [isOpenEditModelPhoto, setIsOpenEditModelPhoto] = useState(false);

	const user = useSelector(state => state?.user);

	const handleSelectAll = e => {
		const { checked } = e.target;
		setIsSelectedAll(checked);
	};

	const handleOpenEditPhotoModal = photo => {
		setIsOpenEditModelPhoto(true);
		setPhotoToEdit(photo);
	};

	const handlePhotoSelection = photoId => {
		const id = selectedPhotoIds?.find(id => id === photoId);
		if (id) {
			const filteredPhotoIds = selectedPhotoIds?.filter(id => id !== photoId);
			setSelectedPhotoIds(filteredPhotoIds);
		} else {
			setSelectedPhotoIds(prev => [...prev, photoId]);
		}
	};

	const handleIsSelected = photoId => {
		const id = selectedPhotoIds?.find(id => id === photoId);
		return id ? true : false;
	};

	const handleFileChange = file => {
		setFile(file);
	};

	const deletePhoto = async id => {
		try {
			const payload = {
				modelId: user?.clientId,
				photoId: id,
			};

			setLoading(true);
			const response = await deletePhotos(payload);
			setLoading(false);
			notification({
				message: response?.message ?? 'Photo deleted successfully',
			});
		} catch (error) {
			notification({
				message: errorFormmatter(error) ?? 'Photo failed to delete',
				type: 'danger',
			});
			setLoading(false);
		}
	};

	const addPhotoToAlbum = async photoId => {
		try {
			const payload = new FormData();

			if (selectedAlbum === null) {
				notification({
					message: 'No album selected',
					type: 'danger',
				});
				return;
			}

			setLoading(true);
			payload.append('registeredId', user?.clientId);
			payload.append('albumId', selectedAlbum.albumId);
			payload.append('PhotoId', photoId);

			const response = await addToAlbum({ payload });
			setLoading(false);
			notification({
				message: response?.message ?? 'Photo added to album successfully',
			});
		} catch (error) {
			notification({
				message: errorFormmatter(error) ?? 'Photo to album failed to add',
				type: 'danger',
			});
			setLoading(false);
		}
	};

	const handlePhotoUpload = async () => {
		try {
			const payload = new FormData();

			payload.append('registeredId', user?.clientId);
			payload.append('addImage', file);
			setLoading(true);
			const response = await addPhoto({ payload });
			setLoading(false);
			notification({
				message: response?.message ?? 'Model photo added successfully',
			});
		} catch (error) {
			notification({
				message: errorFormmatter(error) ?? 'Model photo failed to add',
				type: 'danger',
			});
			setLoading(false);
		}
	};

	const getPhotos = async () => {
		try {
			const response = await getPhoto({ modelId: user.clientId });

			setPhotos(response?.data);
		} catch (error) {
			setPhotos([]);
		}
	};

	const getAlbums = async () => {
		try {
			const response = await getAlbum({ modelId: user?.clientId });
			setAlbum(response.data);
		} catch (error) {
			setAlbum([]);
		}
	};

	const handleUploadClose = () => {
		setOpenUpload(false);
	};

	const handleUploadOpen = () => {
		setOpenUpload(true);
	};

	const handleSelectChange = data => {
		setSelectedAlbum(data.value);
	};

	useEffect(() => {
		getPhotos();
		getAlbums();
	}, []);

	useEffect(() => {
		getPhotos();
	}, [loading, isOpenEditModelPhoto]);

	useEffect(() => {
		if (!photos) return;
		if (isSelectedAll) {
			const selectedIds = photos?.map(photo => photo?.photoId);
			setSelectedPhotoIds(selectedIds);
		} else {
			setSelectedPhotoIds([]);
		}
	}, [isSelectedAll]);

  return (
    <FluidContainer>
      <ModelProfileHeader className={styles.header} />
      <HeaderToolTip
        data-total={photos.length}
        text={'Photo'}
        classNames={styles.headerToottip}
      />
      <section className={styles.navContainer}>
        <SelectAll
          labelText='Select All Photo'
          checked={isSelectedAll}
          onChange={handleSelectAll}
        />
        <nav className={styles.nav}>
          {loading && <Spinner />}
          <DropDown
            onChange={handleSelectChange}
            placeholder='select album'
            options={albums.map((album) => {
              return {
                label:
                  album?.albumName?.length > 14
                    ? `${album?.albumName.slice(0, 14)}...`
                    : album?.albumName,
                value: album,
              };
            })}
            selectClass={styles.select}
            className={styles.selectContainer}
            dropElement={({ props, state }) => (
              <div className={styles.dropDownRender}>
                {selectedAlbum !== null
                  ? selectedAlbum?.albumName?.length > 14
                    ? `${selectedAlbum?.albumName.slice(0, 14)}...`
                    : selectedAlbum?.albumName
                  : 'Select'}
                {/* <DropIcon /> */}
              </div>
            )}
          />
          <Button
            onClick={() => {}}
            text={
              <Link to='/model-album' className={styles.link}>
                Create album
              </Link>
            }
            icon={CreateAlbumIcon}
          />
          <Button
            text='Add to album'
            icon={AddAlbumIcon}
            onClick={() => {
              selectedPhotoIds.map((id) => {
                addPhotoToAlbum(id);
              });
            }}
          />

					<Button
						text='Delete'
						icon={DeleteIcon}
						onClick={() => {
							selectedPhotoIds.map(id => {
								deletePhoto(id);
							});
						}}
					/>
				</nav>
			</section>
			<main className={styles.photosContainer}>
				<AddButton text='Add Photo' onClick={handleUploadOpen} />
				{photos?.map(photo => (
					<PhotoPreview
						key={photo?.imageURL}
						src={photo?.imageURL}
						photoId={photo?.photoId}
						photo={photo}
						deletePhoto={() => deletePhoto(photo?.photoId)}
						handleOpenEditPhotoModal={() => handleOpenEditPhotoModal(photo)}
						handlePhotoSelection={handlePhotoSelection}
						isSelected={handleIsSelected}
					/>
				))}
				<UploadPopup
					disabled={loading}
					upload={handlePhotoUpload}
					onFileChange={handleFileChange}
					open={openUpload}
					handleClose={handleUploadClose}
				/>
				<ModelEditPopup
					albumOptions={albums}
					open={isOpenEditModelPhoto}
					onClose={() => setIsOpenEditModelPhoto(false)}
					photo={photoToEdit}
					key={photoToEdit?.imageURL}
				/>
			</main>
		</FluidContainer>
	);
};

export default Photos;
