import { Store } from 'react-notifications-component';

const notification = ({ title, message, type, duration, position }) => {
	Store.addNotification({
		title: title,
		message: message,
		type: type || 'success',
		insert: 'top',
		container: position || 'top-right',
		animationIn: ['animate__animated', 'animate__fadeIn'],
		animationOut: ['animate__animated', 'animate__fadeOut'],
		dismiss: {
			duration: duration || 20000, // two minutes
			onScreen: false,
		},
	});
};

export default notification;
