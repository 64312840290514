import { useState } from 'react';
import PhotoPreview from './PhotoPreview/ProfilePreview';

const Gallery = ({ clubPhotos, openUpload, handleDeleteClubPhoto }) => {
  const [isShowAll, setIsShowAll] = useState(false);

  return (
    <>
      <section className='mb-[33px] flex justify-between w-[100%] items-center'>
        <h1 className=" text-primary-100 text-[36px] font-semibold font-['Montserrat'] leading-[50.40px]">
          Gallery
        </h1>
        {isShowAll && (
          <h1
            onClick={() => setIsShowAll(false)}
            className="cursor-pointer text-violet-500 text-[18px] font-medium font-['Montserrat'] leading-tight tracking-tight"
          >
            Show Less
          </h1>
        )}
      </section>

      <section className='mb-[46px] flex gap-[20px] flex-wrap'>
        <div
          onClick={openUpload}
          className="cursor-pointer flex items-center justify-center w-[300px] h-[200px] text-center text-white text-[17px] font-semibold font-['Montserrat'] border-[1px] border-primary-100 border-dashed"
        >
          Add photo
        </div>

        {isShowAll === false &&
          clubPhotos?.slice(0, 8)?.map((clubPhoto, index) => {
            return (
              <div className='w-[300px] h-[200px]' key={index}>
                <PhotoPreview
                  key={index}
                  src={clubPhoto?.imageURL}
                  photoId={clubPhoto?.photoId}
                  photo={clubPhoto}
                  deletePhoto={() => handleDeleteClubPhoto(clubPhoto?.photoId)}
                />
              </div>
            );
          })}

        {isShowAll === true &&
          clubPhotos?.map((clubPhoto, index) => {
            return (
              <div className='w-[300px] h-[200px]' key={index}>
                <PhotoPreview
                  key={index}
                  src={clubPhoto?.imageURL}
                  photoId={clubPhoto?.photoId}
                  photo={clubPhoto}
                  deletePhoto={() => handleDeleteClubPhoto(clubPhoto?.photoId)}
                />
              </div>
            );
          })}
      </section>
      {!isShowAll && (
        <button
          onClick={() => {
            if (clubPhotos.length > 8) {
              setIsShowAll(true);
            }
          }}
          className="mb-[112px] appearance-none rounded-[5px] flex items-center justify-center w-[100%] h-[59px] text-center text-white text-[17px] font-semibold font-['Montserrat'] border-[1px] border-[#BCBCBC] border-dashed"
        >
          Show all photos
        </button>
      )}
    </>
  );
};

export default Gallery;
