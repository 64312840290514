import { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import classNames from 'classnames';
import moment from 'moment';
import DateTimePicker from '../../../../custom/datePicker/DatePicker';

const AvailabilityPop = ({
  open,
  availabilities,
  handleClose,
  handleUpdateAvailability,
}) => {
  const closeModal = () => {
    handleClose(false);
  };

  return (
    <Popup
      open={open}
      lockScroll
      onClose={closeModal}
      closeOnDocumentClick
      contentStyle={{
        width: '100%!important',
      }}
    >
      <main
        className={`flex px-[40px] pt-[40px] pb-[36px] !flex-col bg-gradient-to-r from-[#413450] to-[#563143] min-h-[600px] !w-[600px] relative`}
      >
        <h1 className="mb-[1rem] text-primary-100 text-2xl font-semibold font-['Montserrat'] leading-[30px]">
          Working days, hours
        </h1>

        <h2 className="mb-[2.4rem]   text-zinc-400 text-sm font-medium font-['Montserrat'] leading-tight">
          This will be showcased on your public profile, allowing users to
          easily know when your club is open for business.
        </h2>

        <span className='login-popup--close' onClick={() => handleClose(false)}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='18'
            height='18'
            viewBox='0 0 18 18'
            fill='none'
          >
            <path
              d='M17.7071 1.70711C18.0976 1.31658 18.0976 0.683417 17.7071 0.292893C17.3166 -0.0976311 16.6834 -0.0976311 16.2929 0.292893L17.7071 1.70711ZM0.292893 16.2929C-0.0976311 16.6834 -0.0976311 17.3166 0.292893 17.7071C0.683417 18.0976 1.31658 18.0976 1.70711 17.7071L0.292893 16.2929ZM16.2929 17.7071C16.6834 18.0976 17.3166 18.0976 17.7071 17.7071C18.0976 17.3166 18.0976 16.6834 17.7071 16.2929L16.2929 17.7071ZM1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L1.70711 0.292893ZM16.2929 0.292893L0.292893 16.2929L1.70711 17.7071L17.7071 1.70711L16.2929 0.292893ZM17.7071 16.2929L1.70711 0.292893L0.292893 1.70711L16.2929 17.7071L17.7071 16.2929Z'
              fill='white'
            />
          </svg>
        </span>

        <section>
          <Day
            day={'Sunday'}
            handleUpdateAvailability={handleUpdateAvailability}
            availabilities={availabilities}
          />
          <Day
            day={'Monday'}
            handleUpdateAvailability={handleUpdateAvailability}
            availabilities={availabilities}
          />
          <Day
            day={'Tuesday'}
            handleUpdateAvailability={handleUpdateAvailability}
            availabilities={availabilities}
          />
          <Day
            day={'Wednesday'}
            handleUpdateAvailability={handleUpdateAvailability}
            availabilities={availabilities}
          />
          <Day
            day={'Thursday'}
            handleUpdateAvailability={handleUpdateAvailability}
            availabilities={availabilities}
          />
          <Day
            day={'Friday'}
            handleUpdateAvailability={handleUpdateAvailability}
            availabilities={availabilities}
          />
          <Day
            day={'Saturday'}
            handleUpdateAvailability={handleUpdateAvailability}
            availabilities={availabilities}
          />
        </section>
      </main>
    </Popup>
  );
};

const Day = ({ handleUpdateAvailability, availabilities, day = '' }) => {
  const [time, setTime] = useState({});

  const handleSetTime = () => {
    const _time =
      availabilities.find((availability) => {
        if (availability?.dayName) {
          return availability?.dayName.toLowerCase() === day.toLowerCase();
        }
      }) ?? {};

    if (Object.keys(_time).length > 0) {
      setTime({ ..._time });
    }
  };

  useEffect(() => {
    handleSetTime();
  }, []);

  useEffect(() => {
    handleSetTime();
  }, [availabilities, day]);

  return (
    <>
      {Object.keys(time).length > 0 ? (
        <TimeViewer
          handleUpdateAvailability={handleUpdateAvailability}
          day={time?.dayName}
          from={time?.startTime}
          to={time?.endTime}
          isClubOpenOn={time?.available}
        />
      ) : (
        <TimeViewer
          day={day}
          handleUpdateAvailability={handleUpdateAvailability}
          isClubOpenOn={false}
          from={moment().format('h:mm a')}
          to={moment().format('h:mm a')}
        />
      )}
    </>
  );
};

const TimeViewer = ({
  day = '',
  from,
  to,
  handleUpdateAvailability,
  isClubOpenOn,
}) => {
  const [isOpen, setIsOpen] = useState(isClubOpenOn ? isClubOpenOn : false);
  const [currentTime, setCurrentTime] = useState({
    to: to,
    from: from,
  });

  const handleToTimeOnChange = (t) => {
    const _to = moment(t).format('h:mm a');
    setCurrentTime((prev) => {
      return {
        ...prev,
        to: _to,
      };
    });

    const payload = {
      from: currentTime.from,
      to: _to,
      isOpen: isOpen,
      day,
    };

    if (handleUpdateAvailability) {
      handleUpdateAvailability(payload);
    }
  };

  const handleFromTimeOnChange = (t) => {
                                          const _from = moment(t).format(
                                            'h:mm a'
                                          );

                                          setCurrentTime((prev) => {
                                            return {
                                              ...prev,
                                              from: _from,
                                            };
                                          });

                                          const payload = {
                                            from: _from,
                                            to: currentTime.to,
                                            isOpen: isOpen,
                                            day,
                                          };

                                          if (handleUpdateAvailability) {
                                            handleUpdateAvailability(payload);
                                          }
                                        };

  const handleSave = (day, open) => {
    const payload = {
      ...currentTime,
      isOpen: open,
      day,
    };

    if (handleUpdateAvailability) {
      handleUpdateAvailability(payload);
    }
  };

  useEffect(() => {
    setCurrentTime((prev) => {
      return {
        ...prev,
        to,
        from,
      };
    });
  }, [from, to]);

  useEffect(() => {
    setIsOpen(isClubOpenOn);
  }, [isClubOpenOn]);

  return (
    <main className='mb-[2rem] flex items-center gap-[1rem] justify-between w-[100%]'>
      <section className='flex items-center gap-[12px]'>
        <input
          onChange={(e) => {
            setIsOpen(e.target.checked);
            handleSave(day, e.target.checked);
          }}
          checked={isOpen}
          type='checkbox'
          className='w-[18px] h-[18px]  rounded-sm border-2 border-primary-100  before:content-["\2713"] before:text-primary-100  before:text-[1.6rem] before:opacity-[0] cursor-pointer checked:before:opacity-[1] appearance-none flex items-center justify-center'
        />
        <span className="text-neutral-300 text-[14px] font-medium font-['Montserrat'] leading-tight">
          {day}
        </span>
      </section>
      <section className='flex gap-[2rem]'>
        <DateTimePicker
          onChange={handleFromTimeOnChange}
          showTimeSelect
          showTimeSelectOnly
          dateFormat='h:mm aa'
          renderElement={({ onClick, value }) => {
            return (
              <div
                className='bg-[#333] flex items-center !w-[150px] h-[38px] justify-between cursor-pointer'
                onClick={onClick}
              >
                <span className='flex items-center justify-center  text-white text-[18px] font-[Montserrat] font-[normal] font-[600] leading-[100%] w-[80%]'>
                  {!currentTime.from
                    ? value.split(' ')[0]
                    : currentTime?.from?.split(' ')[0]}
                </span>
                <div className='flex w-[max-content]'>
                  <span
                    className={classNames(
                      'flex items-center justify-center w-[38px] h-[38px] bg-[#442734]',
                      (currentTime?.from?.split(' ')[1]?.toLowerCase() ===
                        'pm' ||
                        value?.split(' ')[1]?.toLowerCase() === 'pm') &&
                        'bg-[#936fe0]'
                    )}
                  >
                    PM
                  </span>
                  <span
                    className={classNames(
                      'flex items-center justify-center w-[38px] h-[38px] bg-[#442734]',
                      (currentTime?.from?.split(' ')[1]?.toLowerCase() ===
                        'am' ||
                        value?.split(' ')[1]?.toLowerCase() === 'am') &&
                        'bg-[#936fe0]'
                    )}
                  >
                    AM
                  </span>
                </div>
              </div>
            );
          }}
        />
        <DateTimePicker
          onChange={handleToTimeOnChange}
          showTimeSelect
          showTimeSelectOnly
          dateFormat='h:mm aa'
          renderElement={({ onClick, value }) => {
            return (
              <div
                className='bg-[#333] flex items-center !w-[150px] h-[38px] justify-between cursor-pointer'
                onClick={onClick}
              >
                <span className='flex items-center justify-center  text-white text-[18px] font-[Montserrat] font-[normal] font-[600] leading-[100%] w-[80%]'>
                  {!currentTime.to
                    ? value.split(' ')[0]
                    : currentTime?.to?.split(' ')[0]}
                </span>
                <div className='flex w-[max-content]'>
                  <span
                    className={classNames(
                      'flex items-center justify-center w-[38px] h-[38px] bg-[#442734]',
                      (currentTime?.to?.split(' ')[1]?.toLowerCase() === 'pm' ||
                        value?.split(' ')[1]?.toLowerCase() === 'pm') &&
                        'bg-[#936fe0]'
                    )}
                  >
                    PM
                  </span>
                  <span
                    className={classNames(
                      'flex items-center justify-center w-[38px] h-[38px] bg-[#442734]',
                      (currentTime?.to?.split(' ')[1]?.toLowerCase() === 'am' ||
                        value?.split(' ')[1]?.toLowerCase() === 'am') &&
                        'bg-[#936fe0]'
                    )}
                  >
                    AM
                  </span>
                </div>
              </div>
            );
          }}
        />
      </section>
    </main>
  );
};;;;;

export default AvailabilityPop;
