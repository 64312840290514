import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useQuery } from 'react-query';
import FluidContainer from '../../custom/fluidContainer/FluidContainer';
import styles from './styles.module.scss';
import ModelProfileHeader from '../../custom/modelProfileHeader/ModelProfileHeader';
import { withRole } from '../../hoc/withRole';
import SelectAll from '../../custom/selectAll/SelectAll';
import Button from '../../custom/btn/Btn';
import AddButton from '../../custom/addButton/AddButton';
import { ReactComponent as CreateAlbumIcon } from '../../images/svg/albumIcon.svg';
import { ReactComponent as PermissionIcon } from '../../images/svg/permission.svg';
import { ReactComponent as DeleteIcon } from '../../images/svg/delete.svg';
import ProductUploadPopup from '../../custom/productUpload/ProductUpload';
import ProductDetail from './productDetail/ProductDetail';
import StoreCard from './storeCard/StoreCard';

import { getModelPhotos } from '../../request/model';

const ModelFanClubStore = () => {
  const clientId = useSelector((state) => state?.user?.clientId);
  const [isOpenUploadPhotoModal, setIsOpenUploadPhotoModal] = useState(false);
  const [selectedPhotoIds, setSelectedPhotoIds] = useState([]);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [product, setProduct] = useState([]);
  const [openProductModal, setOpenProductModal] = useState(false);

  const { data, error, isLoading } = useQuery({
    queryFn: () => getModelPhotos(clientId),
    queryKey: 'model-photos',
    refetchOnWindowFocus: false,
    retry: 0,
  });

  const handleClose = () => {
    setIsOpenUploadPhotoModal(false);
  };

  const handleOpenUploadModal = () => {
    setIsOpenUploadPhotoModal(true);
  };

  const handlePhotoSelection = (photoId) => {
    const id = selectedPhotoIds.find((id) => id === photoId);
    if (id) {
      const filteredPhotoIds = selectedPhotoIds.filter((id) => id !== photoId);
      setSelectedPhotoIds(filteredPhotoIds);
    } else {
      setSelectedPhotoIds((prev) => [...prev, photoId]);
    }
  };

  const handleSelectAll = (e) => {
    const { checked } = e.target;
    if (!data) return;

    if (checked) {
      const selectedIds = data?.map((photo) => photo?.photoId);
      setSelectedPhotoIds(selectedIds);
    } else {
      setSelectedPhotoIds([]);
    }

    setIsSelectedAll(checked);
  };

  const handleIsSelected = (photoId) => {
    const id = selectedPhotoIds.find((id) => id === photoId);
    return id ? true : false;
  };

  const handleProductDetails = () => {
    setProduct(() => {
      return [...product, []];
    });
    setOpenProductModal(false);
  };

  const handleProductDetailsClose = () => {
    setOpenProductModal(false);
  };

  const handleConfirmProduct = () => {
    handleClose();
    setOpenProductModal(true);
  };

  return (
    <FluidContainer>
      <ModelProfileHeader className={styles.header} />
      <h1 className={styles.headerTitle}>
        <h5 className={styles.subTitle}>FAN CLUB</h5>
        <h5 className={styles.title}>
          Store <span className={styles.tip}>2</span>
        </h5>
      </h1>
      <section className={styles.navContainer}>
        <SelectAll
          labelText='Select All Products'
          checked={isSelectedAll}
          onChange={handleSelectAll}
        />
        <nav className={styles.nav}>
          <Button
            className={styles.addProductButton}
            text='Add Product'
            icon={CreateAlbumIcon}
            onClick={handleOpenUploadModal}
          />
          <Button
            text='Permitions'
            icon={PermissionIcon}
            className={styles.addPermissionButton}
          />
          <Button
            text='Delete'
            icon={DeleteIcon}
            className={styles.addDeleteButton}
          />
        </nav>
      </section>
      {product.length === 0 && (
        <section className={styles.uploadActionContainer}>
          <h1 className={styles.uploadTitle}>
            You haven't added any products to your store yet
          </h1>
          <AddButton
            text='Add Photo'
            onClick={handleOpenUploadModal}
            className={styles.addButton}
          />
          <p className={styles.uploadSubTitle}>
            You can sell all types of digital products to your fans on your
            store from docs to stickers and archives and everything in-between.
            Support for sales of physical products coming soon.{' '}
          </p>
        </section>
      )}
      {product.length > 0 && (
        <section className={styles.storeItemContainer}>
          <StoreCard />
        </section>
      )}
      <ProductUploadPopup
        handleConfirmProduct={handleConfirmProduct}
        open={isOpenUploadPhotoModal}
        handleClose={handleClose}
      />
      <ProductDetail
        handleProductDetailsClose={handleProductDetailsClose}
        open={openProductModal}
        handleProductDetails={handleProductDetails}
      />
    </FluidContainer>
  );
};

export default withRole(ModelFanClubStore);
