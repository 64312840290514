import { useState } from 'react';
import { useMutation } from 'react-query';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Popup from 'reactjs-popup';
import styles from './uploadPop.module.scss';
import classname from 'classnames';
import notification from '../../../utils/notification';
import errorFormmatter from '../../../utils/errorFormatter';
import { useSelector } from 'react-redux';

const UploadPopup = ({ open, handleClose }) => {
	const clubId = useSelector(state => state?.user?.clientId);
	const [file, setFile] = useState('');
	const [image, setImage] = useState('');
	const history = useHistory();

	const verifyClub = data => axios.post('/ClubProfile/AddVerificationClub', data);
	const verifyImageMutation = useMutation('verifyClub', verifyClub, { retry: 0 });

	const handleVerifyModel = async () => {
		try {
			// notify the user to upload image for verification
			if (!file) throw new Error('Please upload an image for verification');

			const formData = new FormData();
			formData.append('FileUpload', file);
			formData.append('ClubId', clubId);

			await verifyImageMutation.mutateAsync(formData, {
				onSuccess: data => {
					notification({
						title: 'Club Verification',
						message: data?.data?.message,
						type: 'success',
					});

					setFile(null);
					setImage('');
					//redirect to the user to the profile
					history.push('/unverified-model');
				},
			});
		} catch (e) {
			notification({
				title: 'Registration Error',
				message: errorFormmatter(e),
				type: 'danger',
			});
		}
	};

	const handleChange = e => {
		const file = e?.target?.files[0];
		if (!file) return;
		setFile(file);
		hangleImagePreview(file);
	};

	const hangleImagePreview = file => {
		const imgUrl = URL.createObjectURL(file);
		setImage(imgUrl);
	};

	const handleDrop = e => {
		e.preventDefault();

		setFile(e.dataTransfer.files[0]);

		// const imageRegex = /image(?=\/jpeg|\/png|\/jpg)/;
		const dropedImage = e.dataTransfer.files[0];
		// const image = dropedImage.type.match(imageRegex);

		if (dropedImage) {
			hangleImagePreview(dropedImage);
		}

		return;
	};

	const handleDragOver = e => {
		e.preventDefault();
		e.stopPropagation();
	};

	const closeModal = () => {
		setFile(null);
		setImage(null);
		handleClose(false);
	};

	return (
		<Popup open={open} lockScroll className='photoLoad-popup' onClose={closeModal}>
			<span className='login-popup--close' onClick={() => handleClose(false)}>
				<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'>
					<path
						d='M17.7071 1.70711C18.0976 1.31658 18.0976 0.683417 17.7071 0.292893C17.3166 -0.0976311 16.6834 -0.0976311 16.2929 0.292893L17.7071 1.70711ZM0.292893 16.2929C-0.0976311 16.6834 -0.0976311 17.3166 0.292893 17.7071C0.683417 18.0976 1.31658 18.0976 1.70711 17.7071L0.292893 16.2929ZM16.2929 17.7071C16.6834 18.0976 17.3166 18.0976 17.7071 17.7071C18.0976 17.3166 18.0976 16.6834 17.7071 16.2929L16.2929 17.7071ZM1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L1.70711 0.292893ZM16.2929 0.292893L0.292893 16.2929L1.70711 17.7071L17.7071 1.70711L16.2929 0.292893ZM17.7071 16.2929L1.70711 0.292893L0.292893 1.70711L16.2929 17.7071L17.7071 16.2929Z'
						fill='white'
					/>
				</svg>
			</span>

			<div className='photoLoad-popup--wrapper' onDragOver={handleDragOver} onDrop={handleDrop}>
				<div className='photoLoad-popup--left'>
					{!file && (
						<div className='photoLoad-popup--title'>
							You have not uploaded <br />
							any photos yet
						</div>
					)}
					<div className='photoLoad-popup--img'></div>
					<div id='output' className={styles.output}>
						{image && <img src={image} alt='image preview' className={styles.imagePreview} />}
					</div>
					<div className='photoLoad-popup--btnWrapper'>
						<label className={classname('btn btn__red photoLoad-popup--btn', styles.label)}>
							<input
								type='file'
								name='myFile'
								onChange={handleChange}
								id='myFile'
								accept='image/*'
								className={styles.fileUpload}
							/>
							Upload from computer
						</label>
						<button
							className={classname(
								'btn btn__red send-js photoLoad-popup--btn',
								!file || verifyImageMutation.isLoading ? 'photoLoad-popup--btn__disabled' : ''
							)}
							onClick={handleVerifyModel}
							disabled={verifyImageMutation.isLoading}
						>
							{verifyImageMutation.isLoading ? 'Confirming upload...' : 'Confirm upload'}
						</button>
					</div>
				</div>
				<div className='photoLoad-popup--right'>
					<label className='dropzone'>
						<input name='myFile' type='file' onChange={handleChange} id='myFile' accept='image/*' />
					</label>
				</div>
			</div>
		</Popup>
	);
};

export default UploadPopup;
