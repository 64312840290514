import CheckBox from '../../../custom/checkbox/CheckBox';
import notification from '../../../utils/notification';
import {
  getStreamingConfig,
  updateStreamingBroadcastSetting,
  updateStreamingBroadcastConfig,
} from '../../../request/model';
import Input from '../../../custom/input/Input';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

const VideoSetting = () => {
  const [loading, setLoading] = useState(false);
  const [videoSetting, setVideoSetting] = useState({
    tipVolume: 0,
    exitVolume: 0,
    entryVolume: 0,
    allowPrivateChat: true,
    allowGeneralChat: true,
    allowMessage: true,
    allowGuest: true,
    allowMicOff: true,
    allowCamOn: true,
    allowTips: true,
  });
  const user = useSelector((state) => state?.user);

  const handleGetSetting = async () => {
    try {
      const response = await getStreamingConfig({ modelId: user.clientId });

      setVideoSetting((prev) => {
        return {
          ...prev,
          ...response,
          allowShowRecording: response?.allowShowRecording,
          tipVolume: response?.tipVolume,
          exitVolume: response?.exitVolume,
          entryVolume: response?.entryVolume,
        };
      });
    } catch (error) {}
  };

  const updateStreamingVolumeSetting = async (name, value) => {
    try {
      setLoading(true);
      const payload = new FormData();

      payload.append(
        'allowMicOff',
        name === 'allowMicOff' ? value : videoSetting.allowMicOff
      );
      payload.append(
        'allowCamOn',
        name === 'allowCamOn' ? value : videoSetting.allowCamOn
      );

      payload.append('tipVolume', videoSetting.tipVolume);
      payload.append('exitVolume', videoSetting.exitVolume);
      payload.append('entryVolume', videoSetting.entryVolume);
      payload.append('allowPrivateChat', videoSetting.allowPrivateChat);
      payload.append('allowGeneralChat', videoSetting.allowGeneralChat);
      payload.append('allowMessage', videoSetting.allowMessage);
      payload.append('allowGuest', videoSetting.allowGuest);
      payload.append('allowTips', videoSetting.allowTips);
      payload.append('registerId', user?.clientId);

      await updateStreamingBroadcastConfig({
        payload,
      });

      setLoading(false);
      notification({
        title: 'Video settings',
        message: `Successfully updated video setting`,
        type: 'success',
      });
    } catch (error) {
      setLoading(false);
      notification({
        title: 'Video settings',
        message: `Video setting failed to update`,
        type: 'danger',
      });
    }
  };

  const onChange = async (event) => {
    try {
      const name = event.target.name;
      const value = event.target.checked;

      setVideoSetting((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });

      await updateStreamingVolumeSetting(name, value);
    } catch (error) {}
  };

  useEffect(() => {
    handleGetSetting();
  }, []);

  useEffect(() => {
    handleGetSetting();
  }, [loading]);

  return (
    <section key={2} className='max-w-[300px]'>
      <section>
        <h1 className="mb-[1rem] text-neutral-300 text-[17px] font-semibold font-['Montserrat'] leading-[17px] tracking-tight">
          User video
        </h1>
        <div className={'flex w-[100%] mb-[24px] justify-between'}>
          <div className={'flex gap-[1rem] '}>
            <PlayIcon /> <span>User cam</span>
          </div>
          <CheckBox
            onChange={onChange}
            checked={videoSetting.allowCamOn}
            name='allowCamOn'
            checkedColor={'#775E96'}
            className={'!w-[10px]  bg-primary-500'}
          />
        </div>
        <div className={'flex w-[100%] mb-[24px] justify-between'}>
          <div className={'flex gap-[1rem] '}>
            <PlayIcon /> <span>User mic</span>
          </div>
          <CheckBox
            onChange={onChange}
            checked={videoSetting.allowMicOff}
            name='allowMicOff'
            checkedColor={'#775E96'}
            className={'!w-[10px]  bg-primary-500'}
          />
        </div>
      </section>
    </section>
  );
};

const PlayIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <circle opacity='0.15' cx='12' cy='12' r='12' fill='white' />
      <path d='M19 12L8.5 18.0622L8.5 5.93782L19 12Z' fill='#B87693' />
    </svg>
  );
};

export default VideoSetting;
