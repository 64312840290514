import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Popup from 'reactjs-popup';

const LkEditMail = () => {
	const email = useSelector(state => state?.user?.email)?.toUpperCase();
	const [open, setOpen] = useState(false);
	const closeModal = () => setOpen(false);

	return (
		<div className='reg-data-input'>
			<input placeholder='Email' type='text' name='email' disabled value={email} />
			<span className='reg-data-input--placeholder'>Email</span>
			<div className='reg-data-input--edit'>{/* <span onClick={this.openModal}>Edit e-mail</span> */}</div>
			<Popup open={open} closeOnDocumentClick lockScroll onClose={closeModal}>
				<div className='editInfo-popup'>
					<span className='login-popup--close' onClick={closeModal}>
						<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'>
							<path
								d='M17.7071 1.70711C18.0976 1.31658 18.0976 0.683417 17.7071 0.292893C17.3166 -0.0976311 16.6834 -0.0976311 16.2929 0.292893L17.7071 1.70711ZM0.292893 16.2929C-0.0976311 16.6834 -0.0976311 17.3166 0.292893 17.7071C0.683417 18.0976 1.31658 18.0976 1.70711 17.7071L0.292893 16.2929ZM16.2929 17.7071C16.6834 18.0976 17.3166 18.0976 17.7071 17.7071C18.0976 17.3166 18.0976 16.6834 17.7071 16.2929L16.2929 17.7071ZM1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L1.70711 0.292893ZM16.2929 0.292893L0.292893 16.2929L1.70711 17.7071L17.7071 1.70711L16.2929 0.292893ZM17.7071 16.2929L1.70711 0.292893L0.292893 1.70711L16.2929 17.7071L17.7071 16.2929Z'
								fill='white'
							/>
						</svg>
					</span>
					<div className='editInfo-popup--content'>
						<div className='editInfo-popup--title'>Сhange e-mail</div>
						<form className='editInfo-popup--form'>
							<div className='reg-data-input'>
								<input type='text' name='email' defaultValue='Al***ss@gmail.com' />
								<span className='reg-data-input--placeholder'>Email</span>
							</div>
							<div className='reg-data-input'>
								<input type='text' name='new-E-mail' />
								<span className='reg-data-input--placeholder'>New E-mail</span>
							</div>
							<div className='editInfo-popup--warning'>
								<span className='editInfo-popup--warning-img' />
								<span className='editInfo-popup--warning-info'>
									A confirmation email will be sent to your new email address
								</span>
							</div>
							<button className='btn btn__red editInfo-popup--btn'>Save address</button>
						</form>
					</div>
				</div>
			</Popup>
		</div>
	);
};
export default LkEditMail;








/* <<<<<<< HEAD
import React, { Component } from 'react';
import {Link} from "react-router-dom";
import Popup from "reactjs-popup";

class LkEditMail extends Component {
    constructor(props) {
        super(props)
        this.state = { open: false }
        this.openModal = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
    }
    openModal (){
        this.setState({ open: true })
    }
    closeModal () {
        this.setState({ open: false })
    }
    render() {
        return (
            
            <div className="reg-data-input">
            <input placeholder="" type="text" name="email" disabled defaultValue="Alloress@example.com"/>
            <span className="reg-data-input--placeholder">Email</span>
            <div className="reg-data-input--edit">
                <span onClick={this.openModal}>Edit e-mail</span>
            </div>
            <Popup open={this.state.open} closeOnDocumentClick lockScroll onClose={this.closeModal}>
                <div className="editInfo-popup">
                    <span className="login-popup--close" onClick={this.closeModal}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path d="M17.7071 1.70711C18.0976 1.31658 18.0976 0.683417 17.7071 0.292893C17.3166 -0.0976311 16.6834 -0.0976311 16.2929 0.292893L17.7071 1.70711ZM0.292893 16.2929C-0.0976311 16.6834 -0.0976311 17.3166 0.292893 17.7071C0.683417 18.0976 1.31658 18.0976 1.70711 17.7071L0.292893 16.2929ZM16.2929 17.7071C16.6834 18.0976 17.3166 18.0976 17.7071 17.7071C18.0976 17.3166 18.0976 16.6834 17.7071 16.2929L16.2929 17.7071ZM1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L1.70711 0.292893ZM16.2929 0.292893L0.292893 16.2929L1.70711 17.7071L17.7071 1.70711L16.2929 0.292893ZM17.7071 16.2929L1.70711 0.292893L0.292893 1.70711L16.2929 17.7071L17.7071 16.2929Z" fill="white"/>
                        </svg>
                    </span>
                    <div className="editInfo-popup--content">
                        <div className="editInfo-popup--title">Сhange e-mail</div>
                        <form className="editInfo-popup--form">
                            <div className="reg-data-input">
                                <input placeholder="" type="text" name="email" defaultValue="Al***ss@gmail.com"/>
                                <span className="reg-data-input--placeholder">Email</span>
                            </div>
                            <div className="reg-data-input">
                                <input placeholder="" type="text" name="new-E-mail"/>
                                <span className="reg-data-input--placeholder">New E-mail</span>
                            </div>
                            <div className="editInfo-popup--warning">
                                <span className="editInfo-popup--warning-img"></span>
                                <span className="editInfo-popup--warning-info">
                                    A confirmation email will be sent to your new email address
                                </span>
                            </div>
                            <button className="btn btn__red editInfo-popup--btn">Save address</button>
                        </form>
                    </div>
                </div>
            </Popup>
        </div>
        );
    }
}
export default LkEditMail;
=======
>>>>>>> 59d03e77e78a8be035b39ba65f4989f1c2ae5150 */
