import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import notification from '../../utils/notification';

export const getModelProfileStatus = createAsyncThunk('modelProfileStatus', async modelId => {
	try {
		const { data } = await axios.get(`/ModelsProfile/${modelId}/GetModelVerificationStatus`);
		return data?.data;
	} catch (error) {
		const errorMessage = error?.response?.data?.message ?? error?.message;
		throw new Error(errorMessage);
	}
});

export const getModelOnlineStatus = createAsyncThunk('modelOnlineStatus', async modelId => {
	try {
		const { data } = await axios.get(`/ModelsProfile/${modelId}/GetOnlineAsync`);

		return data?.data;
	} catch (error) {
		const errorMessage = error?.response?.data?.message ?? error?.message;
		throw new Error(errorMessage);
	}
});

export const updateModelOnlineStatus = createAsyncThunk('updateModelOnlineStatus', async payload => {
	try {
		const { data } = await axios.put(`/ModelsProfile/UpdateOnlineModel`, payload);

		return data?.data;
	} catch (error) {
		const errorMessage = error?.response?.data?.message ?? error?.message;
		throw new Error(errorMessage);
	}
});

const modelSlice = createSlice({
	name: 'model',
	initialState: {
		isPhotoUploaded: false,
		isVerified: false,
		isProfileCompleted: false,
		isOnline: false,
		loading: false,
		onlineStatusLoading: false,
		error: null,
	},

	reducers: {
		updateOnlineStatus: (state, action) => {
			state.online = action.payload;
		},
	},
	extraReducers: builder => {
		builder.addCase(getModelProfileStatus.fulfilled, (state, action) => {
			const { isCompletedProfile, isVerified, hasUploadVerify } = action?.payload;
			state.isPhotoUploaded = hasUploadVerify;
			state.isVerified = isVerified;
			state.isProfileCompleted = isCompletedProfile;
			state.loading = false;
		});

		builder.addCase(getModelProfileStatus.pending, state => {
			state.loading = true;
		});

		builder.addCase(getModelProfileStatus.rejected, (state, action) => {
			const errorMessage = action?.error?.message;
			state.loading = false;
			state.error = errorMessage;

			notification({
				title: 'Model Profile Status Error',
				message: errorMessage,
				type: 'danger',
			});
		});

		builder.addCase(getModelOnlineStatus.fulfilled, (state, action) => {
			state.onlineStatusLoading = false;
			state.isOnline = action?.payload?.online;
			state.error = null;
		});

		builder.addCase(getModelOnlineStatus.pending, state => {
			state.error = null;
			state.onlineStatusLoading = true;
		});

		builder.addCase(getModelOnlineStatus.rejected, (state, action) => {
			const errorMessage = action?.error?.message;
			state.onlineStatusLoading = false;
			state.error = errorMessage;
		});

		builder.addCase(updateModelOnlineStatus.fulfilled, (state, action) => {
			state.onlineStatusLoading = false;
			state.isOnline = action?.payload?.online;
			state.error = null;
		});

		builder.addCase(updateModelOnlineStatus.pending, state => {
			state.error = null;
			state.onlineStatusLoading = true;
		});

		builder.addCase(updateModelOnlineStatus.rejected, (state, action) => {
			const errorMessage = action?.error?.message;
			state.onlineStatusLoading = false;
			state.error = errorMessage;
		});
	},
});

export default modelSlice.reducer;
