import FluidContainer from '../../../custom/fluidContainer/FluidContainer';
import { Link } from 'react-router-dom';
import { FiPlus } from 'react-icons/fi';
import ClubAvailability from './clubavailability/ClueAvailability';
import {
  getClubDetails,
  updateClubDetails,
  updateClubLogo,
  deleteClubPhoto,
  updateClubCover,
} from '../../../request/club';
import Gallery from './gallery/Gallery';
import { useEffect, useState } from 'react';
import moment from 'moment';
import FileUpload from '../../../custom/fileUpload/FileUpload';
import errorFormmatter from '../../../utils/errorFormatter';
import { postClubPhotos } from '../../../request/club';
import notification from '../../../utils/notification';
import SideAnalysis from './SideAnalysis';
import { ReactComponent as PencilEditIcon } from '../../../svgs/pencilEdit.svg';
import { ReactComponent as CurrentPlanIcon } from '../../../svgs/current-plan.svg';
import { MdOutlineModeEdit } from 'react-icons/md';
import FeedUploadPop from './addFeed/FeedUploadPop';
import Switch from '../../../custom/switch/Switch';
import GalleryUploadPop from './gallery/GalleryUploadPop';
import PostFeedPopUp from './addFeed/PostFeedPopUp';

const clubId = 'b002d30c-ef07-4462-8489-2c9d4568da3a';

const ClubProfile = () => {
  const [currentOpenDayTime, setCurrentOpenDayTime] = useState({});
  const [clubDetails, setClubDetails] = useState({});
  const [openAddFeedPop, setOpenAddFeedPop] = useState(false);
  const [openConfirmFeedPop, setOpenConfirmFeedPop] = useState(false);
  const [currentFeedFiles, setCurrentFeedFiles] = useState([]);
  const [clubPhotos, setClubPhotos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openGalleryPop, setOpenGalleryPop] = useState(false);

  const handleConfirmFeedPhotos = (files) => {
    setCurrentFeedFiles(files);
    setOpenAddFeedPop(false);
    setOpenConfirmFeedPop(true);
  };

  const handleDeleteClubPhoto = async (photoId) => {
    try {
      setLoading(true);

      await deleteClubPhoto({
        clubId,
        photoId,
      });
      notification({
        title: 'Club photos',
        message: 'Successfully deleted club photo',
        type: 'success',
      });
      setLoading(false);
    } catch (error) {
      notification({
        title: 'Club photos',
        message: errorFormmatter(error),
        type: 'danger',
      });
      setLoading(false);
    }
  };

  const handleConfirmGalleryPhotos = async (files) => {
    try {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const payload = new FormData();
        setLoading(true);
        payload.append('addImage', file?.file);
        payload.append('isHidden', false);
        payload.append('isDeleted', false);
        payload.append('registeredId', clubId);
        payload.append('description', file?.description);
        payload.append('photoName', file?.fileName);

        const response = await postClubPhotos({ payload });
        setLoading(false);
        setOpenGalleryPop(false);
        // notification({
        //   title: 'Club photos',
        //   message: response?.message,
        //   type: 'success',
        // });
      }
    } catch (error) {
      setLoading(false);
      notification({
        title: 'Club photos',
        message: errorFormmatter(error),
        type: 'danger',
      });
    }
  };

  const handleGetClubDetails = async () => {
    try {
      const response = await getClubDetails({ clubId });
      handleSetCurrentDayOpenTime(response?.timeClubAvailables ?? []);
      setClubPhotos(response?.clubPhotos ?? []);
      setClubDetails(response);
    } catch (error) {}
  };

  const handleSetCurrentDayOpenTime = (availabilities = []) => {
    try {
      const day = moment().format('dddd');

      const _currentOpenDayTime = availabilities.find((availability) => {
        return availability?.dayName.toLowerCase() === day.toLocaleLowerCase();
      });

      setCurrentOpenDayTime(_currentOpenDayTime);
    } catch (error) {}
  };

  const handleClubDetailsUpdate = async () => {
    try {
      setLoading(true);
      const payload = new FormData();
      payload.append('clubName', clubDetails?.clubName);
      payload.append('description', clubDetails?.description);
      payload.append('street', clubDetails?.street);
      payload.append('clubId', clubId);
      payload.append('registerId', clubId);
      payload.append('site', clubDetails?.site);
      payload.append('phone', clubDetails?.phone);
      payload.append('homePhone', clubDetails?.homePhone);
      payload.append('appartment', clubDetails?.appartment);
      payload.append('countryId', clubDetails?.countryId);
      // payload.append('logoTypeUrl', clubDetails?.logoTypeUrl);
      // payload.append('coverURL', clubDetails?.coverURL);
      payload.append('address', clubDetails?.address);
      payload.append('zipPostal', clubDetails?.zipPostal);
      payload.append('logoTypeImage', clubDetails?.clubLogoPreview ?? '');
      payload.append('coverImage', clubDetails?.clubCoverPreview ?? '');
      payload.append('clubStatus', clubDetails?.clubStatus ?? '');

      await updateClubDetails({
        clubId,
        payload,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleClubOpen = (event) => {
    const value = event.target.checked;

    setClubDetails((prev) => {
      return {
        ...prev,
        clubStatus: value,
      };
    });

    handleClubDetailsUpdate();
  };

  const handleLogoOnChange = async (file) => {
    try {
      if (!file) return;
      const imgUrl = URL.createObjectURL(file);
      setClubDetails((prev) => {
        return {
          ...prev,
          clubLogoPreview: imgUrl,
        };
      });

      setLoading(true);

      const payload = new FormData();
      payload.append('clubId', clubId);
      payload.append('fileUpload', file);

      await updateClubLogo({
        clubId,
        payload,
      });

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleCoverOnChange = async (file) => {
    try {
      if (!file) return;
      const imgUrl = URL.createObjectURL(file);
      setClubDetails((prev) => {
        return {
          ...prev,
          clubCoverPreview: imgUrl,
        };
      });

      setLoading(true);

      const payload = new FormData();
      payload.append('clubId', clubId);
      payload.append('fileUpload', file);

      await updateClubCover({
        clubId,
        payload,
      });

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetClubDetails();
  }, []);

  useEffect(() => {
    handleGetClubDetails();
  }, [loading]);

  return (
    <FluidContainer>
      <section className='flex mb-[42px] mt-[60px] justify-between items-center w-[100%]'>
        <h1 className="text-primary-100 text-4xl font-semibold font-['Montserrat']">
          Personal area
        </h1>
        <div className='flex gap-[1rem]'>
          <button
            onClick={() => setOpenAddFeedPop(true)}
            className='flex w-[147px] h-9 px-4 py-1.5  bg-gradient-to-r from-[#413450] to-[#563143] rounded-sm  justify-center items-center '
          >
            <FiPlus className='text-white text-[1.3rem]' />
            <span className="opacity-90 text-center text-white text-sm font-medium font-['Montserrat'] leading-tight">
              Add to Feed
            </span>
          </button>
          <button className='flex w-[147px] h-9 px-4 py-1.5 bg-black border-primary-100 border-[1px] rounded-sm flex-col justify-center items-center gap-2.5 inline-flex'>
            <span className="opacity-90 text-center text-white text-sm font-medium font-['Montserrat'] leading-tight">
              Edit profile
            </span>
          </button>
        </div>
      </section>
      <section
        className={
          "mb-[72px] w-[100%] [grid-column-gap:24px] [grid-row-gap:12px] [grid-template-areas:'main_availability_revenue''main_availability_analysis'] [display:grid] min-h-[500px] [grid-template-columns:1fr_237px_237px] [grid-template-rows:auto_1fr_auto]"
        }
      >
        <section className='[grid-area:main]  h-[100%]  relative z-[0] brightness-[0.9] '>
          <section className=' absolute top-0  min-h-[100%]  left-0 right-0 z-[999]   bg-gradient-to-r from-[#413450] to-[#563143]'>
            <section className='h-[190px] relative'>
              <div className='w-[max-content] justify-end items-center gap-4 inline-flex absolute right-[12px] top-[12px] w-[max-content]'>
                <Link
                  to={`/clubs-detail?clubId=${clubId}`}
                  className="w-[max-content] h-[28px] px-3 py-1.5 opacity-80 bg-white rounded-sm border border-white justify-start items-center gap-1 inline-flex text-center text-stone-700 text-sm font-semibold font-['Montserrat'] leading-none"
                >
                  View Public Profile{' '}
                </Link>

                <FileUpload
                  handleFileChange={handleCoverOnChange}
                  renderElement={
                    <button className="w-[81px] h-[28px] px-3 py-1.5 opacity-80 rounded-sm border-[1px] border-[#BCBCBC] justify-start items-center gap-1 inline-flex text-center text-white text-sm font-normal font-['Montserrat'] leading-none">
                      Edit
                      <MdOutlineModeEdit className='text-white text-[1.3rem] cursor-pointer' />
                    </button>
                  }
                />
              </div>

              <img
                className='w-[100%]  h-[100%] object-cover'
                src={
                  clubDetails?.clubCoverPreview
                    ? clubDetails?.clubCoverPreview
                    : clubDetails?.coverURL
                }
              />
              <div className='w-[100px] h-[100px] left-[64px] top-[70%] absolute'>
                <img
                  className='w-[80px] h-[80px] rounded-[5px] '
                  src={
                    clubDetails?.clubLogoPreview
                      ? clubDetails?.clubLogoPreview
                      : clubDetails?.clubImageURL
                  }
                />
                <div className='w-[26px] h-[26px] bg-white bottom-[15%] right-[5%] absolute flex items-center justify-center cursor-pointer'>
                  <FileUpload
                    handleFileChange={handleLogoOnChange}
                    renderElement={
                      <PencilEditIcon className='cursor-pointer' />
                    }
                  />
                </div>
              </div>
            </section>
            <section className='p-[32px]'>
              <section className='border-[0.8px] border-primary-100  rounded-[5px] pl-[31px] pb-[31px] pr-[31px]'>
                <section className='mb-[39px] flex items-center justify-between w-[100%]'>
                  <h1 className="text-white text-4xl font-semibold font-['Montserrat'] leading-[50.40px]">
                    {clubDetails?.clubName}
                  </h1>
                  <div className='flex items-center gap-[14px]'>
                    <span className="text-neutral-300 text-sm font-medium font-['Montserrat'] leading-tight">
                      Open now
                    </span>
                    <Switch
                      onChange={handleClubOpen}
                      checkedColor='#A6F787'
                      checked={clubDetails?.clubStatus}
                    />
                  </div>
                </section>
                <section className='mb-[20px]'>
                  <h1 className="opacity-70 text-neutral-300 text-sm font-medium font-['Montserrat'] leading-tight">
                    Address
                  </h1>
                  <p className="w-[446px] text-white text-[17px] font-semibold font-['Montserrat'] leading-tight">
                    {clubDetails?.address}
                  </p>
                </section>
                <section className='flex gap-[39px] items-center'>
                  <div className='w-[max-content] '>
                    <h1 className=" opacity-70 text-neutral-300 text-sm font-medium font-['Montserrat'] leading-tight">
                      Website
                    </h1>
                    <p className=" text-white text-[17px] font-semibold font-['Montserrat'] leading-tight">
                      {clubDetails?.site}
                    </p>
                  </div>
                  <div className='w-[max-content] '>
                    <h1 className=" opacity-70 text-neutral-300 text-sm font-medium font-['Montserrat'] leading-tight">
                      Phone
                    </h1>
                    <p className=" text-white text-[17px] font-semibold font-['Montserrat'] leading-tight">
                      {clubDetails?.homePhone}
                    </p>
                  </div>
                  <div className='w-[max-content]'>
                    <h1 className=" opacity-70 text-neutral-300 text-sm font-medium font-['Montserrat'] leading-tight">
                      Opening hours (Today)
                    </h1>
                    <p className="  text-white text-[17px] font-semibold font-['Montserrat'] leading-tight">
                      {currentOpenDayTime?.startTime} —{' '}
                      {currentOpenDayTime?.endTime}
                    </p>
                  </div>
                </section>
              </section>
            </section>
          </section>
        </section>
        <section className='relative [grid-area:availability]  h-[100%]  bg-gradient-to-r from-[#413450] to-[#563143] p-[1rem]'>
          <ClubAvailability />
        </section>
        <section className='[grid-area:revenue] h-[max-content]   bg-gradient-to-r from-[#413450] to-[#563143] p-[1rem]'>
          <div className='w-[123px] h-6 justify-start items-center gap-2 inline-flex'>
            <CurrentPlanIcon />
            <span className="text-white text-sm font-medium font-['Montserrat'] leading-tight">
              Current Plan
            </span>
          </div>
          <span className="flex items-center justify-center mb-[20px] w-[92px] h-[30px] px-3 py-2 bg-[#EC9ABE] rounded-lg text-zinc-700 text-sm font-medium font-['Montserrat'] ">
            6 months
          </span>

          <div className='mb-[24px]'>
            <p className="text-white text-base font-medium font-['Montserrat'] leading-snug">
              Renews at $48.00
            </p>
            <div className='w-[82px] h-3.5 opacity-80 justify-start items-center gap-1 inline-flex'>
              {/* <div className='w-3.5 h-3.5 relative' /> */}
              <span className="text-white text-xs font-medium font-['Montserrat'] leading-3">
                28/12/2023
              </span>
            </div>
          </div>

          <button className="text-center text-zinc-700 text-sm font-medium font-['Montserrat'] leading-none w-[126px] h-[41px] px-4 py-3 bg-white rounded-sm justify-start items-start gap-2.5 inline-flex">
            Manage Plan
          </button>
        </section>
        <section className='[grid-area:analysis] flew-1   bg-gradient-to-r from-[#413450] to-[#563143] p-[1rem]'>
          <SideAnalysis />
        </section>
      </section>
      <section>
        <Gallery
          handleDeleteClubPhoto={handleDeleteClubPhoto}
          clubPhotos={clubPhotos}
          openUpload={() => {
            setOpenGalleryPop(true);
          }}
        />
      </section>
      <GalleryUploadPop
        handleConfirmGalleryPhotos={handleConfirmGalleryPhotos}
        loading={loading}
        handleClose={() => {
          setOpenGalleryPop(false);
        }}
        open={openGalleryPop}
      />
      <FeedUploadPop
        handleConfirmFeedPhotos={handleConfirmFeedPhotos}
        open={openAddFeedPop}
        handleClose={() => {
          setOpenAddFeedPop(false);
        }}
      />
      <PostFeedPopUp
        files={currentFeedFiles}
        open={openConfirmFeedPop}
        handleClose={() => {
          setOpenConfirmFeedPop(false);
        }}
      />
    </FluidContainer>
  );
};

export default ClubProfile;
