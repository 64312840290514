import classNames from 'classnames';
import React from 'react';
import styles from './table.module.scss';

const GridTable = ({ data, tableHeader }) => {
	return (
		<div className={styles.gridTable}>
			<div className={styles.innerGridTable}>
				<div className={styles.headerRow}>
					{tableHeader.map((header, index) => {
						return (
							<div key={index} className={classNames(styles.headerCell)}>
								<span className={styles.headerTitle}>
									{header?.text} <br />
									<span>{header?.subText}</span>
								</span>
							</div>
						);
					})}
				</div>
				{!data || (data?.length === 0 && <p className={styles.emptyData}>no data</p>)}

				{data &&
					data?.map((row, rowIndex) => (
						<div className={styles.rowOuterContainer} key={rowIndex}>
							<div className={classNames(styles.dataRow)}>
								{row?.map((item, colIndex) => {
									return (
										<div key={colIndex} className={classNames(styles.dataCell)}>
											<span className={styles.cellText}>{item?.text}</span>
										</div>
									);
								})}
							</div>
						</div>
					))}
			</div>
		</div>
	);
};

export default GridTable;
