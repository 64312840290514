import { useState } from 'react';
import Photo from '../../../custom/photo/Photo';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { ReactComponent as EditIcon } from '../../../images/svg/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../images/svg/delete-icon.svg';
import { ReactComponent as AlbumIcon } from '../../../images/svg/album-icon.svg';
import { ReactComponent as TimeIcon } from '../../../images/svg/clock.svg';
import { useMutation, useQueryClient } from 'react-query';
import {
  editModelPhotoName,
  editModelVideoName,
  deleteModelPhoto,
} from '../../../request/model';
import notification from '../../../utils/notification';

const ProfilePreview = ({
  className,
  handleOpenEditPhotoModal,
  deletePhoto,
  handlePhotoSelection,
  photo,
  photoId,
  isSelected,
  ...props
}) => {
  return (
    <section className={classnames(styles.section, className)}>
      <section className={styles.wrapper}>
        <button className={styles.btnEdit} onClick={handleOpenEditPhotoModal}>
          <EditIcon className={styles.editIcon} />
          Edit
        </button>
        <section className={styles.checkBoxWrapper}>
          <fieldset className={styles.checkboxFieldset}>
            <input
              type='checkbox'
              className={styles.checkbox}
              onChange={() => handlePhotoSelection(photoId)}
              checked={isSelected?.(photoId)}
            />
          </fieldset>
          <DeleteIcon className={styles.deleteIcon} onClick={deletePhoto} />
        </section>

        <section className={styles.nameWrapper}>
          <fieldset>
            <label className={styles.inputNameLabel}>
              <AlbumIcon className={styles.editIcon} />
              <input
                type='text'
                className={styles.nameInput}
                value={photo?.photoName}
              />
            </label>
          </fieldset>
        </section>
      </section>
      <Photo className={styles.photo} {...props} />
    </section>
  );
};

export default ProfilePreview;
