import { config } from '../data';
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import { Line } from 'react-chartjs-2';

Chart.register(CategoryScale);

const LineChart = ({ name, total }) => {
  return (
    <div className='w-[300px] h-[173.99px] p-4   rounded-sm border flex-col justify-start items-start gap-4 inline-flex'>
      <div className='self-stretch justify-between items-center inline-flex'>
        <span className="text-white text-xl font-bold font-['Montserrat'] leading-[23px]">
          {name}
        </span>
        <span className="text-stone-300 text-sm font-normal font-['Montserrat'] leading-none">
          July
        </span>
      </div>
      <div className='w-[100%] justify-between items-center inline-flex'>
        <div className='flex-col justify-center items-start gap-5 inline-flex'>
          <div className="text-white text-[40px] font-semibold font-['Montserrat'] leading-[56px]">
            {total}
          </div>
          <div className='justify-end items-center gap-[5.83px] inline-flex'>
            {/* <div className='w-5 h-5 justify-center items-center flex bg-red-500'>
                    <div className='w-5 h-5 relative flex-col justify-start items-start flex' />
                  </div> */}
            <span className="text-lime-300 text-sm font-semibold font-['Montserrat'] leading-relaxed tracking-wide">
              +13.47%
            </span>
          </div>
        </div>

        <Line
          id='line-chart'
          className='!w-[200px] !h-[174px]'
          type='line'
          options={{
            animations: {
              tension: {
                duration: 1000,
                easing: 'linear',
                from: 1,
                to: 0,
                loop: true,
              },
            },

            layout: {
              padding: 0,
              autoPadding: true,
            },
            interaction: {
              //   intersect: false,
            },
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
            },
            scales: {
              x: {
                display: false,
              },
              y: {
                display: false,
              },
            },
          }}
          data={{
            labels: [
              1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 19, 20, 21,
              22,
            ],
            datasets: [
              {
                data: [
                  0, 20, 20, 60, 60, 120, 150, 180, 120, 125, 105, 110, 170,
                  110, 170, 150, 170, 110, 130, 110, 180,
                ],

                tension: 0,
                borderColor: '#A6F787',
                borderWidth: 3,
              },
            ],
          }}
        />
      </div>
    </div>
  );
};

export default LineChart;
