import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import FluidContainer from '../../custom/fluidContainer/FluidContainer';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';
import ModelProfileHeader from '../../custom/modelProfileHeader/ModelProfileHeader';
import { ReactComponent as BroadCastIcon } from '../../images/svg/broadcast.svg';
import { ReactComponent as Card } from '../../images/svg/card.svg';
import { ReactComponent as Profile } from '../../images/svg/profile.svg';
import { ReactComponent as Photo } from '../../images/svg/photo.svg';
import { ReactComponent as Video } from '../../images/svg/video.svg';
import { ReactComponent as FanClub } from '../../images/svg/fan-club.svg';
import { ReactComponent as Prices } from '../../images/svg/prices.svg';
import { ReactComponent as Payout } from '../../images/svg/payout.svg';
import { ReactComponent as Transactiions } from '../../images/svg/transactions.svg';
import { ReactComponent as Stats } from '../../images/svg/stats.svg';
import { ReactComponent as BlockUsers } from '../../images/svg/block-users.svg';
import { ReactComponent as Events } from '../../images/svg/events.svg';
import { ReactComponent as Appointments } from '../../images/svg/appointments.svg';
import { useActionCreator } from '../../hooks/useActionCreators';
import { withRole } from '../../hoc/withRole';

const ModelProfile = () => {
	const clientId = useSelector(state => state?.user?.clientId);
	const { getModelOnlineStatus } = useActionCreator();
	const history = useHistory();

	useEffect(() => {
		(async () => {
			getModelOnlineStatus(clientId);
		})();
	}, []);

	const handleStartBroadCast = () => {
		history.push('/mode-live-streaming');
	};

	return (
		<FluidContainer>
			<ModelProfileHeader />
			<section className={styles.secondaryNav}>
				<div className={styles.broadcastContainer}>
					<div className={styles.startBroadCastWrapper}>
						<h4 className={styles.broadcast}>Broadcast</h4>
						<button className={styles.btnBroadCast} onClick={handleStartBroadCast}>
							<BroadCastIcon />
							Start broadcast
						</button>
					</div>
					<div className={styles.broadcastPublicProfileWrapper}>
						<p className={styles.broadcastPara}>
							Before you start broadcasting, you need to <br />
							set up your public profile
						</p>
						<button className={styles.btnSetup} onClick={() => history.push('/model-public-profile')}>
							Setup public profile
						</button>
					</div>
				</div>

				<article className={styles.percentWrapper}>
					<div>
						<p className={styles.percentPara}>
							Your current <br /> payout percent is
						</p>
						<p className={styles.percentAmount}>50%</p>
					</div>
					<Card className={styles.cardIcon} />
				</article>
			</section>
			<section className={styles.iconContainer}>
				<Link to='/model-public-profile'>
					<i className={styles.iconWrapper}>
						<Profile /> <span>Profile</span>
					</i>
				</Link>
				<Link to='/model-profile-photo'>
					<i className={styles.iconWrapper}>
						<Photo /> <span>Photo</span>
					</i>
				</Link>
				<Link to='/model-video'>
					<i className={styles.iconWrapper}>
						<Video /> <span>Video</span>
					</i>
				</Link>
				<Link to='/fan-club'>
					<i className={styles.iconWrapper}>
						<FanClub /> <span>Fan Club</span>
					</i>
				</Link>
				<Link to='/model-price'>
					<i className={styles.iconWrapper}>
						<Prices /> <span>Prices</span>
					</i>
				</Link>
				<Link to='/payouts'>
					<i className={styles.iconWrapper}>
						<Payout /> <span>Payouts</span>
					</i>
				</Link>
				<Link to='/transactions'>
					<i className={styles.iconWrapper}>
						<Transactiions /> <span>Transactions</span>
					</i>
				</Link>
				<Link to='/model-stats'>
					<i className={styles.iconWrapper}>
						<Stats /> <span>Stats</span>
					</i>
				</Link>
				<Link to='/block-users'>
					<i className={styles.iconWrapper}>
						<BlockUsers /> <span>Block Users</span>
					</i>
				</Link>
				<Link to='/fan-club-event'>
					{' '}
					<i className={styles.iconWrapper}>
						<Events /> <span>Events</span>
					</i>
				</Link>
				<Link to='/model-appointments'>
					{' '}
					<i className={styles.iconWrapper}>
						<Appointments /> <span>Appointments</span>
					</i>
				</Link>
			</section>
		</FluidContainer>
	);
};

export default withRole(ModelProfile);
