import notFoundImgUrl from '../../images/not-found-image.jpg';
import { LuAlertCircle } from 'react-icons/lu';

const EmptyView = ({ text }) => {
  return (
    <div className='relative w-[100%] h-[500px] mb-[2rem] '>
      <img
        src={notFoundImgUrl}
        className='absolute z-[0] w-[100%] h-[100%] top-[0%] bottom-0 '
      />

      <div className='relative z-[999] w-[100%] h-[100%] flex flex-col items-center justify-center'>
        <LuAlertCircle className='text-primary-100 text-[4rem]' />
        <p className=" text-center text-primary-100 text-base font-medium font-['Montserrat'] leading-[18.40px] rounded-sm shadow flex flex-col items-center justify-center">
          {text}...
        </p>
      </div>
    </div>
  );
};

export default EmptyView;
