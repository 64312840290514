import classNames from 'classnames';
import React, { useState } from 'react';
import styles from './table.module.scss';

const GridTable = ({ data, tableHeader }) => {
	return (
		<div className={styles.gridTable}>
			<div className={styles.innerGridTable}>
				<div className={styles.headerRow}>
					{tableHeader.map((header, index) => (
						<div
							key={index}
							className={classNames(
								styles.headerCell,
								index === 2 && styles.active,
								index === 0 && styles.dataCellExpand
							)}
						>
							<span className={styles.headerTitle}>
								{header?.text} <br />
								<span>{header?.subText}</span>
							</span>
						</div>
					))}
				</div>
				{!data || (data?.length === 0 && <p className={styles.emptyData}>no data</p>)}
				{data.map((row, rowIndex) => (
					<div className={styles.rowOuterContainer}>
						<div
							key={rowIndex}
							className={classNames(
								styles.dataRow,
								rowIndex === 0 && styles.firstRow,
								rowIndex === data.length - 1 && styles.lastRow
							)}
						>
							{row.map((item, colIndex) => (
								<div
									key={colIndex}
									className={classNames(
										styles.dataCell,
										colIndex === 2 && styles.active,
										colIndex === 0 && styles.dataCellExpand
									)}
								>
									<span className={styles.cellText}>{item.text}</span>
								</div>
							))}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default GridTable;
