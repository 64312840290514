import styles from './currentGoalShow.module.scss';
import classNames from 'classnames';
import {
  getStreamingGoals,
  updateStreamingGoal,
  deleteStreamingGoal,
} from '../../../request/model';
import { HiDotsVertical } from 'react-icons/hi';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

const CurrentGoalShow = ({ isOnline, setOpenGoalModal }) => {
  const goalRef = useRef({
    currentGoal: null,
    nextGoal: null,
  });

  const [goal, setGoal] = useState({
    currentGoal: null,
    nextGoal: null,
  });

  const [isLoading, setIsLoading] = useState(false);
  const amountRef = useRef(0);
  const [currentAmount, setCurrentAmount] = useState(amountRef.current);
  const user = useSelector((state) => state?.user);
  const allGoalRef = useRef([]);

  const getAllStreamingGoals = async () => {
    try {
      const response = await getStreamingGoals(user.clientId);
      allGoalRef.current = response;

      await handleSetGoal(response);
    } catch (error) {}
  };

  const handleSetGoal = async () => {
    try {
      let currentData = null;
      let nextData = null;

      //update
      const currentGoal = allGoalRef.current.find(
        (g) => g.setGoal === 'current'
      );

      if (
        currentGoal &&
        amountRef.current === goalRef.current.currentGoal?.amount
      ) {
        await updateStatus(goalRef.current.currentGoal, 'completed');

        amountRef.current = 0;
        const { next, current } = await setNextGoalAsProgress();
        currentData = current;
        nextData = next;
      }

      await checkCurrentGoal();
      //no current
      if (goalRef.current.currentGoal === null) {
        const { next, current } = await setNextGoalAsProgress();
        currentData = current;
        nextData = next;
      }

      if (currentData || nextData) {
        const data = {
          ...goalRef.current,
          currentGoal: currentData,
          nextGoal: nextData,
        };
        goalRef.current = data;

        setGoal({ ...data });
      }
    } catch (error) {}
  };

  const checkCurrentGoal = async () => {
    try {
      let currentData = null;
      let nextData = null;

      const currentGoal = allGoalRef.current.find(
        (g) => g.setGoal === 'current'
      );

      const progress = allGoalRef.current.filter((goal) => {
        return goal?.setGoal === 'progress';
      });

      if (currentGoal) {
        currentData = currentGoal;
        nextData = progress[0] ?? null;

        if (currentData || nextData) {
          const data = {
            ...goalRef.current,
            currentGoal: currentData,
            nextGoal: nextData,
          };
          goalRef.current = data;

          setGoal({ ...data });
        }
      }
    } catch (error) {}
  };

  const setNextGoalAsProgress = async () => {
    try {
      let currentData = null;
      let nextData = null;

      const progress = allGoalRef.current.filter((goal) => {
        return goal?.setGoal === 'progress';
      });

      if (progress.length >= 1) {
        if (goalRef.current.nextGoal) {
          currentData = await updateStatus(goalRef.current.nextGoal, 'current');
        } else {
          currentData = await updateStatus(progress[0], 'current');
        }

        nextData = progress.length >= 2 ? progress[1] : null;
      } else {
        nextData = null;
      }

      return {
        current: currentData,
        next: nextData,
      };
    } catch (error) {}
  };

  const updateStatus = async (payload = {}, status) => {
    try {
      if (payload && payload?.setGoal) {
        setIsLoading(true);
        const data = new FormData();
        data.append('goalSettingId', payload['goalSettingId']);
        data.append('amount', payload?.amount);
        data.append('description', payload?.description);
        data.append('registeredId', user.clientId);
        data.append('setGoal', status);
        await updateStreamingGoal(data);
        setIsLoading(false);
        return payload;
      }

      return null;
    } catch (error) {}
  };

  const updateTime = () => {
    const amount = amountRef.current + 1;
    amountRef.current = amount;
    setCurrentAmount(amount);
  };

  useEffect(() => {
    if (isOnline) {
      checkCurrentGoal();
    }
  }, [allGoalRef.current]);

  useEffect(() => {
    if (isOnline) {
      handleSetGoal(allGoalRef.current);
    }
  }, [amountRef.current]);

  useEffect(() => {
    if (isOnline || isLoading) {
      getAllStreamingGoals();

      const unsubscribeId1 = setInterval(() => {
        updateTime();
      }, 3000);

      return () => {
        clearInterval(unsubscribeId1);
      };
    }
  }, [isLoading, isOnline]);

  return (
    <>
      <section
        className={classNames(
          styles.broadcastingCreditContainer,
          isOnline && styles.activeBackgroundThree
        )}
      >
        <div className={styles.broadcastCredits}>
          <div>
            {' '}
            <span>{currentAmount}</span> <br />{' '}
            <span className={styles.currentCredit}>Current total</span>
          </div>
          <span className={styles.bar}></span>
          <div>
            <span>{goal.currentGoal?.amount ?? 0}</span> <br />{' '}
            <span className={styles.goalCredit}>Goal</span>
          </div>

          <span className={styles.editGoalButton}>
            <HiDotsVertical
              onClick={() => {
                setOpenGoalModal(true);
              }}
            />
          </span>
        </div>
      </section>
      <>
        {isOnline && (
          <h1
            className={classNames(
              styles.broadcastGoal,
              isOnline && styles.activeBackgroundThree
            )}
          >
            {goal.nextGoal?.description
              ? `Next Goal : ${goal.nextGoal?.description}`
              : 'No goal'}
          </h1>
        )}
      </>
    </>
  );
};

export default CurrentGoalShow;
