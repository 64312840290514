import styles from '../live-streaming.module.scss';
import classNames from 'classnames';

const Counter = ({ className, hours, seconds, minutes }) => {
  return (
    <div className={classNames(styles.broadCastDurationContainer, className)}>
      <h1 className={styles.broadCastDurationTitle}>
        Current Broadcast Duration
      </h1>
      <span className={styles.broadCastDurationCountDown}>
        {hours} : {minutes} : {seconds}
      </span>
    </div>
  );
};

export default Counter;
