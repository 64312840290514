import React from 'react';
import { Circles } from 'react-loader-spinner';

const Spinner = ({
  height = '20',
  width = '20',
  color = '#b87693',
  wrapperStyle,
  wrapperClass,
  visible = true,
}) => {
  return (
    <Circles
      height={height}
      width={width}
      color={color}
      ariaLabel='circles-loading'
      wrapperStyle={{ justifyContent: 'center', ...wrapperStyle }}
      wrapperClassName={wrapperClass}
      visible={visible}
    />
  );
};

export default Spinner;
