import { Link } from 'react-router-dom';

const TermsAndConditions = () => {
	const siteLink = window.location.origin;
	const privacyLink = `${siteLink}/privacy-policy`;
	const termsOfServiceLink = `${siteLink}/privacy-policy`;
	return (
		<main className='container'>
			<section className='info-page'>
				<div className='info-page--left'>
					<div className='info-page--text'>
						<div className='info-page--title'>Terms & Conditions</div>
						<div className='info-page--modifed'>Last modified: may 21st, 2023</div>
						<div className='info-page--paragraph'>
							<strong>Acceptance of the Terms of Service</strong>
							<p>
								By using or visiting <Link to={siteLink}>{siteLink}</Link> (individually a "Website" and,
								collectively, the "Websites"), or any content, functionality and services offered on or through
								any of these Websites, whether as a guest or a registered user, or by clicking to accept or
								agree to the Terms of Service when this option is made available to you, you signify your
								agreement to these Terms of Service and our Privacy Policy, found at {privacyLink} and
								incorporated herein by reference.
							</p>
							<p>
								These Terms of Service apply to all users, including users who are also contributors of content,
								of any of these Websites, web pages, interactive features, embeddable player, uploader and other
								applications, widgets, blogs, social networks, social network "tabs", or other online or
								wireless offerings that post a link to these Terms of Service, whether accessed via computer,
								mobile device, or other technology, manner, or means.
							</p>
							<p>
								<big>"Content"</big> includes the text, software, scripts, graphics, photos, sounds, music,
								videos, audiovisual combinations, interactive features, textual content, and other materials you
								may view, upload, publish, submit, make available, display, communicate or post on, or transmit
								to other users or other person or access through, a Website.
							</p>
							<p>
								If you do not agree to any of these terms of Service or our Privacy Policy, please do not access
								or use any of these Websites.
							</p>
							<p>
								You consent to entering these Terms of Service electronically, and to storage of records related
								to these Terms of Service in electronic form.
							</p>
						</div>
						<div className='info-page--paragraph'>
							<strong>Ability to Accept Terms of Service</strong>
							<p>
								You affirm that you are at least 18 years of age or the age of majority in the jurisdiction you
								are accessing a Website from, and are fully able and competent to enter into the terms,
								conditions, obligations, affirmations, representations, and warranties set forth in these Terms
								of Service, and to abide by and comply with these Terms of Service. If you are under 18 or the
								applicable age of majority, please do not use any of these Websites. You also represent that the
								jurisdiction from which you access a Website does not prohibit the receiving or viewing of
								sexually explicit content. Whether you are a registered or unregistered User, we may require
								that you provide us and/or our third-party age verification service providers with information
								which will help us determine that you are over the age of majority required to have access to
								this Website and to view its contents. For more information on how this information is
								processed, please review our Privacy Policy. Failure to, when required, provide us and/or our
								third-party age verification service providers with information which will help us determine
								that you are over the age of majority required to have access to this Website and to view its
								contents will lead to the termination or suspension of your access rights to all or part of the
								Website.
							</p>
						</div>
						<div className='info-page--paragraph'>
							<strong>Changes to the Terms of Service</strong>
							<p>
								We reserve the right to amend these Terms of Service at any time and without notice, and it is
								your responsibility to review these Terms of Service for any changes. Your use of a Website
								following any amendment of these Terms of Service will signify your assent to and acceptance of
								its revised terms.
							</p>
							<p>
								The updated version of the Terms of Service supersedes any prior versions immediately upon being
								posted, and the prior version(s) shall have no continuing legal effect. You should periodically
								review the most up-to-date version of the Terms of Service found at {termsOfServiceLink}
							</p>
						</div>
						<div id='block4' className='info-page--paragraph'>
							<strong>About Our Websites</strong>
							<p>
								The Websites allow for uploading, sharing and general viewing of various types of adult-oriented
								content by users, registered and unregistered, and models who desire to share and view visual
								depictions of adult-oriented content, including sexually explicit images. In addition, these
								Websites contain texts, messages, files, data, information, images, photos, videos, recordings,
								materials, code, or content of any kind and other materials posted or uploaded by users and
								models.
							</p>
							<p>
								The Websites may contain links to third party sites that are not owned or controlled by these
								Websites or their operator. The Websites have no control over, and assume no responsibility for,
								the content, privacy policies, or practices of any third-party sites. In addition, these
								Websites will not and cannot censor or edit the content of any third-party site. By using any of
								these Websites, you expressly relieve us from all liability arising from your use of any
								third-party sites. Accordingly, we encourage you to be aware when you leave these Websites and
								to read the terms, conditions, and privacy policies of each other sites that you visit.
							</p>
							<p>
								The Websites are for your personal use and shall not be used for any commercial endeavor except
								those specifically endorsed or approved by these Websites.
							</p>
							<p>
								The Websites are for adult-oriented content. Other categories of content may be rejected or
								deleted in our sole discretion. We may, in our sole discretion and at any time, remove any
								content on these Websites.
							</p>
							<p>
								You understand and acknowledge that when using any of these Websites, you will be exposed to
								content from a variety of sources, and that these Websites are not responsible for the accuracy,
								usefulness, safety, or intellectual property rights of or relating to such content. You further
								understand and acknowledge that you may be exposed to content that is inaccurate, offensive,
								indecent, or objectionable, and you agree to waive, and hereby do waive, any legal or equitable
								rights or remedies you have or may have against any of these Websites with respect thereto, and
								agree to indemnify and hold these Websites, their site operator, their parent corporation, their
								respective affiliates, licensors, service providers, officers, directors, employees, agents,
								successors and assigns, harmless to the fullest extent allowed by law regarding all matters
								related to your use of any of these Websites.
							</p>
						</div>
						<div id='block5' className='info-page--paragraph'>
							<strong>Communication Preferences</strong>
							<p>
								By using any of these Websites, you expressly and specifically consent to receiving electronic
								communications from us relating to your account. These communications may involve sending emails
								to your email address provided during registration, or posting communications on these Websites
								(for example, through the members’ area on these Websites upon login to ensure receipt in the
								event you have unsubscribed from email communications), or in the “My Account” or “My Profile”
								page and may include notices about your account (such as upcoming payment notifications, change
								in password or payment method, confirmation emails and other transactional information) and are
								part of your relationship with us. You agree that any notices, agreements, disclosures, or other
								communications that we send to you electronically will satisfy any legal communication
								requirements, including that such communications be in writing. You should maintain copies of
								electronic communications by printing a paper copy or saving an electronic copy. You also
								expressly and specifically consent to receiving certain other communications from us, such as
								newsletters about new features and content, special offers, promotional announcements and
								customer surveys via email or other methods. If you no longer want to receive certain
								non-transactional communications, you will need to avail yourself of the unsubscribe mechanism
								set out in the applicable communication.
							</p>
						</div>
						<div id='block6' className='info-page--paragraph'>
							<strong>
								Purchasing Content, Subscriptions, Free Trials, Promotion Subscriptions, Billing and
								Cancellation
							</strong>
							<strong>Use of Third-Party Internet Payment Service Providers</strong>
							<p>
								Purchases of Content made available by Models and payment for subscriptions to NSA1 Premium can
								be made by credit cards, and when available, by debit cards, and are processed through our
								third-party Internet payment service providers or other payment processors. By purchasing
								Content made available by Models or subscribing to NSA1 Premium, you hereby consent to and agree
								to abide by such third-party Internet payment service providers’ or payment processors’ customer
								terms and conditions, and policies, and understand that we have no control whatsoever on such
								customer terms and conditions, and policies. If you cannot agree to such third-party internet
								payment service providers’ or payment processors’ customer terms and conditions or policies, do
								not purchase Content made available by Models or subscribe to NSA1 Premium.
							</p>
							<p>
								You may purchase Content made available to you by Models. Purchases are payable in advance.
								Different packages you can choose from may be offered. Models making content available to you
								reserve the right to change Content prices at any time, at their sole discretion and without
								liability to you. Any purchased Content will not be available in your account in the event that
								your account is deleted by you or otherwise terminated in accordance with these Terms of
								Services.
							</p>
						</div>
						<div id='block7' className='info-page--paragraph'>
							<strong>Subscriptions to NSA1 Premium</strong>
							<p>
								Your subscription to NSA1 Premium, which may start with a free trial or promotional
								subscription, will continue unless and until you cancel, or we terminate it. You must provide us
								with a current, valid, accepted method of payment (as such may be updated from time to time,
								<big>"Payment Method"</big>) to use our services. We will bill the applicable subscription fee
								to your Payment Method. You must cancel your subscription before it renews to avoid billing of
								the next period’s subscription fees to your Payment Method.
							</p>
							<p>
								We may offer a number of subscription plans, including special promotional plans or
								subscriptions with differing conditions and limitations. Any materially different terms from
								those described in these Terms of Service will be disclosed at your sign-up or in other
								communications made available to you. You can find specific details regarding your subscription
								with us by verifying the email receipt issued upon your sign-up or by contacting us in one of
								the manners described at {siteLink}. Some promotional subscriptions may be offered by third
								parties in conjunction with the provision of their own products and services. We are not
								responsible for the products and services provided by such third parties. We reserve the right
								to modify, terminate or otherwise amend, in our sole discretion, our offered subscription plans.
							</p>
						</div>
						<div id='block7' className='info-page--paragraph'>
							<strong>Free Trials and Promotional Subscriptions to NSA1 Premium</strong>
							<p>
								Your subscription to NSA1 Premium may start with a free trial or promotional subscription. The
								free trial period of your subscription may last for three(3) days, five (5) days, seven days, or
								as otherwise specified during sign-up. For combinations with other offers, restrictions may
								apply. Free trials or promotional subscriptions are for new and certain former subscribers only.
								We reserve the right, in our sole discretion, to determine your eligibility for free trial or
								promotional subscription.
							</p>
							<p>
								We will begin billing your Payment Method for monthly subscription fees at the end of the free
								trial period of your subscription or at the end of your promotional subscription unless you
								cancel prior to the end of the applicable period. To view the specific details of your
								subscription, including monthly subscription price and end date of your free trial period or
								promotional subscription, please verify the email receipt issued upon your sign-up or contact us
								in one of the manners described at {siteLink}. We may authorize your Payment Method through
								various methods, including authorizing it up to an amount equal to or similar to the amount of
								the monthly subscription fee as soon as you register. In some instances, your available balance
								or credit limit may be reduced to reflect the authorization during your free trial period.
								However, you will not be charged during your free trial period for the subscription fee.
							</p>
						</div>
						<div id='block7' className='info-page--paragraph'>
							<strong>Billing for Subscriptions to NSA1 Premium</strong>
							<p>
								By starting your subscription to NSA1 Premium and providing or designating a Payment Method, you
								authorize us to charge you a recurring subscription fee at the then current rate, and any other
								charges you may incur in connection with your use of our services to your Payment Method. You
								acknowledge that the amount billed each period may vary (but not materially) from term to term
								and you authorize us to charge your Payment Method for such varying amounts, which may be billed
								monthly in one or more charges.
							</p>
							<p>
								We reserve the right to adjust pricing for our service or any components thereof in any manner
								and at any time as we may determine in our sole discretion. Except as otherwise expressly
								provided for in these Terms of Service, any material price increases to your service will take
								effect following notice to you.
							</p>
							<p>
								The subscription fee for our services will be billed at the beginning of the paying portion of
								your subscription and each period thereafter unless and until you cancel your subscription. We
								automatically bill your Payment Method each period on or near the calendar day corresponding to
								the commencement of your paying subscription. Subscription fees are fully earned upon payment.
								We reserve the right to change the timing of our billing, in particular, as indicated below, if
								your Payment Method has not successfully been processed. In the event that your paying
								subscription began on a day not contained in a given month, we may bill your Payment Method on a
								day in the applicable month or such other day as we deem appropriate. Your renewal date may
								change due to changes in your subscription.
							</p>
						</div>
						{/* <div id='block7' className='info-page--paragraph'>
							<strong>No Refunds</strong>
							<p>
								In the event we terminate your rights to use any of these Websites because of a breach of these
								Terms of Service, you shall not be entitled to the refund of any unused portion of subscription
								fees. We reserve the right (but not the obligation) to refund the purchased amount if there is a
								technical error with respect to the purchased Content; this is to be determined by us in our
								sole discretion.
							</p>
							<p>
								At any time, and for any reason, we may provide a refund, discount, or other consideration to
								some or all of our subscribers or to purchasers of Content. The amount and form of such credits,
								and the decision to provide them, are in our sole discretion. The provision of credits in one
								instance does not entitle you to credits in the future for similar instances, nor does it
								obligate us to provide credits in the future, under any circumstance.
							</p>
						</div> */}
						<div id='block7' className='info-page--paragraph'>
							<strong>Payment Methods</strong>
							<p>
								You may edit your Payment Method information by clicking on your account settings. If a payment
								is not successfully processed, due to expiration, insufficient funds, or otherwise, and you do
								not edit your Payment Method information or cancel your account, you remain responsible for any
								uncollected amounts and authorize us to continue billing the Payment Method, as it may be
								updated. This may result in a change to your payment billing dates. For certain Payment Methods,
								the issuer of your Payment Method may charge you a transaction fee or other charges.
							</p>
						</div>
						<div id='block7' className='info-page--paragraph'>
							<strong>Cancellation of Model Account</strong>
							<p>
								You may cancel your Model account at any time . You are liable for charges incurred until the
								date of the termination
							</p>
						</div>
						<div id='block7' className='info-page--paragraph'>
							<strong>Cardholder Disputes/Chargebacks</strong>
							<p>
								All chargebacks are thoroughly investigated and disputed and may prevent future purchases with
								our third-party Internet payment service providers given the circumstances. Fraud claims may
								result in our third-party Internet payment service providers contacting your card issuer to
								protect you and prevent future fraudulent charges to your payment method.
							</p>
						</div>
						<div id='block7' className='info-page--paragraph'>
							<strong>Electronic Receipt</strong>
							<p>
								You will receive an email receipt to the email provided upon initial registration, as the same
								may be changed by you over time. You may request a copy of the account of charges to your
								account, but we cannot guarantee the availability of such records more than 365 days after a
								subscription or purchase date. Requests must be made directly to Aylo Billing. To contact Aylo
								Billing, please contact support at the following link - {siteLink}
							</p>
						</div>
						<div id='block7' className='info-page--paragraph'>
							<strong>Accessing these Websites and Account Security</strong>
							<p>
								We reserve the right to withdraw or amend any of these Websites, and any service or material we
								provide on these Websites, in our sole discretion without notice. We will not be liable if for
								any reason all or any part of a Website is unavailable at any time or for any period. From time
								to time, we may restrict access to some parts of a Website, or the entire Website, to users,
								including registered users.
							</p>
							<p>You are responsible for:</p>
							<ol>
								<li>making all arrangements necessary for you to have access to these Websites, and</li>
								<li>
									ensuring that all persons who access any of these Websites through your internet connection
									are aware of these Terms of Service and comply with them.
								</li>
							</ol>
							<p>
								To access these Websites or some of the resources they offer, you may be asked to provide
								certain registration details or other information. It is a condition of your use of these
								Websites that all the information you provide on these Websites is correct, current, and
								complete. You agree that all information you provide to register with these Websites or
								otherwise, including but not limited to through the use of any interactive features on these
								Websites, is governed by our Privacy Policy found at {privacyLink} and you consent to all
								actions we take with respect to your information consistent with our Privacy Policy.
							</p>
							<p>
								If you choose, or are provided with, a username, password, or any other piece of information as
								part of our security procedures, you must treat such information as confidential, and you must
								not disclose it to any other person. You are fully responsible for all activities that occur
								under your username or password. You also acknowledge that your account is personal to you and
								agree not to provide any other person with access to these Websites or portions of these
								Websites using your username, password, or other security information. You agree to notify us
								immediately of any unauthorized access to or use of your username or password or any other
								breach of security by contacting us at support@nsa1.com. You also agree to ensure that you exit
								from your account at the end of each session. You should use particular caution when accessing
								your account from a public or shared computer so that others are not able to view or record your
								password or other personal information. Although these Websites will not be liable for your
								losses caused by any unauthorized use of your account, you may be liable for the losses of these
								Websites or others due to such unauthorized use.
							</p>
							<p>
								If you interact with us or with third-party service providers, and you provide information,
								including account or credit card or other payment information, you agree that all information
								that you provide will be accurate, complete, and current. You will review all policies and
								agreements applicable to use of third-party services. In the event you use our Websites over
								mobile devices, you hereby acknowledge that your carrier’s normal rates and fees, such as excess
								broadband fees, will still apply.
							</p>
							<p>
								We have the right to disable any username, password, or other identifier, whether chosen by you
								or provided by us, at any time in our sole discretion for any or no reason, including if, in our
								opinion, you have violated any provision of these Terms of Service.
							</p>
							<p>
								You acknowledge that these Websites reserve the right to charge fees for their services and
								Websites access and to change their fees in their sole discretion.
							</p>
						</div>
						<div id='block7' className='info-page--paragraph'>
							<strong>Limited, Conditional License to Use Our Intellectual Property</strong>
							<p>
								NSA1, NSA1 Premium and our associated logos and names are our trademarks and/or service marks.
								Other trademarks, service marks, names, and logos used on or through these Websites, such as
								trademarks, service marks, names, or logos associated with third party content providers, are
								the trademarks, service marks, or logos of their respective owners. You are granted no right or
								license with respect to any of the aforesaid trademarks, service marks, or logos.
							</p>
							<p>
								The inclusion of images or text containing the trademarks or service marks or the name or
								likeness of any person, including any celebrity, does not constitute an endorsement, express or
								implied, by any such person, of these Websites or vice versa.
							</p>
							<p>
								The Websites and certain materials available on or through these Websites are content we own,
								authored, created, purchased, or licensed (collectively, our "Works"). Our Works may be
								protected by copyright, trademark, patent, trade secret, and/or other laws, and we reserve and
								retain all rights in our Works and these Websites.
							</p>
							<p>
								We hereby grant you a conditional, royalty-free, limited, revocable, non-sublicensable,
								non-transferable, and non-exclusive license to access our Websites and Works solely for your
								personal use in connection with using these Websites.
							</p>
							<p>
								We grant you a conditional and limited license to access, view, and display our Websites and
								Works, and to create and display transient copies of these Websites and Works as necessary to
								view them, conditioned upon your agreement to display these Websites whole and intact as
								presented by these Websites host, complete with any advertising, to not interfere with the
								display of any advertising, and to not use ad blocking software of any kind. This limited
								license is further conditioned upon your agreement not to use any information obtained from or
								through these Websites to block or interfere with the display of any advertising on these
								Websites, or for the purpose of implementing, modifying, or updating any software or filter
								lists that block or interfere with the display of any advertising on these Websites.
								Interference with the display of any advertising on these Websites, use of ad blocking software
								to block or disable any advertising while viewing these Websites, or use of information obtained
								from or through these Websites to update any ad blocking software or filter lists, is
								prohibited, violates the conditions of your limited license to view these Websites and Works and
								constitutes copyright infringement.
							</p>
							<p>
								You may not otherwise reproduce, distribute, communicate to the public, make available, adapt,
								publicly perform, link to, or publicly display these Websites and Works or any adaptations
								thereof unless expressly set forth herein. Such conduct would exceed the scope of your license
								and constitute copyright infringement.
							</p>
							<p>
								The Websites may provide an embeddable player feature, which you may incorporate into your own
								website for use in accessing the Content on these Websites. You may not modify, build upon or
								block any portion or functionality of the embeddable player in any way, including but not
								limited to links back to these Websites.
							</p>
							<p>
								The above-described license is conditioned on your compliance with these Terms of Service,
								including, specifically, your agreement to view these Websites whole and intact as presented by
								these Websites host, complete with any advertising, and shall terminate upon termination of
								these Terms of Service. If you breach any provision of these Terms of Service, any license you
								have obtained will be automatically rescinded and terminated. To protect our rights some Content
								made available on these Websites may be controlled by digital rights management technologies,
								which will restrict how you may use the Content. You must not circumvent, remove, delete,
								disable, alter, or otherwise interfere with any digital rights management technology. Such
								conduct is prohibited by law.
							</p>
							<p>
								If these Websites allow you to download or otherwise copy our Works, you are not buying or being
								gifted copies thereof. Instead, you are licensing a limited, revocable, non-sublicensable,
								non-transferable, and non-exclusive right to possess and use the copies for personal,
								non-commercial use, subject to specific terms and conditions (the "Download License"). Under
								this Download License you may not thereafter reproduce, distribute, communicate to the public,
								make available, adapt, publicly perform, or publicly display these Websites and/or Works or any
								adaptations thereof unless expressly set forth herein. Such conduct would exceed the scope of
								your Download License and constitute copyright infringement. At the expiration of your Download
								License or the termination of these Terms of Service, you will delete or otherwise dispose of
								all copies of Works in your possession.
							</p>
						</div>
						<div id='block7' className='info-page--paragraph'>
							<strong>Content Posted by Users and Models</strong>
							<p>
								As these Websites’ account holder, you may submit Content to these Websites and other websites
								linked to these Websites including videos and user comments. You understand that these Websites
								do not guarantee any confidentiality with respect to any Content you submit.
							</p>
							<p>
								You shall be solely responsible for your own Content and the consequences of posting, uploading,
								publishing, transmitting, or otherwise making available your Content on these Websites. You
								understand and acknowledge that you are responsible for any Content you submit or contribute,
								and you, not us, have full responsibility for such Content, including its legality, reliability,
								accuracy, and appropriateness.
							</p>
							<p>
								You affirm, represent, and warrant that you own or have the necessary licenses, rights,
								consents, and permissions to publish Content you submit; and you license to these Websites all
								patent, trademark, trade secret, copyright, or other proprietary rights in and to such Content
								for publication on these Websites pursuant to these Terms of Service.
							</p>
							<p>
								You further agree that Content you submit to these Websites will not contain third party
								copyrighted material, or material that is subject to other third-party proprietary rights,
								unless you have permission from the rightful owner of the material, or you are otherwise legally
								entitled to post the material and to grant to these Websites all of the license rights granted
								herein.
							</p>
							<p>
								You agree and understand that these Websites (and their successors’ and affiliates’) may make
								use of your Content for promotional or commercial purposes and to render the services pursuant
								to these Terms of Services. For clarity, you retain all of your ownership rights in your
								Content. By submitting Content to these Websites, you hereby grant these Websites’ operators an
								unlimited, worldwide, perpetual, non-exclusive, royalty-free, sublicensable, and transferable
								license to use, reproduce, publish, distribute, broadcast, market, create derivative works of,
								adapt, translate, publicly display, communicate, or perform, make available or otherwise use all
								of the Content, including without limitation for promoting and redistributing part or all of
								these Websites (and derivative works thereof) in any media formats and through any media
								channels. You also waive to the full extent permitted by law any and all claims against us
								related to moral rights in the Content. In no circumstances will we be liable to you for any
								exploitation of any Content that you post. You also hereby grant each user of these Websites a
								non-exclusive, royalty free license to access your Content through these Websites, and to use,
								reproduce, display, communicate, and perform such Content as permitted through the functionality
								of these Websites and under these Terms of Service. The above licenses granted by you in video
								Content you submit to these Websites terminate within a commercially reasonable time after you
								remove or delete your Content from these Websites. You understand and agree, however, that these
								Websites may retain, but not display, distribute, or perform, server copies of your videos that
								have been removed or deleted. The above licenses granted by you in user comments you submit are
								perpetual and irrevocable.
							</p>
							<p>
								The Websites do not endorse any Content submitted by any user or other licensor, or any opinion,
								recommendation, or advice expressed therein, and these Websites expressly disclaim any and all
								liability in connection with Content. The Websites do not permit copyright infringing activities
								and infringement of intellectual property rights on these Websites, and these Websites will
								remove all Content if properly notified that such Content infringes on another’s intellectual
								property rights. The Websites reserve the right to remove Content without prior notice.
							</p>
							<p>
								All Content you submit must comply with the Content standards set out in these Terms of Service.
							</p>
							<p>
								If any of the Content that you post to or through these Websites contains ideas, suggestions,
								documents, and/or proposals to us, we will have no obligation of confidentiality, express or
								implied, with respect to such Content, and we shall be entitled to use, exploit, or disclose (or
								choose not to use or disclose) such Content in our sole discretion without any obligation to you
								whatsoever ( i.e. , you will not be entitled to any compensation or reimbursement of any kind
								from us under any circumstances).
							</p>
							<p>
								In the process of posting Content to these Websites, you may be asked to provide some personally
								identifying information, such as your name, address, email address, a password, and other
								documentation. You may also be asked to provide such information in order to use certain
								features of these Websites.
							</p>
							<p>
								We will keep a record of the information you provide, including your personally identifiable
								information. That information may be linked in our records to other information you provide,
								including Content. We will not provide your name or other personally identifying information to
								our advertisers or business partners without your permission. Please note that some of the
								information you provide in registering for and using these Websites, including the name used in
								registering for and using these Websites or other personally identifying information, may be
								displayed to other members of these Websites, and may become public. In addition, we may
								disclose the personally identifying information and documentation you provide in some limited
								circumstances, including but not limited to responses to subpoenas or requests by law
								enforcement, or as required by taxing authorities.
							</p>
						</div>
						<div id='block7' className='info-page--paragraph'>
							<strong>Content Posted by Models</strong>
							<p>
								If you want to become a Model and post Content through these Websites, you must first join our
								Model Program and agree to its terms and conditions and provide true and correct personal
								information. If the information you provide is not true and correct, you face the immediate
								termination of your account and you may be subject to legal sanctions.
							</p>
							<p>
								We may, in our sole discretion, decline your application to join our community of content
								providers for any reason.
							</p>
							<p>
								You may be asked to provide a valid email address for verification purposes and quality
								assurance, and you agree that we may send you emails, both of a transactional nature and also
								for commercial and promotional purposes.
							</p>
							<p>
								You will choose your own screen name, which must be unique to you, not offensive to others, and
								not in violation of another’s copyright or trademark. You will also choose your password, which
								you can change later. It is imperative that you do not let anyone else use your account (you
								must keep your password secret and secure). Certain changes to your personal information such as
								your name and screen name can only be made by our staff. Therefore, if your information appears
								incorrect or needs to be changed you may need to contact our staff to have this done.
							</p>
							<p>You may never use anyone else’s account, just as no one can ever use yours.</p>
							<p>
								Before you are able to make and receive earnings from Content you may offer for sale, you need
								to verify your identity. To do so, you need to submit to us high-res images or scans of a
								minimum of one to two information documents, containing your date of birth, expiration date of
								the ID, your photo, your full legal name, and your address. This could be, for example, your
								driver’s license (in countries where a national ID is not mandatory), international passport,
								citizenship card, state ID, national passport, or your national ID card. The other form of
								identification may be a utility bill. If all the required information is set out on your
								government-issued photo ID, you do not need a second document. We may, in our sole discretion,
								require you to provide us multiple forms of identification to establish proof of adulthood and
								identity.
							</p>
							<p>
								You acknowledge that you are solely responsible for the activity that occurs on your account.
								Please note that you may not permit any other person to use your account and that you must
								immediately inform us of any apparent breach of security, such as loss, theft or unauthorized
								disclosure or use of a screen name or password.
							</p>
							<p>
								You will be liable for any losses incurred by us due to the unauthorized use of your account. We
								are not liable for your losses caused by any unauthorized use of your account, and you
								specifically waive any such claim and agree to defend and indemnify us against any such claims
								made against your account by third parties.
							</p>
							<p>
								To the extent you voluntarily create a user profile to participate in certain select services
								offered by us, your profile (and its contents) may be searchable by other users registered
								through these Websites and others partnered or networked with us. Likewise, your profile (and
								its contents) may be searchable by publicly available search engines.
							</p>
						</div>
						<div id='block7' className='info-page--paragraph'>
							<strong>Model Content</strong>

							<p>
								Subject to what is permitted under applicable law, you are free to choose the type of Content
								you produce and post or publish. You shall be solely responsible for your own Content and the
								consequences of posting or publishing such Content. In connection with Content you post or
								publish, you affirm, represent, and/or warrant that: (i) you own or have the necessary licenses,
								rights, consents, and permissions to use and authorize us to use all patent, trademark, trade
								secret, copyright, right to privacy, right to publicity, or other proprietary commercial or
								personal rights in and to any and all your Content to enable inclusion and use of Content in the
								manner contemplated by these Websites and these Terms of Service, (ii) all persons depicted in
								the Content freely and voluntarily consented to the production of the Content, (iii) you are in
								compliance with any applicable records keeping (including those imposed by 18 U.S.C. Section
								2257 and 28 C.F.R. 75) or age verification laws and regulations, and (iv) you have the written
								consent, release, and/or permission of each and every identifiable persons in your Content to
								use the name or likeness of each individual for use in your Content in the manner contemplated
								by these Websites and these Terms of Service. We may, in our sole discretion, require you to
								provide us with written evidence of the foregoing. If we require such written evidence, you
								agree to provide it to us within five (5) working days of the date of our request. If you fail
								to provide us with such required written evidence within that timeframe, we may, amongst others
								and in our sole discretion, withhold indefinitely payments to you.
							</p>
							<p>
								We are not responsible for any Content that violates community standards in your community. If
								you are seeking information regarding any illegal or inappropriate activities, you agree to
								leave these Websites immediately. We expect and demand that you comply with all federal, state,
								provincial and local laws when using these Websites and when submitting or posting Content to
								these Websites. If you are unsure whether or not Content will violate a law, you are urged to
								contact an attorney prior to posting the Content. We cannot enforce every jurisdiction’s laws
								for all Content that is posted to these Websites. As such, we are not responsible for the
								Content of these Websites.
							</p>
							<p>
								You must evaluate, and bear all risks associated with, the use of any Content, including any
								reliance on the accuracy, completeness, or usefulness, or lawfulness of such Content. In this
								regard, you acknowledge that you may not rely on any content created by us or Content
								transmitted to these Websites. You are responsible for all your Content that is uploaded,
								posted, emailed, transmitted, or otherwise made available via these Websites.
							</p>
							<p>
								If we have a reason to suspect that your Content violates any third party right, including
								without limitation any copyright, trademark, or property right, we can require you to provide us
								with written evidence of your ownership of, or right to use, the material in question. If we
								require such written evidence, you agree to provide it to us within five (5) working days of the
								date of our request. Your failure to provide us with such required written evidence within that
								timeframe may lead to the immediate termination of your account, us demanding compensation from
								you for any accrued costs and damages related to such Content, and the immediate suspension of
								any and all pending payments from us to you.
							</p>
						</div>
						<div id='block7' className='info-page--paragraph'>
							<strong>Models’ Commission</strong>
							<p>Models will be entitled to receive the following:</p>
							<ol>
								<li>
									NSA1 videos on demand: up to sixty five percent (65%) of the revenues generated from the sale
									of your Content on NSA1 net of any applicable sales taxes (such as, but not limited to, Value
									Added Tax and state sales tax). Within certain limits, you are allowed to set the price at
									which your Content will be sold.
								</li>
								<li>
									Model Program – Ad Share Revenue: on free to watch videos on these Websites, a percentage of
									the ad revenue that the video earns.
								</li>
								<li>
									Fan Clubs: if enabled, eighty percent (80%) of all the subscription revenues from your
									fan-only content net of any applicable sales taxes (such as, but not limited to, Value Added
									Tax and state sales tax).
								</li>
								<li>
									For each video in the NSA1 Premium Viewshare Program on these Websites, an amount equal to
									the (i) the view share fraction of such video during the month and (ii) fifteen percent (15%)
									of the net revenues for such month.
								</li>
							</ol>
							<p>
								We are a marketplace facilitator with respect to certain sales of Content by Models on these
								Websites. Where required by U.S. state law, we are assuming the rights and duties of a seller
								with respect to taxable sales of Content by Models through these Websites. Where required by
								U.S. law, we have registered with the applicable state departments of revenue and will collect
								and remit sales tax on taxable sales of Content by Models. We began complying with marketplace
								facilitator laws on or about October 1, 2019. We will deduct and withhold from any amount
								payable to the Model such as amounts as we are required to deduct and withhold with respect to
								such payment under the provision of any applicable laws and remit such deduction and withheld
								amount to the concerned tax authorities.
							</p>

							<p>
								You will not receive monthly a statement showing any amounts you earned. However, you will be
								able to view amounts earned in your account. Payments are generally made within ten (10)
								business days after the end of each month (although, depending on the payment method used, it
								may take longer for you to actually receive your payment); provided, however, that payment will
								be made only when the minimum payout, based on net income, of one hundred dollars (US$100.00)
								has been reached. Any amount below one hundred dollars (US$100.00) will be carried over until
								the minimum payout has been reached and will be paid on the next following payment date. If you
								provide us with incorrect payment details, any fees for chargebacks resulting from such
								incorrect payment details will be offset against any amounts otherwise payable to you.
							</p>
							<p>
								In the event you violate any part of these Terms of Services, you violate any third party right,
								including without limitation any copyright, property, or privacy right, or where a third-party
								claims that all or any part of your Content caused it damage, we may, in our sole discretion,
								withhold indefinitely payments to you.
							</p>
							<p>
								We remind you that it is your sole duty and obligation to fulfill all tax obligations with
								regard to your activities for us, including but not limited to registration, declaration, and
								payment obligations related to income taxes and VAT. If we assist you with these obligations, it
								does not release you from your obligations. It is your duty to inform us in a timely manner of
								changes to your personal data such as an address change or a new email.
							</p>
						</div>
						<div id='block7' className='info-page--paragraph'>
							<strong>Use of Websites</strong>
							<p>
								You agree that you will only use these Websites and our services for the lawful purposes
								expressly permitted and contemplated by these Terms of Service. You may not use these Websites
								and our services for any other purposes, including but not limited to commercial purposes,
								without our express written consent.
							</p>
							<p>
								You agree that you will view these Websites and their content unaltered and unmodified. You
								acknowledge and understand that you are prohibited from modifying these Websites or eliminating
								any of the content of these Websites, including ads. By using these Websites, you expressly
								agree to accept advertising served on and through these Websites and to refrain from using ad
								blocking software or to disable ad blocking software before visiting these Websites.
							</p>
							<p>
								Content is provided to you AS IS. You may access Content for your information and personal use
								solely as intended through the provided functionality of these Websites and as permitted under
								these Terms of Service. You shall not download any Content unless you see a "download" or
								similar link displayed by these Websites for that Content. You shall not copy, reproduce,
								distribute, transmit, broadcast, display, sell, license, or otherwise exploit any Content for
								any other purposes without the prior written consent of these Websites’ operator or the
								respective licensors of the Content. The Websites and their licensors reserve all rights not
								expressly granted in and to these Websites and the Content.
							</p>
						</div>
						<div id='block7' className='info-page--paragraph'>
							<strong>Prohibited Uses</strong>
							<p>
								You agree that you will not use or attempt to use any method, device, software, or routine to
								harm others or interfere with the functioning of any of these WebsitesWebsites or use and/or
								monitor any information in or related to these Websites for any unauthorized purpose.
							</p>
							<p>
								In addition to the general restrictions above, the following restrictions and conditions apply
								specifically to your use of Content. Any determination regarding breach of any of the following
								is final. Please review the following list of prohibited uses carefully before using these
								Websites. Specifically, you agree not to use any of these Websites to:
							</p>
							<ul>
								<li>violate any law or encourage or provide instructions to another to do so;</li>
								<li>
									act in a manner that negatively affects other users’ ability to use these Websites, including
									without limitation by engaging in conduct that is harmful, threatening, abusive,
									inflammatory, intimidating, violent or encouraging of violence to people or animals,
									harassing, stalking, invasive of another’s privacy, or racially, ethnically, or otherwise
									objectionable;
								</li>
								<li>
									post any Content that depicts any person under 18 years of age (or older in any other
									location in which 18 is not the minimum age of majority) whether real or simulated;
								</li>
								<li>
									post any Content for which you have not maintained written documentation sufficient to
									confirm that all subjects of your posts are, in fact, over 18 years of age (or older in any
									other location in which 18 is not the minimum age of majority);
								</li>
								<li>
									post any Content depicting underage sexual activity, non-consensual sexual activity, revenge
									porn, blackmail, intimidation, snuff, torture, death, violence, incest, racial slurs, or hate
									speech, (either orally or via the written word);
								</li>
								<li>
									post any Content that contains falsehoods or misrepresentations that could damage these
									Websites or any third party;
								</li>
								<li>
									post any Content that is obscene, illegal, unlawful, fraudulent, defamatory, libelous,
									harassing, hateful, racially or ethnically offensive, or encourages conduct that would be
									considered a criminal offense, give rise to civil liability, violate any law, or is otherwise
									inappropriate;
								</li>
								<li>
									post any Content containing unsolicited or unauthorized advertising, promotional materials,
									spam, junk mail, chain letters, pyramid schemes, or any other form of unauthorized
									solicitation;
								</li>
								<li>
									post any Content containing sweepstakes, contests, or lotteries, or otherwise related to
									gambling;
								</li>
								<li>
									post any Content containing copyrighted materials, or materials protected by other
									intellectual property laws, that you do not own or for which you have not obtained all
									necessary written permissions and releases;
								</li>
								<li>
									post any Content which impersonates another person or falsely states or otherwise
									misrepresents your affiliation with a person;
								</li>
								<li>
									use these Websites (or post any Content that) in any way that promotes or facilitates
									prostitution, solicitation of prostitution, human trafficking, or sex trafficking;
								</li>
								<li>
									use these Websites to arrange any in-person meetings for purposes of sexual activity for
									hire;
								</li>
								<li>
									deploy programs, software, or applications designed to interrupt, destroy or limit the
									functionality of any computer software or hardware or telecommunications equipment, including
									by engaging in any denial-of-service attack or similar conduct;
								</li>
								<li>
									deploy or use programs, software or applications designed to harm, interfere with the
									operation of, or access in an unauthorized manner, services, networks, servers, or other
									infrastructure;
								</li>
								<li>exceed your authorized access to any portion of these Websites;</li>
								<li>
									remove, delete, alter, circumvent, avoid, or bypass any digital rights management technology,
									encryption or security tools used anywhere on these Websites or in connection with our
									services;
								</li>
								<li>collect or store personal data about anyone;</li>
								<li>
									alter or modify without permission any part of these Websites or their content, including
									ads;
								</li>
								<li>
									obtain or attempt to access or otherwise obtain any Content or information through any means
									not intentionally made available or provided for through these Websites;
								</li>
								<li>
									exploit errors in design, features which are not documented, and/or bugs to gain access that
									would otherwise not be available.
								</li>
							</ul>
							<p>Additionally, you agree not to:</p>
							<ul>
								<li>
									use these Websites in any manner that could disable, overburden, damage, or impair the site
									or interfere with any other party’s use of these Websites, including their ability to engage
									in real time activities through these Websites;
								</li>
								<li>
									use any robot, spider, or other automatic device, process, or means to access these Websites
									for any purpose, including monitoring or copying any of the material on these Websites
									without our prior written consent;
								</li>
								<li>
									use any manual process to monitor or copy any of the material on these Websites or for any
									other unauthorized purpose without our prior written consent;
								</li>
								<li>
									use any information obtained from or through these Websites to block or interfere with the
									display of any advertising on these Websites, or for the purpose of implementing, modifying
									or updating any software or filter lists that block or interfere with the display of any
									advertising on these Websites;
								</li>
								<li>
									use any device, bots, scripts, software, or routine that interferes with the proper working
									of these Websites or that shortcut or alter Websites functions to run or appear in ways that
									are not intended by these Websites’ design;
								</li>
								<li>
									introduce or upload any viruses, Trojan horses, worms, logic bombs, time bombs, cancelbots,
									corrupted files or any other similar software, program, or material which is malicious or
									technologically harmful or that that may damage the operation of another’s property or of
									these Websites or our services;
								</li>
								<li>
									attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of these
									Websites, the server on which these Websites are stored, or any server, computer, or database
									connected to these Websites;
								</li>
								<li>
									remove any copyright or other proprietary notices from our Websites or any of the materials
									contained therein;
								</li>
								<li>
									attack these Websites via a denial-of-service attack or a distributed denial-of-service
									attack;
								</li>
								<li>otherwise attempt to interfere with the proper working of these Websites.</li>
							</ul>
						</div>
						<div id='block7' className='info-page--paragraph'>
							<strong>Monitoring and Enforcement; Termination</strong>
							<p>We have the right but not the obligation to:</p>
							<ul>
								<li>
									remove or refuse to post any Content you submit or contribute to these Websites for any or no
									reason in our sole discretion;
								</li>
								<li>
									monitor any communication occurring on or through these Websites to confirm compliance with
									these Terms of Service, the security of these Websites, or any legal obligation;
								</li>
								<li>
									take any action with respect to any Content posted by you that we deem necessary or
									appropriate in our sole discretion, including if we believe that such Content violates these
									Terms of Service, infringes any intellectual property right or other right of any person or
									entity, threatens the personal safety of users of these Websites or the public, or could
									create liability for us;
								</li>
								<li>
									disclose your personally identifying information or other information about you to any third
									party who claims that Content posted by you violates their rights, including their
									intellectual property rights, or their right to privacy;
								</li>
								<li>
									take appropriate legal action, including without limitation, referral to law enforcement, for
									any illegal or unauthorized use of these Websites;
								</li>
								<li>
									terminate or suspend your access to all or part of these Websites for any or no reason,
									including without limitation, any violation of these Terms of Service.
								</li>
							</ul>
							<p>
								Without limiting the foregoing, we have the right to fully cooperate with any law enforcement
								authorities or court order requesting or directing us to disclose the identity or other
								information of anyone posting any Content on or through these Websites. YOU WAIVE AND HOLD US
								HARMLESS AND OUR WEBSITE OPERATORS, THEIR PARENT CORPORATION, THEIR RESPECTIVE AFFILIATES,
								LICENSORS, SERVICE PROVIDERS, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, SUCCESSORS, AND ASSIGNS
								FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY US OR ANY OF THE FOREGOING PARTIES DURING OR
								AS A RESULT OF ITS INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS
								BY EITHER US, SUCH PARTIES, OR LAW ENFORCEMENT AUTHORITIES.
							</p>
							<p>
								The Websites take a powerful stand against any form of child exploitation or human trafficking.
								If we discover that any Content involves underage individuals, or any form of force, fraud, or
								coercion, we will remove the Content and submit a report to the proper law enforcement
								authorities. If you become aware of any such Content, you agree to report it to these Websites
								by contacting legal@nsa1.com.
							</p>
						</div>
						<div id='block7' className='info-page--paragraph'>
							<strong>Account Termination Policy</strong>
							<p>
								While pornographic and adult-oriented Content is accepted, these Websites reserve the right to
								decide whether Content is appropriate or violates these Terms of Service for reasons other than
								copyright infringement and violations of intellectual property rights, such as, but not limited
								to, obscene or defamatory material. The Websites may at any time, without prior notice and in
								their sole discretion, remove such Content and/or terminate a user’s account for submitting such
								material in violation of these Terms of Service.
							</p>
							<p>
								If you violate the letter or spirit of these Terms of Service, or otherwise create risk or
								possible legal exposure for us, we can terminate access to these Websites or stop providing all
								or part of these Websites to you.
							</p>
						</div>
						<div id='block7' className='info-page--paragraph'>
							<strong>Copyrights and Other Intellectual Property</strong>
							<p>
								The Websites operates a clear copyright policy in relation to any Content alleged to infringe
								the copyright of a third party. Details of that policy can be found at {privacyLink}. If you
								believe that any Content violates your copyright, please see our Copyright Policy {siteLink}for
								instructions on sending us a notice of copyright infringement. As part of our Copyright Policy,
								these Websites will terminate user access to these Websites if, under appropriate circumstances,
								a user has been determined to be a repeat infringer.
							</p>
							<p>
								The Websites are not in a position to mediate trademark disputes between users and trademark
								owners. Accordingly, we encourage trademark owners to resolve any dispute directly with the user
								in question or seek any resolution in court or by other judicial means. If you’re sure you want
								to report content on these Websites that you believe infringes your trademark, you can do so by
								contacting {siteLink}. The Websites are willing to perform a limited investigation of reasonable
								complaints and will remove content in clear cases of infringement. Only the trademark owner or
								their authorized representative may file a report of trademark infringement. Please note that we
								regularly provide the rights owner’s name, your email address and the details of your report to
								the person who posted the content you are reporting. This person may contact you with the
								information you provide.
							</p>
						</div>
						<div id='block7' className='info-page--paragraph'>
							<strong>Abuse Reporting</strong>
							<p>
								The Websites do not permit any form of revenge porn, blackmail, or intimidation. Violations of
								this policy can be reported through the following link: {siteLink}.
							</p>
						</div>
						valid
						<div id='block7' className='info-page--paragraph'>
							<strong>Reliance on Information Posted</strong>
							<p>
								The information presented on or through these Websites is made available solely for general
								information purposes. We do not warrant the accuracy, completeness, or usefulness of this
								information. Any reliance you place on such information is strictly at your own risk. We
								disclaim all liability and responsibility arising from any reliance placed on such materials by
								you or any other visitor to these Websites, or by anyone who may be informed of any of its
								contents.
							</p>
							<p>
								The Websites include Content provided by third parties, including materials provided by other
								users, bloggers and third-party licensors, syndicators, aggregators, and/or reporting services.
								All statements and/or opinions expressed in these materials, and all articles and responses to
								questions and other content, other than the content provided by us, are solely the opinions and
								the responsibility of the person or entity providing those materials. These materials do not
								necessarily reflect our opinion. We are not responsible, or liable to you or any third party,
								for the content or accuracy of any materials provided by any third parties.
							</p>
						</div>
						<div id='block7' className='info-page--paragraph'>
							<strong>Changes to these Websites</strong>
							<p>
								We may update the content on these Websites from time to time, but their content is not
								necessarily complete or up-to-date. Any of the material on these Websites may be out of date at
								any given time, and we are under no obligation to update such material.
							</p>
						</div>
						<div id='block7' className='info-page--paragraph'>
							<strong>Information about You and Your Visits to these Websites</strong>
							<p>
								All information we collect on these Websites is subject to our Privacy Policy located at
								{privacyLink}. By using these Websites, you acknowledge that you have read and understand the
								terms of the Privacy Policy and that you consent to all actions taken by us with respect to your
								information in compliance with the Privacy Policy.
							</p>
						</div>
						<div id='block7' className='info-page--paragraph'>
							<strong>Collection and Use of Your Usage Information by Advertisers and Others</strong>
							<p>
								The Websites allow others to display advertisements using these Websites. These third parties
								use technology to deliver advertisements you see using these Websites directly to your browser.
								In doing so, they may automatically receive your IP, or "Internet Protocol", address. Others
								that place advertising using these Websites may have the ability to use cookies and/or web
								beacons to collect information, including information about your usage of these Websites. We do
								not control the processes that advertisers use to collect information. However, IP addresses,
								cookies and web beacons alone generally cannot be used to identify individuals, only machines.
								Therefore, advertisers and others whose advertisements or content may be provided through the
								service generally will not know who you are unless you provide additional information to them,
								by responding to an advertisement, by entering into an agreement with them, or by some other
								means.
							</p>
						</div>
						<div id='block7' className='info-page--paragraph'>
							<strong>Linking to these Websites and Social Media Features</strong>
							<p>
								You may link to these Websites, provided you do so in a way that is fair and legal and does not
								damage our reputation or take advantage of it, but you must not establish a link in such a way
								as to suggest any form of association, approval, or endorsement on our part without our express
								written consent.
							</p>
							<p>The Websites may provide certain social media features that enable you to:</p>
							<ul>
								<li>
									link from your own or certain third-party websites to certain content on these Websites;
								</li>
								<li>
									send emails or other communications with certain content, or links to certain content, on
									these Websites;
								</li>
								<li>
									cause limited portions of content on these Websites to be displayed or appear to be displayed
									on your own or certain third-party websites.
								</li>
								<p>
									You may use these features solely as they are provided by us and solely with respect to the
									content they are displayed with and otherwise in accordance with any additional terms and
									conditions we provide with respect to such features. Subject to the foregoing, you must not:
								</p>
								<ul>
									<li>
										cause these Websites or portions of these Websites to be displayed, or appear to be
										displayed by, for example, framing, deep linking or in-line linking, on any other site,
									</li>
									<li>
										otherwise take any action with respect to the materials on these Websites that is
										inconsistent with any other provision of these Terms of Service.
									</li>
								</ul>
								<p>
									The sites from which you are linking, or on which you make certain content accessible, must
									comply in all respects with the content standards set out in these Terms of Service.
								</p>
								<p>
									You agree to cooperate with us in causing any unauthorized framing or linking immediately to
									cease. We reserve the right to withdraw linking permission without notice.
								</p>
								<p>
									We may disable all or any social media features and any links at any time without notice in
									our sole discretion.
								</p>
							</ul>
						</div>
						<div id='block7' className='info-page--paragraph'>
							<strong>Links from these Websites</strong>
							<p>
								If these Websites contain links to other sites and resources provided by third parties, these
								links are provided for your convenience only. This includes links contained in advertisements,
								including banner advertisements and sponsored links. We have no control over, and assume no
								responsibility for, the contents, privacy policies, or practices of those sites or resources,
								and accept no responsibility for them or for any loss or damage that may arise from your use of
								them. Inclusion of, linking to, or permitting the use or installation of any third-party site,
								applications, software, content, or advertising does not imply approval or endorsement thereof
								by us. If you decide to access any of the third-party sites linked to these Websites, you do so
								entirely at your own risk and subject to the terms and conditions of use for such sites.
								Further, you agree to release us from any and all liability arising from your use of any
								third-party website, content, service, or software accessed through these Websites.
							</p>
							<p>
								Your communications or dealings with, or participation in promotions of, sponsors, advertisers,
								or other third parties found through these Websites, are solely between you and such third
								parties. You agree that these Websites shall not be responsible or liable for any loss or damage
								of any sort incurred as the result of any dealings with such sponsors, third parties or
								advertisers, or as the result of their presence on these Websites.
							</p>
						</div>
						<div id='block7' className='info-page--paragraph'>
							<strong>Permitted Disclosures of Personal Information</strong>
							<p>
								The Websites generally do not collect personally identifiable information (data such as your
								name, email address, password, and the content of your communications) unless you submit or
								communicate Content through these Websites or register with us in order to use certain features
								of these Websites. The Websites will not disclose any personally identifiable information they
								collects or obtains except: (i) as described in these Terms of Service or our Privacy Policy;
								(ii) after obtaining your permission to a specific use or disclosure; (iii) if these Websites
								are required to do so in order to comply with any valid legal process or governmental request
								(such as a court order, search warrant, subpoena, civil discovery request, or statutory
								requirement) and may in our sole discretion advise you of such process or request; (iv) as
								required to protect these Websites’ property, safety, or operations, or the property or safety
								of others; or (v) to a person that acquires these Websites, or the assets of these Websites’
								operator in connection with which such information has been collected or used; or (vi) as
								otherwise required by law. If these Websites are required to respond to or comply with any of
								these information requests, we reserve the right to charge you with the cost of responding to or
								complying with such information request, including, but not limited to, costs of research,
								copies, media storage, mail, and document delivery, as well as any applicable legal fees.
							</p>
							<p>
								The Websites will have access to all information that you have submitted or created for as long
								as reasonably required to comply with or investigate any information requests, or to protect
								these Websites. To enforce these Terms of Services, to protect intellectual property rights, to
								comply with legal processes and the law, and to protect these Websites, you agree to allow these
								Websites to access your information.
							</p>
						</div>
						<div id='block7' className='info-page--paragraph'>
							<strong>Indemnification</strong>
							<p>
								To the extent permitted by applicable law, you agree to defend, indemnify and hold harmless
								these Websites, their site operator, their parent corporation, their respective affiliates,
								licensors, service providers, officers, directors, employees, agents, successors and assigns
								from and against any and all claims, damages, judgments, awards, obligations, losses,
								liabilities, costs or debt, and expenses (including but not limited to attorney’s fees) arising
								from: (i) your use of and access to these Websites; (ii) your violation of any term of these
								Terms of Service; (iii) your violation of any third party right, including without limitation
								any copyright, property, or privacy right; or (iv) any claim that your Content caused damage to
								a third party. This defense and indemnification obligation will survive these Terms of Service
								and your use of these Websites. You agree that we shall have the sole right and obligation to
								control the legal defense against any such claims, demands, or litigation, including the right
								to select counsel of our choice and to compromise or settle any such claims, demands, or
								litigation.
							</p>
						</div>
						<div id='block7' className='info-page--paragraph'>
							<strong>Disclaimers</strong>
							<p>
								YOU USE THESE WEBSITES AT YOUR SOLE RISK. WE PROVIDE THESE WEBSITES "AS IS" AND "AS AVAILABLE".
								TO THE FULLEST EXTENT PERMITTED BY LAW, THESE WEBSITES, THEIR WEBSITE OPERATOR, AND THEIR
								RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS DISCLAIM ALL WARRANTIES OF ANY KIND
								RELATED TO THESE WEBSITES AND GOODS OR SERVICES PURCHASED AND OBTAINED THROUGH THESE WEBSITES,
								WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
								MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. YOU WILL BE SOLELY
								RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM YOUR USE OF
								THESE WEBSITES. WE MAKE NO WARRANTY OR REPRESENTATION ABOUT THE ACCURACY OR COMPLETENESS OF
								THESE WEBSITES’ CONTENT OR THE CONTENT OF ANY SITES LINKED TO THESE WEBSITES OR THAT THESE
								WEBSITES WILL MEET YOUR REQUIREMENTS AND ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (I)
								ERRORS, MISTAKES, OR INACCURACIES OF CONTENT, (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY
								NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THESE WEBSITES OR OUR SERVICES,
								(III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL
								INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (IV) ANY INTERRUPTION OR CESSATION OF
								TRANSMISSION TO OR FROM THESE WEBSITES OR OUR SERVICES, (IV) ANY BUGS, VIRUSES, TROJAN HORSES,
								OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THESE WEBSITES OR OUR SERVICES BY ANY THIRD
								PARTY, AND/OR (V) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE OF ANY KIND
								INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE
								VIA THESE WEBSITES OR OUR SERVICES. THESE WEBSITES DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
								RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THESE
								WEBSITES OR OUR SERVICES OR ANY HYPERLINKED SERVICES OR FEATURED IN ANY BANNER OR OTHER
								ADVERTISING AND, THESE WEBSITES WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR
								MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS
								WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD
								USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
							</p>
							<p>
								NO INFORMATION OBTAINED BY YOU FROM US OR THROUGH THESE WEBSITES SHALL CREATE ANY WARRANTY NOT
								EXPRESSLY STATED IN THESE TERMS.
							</p>
						</div>
						<div id='block7' className='info-page--paragraph'>
							<strong>Limitation of Liability</strong>
							<p>
								IN NO EVENT SHALL THESE WEBSITES, THEIR WEBSITE OPERATOR, AND THEIR RESPECTIVE OFFICERS,
								DIRECTORS, EMPLOYEES, OR AGENTS, BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
								PUNITIVE, OR CONSEQUENTIAL DAMAGES WHATSOEVER RESULTING FROM ANY (I) ERRORS, MISTAKES, OR
								INACCURACIES OF CONTENT, (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER,
								RESULTING FROM YOUR ACCESS TO AND USE OF OUR WEBSITES OR SERVICES, (III) ANY UNAUTHORIZED ACCESS
								TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL
								INFORMATION STORED THEREIN, (IV) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM OUR
								WEBSITES, (IV) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE, WHICH MAY BE TRANSMITTED TO OR
								THROUGH OUR WEBSITES OR SERVICES BY ANY THIRD PARTY, (V) ANY ERRORS OR OMISSIONS IN ANY CONTENT
								OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF YOUR USE OF ANY CONTENT POSTED,
								TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA OUR WEBSITES OR SERVICES, AND/OR (VI) INTERACTIONS
								YOU HAVE WITH THIRD-PARTY ADVERTISEMENTS OR SERVICE PROVIDERS, OR THIRD-PARTY SITES, FOUND ON OR
								THROUGH THE WEBSITES, INCLUDING PAYMENT AND DELIVERY OF RELATED GOODS OR SERVICES, AND ANY OTHER
								TERMS, CONDITIONS, POLICIES, WARRANTIES OR REPRESENTATIONS ASSOCIATED WITH SUCH DEALINGS,
								WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT THESE
								WEBSITES OR THEIR SITE OPERTOR ARE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING
								LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE
								JURISDICTION.
							</p>
							<p>
								YOU SPECIFICALLY ACKNOWLEDGE THAT THESE WEBSITES SHALL NOT BE LIABLE FOR CONTENT OR THE
								DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY THIRD PARTY, AND THAT THE RISK OF HARM OR
								DAMAGE FROM THE FOREGOING RESTS ENTIRELY WITH YOU.
							</p>
							<p>
								YOU FURTHER ACKNOWLEDGE THAT ANY CONTENT UPLOADED TO THIS WEBSITE MAY BE VIEWED, DOWNLOADED,
								REPUBLISHED, AND DISTRIBUTED – POTENTIALLY IN VIOLATION OF YOUR RIGHTS OR THIS AGREEMENT AND
								THAT YOU ASSUME SUCH RISKS AS A MATERIAL PART OF THESE TERMS OF SERVICE.
							</p>
							<p>
								YOU AGREE NOT TO FILE ANY LAWSUIT OR PROCEEDING INCONSISTENT WITH THE FOREGOING LIABILITY
								LIMITATIONS.
							</p>
							<p>
								Any claim by you that may arise in connection with these Terms of Service will be compensable by
								monetary damages, and you will in no event be entitled to injunctive or other equitable relief.
							</p>
						</div>
						<div id='block7' className='info-page--paragraph'>
							<strong>Limitation on Time to File Claims</strong>
							<p>
								REGARDLESS OF ANY STATUTE OR LAW TO THE CONTRARY, ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE
								ARISING OUT OF OR RELATING TO THESE TERMS OF SERVICE OR THESE WEBSITES MUST BE COMMENCED WITHIN
								ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS
								PERMANENTLY BARRED.
							</p>
						</div>
						<div id='block7' className='info-page--paragraph'>
							<strong>Notice of Litigation</strong>
							<p>
								You shall notify us in writing within two (2) working days after becoming aware of or obtaining
								knowledge of, any proceedings or threatened proceedings, including any litigation or
								governmental proceeding pending against you which could materially adversely affect our
								business, operations, prospects, property, assets, or condition (financial or otherwise); and
								any other event which is likely to materially adversely affect our business, operations,
								prospects, property, assets, or condition (financial or otherwise).
							</p>
						</div>
						<div id='block7' className='info-page--paragraph'>
							<strong>Your Comments and Concerns</strong>
							<p>
								All notices of copyright infringement claims should be sent to the copyright agent designated in
								our Copyright Policy at {siteLink} in the manner and by the means set forth therein.
							</p>
							<p>
								All other feedback, comments, requests for technical support and other communications relating
								to these Websites should be directed to: support@NSA1.com.
							</p>
							<p>
								All records will be stored and kept by NATIONAL STRIP CLUB ASSOCIATION INC. at 13 Evelyn Ct.,
								Columbia SC 29210
							</p>
						</div>
						<div id='block7' className='info-page--paragraph'>
							<strong>Assignment</strong>
							<p>
								These Terms of Service, and any rights and licenses granted hereunder, may not be transferred or
								assigned by you, but may be assigned by us without restriction.
							</p>
						</div>
						<div id='block7' className='info-page--paragraph'>
							<strong>Fees</strong>
							<p>
								You acknowledge that these Websites reserves the right to charge for their services and to
								change their fees from time to time in their sole discretion. Furthermore, in the event these
								Websites terminates your rights to use these Websites because of a breach of these Terms of
								Services, you shall not be entitled to the refund of any unused portion of subscription fees or
								content purchased. In the event these Websites are required to incur fees or expenses as a
								result of your activity, you agree to reimburse any such fees or expenses.
							</p>
						</div>
						<div id='block7' className='info-page--paragraph'>
							<strong>Miscellaneous</strong>

							<p>
								No waiver by us of any term or condition set forth in these Terms of Service shall be deemed a
								further or continuing waiver of such term or condition or a waiver of any other term or
								condition, and any failure by us to assert a right or provision under these Terms of Service
								shall not constitute a waiver of such right or provision.
							</p>
							<p>
								If any provision of these Terms of Service is deemed invalid by a court of competent
								jurisdiction, the invalidity of such provision shall not affect the validity of the remaining
								provisions of these Terms of Service, which shall remain in full force and effect.
							</p>
							<p>
								The Terms of Service, our Privacy Policy, our Copyright Policy, and any documents they expressly
								incorporate by reference constitute the sole and entire agreement between you and us with
								respect to these Websites.
							</p>
							<p>
								We may terminate these Terms of Service for any or no reason at any time by notifying you
								through a notice on these Websites, by email, or by any other method of communication. Any such
								termination will be without prejudice to our rights, remedies, claims, or defenses hereunder.
								Upon termination of the Terms of Service, you will no longer have a right to access your account
								or your Content. We will not have any obligation to assist you in migrating your data or your
								Content and we may not keep any back up of any of your Content. We undertake no responsibility
								for deleting your Content under these Terms of Service. Note that, even if your Content is
								deleted from our active servers, it may remain in our archives (but we have no obligation to
								archive or back-up your Content), and subject to the licenses set forth in these Terms of
								Service.
							</p>
						</div>
					</div>
				</div>
				{/* <div className='info-page--right'>
					<div className='info-page-menu'>
						<ul>
							<li>
								<Link to='#acceptance'>Acceptance of the Terms of Service</Link>
							</li>
							<li>
								<a href='#block2'>The data we collect about you</a>
							</li>
							<li>
								<a href='#block3'>How is your personal information collected?</a>
							</li>
							<li>
								<a href='#block4'>User contributions</a>
							</li>
							<li>
								<a href='#block5'>Information collected througn automatic data collection technologies</a>
							</li>
							<li>
								<a href='#block6'>Third-party use of cookies and other tracking technologies</a>
							</li>
							<li>
								<a href='#block7'>How we use your personal data</a>
							</li>
							<li>
								<a href='#block1'>Purposes for which we use your personal information</a>
							</li>
							<li>
								<a href='#block1'>Disclosure of your personal information</a>
							</li>
							<li>
								<a href='#block1'>Financial information</a>
							</li>
							<li>
								<a href='#block1'>Trannsfers of your personal information to other countries</a>
							</li>
							<li>
								<a href='#block1'>Retention of personal information</a>
							</li>
							<li>
								<a href='#block1'>How we protect the security of your personal information</a>
							</li>
							<li>
								<a href='#block1'>
									Choices about how we collect, <br />
									use and disclose your personal information
								</a>
							</li>
							<li>
								<a href='#block1'>Your rights related to your personal information</a>
							</li>
							<li>
								<a href='#block1'>Changes to our privacy policy</a>
							</li>
							<li>
								<a href='#block1'>Enforcement; cooperation</a>
							</li>
							<li>
								<a href='#block1'>No rights of third parties</a>
							</li>
							<li>
								<a href='#block1'>Our policy towards minors</a>
							</li>
							<li>
								<a href='#block1'>No error free performance</a>
							</li>
							<li>
								<a href='#block1'>Contact information</a>
							</li>
							<li>
								<a href='#block1'>General data protecton regulation</a>
							</li>
						</ul>
					</div>
				</div> */}
			</section>
		</main>
	);
};

export default TermsAndConditions;
