import CustomModal from '../../../custom/Modal/Modal';
import styles from './index.module.scss';
import { ReactComponent as CloseButton } from '../../../svgs/close-button.svg';
import Button from '../../../custom/btn/Btn';
import classNames from 'classnames';
import { MdEdit } from 'react-icons/md';
import { ImFileZip } from 'react-icons/im';
import { useState } from 'react';

const ProductDetail = ({
  open = false,
  handleProductDetailsClose,
  handleProductDetails,
}) => {
  const [permission, setPermission] = useState(false);

  return (
    <CustomModal open={open}>
      <main className={styles.container}>
        <span className={styles.buttonContainer}>
          <CloseButton onClick={handleProductDetailsClose} />
        </span>
        <main>
          <h1 className={styles.title}> Set Product Details</h1>
          <section className={styles.filePreviewContainer}>
            <ImFileZip className={styles.fileIcon} />
            <div className={styles.filePreview}>
              <h5>
                galileo.zip <span>2400mb</span>
              </h5>
              <MdEdit className={styles.zipIcon} />
            </div>
          </section>
          <section className={styles.content}>
            <section>
              <label className={styles.label}>
                Title
                <input
                  className={styles.productTitleInput}
                  placeholder='Event title'
                />
              </label>

              <label className={styles.label}>
                Description
                <textarea
                  rows={5}
                  placeholder='It`s my first travel to beach. And I prepared for you something special'
                  className={styles.descriptionInput}
                />
              </label>

              <label className={styles.label}>
                Permissions
                <div className={styles.permissionButtonContainer}>
                  <span
                    className={classNames(
                      styles.permissionButton,
                      permission && styles.active
                    )}
                    onClick={() => setPermission(true)}
                  >
                    Show in Fan Club
                  </span>
                  <span
                    className={classNames(
                      styles.permissionButton,
                      permission === false && styles.active
                    )}
                    onClick={() => setPermission(false)}
                  >
                    Hidden
                  </span>
                </div>
              </label>
            </section>
          </section>
        </main>
        <Button
          onClick={handleProductDetails}
          text='Confirm Product Details'
          className={styles.continueButton}
        />
      </main>
    </CustomModal>
  );
};

export default ProductDetail;
