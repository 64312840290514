export const buildConfig = async (configs = {}) => {
  const keys = Object.keys(configs);
  const values = Object.values(configs);
  return keys.map((key, index) => {
    return {
      key,
      value: `${values[index]}`,
    };
  });
};
