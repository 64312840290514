import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import classnames from 'classnames';
import { useRef } from 'react';
import useVideoPlayer from '../../hooks/useVideoPlay';
import { CiPlay1 } from 'react-icons/ci';
import { CgPlayStopR } from 'react-icons/cg';
import { AiOutlineAudioMuted } from 'react-icons/ai';
import { GoUnmute } from 'react-icons/go';

const Video = ({ src, className, alt, videoClass, onLoading, ...props }) => {
  const videoElement = useRef(null);
  const {
    playerState,
    togglePlay,
    handleOnTimeUpdate,
    handleVideoProgress,
    handleVideoSpeed,
    toggleMute,
  } = useVideoPlayer(videoElement);

  useEffect(() => {
    videoElement.current.addEventListener('loadstart', () => {
      if (onLoading) onLoading({ loading: true });
    });

    videoElement.current.addEventListener('loadeddata', () => {
      if (onLoading) onLoading({ loading: false });
    });

    if (videoElement.current.error !== null) {
      if (onLoading) onLoading({ loading: true });
    }
  }, [videoElement]);

  return (
    <div className={classnames(styles.container, className)}>
      <div className={classnames(styles['video-wrapper'], videoClass)}>
        <video
          src={src}
          ref={videoElement}
          onTimeUpdate={handleOnTimeUpdate}
          className={styles.video}
        />
        <div
          id='video-controls'
          className={styles.controls}
          data-state='visible'
        >
          {!playerState.isPlaying ? (
            <CiPlay1 onClick={togglePlay} className={styles.icon} />
          ) : (
            <CgPlayStopR onClick={togglePlay} className={styles.icon} />
          )}
          {/* {!playerState.isMuted ? (
            <AiOutlineAudioMuted onClick={toggleMute} className={styles.icon} />
          ) : (
            <GoUnmute onClick={toggleMute} className={styles.icon} />
          )} */}

          <input
            className={styles.progress}
            type='range'
            min='0'
            max='100'
            value={playerState.progress}
            onChange={(e) => handleVideoProgress(e)}
          />
          {/* <button type='button' onClick={toggleMute}>
            Mute/Unmute
          </button>
          <button id='volinc' type='button' data-state='volup'>
            +
          </button>
          <button id='voldec' type='button' data-state='voldown'>
            -
          </button>
          <button id='fs' type='button' data-state='go-fullscreen'>
            Fullscreen
          </button> */}

          {/* <select
            className='velocity'
            value={playerState.speed}
            onChange={(e) => handleVideoSpeed(e)}
          >
            <option value='0.50'>0.50x</option>
            <option value='1'>1x</option>
            <option value='1.25'>1.25x</option>
            <option value='2'>2x</option>
          </select> */}
        </div>
      </div>
    </div>
  );
};

export default Video;
