import React from 'react';
import styles from './styles.module.scss';
import classnames from 'classnames';

const HeaderToolTip = ({ text, classNames, ...props }) => {
	return (
		<h1 className={classnames(styles.heading, classNames)} {...props}>
			{text}
		</h1>
	);
};

export default HeaderToolTip;
