import CustomModal from '../../../custom/Modal/Modal';
import styles from './createEvent.module.scss';
import Button from '../../../custom/btn/Btn';
import classNames from 'classnames';
import moment, { utc } from 'moment';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import DateTimePicker from '../../../custom/datePicker/DatePicker';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import Spinner from '../../../custom/spinner/Spinner';
import { createEvent, saveEvent, deleteEvent } from '../../../request/model';
import { useSelector } from 'react-redux';
import notification from '../../../utils/notification';
import errorFormmatter from '../../../utils/errorFormatter';

const renderDayContent = (day, date) => {
  return <span className={styles.day}>{day}</span>;
};

const EventSchema = Yup.object().shape({
  title: Yup.string()
    .trim()
    .required('title is required'),
  description: Yup.string()
    .trim()
    .required('description is required'),
  startTime: Yup.string().required('start time is required'),
  endTime: Yup.string().required('end time is required'),
  credits: Yup.number().required('credits is required'),
  date: Yup.string().required('date is required'),
});

const initialValue = {
  title: '',
  description: '',
  startTime: '',
  endTime: '',
  credits: '',
  date: '',
}; 

const CreateEvent = ({
  open = false,
  handleOpenModal,
  action = false,
  currentEditingEvent,
}) => {
  const user = useSelector((state) => state?.user);
  const [loading, setLoading] = useState(false);

  const createEventHandle = async (values) => {
    try {
      if (moment(values.date).isBefore(moment().subtract(1, 'days'))) {
        return;
      }
      const payload = new FormData();
      payload.append('title', values.title);
      payload.append('description', values.description);
      payload.append('date', `${values.date}`);
      payload.append('startTime', values.startTime);
      payload.append('endTime', values.endTime);
      payload.append('registeredId', `${user?.clientId}`);
      payload.append('credit', `${values.credits}`);

      setLoading(true);
      const response = await createEvent({ payload });
      handleOpenModal(false);
      notification({ message: response?.message ?? '' });
      setLoading(false);
    } catch (error) {
      notification({
        message: errorFormmatter(error) ?? 'Event failed to create',
        type: 'danger',
      });
      setLoading(false);
    }
  };

  const saveEventHandle = async (values) => {
    try {
      const payload = new FormData();
      payload.append('title', values?.title);
      payload.append('description', values?.description);
      payload.append('date', `${values?.date}`);
      payload.append('startTime', values?.startTime);
      payload.append('endTime', values?.endTime);
      payload.append('registeredId', user?.clientId);
      payload.append('credit', `${values?.credits}`);
      payload.append('id', `${currentEditingEvent?.id}`);

      setLoading(true);
      const response = await saveEvent({ payload });
      handleOpenModal(false);
      notification({ message: response?.message ?? '' });
      setLoading(false);
    } catch (error) {
      notification({
        message: errorFormmatter(error) ?? 'Event failed to update',
        type: 'danger',
      });
      setLoading(false);
    }
  };

  const handleDeleteEvent = async (id) => {
    try {
      const payload = { id: id, modelId: user?.clientId };
      setLoading(true);
      const response = await deleteEvent({ payload });
      handleOpenModal(false);
      notification({ message: response?.message ?? '' });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notification({
        message: 'Error occurred deleting event',
        type: 'danger',
      });
    }
  };

  // const startd = moment(currentEditingEvent.date);
  // let hour = currentEditingEvent.startTime.split(':')[0];
  // let seconds = currentEditingEvent.startTime.split(':')[1];
  // let append = currentEditingEvent.startTime.split(':')[2].trim();
  // console.log(seconds);
  // hour = parseInt(hour);
  // seconds = parseInt(seconds);
  // startd.hours(hour);
  // startd.minutes(seconds);

  return (
    <CustomModal open={open}>
      <main className={styles.container}>
        <section>
          <Formik
            enableReinitialize={true}
            validationSchema={EventSchema}
            initialValues={
              action
                ? {
                    title: currentEditingEvent?.title,
                    description: currentEditingEvent?.description,
                    startTime: currentEditingEvent?.startTime,
                    endTime: currentEditingEvent?.endTime,
                    credits: currentEditingEvent?.credit,
                    date: currentEditingEvent?.date,
                  }
                : initialValue
            }
            onSubmit={!action ? createEventHandle : saveEventHandle}
          >
            {(props) => {
              return (
                <>
                  <h1 className={styles.title}>Event</h1>
                  <label className={styles.label}>
                    Title
                    <input
                      autoComplete='off'
                      className={styles.input}
                      placeholder='Event title'
                      onChange={props.handleChange}
                      name='title'
                      defaultValue={currentEditingEvent?.title}
                    />
                    {props.touched && props.errors?.title && (
                      <span className={styles.error}>
                        * {props.errors?.title}
                      </span>
                    )}
                  </label>

                  <label className={styles.label}>
                    Description
                    <textarea
                      autoComplete='off'
                      onChange={props.handleChange}
                      name='description'
                      className={styles.textArea}
                      placeholder='Event Description'
                      defaultValue={currentEditingEvent?.description}
                    />
                    {props.touched && props.errors?.description && (
                      <span className={styles.error}>
                        * {props.errors?.description}
                      </span>
                    )}
                  </label>
                  <div className={styles.dateTitle}>
                    <h5>Date</h5>
                    {props.touched && props.errors?.date && (
                      <span className={styles.error}>
                        * {props.errors?.date}
                      </span>
                    )}
                    {props.values.date &&
                      moment(props.values.date).isBefore(
                        moment().subtract(1, 'days')
                      ) && (
                        <span className={styles.error}>
                          Selected date should be today's date or date after"
                        </span>
                      )}
                  </div>
                  <section className={styles.eventDateTimeDetails}>
                    <div className={styles.eventDatePickContainer}>
                      <DateTimePicker
                        dateFormat='yyyy-MM-DD'
                        openToDate={
                          currentEditingEvent?.date &&
                          new Date(currentEditingEvent?.date)
                        }
                        onChange={(date) => {
                          props.setFieldValue('date', moment(date).format());
                        }}
                        renderDayContents={renderDayContent}
                        inline
                        className={'eventPopDate'}
                        renderCustomHeader={({
                          date,
                          decreaseMonth,
                          increaseMonth,
                          prevMonthButtonDisabled,
                          nextMonthButtonDisabled,
                        }) => (
                          <div className={styles.dateTimeHeader}>
                            <span className={styles.dateTimeIconContainer}>
                              <FaChevronLeft
                                className={styles.dateIcon}
                                onClick={decreaseMonth}
                                disabled={prevMonthButtonDisabled}
                              />
                            </span>

                            <span className={styles.dateTimeMonthContainer}>
                              {moment(date).format('MMMM')}
                            </span>
                            <span className={styles.dateTimeIconContainer}>
                              <FaChevronRight
                                className={styles.dateIcon}
                                onClick={increaseMonth}
                                disabled={nextMonthButtonDisabled}
                              />
                            </span>
                          </div>
                        )}
                      />
                    </div>
                    <div className={styles.eventDetailContainer}>
                      <h1 className={styles.date}>
                        {moment(currentEditingEvent?.date).format(
                          'dddd, Do  MMMM YYYY'
                        )}
                      </h1>
                      <div className={styles.eventStartEndContainer}>
                        <label className={styles.label}>
                          Start
                          <DateTimePicker
                            // openToDate={currentEditingEvent?.startTime}
                            showTimeSelect
                            onChange={(time) => {
                              props.setFieldValue(
                                'startTime',
                                moment(time).format('h:mm: a')
                              );
                            }}
                            showTimeSelectOnly
                            dateFormat='h:mm aa'
                            renderElement={({ onClick, value, onChange }) => {
                              return (
                                <div
                                  className={styles.eventDetail}
                                  onClick={() => {
                                    onClick();
                                  }}
                                >
                                  {/* {props.values.startTime &&
                                  Object.keys(currentEditingEvent).length > 0
                                    ? props.values.startTime
                                    : value} */}{' '}
                                  {value}
                                </div>
                              );
                            }}
                          />
                          {props.touched && props.errors?.startTime && (
                            <span className={styles.error}>
                              * {props.errors?.startTime}
                            </span>
                          )}
                        </label>
                        <label className={styles.label}>
                          end
                          <DateTimePicker
                            // openToDate={currentEditingEvent?.endTime}
                            showTimeSelect
                            onChange={(time) => {
                              props.setFieldValue(
                                'endTime',
                                moment(time).format('h:mm: a')
                              );
                            }}
                            showTimeSelectOnly
                            dateFormat='h:mm aa'
                            renderElement={({ onClick, value }) => {
                              return (
                                <div
                                  className={styles.eventDetail}
                                  onClick={() => {
                                    onClick();
                                  }}
                                >
                                  {/* {props.values.endTime &&
                                  Object.keys(currentEditingEvent).length > 0
                                    ? props.values.endTime
                                    : value} */}
                                  {value}
                                </div>
                              );
                            }}
                          />
                          {props.touched && props.errors?.endTime && (
                            <span className={styles.error}>
                              * {props.errors?.endTime}
                            </span>
                          )}
                        </label>
                      </div>

                      <label className={styles.label}>
                        Credits
                        <div className={styles.eventCreditContainer}>
                          <div className={styles.creditContainer}>
                            <input
                              autoComplete='off'
                              defaultValue={currentEditingEvent?.credit}
                              onChange={props.handleChange}
                              name='credits'
                              type={'number'}
                              className={styles.creditInput}
                              placeholder='0+'
                            />
                          </div>

                          {action && (
                            <Button
                              onClick={() =>
                                handleDeleteEvent(currentEditingEvent?.id)
                              }
                              text='Delete Event'
                              className={styles.deleteButton}
                            ></Button>
                          )}
                        </div>
                        {props.touched && props.errors?.credits && (
                          <span className={styles.error}>
                            * {props.errors?.credits}
                          </span>
                        )}
                      </label>
                    </div>
                  </section>
                  <div className={styles.actionButtonContainer}>
                    {loading && <Spinner background={false} isTip={false} />}
                    <Button
                      text='Cancel'
                      className={styles.cancelButton}
                      onClick={() => handleOpenModal(false)}
                    />
                    <Button
                      text={action ? 'Save' : 'Create'}
                      type='submit'
                      className={styles.saveButton}
                      onClick={() => {
                        props.handleSubmit();
                      }}
                    />
                  </div>
                </>
              );
            }}
          </Formik>
        </section>
      </main>
    </CustomModal>
  );
};

export default CreateEvent;
