import React, { useState, useEffect } from 'react';
import Scrollbar from 'react-scrollbars-custom';
import axios from 'axios';
import { nanoid } from 'nanoid';
import notification from '../../utils/notification';
import errorFormmatter from '../../utils/errorFormatter';
import validateInput from '../../utils/validationSchema';
import * as yup from 'yup';
import { useQueries } from 'react-query';
import { PatternFormat } from 'react-number-format';

const ClubLkInfo = () => {
	const [firstName, setFirstName] = useState('First Name');
	const [lastName, setLastName] = useState('Last Name');
	const [build, setBuild] = useState('Build');
	const [country, setCountry] = useState('Country');
	const [countries, setCountries] = useState([]);
	const [countryId, setCountryId] = useState('');
	const [nationality, setNationality] = useState('Nationality');
	const [city, setCity] = useState('City');
	const [clubName, setClubName] = useState('Club Name');
	const [street, setStreet] = useState('Street');
	const [site, setSite] = useState('Site');
	const [homePhone, setHomePhone] = useState('Home Phone');
	const [phone, setPhone] = useState('Phone');
	const [apartment, setApartment] = useState('Apartment');
	const [clubCountry, setClubCountry] = useState('Country');
	const [clubCountryId, setClubCountryId] = useState('');
	const [zipCode, setZipCode] = useState('Zip Code');
	const [clientId, setClientId] = useState('');

	const getInitialData = async () => {
		try {
			const {
				data: { models },
			} = await axios.get('/SetUp/GetAllCountries');
			setCountries(models);
		} catch (error) {
			notification({
				title: 'Data Fetching Error',
				message: errorFormmatter(error),
				type: 'danger',
				position: 'top-center',
			});
		}
	};

	const getInitialClubProfile = async () => {
		setModelLoading(true);

		try {
			const {
				data: {
					data: {
						firstName,
						gender,
						nickName,
						cityOfResident,
						nationality,
						country,
						countryId,
						build,
						dateOfBirth,
						lastName,
						aboutMe,
						whatAttractMe,
						myCompetence,
						toy,
						sexualPreference,
						pubicHair,
						language,
						hairColor,
						breastSize,
						bodyChanges,
						eyeColor,
						weight,
						height,
						ethinicity,
					},
				},
			} = await axios.get(`/ModelsProfile/${clientId}/GetModelById`);
		} catch (error) {
			setModelLoading(false);
			notification({
				title: 'Club Loading Error',
				message: errorFormmatter(error),
				type: 'danger',
				position: 'top-center',
			});
		}
	};

	useEffect(() => {
		getInitialData();
	}, []);

	useEffect(() => {
		setClientId(localStorage.getItem('clientId'));
	}, []);

	useEffect(() => {
		getInitialClubProfile();
	}, []);

	function formatTimeInput(idValue, thisElement) {
		let idAlphanumeric = idValue.replace(/\W/g, '').toLowerCase();
		let idLength = idAlphanumeric.length;

		let partOne = idAlphanumeric.slice(0, 2) + ':';
		let partTwo = idAlphanumeric.slice(2, 4) + '';

		let idInput = thisElement;

		if (idLength < 2) {
			idInput.value = idAlphanumeric;
		} else if (idLength >= 2 && idLength < 4) {
			let formatId = partOne + idAlphanumeric.slice(2);
			idInput.value = formatId;
		} else if (idLength === 4) {
			let formatId = partOne + partTwo + idAlphanumeric.slice(4);
			idInput.value = formatId;
		}
	}

	const addActiveClassToDropdowns = e => {
		e.currentTarget.classList.toggle('reg-data-select__active');
	};

	//  options for list of nationalities  dropdown
	const nationalityOptions = countries.length ? (
		countries.map(country => (
			<div
				className='reg-data-select-list--item'
				onClick={e => setNationality(e.target.textContent)}
				key={nanoid()}
				style={{
					display: 'flex',
					paddingInline: '1rem',
					gap: '.1rem',
					alignItems: 'center',
				}}
			>
				<img
					src={`data:image/png;base64,${country.countryFlag}`}
					alt='country flag'
					style={{
						width: '25px',
						height: '25px',
						aspectRatio: '1/1',
						objectFit: 'contain',
					}}
				/>
				{country?.countryName}
			</div>
		))
	) : (
		<p>No Data</p>
	);

	// map options for list of coutries dropdown
	const countryOptions = countries.length ? (
		countries.map(country => (
			<div
				className='reg-data-select-list--item'
				onClick={() => {
					setCountryId(country?.countryId);
					setCountry(country?.countryName);
				}}
				key={nanoid()}
				style={{
					display: 'flex',
					paddingInline: '1rem',
					gap: '.1rem',
					alignItems: 'center',
				}}
			>
				<img
					src={`data:image/png;base64,${country.countryFlag}`}
					alt='country flag'
					style={{
						width: '25px',
						height: '25px',
						aspectRatio: '1/1',
						objectFit: 'contain',
					}}
				/>
				{country?.countryName}
			</div>
		))
	) : (
		<p>No Data</p>
	);

	// map options for list of coutries dropdown
	const clubCountryOptions = countries.length ? (
		countries.map(country => (
			<div
				className='reg-data-select-list--item'
				onClick={() => {
					setClubCountryId(country?.countryId);
					setClubCountry(country?.countryName);
				}}
				key={nanoid()}
				style={{
					display: 'flex',
					paddingInline: '1rem',
					gap: '.1rem',
					alignItems: 'center',
				}}
			>
				<img
					src={`data:image/png;base64,${country.countryFlag}`}
					alt='country flag'
					style={{
						width: '25px',
						height: '25px',
						aspectRatio: '1/1',
						objectFit: 'contain',
					}}
				/>
				{country?.countryName}
			</div>
		))
	) : (
		<p>No Data</p>
	);

	return (
		<main>
			<section className='reg-data'>
				<div className='container'>
					<p className='reg-data--subtitle'>
						To continue, please take a moment to fill out the form. This will greatly assist the public in finding
						you more easily and connecting with you.
					</p>
					<div className='page-title'>Club registration information</div>
					<form>
						<article className='reg-data-row'>
							<div className='reg-data-left'>
								<p className='reg-data-row--title'>
									<span>Main information</span>
								</p>
								<p className='reg-data-row--subtitle'>
									Information for the site administration it <br />
									will not be shown to site visitors
								</p>
							</div>

							<div className='reg-data-right'>
								<div className='reg-data-input'>
									<input
										placeholder='First name'
										type='text'
										name='firstName'
										value={firstName}
										onChange={e => setFirstName(e.target.value)}
									/>
								</div>

								<div className='reg-data-input'>
									<input
										placeholder='Last name'
										type='text'
										name='lastName'
										onChange={e => setLastName(e.target.value)}
										value={lastName}
									/>
								</div>

								<div className='reg-data-input'>
									<div className='reg-data-select' onClick={addActiveClassToDropdowns}>
										<input placeholder='Nationality' type='text' readOnly='readOnly' value={nationality} />
										<div className='reg-data-select-list'>
											<Scrollbar style={{ height: 384 }}>{nationalityOptions}</Scrollbar>
										</div>
									</div>
								</div>

								<div className='reg-data-input placeCustom'>
									<input
										required
										placeholder='City of residence'
										type='text'
										value={city}
										onChange={e => setCity(e.target.value)}
									/>
								</div>

								<div className='reg-data-input'>
									<div className='reg-data-select' onClick={addActiveClassToDropdowns}>
										<input
											placeholder='Country of Residence'
											type='text'
											readOnly='readOnly'
											value={country}
										/>
										<div className='reg-data-select-list'>
											<Scrollbar style={{ height: 384 }}>{countryOptions}</Scrollbar>
										</div>
									</div>
								</div>

								<div className='reg-data-input placeCustom'>
									<PatternFormat required placeholder='Phone Number' type='tel' />
								</div>
							</div>
						</article>

						<div className='reg-data-row'>
							<div className='reg-data-left'>
								<div className='reg-data-row--title'>
									<span>Club information</span>
								</div>
								<div className='reg-data-row--subtitle'>
									Completed information will be displayed <br />
									in the profile and see for users
								</div>
							</div>

							<div className='reg-data-right'>
								<div className='reg-data-input'>
									<input
										placeholder='Club name'
										type='text'
										value={clubName}
										onChange={e => setClubName(e.target.value)}
									/>
								</div>

								<div className='reg-data-input'>
									<input
										placeholder='Street'
										type='text'
										value={street}
										onChange={e => setStreet(e.target.value)}
									/>
								</div>

								<div className='reg-data-input'>
									<input placeholder='Site' type='text' value={site} onChange={e => setSite(e.target.value)} />
								</div>

								<div className='reg-data-input'>
									<input
										placeholder='Home number'
										type='text'
										value={homePhone}
										onChange={e => setHomePhone(e.target.value)}
									/>
								</div>

								<div className='reg-data-input'>
									<input
										placeholder='Phone'
										type='text'
										value={phone}
										onChange={e => setPhone(e.target.value)}
									/>
								</div>

								<div className='reg-data-input'>
									<input
										placeholder='Appartment'
										type='text'
										value={apartment}
										onChange={e => setApartment(e.target.value)}
									/>
								</div>

								<div className='reg-data-input'>
									<div className='reg-data-select' onClick={addActiveClassToDropdowns}>
										<input placeholder='Country' type='text' readOnly='readOnly' value={clubCountry} />
										<div className='reg-data-select-list'>
											<Scrollbar style={{ height: 384 }}>{clubCountryOptions}</Scrollbar>
										</div>
									</div>
								</div>

								<div className='reg-data-input'>
									<input
										placeholder='Zip/Postal'
										type='text'
										value={zipCode}
										onChange={e => setZipCode(e.target.value)}
									/>
								</div>
							</div>
						</div>

						<div className='reg-data-row'>
							<div className='reg-data-left'>
								<div className='reg-data-row--title'>
									<span>Cover</span>
								</div>
								<div className='reg-data-row--subtitle'>
									Completed information will be displayed <br />
									in the profile and see for users
								</div>
							</div>
							<div className='reg-data-right'>
								<label className='dropzone dropzone-cover'>
									<div className='btn btn__purple'>Upload from computer</div>
									<input name='myFile' type='file' id='myFile' placeholder='Upload from computer' />
								</label>
							</div>
						</div>

						<div className='reg-data-row'>
							<div className='reg-data-left'>
								<div className='reg-data-row--title'>
									<span>Logotype</span>
								</div>
								<div className='reg-data-row--subtitle'>
									Completed information will be displayed <br />
									in the profile and see for users
								</div>
							</div>
							<div className='reg-data-right'>
								<label className='dropzone dropzone-logo'>
									<div className='btn btn__purple'>Upload from computer</div>
									<input name='myFile' type='file' id='myFile' placeholder='Upload from computer' />
								</label>
							</div>
						</div>

						<button className='btn btn__red'>Save</button>
					</form>
				</div>
			</section>
		</main>
	);
};
export default ClubLkInfo;

/* <<<<<<< HEAD
import React, { Component } from 'react';
import Scrollbar from 'react-scrollbars-custom';

class ClubLkInfo extends Component {
    componentDidMount () {
        // Кастомный селект
        document.querySelectorAll('.reg-data-select').forEach(function(element) {
            element.onclick = function() {
                this.classList.toggle('reg-data-select__active');
            };
        });
        // Выбор в кастомном селекте
        document.querySelectorAll('.reg-data-select-list--item').forEach(function(element) {
            element.onclick = function() {
                let textContent = this.textContent;
                this.closest('.reg-data-select').querySelector('input').value = textContent;
            };
        });
        // Маска на поле со временем
        document.querySelectorAll('.timeinput input').forEach(function(element) {
            element.oninput = function() {
                let thisElement = element;
                let thisClass = thisElement.className.toUpperCase();
                //let thisClass = thisElement.className.toUpperCase();
                let idValue = element.value;
                idChange(idValue,thisElement,thisClass);
            };
            function idChange(idValue,thisElement,thisClass) {
                let idAlphanumeric = idValue.replace(/\W/g, '').toLowerCase();
                let idLength = idAlphanumeric.length;
            
                let partOne = idAlphanumeric.slice(0, 2) + ":";
                let partTwo = idAlphanumeric.slice(2, 4) + "";
                // let partTwo = idAlphanumeric.slice(2, 4) + " "+thisClass;
            
                let idInput = thisElement;
            
                if (idLength < 2) {
                    idInput.value = idAlphanumeric;
                } else if (idLength >= 2 && idLength < 4) {
                    let formatId = partOne + idAlphanumeric.slice(2);
                    idInput.value = formatId;
                } else if (idLength === 4) {
                    let formatId = partOne + partTwo + idAlphanumeric.slice(4);
                    idInput.value = formatId;
                }
            }
        });
    }
    render() {
        return (
            <main>
                <section className="reg-data">
                    <div className="container">
                        <div className="reg-data--subtitle">
                            Before you start fill in the form, please. It will help users find you easier!
                        </div>
                        <div className="page-title">
                            Club registration information
                        </div>
                        <form>
                            <div className="reg-data-row">
                                <div className="reg-data-left">
                                    <div className="reg-data-row--title">
                                        <span>Main information</span>
                                    </div>
                                    <div className="reg-data-row--subtitle">
                                        Information for the site administration it <br/>
                                        will not be shown to site visitors
                                    </div>
                                </div>
                                <div className="reg-data-right">
                                    <div className="reg-data-input">
                                        <input placeholder="First name" type="text"/>
                                    </div>
                                    <div className="reg-data-input">
                                        <input placeholder="Last name" type="text"/>
                                    </div>
                                    <div className="reg-data-input">
                                        <div className="reg-data-select">
                                            <input placeholder="Build" type="text" readOnly="readOnly"/>
                                            <div className="reg-data-select-list">
                                                <Scrollbar style={{ height: 384 }}>
                                                    <div className="reg-data-select-list--item">
                                                        Athletic
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Average
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        A couple extra pounds
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Strong
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Pleasant fatness
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Slim
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Fatness
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Fatness
                                                    </div>
                                                </Scrollbar>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="reg-data-input">
                                        <input placeholder="Nationality" type="text"/>
                                    </div>
                                    <div className="reg-data-input">
                                        <div className="reg-data-select">
                                            <input placeholder="Country" type="text" readOnly="readOnly"/>
                                            <div className="reg-data-select-list">
                                                <Scrollbar style={{ height: 384 }}>
                                                    <div className="reg-data-select-list--item">
                                                        Athletic
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Average
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        A couple extra pounds
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Strong
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Pleasant fatness
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Slim
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Slim
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Fatness
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Fatness
                                                    </div>
                                                </Scrollbar>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="reg-data-input">
                                        <div className="reg-data-select">
                                            <input placeholder="City of residence" type="text" readOnly="readOnly"/>
                                            <div className="reg-data-select-list">
                                                <Scrollbar style={{ height: 384 }}>
                                                    <div className="reg-data-select-list--item">
                                                        Athletic
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Average
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        A couple extra pounds
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Strong
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Pleasant fatness
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Slim
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Fatness
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Fatness
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Fatness
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Fatness
                                                    </div>
                                                </Scrollbar>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="reg-data-row">
                                <div  className="reg-data-left">
                                    <div className="reg-data-row--title">
                                        <span>Club information</span>
                                    </div>
                                    <div className="reg-data-row--subtitle">
                                        Completed information will be displayed <br/>
                                        in the profile and see for users
                                    </div>
                                </div>
                                <div className="reg-data-right">
                                    <div className="reg-data-input">
                                        <input placeholder="Club name" type="text"/>
                                    </div>
                                    <div className="reg-data-input">
                                        <input placeholder="Street" type="text"/>
                                    </div>
                                    <div className="reg-data-input">
                                        <input placeholder="Site" type="text"/>
                                    </div>
                                    <div className="reg-data-input">
                                        <input placeholder="Home number" type="text"/>
                                    </div>
                                    <div className="reg-data-input">
                                        <input placeholder="Phone" type="text"/>
                                    </div>
                                    <div className="reg-data-input">
                                        <input placeholder="Appartment" type="text"/>
                                    </div>
                                    <div className="reg-data-input">
                                        <div className="reg-data-select">
                                            <input placeholder="Country" type="text" readOnly="readOnly"/>
                                            <div className="reg-data-select-list">
                                                <Scrollbar style={{ height: 384 }}>
                                                    <div className="reg-data-select-list--item">
                                                        Athletic
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Average
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        A couple extra pounds
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Strong
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Pleasant fatness
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Slim
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Fatness
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Fatness
                                                    </div>
                                                </Scrollbar>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="reg-data-input">
                                        <div className="reg-data-select">
                                            <input placeholder="Zip/Postal" type="text" readOnly="readOnly"/>
                                            <div className="reg-data-select-list">
                                                <Scrollbar style={{ height: 384 }}>
                                                    <div className="reg-data-select-list--item">
                                                        Athletic
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Average
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        A couple extra pounds
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Strong
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Pleasant fatness
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Slim
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Fatness
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Fatness
                                                    </div>
                                                </Scrollbar>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="reg-data-input">
                                        <div className="reg-data-select">
                                            <input placeholder="State" type="text" readOnly="readOnly"/>
                                            <div className="reg-data-select-list">
                                                <Scrollbar style={{ height: 384 }}>
                                                    <div className="reg-data-select-list--item">
                                                        Athletic
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Average
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        A couple extra pounds
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Strong
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Pleasant fatness
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Slim
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Fatness
                                                    </div>
                                                    <div className="reg-data-select-list--item">
                                                        Fatness
                                                    </div>
                                                </Scrollbar>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="reg-data-row">
=======
import React, { useState, useEffect } from 'react';
import Scrollbar from 'react-scrollbars-custom';
import axios from 'axios';
import { nanoid } from 'nanoid';
import notification from '../../utils/notification';
import errorFormmatter from '../../utils/errorFormatter';
import validateInput from '../../utils/validationSchema';
import * as yup from 'yup';

const ClubLkInfo = () => {
	const [firstName, setFirstName] = useState('First Name');
	const [lastName, setLastName] = useState('Last Name');
	const [build, setBuild] = useState('Build');
	const [country, setCountry] = useState('Country');
	const [countries, setCountries] = useState([]);
	const [countryId, setCountryId] = useState('');
	const [nationality, setNationality] = useState('Nationality');
	const [city, setCity] = useState('City');
	const [clubName, setClubName] = useState('Club Name');
	const [street, setStreet] = useState('Street');
	const [site, setSite] = useState('Site');
	const [homePhone, setHomePhone] = useState('Home Phone');
	const [phone, setPhone] = useState('Phone');
	const [apartment, setApartment] = useState('Apartment');
	const [clubCountry, setClubCountry] = useState('Country');
	const [clubCountryId, setClubCountryId] = useState('');
	const [zipCode, setZipCode] = useState('Zip Code');
	const [clientId, setClientId] = useState('');

	const getInitialData = async () => {
		try {
			const {
				data: { models },
			} = await axios.get('/SetUp/GetAllCountries');
			setCountries(models);
		} catch (error) {
			notification({
				title: 'Data Fetching Error',
				message: errorFormmatter(error),
				type: 'danger',
				position: 'top-center',
			});
		}
	};

	const getInitialClubProfile = async () => {
		setModelLoading(true);

		try {
			const {
				data: {
					data: {
						firstName,
						gender,
						nickName,
						cityOfResident,
						nationality,
						country,
						countryId,
						build,
						dateOfBirth,
						lastName,
						aboutMe,
						whatAttractMe,
						myCompetence,
						toy,
						sexualPreference,
						pubicHair,
						language,
						hairColor,
						breastSize,
						bodyChanges,
						eyeColor,
						weight,
						height,
						ethinicity,
					},
				},
			} = await axios.get(`/ModelsProfile/${clientId}/GetModelById`);

			// check for null value in the callback function
			// setFirstName(() => (firstName ? firstName : ''));
			// setGender(() => (gender ? gender : ''));
			// setNickName(() => (nickName ? nickName : ''));
			// setCity(() => (cityOfResident ? cityOfResident : ''));
			// setCountry(() => (country ? country?.countryName : ''));
			// setCountryId(() => (countryId ? countryId : ''));
			// setNationality(() => (nationality ? nationality : ''));
			// setBuild(() => (build ? build : ''));
			// setDate(() => (dateOfBirth ? dateOfBirth?.slice(0, 10) : 'XXXX-XX-XX'));
			// setLastName(() => (lastName ? lastName : ''));
			// setAboutMe(() => (aboutMe ? aboutMe : ''));
			// setWhatAttractsMe(() => (whatAttractMe ? whatAttractMe : ''));
			// setCompetence(() => (myCompetence ? myCompetence : ''));
			// setToys(() => (toy ? toy : ''));
			// setSexualPreferences(() => (sexualPreference ? sexualPreference : ''));
			// setPubicHair(() => (pubicHair ? pubicHair : ''));
			// setLanguanges(() => (language ? language : ''));
			// setHairColor(() => (hairColor ? hairColor : ''));
			// setBreastSizes(() => (breastSize ? breastSize : ''));
			// setBodyChanges(() => (bodyChanges ? bodyChanges : ''));
			// setEyeColor(() => (eyeColor ? eyeColor : ''));
			// setWeight(() => (weight ? weight : ''));
			// setHeight(() => (height ? height : ''));
			// setEthinicity(() => (ethinicity ? ethinicity : ''));
			// setModelLoading(false);
		} catch (error) {
			setModelLoading(false);
			notification({
				title: 'Club Loading Error',
				message: errorFormmatter(error),
				type: 'danger',
				position: 'top-center',
			});
		}
	};

	useEffect(() => {
		getInitialData();
	}, []);

	useEffect(() => {
		setClientId(localStorage.getItem('clientId'));
	}, []);

	useEffect(() => {
		getInitialClubProfile();
	}, []);

	const handleTimeInputChange = e => {
		let thisElement = element.currentTarget;
		let thisClass = thisElement.className.toUpperCase();

		let idValue = element.value;
		formatTimeInput(idValue, thisElement, thisClass);
	};

	function formatTimeInput(idValue, thisElement) {
		let idAlphanumeric = idValue.replace(/\W/g, '').toLowerCase();
		let idLength = idAlphanumeric.length;

		let partOne = idAlphanumeric.slice(0, 2) + ':';
		let partTwo = idAlphanumeric.slice(2, 4) + '';

		let idInput = thisElement;

		if (idLength < 2) {
			idInput.value = idAlphanumeric;
		} else if (idLength >= 2 && idLength < 4) {
			let formatId = partOne + idAlphanumeric.slice(2);
			idInput.value = formatId;
		} else if (idLength === 4) {
			let formatId = partOne + partTwo + idAlphanumeric.slice(4);
			idInput.value = formatId;
		}
	}

	const addActiveClassToDropdowns = e => {
		e.currentTarget.classList.toggle('reg-data-select__active');
	};

	//  options for list of nationalities  dropdown
	const nationalityOptions = countries.length ? (
		countries.map(country => (
			<div
				className='reg-data-select-list--item'
				onClick={e => setNationality(e.target.textContent)}
				key={nanoid()}
				style={{
					display: 'flex',
					paddingInline: '1rem',
					gap: '.1rem',
					alignItems: 'center',
				}}
			>
				<img
					src={`data:image/png;base64,${country.countryFlag}`}
					alt='country flag'
					style={{
						width: '25px',
						height: '25px',
						aspectRatio: '1/1',
						objectFit: 'contain',
					}}
				/>
				{country?.countryName}
			</div>
		))
	) : (
		<p>No Data</p>
	);

	// map options for list of coutries dropdown
	const countryOptions = countries.length ? (
		countries.map(country => (
			<div
				className='reg-data-select-list--item'
				onClick={() => {
					setCountryId(country?.countryId);
					setCountry(country?.countryName);
				}}
				key={nanoid()}
				style={{
					display: 'flex',
					paddingInline: '1rem',
					gap: '.1rem',
					alignItems: 'center',
				}}
			>
				<img
					src={`data:image/png;base64,${country.countryFlag}`}
					alt='country flag'
					style={{
						width: '25px',
						height: '25px',
						aspectRatio: '1/1',
						objectFit: 'contain',
					}}
				/>
				{country?.countryName}
			</div>
		))
	) : (
		<p>No Data</p>
	);

	// map options for list of coutries dropdown
	const clubCountryOptions = countries.length ? (
		countries.map(country => (
			<div
				className='reg-data-select-list--item'
				onClick={() => {
					setClubCountryId(country?.countryId);
					setClubCountry(country?.countryName);
				}}
				key={nanoid()}
				style={{
					display: 'flex',
					paddingInline: '1rem',
					gap: '.1rem',
					alignItems: 'center',
				}}
			>
				<img
					src={`data:image/png;base64,${country.countryFlag}`}
					alt='country flag'
					style={{
						width: '25px',
						height: '25px',
						aspectRatio: '1/1',
						objectFit: 'contain',
					}}
				/>
				{country?.countryName}
			</div>
		))
	) : (
		<p>No Data</p>
	);

	return (
		<main>
			<section className='reg-data'>
				<div className='container'>
					<div className='reg-data--subtitle'>
						Before you start fill in the form, please. It will help users find you easier!
					</div>
					<div className='page-title'>Club registration information</div>
					<form>
						<div className='reg-data-row'>
							<div className='reg-data-left'>
								<div className='reg-data-row--title'>
									<span>Main information</span>
								</div>
								<div className='reg-data-row--subtitle'>
									Information for the site administration it <br />
									will not be shown to site visitors
								</div>
							</div>

							<div className='reg-data-right'>
								<div className='reg-data-input'>
									<input
										placeholder='First name'
										type='text'
										name='firstName'
										value={firstName}
										onChange={e => setFirstName(e.target.value)}
									/>
								</div>

								<div className='reg-data-input'>
									<input
										placeholder='Last name'
										type='text'
										name='lastName'
										onChange={e => setLastName(e.target.value)}
										value={lastName}
									/>
								</div>

								<div className='reg-data-input'>
									<div className='reg-data-select' onClick={addActiveClassToDropdowns}>
										<input placeholder='Build' type='text' readOnly='readOnly' value={build} />
										<div className='reg-data-select-list'>
											<Scrollbar style={{ height: 384 }}>
												<div className='reg-data-select-list--item' onClick={e => setBuild(e.target.textContent)}>
													Slim
												</div>
												<div className='reg-data-select-list--item' onClick={e => setBuild(e.target.textContent)}>
													Slim Thick
												</div>
												<div className='reg-data-select-list--item' onClick={e => setBuild(e.target.textContent)}>
													Thick
												</div>
											</Scrollbar>
										</div>
									</div>
								</div>

								<div className='reg-data-input'>
									<div className='reg-data-select' onClick={addActiveClassToDropdowns}>
										<input placeholder='Nationality' type='text' readOnly='readOnly' value={nationality} />
										<div className='reg-data-select-list'>
											<Scrollbar style={{ height: 384 }}>{nationalityOptions}</Scrollbar>
										</div>
									</div>
								</div>

								<div className='reg-data-input'>
									<div className='reg-data-select' onClick={addActiveClassToDropdowns}>
										<input placeholder='Country of Residence' type='text' readOnly='readOnly' value={country} />
										<div className='reg-data-select-list'>
											<Scrollbar style={{ height: 384 }}>{countryOptions}</Scrollbar>
										</div>
									</div>
								</div>

								<div className='reg-data-input placeCustom'>
									<input
										required
										placeholder='City of Residence'
										type='text'
										value={city}
										onChange={e => setCity(e.target.value)}
									/>
								</div>
							</div>
						</div>

						<div className='reg-data-row'>
							<div className='reg-data-left'>
								<div className='reg-data-row--title'>
									<span>Club information</span>
								</div>
								<div className='reg-data-row--subtitle'>
									Completed information will be displayed <br />
									in the profile and see for users
								</div>
							</div>

							<div className='reg-data-right'>
								<div className='reg-data-input'>
									<input
										placeholder='Club name'
										type='text'
										value={clubName}
										onChange={e => setClubName(e.target.value)}
									/>
								</div>

								<div className='reg-data-input'>
									<input placeholder='Street' type='text' value={street} onChange={e => setStreet(e.target.value)} />
								</div>

								<div className='reg-data-input'>
									<input placeholder='Site' type='text' value={site} onChange={e => setSite(e.target.value)} />
								</div>

								<div className='reg-data-input'>
									<input
										placeholder='Home number'
										type='text'
										value={homePhone}
										onChange={e => setHomePhone(e.target.value)}
									/>
								</div>

								<div className='reg-data-input'>
									<input placeholder='Phone' type='text' value={phone} onChange={e => setPhone(e.target.value)} />
								</div>

								<div className='reg-data-input'>
									<input
										placeholder='Appartment'
										type='text'
										value={apartment}
										onChange={e => setApartment(e.target.value)}
									/>
								</div>

								<div className='reg-data-input'>
									<div className='reg-data-select' onClick={addActiveClassToDropdowns}>
										<input placeholder='Country' type='text' readOnly='readOnly' value={clubCountry} />
										<div className='reg-data-select-list'>
											<Scrollbar style={{ height: 384 }}>{clubCountryOptions}</Scrollbar>
										</div>
									</div>
								</div>

								<div className='reg-data-input'>
									<input
										placeholder='Zip/Postal'
										type='text'
										value={zipCode}
										onChange={e => setZipCode(e.target.value)}
									/>
								</div>
							</div>
						</div>
						{/* <div className="reg-data-row">
>>>>>>> 59d03e77e78a8be035b39ba65f4989f1c2ae5150
                                <div className="reg-data-left">
                                    <div className="reg-data-row--title">
                                        <span>Working hours</span>
                                    </div>
                                    <div className="reg-data-row--subtitle">
                                        Completed information will be displayed <br/>
                                        in the profile and see for users
                                    </div>
                                </div>
                                <div className="reg-data-right timeinput">
                                    <div className="reg-data-input">
                                        <input className="pm" maxLength="5" placeholder="12:00 PM" type="text" id='idInput'/>
                                        <span className="reg-data-input--placeholder">Monday</span>
                                    </div>
                                    <div className="reg-data-input">
                                        <input className="am" maxLength="5" placeholder="03:00 AM" type="text"/>
                                        <span className="reg-data-input--placeholder">Monday</span>
                                    </div>
                                    <div className="reg-data-input">
                                        <input className="pm" maxLength="5" placeholder="12:00 PM" type="text"/>
                                        <span className="reg-data-input--placeholder">Tuesday</span>
                                    </div>
                                    <div className="reg-data-input">
                                        <input className="am" maxLength="5" placeholder="03:00 AM" type="text"/>
                                        <span className="reg-data-input--placeholder">Tuesday</span>
                                    </div>
                                    <div className="reg-data-input">
                                        <input className="pm" maxLength="5" placeholder="12:00 PM" type="text"/>
                                        <span className="reg-data-input--placeholder">Wednesday</span>
                                    </div>
                                    <div className="reg-data-input">
                                        <input className="am" maxLength="5" placeholder="03:00 AM" type="text"/>
                                        <span className="reg-data-input--placeholder">Wednesday</span>
                                    </div>
                                    <div className="reg-data-input">
                                        <input className="pm" maxLength="5" placeholder="12:00 PM" type="text"/>
                                        <span className="reg-data-input--placeholder">Thursday</span>
                                    </div>
                                    <div className="reg-data-input">
                                        <input className="am" maxLength="5" placeholder="03:00 AM" type="text"/>
                                        <span className="reg-data-input--placeholder">Thursday</span>
                                    </div>
                                    <div className="reg-data-input">
                                        <input className="pm" maxLength="5" placeholder="12:00 PM" type="text"/>
                                        <span className="reg-data-input--placeholder">Friday</span>
                                    </div>
                                    <div className="reg-data-input">
                                        <input className="am" maxLength="5" placeholder="03:00 AM" type="text"/>
                                        <span className="reg-data-input--placeholder">Friday</span>
                                    </div>
                                    <div className="reg-data-input">
                                        <input className="pm" maxLength="5" placeholder="12:00 PM" type="text"/>
                                        <span className="reg-data-input--placeholder">Saterday</span>
                                    </div>
                                    <div className="reg-data-input">
                                        <input className="am" maxLength="5" placeholder="03:00 AM" type="text"/>
                                        <span className="reg-data-input--placeholder">Sunday</span>
                                    </div>
                                    <div className="reg-data-input">
                                        <input className="pm" maxLength="5" placeholder="12:00 PM" type="text"/>
                                        <span className="reg-data-input--placeholder">Sunday</span>
                                    </div>
                                    <div className="reg-data-input">
                                        <input className="am" maxLength="5" placeholder="03:00 AM" type="text"/>
                                        <span className="reg-data-input--placeholder">Saterday</span>
                                    </div>
                                </div>
                            </div> */
/* <<<<<<< HEAD
                            <div className="reg-data-row">
                                <div className="reg-data-left">
                                    <div className="reg-data-row--title">
                                        <span>Working days, hours</span>
                                    </div>
                                    <div className="reg-data-row--subtitle">
                                        Completed information will be <br/>
                                        displayed in the profile and see for <br/>users
                                    </div>
                                </div>
                                <div className="reg-data-right timeinput reg-data-right--work workTime">
                                    <div className="reg-data-right--two workTime-wrapper">
                                        <div className="workTime-check">
                                            <div className="workTime-li">
                                                <label className="workTime-check">
                                                    <input type="checkbox" />
                                                    <span></span>
                                                    <div>Monday</div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="workTime-input">
                                            <div className="reg-data-input">
                                                <input className="pm" maxLength="5" placeholder="12:00" type="text" id='idInput'/>
                                                <span className="reg-data-input--time pm"></span>
                                            </div>
                                            <div className="reg-data-input">
                                                <input className="am" maxLength="5" placeholder="03:00" type="text"/>
                                                <span className="reg-data-input--time am"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="reg-data-right--two workTime-wrapper">
                                        <div className="workTime-check">
                                            <div className="workTime-li">
                                                <label className="workTime-check">
                                                    <input type="checkbox" />
                                                    <span></span>
                                                    <div>Tuesday</div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="workTime-input">
                                            <div className="reg-data-input">
                                                <input className="pm" maxLength="5" placeholder="12:00" type="text" id='idInput'/>
                                                <span className="reg-data-input--time pm"></span>
                                            </div>
                                            <div className="reg-data-input">
                                                <input className="am" maxLength="5" placeholder="03:00" type="text"/>
                                                <span className="reg-data-input--time am"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="reg-data-right--two workTime-wrapper">
                                        <div className="workTime-check">
                                            <div className="workTime-li">
                                                <label className="workTime-check">
                                                    <input type="checkbox" />
                                                    <span></span>
                                                    <div>Wednesday</div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="workTime-input">
                                            <div className="reg-data-input">
                                                <input className="pm" maxLength="5" placeholder="12:00" type="text" id='idInput'/>
                                                <span className="reg-data-input--time pm"></span>
                                            </div>
                                            <div className="reg-data-input">
                                                <input className="am" maxLength="5" placeholder="03:00" type="text"/>
                                                <span className="reg-data-input--time am"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="reg-data-right--two workTime-wrapper">
                                        <div className="workTime-check">
                                            <div className="workTime-li">
                                                <label className="workTime-check">
                                                    <input type="checkbox" />
                                                    <span></span>
                                                    <div>Thursday</div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="workTime-input">
                                            <div className="reg-data-input">
                                                <input className="pm" maxLength="5" placeholder="12:00" type="text" id='idInput'/>
                                                <span className="reg-data-input--time pm"></span>
                                            </div>
                                            <div className="reg-data-input">
                                                <input className="am" maxLength="5" placeholder="03:00" type="text"/>
                                                <span className="reg-data-input--time am"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="reg-data-right--two workTime-wrapper">
                                        <div className="workTime-check">
                                            <div className="workTime-li">
                                                <label className="workTime-check">
                                                    <input type="checkbox" />
                                                    <span></span>
                                                    <div>Friday</div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="workTime-input">
                                            <div className="reg-data-input">
                                                <input className="pm" maxLength="5" placeholder="12:00" type="text" id='idInput'/>
                                                <span className="reg-data-input--time pm"></span>
                                            </div>
                                            <div className="reg-data-input">
                                                <input className="am" maxLength="5" placeholder="03:00" type="text"/>
                                                <span className="reg-data-input--time am"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="reg-data-right--two workTime-wrapper">
                                        <div className="workTime-check">
                                            <div className="workTime-li">
                                                <label className="workTime-check">
                                                    <input type="checkbox" />
                                                    <span></span>
                                                    <div>Saturday</div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="workTime-input">
                                            <div className="reg-data-input">
                                                <input className="pm" maxLength="5" placeholder="12:00" type="text" id='idInput'/>
                                                <span className="reg-data-input--time pm"></span>
                                            </div>
                                            <div className="reg-data-input">
                                                <input className="am" maxLength="5" placeholder="03:00" type="text"/>
                                                <span className="reg-data-input--time am"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="reg-data-right--two workTime-wrapper">
                                        <div className="workTime-check">
                                            <div className="workTime-li">
                                                <label className="workTime-check">
                                                    <input type="checkbox" />
                                                    <span></span>
                                                    <div>Sunday</div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="workTime-input">
                                            <div className="reg-data-input">
                                                <input className="pm" maxLength="5" placeholder="12:00" type="text" id='idInput'/>
                                                <span className="reg-data-input--time pm"></span>
                                            </div>
                                            <div className="reg-data-input">
                                                <input className="am" maxLength="5" placeholder="03:00" type="text"/>
                                                <span className="reg-data-input--time am"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="reg-data-row">
                                <div className="reg-data-left">
                                    <div className="reg-data-row--title">
                                        <span>Cover</span>
                                    </div>
                                    <div className="reg-data-row--subtitle">
                                        Completed information will be displayed <br/>
                                        in the profile and see for users
                                    </div>
                                </div>
                                <div className="reg-data-right">
                                    <label className="dropzone dropzone-cover">
                                        <div className="btn btn__purple">Upload from computer</div>
                                        <input name="myFile" type="file" id="myFile" placeholder="Upload from computer"/>
                                    </label>
                                </div>
                            </div>
                            <div className="reg-data-row">
                                <div className="reg-data-left">
                                    <div className="reg-data-row--title">
                                        <span>Logotype</span>
                                    </div>
                                    <div className="reg-data-row--subtitle">
                                        Completed information will be displayed <br/>
                                        in the profile and see for users
                                    </div>
                                </div>
                                <div className="reg-data-right">
                                    <label className="dropzone dropzone-logo">
                                        <div className="btn btn__purple">Upload from computer</div>
                                        <input name="myFile" type="file" id="myFile" placeholder="Upload from computer"/>
                                    </label>
                                </div>
                            </div>
                            <button className="btn btn__red">
                                Save
                            </button>
                        </form>
                    </div>
                </section>
            </main>
        );
    }
}
export default ClubLkInfo;
=======
						<div className='reg-data-row'>
							<div className='reg-data-left'>
								<div className='reg-data-row--title'>
									<span>Working days, hours</span>
								</div>
								<div className='reg-data-row--subtitle'>
									Completed information will be <br />
									displayed in the profile and see for <br />
									users
								</div>
							</div>

							<div className='reg-data-right timeinput reg-data-right--work workTime'>
								<div className='reg-data-right--two workTime-wrapper'>
									<div className='workTime-check'>
										<div className='workTime-li'>
											<label className='workTime-check'>
												<input type='checkbox' />
												<span />
												<div>Monday</div>
											</label>
										</div>
									</div>
									<div className='workTime-input'>
										<div className='reg-data-input'>
											<input className='pm' maxLength='5' placeholder='12:00' type='text' id='idInput' />
											<span className='reg-data-input--time pm' />
										</div>
										<div className='reg-data-input'>
											<input className='am' maxLength='5' placeholder='03:00' type='text' />
											<span className='reg-data-input--time am' />
										</div>
									</div>
								</div>
								<div className='reg-data-right--two workTime-wrapper'>
									<div className='workTime-check'>
										<div className='workTime-li'>
											<label className='workTime-check'>
												<input type='checkbox' />
												<span />
												<div>Tuesday</div>
											</label>
										</div>
									</div>
									<div className='workTime-input'>
										<div className='reg-data-input'>
											<input className='pm' maxLength='5' placeholder='12:00' type='text' id='idInput' />
											<span className='reg-data-input--time pm' />
										</div>
										<div className='reg-data-input'>
											<input className='am' maxLength='5' placeholder='03:00' type='text' />
											<span className='reg-data-input--time am' />
										</div>
									</div>
								</div>
								<div className='reg-data-right--two workTime-wrapper'>
									<div className='workTime-check'>
										<div className='workTime-li'>
											<label className='workTime-check'>
												<input type='checkbox' />
												<span />
												<div>Wednesday</div>
											</label>
										</div>
									</div>
									<div className='workTime-input'>
										<div className='reg-data-input'>
											<input className='pm' maxLength='5' placeholder='12:00' type='text' id='idInput' />
											<span className='reg-data-input--time pm' />
										</div>
										<div className='reg-data-input'>
											<input className='am' maxLength='5' placeholder='03:00' type='text' />
											<span className='reg-data-input--time am' />
										</div>
									</div>
								</div>
								<div className='reg-data-right--two workTime-wrapper'>
									<div className='workTime-check'>
										<div className='workTime-li'>
											<label className='workTime-check'>
												<input type='checkbox' />
												<span />
												<div>Thursday</div>
											</label>
										</div>
									</div>
									<div className='workTime-input'>
										<div className='reg-data-input'>
											<input className='pm' maxLength='5' placeholder='12:00' type='text' id='idInput' />
											<span className='reg-data-input--time pm' />
										</div>
										<div className='reg-data-input'>
											<input className='am' maxLength='5' placeholder='03:00' type='text' />
											<span className='reg-data-input--time am' />
										</div>
									</div>
								</div>
								<div className='reg-data-right--two workTime-wrapper'>
									<div className='workTime-check'>
										<div className='workTime-li'>
											<label className='workTime-check'>
												<input type='checkbox' />
												<span />
												<div>Friday</div>
											</label>
										</div>
									</div>
									<div className='workTime-input'>
										<div className='reg-data-input'>
											<input className='pm' maxLength='5' placeholder='12:00' type='text' id='idInput' />
											<span className='reg-data-input--time pm' />
										</div>
										<div className='reg-data-input'>
											<input className='am' maxLength='5' placeholder='03:00' type='text' />
											<span className='reg-data-input--time am' />
										</div>
									</div>
								</div>
								<div className='reg-data-right--two workTime-wrapper'>
									<div className='workTime-check'>
										<div className='workTime-li'>
											<label className='workTime-check'>
												<input type='checkbox' />
												<span />
												<div>Saturday</div>
											</label>
										</div>
									</div>
									<div className='workTime-input'>
										<div className='reg-data-input'>
											<input className='pm' maxLength='5' placeholder='12:00' type='text' id='idInput' />
											<span className='reg-data-input--time pm' />
										</div>
										<div className='reg-data-input'>
											<input className='am' maxLength='5' placeholder='03:00' type='text' />
											<span className='reg-data-input--time am' />
										</div>
									</div>
								</div>
								<div className='reg-data-right--two workTime-wrapper'>
									<div className='workTime-check'>
										<div className='workTime-li'>
											<label className='workTime-check'>
												<input type='checkbox' />
												<span />
												<div>Sunday</div>
											</label>
										</div>
									</div>
									<div className='workTime-input'>
										<div className='reg-data-input'>
											<input className='pm' maxLength='5' placeholder='12:00' type='text' id='idInput' />
											<span className='reg-data-input--time pm' />
										</div>
										<div className='reg-data-input'>
											<input className='am' maxLength='5' placeholder='03:00' type='text' />
											<span className='reg-data-input--time am' />
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className='reg-data-row'>
							<div className='reg-data-left'>
								<div className='reg-data-row--title'>
									<span>Cover</span>
								</div>
								<div className='reg-data-row--subtitle'>
									Completed information will be displayed <br />
									in the profile and see for users
								</div>
							</div>
							<div className='reg-data-right'>
								<label className='dropzone dropzone-cover'>
									<div className='btn btn__purple'>Upload from computer</div>
									<input name='myFile' type='file' id='myFile' placeholder='Upload from computer' />
								</label>
							</div>
						</div>

						<div className='reg-data-row'>
							<div className='reg-data-left'>
								<div className='reg-data-row--title'>
									<span>Logotype</span>
								</div>
								<div className='reg-data-row--subtitle'>
									Completed information will be displayed <br />
									in the profile and see for users
								</div>
							</div>
							<div className='reg-data-right'>
								<label className='dropzone dropzone-logo'>
									<div className='btn btn__purple'>Upload from computer</div>
									<input name='myFile' type='file' id='myFile' placeholder='Upload from computer' />
								</label>
							</div>
						</div>

						<button className='btn btn__red'>Save</button>
					</form>
				</div>
			</section>
		</main>
	);
};
export default ClubLkInfo;
>>>>>>> 59d03e77e78a8be035b39ba65f4989f1c2ae5150
 */
