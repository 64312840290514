import React, { Component } from 'react';
import {Link} from "react-router-dom";

class RegistrationClub extends Component {
    componentDidMount(){
        document.querySelector('.go-step2').onclick = function() {
            document.querySelector('.reg-model').classList.add('step3');
            document.querySelector('.reg-model .reg-model--step').classList.remove('reg-model--step__active');
            document.querySelector('.reg-model .reg-model--step:nth-child(2)').classList.add('reg-model--step__active');
        };
        document.querySelectorAll('.club-signin').forEach(function(element) {
            element.onclick = function() {
                document.querySelector('.singin-footer__club').click();
            };
        });
    }
    render() {
        return (
            <main className="container">
                <form className="reg-model reg-model__club">
                    <div className="reg-model-steps">
                        <div className="reg-model--step reg-model--step__active">
                            Contact Info
                        </div>
                        <div className="reg-model--step">
                            Club registration information
                        </div>
                    </div>
                    <div className="reg-model-step1">
                        <div className="reg-model-step1--left">
                            <div className="reg-model-step1--title">
                                CLUB registration
                            </div>
                            <div className="reg-model-step1--subtitle">
                                Sign Up now!
                            </div>
                            <div className="reg-model-step1-text">
                                <div className="reg-model-step1-text--rotate">
                                    Benefits
                                </div>
                                <div>
                                    <p>
                                        Advertise your club
                                    </p>
                                    <p>
                                        Tell about your advantages
                                    </p>
                                    <p>
                                        Registration is absolutely free!
                                    </p>
                                </div>
                            </div>
                            <div className="reg-model-step1--links">
                                or
                                <div className="btn btn__purple club-signin">Sign In</div>
                                if you have an account
                            </div>
                        </div>
                        <div className="reg-model-step1--right">
                            <div className="registration-form--title">Start here</div>
                            <div className="reg-model-step1--form--title">Full name</div>
                            <div className="input-wrapper">
                                <input name="name" type="text" placeholder="Enter your full name"/>
                            </div>
                            <div className="reg-model-step1--form--title">Email address</div>
                            <div className="input-wrapper">
                                <input name="email" type="text" placeholder="Email address"/>
                            </div>
                            <div className="reg-model-step1--form--title">Password</div>
                            <div className="input-wrapper">
                                <input name="password" type="password" placeholder="Password"/>
                            </div>
                            <div className="reg-model-step1--bottom">
                                <div className="btn btn__red go-step2">Sign up now!</div>
                                <div className="politics">
                                    By signing up, you agree <br/>
                                    to our <Link to="">Terms and Conditions</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="reg-model-step3">
                        <div className="reg-model-thanks">
                            <div className="reg-model-thanks--title">
                                Thank you <br/>
                                for the application!
                                </div>
                            <div className="reg-model-thanks--subtitle">
                                An e-mail with a link was sent to the e-mail address you <br/>
                                specified, after which you will be able to confirm the <br/>
                                e-mail address and complete the registration process <br/>
                            </div>
                            <Link to="/" className="reg-model-thanks--link btn btn__red">Return to main page</Link>
                        </div>
                    </div>
                </form>
            </main>
        );
    }
}
export default RegistrationClub;