import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import styles from './table.module.scss';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';

const columns = [
	{
		id: 'id',
		header: 'ID',
		accessorKey: 'id',
	},

	{
		id: 'date',
		header: 'Date',
		accessorKey: 'date',
	},

	{
		id: 'time',
		header: 'Time',
		accessorKey: 'time',
	},
	{
		id: 'customer',
		header: 'Customer',
		accessorKey: 'customer',
	},

	{
		id: 'description',
		header: 'Description',
		accessorKey: 'description',
	},
	{
		id: 'duration',
		header: 'Duration',
		accessorKey: 'duration',
	},

	{
		id: 'amount',
		header: 'Amount',
		accessorKey: 'amount',
	},
];

const GridTable = ({ tableData }) => {
	const preProcessTableData = data => {
		return data?.map(item => {
			return {
				id: item?.id,
				date: item?.date,
				description: item?.description,
				time: item?.time,
				customer: item?.customer,
				duration: item?.duration,
				amount: item?.amount,
			};
		});
	};

	const table = useReactTable({
		data: preProcessTableData(tableData),
		columns,
		getCoreRowModel: getCoreRowModel(),
	});

	return (
		<div className='p-2'>
			<table>
				<thead>
					{table.getHeaderGroups()?.map(headerGroup => (
						<tr key={headerGroup.id} className={styles.tableHeader}>
							{headerGroup?.headers?.map(header => (
								<th key={header.id} className={styles.tableHeaderCell}>
									{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody>
					{table?.getRowModel()?.rows?.length === 0 && <span className={styles.noData}>No Transaction Available</span>}
					{table?.getRowModel()?.rows?.map((row, index) => (
						<tr key={row.id} className={styles.tableRow}>
							{row.getVisibleCells()?.map(cell => (
								<td key={cell.id} className={classNames(styles.tableCell, row.length === index ? styles.lastRow : '')}>
									{flexRender(cell.column.columnDef.cell, cell?.getContext())}
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default GridTable;
