
import React, { useState } from 'react';

const Notover = () => {
	const [isToggleOn, setIsToggleOn] = useState(true);

	const handleClick = () => {
		window.open('', '_parent', '');
		window.close();
	};

	return (
		<div className='age-check-checkblock-wrapper'>
			<div onClick={handleClick} className='age-check-checkblock-notOver18' />
			<div onClick={handleClick} className='age-check-checkblock-notBtn'>
				I`m not over
			</div>
		</div>
	);
};


export default Notover;
