
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import * as yup from 'yup';
import notification from '../../utils/notification';
import errorFormmatter from '../../utils/errorFormatter';
import validateInputs from '../../utils/validationSchema';

const ResetPassword = () => {
	const [email, setEmail] = useState('');
	const [token, setToken] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [loading, setLoading] = useState(false);
	const location = useLocation();

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		setEmail(params.get('email'));

		setToken(
			params
				.get('token')
				.split(' ')
				.join('+')
		); // split and join adds back + symbol removed after parsing by URLSearchParams
	}, []);

	const validationSchema = yup.object().shape({
		password: yup
			.string()
			.required('Password is required')
			.min(8, 'Password is less than 8 characters')
			.matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/, 'Password must be alphanumeric'),
		confirmPassword: yup.string().oneOf([yup.ref('password')], 'Confirm password does not match password'),
	});

	const resetPassword = async event => {
		event.preventDefault();

		try {
			const successful = await validateInputs(validationSchema, { confirmPassword, password });
			if (!successful) return; // stop execution on errors

			setLoading(true);
			const formData = new FormData();

			formData.append('Password', password);
			formData.append('ConfirmPassword', confirmPassword);
			formData.append('Email', email);
			formData.append('Token', token);

			const response = await axios.post('Authentication/reset-password', formData);

			if (response?.status === 204 || response?.status === 201 || response?.status === 200) {
				document.querySelector('.lost-data__change').classList.add('hidden');
				document.querySelector('.lost-data__success').classList.add('active');
			}

			notification({
				title: 'Reset Password Success',
				message: response?.data.message,
				type: 'success',
				position: 'top-center',
			});
			setLoading(false);
		} catch (error) {
			setLoading(false);
			notification({
				title: 'Reset Password Error',
				message: errorFormmatter(error),
				type: 'danger',
				position: 'top-center',
			});
		}
	};

	return (
		<main className='container'>
			<section className='lost-data lost-data__change'>
				<div className='lost-data--title'>
					Please use this page
					<br />
					to reset your password
				</div>
				<div className='lost-data--subtitle'>Please enter your new password for your account</div>
				<form onSubmit={resetPassword}>
					<div className='input-wrapper'>
						<input
							name='password'
							className='passwordJS firstPassword'
							type='password'
							placeholder='Password'
							onChange={e => setPassword(e.target.value)}
						/>
						<div className='password-tooltip'>
							<div className='password-tooltip--title'>Must comply with the following rules:</div>
							<ul className='password-tooltip--list'>
								<li className='passwordJS_lower'>
									Аt least one lower <span />
								</li>
								<li className='passwordJS_number'>
									Аt least one number <span />
								</li>
								<li className='passwordJS_upper'>
									Аt least one upper <span />
								</li>
								<li className='passwordJS_min'>
									Оver 8 characters <span />
								</li>
							</ul>
						</div>
					</div>
					<div className='input-wrapper'>
						<input
							name='confirmPassword'
							className='secondPassword'
							type='password'
							placeholder='Repeat password again'
							onChange={e => setConfirmPassword(e.target.value)}
						/>
					</div>
					<button onClick={resetPassword} className='btn btn__red' disabled={loading}>
						{loading ? 'Saving...' : 'Save password'}
					</button>
				</form>
				<img className='lost-data--img' src={require('../../images/forget.png')} alt='' />
			</section>
			<section className='lost-data lost-data__success'>
				<div className='lost-data--title'>
					<svg xmlns='http://www.w3.org/2000/svg' width='94' height='93' viewBox='0 0 94 93' fill='none'>
						<path
							opacity='0.28'
							d='M46.1602 92.3204C20.7112 92.3204 0 71.6092 0 46.1602C0 20.7112 20.7112 0 46.1602 0C54.5358 0 62.8564 2.3964 70.2264 6.91421C72.5363 8.33634 73.2592 11.3613 71.8371 13.6713C70.4149 15.9812 67.39 16.6962 65.0878 15.2898C59.2658 11.7149 52.7208 9.82132 46.1602 9.82132C26.1247 9.82132 9.82132 26.1247 9.82132 46.1602C9.82132 66.1957 26.1247 82.4991 46.1602 82.4991C66.1957 82.4991 82.4991 66.2035 82.4991 46.1602C82.4991 43.5438 82.1376 40.7781 81.3991 37.7139C80.7627 35.0739 82.3891 32.4261 85.0212 31.7896C87.669 31.1454 90.3168 32.7718 90.9454 35.4117C91.8725 39.2538 92.3204 42.7659 92.3204 46.1602C92.3204 71.6092 71.6092 92.3204 46.1602 92.3204ZM47.633 59.4822L91.7596 15.3556C93.6689 13.4385 93.6689 10.3271 91.7596 8.40996C89.8425 6.49284 86.7233 6.49284 84.8061 8.40996L44.1602 49.0638L32.8225 37.7182C30.9053 35.801 27.794 35.801 25.8768 37.7182C23.9597 39.6353 23.9597 42.7467 25.8768 44.6638L40.6952 59.4822C41.6538 60.4408 42.9031 60.92 44.1602 60.92C45.4173 60.92 46.6745 60.4408 47.633 59.4822Z'
							fill='#EC9ABE'
						/>
					</svg>
				</div>
				<div className='lost-data--subtitle'>Your password has been saved</div>
				<Link to='/' className='btn btn__red'>
					To Main
				</Link>
				<img className='lost-data--img' src={require('../../images/forget.png')} alt='' />
			</section>
		</main>
	);
};

export default ResetPassword;

