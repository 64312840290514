import DateTimePicker from '../../custom/datePicker/DatePicker';
import FluidContainer from '../../custom/fluidContainer/FluidContainer';
import { RiArrowDropUpLine } from 'react-icons/ri';
import LineChart from './charts/LineChart';
import ExpandAnalysis from './charts/ExpandAnalysis';


const ClubAnalysis = () => {
  return (
    <FluidContainer>
      <main className='mt-[93px]'>
        <section className='flex w-[100%] items-center justify-between mb-[54px]'>
          <div className='flex items-end gap-[0.3rem]'>
            <h1 className="text-primary-100 text-2xl font-bold font-['Montserrat'] leading-7">
              Analytics
            </h1>
            <span className="text-stone-300 text-[1rem] font-normal font-['Montserrat']">
              vs last month
            </span>
          </div>
          <div>
            <DateTimePicker
              selected={new Date()}
              renderElement={({ value, onClick }) => (
                <div className='flex items-center gap-[4px]'>
                  <span className="text-white text-xl font-medium font-['Montserrat']">
                    {value}
                  </span>
                  <RiArrowDropUpLine
                    onClick={onClick}
                    className='text-[2rem] rotate-180'
                  />
                </div>
              )}
              showMonthYearPicker
              dateFormat='MMM yyyy'
            />
          </div>
        </section>
        <section className='mb-[150px] gap-[24px] flex w-[100%] justify-between items-center'>
          <LineChart name='Posts' total='45' />
          <LineChart name='Impressions' total='76k' />
          <LineChart name='Profile Visits' total='256' />
          <LineChart name='Followers' total='1,986' />
        </section>
        <ExpandAnalysis />
      </main>
    </FluidContainer>
  );
};

const TipDisplay = () => {
  return (
    <main className='w-[207px] h-[90px] px-3 py-2  bg-gradient-to-r from-[#413450] to-[#563143] shadow flex-col justify-start items-start gap-2.5 inline-flex'>
      <h1 className="text-white text-base font-semibold font-['Montserrat']">
        Saturday , July 4, 2023
      </h1>
      <div className='w-[180px] justify-between items-start inline-flex'>
        <p className="text-white text-sm font-normal font-['Montserrat']">
          Impressions
        </p>
        <p className="text-white text-sm font-normal font-['Montserrat']">
          4000
        </p>
      </div>
      <div className='self-stretch justify-between items-start inline-flex'>
        <p className="text-white text-sm font-normal font-['Montserrat']">
          Posts
        </p>
        <p className="text-white text-sm font-normal font-['Montserrat']">5</p>
      </div>
    </main>
  );
};

export default ClubAnalysis;
