import styles from './tipmenu.scss';
import classNames from 'classnames';
import { getStreamingTips } from '../../../../request/model';
import { useEffect, useState } from 'react';
import SendTipModal from '../sendTipModal/SendTipModal';

const TipMenu = ({ isFullScreen, modelId, rankingMembers = [] }) => {
  const [tips, setTips] = useState([]);

  const getAllStreamingTips = async () => {
    try {
      const response = await getStreamingTips(modelId);
      setTips(response);
    } catch (error) {
      setTips([]);
    }
  };

  useEffect(() => {
    getAllStreamingTips();

    const timeIntervalId = setInterval(() => {
      getAllStreamingTips();
    }, 5000);

    return () => {
      clearInterval(timeIntervalId);
    };
  }, []);

  return (
    <section
      key={0}
      className={classNames(
        styles.chatRoomMessageContainer,
        isFullScreen && styles.chatRoomMessageContainerFullScreen,
        'mb-[1rem] overflow-auto'
      )}
    >
      <div className={classNames(styles.table, 'h-[291.5px]')}>
        <h1 className='king-tip-menu-title flex-wrap'>
          {rankingMembers.map((member, index) => {
            return (
              <span className='inline-block w-[max-content] mr-[0.5rem] '>
                {member?.memberName}{' '}
                {index + 1 !== rankingMembers.length && ','}
              </span>
            );
          })}{' '}
          <span>is King of the Room </span>
        </h1>

        <h2 className='top-tip'>
          Spend more credits to become the King of the room
        </h2>
        <h1 className='tip-menu-title'>Tip Menu</h1>
        <div className='tip-container'>
          {tips.map((tip) => {
            return (
              <h5 key={tip?.id} className='tip'>
                {tip?.description} <span>{tip?.credit}</span>
              </h5>
            );
          })}
        </div>
      </div>
    </section>
  );
};
export default TipMenu;
